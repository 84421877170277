"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
exports.__esModule = true;
exports.default = void 0;
var _extends3 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));
var _classnames = _interopRequireDefault(require("classnames"));
var _react = _interopRequireDefault(require("react"));

/* eslint-disable react/prop-types */
function TimeGridEvent(props) {
  var _extends2;
  var style = props.style,
    className = props.className,
    event = props.event,
    accessors = props.accessors,
    isRtl = props.isRtl,
    selected = props.selected,
    label = props.label,
    continuesEarlier = props.continuesEarlier,
    continuesLater = props.continuesLater,
    getters = props.getters,
    onClick = props.onClick,
    onDoubleClick = props.onDoubleClick,
    _props$components = props.components,
    Event = _props$components.event,
    EventWrapper = _props$components.eventWrapper;
  var title = accessors.title(event);
  var tooltip = accessors.tooltip(event);
  var end = accessors.end(event);
  var start = accessors.start(event);
  var userProps = getters.eventProp(event, start, end, selected);
  var height = style.height,
    top = style.top,
    width = style.width,
    xOffset = style.xOffset;
  var inner = [_react.default.createElement("div", {
    key: "1",
    className: "rbc-event-label"
  }, event.clientNameWithTitle), _react.default.createElement("div", {
    key: "2",
    className: "rbc-event-content"
  }, Event ? _react.default.createElement(Event, {
    event: event,
    title: label
  }) : label)];
  return _react.default.createElement(EventWrapper, (0, _extends3.default)({
    type: "time"
  }, props), _react.default.createElement("div", {
    onClick: onClick,
    onDoubleClick: onDoubleClick,
    style: (0, _extends3.default)({}, userProps.style, (_extends2 = {
      backgroundColor: event.color,
      backgroundImage: event.gradient,
      "zIndex": event.color === "#dedede" ? 1 : event.color === "#000000" ? 2 : 3,
      "border": "none",
      top: top + "%",
      height: height + "%"
    }, _extends2[isRtl ? 'right' : 'left'] = Math.max(0, event.availabilityGrid || event.color === "#000000" ? 0 : xOffset) + "%", _extends2.width = event.availabilityGrid || event.color === "#000000" ? "100%" : width + "%", _extends2)),
    "data-tip": event.is_provider_schedule ? event.availabilityGrid ? tooltip : tooltip ? (typeof event.clientName === 'string' ? event.clientName + '<br />' : '') + tooltip : undefined : event.availabilityGrid ? tooltip : tooltip ? (typeof label === 'string' ? event.clientName + '<br />' + event.clientEmail + '<br />' + event.clientPhone + '<br />' + label + ' <br /> ' : '') + tooltip + '<br />' + (event.confirmed_status != '' ? event.confirmed_status : '') + '<br />' + (event.appointments_checkin ? event.appointments_checkin : '') : undefined,
    className: (0, _classnames.default)('rbc-event', className, userProps.className, {
      'rbc-selected': selected,
      'rbc-event-continues-earlier': continuesEarlier,
      'rbc-event-continues-later': continuesLater
    })
  }, inner));
}
var _default = TimeGridEvent;
exports.default = _default;
module.exports = exports["default"];