import React, { useEffect, useRef } from "react";
import cx from "clsx";
import { Link } from "react-router-dom";
import { Box } from "../../../shared/Box/Box";
import classes from "../newBusinessInsightsContent.module.scss";

const ReportList = ({ list, reports, expandedList, updateExpandedList }) => {
  const title = {
    sales: "Sales Reports",
    payments: "Payments Reports",
    memberships: "Memberships Reports",
  };

  const baseUrl = (href) => {
    return `/refactor-business-insight/sales/${href}`;
  };

  const listRef = useRef();

  useEffect(() => {
    updateExpandedList("sales");
  }, []);

  return (
    <div>
      <Box className={classes.expandParent}>
        <button
          onClick={() => updateExpandedList(list)}
          className={cx(classes.expandButton, "flex", "gap-8", "items-center")}
        >
          <span
            className={cx(
              classes.expandSign,
              expandedList[list] && classes.active,
            )}
          ></span>
          <span>{title[list]}</span>
        </button>
      </Box>
      <div
        className={cx(classes.expandedRow)}
        style={{
          maxHeight: `${
            expandedList[list] ? listRef?.current?.offsetHeight : 0
          }px`,
        }}
      >
        <ul ref={listRef}>
          {reports.map((report, index) => (
            <li key={index}>
              <Link to={baseUrl(report.href)}>{report.label}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ReportList;
