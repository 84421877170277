import React, { useRef, useState } from "react";
import { Modal } from "../../../../shared/Modal/Modal";
import { Select } from "../../../../shared/Select/Select";
import { Button } from "../../../../shared/Button/Button";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { DatePicker } from "../../../../shared/DatePicker/DatePicker";
import { API_DATE_FORMAT } from "../../../../consts/api";
import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../../consts/general";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { withRouter } from "react-router";
import { useAddDocumentExpirationDate } from "../../../../api/mutations/useAddDocumentExpirationDate";
import { uiNotification } from "../../../../services/UINotificationService";
import useDocumentTypeDefaultExpirationDate from "../../../../api/queries/useDocumentTypeDefaultExpirationDate";
import classes from "./expirationModal.module.scss";
import Loader from "../../../Common/Loader";

function ExpirationModal({
  isOpen,
  onClose,
  document, 
  patientId,
  patientName,
  refetchDocuments,
}) {
  const {
    date,
    name,
    type,
    documentId,
    expirationDate,
    label,
    editedByFirstName,
    editedByLastName,
    updateAt,
    parentID,  
    subType 
  } = document;

  const { data: user } = useCurrentUserQuery();

  const userFormatDate = (date) =>
    moment(date).format(user?.dateFormat || DEFAULT_DATE_FORMAT);

  const editedBy = editedByFirstName + " " + editedByLastName;
  const lastUpdatedParagraph =
    (editedByFirstName || editedByLastName) && updateAt;

  const [settedExpiryDate, setSettedExpiryDate] = useState(expirationDate);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const expirationDateRef = useRef(null);

  const { mutation, isAddLoading } = useAddDocumentExpirationDate();
  const { mutate, isLoading } = mutation;

  const { data: defaultExpirationDate, isLoading: isGettingDate } =
    useDocumentTypeDefaultExpirationDate(
      {
        documentType: type,
        createdDate: date,
      },
      {
        cacheTime: 0,
      },
    );

  const defaultTimers = [
    {
      value: moment(date).clone().add(1, "year").format(API_DATE_FORMAT),
      label: "1 Year",
    },
    {
      value: moment(date).clone().add(6, "months").format(API_DATE_FORMAT),
      label: "6 Months",
    },
    {
      value: moment(date).clone().add(90, "days").format(API_DATE_FORMAT),
      label: "90 Days",
    },
    {
      value: moment(date).clone().add(30, "days").format(API_DATE_FORMAT),
      label: "30 Days",
    },
    { value: "custom", label: "Custom" },
  ]; 

  const handleExpiryDate = (timer) => {
    if (timer.value === "custom") {
      setShowDatePicker(true);
    } else {
      setShowDatePicker(false);
      setSettedExpiryDate(timer.value);
    }
  };

  const handleConfirm = () => {
    mutate(
      {
        documentId,
        type,
        expiryDate: settedExpiryDate,
        patientId,
        parentID,  
        subType,
      },
      {
        onSuccess: () => {
          uiNotification.success(
            "Document expiration date has been set successfully!",
          );
          refetchDocuments();
          onClose();
        },
        onError: () => {
          uiNotification.error("Failed to set document expiration date!");
        },
      },
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      headerClassName={classes.modalHeader}
      contentClassName="p-b-30"
      footerNoBorder
      header={<Modal.Title>Document Expiration Settings</Modal.Title>}
      footer={
        <div className="flex justify-between items-baseline">
          <div className="flex justify-start">
            {lastUpdatedParagraph && (
              <span className={classes.spanUpdatedText}>
                Last updated on {userFormatDate(updateAt)} by {editedBy}
              </span>
            )}
          </div>
          <div className="flex justify-end gap-8">
            <Button
              onClick={handleConfirm}
              isDisabled={isLoading}
              leftAdornment={
                isAddLoading ? (
                  <CircularProgress size="small" color="white" />
                ) : undefined
              }
            >
              Confirm
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      }
      shouldCloseOnOverlayClick={Boolean(onClose)}
      withCloseBtn={Boolean(onClose)}
    >
      <div className="flex justify-center text-center m-b-40">
        <div>
          {settedExpiryDate ? (
            <span className="bold">
              Document Expires on {userFormatDate(settedExpiryDate)}
            </span>
          ) : (
            <span className="bold">Expiration Date Not Set</span>
          )}
        </div>
      </div>
      <div className={classes.container}>
        <div className="flex-col gap-16">
          <div className="flex-col">
            <span>File Type</span>
            <span className="bold">{label}</span>
          </div>
          <div className="flex-col">
            <span>Patient Name</span>
            <span className="bold">{patientName}</span>
          </div>
          <div className="flex-col">
            <span>File Name</span>
            <span className="bold">{name}</span>
          </div>
          <div className="flex-col">
            <div>
              Added On <p className="bold">{userFormatDate(date)}</p>
            </div>

            <div>
              Expires Date{" "}
              {settedExpiryDate ? (
                <p className="bold">{userFormatDate(settedExpiryDate)}</p>
              ) : (
                <p className="bold">Not Set</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex-col gap-16">
          <div className="flex-col gap-8">
            <span className="bold m-b-10">Change Expiration Date</span>
            {defaultExpirationDate?.data && (
              <div>
                <Button
                  className={classes.buttonDefaultExpiration}
                  onClick={() =>
                    setSettedExpiryDate(defaultExpirationDate?.data)
                  }
                >
                  <span className={classes.spanLabel}>
                    Default to {userFormatDate(defaultExpirationDate?.data)}
                  </span>
                </Button>
              </div>
            )}

            <div className="flex items-center gap-8 bold">
              <div className={classes.selectLabel}>
                <div>
                  <Select
                    size="small"
                    className={classes.selectCustomLabel}
                    onChange={(timer) => {
                      handleExpiryDate(timer);
                    }}
                    options={defaultTimers}
                    placeholder="Choose Expiration"
                    name="select-resource-type"
                    value={{
                      label: "Choose Expiration",
                      value: "Choose Expiration",
                    }}
                    whiteChevron
                  />
                </div>
                {showDatePicker && (
                  <div>
                    <DatePicker
                      className={classes.datePickerClass}
                      showMonthDropdown
                      showYearDropdown
                      ref={expirationDateRef}
                      onChangeRaw={(e) => e.preventDefault()}
                      minDate={new Date()}
                      onClickOutside={() =>
                        expirationDateRef.current.setOpen(false)
                      }
                      selected={
                        settedExpiryDate
                          ? moment(settedExpiryDate).toDate()
                          : moment().toDate()
                      }
                      value={
                        settedExpiryDate
                          ? userFormatDate(settedExpiryDate)
                          : userFormatDate()
                      }
                      onChange={(date) =>
                        setSettedExpiryDate(
                          moment(date).format(API_DATE_FORMAT),
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div>
              <Button
                className={classes.buttonDefaultExpiration}
                onClick={() => {
                  setShowDatePicker(false);
                  setSettedExpiryDate(null);
                }}
                isDisabled={!settedExpiryDate}
              >
                <span className={classes.spanLabel}>Remove Expiration</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Loader showLoader={isGettingDate} />
    </Modal>
  );
}

export default withRouter(ExpirationModal);
