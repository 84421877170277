import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, TWO_FACTOR_TYPES } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import { authWorkspaceSchema, composeAuthWorkspace } from "../../schemas/auth";

const requestSchema = yup.object({
  email: yup.string().required(),
  password: yup.string().required(),
});

const responseSchema = yup.object({
  wpuId: yup.number().required(),

  need2Fa: yup.bool().required(),

  alreadyLoggedIn: yup.bool().required(),

  accessToken: yup.string().nullable(),

  twoFactorType: yup
    .string()
    .test({
      test: (value) =>
        Object.values(TWO_FACTOR_TYPES).includes(value) || value === null,
    })
    .nullable(),

  googleAuthCode: yup.string().nullable(),

  workspaces: yup.array().of(authWorkspaceSchema).required(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    wpuId: data.wpuId,
    need2Fa: data["2fa"],
    alreadyLoggedIn: data.alreadyLoggedIn.web,
    accessToken: data["access-token"] ? data["access-token"].web : null,
    twoFactorType: data.type || null,
    googleAuthCode: data.googleAuthCode || null,
    workspaces: (data.workspaces || []).map(composeAuthWorkspace),
  };
};

export function useLoginMutation(options = {}) {
  return useMutation(async (payload) => {
    const dto = requestSchema.validateSync(payload, {
      strict: true,
    });
    const res = await http.post(HTTP_ENDPOINTS.postLogin(), {
      email: dto.email,
      password: dto.password,
    });
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
