import React, { Component } from "react";
import { toast } from "react-toastify";
import { formatBytes } from '../../Utils/services.js';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import { isFormSubmit } from '../../Utils/services.js';
import defLogo from '../../_legacy/images/upload.png';
import crossImg from '../../_legacy/images/close.png';

const nextChar = (value) => {
  value = String(value)
  return String.fromCharCode(value.charCodeAt(0) + 1);
}

// use fnction while uplaoding file
const getFileMimeType = (value) => {
  value = String (value);
  let fileType = '';
  if (["application/pdf"].indexOf(value) > -1 ) {
    fileType = 'pdf';
  } else if (["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/gif","image/GIF"].indexOf(value) > -1 ) {
    fileType = 'image';
  } else if(["application/excel", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-excel", "application/x-msexcel"].indexOf(value) > -1){
    fileType = 'xls';
  }  else if(["application/doc", "application/ms-doc", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"].indexOf(value) > -1){
    fileType = 'doc';
  }
  return fileType;
}

class ProcedureQuestionnaireHealthEdit extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.state = {
      globalLang: languageData.global,
      languageData: languageData.clients,
      questionnaireData: (props.questionnaireData) ? (props.questionnaireData) : null,
      templateQuestionList: (props.questionnaireData && props.questionnaireData.question_answer) ? (props.questionnaireData.question_answer) : [],

    }
  }

  resetFileState = (questionId) => {
    questionId = questionId || 0
    this.setState({
      uploadIndex: '',
      fileReader: {},
      file: '',
      target: '',
      file_name: '',
      file_thumbnail: '',
      file_size: ''
    });

    // reset input-field value (it's required for upload same file after removing)
    if (questionId) {
      var inputFiled = document.getElementById("image_questionnaire-" + questionId);
      if (inputFiled) {
        inputFiled.value = ''
      }
    }
  }

  removeUploadedFile = (index, questionId, event) => {
    let returnState = {};
    let templateQuestionList = this.state.templateQuestionList;
    templateQuestionList[index]['field_value'] = ''
    templateQuestionList[index]['file_thumbnail'] = ''
    templateQuestionList[index]['file_size'] = ''
    templateQuestionList[index]['download_path'] = ''
    templateQuestionList[index]['class_name'] = 'file-container file-upload-img';
    this.setState({ templateQuestionList: templateQuestionList });
    this.resetFileState(questionId)
  }

  handleUpload = (targetName) => {
    let uploadtype = 'procedure_image'
    const data = new FormData()
    data.append('file', this.state.file, this.state.file.name)
    let endpoint = process.env.REACT_APP_API_URL + `media/upload?upload_type=${uploadtype}`;

    axios.post(endpoint, data).then(res => {
      let templateQuestionList = this.state.templateQuestionList;
      let uploadIndex = this.state.uploadIndex
      templateQuestionList[uploadIndex]['field_value'] = res.data.data.file_name
      templateQuestionList[uploadIndex]['file_thumbnail'] = this.state.file_thumbnail
      templateQuestionList[uploadIndex]['file_size'] = this.state.file_size
      templateQuestionList[uploadIndex]['download_path'] = this.state.fileReader.result
      templateQuestionList[uploadIndex]['class_name'] = 'file-container file-upload-img';
      templateQuestionList[uploadIndex]['file_type'] = this.state.file_type
      this.setState({
        templateQuestionList: templateQuestionList,
        showLoader: false
      });
      this.resetFileState()
    }).catch(error => {
      toast.error(this.state.globalLang[error.response.data.message]);
      this.setState({ showLoader: false });
      this.resetFileState()
    })
  }

  handleFileRead = (e) => {
    const content = this.state.fileReader.result;
    let fileSize = formatBytes(this.state.file.size, 1)
    this.setState({ file_thumbnail: this.state.file.name, file_size: fileSize, file_src: this.state.fileReader.result, showLoader: true });
    this.handleUpload(this.state.target.name)

  }

  handleFileChosen = (event) => {
    const target = event.target;
    if (target && target.type === 'file') {          
      const allowedTypes = [
      "image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/gif", "image/GIF", 
      "application/excel", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/x-excel", "application/x-msexcel",
      "application/doc", "application/ms-doc", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf"
      ];
      if (target.files) {
        let  file_type = getFileMimeType(target.files[0].type);  
        if (file_type) {
          const file = target.files[0]
          this.state.fileReader = new FileReader();
          this.state.fileReader.onloadend = this.handleFileRead;
          this.state.fileReader.readAsDataURL(file);
          this.state.file = file
          this.state.target = target
          this.state.file_type = file_type
          this.state.uploadIndex = target.dataset.index
        } else {
          toast.error('This file type is not allowed');
        }
      } else {
        toast.error('This file type is not allowed');
      }
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = event.target.name

    const question = event.target.dataset.question;
    if (question) {
      const option = event.target.dataset.option;
      const index = event.target.dataset.index;
      let templateQuestionList = this.state.templateQuestionList;
      if (option == -1) {
        if (name.startsWith('Opinion_Scale_Why')) {
          templateQuestionList[index]['field_value_why'] = value
        } else {
          templateQuestionList[index]['field_value'] = value
        }
        templateQuestionList[index]['class_name'] = 'report-input'
        this.setState({ templateQuestionList: templateQuestionList })
      }
    } else {
      this.setState({ [event.target.name]: value, dataChanged: true });
    }


    if (name === 'procedure_template_id') {
      this.getProcedureTemplateData(value)

    }
    if (name === 'appointment_id') {
      this.getApptData(value)
    }
    if (name === 'user_id') {
      this.getAssociatedClinics(value)
    }
  }

  handleAnswerChange = (index, questionId, optionId, event) => {
    let templateQuestionList = this.state.templateQuestionList;
    switch (templateQuestionList[index]['question_type']) {
      case "Textbox":
      case "File Upload":
        break;
      case "Multiple Choice":
        let field_value = (templateQuestionList[index]['field_value']) ? templateQuestionList[index]['field_value'] : [];
        let existOrNot = field_value.indexOf(optionId);
        if (existOrNot > -1) {
          field_value.splice(existOrNot, 1);
        } else {
          field_value.push(optionId);
        }
        templateQuestionList[index]['field_value'] = field_value;
        templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer'
        break;
      case "Yes/No":
        templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer'
        templateQuestionList[index]['field_value'] = optionId;
        break;
      case "Single Choice":
        templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer'
        templateQuestionList[index]['field_value'] = optionId;
        break;
      case "Opinion Scale":
        templateQuestionList[index]['field_value'] = optionId;
        templateQuestionList[index]['class_name'] = 'survey-rate-outer'
        templateQuestionList[index]['display_step'] = (templateQuestionList[index]['why_choose'] === 1) ? 1 : 0
        break;
      default:
        break;
    }
    this.setState({ templateQuestionList: templateQuestionList })
  }



  handleBackNext = (index, questionId, buttonAction, event) => {
    let backQuestionId = 0
    let nextQuestionId = 0
    let templateQuestionList = this.state.templateQuestionList;
    let consultationFeeShow = this.state.consultationFeeShow;
    let displayedQuestionId = this.state.displayedQuestionId;

    // display back-question if back request comming from consultation_fee question
    if (index == -1 && consultationFeeShow === true && buttonAction === 'back') {
      backQuestionId = displayedQuestionId;
      templateQuestionList.map((obj, idx) => {
        if (obj.id == backQuestionId) {
          templateQuestionList[idx]['isShow'] = true
          displayedQuestionId = templateQuestionList[idx]['id'];
        } else {
          templateQuestionList[idx]['isShow'] = false
        }
      })
      this.setState({ templateQuestionList: templateQuestionList, consultationFeeShow: false, displayedQuestionId: displayedQuestionId })
      return
    }

    // checke input-field is required or not
    const field_value = templateQuestionList[index]['field_value'];
    if (templateQuestionList[index]['required'] === 1 && buttonAction === 'next' && (field_value == '' || field_value == null || field_value == undefined)) {
      // prevent to move next or back question if input-field is empty (when input-field is required)
      switch (templateQuestionList[index]['question_type']) {
        case "Textbox":
          templateQuestionList[index]['class_name'] = 'report-input field_error'
          break;
        case "Multiple Choice":
          templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer error'
          break;
        case "File Upload":
          templateQuestionList[index]['class_name'] = 'file-container file-upload-img field_error'
          break;
        case "Yes/No":
          templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer error'
          break;
        case "Single Choice":
          templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer error'
          break;
        case "Opinion Scale":
          templateQuestionList[index]['class_name'] = 'survey-rate-outer field_error'
          break;
        default:
          return null
          break;
      }
      this.setState({ templateQuestionList: templateQuestionList })
      return
    } else {
      switch (templateQuestionList[index]['question_type']) {
        case "Textbox":
          templateQuestionList[index]['class_name'] = 'report-input'
          break;
        case "Multiple Choice":
          templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer'
          break;
        case "File Upload":
          templateQuestionList[index]['class_name'] = 'file-container file-upload-img'
          break;
        case "Yes/No":
          templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer'
          break;
        case "Single Choice":
          templateQuestionList[index]['class_name'] = 'col-sm-12 multisel-outer'
          break;
        case "Opinion Scale":
          templateQuestionList[index]['class_name'] = 'survey-rate-outer'
          break;
        default:
          return null
          break;
      }
    }

    // fetch next-question-id
    if (buttonAction === 'next') {
      switch (templateQuestionList[index]['question_type']) {
        case "Textbox":
        case "Multiple Choice":
        case "File Upload":
          // get next question id (without logic jump)
          nextQuestionId = (templateQuestionList[index + 1] !== undefined) ? templateQuestionList[index + 1]['id'] : 0;
          break;
        case "Yes/No":
        case "Single Choice":
        case "Opinion Scale":
          if (templateQuestionList[index]['procedure_templates_logic'] !== undefined && templateQuestionList[index]['procedure_templates_logic'].length > 0) {

            // fetch logic-jump-question if set for selected option (value)
            let procedureTemplatesLogic = templateQuestionList[index]['procedure_templates_logic'].find(logicJump => logicJump.procedure_question_option_id == field_value)

            // fetch logic-jump-question if not set for selected option (value) or default next question-id
            if (procedureTemplatesLogic === undefined) {
              procedureTemplatesLogic = templateQuestionList[index]['procedure_templates_logic'].find(logicJump => logicJump.procedure_question_option_id == 0)
            }

            if (procedureTemplatesLogic  && procedureTemplatesLogic.jump_to_question && procedureTemplatesLogic.jump_to_question > 0 && (templateQuestionList.find(x => x.id === procedureTemplatesLogic.jump_to_question))) {
              // get next-question-id if logic jump found
              nextQuestionId = procedureTemplatesLogic.jump_to_question;
            } else {
              // get default next-question-id if logic jump not found
              nextQuestionId = (templateQuestionList[index + 1] !== undefined) ? templateQuestionList[index + 1]['id'] : 0;
            }
          } else {
            // get default next-question-id if logic jump not found
            nextQuestionId = (templateQuestionList[index + 1] !== undefined) ? templateQuestionList[index + 1]['id'] : 0;
          }
          if (templateQuestionList[index]['question_type'] === "Opinion Scale") {
            templateQuestionList[index]['display_step'] = 0;
          }
          break;
        default:
          break;
      }
    }
    // fetch back-question-id
    if (buttonAction === 'back') {
      const currentQuestion = templateQuestionList[index];
      // get default back-question-id from current question
      backQuestionId = currentQuestion.button_back;

      if (templateQuestionList[index]['question_type'] === "Opinion Scale") {
        if (templateQuestionList[index]['why_choose'] === 1 && templateQuestionList[index]['display_step'] === 1) {
          templateQuestionList[index]['display_step'] = 0;
          this.setState({ templateQuestionList: templateQuestionList })
          return
        }
      }
    }

    let isShow = false
    templateQuestionList.map((obj, idx) => {
      if (obj.id == questionId) { // hide current question
        templateQuestionList[idx]['isShow'] = false
      } else if (buttonAction === 'back' && obj.id == backQuestionId && backQuestionId != 0) { // display back question
        templateQuestionList[idx]['isShow'] = true
        displayedQuestionId = templateQuestionList[idx]['id'];
        consultationFeeShow = false
        isShow = true
      } else if (buttonAction === 'next' && obj.id == nextQuestionId && nextQuestionId != 0) { // display next question
        templateQuestionList[idx]['isShow'] = true
        // set back question for next question
        templateQuestionList[idx]['button_back'] = questionId
        displayedQuestionId = templateQuestionList[idx]['id'];
        consultationFeeShow = false
        isShow = true
      }
    })
    if (isShow === false && this.state.invoice_id == 0 && buttonAction === 'next') {
      consultationFeeShow = true
    }
    this.setState({ templateQuestionList: templateQuestionList, consultationFeeShow: consultationFeeShow, displayedQuestionId: displayedQuestionId })
  }



  handleSubmit = (event) => {
    event.preventDefault();
    if (isFormSubmit()) {
      let error = false;

      let formData = {
        patient_id: this.state.clientID
      }

      let templateQuestionList = this.state.templateQuestionList;

      // check last question is required or not and validation if required
      const displayedQuestionIndex = templateQuestionList.findIndex(x => x.id == this.state.displayedQuestionId);
      const displayedQuestion = (displayedQuestionIndex != -1) ? templateQuestionList[displayedQuestionIndex] : null;
      if (displayedQuestion) {
        const field_value = displayedQuestion.field_value;
        if (displayedQuestion.required === 1 && (field_value == '' || field_value == null || field_value == undefined)) {
          // prevent to move next or back question if input-field is empty (when input-field is required)
          switch (displayedQuestion.question_type) {
            case "Textbox":
              displayedQuestion.class_name = 'report-input field_error'
              break;
            case "Multiple Choice":
              displayedQuestion.class_name = 'col-sm-12 multisel-outer error'
              break;
            case "File Upload":
              displayedQuestion.class_name = 'file-container file-upload-img field_error'
              break;
            case "Yes/No":
              displayedQuestion.class_name = 'col-sm-12 multisel-outer error'
              break;
            case "Single Choice":
              displayedQuestion.class_name = 'col-sm-12 multisel-outer error'
              break;
            case "Opinion Scale":
              displayedQuestion.class_name = 'survey-rate-outer field_error'
              break;
            default:
              return null
              break;
          }
          templateQuestionList[displayedQuestionIndex] = displayedQuestion
          this.setState({ templateQuestionList: templateQuestionList })
          return false
        }
      }

      let isShow = false;
      let questions_data = []
      templateQuestionList.map((obj, index) => {
        let answer = ''
        if (templateQuestionList[index]['field_value']) {
          switch (obj['question_type']) {
            case "Textbox":
            case "File Upload":
              answer = templateQuestionList[index]['field_value'];
              break;
            case "Multiple Choice":
              answer = []
              templateQuestionList[index]['field_value'].map((obj1, idx) => {
                const tempAnswer = obj.procedure_template_question_option.find(x => x.id == templateQuestionList[index]['field_value'][idx]);
                if (tempAnswer) {
                  answer.push(tempAnswer.question_option);
                }
              })
              break;
            case "Yes/No":
            case "Single Choice":
            case "Opinion Scale":
              const tempAnswer = obj.procedure_template_question_option.find(x => x.id == templateQuestionList[index]['field_value']);
              if (tempAnswer) {
                answer = tempAnswer.question_option;
              }
              break;
            default:
              break;
          }
        }

        questions_data.push({
          question_id: templateQuestionList[index]['id'],
          quest_type: templateQuestionList[index]['question_type'],
          answers: answer, //templateQuestionList[index]['field_value'],
          why_choose: (templateQuestionList[index]['why_choose']) ? true : false,
          comment: (templateQuestionList[index]['why_choose']) ? templateQuestionList[index]['field_value_why'] : '',
          required: 0,//obj.required,
        })
      })

      formData.user_id = this.props.clientID
      formData.patient_id = this.props.clientID
      formData.procedure_id = this.props.procedureID
      formData.appointment_id = this.props.appointmentID
      formData.procedure_template_id = this.props.questionnaireID
      formData.consultation_fee = (this.state.consultation_fee) ? this.state.consultation_fee : 0.00
      formData.questions = questions_data
      //this.setState({showLoader:true})
      this.props.saveQuestionnaire(formData)
    }
    return false
  }

  sendBackToOldLink = () => {
    this.props.handleDismissQuestionnaire()
  }

  renderInputFields = (questionObj, questionIdx) => {
    let returnHtml = '';
    let serialCount = 'A';
    switch (questionObj.question_type) {
      case "Textbox":
        returnHtml = (
          <input data-index={questionIdx} data-question={questionObj.id} data-option={-1} className={questionObj.class_name} value={questionObj.field_value} name={questionObj.field_name} type="text" onChange={this.handleInputChange} placeholder="Write your answer here..." autoComplete="off" />
        )
        break;
      case "Multiple Choice":
        returnHtml = (
          <div className={questionObj.class_name}>
            {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
              questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                if (optionIdx !== 0) {
                  serialCount = nextChar(serialCount)
                }
                return (
                  <div key={'templateQuestionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value.indexOf(optionObj.id) > -1) ? "multisel activated" : "multisel"} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)}>
                    <input data-index={questionIdx} data-question={questionObj.id} data-option={optionObj.id} className="servy-checkbox servy-radio preboxval" value={optionObj.id} name={questionObj.field_name} type="checkbox" onChange={this.handleInputChange} />
                    <span className="multi-sel-alpha">{serialCount}</span>
                    <label className="multisel-label" htmlFor={"Multiple_Choice_" + questionObj.id}>{optionObj.question_option}</label>
                    <i className="fa fa-check" />
                  </div>
                )
              })
            }
          </div>
        )
        break;
      case "File Upload":
        returnHtml = (
          <div className="row add-doc-section">
            <div className="col-xs-6 m-b-10">
              <div className={questionObj.class_name} title={(questionObj.field_value) ? questionObj.field_value : ''}>
                {(questionObj.field_value) && <a className="delete-file" onClick={this.removeUploadedFile.bind(this, questionIdx, questionObj.id)} ></a>}
                <img
                  className={(questionObj.field_value) ? "full-custom-img" : ""}
                  src={(questionObj.field_value && questionObj.file_type && questionObj.file_type == 'image') ? questionObj.download_path : defLogo}
                />
                <span className={(questionObj.field_value) ? "file-name file-info" : "file-name-hide no-display"}> {questionObj.field_value}</span>
                <span className={(questionObj.field_value) ? "file-size file-info" : "file-size-hide no-display"}>{questionObj.file_size}</span>
                <div className="upload">{(questionObj.field_value) ? 'Uploaded' : this.state.globalLang.global_upload}
                  <input data-index={questionIdx} data-question={questionObj.id} data-option={-1} type="file" className={'image_questionnaire'} name="file" autoComplete="off" onChange={this.handleFileChosen} title={(questionObj.field_value) ? '' : 'No file chosen'} id={'image_questionnaire-' + questionObj.id} />
                </div>
              </div>
            </div>            
            <p className="p-text m-b-20 col-xs-12">Allowed file types: Images, PDF documents, Excel and Word documents</p>
          </div>
        )
        break;
      case "Yes/No":
        returnHtml = (
          <div className={questionObj.class_name}>
            {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
              questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                if (optionIdx !== 0) {
                  serialCount = nextChar(serialCount)
                }
                return (
                  <div key={'templateQuestionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value == optionObj.id) ? "multisel activated" : "multisel"} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)}>
                    <input data-index={questionIdx} data-question={questionObj.id} data-option={optionObj.id} className="servy-checkbox servy-radio preboxval" value={optionObj.id} name={questionObj.field_name} type="radio" onChange={this.handleInputChange} />
                    <span className="multi-sel-alpha">{serialCount}</span>
                    <label className="multisel-label" htmlFor={"Yes_No_" + questionObj.id}>{optionObj.question_option}</label>
                    <i className="fa fa-check" />
                  </div>
                )
              })
            }
          </div>
        )
        break;
      case "Single Choice":
        returnHtml = (
          <div className={questionObj.class_name}>
            {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
              questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                if (optionIdx !== 0) {
                  serialCount = nextChar(serialCount)
                }
                return (
                  <div key={'templateQuestionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value == optionObj.id) ? "multisel activated" : "multisel"} data-index={questionIdx} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)}>
                    <input data-index={questionIdx} data-question={questionObj.id} data-option={optionObj.id} className="servy-checkbox servy-radio preboxval" value={optionObj.id} name={questionObj.field_name} type="radio" onChange={this.handleInputChange} />
                    <span className="multi-sel-alpha">{serialCount}</span>
                    <label className="multisel-label" htmlFor={"Single_Choice_" + questionObj.id}>{optionObj.question_option}</label>
                    <i className="fa fa-check" />
                  </div>
                )
              })
            }
          </div>
        )
        break;
      case "Opinion Scale":
        returnHtml = (
          <div className="col-sm-12 opinion-scale">
            {(questionObj.why_choose === 1 && questionObj.display_step === 1)
              ?
              <input data-index={questionIdx} data-question={questionObj.id} data-option={-1} className="report-input" value={questionObj.field_value_why} name={questionObj.field_name_why} type="text" onChange={this.handleInputChange} placeholder="Write your answer here..." autoComplete="off" />
              :
              <div>
                <ul className={questionObj.class_name}>
                  {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
                    questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                      return (
                        <li key={'templateQuestionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value == optionObj.id) ? "survey-li preboxval survey-li-active" : "survey-li preboxval"} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)} >{optionObj.question_option}</li>
                      )
                    })
                  }
                </ul>
                <div className={'likeornot m-b-20'}><span>{this.state.languageData.pro_not_likely}</span> <span className="pull-right">{this.state.languageData.pro_likely}</span></div>
              </div>
            }
          </div>
        )
        break;
      default:
        return null
        break;
    }
    return returnHtml;
  }




  render() {
    let templateQuestionList = '';
    if (this.state.templateQuestionList && this.state.templateQuestionList.length > 0) {
      templateQuestionList = this.state.templateQuestionList.map((questionObj, questionIdx) => {
        return (
          <div key={'templateQuestionList-' + questionObj.id} id={"question-outer-" + questionObj.id} className="report-question-outer" style={{ 'display': (questionObj.isShow) ? 'block' : 'none' }}>
            {(questionObj.question_type === "Opinion Scale" && questionObj.why_choose === 1 && questionObj.display_step === 1) ?
              <div className="survey-title no-margin" id={"question-" + questionObj.id}>{this.state.languageData.pro_why_did_choose}</div>
              :
              <div>
                <div className="survey-title no-margin" id={"question-" + questionObj.id}>{questionObj.question}{(questionObj.required == 1) && <span className="setting-require font-size-16">*</span>}</div>
                {(questionObj.description == 1) &&
                  <div className="quest-discription" id={"question-description-" + questionObj.id}> {questionObj.description_text}</div>
                }
              </div>
            }
            {this.renderInputFields(questionObj, questionIdx)}
            {(questionIdx !== 0) &&
              <button id={"back_ok-" + questionObj.id} className="back-ok m-r-20" onClick={this.handleBackNext.bind(this, questionIdx, questionObj.id, 'back')}>{this.state.languageData.pro_back}<i className="fa fa-arrow-left"></i></button>
            }
            <button id={"next-save-ok" + questionObj.id} className="report-btn" onClick={(this.state.templateQuestionList[questionIdx + 1] === undefined) ? this.handleSubmit : this.handleBackNext.bind(this, questionIdx, questionObj.id, 'next')} style={{ 'display': (questionObj.question_type === "Opinion Scale" && questionObj.why_choose === 1 && questionObj.display_step === 0 && questionObj.required === 1) ? 'none' : 'block' }}>{(this.state.invoice_id != 0 && this.state.templateQuestionList[questionIdx + 1] === undefined) ? this.state.globalLang.label_save : 'Next'}<i className="fa fa-check" /></button>

          </div>
        )
      })
    }
    return (
      <React.Fragment>
        <div className="container-fluid content setting-wrapper">
          <div className="juvly-section full-width m-t-15 proQuesAccordionOuter">
            <div className="proQuesAccordionTitle">Edit Questionnaire {(this.props.questionnaireData && this.props.questionnaireData.title) ? ' - ' + this.props.questionnaireData.title : ''}
              <a className="pull-right" onClick={this.props.handleDismissQuestionnaire}><img src={crossImg} /></a>
            </div>
            <div className="juvly-container">




              <div className="half-preview healthtimeline" id="half-preview -12">
                {templateQuestionList}

              </div>


            </div>
          </div>
        </div>
        <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
          <div className="loader-outer">
            <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
            <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default ProcedureQuestionnaireHealthEdit;
