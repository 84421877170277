import Intercom, {
  shutdown as intercomShutdown,
} from "@intercom/messenger-js-sdk";
import { THEME } from "../consts/theme";

class IntercomService {
  #color = THEME.colors.primary;

  boot({ appId, userId, name, email, createdAt }) {
    const config = {
      app_id: appId,
      user_id: userId,
      name,
      email,
      created_at: createdAt,
      action_color: this.#color,
    };

    Intercom(config);
  }

  shutdown() {
    intercomShutdown();
  }
}

export const intercomService = new IntercomService();
