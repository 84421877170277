import React from "react";
import PhoneInput from "react-phone-input-2";
import * as yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import { withRouter } from "react-router";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { tCommon, tSales } from "../../../../../i18n/useAppTranslation";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useChargeForm } from "../../hooks/useChargeForm";
import { Input } from "../../../../../shared/Input/Input";
import "react-phone-input-2/lib/style.css";
import classes from "../ViewChargeKlarna/ViewChargeKlarna.module.scss";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { InputError } from "../../../../../shared/InputError/InputError";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { uiNotification } from "../../../../../services/UINotificationService";
import { svg } from "../../../../../assets/svg";

const ViewChargeText2Pay = (props) => {
  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };
  const { invoice, amountToPay } = useInvoice();
  const { chargeText2Pay } = useInvoiceActions();

  const goToInvoice = () => {
    const invoiceId = invoice?.id;
    const patientId = invoice?.patient?.id;

    props.history.push(`/sales/invoice/${invoiceId}/${patientId}/invoices`);
  };

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    phoneNumber: yup
      .string()
      .required(tSales("checkoutInvoice.formError.phoneNumberRequired"))
      .test(
        "valid-phone-number",
        tSales("checkoutInvoice.formError.validPhoneNumberRequired"),
        function (value) {
          return isValidPhoneNumber(
            value || "",
            this.parent.country?.countryCode.toUpperCase(),
          );
        },
      ),
    email: yup
      .string()
      .email(tCommon("formError.emailInvalid"))
      .required(tSales("checkoutInvoice.formError.emailRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount, email, phoneNumber }) => {
        chargeText2Pay
          .initiate({ amount, email, phoneNumber })
          .then((response) => {
            uiNotification.success(response.data.message);
            goToInvoice();
          })
          .catch((e) => {
            uiNotification.error(e.response.data.message);
          })
          .finally(() => {
            onClose();
          });
      },
      initialValues: {
        amount: amountToPay || "",
        phoneNumber: invoice?.patient?.phoneNumber || "",
        email: invoice?.patient?.email || "",
      },
    });

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.text2pay")}
      imageSrc={svg.text2Pay}
      onCharge={submit}
      isCharging={chargeText2Pay.isLoading}
      isChargeDisabled={!isValid}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={amountToPay}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          isDisabled
        />
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <InputLabel>{tSales("checkoutInvoice.formInputLabel.mail")}</InputLabel>
        <Input
          type="email"
          size="medium"
          value={form.email}
          isError={hasError("email")}
          onChange={(e) => changeFormValue("email", e.target.value)}
          placeholder={tCommon("formPlaceholder.email")}
          isDisabled={invoice?.patient?.email}
        />
        {errors.email && <InputError>{errors.email}</InputError>}
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.phoneNumber")}
        </InputLabel>
        <PhoneInput
          size="medium"
          value={form.phoneNumber}
          isError={hasError("phoneNumber")}
          country={"us"}
          countryCodeEditable={false}
          enableSearch={true}
          className={classes.phoneKlarna + " klarna-tel-input"}
          isValid={() => Boolean(!errors.phoneNumber)}
          onChange={(phone, country, event, formattedPhone) => {
            changeFormValue("country", country);
            changeFormValue("phoneNumber", formattedPhone);
          }}
        />
        {errors.phoneNumber && <InputError>{errors.phoneNumber}</InputError>}
      </ModalCharge.InputWrap>
    </ModalCharge>
  );
};

export default withRouter(ViewChargeText2Pay);
