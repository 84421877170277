import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { Trans } from "react-i18next";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { useGiftCardActions } from "../../../hooks/useGiftCardActions";
import { useChargeForm } from "../../../hooks/useChargeForm";
import { dispatch } from "../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../store/checkoutInvoice";
import { InputError } from "../../../../../../shared/InputError/InputError";
import { composeAmountSchema } from "../../../SalesCheckoutInvoice.schemas";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { InputCurrency } from "../../../../../../shared/InputCurrency/InputCurrency";
import { formatCurrency } from "../../../../../../utilities/general";
import classes from "./ApplyGiftAmount.module.scss";

export function ApplyGiftAmount({ giftCard }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { applyGiftCard } = useGiftCardActions();
  const { invoice, amountToPay } = useInvoice();

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const maxPaymentAmount =
    giftCard.availableAmount >= amountToPay
      ? amountToPay
      : giftCard.availableAmount;

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: maxPaymentAmount,
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        applyGiftCard
          .initiate({
            redeemAmount: amount,
            giftCardId: giftCard.id,
            redemptionCode: giftCard.redemptionCode,
            giftCardAvailableAmount: giftCard.availableAmount,
          })
          .then(onClose);
      },
      initialValues: {
        amount: maxPaymentAmount,
      },
    });

  return (
    <div>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <div className={classes.giftAmount}>
          <Trans
            i18nKey={tSales("checkoutInvoice.giftCard.availableAmount")}
            values={{
              amount: formatCurrency(
                giftCard.availableAmount,
                invoice?.currency,
              ),
            }}
            components={{
              b: <b />,
            }}
          />
          <br />
          <br />
          <Trans i18nKey={tSales("checkoutInvoice.giftCard.consumerNotice")} />
        </div>
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={onClose}
        isCharging={applyGiftCard.isLoading}
        onCharge={submit}
        chargeLabel={tCommon("label.redeem")}
        isChargeDisabled={!isValid || applyGiftCard.isLoading}
      />
    </div>
  );
}

ApplyGiftAmount.propTypes = {
  giftCard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    availableAmount: PropTypes.number.isRequired,
    redemptionCode: PropTypes.string.isRequired,
  }).isRequired,
};
