import React, { useEffect } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { Modal } from "../../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { img } from "../../../../../assets/img";
import classes from "./ViewPaymentOptions.module.scss";
import {
  PAYMENT_OPTIONS,
  PAYMENT_VIEW_TYPES,
} from "../../../../../store/checkoutInvoice/consts";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import { USER_PAYMENT_SYSTEMS } from "../../../../../consts/api";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { isNullish } from "../../../../../utilities/general";
import { useCherry } from "../../hooks/useCherry";
import { fetchIsKlarnaAvailable } from "../../../../../Actions/Sales/klarnaActions";

function ViewPaymentOptions({ fetchIsKlarnaAvailable, isKlarnaAvailable }) {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, isGuest } = useInvoice();
  const { data: user, isLoading: isUserLoading } = useCurrentUserQuery();
  const { isCherryAvailable, isCherryLoading } = useCherry();

  const isKlarna = () =>
    isKlarnaAvailable.status === "success" &&
    isKlarnaAvailable.data.isKlarnaAvailable;

  useEffect(() => {
    fetchIsKlarnaAvailable(invoice.id);
  }, []);

  const changeViewType = (type) => {
    dispatch(
      checkoutInvoice.actions.paymentViewChange({
        paymentView: type,
      }),
    );
  };

  const cardOnFile = invoice?.patient?.cardOnFiles?.[0]?.number || null;

  const isArWalletEmpty =
    isNullish(invoice?.patient?.walletBalance) ||
    invoice?.patient?.walletBalance <= 0;

  const isTotalMount = invoice?.totalAmount <= 0;

  const options = [
    {
      key: PAYMENT_OPTIONS.card,
      img: svg.ccPrimary,
      isAvailable: !isTotalMount,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.card),
      rightAdornment:
        user?.account?.paymentSystem !== USER_PAYMENT_SYSTEMS.stripe
          ? cardOnFile
          : null,
    },
    {
      key: PAYMENT_OPTIONS.cash,
      img: svg.cashPrimary,
      isAvailable: true,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.cash),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.arWallet,
      img: svg.arWalletPrimary,
      isAvailable: !isArWalletEmpty && !isTotalMount && !isGuest,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.arWallet),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.cherry,
      img: svg.cherryPay,
      isAvailable: isCherryAvailable && !isTotalMount,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.cherry),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.klarna,
      img: img.klarna,
      isAvailable: isKlarna() && !isTotalMount,
      isHidden: user?.account?.paymentSystem !== USER_PAYMENT_SYSTEMS.stripe,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.klarna),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.careCredit,
      img: img.careCredit,
      isAvailable: !isTotalMount,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.careCredit),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.greenSky,
      img: img.greenSky,
      isAvailable: !isTotalMount,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.greenSky),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.check,
      img: svg.checkPayPrimary,
      isAvailable: !isTotalMount,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.check),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.giftCard,
      img: svg.giftCardPrimary,
      isAvailable: !isTotalMount,
      isHidden: false,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.giftCard),
      rightAdornment: null,
    },
    {
      key: PAYMENT_OPTIONS.text2pay,
      img: svg.text2Pay,
      isAvailable: !isTotalMount && invoice.status === "draft" && !isGuest,
      isHidden: user?.account?.paymentSystem !== USER_PAYMENT_SYSTEMS.stripe,
      onClick: () => changeViewType(PAYMENT_VIEW_TYPES.text2pay),
      rightAdornment: null,
    },
  ].filter((i) => !i.isHidden);
  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const isLoading = isCherryLoading || isUserLoading;

  return (
    <Modal
      headerNoBorder
      isOpen
      onClose={onClose}
      header={<Modal.Title>{tCommon("label.paymentOptions")}</Modal.Title>}
      contentClassName={classes.root}
      className={classes.modal}
    >
      {isLoading ? (
        <Skeleton count={options.length} height={64} borderRadius="2px" />
      ) : (
        options.map((option) => (
          <button
            key={option.key}
            disabled={!option.isAvailable}
            onClick={option.onClick}
            className={cx(classes.option, {
              [classes.optionDisabled]: !option.isAvailable,
            })}
          >
            <div className={classes.imgBox}>
              <img src={option.img} alt="" />
            </div>
            <div className={classes.optionInfo}>
              <div className={classes.optionLabel}>
                {tSales(`checkoutInvoice.paymentOptions.${option.key}`)}
              </div>
              <div className={classes.optionRight}>
                {option.isAvailable && option.rightAdornment}
                {option.isAvailable ? (
                  <img src={svg.chevronRightGray} alt="" />
                ) : (
                  tCommon("label.unavailable")
                )}
              </div>
            </div>
          </button>
        ))
      )}
    </Modal>
  );
}

ViewPaymentOptions.propTypes = {
  fetchIsKlarnaAvailable: PropTypes.func,
  isKlarnaAvailable: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isKlarnaAvailable: state.KlarnaReducer.isKlarnaAvailable,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ fetchIsKlarnaAvailable }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ViewPaymentOptions));
