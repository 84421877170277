import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import { Header } from "./components/Header";
import classes from "./sass/Picker.module.scss";
import { usePicker } from "./hooks/usePicker";
import { useWindowSize } from "../../../utilities/hooks/useWindowSize";
import { MOBILE_BREAKPOINT } from "./Picker.consts";
import { Category } from "./components/Category";
import { Skeleton } from "../../../shared/Skeleton/Skeleton";
import { Modifiers } from "./components/Modifiers";
import { useModifiers } from "./hooks/useModifiers";
import { serviceCategoryPropType } from "./Picker.types";
import { useAppTranslation } from "../../../i18n/useAppTranslation";

export function Picker({
  pickedIds,
  onChange,
  contentClassName,
  servicesByCategory,
  isServicesLoading,
}) {
  const windowSize = useWindowSize();
  const { tCommon } = useAppTranslation.Common();

  const picker = usePicker({
    pickedIds,
    onChange,
    servicesByCategory,
  });

  const { modifiedData, search, filter } = useModifiers({
    servicesByCategory,
    pickedServiceIds: picker.pickedServiceIds,
  });

  return (
    <div>
      <Modifiers search={search} filter={filter} />
      {windowSize.width > MOBILE_BREAKPOINT && <Header />}
      {isServicesLoading && (
        <Skeleton count={10} containerClassName={classes.content} height={23} />
      )}
      {!isServicesLoading && (
        <div className={cx(classes.content, contentClassName)}>
          {modifiedData.map((category) => (
            <Category key={category.id} picker={picker} category={category} />
          ))}
          {modifiedData.length === 0 && (
            <div className={classes.empty}>{tCommon("info.nothingFound")}</div>
          )}
        </div>
      )}
    </div>
  );
}

Picker.propTypes = {
  pickedIds: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  contentClassName: PropTypes.string,
  servicesByCategory: PropTypes.arrayOf(serviceCategoryPropType),
  isServicesLoading: PropTypes.bool,
};

Picker.defaultProps = {
  pickedIds: [],
  contentClassName: undefined,
  servicesByCategory: [],
  isServicesLoading: false,
};
