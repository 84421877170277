import React from "react";
import { ToastContainer } from "react-toastify";
import { passOr } from "../../utilities/general";
import { ROUTES } from "../../consts/routes";

const MESSAGE_SIZE = {
  default: "default",
  big: "big",
};

export const Notifications = () => {
  const toastMessageType = window.location.pathname.startsWith(
    ROUTES.accountSetup(""),
  )
    ? MESSAGE_SIZE.big
    : MESSAGE_SIZE.default;

  return (
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
      style={passOr(toastMessageType === MESSAGE_SIZE.big, undefined, () => ({
        fontSize: 19,
      }))}
    />
  );
};
