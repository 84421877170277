import axios from "axios";
import { setToken, setConfigData } from "../Utils/services.js";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const signinInstance = axios.create();

signinInstance.interceptors.response.use(
  function (response) {
    if (response.headers.access_token) {
      setToken(response.headers.access_token);
    }

    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    return response;
  },
  function (error) {
    if (error.response.data.status === 602) {
      localStorage.setItem("blockIP", 1);
      localStorage.removeItem("showRecaptcha");
      window.location.href = "/block-ip";
    }

    return Promise.reject(error);
  },
);

signinInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function doAutoLogin(cipeher) {
  return (dispatch) => {
    signinInstance
      .get(process.env.REACT_APP_API_URL + "switch-to-new/" + cipeher)
      .then((response) => {
        dispatch({ type: "DO_AUTO_LOGIN", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "DO_AUTO_LOGIN", payload: error.response.data });
        }
      });
  };
}

export function AvailableJobs(formData) {
  return (dispatch) => {
    signinInstance
      .get(process.env.REACT_APP_API_URL + "available-jobs", formData)
      .then((response) => {
        dispatch({ type: "AVAILABLE_JOBS_SIMULATOR", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "AVAILABLE_JOBS_SIMULATOR",
            payload: error.response.data,
          });
        }
      });
  };
}

export function RunJob(formData) {
  return async (dispatch) => {
    return signinInstance
      .post(process.env.REACT_APP_API_URL + "run-job", formData)
      .then((response) => {
        dispatch({ type: "RUN_JOB_RESPONSE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "RUN_JOB_RESPONSE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
