import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

positionFooterCorrectly();

const dashInstance = axios.create();

dashInstance.defaults.headers.common["access-token"] = getToken();

dashInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

dashInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getUserLogs(formData) {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "user-logs",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "USER_LOGS_LIST", payload: response.data });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({ type: "USER_LOGS_LIST", payload: error.response.data });
      });
  };
}

export function fetchSMSNotifications(formData) {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/sms-notifications",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SMS_NOTIFICATION_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SMS_NOTIFICATION_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function fetchNotifications(formData) {
  return async (dispatch) => {
    return dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/notifications",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "NOTIFICATION_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "NOTIFICATION_LIST", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function deleteNotifications(userId) {
  return async (dispatch) => {
    return dashInstance
      .delete(
        process.env.REACT_APP_API_URL + "dashboard/notifications/" + userId,
      )
      .then((response) => {
        dispatch({ type: "DELETE_NOTIFICATION_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_NOTIFICATION_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function exportCsv(formData) {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "user-logs/export",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "EXPORT_CSV", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EXPORT_CSV", payload: error.response.data });
      });
  };
}
export function viewChanges(objectType, childId, objectId) {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL +
          "user-logs/view-changes/" +
          objectType +
          "/" +
          childId +
          "/" +
          objectId,
      )
      .then((response) => {
        dispatch({ type: "VIEW_CHANGES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_CHANGES", payload: error.response.data });
      });
  };
}

export const fetchSMSNotificationsPopups = (smsId) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/sms-notifications/" + smsId,
      )
      .then((response) => {
        dispatch({ type: "SELECTED_SMS_POPUPS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SELECTED_SMS_POPUPS", payload: error.response.data });
      });
  };
};

export const fetchNotificationsPopupsMenu = () => {
  return (dispatch, getState) => {
    const isInProgress =
      getState().DashboardReducer?.isSmsNotificationsFetching || false;
    if (!isInProgress) {
      dispatch({ type: "FETCH_POPUPS_MENU_START" });
      dashInstance
        .get(process.env.REACT_APP_API_URL + "dashboard/header-notifications")
        .then((response) => {
          dispatch({ type: "FETCH_POPUPS_MENU", payload: response.data });
        })
        .catch((error) => {
          dispatch({ type: "FETCH_POPUPS_MENU", payload: error.response.data });
        })
        .finally(() => {
          dispatch({ type: "FETCH_POPUPS_MENU_FINISH" });
        });
    }
  };
};

export const createReply = (formData, smsId) => {
  return (dispatch) => {
    dashInstance
      .post(
        process.env.REACT_APP_API_URL + "dashboard/sms-reply",
        formData ? formData : "",
      )
      .then((response) => {
        let previousMessage = response.data.message;
        let previousStatus = response.data.status;

        dashInstance
          .get(
            process.env.REACT_APP_API_URL +
              "dashboard/sms-notifications/" +
              smsId,
          )
          .then((responseNew) => {
            responseNew.data.message = previousMessage;
            responseNew.data.status = previousStatus;
            dispatch({ type: "CREATE_REPLY", payload: responseNew.data });
          })
          .catch((error) => {
            dispatch({ type: "CREATE_REPLY", payload: error.response.data });
          });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_REPLY", payload: error.response.data });
      });
  };
};

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export const fetchwidgetsData = () => {
  return async (dispatch) => {
    return dashInstance
      .get(process.env.REACT_APP_API_URL + "get_dashboard_active_widgets")
      .then((response) => {
        dispatch({ type: "DASHBOARD_WIDGET_LIST", payload: response.data });
        dispatch(exportEmptyData({}));
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DASHBOARD_WIDGET_LIST",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const submitSelectedIds = (formData) => {
  return async (dispatch) => {
    return dashInstance
      .post(
        process.env.REACT_APP_API_URL + "save_dashboard_settings_by_user",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SELECTED_WIDGETS_IDS", payload: response.data });
        dispatch(exportEmptyData({}));
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_WIDGETS_IDS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const fetchProviderSalesData = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/sales_by_provider",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "DASHBOARD_PROVIDER_SALES_DATA",
          payload: response.data,
        });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "DASHBOARD_PROVIDER_SALES_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const fetchProviderSalesGoalsData = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/provider_sales_goals",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "DASHBOARD_PROVIDER_GOALS_DATA",
          payload: response.data,
        });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "DASHBOARD_PROVIDER_GOALS_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const fetchProductSalesData = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/sales_by_product",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "DASHBOARD_PRODUCT_SALES_DATA",
          payload: response.data,
        });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "DASHBOARD_PRODUCT_SALES_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const fetchProviderNpsScores = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/nps_scores_by_provider",
        formData,
      )
      .then((response) => {
        dispatch({ type: "PROVIDER_NPS_SCORES", payload: response.data });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({ type: "PROVIDER_NPS_SCORES", payload: error.response.data });
      });
  };
};

export const fetchClinicNpsScores = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/nps_scores_by_clinic",
        formData,
      )
      .then((response) => {
        dispatch({ type: "CLINIC_NPS_SCORES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLINIC_NPS_SCORES", payload: error.response.data });
      });
  };
};

export const fetchOfficeSalesGoals = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/office_sales_goals",
        formData,
      )
      .then((response) => {
        dispatch({ type: "OFFICE_SALES_GOALS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "OFFICE_SALES_GOALS", payload: error.response.data });
      });
  };
};

export const fetchCProcedureGoals = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/provider_procedure_goals",
        formData,
      )
      .then((response) => {
        dispatch({ type: "PROCEDURE_GOALS_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PROCEDURE_GOALS_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const fetchMostUsedDiscounts = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL + "dashboard/most_used_discounts",
        formData,
      )
      .then((response) => {
        dispatch({ type: "MOST_USED_DISCOUNTS_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MOST_USED_DISCOUNTS_DATA",
          payload: error.response.data,
        });
      });
  };
};

export const fetchbookingPerClinic = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL +
          "dashboard/booking_percentage_per_clinics",
        formData,
      )
      .then((response) => {
        dispatch({ type: "BOOKING_PER_CLINIC", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "BOOKING_PER_CLINIC", payload: error.response.data });
      });
  };
};

export const fetchBookingPerProvider = (formData) => {
  return (dispatch) => {
    dashInstance
      .get(
        process.env.REACT_APP_API_URL +
          "dashboard/booking_percentage_per_provider",
        formData,
      )
      .then((response) => {
        dispatch({ type: "BOOKING_PER_PROVIDER", payload: response.data });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "BOOKING_PER_PROVIDER",
          payload: error.response.data,
        });
      });
  };
};
