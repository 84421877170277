import React, { useMemo } from "react";
import classes from "./sass/drawdaystepper.module.scss";
import cx from "clsx";
import { numberFormat, showFormattedDate } from "../../Utils";
import Tooltip from "react-tooltip";

const DrawDayStepper = ({ idx, item, timelineData, size = "small" }) => {
  const rootClasses = cx(classes.stepperContainer, {
    [classes.stepperLarge]: size === "large",
  });
  function monthInterval(idx) {
    if (idx) {
      const date1 = new Date(timelineData?.[idx - 1]?.payment_date);
      const date2 = new Date(timelineData?.[idx]?.payment_date);
      let months = 0;
      if (date1 && date2) {
        months = (date2.getFullYear() - date1.getFullYear()) * 12;
        months -= date1.getMonth();
        months += date2.getMonth();
      }
      return months <= 1 ? 0 : months;
    }
  }

  const showIdx = useMemo(() => {
    if (idx) {
      if (monthInterval(idx)) {
        return idx + monthInterval(idx);
      } else {
        return idx + 1;
      }
    } else {
      return 1;
    }
  }, [idx, monthInterval]);

  const isShowDashline = useMemo(
    () => Boolean(timelineData?.length - 1 === idx || monthInterval(idx + 1)),
    [idx, timelineData, monthInterval],
  );

  return (
    <div className={rootClasses}>
      <div
        className={cx(
          classes.stepperLine,
          isShowDashline ? classes.stepperLastLine : classes.stepperLineSolid,
        )}
      >
        {showFormattedDate(item.payment_date)}
        <div className={classes.stepperCircle}>{showIdx}</div>
      </div>

      <div className={classes.stepperContent}>
        {Boolean(item.one_time_setup) && (
          <div>
            <div>One Time Setup Fee </div>{" "}
            <div>{numberFormat(item.one_time_setup, "currency", 2)}</div>
          </div>
        )}
        {Boolean(item.membership_fee) && (
          <div>
            <div>Membership Fee </div>{" "}
            <div>{numberFormat(item.membership_fee, "currency", 2)}</div>
          </div>
        )}
        {Boolean(item.discount) && (
          <div>
            <div>Discount </div>{" "}
            <div>{numberFormat(item.discount, "currency", 2)}</div>
          </div>
        )}

        <div className={classes.stepperContentTotal}>
          <div>Total</div> <div>{numberFormat(item.total, "currency", 2)}</div>
        </div>
        {Boolean(item.pro_rated_days) && (
          <div>
            <div
              className={cx(classes.stepperCircle, classes.stepperCircleInfo)}
              data-tip
              data-for="propratedMessage"
            >
              i
            </div>
            <Tooltip
              id="propratedMessage"
              aria-haspopup="true"
              border
              place="right"
              type="light"
              effect="solid"
            >
              The prorated amount is always calculated on a 30 days basis.
            </Tooltip>
            <div>
              <i>
                Prorated fee for {item.pro_rated_days} day
                {item.pro_rated_days > 1 ? "s" : ""} of membership.{" "}
              </i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DrawDayStepper;
