import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useChartingFilterCategories = (options = {}) => {
  return useQuery(
    ["chartingFilterCategories"],
    async () => {
      return http.get(HTTP_ENDPOINTS.getChartingFilterCategories());
    },
    {
      ...options,
      select: (data) => data.data.data,
      cacheTime: 0,
    },
  );
};
