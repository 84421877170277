import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../consts/api";

export const useShowFile = ({ clientId, fileId }, options = {}) => {
  return useQuery(
    ["showFile"],
    () => {
      return http.get(HTTP_ENDPOINTS.showFile(fileId), {
        params: { patient_id: clientId },
      });
    },
    { ...options, enabled: false },
  );
};
