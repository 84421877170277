import { useQueryClient } from "@tanstack/react-query";
import { useProviderRoomSignProceduresMutation } from "../../../api/mutations/useProviderRoomSignProceduresMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { QUERY_KEYS } from "../../../consts/api";
import { useUploadSignatureMutation } from "../../../api/mutations/useUploadSignatureMutation";
import { getLastUrlEntry } from "../../../utilities/general";
import { dispatch } from "../../../store/store";
import { providerRoom } from "../../../store/providerRoom";

export function useSign() {
  const { tCommon } = useAppTranslation.Common();
  const queryClient = useQueryClient();

  const { mutateAsync: uploadSignature, isLoading: isSignatureUploading } =
    useUploadSignatureMutation();

  const { mutateAsync, isLoading } = useProviderRoomSignProceduresMutation();

  const sign = async ({
    isConsult,
    mdId,
    procedureIds,
    signature,
    prevSignature,
    saveSignature,
  }) => {
    try {
      dispatch(providerRoom.actions.proceduresSigningStatusChange(true));
      dispatch(providerRoom.actions.proceduresSignedStatusChange(false));

      let nextSignature = prevSignature;

      if (prevSignature !== signature) {
        const uploadRes = await uploadSignature({ imageData: signature });
        nextSignature = uploadRes.signatureUrl;
      }

      const nextSignatureFileName = getLastUrlEntry(nextSignature);

      if (!nextSignatureFileName) {
        throw new Error();
      }

      await mutateAsync({
        isConsult,
        mdId,
        procedureIds,
        signature: nextSignatureFileName,
        saveSignature,
      });

      uiNotification.success(tCommon("providerRoom.success.sign"));
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.providerRoomProcedures],
        refetchType: "active",
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.user],
        refetchType: "active",
      });

      dispatch(providerRoom.actions.proceduresSignedStatusChange(true));
      dispatch(providerRoom.actions.signProceduresDataReset());
    } catch (e) {
      uiNotification.error(tCommon("providerRoom.error.sign"));
      throw e;
    } finally {
      dispatch(providerRoom.actions.proceduresSigningStatusChange(false));
      dispatch(providerRoom.actions.proceduresSignedStatusChange(false));
    }
  };

  return {
    sign,
    isSigning: isLoading || isSignatureUploading,
  };
}
