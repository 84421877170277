import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import {positionFooterCorrectly, showFormattedDate} from '../../../Utils/services';

class Documents extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			clientLang : languageData.clients,
			globalLang : languageData.global,
			select_all : false
		};
	}

	static getDerivedStateFromProps(nextProps,prevState){
		let returnState = {};
		if(nextProps.documentDataTime != prevState.documentDataTime){
			positionFooterCorrectly();
			if(nextProps.documents != undefined){
				returnState.showLoader = false;
				returnState.documents = nextProps.documents;
				nextProps.documents.map((data,index) => {
					returnState['document-' + data.id] = data.selected;
				})
				if(nextProps.documents.length > 0){
				let enableSelectAll;
				enableSelectAll = nextProps.documents.find(y => y.selected == false);
				if(enableSelectAll){
					returnState.select_all = false;
				} else {
					returnState.select_all = true;
				}}
			}
			returnState.documentDataTime = nextProps.documentDataTime;
		}

		return returnState;
	}


	handleInputChange = (event) => {
		const target = event.target;
		let value= target.value;
		let name = event.target.name;
		let returnState = {}
		switch(target.type) {
			case 'checkbox': {
				value = target.checked;
				break;
			}
	  
			case 'radio' :{
			  value = target.value;
			  break;
		  }
		}
		if(target.type == 'checkbox' && target.checked == true && target.name == 'select_all') {
			let data = this.state.documents;
			data.map((obj, index) => {
				returnState['document-' + obj.id] = true;
			})

		} else if(target.type == 'checkbox' && target.checked == false && target.name == 'select_all') {
			let data = this.state.documents;
			data.map((obj, index) => {
				returnState['document-' + obj.id] = false;
			})
		}
	  returnState[event.target.name] = value;
	  this.setState(returnState, () => this.sendDataToParent());
	}

	sendDataToParent = () => {
		let documents = [];
		let checkStatus = [];
		if(this.state.documents.length > 0){
			this.state.documents.map((data,index) => {
				if(this.state['document-' + data.id] == false){
					checkStatus.push(data);
				}
			})
			if(checkStatus.length > 0){
				this.setState({ select_all : false })
			} else {
				this.setState({ select_all : true })
			}
		}




		
		this.state.documents.map((obj, index) => {
			documents.push({
				appointment_id: obj.appointment_id,
                created: obj.created,
                description: obj.description,
                document_name: obj.document_name,
                document_type: obj.document_type,
                download_path: obj.download_path,
                filename: obj.filename,
                id: obj.id,
                is_deleted: obj.is_deleted,
                modified: obj.modified,
                patient_id: obj.patient_id,
				selected : this.state['document-' + obj.id],
				appointmentData : obj.appointmentData
			})
		})
		let formData = {
			step8Data : {
				documents : documents
			}
		}
		this.props.handleChildState(formData);
	}






    render(){
        return(
        <div>
        <div className="merge-title full-width">
        <div className="row">
		
		<div className="col-xs-12">
			<div className="setting-setion full-width heightAuto">
				<div className="merge-main-title checkOuter">
				{this.state.documents && this.state.documents.length > 0 && <input type="checkbox" id="double-booking" 
				name="select_all" value={this.state.select_all} 
				checked={(this.state.select_all) ? "checked" : false} 
				onChange={this.handleInputChange} />}
					<span>{this.state.clientLang.clientprofile_documents}</span>
					{this.state.documents && this.state.documents.length > 0 && <p>{this.state.clientLang.merge_right_information_message}</p>}
				</div>
				<div className="table-responsive">
				<table className="table-updated juvly-table table-min-width no-td-border">
					<thead className="table-updated-thead">
						<tr>
							<th className="col-xs-0 table-updated-th table-checkbox">
								&nbsp;
							</th>
							<th className="col-xs-4 table-updated-th">{this.state.clientLang.client_document}</th>
							<th className="col-xs-4 table-updated-th p-l-5 p-r-0">{this.state.clientLang.client_document_type}</th>
							<th className="col-xs-4 table-updated-th">{this.state.clientLang.pro_appointment}</th>
						</tr>
					</thead>
					<tbody className="ajax_body">
						{this.state.documents && this.state.documents.map((data,index) => {
							return(
							<tr className="table-updated-tr" key={index}>
							<td className="col-xs-0 table-checkbox table-updated-td checkbox-recently-deleted">
							<input type="checkbox" value={data.selected} name={"document-"+data.id} 
					checked={(this.state["document-"+data.id]) ? "checked" : false} autoComplete="off" onChange={this.handleInputChange} />
							</td>
							<td className="col-xs-4 table-updated-td">{data.document_name}</td>
							<td className="col-xs-4 table-updated-td p-l-5 p-r-0 break-all">{data.document_type}</td>
							<td className="col-xs-4 table-updated-td break-all">{ data.appointmentData ? showFormattedDate(data.appointmentData.appointment_datetime,true) : 'N/A'}</td>
						</tr>)
						})}
					</tbody>
				</table>
				{this.state.documents && this.state.documents.length == 0 && <div className="no-record">Sorry, No Record Found</div>}
				
</div>
			</div>
		</div>
		
	</div>
        </div>
        </div>
    )
    }}

export default Documents;