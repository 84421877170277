import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
} from "../Utils/services.js";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const http = axios.create();

http.defaults.headers.common["access-token"] = getToken();

http.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    return response;
  },
  function (error) {
    let msg = error.response.data.message;

    if (
      msg === "invalid_token" ||
      msg === "session_timeout" ||
      msg === "server_error" ||
      msg === "token_not_found"
    ) {
      handleInvalidToken();
    }

    return Promise.reject(error);
  },
);

http.interceptors.response.use(accountStatusResponseInterceptor);

export const getReferralSources = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .get(process.env.REACT_APP_API_URL + "get-referral-sources", formData)
      .then((response) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const changeReferralSourceStatus = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(
        process.env.REACT_APP_API_URL + "change-status-referral-source",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "CHANGE_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const addReferralSource = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(process.env.REACT_APP_API_URL + "add-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const addReferralSourceSubcategory = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(process.env.REACT_APP_API_URL + "add-source-subcategory", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const changeReferralSubcategoryStatus = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(
        process.env.REACT_APP_API_URL + "change-status-source-subcategory",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const editReferralSourceName = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(process.env.REACT_APP_API_URL + "edit-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const deleteReferralSource = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(process.env.REACT_APP_API_URL + "delete-referral-source", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCES_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const editReferralSubcategoryName = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(process.env.REACT_APP_API_URL + "edit-source-subcategory", formData)
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};

export const deleteSubcategorySource = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_REFERRAL_SOURCES_LOADING" });
    http
      .post(
        process.env.REACT_APP_API_URL + "delete-source-subcategory",
        formData,
      )
      .then((response) => {
        dispatch({
          type: "ADD_REFERRAL_SOURCE_SUBCATEGORY_SUCCESS",
          payload: response.data,
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "GET_REFERRAL_SOURCES_ERROR",
          payload: error.response.data,
        });
        toast.error(error.response.data.message);
      });
  };
};
