/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import validator from "validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  emptyInventoryReducer,
  createStockTransfer,
  viewStockTransfer,
  getProductStockDetail,
  searchProductStock,
} from "../../../Actions/Inventory/inventoryActions.js";
import {
  toggleBodyScroll,
  isFormSubmit,
  isPositiveNumber,
  displayName,
  positionFooterInstantly,
} from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import StockTransferAddProductRow from "./StockTransferAddProductRow.js";
import ConfirmationModal from "../../Common/ConfirmationModal.js";
import { initTransfer } from "./stock-transfer.js";
import InventorySidebar from "../InventorySidebar.js";

class CreateEditStockTransfer extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      globalLang: languageData.global,
      showLoader: false,
      stockTransferId:
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
          ? this.props.match.params.id
          : 0,
      clinicList: [],
      stockTransferData: {},
      productStockData: null,
      autoProductData: {},
      showSearchResult: false,

      from_clinic_id: "",
      from_clinic_idClass: "simpleSelect",
      to_clinic_id: "",
      to_clinic_idClass: "simpleSelect",

      transfer_type: "shipping_provider",
      shipping_provider: "",
      shipping_providerClass: "simpleInput",
      shipping_tracking_number: "",
      shipping_tracking_numberClass: "simpleInput",
      responsible_staff_id: "",
      responsible_staff_idClass: "simpleSelect",
      provider_name: "",
      provider_nameClass: "simpleInput",
      tracking_number: "",
      tracking_numberClass: "simpleInput",
      userList: [],
      transfer: [],

      showStockTransferProductModal: false,
      editRowIndex: null,

      showConfirmationModal: false,
      confirmationMsg: "",
      confirmationActionType: "",
      from_clinic_id_changed: "",
      fromDataTransfer: [],
    };
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.viewStockTransfer(this.state.stockTransferId);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader) {
      returnState.showLoader = false;
      nextProps.emptyInventoryReducer();
    } else if (nextProps.isRedirect) {
      returnState.showModal = false;
      nextProps.history.push(`/inventory/stock-transfers`);
    } else if (
      nextProps.stockTransferData &&
      nextProps.stockTransferData.data !== prevState.stockTransferData
    ) {
      nextProps.emptyInventoryReducer();
      returnState.showLoader = false;
      returnState.stockTransferData = nextProps.stockTransferData;
      returnState.clinicList = nextProps.stockTransferData.clinics
        ? nextProps.stockTransferData.clinics
        : [];
      returnState.userList = nextProps.stockTransferData.users
        ? nextProps.stockTransferData.users
        : [];
    } else if (
      nextProps.productStockData &&
      nextProps.productStockData !== prevState.productStockData
    ) {
      nextProps.emptyInventoryReducer();
      returnState.showLoader = false;
      returnState.productStockData = nextProps.productStockData;
    } else if (
      nextProps.autoProductData &&
      nextProps.autoProductData !== prevState.autoProductData
    ) {
      nextProps.emptyInventoryReducer();
      returnState.autoProductData = nextProps.autoProductData;
      returnState.showLoader = false;
      returnState.showSearchResult = true;
    }
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    let returnState = {
      editRowIndex: null,
    };

    if (
      event.target.name === "from_clinic_id" &&
      this.state.transfer.length > 0
    ) {
      returnState.showConfirmationModal = true;
      returnState.confirmationMsg =
        "Stock initiated can't be reverted. Click Yes to create.";
      returnState.confirmationActionType = "resetTransferList";
      returnState.from_clinic_id_changed = value;
      toggleBodyScroll(true);
    } else {
      returnState[event.target.name] = value;
    }
    this.setState(returnState);
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ editRowIndex: null });
    if (isFormSubmit()) {
      let error = false;
      let toastMsg = "";
      if (
        typeof this.state.from_clinic_id == undefined ||
        this.state.from_clinic_id == null ||
        this.state.from_clinic_id == ""
      ) {
        this.setState({ from_clinic_idClass: "simpleSelect field_error" });
        error = true;
      } else if (this.state.from_clinic_id) {
        this.setState({ from_clinic_idClass: "simpleSelect" });
      }
      if (
        typeof this.state.to_clinic_id == undefined ||
        this.state.to_clinic_id == null ||
        this.state.to_clinic_id == ""
      ) {
        this.setState({ to_clinic_idClass: "simpleSelect field_error" });
        error = true;
      } else if (this.state.to_clinic_id) {
        if (this.state.from_clinic_id == this.state.to_clinic_id) {
          this.setState({
            to_clinic_idClass: "simpleSelect field_error",
            from_clinic_idClass: "simpleSelect field_error",
          });
          error = true;
          toastMsg = "You can not transfer to same clinic.";
        } else {
          this.setState({ to_clinic_idClass: "simpleSelect" });
        }
      }

      this.setState({
        shipping_providerClass: "simpleInput",
        shipping_tracking_numberClass: "simpleInput",
        responsible_staff_idClass: "simpleSelect",
      });
      if (this.state.transfer_type === "shipping_provider") {
        if (
          typeof this.state.shipping_provider == undefined ||
          this.state.shipping_provider == null ||
          this.state.shipping_provider == ""
        ) {
          this.setState({ shipping_providerClass: "simpleInput field_error" });
          error = true;
        } else if (this.state.shipping_provider) {
          this.setState({ shipping_providerClass: "simpleInput" });
        }
        if (
          typeof this.state.shipping_tracking_number == undefined ||
          this.state.shipping_tracking_number == null ||
          this.state.shipping_tracking_number == ""
        ) {
          this.setState({
            shipping_tracking_numberClass: "simpleInput field_error",
          });
          error = true;
        } else if (this.state.shipping_tracking_number) {
          this.setState({ shipping_tracking_numberClass: "simpleInput" });
        }
      }

      if (this.state.transfer_type === "courier_service") {
        if (
          typeof this.state.provider_name == undefined ||
          this.state.provider_name == null ||
          this.state.provider_name == ""
        ) {
          this.setState({ provider_nameClass: "simpleInput field_error" });
          error = true;
        } else if (this.state.provider_name) {
          this.setState({ provider_nameClass: "simpleInput" });
        }
        if (
          typeof this.state.tracking_number == undefined ||
          this.state.tracking_number == null ||
          this.state.tracking_number == ""
        ) {
          this.setState({ tracking_numberClass: "simpleInput field_error" });
          error = true;
        } else if (this.state.tracking_number) {
          this.setState({ tracking_numberClass: "simpleInput" });
        }
      }

      if (this.state.transfer_type === "responsible_staff") {
        if (
          typeof this.state.responsible_staff_id == undefined ||
          this.state.responsible_staff_id == null ||
          this.state.responsible_staff_id == ""
        ) {
          this.setState({
            responsible_staff_idClass: "simpleSelect field_error",
          });
          error = true;
        } else if (this.state.responsible_staff_id) {
          this.setState({ responsible_staff_idClass: "simpleSelect" });
        }
      }

      let transfer = JSON.parse(JSON.stringify(this.state.transfer));
      let tranferProducts = [];
      if (transfer && transfer.length > 0) {
        transfer.map((obj, idx) => {
          if (
            obj.product_name !== "" &&
            validator.trim(obj.product_name) !== ""
          ) {
            if (
              (obj.type === "injectable" && obj.inventory_id !== "") ||
              obj.type === "others"
            ) {
              if (obj.transfer_units > 0) {
                if (
                  !isPositiveNumber(
                    obj.transfer_units,
                    0,
                    obj.available_units,
                    4,
                  )
                ) {
                  transfer[idx]["transfer_unitsClass"] =
                    "simpleInput field_error";
                  error = true;
                } else {
                  transfer[idx]["transfer_unitsClass"] = "simpleInput";
                  const findIndex = tranferProducts.findIndex(
                    (x) => x.product_id === obj.product_id,
                  );
                  if (findIndex > -1) {
                    let product = tranferProducts[findIndex];
                    const batchIndex = product.batches.findIndex(
                      (y) => y.inventory_id === obj.inventory_id,
                    );
                    if (batchIndex == -1) {
                      let batches = {
                        transfer_units: obj.transfer_units,
                        available_units: obj.available_units,
                        inventory_id: obj.inventory_id,
                        batch_id: obj.batch_id,
                      };
                      if (obj.transfer_units > obj.available_units) {
                        error = true;
                        transfer[idx]["transfer_unitsClass"] =
                          "simpleInput field_error";
                      }
                      if (product.type === "others") {
                        batches.batch_id = "";
                      }
                      product.batches.push(batches);
                    } else {
                      if (
                        parseFloat(
                          product.batches[batchIndex]["transfer_units"],
                        ) +
                          parseFloat(obj.transfer_units) >
                        obj.available_units
                      ) {
                        error = true;
                        transfer[idx]["transfer_unitsClass"] =
                          "simpleInput field_error";
                        transfer[idx]["inventory_idClass"] =
                          "simpleSelect field_error";
                        transfer[idx]["search_product_keywordClass"] =
                          "simpleSelect field_error";
                        transfer.map((obj1, idx1) => {
                          if (obj1.inventory_id == obj.inventory_id) {
                            transfer[idx1]["transfer_unitsClass"] =
                              "simpleInput field_error";
                            transfer[idx1]["inventory_idClass"] =
                              "simpleSelect field_error";
                            transfer[idx1]["search_product_keywordClass"] =
                              "simpleSelect field_error";
                          }
                        });
                        if (toastMsg == "") {
                          toastMsg = "Can't add same product multiple times.";
                        }
                      } else {
                        product.batches[batchIndex]["transfer_units"] =
                          parseFloat(
                            product.batches[batchIndex]["transfer_units"],
                          ) + parseFloat(obj.transfer_units);
                        transfer[idx]["transfer_unitsClass"] = "simpleInput";
                        transfer[idx]["inventory_idClass"] = "simpleSelect";
                      }
                    }
                    tranferProducts[findIndex] = product;
                  } else {
                    let product = {
                      product_id: obj.product_id,
                      product_name: obj.product_name,
                      type: obj.type,
                    };

                    let batches = {
                      transfer_units: obj.transfer_units,
                      available_units: obj.available_units,
                      inventory_id: obj.inventory_id,
                      batch_id: obj.batch_id,
                    };
                    if (obj.transfer_units > 0) {
                      if (
                        !isPositiveNumber(
                          obj.transfer_units,
                          0,
                          obj.available_units,
                          4,
                        )
                      ) {
                        error = true;
                        transfer[idx]["transfer_unitsClass"] =
                          "simpleInput field_error";
                      }
                    } else {
                      error = true;
                      transfer[idx]["transfer_unitsClass"] =
                        "simpleInput field_error";
                    }

                    if (product.type === "others") {
                      batches.batch_id = "";
                    }
                    product.batches = [batches];
                    tranferProducts.push(product);
                  }
                }
              } else {
                error = true;
                transfer[idx]["transfer_unitsClass"] =
                  "simpleInput field_error";
              }
            } else {
              error = true;
              transfer[idx]["inventory_idClass"] = "simpleSelect field_error";
            }
          } else {
            error = true;
            if (toastMsg == "") {
              toastMsg =
                transfer.length > 1
                  ? "Kindly add the product details or remove the empty row."
                  : "Please add products.";
            }
          }
        });
        this.setState({ transfer: transfer });
      } else {
        error = true;
        if (toastMsg == "") {
          toastMsg = "Please add products.";
        }
      }
      if (error) {
        if (toastMsg) {
          toast.dismiss();
          toast.error(toastMsg);
        }
        return false;
      }

      if (this.state.transfer && this.state.transfer.length > 0) {
        let formData = {
          from_clinic_id: this.state.from_clinic_id,
          to_clinic_id: this.state.to_clinic_id,
          transfer_type: this.state.transfer_type,
          transfer: tranferProducts,
        };

        if (this.state.transfer_type === "shipping_provider") {
          formData.shipping_provider = this.state.shipping_provider;
          formData.shipping_tracking_number =
            this.state.shipping_tracking_number;
        } else if (this.state.transfer_type === "courier_service") {
          formData.provider_name = this.state.provider_name;
          formData.tracking_number = this.state.tracking_number;
        } else {
          formData.responsible_staff_id = this.state.responsible_staff_id;
        }

        toggleBodyScroll(true);
        this.setState({
          fromDataTransfer: formData,
          showConfirmationModal: true,
          confirmationMsg:
            "Once Stock Transfer is created you can't revert back!",
          confirmationActionType: "createStockTransfer",
        });
      } else {
        toast.dismiss();
        toast.error("Please add products");
      }
    }
  };

  addStockTransferProduct = () => {
    if (this.state.from_clinic_id) {
      positionFooterInstantly();
      let transfer = this.state.transfer;
      transfer.push(initTransfer());
      this.setState({
        transfer: transfer,
        from_clinic_idClass: "simpleSelect",
        autoProductData: {},
      });
    } else {
      toast.dismiss();
      toast.error("Please select from clinic");
      this.setState({ from_clinic_idClass: "simpleSelect field_error" });
    }
  };

  searchProductStock = (formData, editRowIndex) => {
    if (this.state.from_clinic_id) {
      this.setState({
        productStockData: null,
        editRowIndex: editRowIndex,
        from_clinic_idClass: "simpleSelect",
      });
      formData.params.clinic_id = this.state.from_clinic_id;
      this.props.searchProductStock(formData);
    } else {
      this.setState({ from_clinic_idClass: "simpleSelect field_error" });
    }
  };

  getProductStockDetail = (prouctId) => {
    if (this.state.from_clinic_id) {
      if (prouctId) {
        this.setState({
          showLoader: true,
          productStockData: null,
          from_clinic_idClass: "simpleSelect",
        });
        this.props.getProductStockDetail(prouctId, this.state.from_clinic_id);
      }
    } else {
      toast.dismiss();
      toast.error("Please select from clinic");
      this.setState({ from_clinic_idClass: "simpleSelect field_error" });
    }
  };

  redirectToStockTransfer = () => {
    this.props.history.push("/inventory/stock-transfers");
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  onResetConfirmation = (resetState) => {
    toggleBodyScroll(false);
    this.setState(resetState);
  };

  confirmationAction = () => {
    toggleBodyScroll(false);
    if (this.state.confirmationActionType === "resetTransferList") {
      this.resetTransferList();
    } else if (this.state.confirmationActionType === "createStockTransfer") {
      this.createStockTransfer();
    }
  };

  resetTransferList = () => {
    toggleBodyScroll(false);
    this.setState({
      transfer: [],
      from_clinic_id: this.state.from_clinic_id_changed,
    });
  };

  createStockTransfer = () => {
    toggleBodyScroll(false);
    this.setState({ showLoader: true });
    this.props.createStockTransfer(this.state.fromDataTransfer);
  };

  handleRowDelete = (childState, index) => {
    this.setState(childState);
    let transfer = this.state.transfer;
    transfer.splice(index, 1);
    this.setState({
      transfer: transfer,
      editRowIndex: null,
      autoProductData: {},
    });
    positionFooterInstantly();
  };

  render() {
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />

            {this.state.showConfirmationModal === true && (
              <ConfirmationModal
                showConfirmationModal={this.state.showConfirmationModal}
                confirmationMsg={this.state.confirmationMsg}
                confirmationAction={this.confirmationAction}
                onResetConfirmation={this.onResetConfirmation}
              />
            )}

            <div className="business-setion memberWalletOuter">
              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span
                      className="cursor-pointer"
                      onClick={this.redirectToStockTransfer}
                    >
                      {"Stock Transfers"}
                    </span>
                    <span className="breadCrumb">
                      {" "}
                      <i className="fa fa-chevron-right" />{" "}
                      <span className="breadCrumb-text">
                        {"Start New Transfer"}
                      </span>
                    </span>
                    <div className="memberRightActions">
                      <button
                        className="new-blue-btn pull-right"
                        onClick={this.handleSubmit}
                      >
                        {"Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="juvly-section supplierInformation">
                <div className="juvly-subtitle m-b-15 no-display"></div>
                <div className="row">
                  <div className="col-sm-6 supplierDetailsLeft">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        From Clinic<span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.from_clinic_idClass}
                        name="from_clinic_id"
                        onChange={this.handleInputChange}
                        value={this.state.from_clinic_id}
                        disabled={this.state.supplierId ? true : false}
                      >
                        <option value="">Select</option>
                        {this.state.clinicList.map((obj, id) => {
                          return (
                            <option key={"from_clinic_id-" + id} value={obj.id}>
                              {obj.clinic_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="col-sm-6 supplierDetailsLeft">
                    <div className="simpleField m-b-15">
                      <div className="simpleLabel">
                        To Clinic<span className="setting-require">*</span>
                      </div>
                      <select
                        className={this.state.to_clinic_idClass}
                        name="to_clinic_id"
                        onChange={this.handleInputChange}
                        value={this.state.to_clinic_id}
                      >
                        <option value="">Select</option>
                        {this.state.clinicList.map((obj, id) => {
                          return (
                            <option key={"to_clinic_id-" + id} value={obj.id}>
                              {obj.clinic_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="juvly-section full-width supplierInformation no-padding">
                <div className="mainSectionTitle bold m-b-0">
                  Transfer Logistics
                </div>
                <div className="row p20">
                  <div className="col-xs-12">
                    <div className="basic-checkbox-outer m-t-0">
                      <input
                        id="rbShippingProvider"
                        className="basic-form-checkbox"
                        name="transfer_type"
                        type="radio"
                        value="shipping_provider"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.transfer_type === "shipping_provider"
                            ? "checked"
                            : false
                        }
                      />
                      <label
                        className="basic-form-text"
                        htmlFor="rbShippingProvider"
                      >
                        Shipping Provider
                      </label>
                    </div>
                    <div className="basic-checkbox-outer m-t-0">
                      <input
                        id="rbCourierServices"
                        className="basic-form-checkbox"
                        name="transfer_type"
                        type="radio"
                        value="courier_service"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.transfer_type === "courier_service"
                            ? "checked"
                            : false
                        }
                      />
                      <label
                        className="basic-form-text"
                        htmlFor="rbCourierServices"
                      >
                        Courier Service
                      </label>
                    </div>
                    <div className="basic-checkbox-outer m-t-0">
                      <input
                        id="rbResponsibleStaff"
                        className="basic-form-checkbox"
                        name="transfer_type"
                        type="radio"
                        value="responsible_staff"
                        onChange={this.handleInputChange}
                        checked={
                          this.state.transfer_type === "responsible_staff"
                            ? "checked"
                            : false
                        }
                      />
                      <label
                        className="basic-form-text"
                        htmlFor="rbResponsibleStaff"
                      >
                        Responsible Staff
                      </label>
                    </div>
                  </div>

                  {this.state.transfer_type === "shipping_provider" && (
                    <React.Fragment>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="simpleField m-b-15">
                          <div className="simpleLabel">
                            Shipping Provider
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            className={this.state.shipping_providerClass}
                            name="shipping_provider"
                            onChange={this.handleInputChange}
                            value={this.state.shipping_provider}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="simpleField m-b-15">
                          <div className="simpleLabel">
                            Tracking Number
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            className={this.state.shipping_tracking_numberClass}
                            name="shipping_tracking_number"
                            onChange={this.handleInputChange}
                            value={this.state.shipping_tracking_number}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.transfer_type === "courier_service" && (
                    <React.Fragment>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="simpleField m-b-15">
                          <div className="simpleLabel">
                            Provider Name
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            className={this.state.provider_nameClass}
                            name="provider_name"
                            onChange={this.handleInputChange}
                            value={this.state.provider_name}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="simpleField m-b-15">
                          <div className="simpleLabel">
                            Tracking Number
                            <span className="setting-require">*</span>
                          </div>
                          <input
                            className={this.state.tracking_numberClass}
                            name="tracking_number"
                            onChange={this.handleInputChange}
                            value={this.state.tracking_number}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                  {this.state.transfer_type === "responsible_staff" && (
                    <React.Fragment>
                      <div className="col-sm-6 col-xs-12 shippingInfo">
                        <div className="simpleField m-b-15">
                          <div className="simpleLabel">
                            Staff<span className="setting-require">*</span>
                          </div>
                          <select
                            className={this.state.responsible_staff_idClass}
                            name="responsible_staff_id"
                            onChange={this.handleInputChange}
                            value={this.state.responsible_staff_id}
                          >
                            <option value="">Select</option>
                            {this.state.userList.map((obj, id) => {
                              return (
                                <option
                                  key={"responsible_staff_id-" + id}
                                  value={obj.id}
                                >
                                  {displayName(obj)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>

              <div className="juvly-section full-width m-b-10 auto-height">
                <div className="setting-search-outer">
                  <span className="newTableTitle bold full-width">
                    <b>Product Details</b>

                    {this.state.transfer && this.state.transfer.length <= 0 && (
                      <button
                        className="new-blue-btn pull-right -m-t-5 -m-b-5"
                        onClick={this.addStockTransferProduct}
                      >
                        {"Add Product"}
                      </button>
                    )}
                  </span>
                </div>
                {this.state.transfer && this.state.transfer.length > 0 && (
                  <div className="table-responsive stockTransferProductDetail">
                    <table className="table-updated juvly-table min-w-600 no-td-border no-hover">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="col-xs-4 table-updated-th">
                            Product Name
                          </th>
                          <th className="col-xs-3 table-updated-th">Batch</th>
                          <th className="col-xs-3 table-updated-th">Stock</th>
                          <th className="col-xs-2 table-updated-th p-l-0"></th>
                        </tr>
                      </thead>

                      <tbody className={"ajax_body no-scroll"}>
                        <React.Fragment>
                          {this.state.transfer.map((objProduct, idxProduct) => {
                            return (
                              <StockTransferAddProductRow
                                key={"transfer-objProduct-" + idxProduct}
                                showStockTransferProductModal={
                                  this.state.showStockTransferProductModal
                                }
                                hideStockTransferProductModal={
                                  this.hideStockTransferProductModal
                                }
                                searchProductStock={this.searchProductStock}
                                autoProductData={this.state.autoProductData}
                                getProductStockDetail={
                                  this.getProductStockDetail
                                }
                                productStockData={this.state.productStockData}
                                addedTransferProduct={this.addedTransferProduct}
                                transfer={this.state.transfer}
                                editRowIndex={this.state.editRowIndex}
                                index={idxProduct}
                                row={objProduct}
                                handleChildState={this.handleChildState}
                                handleRowDelete={this.handleRowDelete}
                              />
                            );
                          })}
                          {(!this.state.transfer ||
                            this.state.transfer.length <= 0) && (
                            <tr>
                              <td
                                colSpan={5}
                                className="col-xs-12 table-updated-td text-center "
                              >
                                <div className={"no-record"}>
                                  {"Product not selected yet!"}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      </tbody>
                    </table>
                  </div>
                )}
                {this.state.transfer && this.state.transfer.length > 0 && (
                  <div className="row p15">
                    <div className="col-xs-12">
                      <button
                        className="new-blue-btn pull-left m-l-0"
                        onClick={this.addStockTransferProduct}
                      >
                        {"Add Product"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "VIEW_STOCK_TRANSFER") {
    toast.dismiss();
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = true;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.stockTransferData = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "CREATE_STOCK_TRANSFER") {
    toast.dismiss();
    if (state.InventoryReducer.data.status !== 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = true;
    } else {
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.isRedirect = true;
    }
  }
  if (state.InventoryReducer.action === "GET_PRODUCT_STOCK_DETAIL") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = true;
    } else {
      returnState.productStockData = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "AUTO_PRODUCT_SEARCH_LIST") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = true;
    } else {
      returnState.autoProductData = state.InventoryReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      emptyInventoryReducer: emptyInventoryReducer,
      createStockTransfer: createStockTransfer,
      viewStockTransfer: viewStockTransfer,
      getProductStockDetail: getProductStockDetail,
      searchProductStock: searchProductStock,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateEditStockTransfer);
