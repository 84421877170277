import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const salesInstance = axios.create();

salesInstance.defaults.headers.common["access-token"] = getToken();

salesInstance.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      let msg = error.response.data.message;

      if (
        msg === "invalid_token" ||
        msg === "session_timeout" ||
        msg === "server_error" ||
        msg === "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

salesInstance.interceptors.response.use(accountStatusResponseInterceptor);

let timeoutId;

export const fetchIsKlarnaAvailable = (invoiceId) => {
  return (dispatch) => {
    dispatch({
      type: "IS_KLARNA_AVAILABLE",
      payload: { status: "loading", data: { isKlarnaAvailable: false } },
    });
    salesInstance
      .get(
        process.env.REACT_APP_API_URL +
          `stripe/checkout/klarna/is-available/${invoiceId}`,
      )
      .then((response) => {
        dispatch({
          type: "IS_KLARNA_AVAILABLE",
          payload: {
            status: "success",
            data: { isKlarnaAvailable: response.data.data.isKlarnaAvailable },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "IS_KLARNA_AVAILABLE",
          payload: {
            status: "error",
            data: { isKlarnaAvailable: false, message: error.response.data },
          },
        });
      });
  };
};

export const fetchKlarnaAvailability = () => {
  return (dispatch) => {
    dispatch({
      type: "KLARNA_AVAILABILITY",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .get(process.env.REACT_APP_API_URL + `stripe/checkout/klarna/capability`)
      .then((response) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "success", data: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const updateKlarnaAvailability = (clinicId, isEnabled) => {
  return async (dispatch) => {
    dispatch({
      type: "KLARNA_AVAILABILITY",
      payload: { status: "loading", data: null },
    });
    return salesInstance
      .put(
        process.env.REACT_APP_API_URL +
          `stripe/checkout/klarna/capability/${clinicId}`,
        {
          is_klarna_enabled: isEnabled,
        },
      )
      .then((response) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "success", data: response.data.data },
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "KLARNA_AVAILABILITY",
          payload: { status: "error", data: error.response.data },
        });
        dispatch(fetchKlarnaAvailability());
        return Promise.reject(error.response.data);
      });
  };
};

export const createKlarnaPayment = (klarnaPayment) => {
  return async (dispatch) => {
    dispatch({
      type: "CREATE_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    await salesInstance
      .post(process.env.REACT_APP_API_URL + "stripe/checkout/klarna", {
        klarnaPayment,
      })
      .then((response) => {
        dispatch({
          type: "CREATE_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data },
        });
        dispatch({
          type: "FETCH_KLARNA_PAYMENT",
          payload: { status: "loading", data: null },
        });
        dispatch({
          type: "ONGOING_KLARNA_PAYMENT",
          payload: { status: "loading", data: null },
        });
        dispatch(
          fetchKlarnaPayment({
            invoiceId: klarnaPayment.invoiceId,
          }),
        );
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
        toast.error(error.response.data.message);
      });
  };
};

const fetchKlarnaPayment = ({ invoiceId }) => {
  return (dispatch, getState) => {
    const state = getState();

    salesInstance
      .get(process.env.REACT_APP_API_URL + `stripe/checkout/${invoiceId}`)
      .then((response) => {
        if (state.KlarnaReducer.klarnaPaymentStatus.status !== "loading") {
          return;
        }

        const klarnaPayment = response.data.data.klarnaPayment;
        dispatch({
          type: "ONGOING_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        if (klarnaPayment.expired) {
          dispatch({
            type: "FETCH_KLARNA_PAYMENT",
            payload: { status: "error", data: response.data },
          });
          dispatch({ type: "RESET_KLARNA_PAYMENT" });
        }
        if (klarnaPayment.completed) {
          dispatch({
            type: "FETCH_KLARNA_PAYMENT",
            payload: { status: "success", data: response.data },
          });
          dispatch({ type: "RESET_KLARNA_PAYMENT" });
        } else {
          timeoutId = setTimeout(
            () =>
              dispatch(
                fetchKlarnaPayment({
                  invoiceId,
                }),
              ),
            5000,
          );
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
      });
  };
};

export const cancelKlarnaPayment = ({ invoiceId }) => {
  return (dispatch) => {
    clearInterval(timeoutId);
    dispatch({
      type: "CANCEL_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .delete(process.env.REACT_APP_API_URL + `stripe/checkout/${invoiceId}`)
      .then((response) => {
        dispatch({
          type: "CANCEL_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
      })
      .catch((error) => {
        dispatch({
          type: "CANCEL_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        dispatch({ type: "RESET_KLARNA_PAYMENT" });
      });
  };
};

export const notifyViaSMS = ({ invoiceId, phone }) => {
  return (dispatch) => {
    dispatch({
      type: "NOTIFY_VIA_SMS_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .post(
        process.env.REACT_APP_API_URL +
          `stripe/checkout/notify-via-sms/${invoiceId}`,
        { phone },
      )
      .then((response) => {
        dispatch({
          type: "NOTIFY_VIA_SMS_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "NOTIFY_VIA_SMS_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        toast.error(error.response.data.message);
      });
  };
};

export const notifyViaEmail = ({ invoiceId, email }) => {
  return (dispatch) => {
    dispatch({
      type: "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .post(
        process.env.REACT_APP_API_URL +
          `stripe/checkout/notify-via-email/${invoiceId}`,
        { email },
      )
      .then((response) => {
        dispatch({
          type: "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT",
          payload: { status: "success", data: response.data.data },
        });
        toast.success(response.data.message);
      })
      .catch((error) => {
        dispatch({
          type: "NOTIFY_VIA_EMAIL_KLARNA_PAYMENT",
          payload: { status: "error", data: error.response.data },
        });
        toast.error(error.response.data.message);
      });
  };
};
