import React from "react";
import PropTypes from "prop-types";
import classes from "./QualiphyExamProcedure.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { FileBtn } from "./shared/FileBtn/FileBtn";
import { Status } from "./shared/Status/Status";
import { EXAM_STATUSES } from "./QualiphyExamProcedure.consts";
import { Button } from "../../../../shared/Button/Button";
import { useInfo } from "./hooks/useInfo";
import { useResend } from "./hooks/useResend";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { useDelete } from "./hooks/useDelete";

export function QualiphyExamProcedure({
  procedure,
  viewNotes,
  refetchProcedures,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { resend, isResending } = useResend({
    onSuccess: refetchProcedures,
  });
  const { deleteProcedure, isDeleting } = useDelete({
    onSuccess: refetchProcedures,
  });

  const {
    exams,
    patientId,
    procedureId,
    clinicId,
    procedureName,
    requestedDate,
    requestedProvider,
    requestedClinic,
    completedDate,
    completedProvider,
    completedClinic,
    isCompleted,
    isExpired,
    examLink,
  } = useInfo(procedure);

  const onResend = () => {
    resend({
      clinicId,
      procedureId,
    });
  };

  const onDelete = () => {
    deleteProcedure({
      patientId,
      procedureId,
    });
  };

  const onJoinMeeting = () => {
    window.open(examLink, "_blank");
  };

  return (
    <div className={classes.root}>
      <div>
        <div className="col-sm-4 col-xs-12 m-b-15">
          <div className="procedure-name">{procedureName}</div>
          <p className="pro-time healthTimelineDetail m-b-5">
            <b>{tCommon("label.requested")}</b> {requestedDate}
          </p>
          {isCompleted && (
            <p className="pro-time healthTimelineDetail m-b-0">
              <b>{tCommon("label.completed")}</b> {completedDate}
            </p>
          )}
        </div>
        <div className="col-sm-3 col-xs-12 m-b-15">
          <label className="popup-input-field-name">
            {tCommon("createQualifyProcedure.label.requestedBy")}
          </label>
          <p className="pro-time healthTimelineDetail m-b-5">
            {requestedProvider}
          </p>
          {isCompleted && (
            <p className="pro-time healthTimelineDetail m-b-0">
              {completedProvider}
            </p>
          )}
        </div>
        <div className="col-sm-2 col-xs-12 m-b-15">
          <label className="popup-input-field-name">
            {tCommon("label.location")}
          </label>
          <p className="pro-time healthTimelineDetail m-b-5">
            {requestedClinic}
          </p>
          {isCompleted && (
            <p className="pro-time healthTimelineDetail m-b-0">
              {completedClinic}
            </p>
          )}
        </div>
        <div className="col-sm-3 col-xs-12 m-b-15">
          <button className="easy-link no-padding" onClick={viewNotes}>
            {tCommon("label.addViewNotesCount", {
              count: procedure.procedure_notes.length,
            })}
          </button>
        </div>
      </div>
      {exams.length > 0 && (
        <div className={classes.examsWrap}>
          <table>
            <thead>
              <tr>
                <th className={classes.examsPaperworkHeader}>
                  {tCommon("createQualifyProcedure.exams.paperwork")}
                </th>
                <th>
                  {tCommon("createQualifyProcedure.exams.treatmentStatus")}
                </th>
              </tr>
            </thead>
            <tbody>
              {exams.map((exam) => (
                <tr key={exam?.id}>
                  <td className={classes.fileBtnCol}>
                    <FileBtn
                      type={isExpired ? EXAM_STATUSES.expired : exam?.status}
                      downloadLink={exam?.filename}
                    >
                      {exam?.exam_name}
                    </FileBtn>
                  </td>
                  <td>
                    <Status
                      type={isExpired ? EXAM_STATUSES.expired : exam?.status}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className={classes.actions}>
            {isExpired && (
              <Button
                color="orangeDark"
                size="small"
                onClick={onResend}
                isDisabled={isResending}
                leftAdornment={
                  isResending ? (
                    <CircularProgress color="white" size="small" />
                  ) : undefined
                }
              >
                {tCommon("label.resend")}
              </Button>
            )}
            {isExpired && (
              <Button
                color="error"
                size="small"
                onClick={onDelete}
                isDisabled={isDeleting}
                leftAdornment={
                  isDeleting ? (
                    <CircularProgress color="white" size="small" />
                  ) : undefined
                }
              >
                {tCommon("label.delete")}
              </Button>
            )}
            {!isExpired && examLink && (
              <Button size="small" onClick={onJoinMeeting}>
                {tCommon("label.joinMeeting")}
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

QualiphyExamProcedure.propTypes = {
  procedure: PropTypes.object.isRequired,
  viewNotes: PropTypes.func.isRequired,
  refetchProcedures: PropTypes.func.isRequired,
};
