/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import AppointmentConfigNavbar from "../AppointmentConfigNavbar";
import CreateEditVerticals from "../Verticals/CreateEditVerticals";
import {
  deleteVertical,
  fetchVerticals,
  verticalEnableView,
} from "../../../Actions/Verticals/verticalsAction";
import { fetchClinics } from "../../../Actions/Settings/settingsActions";
import { getServiceList } from "../../../Actions/Appointment/appointmentAction";

class Verticals extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      userData: userData,
      vertical_name: languageData.verticals["vertical_name"],
      vertical_clinics: languageData.verticals["vertical_clinics"],
      vertical_services_included:
        languageData.verticals["vertical_services_included"],
      vertical_vertical_create:
        languageData.verticals["vertical_vertical_create"],
      vertical_enable_vertical_view:
        languageData.verticals["vertical_enable_vertical_view"],
      vertical_verticals: languageData.verticals["vertical_verticals"],
      sorry_no_record_found: languageData.global["sorry_no_record_found"],
      please_wait: languageData.global["Please_Wait"],
      settingsLang: languageData.settings,
      verticalsList: [],
      isVerticalView: userData ? +userData.user.vertical_view : 0,
      showLoader: false,
      isVerticalModal: false,
      sortOrder: "asc",
      sortBy: "name",
      clinicsList: [],
      servicesList: [],
      editableVertical: null,
      paramsPage: 1,
      paramsPageSize: 15,
      paramsSortBy: "name",
      lastPage: 1,
      deleteVerticalModal: {
        isOpen: false,
        verticalName: "",
        verticalId: "",
      },
    };
    document.addEventListener("scroll", this.loadMore);
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.fetchVerticals({
      params: {
        pagesize: this.state.paramsPageSize,
        page: this.state.paramsPage,
        sortby: this.state.paramsSortBy,
        sortorder: this.state.sortOrder,
      },
    });
    this.props.fetchClinics();
    this.props.getServiceList();
  }

  static getDerivedStateFromProps(props, state) {
    let returnState = {};
    if (state.deleteVerticalModal.verticalId === props.deletedVerticalId) {
      const verticalId = state.deleteVerticalModal.verticalId;
      const newVerticalList = state.verticalsList.filter(
        (item) => item.id !== verticalId,
      );
      returnState.deleteVerticalModal = {
        verticalId: "",
      };
      returnState.verticalsList = newVerticalList;
      returnState.showLoader = false;
      return returnState;
    }
    if (props.showLoader != undefined && props.showLoader == false) {
      if (localStorage.getItem("showLoader") == "false") {
        returnState.showLoader = false;
        return returnState;
      }
    } else if (
      props.verticals != undefined &&
      props.verticals.data.length === 0 &&
      localStorage.getItem("showLoader") == "false"
    ) {
      returnState.showLoader = false;
      return returnState;
    } else if (
      props.verticals != undefined &&
      JSON.stringify(props.verticals.data) !==
        JSON.stringify(state.verticalsList) &&
      props.verticalsTimestamp != state.verticalsTimestamp &&
      localStorage.getItem("showLoader") == "false"
    ) {
      if (props.verticals.current_page === 1) {
        returnState.verticalsList = props.verticals.data;
      } else {
        returnState.verticalsList = state.verticalsList.concat(
          props.verticals.data,
        );
      }
      returnState.verticalsTimestamp = new Date(
        props.verticalsTimestamp,
      ).getTime();
      returnState.showLoader = false;
      returnState.lastPage = props.verticals.last_page;
      returnState.paramsPage = state.paramsPage + 1;
      return returnState;
    } else if (
      props.verticalView != undefined &&
      localStorage.getItem("showLoader") == "false"
    ) {
      returnState.showLoader = false;
      let userData = JSON.parse(localStorage.getItem("userData"));
      userData.user.vertical_view = props.verticalView;
      localStorage.setItem("userData", JSON.stringify(userData));
      return returnState;
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.clinicsList &&
      prevProps.clinicsList.length !== prevState.clinicsList.length
    ) {
      this.setState({ clinicsList: prevProps.clinicsList });
    }
    if (
      prevProps.servicesList &&
      prevProps.servicesList.length !== prevState.servicesList.length
    ) {
      this.setState({ servicesList: prevProps.servicesList });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.loadMore);
  }

  handleInputChange(event) {
    localStorage.setItem("showLoader", "true");
    this.setState({
      showLoader: true,
      isVerticalView: +event.target.checked,
    });
    this.props.verticalEnableView({
      enable_checkbox: +event.target.checked,
    });
  }

  toggleVerticalModal(value, isCreatedNew) {
    this.setState({ isVerticalModal: value });
    if (!value) {
      this.setState({ editableVertical: null });
    }
    if (isCreatedNew) {
      this.setState({
        showLoader: true,
        paramsPage: 1,
        verticalsList: [],
      });
      this.props.fetchVerticals({
        params: {
          pagesize: this.state.paramsPageSize,
          page: 1,
          sortby: this.state.paramsSortBy,
          sortorder: this.state.sortOrder,
        },
      });
    }
  }

  openEditVerticalModal(v) {
    this.setState({ editableVertical: v }, () => {
      this.toggleVerticalModal(true);
    });
  }

  sortBy(sortBy) {
    let sortOrder = this.state.sortOrder === "asc" ? "desc" : "asc";
    let formData = {
      params: {
        pagesize: this.state.paramsPageSize,
        page: 1,
        sortby: sortBy,
        sortorder: sortOrder,
      },
    };
    this.setState(
      {
        sortOrder: sortOrder,
        paramsPage: 1,
        showLoader: true,
        verticalsList: [],
      },
      () => {
        this.props.fetchVerticals(formData);
      },
    );
  }

  loadMore = () => {
    const scrollTop = parseInt(
      Math.max(
        window.pageYOffset,
        document.documentElement.scrollTop,
        document.body.scrollTop,
      ),
    );
    if (
      document.documentElement.offsetHeight -
        (window.innerHeight + scrollTop) <=
        5 &&
      this.state.paramsPage <= this.state.lastPage &&
      !this.state.showLoader
    ) {
      this.setState({ showLoader: true });
      this.props.fetchVerticals({
        params: {
          pagesize: this.state.paramsPageSize,
          page: this.state.paramsPage,
          sortby: this.state.paramsSortBy,
          sortorder: this.state.sortOrder,
        },
      });
    }
  };

  dismissDeleteVertical = () => {
    this.setState({
      deleteVerticalModal: {
        isOpen: false,
        verticalName: "",
        verticalId: "",
      },
    });
  };

  confirmDeleteVertical = (id) => {
    this.setState({ showLoader: true });
    this.props.deleteVertical(id);
    this.setState({
      deleteVerticalModal: {
        isOpen: false,
        verticalName: "",
        verticalId: id,
      },
    });
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content">
          <AppointmentConfigNavbar activeVerticals={true} />
          {this.state.isVerticalModal ? (
            <CreateEditVerticals
              locationsList={this.state.clinicsList}
              servicesList={this.state.servicesList}
              vertical={this.state.editableVertical}
              toggleVerticalModal={(created) =>
                this.toggleVerticalModal(false, created)
              }
            />
          ) : (
            <div className="setting-setion full-width">
              <div className="setting-search-outer setting-verticals-outer">
                <span className="searchPageName searchPageName-list">
                  {this.state.vertical_verticals}
                </span>
                <a>
                  <label className="checkbox-label">
                    <input
                      className="checkbox-input"
                      type="checkbox"
                      checked={this.state.isVerticalView}
                      autoComplete="off"
                      onChange={this.handleInputChange.bind(this)}
                    />
                    <span className="checkbox-text">
                      {this.state.vertical_enable_vertical_view}
                    </span>
                  </label>
                </a>
                <a
                  onClick={() => this.toggleVerticalModal(true)}
                  className="blue-btn link"
                >
                  {this.state.vertical_vertical_create}
                </a>
              </div>
              <div className="table-responsive" id="table-verticals">
                <table className="table-updated table-min-width no-td-border">
                  <thead className="table-updated-thead">
                    <tr>
                      <th
                        className="col-xs-3 table-updated-th sorting"
                        onClick={() => this.sortBy("name")}
                      >
                        {this.state.vertical_name}
                        <i
                          className={
                            this.state.sortOrder === "asc" &&
                            this.state.sortBy === "name"
                              ? "blue-gray"
                              : this.state.sortOrder === "desc" &&
                                this.state.sortBy === "name"
                              ? "gray-blue"
                              : "gray-gray"
                          }
                        />
                      </th>
                      <th className="col-xs-3 table-updated-th">
                        {this.state.vertical_clinics}
                      </th>
                      <th className="col-xs-1 table-updated-th">
                        {this.state.vertical_services_included}
                      </th>
                      <th className="col-xs-2 table-updated-th">
                        Included in Primary Clinic URL
                      </th>
                      <th className="col-xs-1 table-updated-th"></th>
                    </tr>
                  </thead>
                  {this.state.verticalsList.length > 0 && (
                    <tbody>
                      {this.state.verticalsList.map((vertical) => {
                        return (
                          <tr
                            key={vertical.id}
                            className="table-updated-tr"
                            onClick={() => this.openEditVerticalModal(vertical)}
                          >
                            <td className="col-xs-3 table-updated-td">
                              {vertical.name}
                            </td>
                            <td className="col-xs-3 table-updated-td">
                              {vertical.locations.length &&
                                vertical.locations.map((l, i, arr) => {
                                  return i === arr.length - 1 ||
                                    arr.length === 1
                                    ? l.clinic.clinic_name
                                    : `${l.clinic.clinic_name}, `;
                                })}
                            </td>
                            <td className="col-xs-1 table-updated-td">
                              {vertical.services_id.length}
                            </td>
                            <td className="col-xs-2 table-updated-td">
                              {Boolean(
                                !vertical.is_excluded_primary_clinic_url,
                              ) && (
                                <span className={"color-palette-checked 2"}>
                                  <i
                                    className="fa fa-check"
                                    style={{ color: "#047ec3" }}
                                  />
                                </span>
                              )}
                            </td>
                            <td className="col-xs-1 table-updated-td text-center">
                              <div
                                className="deleteVertical"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.setState({
                                    deleteVerticalModal: {
                                      isOpen: true,
                                      verticalName: vertical.name,
                                      verticalId: vertical.id,
                                    },
                                  });
                                }}
                              ></div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                  {this.state.verticalsList.length == 0 &&
                    this.state.showLoader === false && (
                      <tbody>
                        <tr className="table-updated-tr">
                          <td
                            className="col-xs-12 table-updated-td text-center"
                            colSpan="7"
                          >
                            {this.state.sorry_no_record_found}
                          </td>
                        </tr>
                      </tbody>
                    )}
                </table>
              </div>
              <div
                className={
                  this.state.showLoader
                    ? "new-loader text-left displayBlock"
                    : "new-loader text-left"
                }
              >
                <div className="loader-outer">
                  <img
                    src="/images/Eclipse.gif"
                    className="loader-img"
                    alt=""
                  />
                  <div className="popup-subtitle">{this.state.please_wait}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={this.state.deleteVerticalModal.isOpen ? "overlay" : ""}
        ></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.deleteVerticalModal.isOpen
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissDeleteVertical}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  Confirmation Delete Vertical
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {`Are you sure you want to delete ${this.state.deleteVerticalModal.verticalName} vertical?`}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={this.dismissDeleteVertical}
                  >
                    {this.state.settingsLang.no_option}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={() =>
                      this.confirmDeleteVertical(
                        this.state.deleteVerticalModal.verticalId,
                      )
                    }
                  >
                    {this.state.settingsLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  localStorage.setItem("showLoader", "false");
  if (state.VerticalsReducer.action === "VERTICAL_LIST") {
    if (state.VerticalsReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.VerticalsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.verticals = state.VerticalsReducer.data.data.verticals;
      returnState.verticalsTimestamp = new Date().getTime();
    }
  }
  if (state.VerticalsReducer.action === "VERTICAL_ENABLE_VIEW") {
    if (state.VerticalsReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.VerticalsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.verticalView = state.VerticalsReducer.data.data.vertical_view;
    }
  }
  if (state.SettingReducer.action === "CLINIC_LIST") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.clinicsList = state.SettingReducer.data.data;
    }
  }
  if (state.AppointmentReducer.action === "SERVICE_SERVICES_LIST") {
    if (state.AppointmentReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.AppointmentReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.servicesList = state.AppointmentReducer.data.data.services;
    }
  }
  if (state.VerticalsReducer.action === "DELETE_VERTICAL") {
    if (state.VerticalsReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.VerticalsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.showLoader = false;
      returnState.deletedVerticalId = state.VerticalsReducer.verticalID;
      toast.success(languageData.global[state.VerticalsReducer.data.message]);
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchVerticals: fetchVerticals,
      verticalEnableView: verticalEnableView,
      deleteVertical: deleteVertical,
      fetchClinics: fetchClinics,
      getServiceList: getServiceList,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Verticals);
