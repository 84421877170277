import React, { useMemo } from "react";
import * as yup from "yup";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { Input } from "../../../../../../../shared/Input/Input";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import {
  composeAmountSchema,
  composeEmailSchema,
  composeZipCodeSchema,
} from "../../../../SalesCheckoutInvoice.schemas";
import { Select } from "../../../../../../../shared/Select/Select";

export function FormCardOnFile() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();
  const { chargeCard } = useInvoiceActions();

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    email: composeEmailSchema(),
    zipCode: composeZipCodeSchema(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: (values) => {
        chargeCard
          .initiate({
            clearentEmail: values.email,
            clearentZip: values.zipCode,
            isSavedCard: true,
            amount: values.amount,
            clearentCardToken: values.cardOnFile.value,
          })
          .then(onClose);
      },
      initialValues: {
        amount: amountToPay || "",
        email: invoice?.patient?.email || "",
        zipCode: invoice?.patient?.cardOnFiles?.[0]?.billingZip || "",
        cardOnFile: {
          label: invoice?.patient?.cardOnFiles?.[0]?.number,
          value: invoice?.patient?.cardOnFiles?.[0]?.cardOnFile,
        },
      },
    });

  const savedCards = useMemo(() => {
    return invoice?.patient?.cardOnFiles?.map((card) => ({
      value: card.cardOnFile,
      label: card.number,
      billingZip: card.billingZip,
    }));
  }, [invoice?.patient?.cardOnFiles]);

  return (
    <div>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.cardOnFile")}
          </InputLabel>
          <Select
            options={savedCards}
            value={form.cardOnFile}
            onChange={(value) => {
              changeFormValue("cardOnFile", value);
              changeFormValue("zipCode", value.billingZip || "");
            }}
          />
        </ModalCharge.InputWrap>
        <ModalCharge.InputRow>
          <ModalCharge.InputWrap fullWidth>
            <InputLabel>{tCommon("label.email")}</InputLabel>
            <Input
              type="email"
              size="medium"
              value={form.email}
              isError={hasError("email")}
              onChange={(e) => changeFormValue("email", e.target.value)}
              placeholder={tCommon("formPlaceholder.email")}
            />
            {hasError("email") && <InputError>{errors.email}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap width="170px">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.zipCode")}
            </InputLabel>
            <Input
              type="number"
              size="medium"
              value={form.zipCode}
              isError={hasError("zipCode")}
              onChange={(e) => changeFormValue("zipCode", e.target.value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.zipCode",
              )}
            />
            {hasError("zipCode") && <InputError>{errors.zipCode}</InputError>}
          </ModalCharge.InputWrap>
        </ModalCharge.InputRow>
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={onClose}
        isCharging={chargeCard.isLoading}
        onCharge={submit}
        isChargeDisabled={!isValid || chargeCard.isLoading}
      />
    </div>
  );
}
