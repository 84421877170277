import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export function useCreateAppointmentMutation(options = {}) {
  return useMutation((dto) => {
    return http.post(
      dto.isConvertingWaitlist
        ? HTTP_ENDPOINTS.postConvertWaitlisltToAppointment()
        : HTTP_ENDPOINTS.postAppointment(),
      {
        //TO DO STRIPE SETUP INTENT
        appointment_waitlist_id: dto.appointment_waitlist_id,
        patient_id: dto.patientId,
        patient_name: dto.patientName,
        patient_phone: dto.patientPhone,
        patient_email: dto.patientEmail,
        clinic_id: dto.clinicId,
        double_booking: dto.doubleBooking,
        patient_to_be_charged: dto.patientToBeCharged,
        date: dto.date,
        time: dto.time,
        notes: dto.notes,
        type_of_appointment: dto.typeOfAppointment,
        outside_scheduled_hours: dto.outsideScheduledHours,
        appointment_service: dto.appointmentService,
        package: dto.package,
        duration: dto.duration,
        provider_id: dto.providerId,
        setup_intent_id: dto.setupIntentId,
        payment_method_id:
          dto.paymentIntentId || dto.selectedClearentCard || null,
        token_id: dto.tokenId,
        clearent_email_id: dto.clearentEmailId,
        clearent_zip: dto.clearentZip,
        "cc-check": dto.ccCheck,
        appointment_id: dto.appointmentId,
      },
    );
  }, options);
}
