import React, { Component } from "react";

export default class MaxCharCount extends Component {
  render() {
    const charLeft = this.props.maxCharCount - this.props.currentValue.length;

    return (
      <div className="filter-count">
        {charLeft} of {this.props.maxCharCount} left
      </div>
    );
  }
}
