import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { viewTreatmentMarkings } from "../../../Actions/TreatmentMarkings/treatActions.js";
import {
  displayName,
  numberFormat,
  toggleBodyScroll,
} from "../../../Utils/services.js";
import defVImage from "../../../_legacy/images/no-image-vertical.png";

class CosmeticTimelinePhotoMarking extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      action: this.props.type ? this.props.type : "profile",
      showLoader: false,
      procedureID: this.props.procedureID,
      globalLang: languageData.global,
      treatmentMarkingData: [],
      def_no_image_vertical: defVImage,
      languageData: languageData.clients,
      image90Height: null,
      image45Height: null,
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });

    this.props.viewTreatmentMarkings(this.state.procedureID);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.showLoader != undefined && props.showLoader == false) {
      return { showLoader: false };
    }
    if (
      props.treatmentMarkingData !== undefined &&
      props.treatmentMarkingData.status === 200 &&
      props.treatmentMarkingData.data !== state.treatmentMarkingData
    ) {
      return {
        treatmentMarkingData: props.treatmentMarkingData.data,
        showLoader: false,
      };
    }

    return null;
  }

  backToClientProfile = () => {
    toggleBodyScroll(false);
    this.props.handleChildState({
      procedureIdForPopup: 0,
      isShowCosmeticPhotoMarkingFlag: false,
    });
  };

  handle90ImageLoad = (event) => {
    const image = event.target;
    if (image.src !== this.state.def_no_image_vertical) {
      this.setState({
        image90Height: image.height,
      });
    }
  };
  handle45ImageLoad = (event) => {
    const image = event.target;
    if (image.src !== this.state.def_no_image_vertical) {
      this.setState({
        image45Height: image.height,
      });
    }
  };

  render() {
    let { treatmentMarkingData: imageData, def_no_image_vertical } = this.state;

    let frontImageSrc = 
      imageData?.procedure_image_data?.patient_image_front &&
      (imageData?.ProcedureDrawingsImage?.drawing_image ||
      imageData?.ProcedureDrawingsImage?.drawing_front_image_with_pins ||
      imageData?.pdf_image?.front_pdf_image_url) ||
      def_no_image_vertical;
    
    let leftImageSrc = 
      imageData?.procedure_image_data?.patient_image_left &&
      (imageData?.ProcedureDrawingsImage?.drawing_left_image_with_pins ||
      imageData?.pdf_image?.left_pdf_image_url) ||
      def_no_image_vertical;
    
    let leftImage45Src = 
      imageData?.procedure_image_data?.patient_image_left_45 &&
      (imageData?.ProcedureDrawingsImage?.drawing_left_image_with_pins_45 ||
      imageData?.pdf_image?.left_pdf_image_45_url) ||
      def_no_image_vertical;
    
    let rightImageSrc = 
      imageData?.procedure_image_data?.patient_image_right &&
      (imageData?.ProcedureDrawingsImage?.drawing_right_image_with_pins ||
      imageData?.pdf_image?.right_pdf_image_url) ||
      def_no_image_vertical;
    
    let rightImage45Src = 
      imageData?.procedure_image_data?.patient_image_right_45 &&
      (imageData?.ProcedureDrawingsImage?.drawing_right_image_with_pins_45 ||
      imageData?.pdf_image?.right_pdf_image_45_url) ||
      def_no_image_vertical;
    
    let backImageSrc = 
      (imageData?.procedure_image_data?.patient_image_back || imageData?.pdf_image?.back_pdf_image_url) &&
      (imageData?.ProcedureDrawingsImage?.drawing_back_image_with_pins ||
      imageData?.pdf_image?.back_pdf_image_url) ||
      def_no_image_vertical;
    
    let backImageLeft45Src = 
      (imageData?.procedure_image_data?.patient_image_back_left_45 || imageData?.pdf_image?.back_pdf_image_left_45_url) &&
      (imageData?.ProcedureDrawingsImage?.drawing_back_left_image_45_with_pins ||
      imageData?.pdf_image?.back_pdf_image_left_45_url) ||
      def_no_image_vertical;
    
    let backImageRight45Src = 
      (imageData?.procedure_image_data?.patient_image_back_right_45 || imageData?.pdf_image?.back_pdf_image_right_45_url) &&
      (imageData?.ProcedureDrawingsImage?.drawing_back_right_image_45_with_pins ||
      imageData?.pdf_image?.back_pdf_image_right_45_url) ||
      def_no_image_vertical;
    
    let proImageSrc = 
      imageData?.procedure_image &&
      (imageData?.ProcedureDrawingsImage?.drawing_image ||
      imageData?.procedure_image_url) ||
      def_no_image_vertical;
    
    let proImage45Src = 
      imageData?.procedure_image_45 &&
      (imageData?.ProcedureDrawingsImage?.drawing_image_45 ||
      imageData?.procedure_image_45_url) ||
      def_no_image_vertical;
    
  
    const img90Styles = {
      maxWidth: "100%",
      height: this.state.image90Height > 400 ? "320px" : "auto",
      width: this.state.image90Height > 400 ? "240px" : "auto",
    };
    const img45Styles = {
      maxWidth: "100%",
      height: this.state.image45Height > 400 ? "320px" : "auto",
      width: this.state.image45Height > 400 ? "240px" : "auto",
    };
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="wide-popup">
            <div className="modal-blue-header">
              <a onClick={this.backToClientProfile} className="popup-cross">
                ×
              </a>
              {this.state.showLoader === false && (
                <span className="popup-blue-name">
                  {this.state.treatmentMarkingData &&
                    this.state.treatmentMarkingData.patient &&
                    displayName(this.state.treatmentMarkingData.patient) +
                      " - "}{" "}
                  {this.state.treatmentMarkingData &&
                    this.state.treatmentMarkingData.procedure_name}
                </span>
              )}
            </div>
            {[
              proImage45Src,
              proImageSrc,
              backImageRight45Src,
              backImageSrc,
              rightImage45Src,
              rightImageSrc,
              leftImage45Src,
              leftImageSrc,
              frontImageSrc,
            ].some((image) => image !== this.state.def_no_image_vertical) ? (
              <div className="wide-popup-wrapper time-line">
                {imageData && imageData.type !== "laser" ? (
                  <div className="pdfImageContainer">
                    {(imageData?.type === "coolsculpting"
                      ? [leftImage45Src, frontImageSrc, rightImage45Src]
                      : [proImage45Src]
                    ).some(
                      (image) => image !== this.state.def_no_image_vertical,
                    ) && (
                      <>
                        <div className="juvly-subtitle">
                          {this.state.languageData.pro_image_45_deg}
                        </div>
                        <div className="row">
                          {imageData?.type === "coolsculpting" && (
                            <>
                              <div className="col-sm-4 col-xs-12">
                                <div className="procedure-img">
                                  <img src={leftImage45Src} />
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="procedure-img">
                                  <img src={frontImageSrc} />
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="procedure-img">
                                  <img src={rightImage45Src} />
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={
                               imageData?.type === "coolsculpting"
                                ? "col-xs-12 text-center no-display"
                                : "col-xs-12 text-center "
                            }
                          >
                            <div className="procedure-img">
                              <img
                              onLoad={this.handle45ImageLoad}
                              style={img45Styles}
                              
                               src={proImage45Src} />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {[
                      leftImageSrc,
                      rightImageSrc,
                      imageData?.type === "other" ? frontImageSrc : proImageSrc,
                    ].some(
                      (image) => image !== this.state.def_no_image_vertical,
                    ) && (
                      <>
                        <div className="juvly-subtitle m-t-40">
                          {this.state.languageData.pro_image_90_deg}
                        </div>
                        <div className="row">
                          {imageData && imageData.type === "coolsculpting" && (
                            <>
                              <div className="col-sm-4 col-xs-12">
                                <div className="procedure-img">
                                  <img src={leftImageSrc} />
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="procedure-img">
                                  <img src={frontImageSrc} />
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="procedure-img">
                                  <img src={rightImageSrc} />
                                </div>
                              </div>
                            </>
                          )}
                          <div
                            className={
                              imageData?.type === "coolsculpting"
                                ? "col-xs-12 text-center no-display"
                                : "col-xs-12 text-center "
                            }
                          >
                            <div className="procedure-img">
                              <img
                               onLoad={this.handle90ImageLoad}
                               style={img90Styles}
                                src={
                                   imageData?.type === "other"
                                    ? frontImageSrc
                                    : proImageSrc
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {[
                      backImageLeft45Src,
                      backImageSrc,
                      backImageRight45Src,
                    ].some(
                      (image) => image !== this.state.def_no_image_vertical,
                    ) && (
                      <>
                        <div
                          className={
                            imageData?.type === "coolsculpting"
                              ? "juvly-subtitle m-t-40"
                              : "juvly-subtitle m-t-40 no-display"
                          }
                        >
                          {this.state.languageData.client_pro_image_back}
                        </div>
                        <div
                          className={
                            imageData?.type === "coolsculpting"
                              ? "row"
                              : "row no-display"
                          }
                        >
                          <div className="col-sm-4 col-xs-12">
                            <div className="procedure-img">
                              <img src={backImageLeft45Src} />
                            </div>
                          </div>
                          <div className="col-sm-4 col-xs-12">
                            <div className="procedure-img">
                              <img src={backImageSrc} />
                            </div>
                          </div>
                          <div className="col-sm-4 col-xs-12">
                            <div className="procedure-img">
                              <img src={backImageRight45Src} />
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : 
                  imageData?.type === "laser" &&
                  [leftImage45Src, frontImageSrc, rightImage45Src].some(
                    (image) => image !== this.state.def_no_image_vertical,
                  ) ? (
                  <div className="pdfImageContainer">
                    <div className="juvly-subtitle">
                      {this.state.languageData.client_pro_images}
                    </div>
                    <div className="row">
                      <div className="col-sm-4 col-xs-12">
                        <div className="procedure-img">
                          <img src={leftImage45Src} />
                        </div>
                      </div>
                      <div className="col-sm-4 col-xs-12">
                        <div className="procedure-img">
                          <img src={frontImageSrc} />
                        </div>
                      </div>
                      <div className="col-sm-4 col-xs-12">
                        <div className="procedure-img">
                          <img src={rightImage45Src} />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  frontImageSrc !== this.state.def_no_image_vertical && (
                    <div className="col-xs-12 text-center">
                      <div className="procedure-img">
                        <img src={frontImageSrc} />
                      </div>
                    </div>
                  )
                )}
                <div className="p-r-15 p-l-15 timeline-detail">
                  <h4>TREATMENT SUMMARY</h4>
                  <ul className="treat-sumry profile-treat-sumry no-padding">
                    {  imageData?.injections?.length ? (
                      imageData.injections.map((injobj, injidx) => {
                        return (
                          <li key={injidx}>
                            <label>
                              <span
                              className="color-box"
                                style={{
                                  backgroundColor: injobj.color.startsWith("#")
                                    ? injobj.color
                                    : "rgba(72.0,193.0,212.0)",
                                }}
                              />
                              {"  "}
                              <div
                            className="product-name"
                              >
                                {injobj.product_name}
                              </div>
                            </label>{" "}
                            <span>
                              {injobj.quantity
                                ? numberFormat(injobj.quantity, "decimal", 1)
                                : ""}{" "}
                              {injobj.unit}
                            </span>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <label className="sorry-no-record">
                          {"Sorry, No record found"}
                        </label>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              <div className="wide-popup-wrapper time-line">
                <div className="row">
                  <div className="col-xs-12 text-center ">
                    <div className="procedure-img">
                      <img src={this.state.def_no_image_vertical} />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              className={
                this.state.showLoader
                  ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
                  : "new-loader text-left"
              }
            >
              <div className="loader-outer">
                <img
                  id="loader-outer"
                  src="/images/Eclipse.gif"
                  className="loader-img"
                />
                <div id="modal-confirm-text" className="popup-subtitle">
                  {this.state.globalLang.loading_please_wait_text}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.TreatmentMarkingReducer.action === "VIEW_TREATMENT_MARKINGS") {
    if (state.TreatmentMarkingReducer.data.status != 200) {
      toast.error(
        languageData.global[state.TreatmentMarkingReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.treatmentMarkingData = state.TreatmentMarkingReducer.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { viewTreatmentMarkings: viewTreatmentMarkings },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CosmeticTimelinePhotoMarking);
