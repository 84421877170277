import { useState } from "react";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateAppointmentMutation } from "../../../api/mutations/useCreateAppointmentMutation";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import { uiNotification } from "../../../services/UINotificationService";
import { getClearentToken } from "../../../Utils/services";
// eslint-disable-next-line import/no-restricted-paths
import { QueryKeys } from "../../../_legacy/Queries";
import { updatePageFilter } from "../../../Utils/localStorage";
import { extractApiError } from "../../../utilities/api";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { phoneUtil } from "../../../utilities/phone";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { APPT_API_TIME_FORMAT } from "../Event.consts";
import { getPathParams } from "../Event.utils";

const VALIDATION_ERROR = {
  providerNotAvailable: "Provider is not available",
  startTimeNotAvailable: "Start time is not available",
  ccRequired:
    "Enter Credit Card details (You will not be able to charge deposit if Credit Card details are not filled",
  invalidCC: "Invalid Card Details",
  userNotFound: "User not found",
};

const composeNotification = (response) => {
  let result = null;
  const notification = response?.data?.data;
  if (notification) {
    result = {
      appointmentId: notification.appointment_id,
      emailText: notification.appointment_email_text,
      sms: notification.appointment_sms,
      subject: notification.appointment_subject,
      type: notification.noti_type,
      patientName: notification.patient_name,
    };
  }
  return result;
};

const composeSuccess = (response) => {
  const data = response?.data?.data;
  if (data) {
    return {
      appointmentId: data.appointment_id || null,
    };
  }
  return null;
};

export function useMutateAppointment({
  providers,
  availableHoursByDate,
  isCanTakePaymentWithDeposit,
  isCanTakePaymentWithCancelationPolicy,
  clientCardNumber,
  setNotification,
  editEventId,
  confirmCancellationPolicyExcluding,
  isConvertingWaitlist,
  onSuccess,
  stripeIntent,
  cardSelected,
  newCard,
  resetClient,
  selectedClearentCard,
}) {
  const { tCommon } = useAppTranslation.Common();
  const isEditMode = Boolean(editEventId);
  const [isLoading, setIsLoading] = useState(false);
  const { data: user } = useCurrentUserQuery();
  const { leadId } = getPathParams();

  const queryClient = useQueryClient();

  const { mutateAsync, isLoading: isRequestLoading } =
    useCreateAppointmentMutation({
      onSuccess: () => {
        if (isConvertingWaitlist) {
          queryClient.invalidateQueries(QueryKeys.GET_WAITLIST);
          updatePageFilter("WAITLIST", {
            sort_by: "created_at",
            sort_dir: "desc",
          });
        }
        uiNotification.success(
          `Successfully ${
            isEditMode ? "updated" : "created"
          } 'Appointment' event`,
        );
      },
    });

  const mutate = async (values, { setFieldError, setFieldValue }) => {
    try {
      setIsLoading(true);

      if (!user) {
        throw new Error(VALIDATION_ERROR.userNotFound);
      }

      if (!providers.find((p) => p.id === values.providerId)) {
        setFieldError("providerId", "");
        throw new Error(VALIDATION_ERROR.providerNotAvailable);
      }

      if (!availableHoursByDate.includes(values.timeStart)) {
        setFieldError("timeStart", "");
        throw new Error(VALIDATION_ERROR.startTimeNotAvailable);
      }

      // APT_PAY: See point V.1 in usePaymentInfoAppointment
      if (isCanTakePaymentWithDeposit && !values.isEnterCreditCardDetails) {
        throw new Error(VALIDATION_ERROR.ccRequired);
      }

      // APT_PAY: See point III.1 in usePaymentInfoAppointment
      if (
        isCanTakePaymentWithCancelationPolicy &&
        !values.isEnterCreditCardDetails &&
        !values.isCancellationPolicyExcludeConfirmed
      ) {
        return confirmCancellationPolicyExcluding();
      }

      const { isConvertingWaitlist, appointment_waitlist_id } = values;
      const dto = {
        isConvertingWaitlist,
        appointment_waitlist_id,
        patientToBeCharged: values.isPatientToBeCharged ? 1 : 0,
        package: 0,
        patientId: values.clientId,
        patientName: values.clientName,
        patientPhone: phoneUtil.compose(values.clientPhone),
        patientEmail: values.clientEmail,
        clinicId: values.clinicId,
        doubleBooking: values.isDoubleBooking,
        date: values.date,
        time: moment(values.timeStart, user.timeFormat).format(
          APPT_API_TIME_FORMAT,
        ),
        notes: values.notes,
        typeOfAppointment: values.type,
        outsideScheduledHours: values.isOutsideScheduledHours,
        appointmentService: values.services.map((s) => ({
          id: s.id,
          duration: s.durationInMin,
          confirm_deposit: s.isDepositConfirmed,
        })),
        duration: values.services.reduce((r, s) => (r += s.durationInMin), 0),
        providerId: values.providerId,
        // APT_PAY: See point I in usePaymentInfoAppointment
        ccCheck:
          !(
            isCanTakePaymentWithDeposit || isCanTakePaymentWithCancelationPolicy
          ) || !values.isEnterCreditCardDetails
            ? 0
            : 1,
        selectedClearentCard,
      };

      if (values.userPaymentSystem === USER_PAYMENT_SYSTEMS.stripe) {
        dto.setupIntentId = "";
        dto.paymentIntentId = "";
        if (newCard) dto.setupIntentId = stripeIntent.setupIntentId;
        else dto.paymentIntentId = cardSelected;
      }

      if (
        values.isEnterCreditCardDetails &&
        (values.isAddNewCard || !clientCardNumber)
      ) {
        if (values.userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent) {
          const cardDataToken = await getClearentToken();
          if (cardDataToken) {
            dto.tokenId = cardDataToken;
            dto.clearentEmailId = values.clearentEmail;
            dto.clearentZip = values.clearentZipCode;
          } else {
            throw new Error(VALIDATION_ERROR.invalidCC);
          }
        }
      }

      if (
        values.isEnterCreditCardDetails &&
        (!values.isAddNewCard || clientCardNumber) &&
        values.userPaymentSystem === USER_PAYMENT_SYSTEMS.clearent
      ) {
        dto.clearentEmailId = values.clearentEmail;
        dto.clearentZip = values.clearentZipCode;
      }

      if (editEventId) {
        dto.appointmentId = editEventId;
      }

      const response = await mutateAsync(dto);
      const notification = composeNotification(response);
      onSuccess(composeSuccess(response));

      if (notification) {
        setNotification(notification);
      }
    } catch (e) {
      if (
        ![VALIDATION_ERROR.startTimeNotAvailable].includes(e.message) &&
        !leadId
      ) {
        resetClient();
      }

      const message = extractApiError(e);

      uiNotification.error(
        tCommon(`appointments.apiErrors.${message}`, message || e.message),
      );

      setFieldValue("isCancellationPolicyExcludeConfirmed", false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    mutateAppointment: mutate,
    isAppointmentMutating: isLoading || isRequestLoading,
  };
}
