import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";

export const RouterPrompt = withRouter((props) => {
  const { when, title, text } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");

  const unblockRef = useRef();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    setShowPrompt(false);
  }

  useEffect(() => {
    unblockRef.current = props.history.block((location) => {
      if (when) {
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }
      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when]);

  function handleConfirm() {
    if (unblockRef) {
      unblockRef.current();
    }
    setShowPrompt(false);
    props.history.push(currentPath);
  }

  return showPrompt ? (
    <ConfirmModal
      isOpen={true}
      title={title}
      onConfirm={handleConfirm}
      onCancel={onCancel}
    >
      {text}
    </ConfirmModal>
  ) : null;
});
