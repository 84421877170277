import React, { useRef } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Modal } from "../../shared/Modal/Modal";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { useForm } from "./hooks/useForm";
import { InputLabel } from "../../shared/InputLabel/InputLabel";
import { Select } from "../../shared/Select/Select";
import { InputError } from "../../shared/InputError/InputError";
import { Button } from "../../shared/Button/Button";
import classes from "./ModalCreateQualiphyProcedure.module.scss";
import { useEnrolledClinics } from "./hooks/useEnrolledClinics";
import { useExams } from "./hooks/useExams";
import {
  getAlreadyRequestedFormExams,
  useCreateInvite,
} from "./hooks/useCreateInvite";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { InputPhone } from "../../shared/InputPhone/InputPhone";
import { DatePicker } from "../../shared/DatePicker/DatePicker";
import { PREVIEW_DATE_FORMAT } from "../../consts/general";
import { API_DATE_FORMAT } from "../../consts/api";
import { formatUTC } from "../../Utils/dateHelper";
import { Input } from "../../shared/Input/Input";
import { passOr } from "../../utilities/general";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";
import { AlreadyRequestedBox } from "./shared/AlreadyRequestedBox/AlreadyRequestedBox";
import { Attachments } from "./shared/Attachments/Attachments";
import { useQualiphyMessageQuery } from "../../api/qualiphy/useQualiphyMessageQuery";

export function ModalCreateQualiphyProcedure({
  isOpen,
  onClose,
  onSuccess,
  patientId,
  title,
  initialValues,
  appointmentId,
}) {
  const dateOfBirthRef = useRef(null);
  const { tCommon } = useAppTranslation.Common();
  const createInvite = useCreateInvite({ onSuccess, patientId, appointmentId });
  const { clinicOptions, isClinicsFetching } = useEnrolledClinics();
  const { data: message } = useQualiphyMessageQuery();

  const { form, hasError, setFormValue, isValid, errors, submit } = useForm({
    onSubmit: (values) => createInvite.initiate(values),
    additionPatientFields: createInvite.additionPatientFields,
    initialValues: initialValues || {},
  });

  const { examOptions, isExamsFetching } = useExams({
    clinicId: form.clinicId,
  });

  const alreadyRequestedExams = getAlreadyRequestedFormExams(
    form.exams,
    createInvite.alreadyRequestedExams,
  );

  const isAttachmentsRequired = form.exams.some(
    (exam) => exam.isAttachmentsRequired,
  );

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      shouldCloseOnOverlayClick={false}
      header={
        <Modal.Title>
          {title || tCommon("createQualifyProcedure.title")}
        </Modal.Title>
      }
      footer={
        <div className={classes.footer}>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
          <Button
            onClick={submit}
            isDisabled={!isValid || createInvite.isLoading}
            leftAdornment={passOr(createInvite.isLoading, undefined, () => (
              <CircularProgress size="small" color="white" />
            ))}
          >
            {tCommon("label.create")}
          </Button>
        </div>
      }
    >
      <div>
        <InputLabel>{tCommon("label.clinic")}</InputLabel>
        <Select
          value={clinicOptions.find((c) => c.value === form.clinicId)}
          options={clinicOptions}
          onChange={(option) => {
            setFormValue("clinicId", option.value);
            if (form.exams.length > 0 && option.value !== form.clinicId) {
              setFormValue("exams", []);
            }
          }}
          isLoading={isClinicsFetching}
          isError={hasError("clinicId")}
        />
        {hasError("clinicId") && <InputError>{errors.clinicId}</InputError>}
      </div>
      {form.clinicId && (
        <div>
          <InputLabel>{tCommon("label.exams")}</InputLabel>
          <Select
            isMulti
            isSearchable
            closeMenuOnSelect={false}
            options={examOptions}
            isLoading={isExamsFetching}
            isError={hasError("exams")}
            onChange={(options) => setFormValue("exams", options)}
            value={form.exams}
          />
          {hasError("exams") && <InputError>{errors.exams}</InputError>}
        </div>
      )}
      {isAttachmentsRequired && (
        <Attachments
          message={message}
          value={form.attachments}
          onChange={(next) => setFormValue("attachments", next)}
        />
      )}
      {createInvite.additionPatientFields.some((f) =>
        Object.values(ADDITION_FIELDS).includes(f),
      ) && (
        <div className={classes.additionData}>
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.firstName,
          ) && (
            <div>
              <InputLabel>
                {tCommon("createQualifyProcedure.formLabel.firstName")}
              </InputLabel>
              <Input
                value={form.firstName}
                placeholder={tCommon("label.firstName")}
                onChange={(e) => setFormValue("firstName", e.target.value)}
                isError={hasError("firstName")}
              />
              {hasError("firstName") && (
                <InputError>{errors.firstName}</InputError>
              )}
            </div>
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.lastName,
          ) && (
            <div>
              <InputLabel>
                {tCommon("createQualifyProcedure.formLabel.lastName")}
              </InputLabel>
              <Input
                value={form.lastName}
                placeholder={tCommon("label.lastName")}
                onChange={(e) => setFormValue("lastName", e.target.value)}
                isError={hasError("lastName")}
              />
              {hasError("lastName") && (
                <InputError>{errors.lastName}</InputError>
              )}
            </div>
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.email,
          ) && (
            <div>
              <InputLabel>
                {tCommon("createQualifyProcedure.formLabel.email")}
              </InputLabel>
              <Input
                value={form.email}
                placeholder={tCommon("formPlaceholder.email")}
                onChange={(e) => setFormValue("email", e.target.value)}
                isError={hasError("email")}
              />
              {hasError("email") && <InputError>{errors.email}</InputError>}
            </div>
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.phone,
          ) && (
            <div>
              <InputLabel>
                {tCommon("createQualifyProcedure.formLabel.phone")}
              </InputLabel>
              <InputPhone
                fullWidth
                value={form.phone}
                onChange={(phone) => setFormValue("phone", phone)}
                isError={hasError("phone")}
              />
              {hasError("phone") && <InputError>{errors.phone}</InputError>}
            </div>
          )}
          {createInvite.additionPatientFields.includes(
            ADDITION_FIELDS.dateOfBirth,
          ) && (
            <div>
              <InputLabel>
                {tCommon("createQualifyProcedure.formLabel.dateOfBirth")}
              </InputLabel>
              <DatePicker
                showMonthDropdown
                showYearDropdown
                ref={dateOfBirthRef}
                onChangeRaw={(e) => e.preventDefault()}
                isError={hasError("dateOfBirth")}
                maxDate={new Date()}
                onClickOutside={() => dateOfBirthRef.current.setOpen(false)}
                value={passOr(form.dateOfBirth, null, () =>
                  moment.utc(form.dateOfBirth).format(PREVIEW_DATE_FORMAT),
                )}
                onChange={(next) =>
                  setFormValue(
                    "dateOfBirth",
                    moment(next).format(API_DATE_FORMAT),
                  )
                }
                selected={passOr(form.dateOfBirth, null, () =>
                  formatUTC(moment.utc(form.dateOfBirth).toDate()),
                )}
              />
              {hasError("dateOfBirth") && (
                <InputError>{errors.dateOfBirth}</InputError>
              )}
            </div>
          )}
        </div>
      )}
      {alreadyRequestedExams.length > 0 && (
        <AlreadyRequestedBox exams={alreadyRequestedExams} />
      )}
    </Modal>
  );
}

ModalCreateQualiphyProcedure.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func,
  patientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  title: PropTypes.string,
  appointmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  initialValues: PropTypes.object,
};

ModalCreateQualiphyProcedure.defaultProps = {
  onSuccess: () => {},
  title: null,
  appointmentId: null,
  initialValues: null,
};
