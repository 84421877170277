import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import calendarIcon from "../../../_legacy/images/calender.svg";
import { useClickOutside } from "../../../utilities/hooks/useClickOutside";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { DEFAULT_DATE_FORMAT } from "../../../consts/general";
import { toExactMoment } from "../../../utilities/date";

export function WidgetDateRange({ className, ranges, onChange }) {
  const rootRef = useRef(null);
  const [isCalendarVisible, setIsCalendarVisible] = useState(false);
  const [clickedTimes, setClickedTimes] = useState(0);
  const { data: user } = useCurrentUserQuery();

  const value = (() => {
    const format = (date) =>
      toExactMoment(date).format(user?.dateFormat || DEFAULT_DATE_FORMAT);
    if (ranges.startDate && ranges.endDate) {
      return `${format(ranges.startDate)}-${format(ranges.endDate)}`;
    }
    return "";
  })();

  const handleChange = (payload) => {
    let clicked = clickedTimes + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    setClickedTimes(clicked);

    if (clicked && clicked % 2 === 0) {
      setIsCalendarVisible(false);
    }

    onChange({
      startDate: payload.selection.startDate,
      endDate: payload.selection.endDate,
    });
  };

  useClickOutside(rootRef, () => setIsCalendarVisible(false));

  return (
    <div ref={rootRef} className={cx("memberRightActions", className)}>
      <div className="search-bg new-calender pull-left">
        <img src={calendarIcon} alt="calendar" />
        {isCalendarVisible && (
          <DateRangePicker
            className="CalendarPreviewArea"
            ranges={[
              {
                startDate: new Date(ranges.startDate),
                endDate: new Date(ranges.endDate),
                key: "selection",
              },
            ]}
            onChange={handleChange}
            fromMonth={new Date()}
            disabledDays={{ before: new Date() }}
            dragSelectionEnabled={false}
          />
        )}
        <input
          type="text"
          className="input-cal setting-search-input p-b-5"
          name="calendar-input"
          value={value}
          autoComplete="off"
          onChange={() => {}}
          onClick={() => setIsCalendarVisible(true)}
        />
      </div>
    </div>
  );
}

WidgetDateRange.propTypes = {
  className: PropTypes.string,
  ranges: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};
