/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import validator from "validator";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import {
  isPositiveNumber,
  isFormSubmit,
  decimalPlaces,
} from "../../../Utils/services.js";
import popupClose from "../../../_legacy/images/popupClose.png";

class StockTransferProductModal extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,

      productStockData: null,
      transfer: null,
    };
  }

  hideStockTransferProductModal = () => {
    this.props.hideStockTransferProductModal({
      showStockTransferProductModal: false,
    });
  };

  acceptStockTransfer = (event) => {
    event.preventDefault();
    if (isFormSubmit()) {
      let error = false;
      let transfer = this.props.transfer;
      let acceptStocks = [];
      let errormesage = "";
      if (transfer && transfer.length > 0) {
        transfer.map((objProduct, idxProduct) => {
          if (objProduct.product) {
            let stockData = objProduct.product;
            stockData.units = objProduct.units;
            stockData.product_id = objProduct.product_id; // set product-id
            stockData.id = objProduct.id; // set stock-transfer-product-id
            let productBatch = [];
            objProduct.batches.map((objBatch, idxBatch) => {
              if (objBatch.received_units >= 0) {
                if (
                  !isPositiveNumber(
                    objBatch.received_units,
                    0,
                    objBatch.transfer_units,
                    4,
                  )
                ) {
                  objProduct.batches[idxBatch].received_unitsClass =
                    "simpleInput field-error";
                  error = true;
                } else {
                  objProduct.batches[idxBatch].received_unitsClass =
                    "simpleInput";
                  if (objBatch.received_units == objBatch.transfer_units) {
                    objProduct.batches[idxBatch].received_type = "fully";
                    objProduct.batches[idxBatch].received_status = "received";
                  } else {
                    objProduct.batches[idxBatch].received_type = "partially";
                    objProduct.batches[idxBatch].received_status = "markas";
                  }
                }
              } else {
                objProduct.batches[idxBatch].received_unitsClass =
                  "simpleInput field-error";
                error = true;
              }

              // validate damage unit
              if (objBatch.damage_units >= 0) {
                if (
                  !isPositiveNumber(
                    objBatch.damage_units,
                    0,
                    objBatch.transfer_units,
                    4,
                  )
                ) {
                  objProduct.batches[idxBatch].damage_unitsClass =
                    "simpleInput field-error";
                  error = true;
                } else {
                  objProduct.batches[idxBatch].damage_unitsClass =
                    "simpleInput";
                }
              } else {
                objProduct.batches[idxBatch].damage_unitsClass =
                  "simpleInput field-error";
                error = true;
              }

              if (
                parseFloat(objBatch.transfer_units) !=
                parseFloat(objBatch.received_units) +
                  parseFloat(objBatch.damage_units)
              ) {
                objProduct.batches[idxBatch].damage_unitsClass =
                  "simpleInput field-error";
                objProduct.batches[idxBatch].received_unitsClass =
                  "simpleInput field-error";
                error = true;
                errormesage = "Quantity doesn't match the transferred stock";
              }

              if (!isPositiveNumber(objBatch.cost_to_company, 0)) {
                objProduct.batches[idxBatch].ctcClass =
                  "simpleInput field-error";
                error = true;
              } else {
                objProduct.batches[idxBatch].ctcClass = "simpleInput";
              }

              if (objBatch.damage_units > 0) {
                if (
                  objBatch.reason == "" ||
                  validator.trim(objBatch.reason) == ""
                ) {
                  error = true;
                  objProduct.batches[idxBatch].reasonClass =
                    "simpleInput field-error";
                } else {
                  objProduct.batches[idxBatch].reasonClass = "simpleInput";
                }
              } else {
                objProduct.batches[idxBatch].reasonClass = "simpleInput";
              }

              let batches = {
                stock_transfer_batch_id: objBatch.id,
                inventory_id: objBatch.inventory_id,
                batch_id: objBatch.batch_id,
                units: objBatch.units,
                transfer_units: objBatch.transfer_units,
                received_units: objBatch.received_units,
                damage_units: objBatch.damage_units,
                received_type: objBatch.received_type,
                received_status: objBatch.received_status,
                cost_to_company: objBatch.cost_to_company,
                reason: objBatch.reason,
              };
              productBatch.push(batches);
            });
            transfer[idxProduct] = objProduct;
            stockData.batches = productBatch;
            acceptStocks.push(stockData);
          }
        });
        this.props.handleChildState({ transfer: transfer });
        if (error) {
          if (errormesage) {
            toast.dismiss();
            toast.error(errormesage);
          }
          return false;
        }

        this.props.acceptStockTransfer(acceptStocks);
      }
    }
  };

  handleRowInputChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox"
        ? target.checked
          ? true
          : false
        : target.value;
    const productIndex = target.dataset.productIndex;
    const batchIndex = target.dataset.batchIndex;

    let transfer = this.props.transfer;
    let row = transfer[productIndex];

    let batch = row["batches"][batchIndex];
    if (batch) {
      if (event.target.name === "received_units") {
        if (decimalPlaces(value) > 3) {
          return;
        }
        batch.received_units = value;
        if (value >= 0) {
          if (!isPositiveNumber(value, 0, batch.transfer_units, 4)) {
            batch.received_unitsClass = "simpleInput field-error";
          } else if (
            parseFloat(batch.transfer_units) <
            parseFloat(batch.received_units) + parseFloat(batch.damage_units)
          ) {
            batch.received_unitsClass = "simpleInput field-error";
            batch.damage_unitsClass = "simpleInput field-error";
          } else {
            batch.received_unitsClass = "simpleInput";
            batch.damage_unitsClass = "simpleInput";
          }
        } else {
          batch.received_unitsClass = "simpleInput field-error";
        }
        row["batches"][batchIndex] = batch;
      } else if (event.target.name === "damage_units") {
        if (decimalPlaces(value) > 3) {
          return;
        }
        batch.damage_units = value;
        if (value >= 0) {
          if (!isPositiveNumber(value, 0, batch.transfer_units, 4)) {
            batch.damage_unitsClass = "simpleInput field-error";
          } else if (
            parseFloat(batch.transfer_units) <
            parseFloat(batch.received_units) + parseFloat(batch.damage_units)
          ) {
            batch.received_unitsClass = "simpleInput field-error";
            batch.damage_unitsClass = "simpleInput field-error";
          } else {
            batch.received_unitsClass = "simpleInput";
            batch.damage_unitsClass = "simpleInput";
          }
        } else {
          batch.damage_unitsClass = "simpleInput field-error";
        }
      } else if (event.target.name === "reason") {
        batch.reason = value;
      } else if (event.target.name === "cost_to_company") {
        if (!isPositiveNumber(value, 0)) {
          batch.ctcClass = "simpleInput field-error";
        } else {
          batch.ctcClass = "simpleInput";
        }
        batch.cost_to_company = value;
      }
      row["batches"][batchIndex] = batch;
    }
    transfer[productIndex] = row;
    this.props.handleChildState({ transfer: transfer });
  };

  render() {
    return (
      <div className={"blackOverlay"}>
        <div className="vert-middle">
          <div className="loyaltyMembership extra-large-popup confirmAcceptPopup">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle m-b-20">
                {"Confirm & Acccept"}
                <a
                  onClick={() => this.hideStockTransferProductModal()}
                  className="popupClose"
                >
                  <img alt="" src={popupClose} />
                </a>
              </div>
            </div>
            <div className="table-responsive ">
              <table className="table-updated juvly-table min-w-850 no-td-border no-hover">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-2 table-updated-th">Name</th>
                    <th className="col-xs-1 table-updated-th">Batch Id</th>
                    <th className="col-xs-1 table-updated-th">
                      Transferred Stock
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      Received Stock
                    </th>
                    <th className="col-xs-2 table-updated-th">Damaged Stock</th>
                    <th className="col-xs-2 table-updated-th">CTC/Unit</th>
                    <th className="col-xs-2 table-updated-th">Reason</th>
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.props.transfer &&
                    this.props.transfer.length > 0 &&
                    this.props.transfer.map((objProduct, idxProduct) => {
                      return (
                        <React.Fragment
                          key={"transfer-objProduct-" + idxProduct}
                        >
                          {objProduct.batches.map((objBatch, idxBatch) => {
                            return (
                              <tr
                                className="table-updated-tr"
                                key={
                                  "transfer-objProduct-" +
                                  idxProduct +
                                  "-objBatch-" +
                                  idxBatch
                                }
                              >
                                <td className="table-updated-td">
                                  {objProduct.product &&
                                  objProduct.product.product_name
                                    ? objProduct.product.product_name
                                    : ""}
                                </td>
                                <td className="table-updated-td">
                                  {objProduct.product &&
                                  objProduct.product.product_type === "others"
                                    ? "N/A"
                                    : objBatch.batch_id}
                                </td>
                                <td className="table-updated-td">
                                  {objBatch.units}
                                </td>
                                <td className="table-updated-td">
                                  <div className="simpleField no-margin">
                                    <input
                                      type={"text"}
                                      className={objBatch.received_unitsClass}
                                      autoComplete="off"
                                      name={"received_units"}
                                      value={objBatch.received_units}
                                      onChange={this.handleRowInputChange}
                                      data-product-index={idxProduct}
                                      data-batch-index={idxBatch}
                                    />
                                    {objBatch.transfer_units > 0 && (
                                      <span className="maxCharacterInstruction">
                                        Max: {objBatch.transfer_units}
                                      </span>
                                    )}
                                  </div>
                                </td>
                                <td className="table-updated-td">
                                  <div className="simpleField no-margin">
                                    <input
                                      type={"text"}
                                      className={objBatch.damage_unitsClass}
                                      autoComplete="off"
                                      name={"damage_units"}
                                      value={objBatch.damage_units}
                                      onChange={this.handleRowInputChange}
                                      data-product-index={idxProduct}
                                      data-batch-index={idxBatch}
                                    />
                                    {objBatch.transfer_units > 0 && (
                                      <span className="maxCharacterInstruction">
                                        Min: 0
                                      </span>
                                    )}
                                  </div>
                                </td>

                                <td className="table-updated-td">
                                  <div className="simpleField no-margin">
                                    <input
                                      type={"text"}
                                      className={objBatch.ctcClass}
                                      autoComplete="off"
                                      name={"cost_to_company"}
                                      value={objBatch.cost_to_company}
                                      onChange={this.handleRowInputChange}
                                      data-product-index={idxProduct}
                                      data-batch-index={idxBatch}
                                    />
                                    {objBatch.transfer_units > 0 && (
                                      <span className="maxCharacterInstruction">
                                        &nbsp;
                                      </span>
                                    )}
                                  </div>
                                </td>

                                <td className="table-updated-td">
                                  <div className="simpleField no-margin">
                                    <input
                                      type={"text"}
                                      className={objBatch.reasonClass}
                                      autoComplete="off"
                                      name={"reason"}
                                      value={objBatch.reason}
                                      onChange={this.handleRowInputChange}
                                      data-product-index={idxProduct}
                                      data-batch-index={idxBatch}
                                    />
                                    {objBatch.transfer_units > 0 && (
                                      <span className="maxCharacterInstruction">
                                        &nbsp;
                                      </span>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                  {(!this.props.transfer ||
                    this.props.transfer.length <= 0) && (
                    <tr>
                      <td
                        colSpan={5}
                        className="col-xs-12 table-updated-td text-center "
                      >
                        <div className={"no-record"}>
                          {"Product not selected yet!"}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="footer-static p-r-0">
              <a
                className="new-blue-btn pull-right m-l-0"
                onClick={this.acceptStockTransfer}
              >
                {"Accept"}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(StockTransferProductModal);
