import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../shared/Modal/Modal";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import classes from "./ChangeClinic.module.scss";
import { Select } from "../../../../shared/Select/Select";
import { useClinics } from "./hooks/useClinics";
import { useForm } from "./hooks/useForm";
import { useSubmit } from "./hooks/useSubmit";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";

export function ChangeClinic({ invoiceId, clinicId, onSuccess }) {
  const { tSales } = useAppTranslation.Sales();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clinics = useClinics({ clinicId });

  const submit = useSubmit({
    invoiceId,
    onSuccess: () => {
      onSuccess();
      setIsModalOpen(false);
    },
  });

  const form = useForm({
    initialClinic: clinics.currentClinic,
    onSubmit: ({ clinic }) => submit.initiate(clinic.value),
  });

  return (
    <>
      <button
        className={classes.openModalBtn}
        onClick={() => setIsModalOpen(true)}
      >
        <i className="fa fa-pen" aria-hidden="true"></i>
      </button>
      {isModalOpen && (
        <Modal
          isOpen
          headerNoBorder
          footerNoBorder
          onClose={() => setIsModalOpen(false)}
          header={
            <Modal.Title>{tSales("invoice.changeClinic.title")}</Modal.Title>
          }
          footer={
            <div className={classes.footer}>
              <Button
                onClick={form.handleSubmit}
                isDisabled={!form.dirty || submit.isLoading}
                leftAdornment={
                  submit.isLoading ? (
                    <CircularProgress size="small" color="white" />
                  ) : undefined
                }
              >
                {tSales("invoice.changeClinic.submit")}
              </Button>
              <Button variant="outlined" onClick={() => setIsModalOpen(false)}>
                {tSales("invoice.changeClinic.cancel")}
              </Button>
            </div>
          }
        >
          <Select
            isSearchable
            value={form.values.clinic}
            options={clinics.data}
            onChange={(next) => form.setFieldValue("clinic", next)}
            isError={Boolean(form.errors.clinic)}
          />
        </Modal>
      )}
    </>
  );
}

ChangeClinic.propTypes = {
  invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  clinicId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSuccess: PropTypes.func.isRequired,
};
