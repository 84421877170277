import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { formatBytes } from '../../Utils/services.js';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";
import defLogo from '../../_legacy/images/upload.png';
import crossImg from '../../_legacy/images/close.png';
import {fetchHealthQuestionnaire } from '../../Actions/Settings/settingsActions';

const nextChar = (value) => {
    value = String(value)
    return String.fromCharCode(value.charCodeAt(0) + 1);
  }

class EditProcedureHealth extends Component {
constructor(props){
    super(props);
    const languageData  = JSON.parse(localStorage.getItem('languageData'))
    this.state = {
        serviceID : this.props.match.params.serviceID,
        procedureID : this.props.match.params.procedureID,
        clientID : this.props.match.params.clientID,
        questionnaireID : this.props.match.params.questionnaireID,
        templateQuesionList : [],
        globalLang : languageData.global,
        languageData : languageData.clients

    }
    window.onscroll = () => {   
      return false
    }
}

componentDidMount(){
    this.setState({ showLoader : true });
    this.props.fetchHealthQuestionnaire(this.state.serviceID, this.state.procedureID);
}

static getDerivedStateFromProps(nextProps, prevState){
    let returnState = {};
    if(nextProps.healthQuestionData != undefined && nextProps.healthQuestionDataTime != prevState.healthQuestionDataTime){
        let value = nextProps.healthQuestionData.data.prodecure_templates.find(y => y.id == prevState.questionnaireID);
        if(value){
            returnState.procedureTemplateData = value;
            returnState.healthQuestionDataTime = nextProps.healthQuestionDataTime;
            returnState.showLoader = false
            let templateQuesionList = (value.procedure_template_question) ? value.procedure_template_question : []
            templateQuesionList.map((question, idx) => {
              switch (question.question_type) {
                case "Textbox":
                  question.field_name = 'Textbox_' + question.id
                  question.field_value = ''
                  question.class_name = 'report-input'
                  break;
                case "Multiple Choice":
                  question.field_name = 'Multiple_Choice_' + question.id
                  question.field_value = []
                  question.class_name = 'col-sm-12 multisel-outer'
                  break;
                case "File Upload":
                  question.field_name = 'File_Upload_' + question.id
                  question.field_value = ''
                  question.class_name = 'file-container file-upload-img'
                  break;
                case "Yes/No":
                  question.field_name = 'Yes_No_' + question.id
                  question.field_value = ''
                  question.class_name = 'col-sm-12 multisel-outer'
                  break;
                case "Single Choice":
                  question.field_name = 'Single_Choice_' + question.id
                  question.field_value = ''
                  question.class_name = 'col-sm-12 multisel-outer'
                  break;
                case "Opinion Scale":
                  question.field_name = 'Opinion_Scale_' + question.id
                  question.field_value = ''
                  question.field_name_why = 'Opinion_Scale_Why_' + question.id
                  question.field_value_why = ''
                  question.class_name = 'survey-rate-outer'
                  question.display_step = 0
                  break;
                default:
                  return null
                  break;
              }
              question.error_flag = false
              question.isShow = (idx === 0) ? true : false;
              question.button_next = (templateQuesionList[idx + 1] !== undefined) ? templateQuesionList[idx + 1]['id'] : 0;
              question.button_back = (idx !== 0) ? templateQuesionList[idx - 1]['id'] : 0;
            });
            returnState.templateQuesionList = templateQuesionList;
            returnState.consultation_fee = '';
            returnState.consultationFeeClass = 'report-input';
            returnState.consultationFeeShow = false;
            returnState.showLoader = false;
            returnState.displayedQuestionId = (templateQuesionList.length > 0) ? templateQuesionList[0]['id'] : 0
            // nextProps.emptyProcedureReducer()
        }
       
    }
    return returnState;
}


renderInputFields = (questionObj, questionIdx) => {
    let returnHtml = '';
    let serialCount = 'A';
    switch (questionObj.question_type) {
      case "Textbox":
        returnHtml = (
          <input data-index={questionIdx} data-question={questionObj.id} data-option={-1} className={questionObj.class_name} value={questionObj.field_value} name={questionObj.field_name} type="text" onChange={this.handleInputChange} placeholder="Write your answer here..." autoComplete="off" />
        )
        break;
      case "Multiple Choice":
        returnHtml = (
          <div className={questionObj.class_name}>
            {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
              questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                if (optionIdx !== 0) {
                  serialCount = nextChar(serialCount)
                }
                return (
                  <div key={'templateQuesionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value.indexOf(optionObj.id) > -1) ? "multisel activated" : "multisel"} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)}>
                    <input data-index={questionIdx} data-question={questionObj.id} data-option={optionObj.id} className="servy-checkbox servy-radio preboxval" value={optionObj.id} name={questionObj.field_name} type="checkbox" onChange={this.handleInputChange} />
                    <span className="multi-sel-alpha">{serialCount}</span>
                    <label className="multisel-label" htmlFor={"Multiple_Choice_" + questionObj.id}>{optionObj.question_option}</label>
                    <i className="fa fa-check" />
                  </div>
                )
              })
            }
          </div>
        )
        break;
      case "File Upload":
        returnHtml = (
          <div className="row add-doc-section">
            <div className="col-xs-6 m-b-20">
              <div className={questionObj.class_name} title={(questionObj.field_value) ? questionObj.field_value : ''}>
                {(questionObj.field_value) && <a className="delete-file" onClick={this.removeUploadedFile.bind(this, questionIdx, questionObj.id)} ></a>}
                <img
                  className={(questionObj.field_value) ? "full-custom-img" : ""}
                  src={(questionObj.field_value) ? questionObj.download_path : defLogo}
                />
                <span className={(questionObj.field_value) ? "file-name file-info" : "file-name-hide no-display"}> {questionObj.file_thumbnail}</span>
                <span className={(questionObj.field_value) ? "file-size file-info" : "file-size-hide no-display"}>{questionObj.file_size}</span>
                <div className="upload">{this.state.globalLang.global_upload}
                  <input data-index={questionIdx} data-question={questionObj.id} data-option={-1} type="file" className={'image_questionnaire'} name="file" autoComplete="off" onChange={this.handleFileChosen} title={(questionObj.field_value) ? '' : 'No file chosen'} id={'image_questionnaire-' + questionObj.id} />
                </div>
              </div>
            </div>
          </div>
        )
        break;
      case "Yes/No":
        returnHtml = (
          <div className={questionObj.class_name}>
            {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
              questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                if (optionIdx !== 0) {
                  serialCount = nextChar(serialCount)
                }
                return (
                  <div key={'templateQuesionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value == optionObj.id) ? "multisel activated" : "multisel"} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)}>
                    <input data-index={questionIdx} data-question={questionObj.id} data-option={optionObj.id} className="servy-checkbox servy-radio preboxval" value={optionObj.id} name={questionObj.field_name} type="radio" onChange={this.handleInputChange} />
                    <span className="multi-sel-alpha">{serialCount}</span>
                    <label className="multisel-label" htmlFor={"Yes_No_" + questionObj.id}>{optionObj.question_option}</label>
                    <i className="fa fa-check" />
                  </div>
                )
              })
            }
          </div>
        )
        break;
      case "Single Choice":
        returnHtml = (
          <div className={questionObj.class_name}>
            {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
              questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                if (optionIdx !== 0) {
                  serialCount = nextChar(serialCount)
                }
                return (
                  <div key={'templateQuesionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value == optionObj.id) ? "multisel activated" : "multisel"} data-index={questionIdx} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)}>
                    <input data-index={questionIdx} data-question={questionObj.id} data-option={optionObj.id} className="servy-checkbox servy-radio preboxval" value={optionObj.id} name={questionObj.field_name} type="radio" onChange={this.handleInputChange} />
                    <span className="multi-sel-alpha">{serialCount}</span>
                    <label className="multisel-label" htmlFor={"Single_Choice_" + questionObj.id}>{optionObj.question_option}</label>
                    <i className="fa fa-check" />
                  </div>
                )
              })
            }
          </div>
        )
        break;
      case "Opinion Scale":
        returnHtml = (
          <div className="col-sm-12 opinion-scale">
            {(questionObj.why_choose === 1 && questionObj.display_step === 1)
              ?
              <input data-index={questionIdx} data-question={questionObj.id} data-option={-1} className="report-input" value={questionObj.field_value_why} name={questionObj.field_name_why} type="text" onChange={this.handleInputChange} placeholder="Write your answer here..." autoComplete="off" />
              :
              <div>
                <ul className={questionObj.class_name}>
                  {((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) &&
                    questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
                      return (
                        <li key={'templateQuesionList-' + questionObj.id + '-optionList-' + optionObj.id} className={(questionObj.field_value == optionObj.id) ? "survey-li preboxval survey-li-active" : "survey-li preboxval"} onClick={this.handleAnswerChange.bind(this, questionIdx, questionObj.id, optionObj.id)} >{optionObj.question_option}</li>
                      )
                    })
                  }
                </ul>
                <div className={'likeornot m-b-20'}><span>{this.state.languageData.pro_not_likely}</span> <span className="pull-right">{this.state.languageData.pro_likely}</span></div>
              </div>
            }
          </div>
        )
        break;
      default:
        return null
        break;
    }
    return returnHtml;
  }

  handleFileRead = (e) => {
    const content = this.state.fileReader.result;
    let fileSize = formatBytes(this.state.file.size, 1)
    this.setState({ file_thumbnail: this.state.file.name, file_size: fileSize, file_src: this.state.fileReader.result, showLoader: true });
    this.handleUpload(this.state.target.name)

  }

  handleUpload = (targetName) => {
    let uploadtype = 'procedure_image'
    const data = new FormData()
    data.append('file', this.state.file, this.state.file.name)
    let endpoint = process.env.REACT_APP_API_URL + `media/upload?upload_type=${uploadtype}`;

    axios.post(endpoint, data).then(res => {
      let templateQuesionList = this.state.templateQuesionList;
      let uploadIndex = this.state.uploadIndex
      templateQuesionList[uploadIndex]['field_value'] = res.data.data.file_name
      templateQuesionList[uploadIndex]['file_thumbnail'] = this.state.file_thumbnail
      templateQuesionList[uploadIndex]['file_size'] = this.state.file_size
      templateQuesionList[uploadIndex]['download_path'] = this.state.fileReader.result
      templateQuesionList[uploadIndex]['class_name'] = 'file-container file-upload-img';
      this.setState({
        templateQuesionList: templateQuesionList,
        showLoader: false
      });
      this.resetFileState()
    }).catch(error => {
      toast.error(this.state.globalLang[error.response.data.message]);
      this.setState({ showLoader: false });
      this.resetFileState()
    })
  }

  handleFileChosen = (event) => {
    const target = event.target;
    if (target && target.type === 'file') {
      const allowedTypes = ["image/png", "image/PNG", "image/jpg", "image/JPG", "image/jpeg", "image/JPEG", "image/gif", "image/GIF"];
      if (target.files && allowedTypes.indexOf(target.files[0].type) > -1) {
        const file = target.files[0]
        this.state.fileReader = new FileReader();
        this.state.fileReader.onloadend = this.handleFileRead;
        this.state.fileReader.readAsDataURL(file);
        this.state.file = file
        this.state.target = target
        this.state.uploadIndex = target.dataset.index
      } else {
        toast.error('This file type is not allowed');
      }
    }
  }
  removeUploadedFile = (index, questionId, event) => {
    let returnState = {};
    let templateQuesionList = this.state.templateQuesionList;
    templateQuesionList[index]['field_value'] = ''
    templateQuesionList[index]['file_thumbnail'] = ''
    templateQuesionList[index]['file_size'] = ''
    templateQuesionList[index]['download_path'] = ''
    templateQuesionList[index]['class_name'] = 'file-container file-upload-img';
    this.setState({ templateQuesionList: templateQuesionList });
    this.resetFileState(questionId)
  }

  resetFileState = (questionId) => {
    questionId = questionId || 0
    this.setState({
      uploadIndex: '',
      fileReader: {},
      file: '',
      target: '',
      file_name: '',
      file_thumbnail: '',
      file_size: ''
    });

    // reset input-field value (it's required for upload same file after removing)
    if (questionId) {
      var inputFiled = document.getElementById("image_questionnaire-" + questionId);
      if (inputFiled) {
        inputFiled.value = ''
      }
    }
  }

  handleBackNext = (index, questionId, buttonAction, event) => {
    let backQuestionId = 0
    let nextQuestionId = 0
    let templateQuesionList = this.state.templateQuesionList;
    let consultationFeeShow = this.state.consultationFeeShow;
    let displayedQuestionId = this.state.displayedQuestionId;

    // display back-question if back request comming from consultation_fee question
    if (index == -1 && consultationFeeShow === true && buttonAction === 'back') {
      backQuestionId = displayedQuestionId;
      templateQuesionList.map((obj, idx) => {
        if (obj.id == backQuestionId) {
          templateQuesionList[idx]['isShow'] = true
          displayedQuestionId = templateQuesionList[idx]['id'];
        } else {
          templateQuesionList[idx]['isShow'] = false
        }
      })
      this.setState({ templateQuesionList: templateQuesionList, consultationFeeShow: false, displayedQuestionId: displayedQuestionId })
      return
    }

    // checke input-field is required or not
    const field_value = templateQuesionList[index]['field_value'];
    if (templateQuesionList[index]['required'] === 1 && buttonAction === 'next' && (field_value == '' || field_value == null || field_value == undefined)) {
      // prevent to move next or back question if input-field is empty (when input-field is required)
      switch (templateQuesionList[index]['question_type']) {
        case "Textbox":
          templateQuesionList[index]['class_name'] = 'report-input field_error'
          break;
        case "Multiple Choice":
          templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer error'
          break;
        case "File Upload":
          templateQuesionList[index]['class_name'] = 'file-container file-upload-img field_error'
          break;
        case "Yes/No":
          templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer error'
          break;
        case "Single Choice":
          templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer error'
          break;
        case "Opinion Scale":
          templateQuesionList[index]['class_name'] = 'survey-rate-outer field_error'
          break;
        default:
          return null
          break;
      }
      this.setState({ templateQuesionList: templateQuesionList })
      return
    } else {
      switch (templateQuesionList[index]['question_type']) {
        case "Textbox":
          templateQuesionList[index]['class_name'] = 'report-input'
          break;
        case "Multiple Choice":
          templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer'
          break;
        case "File Upload":
          templateQuesionList[index]['class_name'] = 'file-container file-upload-img'
          break;
        case "Yes/No":
          templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer'
          break;
        case "Single Choice":
          templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer'
          break;
        case "Opinion Scale":
          templateQuesionList[index]['class_name'] = 'survey-rate-outer'
          break;
        default:
          return null
          break;
      }
    }

    // fetch next-question-id
    if (buttonAction === 'next') {
      switch (templateQuesionList[index]['question_type']) {
        case "Textbox":
        case "Multiple Choice":
        case "File Upload":
          // get next question id (without logic jump)
          nextQuestionId = (templateQuesionList[index + 1] !== undefined) ? templateQuesionList[index + 1]['id'] : 0;
          break;
        case "Yes/No":
        case "Single Choice":
        case "Opinion Scale":
          if (templateQuesionList[index]['procedure_templates_logic'] !== undefined && templateQuesionList[index]['procedure_templates_logic'].length > 0) {

            // fetch login-jump-question if set for selected option (value)
            let procedureTemplatesLogic = templateQuesionList[index]['procedure_templates_logic'].find(logicJump => logicJump.procedure_question_option_id == field_value)

            // fetch login-jump-question if not set for selected option (value) or default next question-id
            if (procedureTemplatesLogic === undefined) {
              procedureTemplatesLogic = templateQuesionList[index]['procedure_templates_logic'].find(logicJump => logicJump.procedure_question_option_id == 0)
            }

            if (procedureTemplatesLogic !== undefined && procedureTemplatesLogic.jump_to_question !== undefined) {
              // get next-question-id if logic jump found
              nextQuestionId = procedureTemplatesLogic.jump_to_question;
            } else {
              // get default next-question-id if logic jump not found
              nextQuestionId = (templateQuesionList[index + 1] !== undefined) ? templateQuesionList[index + 1]['id'] : 0;
            }
          } else {
            // get default next-question-id if logic jump not found
            nextQuestionId = (templateQuesionList[index + 1] !== undefined) ? templateQuesionList[index + 1]['id'] : 0;
          }
          if (templateQuesionList[index]['question_type'] === "Opinion Scale") {
            templateQuesionList[index]['display_step'] = 0;
          }
          break;
        default:
          break;
      }
    }
    // fetch back-question-id
    if (buttonAction === 'back') {
      const currentQuestion = templateQuesionList[index];
      // get default back-question-id from current question
      backQuestionId = currentQuestion.button_back;

      if (templateQuesionList[index]['question_type'] === "Opinion Scale") {
        if (templateQuesionList[index]['why_choose'] === 1 && templateQuesionList[index]['display_step'] === 1) {
          templateQuesionList[index]['display_step'] = 0;
          this.setState({ templateQuesionList: templateQuesionList })
          return
        }
      }
    }

    let isShow = false
    templateQuesionList.map((obj, idx) => {
      if (obj.id == questionId) { // hide current question
        templateQuesionList[idx]['isShow'] = false
      } else if (buttonAction === 'back' && obj.id == backQuestionId && backQuestionId != 0) { // display back question
        templateQuesionList[idx]['isShow'] = true
        displayedQuestionId = templateQuesionList[idx]['id'];
        consultationFeeShow = false
        isShow = true
      } else if (buttonAction === 'next' && obj.id == nextQuestionId && nextQuestionId != 0) { // display next question
        templateQuesionList[idx]['isShow'] = true
        // set back question for next question
        templateQuesionList[idx]['button_back'] = questionId
        displayedQuestionId = templateQuesionList[idx]['id'];
        consultationFeeShow = false
        isShow = true
      }
    })
    if(isShow === false && this.state.invoice_id == 0 && buttonAction === 'next'){
      consultationFeeShow = true
    }
    this.setState({ templateQuesionList: templateQuesionList, consultationFeeShow: consultationFeeShow, displayedQuestionId: displayedQuestionId })
  }


  handleAnswerChange = (index, questionId, optionId, event) => {
    let templateQuesionList = this.state.templateQuesionList;
    switch (templateQuesionList[index]['question_type']) {
      case "Textbox":
      case "File Upload":
        break;
      case "Multiple Choice":
        let field_value = (templateQuesionList[index]['field_value']) ? templateQuesionList[index]['field_value'] : [];
        let existOrNot = field_value.indexOf(optionId);
        if (existOrNot > -1) {
          field_value.splice(existOrNot, 1);
        } else {
          field_value.push(optionId);
        }
        templateQuesionList[index]['field_value'] = field_value;
        templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer'
        break;
      case "Yes/No":
        templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer'
        templateQuesionList[index]['field_value'] = optionId;
        break;
      case "Single Choice":
        templateQuesionList[index]['class_name'] = 'col-sm-12 multisel-outer'
        templateQuesionList[index]['field_value'] = optionId;
        break;
      case "Opinion Scale":
        templateQuesionList[index]['field_value'] = optionId;
        templateQuesionList[index]['class_name'] = 'survey-rate-outer'
        templateQuesionList[index]['display_step'] = (templateQuesionList[index]['why_choose'] === 1) ? 1 : 0
        break;
      default:
        break;
    }
    this.setState({ templateQuesionList: templateQuesionList })
  }


  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    let name = event.target.name

    const question = event.target.dataset.question;
    if (question) {
      const option = event.target.dataset.option;
      const index = event.target.dataset.index;
      let templateQuesionList = this.state.templateQuesionList;
      if (option == -1) {
        if (name.startsWith('Opinion_Scale_Why')) {
          templateQuesionList[index]['field_value_why'] = value
        } else {
          templateQuesionList[index]['field_value'] = value
        }
        templateQuesionList[index]['class_name'] = 'report-input'
        this.setState({ templateQuesionList: templateQuesionList })
      }
    } else {
      this.setState({ [event.target.name]: value, dataChanged: true });
    }


    if (name === 'procedure_template_id') {
      this.getProcedureTemplateData(value)

    }
    if (name === 'appointment_id') {
      this.getApptData(value)
    }
    if (name === 'user_id') {
      this.getAssociatedClinics(value)
    }
  }

  sendBackToOldLink = () => {
      this.props.history.push(`/clients/${this.state.clientID}/questionnaire/${this.state.procedureID}/health/${this.state.serviceID}`)
  }




render(){
    let templateQuesionList= '';
    if (this.state.templateQuesionList && this.state.templateQuesionList.length > 0) {
        templateQuesionList = this.state.templateQuesionList.map((questionObj, questionIdx) => {
          return (
            <div key={'templateQuesionList-' + questionObj.id} id={"question-outer-" + questionObj.id} className="report-question-outer" style={{ 'display': (questionObj.isShow) ? 'block' : 'none' }}>
              {(questionObj.question_type === "Opinion Scale" && questionObj.why_choose === 1 && questionObj.display_step === 1) ?
                <div className="survey-title no-margin" id={"question-" + questionObj.id}>{this.state.languageData.pro_why_did_choose}</div>
                :
                <div>
                  <div className="survey-title no-margin" id={"question-" + questionObj.id}>{questionObj.question}{(questionObj.required == 1) && <span className="setting-require font-size-16">*</span>}</div>
                  {(questionObj.description == 1) &&
                    <div className="quest-discription" id={"question-description-" + questionObj.id}> {questionObj.description_text}</div>
                  }
                </div>
              }
              {this.renderInputFields(questionObj, questionIdx)}
              {(questionIdx !== 0) &&
                <button id={"back_ok-"+questionObj.id} className="back-ok m-r-20" onClick={this.handleBackNext.bind(this, questionIdx, questionObj.id, 'back')}>{this.state.languageData.pro_back}<i className="fa fa-arrow-left"></i></button>
              }
              <button id={"rating-ok"+questionObj.id} className="report-btn" onClick={(this.state.invoice_id != 0 && this.state.templateQuesionList[questionIdx + 1] === undefined) ? this.handleSubmit : this.handleBackNext.bind(this, questionIdx, questionObj.id, 'next')} style={{ 'display': (questionObj.question_type === "Opinion Scale" && questionObj.why_choose === 1 && questionObj.display_step === 0 && questionObj.required === 1) ? 'none' : 'block' }}>{(this.state.invoice_id != 0 && this.state.templateQuesionList[questionIdx + 1 ] === undefined) ? this.state.globalLang.label_save : this.state.languageData.pro_ok }<i className="fa fa-check" /></button>
            </div>
          )
        })
      }
    return(
        <div id="content">
        <div className="container-fluid content setting-wrapper">
           <div className="juvly-section full-width m-t-15">
              <div className="juvly-container">
                 <div className="juvly-title m-b-30">Update Questionnaire
                    <a className="pull-right" onClick={() => this.sendBackToOldLink()}><img src={crossImg} /></a>
                 </div>



                 <div className="half-preview healthtimeline" id="half-preview -12">
                  {templateQuesionList}
                  {(templateQuesionList !== '') &&
                    <div key={'consultation_fee'} id={"qconsultation-fee-0"} className="report-question-outer" style={{ 'display': (this.state.consultationFeeShow) ? 'block' : 'none' }}>
                      <div className="survey-title no-margin" id={"consultation-fee-1"}>{this.state.languageData.pro_consultation_fee}</div>
                      <input className={this.state.consultationFeeClass} value={this.state.consultation_fee} name="consultation_fee" type="text" onChange={this.handleInputChange} placeholder="Write your answer here..." autoComplete="off" />
                      <button id="back_ok-consultation-fee-0" className="back-ok m-r-20" onClick={this.handleBackNext.bind(this, -1, -1, 'back')}>{this.state.languageData.pro_back}<i className="fa fa-arrow-left"></i></button>
                      <button id="rating-ok-consultation-fee-1" className="report-btn" onClick={this.handleSubmit}>{this.state.globalLang.label_save} <i className="fa fa-check" /></button>
                    </div>
                  }
                </div>

             
                 </div></div></div>
                 <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
           <div className="loader-outer">
             <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
             <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
           </div>
         </div>
                 </div>
    )
}
}

function mapStateToProps(state) {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    let returnState = {};
    if(state.SettingReducer.action === "FETCH_HEALTH_QUESTIONNAIRE"){
        if(state.SettingReducer.data.status === 200){
          returnState.healthQuestionData = state.SettingReducer.data;
          returnState.healthQuestionDataTime = new Date()
          
        } else {
          toast.dismiss();
          toast.error(languageData.global[state.SettingReducer.data.message])
        }
    }
    return returnState;
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchHealthQuestionnaire : fetchHealthQuestionnaire
    },dispatch)
  }
export default connect(mapStateToProps, mapDispatchToProps)(EditProcedureHealth);
