import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { Link } from 'react-router-dom'
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import { fetchUsers, exportEmptyData, userStatusUpdate, getUsersPendingInvites, resendInvite, deleteInvite } from '../../../../Actions/Settings/settingsActions.js';
import { autoScrolling, showFormattedDate } from "../../../../Utils/services.js";
import Loader from '../../../Common/Loader.js'
import { ObOrder } from './components/ObOrder/ObOrder.js';
import { PERMISSIONS } from '../../../../consts/api.js';
import { UpdateUserPhone } from './components/UpdateUserPhone/UpdateUserPhone.js';
import { withCurrentAccountQuery } from '../../../../api/queries/useAccountQuery.js';
import { StarIcon } from '../../../../assets/Icons/StarIcon.js';


const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
}


class Users extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const userData = JSON.parse(localStorage.getItem('userData'))
    this.state = {
      accountType : (userData.account != undefined && userData.account.account_type != undefined) ? userData.account.account_type : 'trial',
      fisrtname: '',
      lastname: '',
      role_id:null,
      status: 'active',
      showLoadingText: false,
      userData: '',
      page: 1,
      user_roles:[],
      pagesize: 15,
      sortby: 'firstname',
      sortorder: 'asc',
      term: '',
      hasMoreItems: true,
      next_page_url: '',
      userList: [],
      loadMore: true,
      startFresh: true,
      showLoader: false,
      add_user_limit_reached: 0,
      email:"",
      showModal: false,
      settingsLang: languageData.settings,
      pageNum: 1,
      role_id_inactive:null,
      inActive: false,
      isTouchMd : (userData && userData.account) ? userData.account.account_preference.is_touch_md : 0,
      plan_selected: userData.account.account_subscription.plan_code,
      active: true,
      pending: false,
    }
    localStorage.setItem('loadFresh', false);
    localStorage.setItem('sortOnly', false);
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <=5 && this.state.next_page_url != null) {
        this.loadMore();
      }
    };
  }

  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", true);
  }

  componentDidMount() {
    let formData = {
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        role_id : this.state.role_id,
        sortorder: 'asc',
        term: this.state.term
      }
    }
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.setState({
      user_Create_UserBtn: languageData.settings['user_Create_UserBtn'],
      user_First_Name: languageData.settings['user_First_Name'],
      user_Role: languageData.settings['user_Role'],
      user_Last_Name: languageData.settings['user_Last_Name'],
      clinics_Search: languageData.settings['clinics_Search'],
      loading_please_wait_text: languageData.global['loading_please_wait_text'],
      user_Status: languageData.settings['user_Status'],
      clinic_No_Record_Found: languageData.settings['clinic_No_Record_Found'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],
      clinic_No_Record_Found: languageData.settings['clinic_No_Record_Found'],
      setting_create_user_button_error__trail_account_type: languageData.settings['setting_create_user_button_error__trail_account_type'],
    })
    this.setState({ showLoader: true, active: true, inactive: false, pending: false })
    autoScrolling(true);
    this.props.fetchUsers(formData);
    this.props.getUsersPendingInvites();
  }

  componentDidUpdate(prevProps){
    if(prevProps.userPendingInvites && !this.props.userPendingInvites) {
      this.props.getUsersPendingInvites()
    }
    if(prevProps.deleteInviteStatus === 'loading' && this.props.deleteInviteStatus !== 'loading') {
      this.setState({ active: false, inActive: false, pending: true })
      this.props.getUsersPendingInvites()
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
    if ( event.target.name === 'role_id' ) {
      this.setState({
        showLoader: true
      });
    
      localStorage.setItem("roleId", JSON.stringify(value));
      this.handleSubmit('role_id', value)
    }
    if ( event.target.name === 'role_id_inactive' ) {
      this.setState({
        showLoader: true
      });
      localStorage.setItem("roleIdinactive", JSON.stringify(value));
      this.handleSubmit('role_id_inactive', value) 
    }
  }

  handleSubmit = (event, value) => {
      let role_id = (this.state.role_id)
      let status = (this.state.inActive) ? "Inactive" : "Active"
      let role_id_inactive = (this.state.role_id_inactive)
      if(event === 'role_id'){
        role_id = value
      }
       else if(event === 'role_id_inactive'){
        role_id = value
      
      }
       else if (event) {
        event.preventDefault();
      }   else
    localStorage.setItem('sortOnly', true);
    let formData = {
      'params': {
        status: status,
        page: 1,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        sortorder: this.state.sortorder,
        term: this.state.term
      }
    }
   
    if(role_id){
      formData.params.role_id = role_id
    }
    this.setState({
      page: 1,
      sortby: this.state.sortby,
      pagesize: this.state.pagesize,
      sortorder: (this.state.sortorder == 'asc') ? 'asc': 'desc',
      startFresh: true,
      loadMore: true,
      startFresh: true,
      next_page_url: '',
      userList: [],
      showLoader: true
    });
    autoScrolling(true);
    this.props.fetchUsers(formData);

  }

  onSort = (sortby) => {
    let sortorder = (this.state.sortorder === 'asc') ? 'desc': 'asc';
    let formData = {
      'params': {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term
      }
    }
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: '',
      userList: []
    });
    localStorage.setItem('sortOnly', true);
    autoScrolling(true);
    this.props.fetchUsers(formData);
  }

  loadMore = () => {
    if(!autoScrolling()){
      localStorage.setItem('sortOnly', false);
      this.setState({ 'loadMore': true, startFresh: true, showLoader: true, showLoadingText: true })
      let formData ={}
if( this.state.inActive == true ){
formData = {
    'params': {
      page: this.state.page,
      pagesize: this.state.pagesize,
      sortby: this.state.sortby,
      sortorder: this.state.sortorder,
      role_id : this.state.role_id_inactive,
      term: this.state.term,
      status: "Inactive"
    }
  }
}else{

      formData = {
        'params': {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortby: this.state.sortby,
          sortorder: this.state.sortorder,
          role_id : this.state.role_id,
          term: this.state.term
        }
      }
    }
      autoScrolling(true);
      this.props.fetchUsers(formData);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if(nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.exportEmptyData();
        return {showLoader : false};
     }
    if (nextProps.userList != undefined && nextProps.userListTime !== prevState.userListTime) {
      let returnState = {};
      returnState.userListTime = nextProps.userListTime;      
      if (prevState.userList.length == 0) {
          if (nextProps.userList.next_page_url != null) {
            returnState.page = prevState.page + 1;
          } else {
            returnState.next_page_url = nextProps.userList.next_page_url;

          }
          returnState.userList = nextProps.userList.data;
          returnState.user_roles = nextProps.userList.roles
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
          returnState.add_user_limit_reached = nextProps.userList.add_user_limit_reached;

      } else if (prevState.userList != nextProps.userList.data && prevState.userList.length != 0 && nextProps.redirect == true  ) {
        returnState.userList = nextProps.userList.data;
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.userList.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.add_user_limit_reached = nextProps.userList.add_user_limit_reached;
      
       // nextProps.exportEmptyData();
      }  
      
      else if (prevState.userList != nextProps.userList.data && prevState.userList.length != 0) {
        returnState.userList = [...prevState.userList, ...nextProps.userList.data];
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.userList.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.add_user_limit_reached = nextProps.userList.add_user_limit_reached;
      
       // nextProps.exportEmptyData();
      } 
      autoScrolling(false);
      nextProps.exportEmptyData();
      return returnState;
    }
    return null;
  }

 /* componentWillUnmount = () => {
    window.onscroll = () => {
      return false;
    }
  }*/

  userEdit = (id) => {
    //localStorage.setItem('userID', id)
    return (
      <div>
        {this.props.history.push(`/settings/users/${id}/edit`)}
      </div>
    );
  }

  userAdd = (id) => {
    if(this.state.add_user_limit_reached){
      toast.dismiss();
      toast.error(this.state.setting_create_user_button_error__trail_account_type);
      return false;
    } else {
      return (
        <div>
          {this.props.history.push(`/settings/users/create`)}
        </div>
      );
    }
  }

  /* ,
        {this.props.passID(id)}*/

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }


  showActiveModal = (x, y) => {
   // let id = this.props.match.params.userId;
   let id = x
  this.setState({showModal: true,  activate: id})
  }

  dismissModal = () => {
    this.setState({ showModal: false })
  }


  activeUserID = () => {
    let id = this.state.activate
    let role_id_inactive = this.state.role_id_inactive
    let formData
  if(role_id_inactive == null){
     formData = {
     user_status:"Active",
     id : id ,
     page: 1,
     pagesize: this.state.pagesize,
     sortby: this.state.sortby,
     //role_id :  this.state.role_id_inactive ,
     sortorder: 'asc',
     term: this.state.term,
     status: "Inactive"
    }
  }

  if(role_id_inactive !== null){
    formData = {
    id : id ,
    page: 1,
    pagesize: this.state.pagesize,
    sortby: this.state.sortby,
    sortorder: 'asc',
    term: this.state.term,
   }
 }
    this.showLoaderFunc();
    this.dismissModal();
   // this.dismissFutureAppoinmentModal();
   
    this.props.userStatusUpdate(formData);
  }

showActive = ()=>{
  this.setState({
    active: true,
    inActive : false,
    pending: false,
    userList: [],
    page: 1,
    sortby: this.state.sortby,
    pagesize: this.state.pagesize,
    sortorder: (this.state.sortorder == 'asc') ? 'asc': 'desc',
    startFresh: true,
    loadMore: true,
    startFresh: true,
    next_page_url: '',
    showLoader: true, 
    

  })

  let formData = {
    'params': {
      page: 1,
      pagesize: this.state.pagesize,
      sortby: this.state.sortby,
      role_id : this.state.role_id,
      //role_id_inactive: this.state.role_id_inactive,
      sortorder: 'asc',
      term: this.state.term,
      status: "Active" 
    }
  }
  autoScrolling(true);
  this.props.fetchUsers(formData);
  this.props.getUsersPendingInvites();
}


showInActive = ()=> {
  this.setState({
    term: '',
    inActive : true,
    active: false,
    pending: false, 
    page: 1,
    sortby: this.state.sortby,
    pagesize: this.state.pagesize,
    sortorder: (this.state.sortorder == 'asc') ? 'asc': 'desc',
    startFresh: true,
    loadMore: true,
    startFresh: true,
    next_page_url: '',
    userList: [],
    showLoader: true,
  })

  let formData = {
    'params': {
      page: 1,
      pagesize: this.state.pagesize,
      sortby: this.state.sortby,
      role_id :  this.state.role_id_inactive,
      sortorder: 'asc',
      term: this.state.term,
      status: "Inactive" 
    }
  }
  autoScrolling(true);
  this.props.fetchUsers(formData);
  this.props.getUsersPendingInvites();

}

showPending = () => {
  this.props.getUsersPendingInvites();
  this.setState({
    term: '',
    pending : true, 
    active: false,
    inActive: false,
    page: 1,
    sortby: this.state.sortby,
    pagesize: this.state.pagesize,
    sortorder: (this.state.sortorder == 'asc') ? 'asc': 'desc',
    startFresh: true,
    loadMore: true,
    next_page_url: '',
    userList: [],
    showLoader: true,
  })

  this.props.getUsersPendingInvites();
  if(this.props.userPendingInvites) {
    this.setState({ showLoader: false })
  }
}

  renderPendingInvitesTableBody() {
    if(!this.props.userPendingInvites) {
      return;
    }
    
    return this.props.userPendingInvites.map(user => {
      let data = {
        wpu_id: user.workspace_user_id
      }
      if(user.user !== null) {
      return <tr key={user.id}>
        <td className="col-xs-2 table-updated-td">{`${user.user.firstname} ${user.user.lastname}`}</td>
        <td className="col-xs-2 table-updated-td">{user.user.email_id}</td>
        <td className="col-xs-2 table-updated-td">{user.user.contact_number_1}</td>
        <td className="col-xs-4 table-updated-td">
          <a className="line-btn text-center" onClick = {() => this.props.resendInvite(data)}>Resend invite</a>
          <a className="easy-link" onClick = {() => this.props.deleteInvite(data)}><i className="fa fa-trash-alt m-r-5" />Delete</a>
          <UpdateUserPhone 
            userId={user.user.id}
            phone={user.user.contact_number_1}
            onSuccess={this.props.getUsersPendingInvites}
          />
        </td>
      </tr>
    }})
  }

  render() {
    const accountAdminId = this.props.currentAccountQuery.data?.adminId;
  
    let optData    = '';
    let roles = ['Admin', 'Provider', 'Front Desk', 'Medical Director']
    
    if ( this.state.user_roles !== undefined ) {
      optData = roles.map((val, idx) => {
         let str = (val === 'Admin' || val === 'Provider' || val === 'Front Desk' || val === 'Medical Director'  ) ? val : "";
          return <option key={idx} value={1 + idx}>{str}</option>;
     })
    }
    
    const userPermissions = JSON.parse(localStorage.getItem('userData')).permissions;
    let sideBarPermissions = [...userPermissions];
    return (

      <div className="main protected users-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
      {/*Clinic Head Start*/}
          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
            <div className="membership-title">
              <span className="cursor-pointer">Users</span>
              
                <div className="memberRightActions">
               
                </div>
            </div>
          </div>
          <div className="setting-setion m-b-10">
                <div className="membership-title">
                  <Link to="/settings/users" onClick={()=>this.setState({term: '', role_id: null,}, this.showActive)} className= {this.state.active ? "newTabs" : "newTabs disabled-tab" }>Active</Link>
                  {checkPermission(sideBarPermissions, PERMISSIONS.settings.manageUsers) &&
                    <a onClick={()=>this.setState({term: '', role_id_inactive: null,}, this.showInActive)} className={this.state.inActive ? "newTabs" : "newTabs disabled-tab" }>Inactive </a>
                  }
                  <a onClick={() => this.showPending()} className={this.state.pending ? "newTabs" : "newTabs disabled-tab" }>Pending Invites </a>
                  <form onSubmit={this.handleSubmit} className="searchForm">
                  <span className="searchOuter"><i className="fa fa-search search-icon" />
                  <input
                    className="setting-search-input search-key"
                    data-url="/settings/clinics"
                    name="term"
                    placeholder={this.state.clinics_Search}
                    autoComplete="off"
                    value={this.state.term}
                    onChange={this.handleInputChange}
                  />
                  </span>
                </form>
                  <div className="memberRightActions">
                    <button onClick={this.userAdd} className="new-blue-btn pull-right">{this.state.user_Create_UserBtn}</button>
                    <ObOrder />
                  </div>
                </div>
              </div>
          {/*Clinic Head END*/}
          <div className="juvly-section full-width">
        <div className="setting-search-outer">
        <span className="newTableTitle">User List</span>
        {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' : <div className="header-select pull-right">
          <select value={ (this.state.role_id ) ? this.state.role_id : "" } onChange={this.handleInputChange} name={"role_id"} className={(this.state.inActive) ? "no-display" : "no-margin" }>
              <option value="" >All roles</option>
              {optData}
          </select>
          <select value={ (this.state.role_id_inactive ) ? this.state.role_id_inactive : "" } onChange={this.handleInputChange} name={"role_id_inactive"} className={(this.state.inActive) ? "no-margin"  : "no-display"}>
              <option value="" >All roles</option>
              {optData}
          </select>
          <i className= "fas fa-angle-down"></i>
        </div>}
       </div> 
          <div className="table-responsive">
              {!this.state.pending && <table className="table-updated setting-table table-min-width no-td-border">
              <thead className="table-updated-thead">
            <tr>
              {/* <th className="col-xs-1 table-updated-th">Image</th> */}
              <th className="col-xs-3 table-updated-th">Name</th>
              <th className="col-xs-2 table-updated-th">Email</th>
              {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' : <th className="col-xs-2 table-updated-th">Role</th>}
      <th className="col-xs-3 table-updated-th no-padding-right">{this.state.inActive ? <span>Inactivated On</span> : <span>Activated On</span>}</th>
              <th className="col-xs-2 table-updated-th">Action</th>
            </tr>
          </thead>
                <tbody className={this.state.active ? "ajax_body" : "no-display"} >
                {

                  (this.state.userList && this.state.userList.length > 0) && this.state.userList.map((obj, idx) => {
                    const isMainAdmin = obj.id === accountAdminId;
                    let userImage = obj.user_image_url ? obj.user_image_url : '/images/user.png';
                  return (
                    <tr key={'userList-'+idx}>
                      <td className="col-xs-2 table-updated-td" data-id={obj.id}>
                        <div className="user-profile-img" style={{backgroundImage: `url(${userImage})`}}></div>
                        {obj.firstname + " "+ obj.lastname}
                      </td>
                      <td className="col-xs-2 table-updated-td" data-id={obj.id}>
                        {obj.email_id}
                      </td>
                      {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' : (
                        <td className="col-xs-2 table-updated-td text-left" data-id={obj.id}>
                          {isMainAdmin ? (
                            <div className="d-flex align-center gap-4">Main Admin <StarIcon className="main-admin-star" width="16px" /></div>
                          ) : roles[obj.user_role_id - 1]}
                        </td>
                      )}
                      <td className="col-xs-3 table-updated-td" data-id={obj.id} >{(obj.created == null || obj.created == "" ) ? "" : showFormattedDate(obj.created) }</td>
                      <td className="col-xs-2 table-updated-td " data-id={obj.id} ><a className="easy-link " key={idx}
                       onClick = {this.userEdit.bind(this, obj.id)}
                       data-title="Edit User" data-url="/user/add/8968" ><i className="fa fa-pencil-alt m-r-5 " />Edit</a></td>
                    </tr>)
                  })
                }
                {(this.state.showLoader === false && (!this.state.userList || this.state.userList.length <= 0)) &&
                  <tr >
                    <td colSpan={6} className="col-xs-12 table-updated-td text-center ">
                      <div className={(this.state.userList.length) ? "no-record no-display" : "no-record"}>
                       {this.state.clinic_No_Record_Found}
                     </div>
                    </td>
                  </tr>
                }
              </tbody>


              <tbody className={this.state.inActive ? "ajax_body" : "no-display"} >
                {

                  (this.state.userList && this.state.userList.length > 0) && this.state.userList.map((obj, idx) => {
                    let userImage = obj.user_image_url ? obj.user_image_url : '/images/user.png';
                  return (
                    <tr key={'userList-'+idx}>
                      
                      <td className="col-xs-2 table-updated-td" data-id={obj.id} >
                      <div className="user-profile-img" style={{backgroundImage: `url(${userImage})`}}></div>
                      {obj.firstname + " "+ obj.lastname}</td>
                      <td className="col-xs-3 table-updated-td" data-id={obj.id} >{obj.email_id}</td>
                      {this.state.isTouchMd == 1 && this.state.plan_selected == 'practice_booking' ? '' :<td className="col-xs-2 table-updated-td text-left" data-id={obj.id} >{roles[obj.user_role_id - 1]}</td>}
                      <td className="col-xs-2 table-updated-td" data-id={obj.id} >{(obj.user_log == null || obj.user_log == undefined || obj.user_log == "") ? "" : showFormattedDate(obj.user_log.created)}</td>
                      <td className="col-xs-2 table-updated-td text-left" data-id={obj.id} ><a className="line-btn pull-left text-center" key={idx}
                       onClick = {this.showActiveModal.bind(this, obj.id)}
                  data-title="Active User" data-url="/user/add/8968" >Activate</a></td>
                    </tr>)
                  })
                }
                {(this.state.showLoader === false && (!this.state.userList || this.state.userList.length <= 0)) &&
                  <tr >
                    <td colSpan={6} className="col-xs-12 table-updated-td text-center ">
                      <div className={(this.state.userList.length) ? "no-record no-display" : "no-record"}>
                       {this.state.clinic_No_Record_Found}
                     </div>
                    </td>
                  </tr>
                }
              </tbody>

            </table>}

            {this.state.pending &&  // Render pending invites table when tab is active
              <table className="table-updated setting-table table-min-width no-td-border">
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-2 table-updated-th">Name</th>
                    <th className="col-xs-2 table-updated-th">Email</th>
                    <th className="col-xs-2 table-updated-th">Phone Number</th>
                    <th className="col-xs-2 table-updated-th">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.renderPendingInvitesTableBody()}
                </tbody>
              </table>}

          </div>
          </div>
          <div className={(this.state.showLoadingText) ? "loading-please-wait no-margin-top" : "loading-please-wait no-margin-top no-display "}>{this.state.loading_please_wait_text}</div>
        </div>
                {/*   Active MODAL - START  */}
                <div className={(this.state.showModal ? 'overlay' : '')}></div>
                  <div id="filterModal" role="dialog" className={(this.state.showModal ? 'modal fade in displayBlock' : 'modal fade')}>
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal" onClick={this.dismissModal}>×</button>
                          <h4 className="modal-title" id="model_title">{this.state.settingsLang.settings_Confirmation_required}{this.state.showModal}</h4>
                        </div>
                        <div id="errorwindow" className="modal-body add-patient-form filter-patient">
                          {"Are you sure you want to activate this user?"}
                        </div>
                        <div className="modal-footer" >
                          <div className="col-md-12 text-left" id="footer-btn">
                            <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.dismissModal}>{this.state.settingsLang.no_option}</button>
                            <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this.activeUserID}>{this.state.settingsLang.yes_option}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*   Active MODAL - END  */}
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.SettingReducer.action === "USERS_LIST") {
    localStorage.setItem('loadFresh', false);
    if(state.SettingReducer.data.status != 200){
      returnState.showLoader = false
    }
    else {
        returnState.userList= state.SettingReducer.data.data
        returnState.userListTime = new Date();
      }
    }
    if (state.SettingReducer.action === "USERS_SEARCH") {
      if(state.SettingReducer.data.status != 200) {
        returnState.showLoader = false
      }
      else
      {
        returnState.userSearch= state.SettingReducer.data.data
      }
    }

    if (state.SettingReducer.action === "USER_STATUS_UPDATE") {
      if(state.SettingReducer.data.status != 200) {
        returnState.showLoader = false
      }
      else
      {
        returnState.userList= state.SettingReducer.data.data
        returnState.userListTime = new Date();
        returnState.redirect = true
       
        toast.success(languageData.global[state.SettingReducer.data.message])
      }
    }

    returnState.userPendingInvites= state.SettingReducer.userPendingInvites;
    returnState.deleteInviteStatus = state.SettingReducer.deleteInviteStatus;
    return returnState;
  }

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchUsers: fetchUsers, userStatusUpdate: userStatusUpdate, exportEmptyData:exportEmptyData, getUsersPendingInvites: getUsersPendingInvites, resendInvite: resendInvite, deleteInvite: deleteInvite }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withCurrentAccountQuery(Users));
