import React, { useCallback, useState } from "react";
import { Header } from "./shared/Header/Header";
import { LayoutInventory } from "../../../boxes/LayoutInventory/LayoutInventory";
import { useData } from "./hooks/useData";
import { Table } from "./shared/Table/Table";
import styles from "./styles.module.scss";

export function ShopifyProducts() {
  const { data, search, filterMapped } = useData();
  const [editedRows, setEditedRows] = useState(new Map());

  const handleSelectProduct = useCallback((id, product) => {
    setEditedRows((prev) => {
      const updatedRows = new Map(prev);
      updatedRows.set(id, product);
      return updatedRows;
    });
  }, []);

  return (
    <LayoutInventory contentClassName={styles.root}>
      <Header
        search={search}
        editedRows={editedRows}
        filterMapped={filterMapped}
      />
      <Table
        data={data}
        editedRows={editedRows}
        onProductSelect={handleSelectProduct}
      />
    </LayoutInventory>
  );
}
