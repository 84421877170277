import http from "http";
import https from "https";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { LayoutMain } from "../../../boxes/LayoutMain/LayoutMain";
import { tCommon, useAppTranslation } from "../../../i18n/useAppTranslation";
import classes from "./SalesCheckoutInvoice.module.scss";
import { checkoutInvoice } from "../../../store/checkoutInvoice";
import { PAYMENT_VIEW_TYPES } from "../../../store/checkoutInvoice/consts";
import ViewPaymentOptions from "./Views/ViewPaymentOptions/ViewPaymentOptions";
import ViewChargeCard from "./Views/ViewChargeCard/ViewChargeCard";
import { ViewChargeCash } from "./Views/ViewChargeCash/ViewChargeCash";
import { ViewChargeArWallet } from "./Views/ViewChargeArWallet/ViewChargeArWallet";
import ViewChargeCherry from "./Views/ViewChargeCherry/ViewChargeCherry";
import ViewChargeKlarna from "./Views/ViewChargeKlarna/ViewChargeKlarna";
import { ViewChargeCareCredit } from "./Views/ViewChargeCareCredit/ViewChargeCareCredit";
import { ViewChargeGreenSky } from "./Views/ViewChargeGreenSky/ViewChargeGreenSky";
import { ViewChargeCheck } from "./Views/ViewChargeCheck/ViewChargeCheck";
import { ViewChargeGiftCard } from "./Views/ViewChargeGiftCard/ViewChargeGiftCard";
import { useInvoice } from "./hooks/invoice/useInvoice";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { GridSkeleton } from "./boxes/GridSkeleton/GridSkeleton";
import { StageStart } from "./Stages/StageStart/StageStart";
import { StageSuccess } from "./Stages/StageSuccess/StageSuccess";
import { StageBalanceDue } from "./Stages/StageBalanceDue/StageBalanceDue";
import { AlertBox } from "../../../shared/AlertBox/AlertBox";
import { LabeledBox } from "./shared/LabeledBox/LabeledBox";
import { ErrorBoundary } from "./boxes/ErrorBoundary/ErrorBoundary";
import { extractUrlInvoiceId } from "./SalesCheckoutInvoice.utils";
import ViewChargeText2Pay from "./Views/ViewChargeText2Pay/ViewChargeText2Pay";

function SalesCheckoutInvoice({ paymentView, history }) {
  const { tSales } = useAppTranslation.Sales();
  const { isLoading: isUserLoading } = useCurrentUserQuery();

  const {
    isInvoiceLoading,
    isInvoiceError,
    errorMessage,
    isStartStage,
    isBalanceDueStage,
    isSuccessStage,
  } = useInvoice();

  const viewByType = {
    [PAYMENT_VIEW_TYPES.optionSelection]: <ViewPaymentOptions />,
    [PAYMENT_VIEW_TYPES.card]: <ViewChargeCard />,
    [PAYMENT_VIEW_TYPES.cash]: <ViewChargeCash />,
    [PAYMENT_VIEW_TYPES.arWallet]: <ViewChargeArWallet />,
    [PAYMENT_VIEW_TYPES.cherry]: <ViewChargeCherry />,
    [PAYMENT_VIEW_TYPES.klarna]: <ViewChargeKlarna />,
    [PAYMENT_VIEW_TYPES.careCredit]: <ViewChargeCareCredit />,
    [PAYMENT_VIEW_TYPES.greenSky]: <ViewChargeGreenSky />,
    [PAYMENT_VIEW_TYPES.check]: <ViewChargeCheck />,
    [PAYMENT_VIEW_TYPES.giftCard]: <ViewChargeGiftCard />,
    [PAYMENT_VIEW_TYPES.text2pay]: <ViewChargeText2Pay />,
  };

  const isSkeletonVisible = isInvoiceLoading || isUserLoading;
  const isDataVisible = !isSkeletonVisible && !isInvoiceError;

  // Destroy merge Invoice
  useEffect(() => {
    const invoiceId = extractUrlInvoiceId();
    const destroyMergerInvoice = async (id) => {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_URL}pos-invoice-merge/${id}`,
          {
            httpAgent: new http.Agent({ keepAlive: true }),
            httpsAgent: new https.Agent({ keepAlive: true }),
          },
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    };
    const onBeforeUnload = (event) => {
      event.preventDefault();
      destroyMergerInvoice(invoiceId);
    };

    const unblock = history.block(() => {
      destroyMergerInvoice(invoiceId);
    });

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      unblock();
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [history]);

  return (
    <ErrorBoundary>
      <LayoutMain className={classes.root}>
        <div className={classes.header}>{tSales("checkoutInvoice.header")}</div>
        {isSkeletonVisible && <GridSkeleton />}
        {isDataVisible && (
          <>
            {isStartStage && <StageStart />}
            {isBalanceDueStage && <StageBalanceDue />}
            {isSuccessStage && <StageSuccess />}
          </>
        )}
        {isInvoiceError && (
          <LabeledBox label={tCommon("label.error")}>
            <AlertBox variant="error">
              {tSales("checkoutInvoice.error.invoiceFetch", {
                message: tSales([
                  `checkoutInvoice.apiError.${errorMessage}`,
                  tCommon("label.unknownError"),
                ]),
              })}
            </AlertBox>
          </LabeledBox>
        )}
        {viewByType[paymentView]}
      </LayoutMain>
    </ErrorBoundary>
  );
}

SalesCheckoutInvoice.propTypes = {
  paymentView: PropTypes.string,
  history: PropTypes.object,
};

const mapStateToProps = (state) => ({
  paymentView: checkoutInvoice.selectors.selectPaymentView(state),
});

export default connect(mapStateToProps)(SalesCheckoutInvoice);
