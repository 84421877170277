import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { passOr, removeNullishFromShape } from "../../utilities/general";

export const CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS = {
  dateOfBirth: "dob",
  email: "email",
  phone: "phoneNumber",
  firstName: "firstname",
  lastName: "lastname",
};

const requestSchema = yup.object({
  clinicId: yup.number().required(),
  exams: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        name: yup.string().required(),
      }),
    )
    .required(),
  patient: yup
    .object({
      id: yup.number().required(),
      firstName: yup.string().nullable(),
      lastName: yup.string().nullable(),
      email: yup.string().nullable(),
      phone: yup.string().nullable(),
      dateOfBirth: yup.string().nullable(),
    })
    .required(),
  appointmentId: yup.number().nullable(),
  requestNew: yup.bool().nullable(),
  attachments: yup.array().of(yup.string()).nullable(),
});

export function useQualiphyCreateExamInviteMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(
      HTTP_ENDPOINTS.qualiphy.createExamInvite(),
      removeNullishFromShape({
        patient_id: req.patient.id,
        clinic_id: req.clinicId,
        exams: req.exams.map((exam) => ({
          id: exam.id,
          name: exam.name,
        })),
        dob: req.patient.dateOfBirth,
        email: req.patient.email,
        phoneNumber: req.patient.phone,
        firstname: req.patient.firstName,
        lastname: req.patient.lastName,
        appointment_id: req.appointmentId,
        request_new: passOr(typeof req.requestNew === "boolean", null, () =>
          req.requestNew ? 1 : 0,
        ),
        attachments: req.attachments,
      }),
    );
  }, options);
}
