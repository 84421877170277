/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../InventorySidebar";
import {
  fetchStockEmailAlerts,
  resetStockEmailAlertStatus,
  saveStockEmailAlerts,
} from "../../../Actions/stockAlertsActions";
import Loader from "../../Common/Loader";

function StockAlertsEmails(props) {
  const [selectedDays, setSelectedDays] = useState([]);
  const [isEnabled, setIsEnabled] = useState(false);
  const isLoading =
    props.stockAlertsData.status === "loading" ||
    props.saveStockAlertsData.status === "loading";

  useEffect(() => {
    props.fetchStockEmailAlerts();
    return props.resetStockEmailAlertStatus;
  }, []);

  useEffect(() => {
    setIsEnabled(props.stockAlertsData.isEnabled);
    setSelectedDays(props.stockAlertsData.selectedDays);
  }, [props.stockAlertsData.isEnabled, props.stockAlertsData.selectedDays]);

  useEffect(() => {
    if (
      props.stockAlertsData.status === "error" ||
      props.saveStockAlertsData.status === "error"
    ) {
      toast.error("There was an error saving your changes.");
      props.resetStockEmailAlertStatus();
    }

    if (props.saveStockAlertsData.status === "success") {
      toast.success("Your settings have been saved.");
      props.resetStockEmailAlertStatus();
    }
  }, [props.stockAlertsData.status, props.saveStockAlertsData.status]);

  function toggleIsEnabled() {
    props.saveStockEmailAlerts(!isEnabled, []);
  }

  function saveSelectedDays(selectedDays) {
    props.saveStockEmailAlerts(isEnabled, selectedDays);
  }

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <Sidebar />
        <div className="memberWalletOuter business-section">
          <div className="setting-setion m-b-10">
            <div className="membership-title">
              <span className="cursor-pointer">Stock Alert Emails</span>
              <div className="memberRightActions">
                <span className="switch-text" />
                <label className="setting-switch pull-right">
                  <input
                    type="checkbox"
                    name="is_active"
                    id="is_active"
                    disabled={false}
                    checked={isEnabled}
                    onChange={toggleIsEnabled}
                  />

                  <span className="setting-slider" />
                </label>
              </div>
            </div>
          </div>
          {isEnabled ? (
            <StockAlertsEmailsContent
              selectedDays={selectedDays}
              save={saveSelectedDays}
            />
          ) : null}
        </div>
      </div>
      <Loader showLoader={isLoading} isFullWidth={true} />
    </div>
  );
}

const days = [
  { name: "Sunday", value: 7 },
  { name: "Monday", value: 1 },
  { name: "Tuesday", value: 2 },
  { name: "Wednesday", value: 3 },
  { name: "Thursday", value: 4 },
  { name: "Friday", value: 5 },
  { name: "Saturday", value: 6 },
];

function StockAlertsEmailsContent(props) {
  const [selectedDays, setSelectedDays] = useState(() => new Set());

  useEffect(() => {
    setSelectedDays(new Set(props.selectedDays));
  }, [props.selectedDays]);

  function toggleDay(dayValue) {
    const nextSelectedDays = new Set(selectedDays);
    if (selectedDays.has(dayValue)) {
      nextSelectedDays.delete(dayValue);
    } else {
      nextSelectedDays.add(dayValue);
    }
    setSelectedDays(nextSelectedDays);
  }

  function save() {
    props.save([...selectedDays]);
  }

  return (
    <div className="juvly-section full-width">
      <div className="p-t-25 p-l-25 p-b-25 p-r-25  clear-both">
        <div className="font-size-16 font-weight-600 m-b-10">
          Emails will be sent to users that have the stock alert permission
          enabled. One email will be sent per day with all products that have
          hit the low stock threshold.
        </div>
        {days.map((d) => (
          <div key={d.name} className="basic-checkbox-outer clear-both">
            <input
              id={d.name}
              className="basic-form-checkbox"
              name={d.name}
              type="checkbox"
              value={d.value}
              checked={selectedDays.has(d.value)}
              onChange={() => toggleDay(d.value)}
            />
            <label className="basic-form-text" htmlFor={d.name}>
              {d.name}
            </label>
          </div>
        ))}

        <button className="new-blue-btn clear-both pull-right" onClick={save}>
          Save
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    stockAlertsData: state.StockAlertsReducer.stockAlertsData,
    saveStockAlertsData: state.StockAlertsReducer.saveStockAlertsData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchStockEmailAlerts,
      saveStockEmailAlerts,
      resetStockEmailAlertStatus,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StockAlertsEmails);
