import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import {
  getPosSetupData,
  savePosSetupData,
  exportEmptyData,
  finalizeStripePosSetup
} from '../../../Actions/Settings/settingsActions.js';
import { parse } from "query-string";
import { toast } from "react-toastify";
import { capitalizeFirstLetter } from '../../../Utils/services.js';
import "react-datepicker/dist/react-datepicker.css";
import validator from 'validator';

const VIEW_TYPE_STRIPE_FINALIZE = 'stripe-finalize';
const VIEW_TYPE_STRIPE_REFRESH = 'stripe-refresh';

class PosSetup extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    return {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      showLoader: false,
      backAction: '/settings/pos',
      posSetupData: {},
      verificationData:[],
      userChanged: false,
      userChangedAdditional: false,
      type:'',
      country:'',
      email: '',
      typeClass:'newSelectField',
      countryClass:'newSelectField',
      emailClass: 'newInputField',
      typeList:[],
      countryList:[],
      currencyList:[],
      show_countries_status:true,
      defaultCountry: 'US',
      defaultCurrency:'USD',
      countryCurrencyLabel:'',
      posStripeRedirectData: null,
      finalizeStripePosSetupState: { status: 'pending' },
    }
  }

  getViewType = () => {
    return this.props.match.params.type;
  }

  getClinicId = () => {
    return this.props.match.params.clinicId || 0;
  }

  getAccountId = () => {
    const qs = parse(window.location.search);
    return qs ? qs.account_id : null;
  }

  componentDidMount() {
    this.props.exportEmptyData()
    const viewType = this.getViewType();

    switch (viewType) {
      case VIEW_TYPE_STRIPE_FINALIZE:
        this.setState({ showLoader: true });
        this.props.finalizeStripePosSetup(this.getAccountId(), this.getClinicId());
        break;
      case VIEW_TYPE_STRIPE_REFRESH:
        // this.setState({ showLoader: true });
        // this.props.refreshStripePosSetup(this.getAccountId(), this.getClinicId());
        break;
      default:
        this.setState({ showLoader: true });
        this.props.getPosSetupData();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.finalizeStripePosSetupState && this.props.finalizeStripePosSetupState) {
      if (prevProps.finalizeStripePosSetupState.status === 'loading' && this.props.finalizeStripePosSetupState.status !== 'loading') {
        this.setState({showLoader: false});
        this.props.history.push(this.state.backAction);
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.posSetupData !== undefined && nextProps.posSetupData !== prevState.posSetupData) {
      returnState.posSetupData = nextProps.posSetupData;
      returnState.show_countries_status = returnState.posSetupData.show_countries_status;
      returnState.defaultCountry = returnState.posSetupData.default_country;
      returnState.defaultCurrency = returnState.posSetupData.default_currency;
      returnState.countryList = returnState.posSetupData.stripe_countries;
      returnState.typeList = returnState.posSetupData.business_type;
      if(!returnState.show_countries_status){
        returnState.country = returnState.defaultCountry;
        let countryName = (returnState.countryList.length > 0) ? returnState.countryList.find(x => x.country_code == returnState.defaultCountry) : null
        if(countryName){
          countryName = countryName.country_name
        }
        returnState.countryCurrencyLabel = capitalizeFirstLetter(countryName) + ` (${returnState.defaultCurrency.toUpperCase()})`;
      }
      returnState.showLoader = false;
    } else if (nextProps.posStripeRedirectData !== undefined && nextProps.posStripeRedirectData !== prevState.posStripeRedirectData) {
      returnState.posStripeRedirectData = nextProps.posStripeRedirectData;
      returnState.showLoader = false;
    } else if (nextProps.showLoader !== undefined && nextProps.showLoader === false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData()
    }

    return returnState
  }

  handleInputChange = ({target}) => {
    let value = target.value;
    let inputName = target.name;

    this.setState({
      userChanged: true,
      [inputName]: value}
    );
    // this.getCurrencyFromCountry();
  }

  handleSubmit = (event) => {
    let error = false;
    if (typeof this.state.type === undefined || this.state.type === null || this.state.type === '') {
      this.setState({typeClass:'newSelectField field_error'})
      error = true;
    } else if(this.state.type) {
      this.setState({typeClass:'newSelectField'})
    }
    // if (typeof this.state.country === undefined || this.state.country === null || this.state.country === '') {
    //   this.setState({countryClass:'newSelectField field_error'})
    //   error = true;
    // } else if(this.state.country) {
    //   this.setState({countryClass:'newSelectField'})
    // }

    if (typeof this.state.email === undefined || this.state.email === null || this.state.email === '' || !validator.isEmail(this.state.email)) {
      this.setState({emailClass:'newInputField field_error'})
      error = true;
    } else if(this.state.country) {
      this.setState({emailClass:'newInputField'})
    }

    if (error) {
      return
    }
    let formData = {
      type:this.state.type,
      clinic_id: this.getClinicId(),
      email: this.state.email,
    }
    this.setState({ showLoader:true });
    this.props.savePosSetupData(formData)
  }

  getViewForType = (viewType) => {
    switch (viewType) {
      case VIEW_TYPE_STRIPE_FINALIZE:
        return this.stripeFinalizeView();
      case VIEW_TYPE_STRIPE_REFRESH:
        return this.stripeRefreshView();
      default:
        return this.beginSetupView();
    }
  }

  getCurrencyFromCountry = () => {
    let selectedCountry = this.props.posSetupData && this.props.posSetupData.stripe_countries.filter(el => {
      return (
          el.country_code === this.state.country
      )
    })
    selectedCountry.length > 0 && this.setState({ selectedCurrency: selectedCountry[0].default_currency })
  }

  render() {
    const viewType = this.getViewType();
    return (
        <div id="content">
          <div className="wide-popup">
            <div className="modal-blue-header">
              {!this.state.showLoader && <Link to={this.state.backAction} className="popup-cross">×</Link>}
              <span className="popup-blue-name">POS Setup</span>
            </div>

            <div className="wide-popup-wrapper time-line">
              {this.getViewForType(viewType)}
            </div>

            <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock' : 'new-loader text-left'}>
              <div className="loader-outer">
                <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" alt="Loading"/>
                <div id="modal-confirm-text" className="popup-subtitle">{this.state.globalLang.Please_Wait}</div>
              </div>
            </div>
          </div>
        </div>
    );
  }

  userInfoView = () => {
    return <>
      <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          <div className="newInputFileldOuter">
            <div
                className="newInputLabel">{this.state.settingsLang.pos_account_type}<span
                className="setting-require">*</span></div>
            <select autoComplete='off' className={this.state.typeClass}
                    name="type" value={this.state.type}
                    onChange={this.handleInputChange}>
              <option value="">{this.state.settingsLang.pos_select_account_type}</option>
              {this.state.typeList.map((accountType, index) => <option key={'typeList-' + index} value={accountType.value}>{accountType.label}</option>)}
            </select>
          </div>
        </div>
        {/* <div className="col-sm-6 col-sm-offset-3">
          <div className="newInputFileldOuter">
            <div
                className="newInputLabel">{this.state.globalLang.label_country}<span
                className="setting-require">*</span></div>
            <div className="setting-input-outer">
              <select autoComplete='off' className={this.state.countryClass}
                      name="country" value={this.state.country}
                      onChange={this.handleInputChange}>
                <option value="">{this.state.globalLang.signup_select_country}</option>
                {this.state.countryList.map((country, index) => <option key={'countryList-' + index} value={country.country_code}>{country.country_name}</option>)}
              </select>
            </div>
          </div>
        </div> */}

        <div className="col-sm-6 col-sm-offset-3">
          <div className="newInputFileldOuter">
            <div
                className="newInputLabel">{this.state.globalLang.label_email}<span
                className="setting-require">*</span></div>
            <input className={this.state.emailClass} name="email"
                   value={this.state.email}
                   placeholder={this.state.globalLang.label_email}
                   onChange={this.handleInputChange}/>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-6 col-sm-offset-3">
          <button type="button" className="new-blue-btn pull-right"
                  onClick={this.handleSubmit}>{this.state.globalLang.label_continue}</button>
        </div>
      </div>
    </>;
  }

  handleStripeRedirect = () => {
    const url = this.state.posStripeRedirectData.data.redirect_link;
    window.location.replace(url);
  }

  stripeRedirectView = () => {
    return <div className="row">
      <div className="col-sm-6 col-sm-offset-3 text-bold text-center font-size-16 font-weight-600 m-b-20">
        Let's Continue Building Your Account!
      </div>
      <div className="col-sm-6 col-sm-offset-3 text-center m-b-20">
        Click the button below to head over to Stripe and finish setting up your profile. This is required to take payments and receive payouts.
      </div>
      <div className="col-sm-12 text-center">
        <button type="button" className="new-blue-btn" onClick={this.handleStripeRedirect}>Take Me To Stripe</button>
      </div>
    </div>
  }

  beginSetupView = () => {
    if (this.state.posStripeRedirectData) {
      return this.stripeRedirectView();
    }

    return this.userInfoView();
  }

  stripeFinalizeView = () => null;
  stripeRefreshView = () => null;
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.SettingReducer.action === "GET_POS_SETUP_DATA") {
    if (state.SettingReducer.data.status !== 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.posSetupData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SAVE_POS_SETUP_DATA") {
    if (state.SettingReducer.data.status !== 200) {
      returnState.message = languageData.global[state.SettingReducer.data.message]
      toast.error(languageData.global[state.SettingReducer.data.message] || state.SettingReducer.data.message);
      returnState.showLoader = false;
    } else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.posStripeRedirectData = state.SettingReducer.data;
      returnState.showLoader = false;
    }
  }

  returnState.finalizeStripePosSetupState = state.SettingReducer.finalizeStripePosSetupState;

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getPosSetupData: getPosSetupData,
    savePosSetupData:savePosSetupData,
    exportEmptyData:exportEmptyData,
    finalizeStripePosSetup: finalizeStripePosSetup
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PosSetup));
