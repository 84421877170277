/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showFormattedDate } from "../../Utils/services.js";
import {
  downloadSurveyData,
  resetReducerAction,
} from "../../Actions/BusinessInsights/businessInsightActions.js";
import { accountStatusResponseInterceptor } from "../AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { surveysList } from "../../Actions/Surveys/surveyActions.js";

const headerInstance = axios.create();
headerInstance.interceptors.response.use(accountStatusResponseInterceptor);
const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

class BiViewAllSurveys extends React.Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const dateData = JSON.parse(
      localStorage.getItem("selectionRange-businessInsight"),
    );

    this.state = {
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      },
      to_date: dateData
        ? apiDateFormat(dateData.endDate)
        : format(new Date(), "YYYY-MM-DD"),
      from_date: dateData
        ? apiDateFormat(dateData.startDate)
        : format(new Date(), "YYYY-MM-DD"),
      viewAllSurveysList: [],
      viewSurveyData: [],
      ProviderClass: "new-dropdown-menu Providers no-display",
      ClinicsClass: "new-dropdown-menu Clinics no-display",
      ServicesClass: "new-dropdown-menu Services no-display",
      NPSClass: "new-dropdown-menu NPS no-display",
      selectedSureyIdList: {
        provider: [],
        services: [],
        clinics: [],
        nps: [1, 2, 3],
      },
      isSurveyListApi: false,
      isSurveyListUpdated: false,
      totalSureyIdList: {
        provider: 0,
        services: 0,
        clinics: 0,
        nps: 3,
      },
      eventSureveyTab: {
        provider: "unselect",
        services: "unselect",
        clinics: "unselect",
        nps: "unselect",
      },
      page: 1,
      pagesize: 15,
      next_page_url: "",
      startFresh: true,
      showLoadingText: false,
      showLoader: false,
      showCalendar: false,
      globalLang: languageData.global,
      surveyLang: languageData.surveys,
      clicked: 0,
      timeStamp: new Date(),
      surveyID:
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.id
          ? this.props.match.params.id
          : 0,
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
  }
  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      surveys_survey: languageData.surveys["surveys_survey"],
      surveys_insights: languageData.surveys["surveys_insights"],
      survey_selected_clinics: languageData.surveys["survey_selected_clinics"],
      survey_selected_nps_score:
        languageData.surveys["survey_selected_nps_score"],
      survey_selected_providers:
        languageData.surveys["survey_selected_providers"],
      survey_selected_services:
        languageData.surveys["survey_selected_services"],
      survey_export: languageData.surveys["survey_export"],
      survey_export_csv: languageData.surveys["survey_export_csv"],
      survey_export_excel: languageData.surveys["survey_export_excel"],
      survey_select_all: languageData.surveys["survey_select_all"],
      survey_unselect_all: languageData.surveys["survey_unselect_all"],
      survey_all_surveys: languageData.surveys["survey_all_surveys"],
      survey_client: languageData.surveys["survey_client"],
      survey_appointment: languageData.surveys["survey_appointment"],
      survey_provider: languageData.surveys["survey_provider"],
      survey_service: languageData.surveys["survey_service"],
      survey_survey_title: languageData.surveys["survey_survey_title"],
      survey_submitted_on: languageData.surveys["survey_submitted_on"],
      survey_satisfaction_score:
        languageData.surveys["survey_satisfaction_score"],
      survey_sorry_no_record_found:
        languageData.surveys["survey_sorry_no_record_found"],
      survey_processing_please_wait:
        languageData.surveys["survey_processing_please_wait"],
    });

    const storedData = JSON.parse(
      localStorage.getItem("selectionRange-businessInsight"),
    );

    let formData = {
      to_date: this.state.to_date,
      from_date: this.state.from_date,
    };

    if (storedData.clinicID && storedData.clinicID > 0) {
      formData.clinic_id = storedData.clinicID;
    }

    this.setState({
      showLoader: true,
      viewSurveyData: [],
    });
    this.props.surveysList(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.viewAllSurveysList != undefined &&
      nextProps.viewAllSurveysList.data != prevState.viewAllSurveysList &&
      nextProps.viewAllSurveysList.status == 200 &&
      nextProps.timeStamp != prevState.timeStamp
    ) {
      returnState.viewAllSurveysList = nextProps.viewAllSurveysList.data;
      returnState.viewSurveyData = nextProps.viewAllSurveysList.data.data;
      returnState.startFresh = false;
      returnState.showLoader = false;
      returnState.showLoadingText = false;
      returnState.selectedSureyIdList = {
        provider: prevState.isSurveyListUpdated
          ? prevState.selectedSureyIdList.provider
          : nextProps.viewAllSurveysList.data.user_ids,
        services: prevState.isSurveyListUpdated
          ? prevState.selectedSureyIdList.services
          : nextProps.viewAllSurveysList.data.service_ids,
        clinics: prevState.isSurveyListUpdated
          ? prevState.selectedSureyIdList.clinics
          : nextProps.viewAllSurveysList.data.clinic_ids,
        nps: prevState.isSurveyListUpdated
          ? prevState.selectedSureyIdList.nps
          : [1, 2, 3],
      };
      returnState.totalSureyIdList = {
        provider: nextProps.viewAllSurveysList.data.user_ids,
        services: nextProps.viewAllSurveysList.data.service_ids,
        clinics: nextProps.viewAllSurveysList.data.clinic_ids,
        nps: [1, 2, 3],
      };
      returnState.timeStamp = nextProps.timeStamp;

      return returnState;
    }

    if (
      nextProps.exportDetails !== undefined &&
      nextProps.exportDetails.status === 200 &&
      nextProps.exportDetails.data !== prevState.exportDetails
    ) {
      returnState.exportDetails = nextProps.exportDetails.data;
      returnState.showLoader = false;

      if (nextProps.exportDetails.data && nextProps.exportDetails.data.file) {
        window.open(nextProps.exportDetails.data.file, "_blank");
      }
      nextProps.resetReducerAction();
    }
    if (nextProps.showLoader === false) {
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    }

    return returnState;
  }

  surveyEdit = (id) => {
    return (
      <div>
        {this.props.history.push(
          `/business-insight/surveys/view/${this.state.surveyID}/${id}/survey-data`,
        )}
      </div>
    );
  };

  closeWindow = () => {
    window.close();
  };

  exportData = () => {
    const storedData = JSON.parse(
      localStorage.getItem("selectionRange-businessInsight"),
    );

    let formData = {
      to_date: this.state.to_date,
      from_date: this.state.from_date,
    };

    if (storedData.clinicID) {
      formData.clinic_id = storedData.clinicID;
    }

    this.setState({ showLoader: true });
    this.props.downloadSurveyData(formData);
  };

  render() {
    return (
      <div className="main protected">
        <form onSubmit={this.handleSubmit}>
          <div id="content">
            <div className="container-fluid content setting-wrapper">
              <div className="memberWalletOuter business-section bi-wallet-outer">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span className="cursor-pointer">
                      {this.state.survey_all_surveys}
                    </span>

                    <div className="memberRightActions">
                      <a
                        onClick={() => this.exportData()}
                        className="easy-link no-padding m-r-50"
                      >
                        <i className="fa fa-download m-r-5" />
                        {this.state.globalLang.label_export_as_excel_text}
                      </a>
                      <a onClick={this.closeWindow} className="pull-right">
                        <img alt="" src="/images/close.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="juvly-section full-width">
                  <div className="table-responsive">
                    <table className="table-updated setting-table">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="col-xs-2 table-updated-th">
                            {this.state.survey_client}
                          </th>
                          <th className="col-xs-3 table-updated-th min-w-160">
                            Appointment Details
                          </th>
                          <th className="col-xs-3 table-updated-th">
                            {this.state.survey_survey_title}
                          </th>
                          <th className="col-xs-2 table-updated-th min-w-160">
                            {this.state.survey_submitted_on}
                          </th>
                          <th className="col-xs-2 table-updated-th min-w-120">
                            {this.state.survey_satisfaction_score}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.viewSurveyData !== undefined &&
                        this.state.viewSurveyData.length > 0
                          ? this.state.viewSurveyData.map((obj, idx) => {
                              return (
                                <tr
                                  key={"tr-survey-" + idx}
                                  className="table-updated-tr"
                                  id={"liConsltDivId_" + idx}
                                  data-order_by={obj.order_by}
                                  onClick={this.surveyEdit.bind(
                                    this,
                                    obj.patient_survey_id,
                                  )}
                                >
                                  <td className="col-xs-2 table-updated-td">
                                    {obj.client}
                                  </td>
                                  <td className="col-xs-3 table-updated-td min-w-160">
                                    {showFormattedDate(obj.appointment, true)}
                                    <div>{obj.provider}</div>
                                    <div>{obj.service_name}</div>
                                  </td>
                                  <td className="col-xs-3 table-updated-td">
                                    {obj.surveys}
                                  </td>
                                  <td className="col-xs-2 table-updated-td min-w-160">
                                    {showFormattedDate(obj.submitted, true)}
                                  </td>
                                  <td className="col-xs-2 table-updated-td min-w-120">
                                    {obj.survey_score_sum}
                                  </td>
                                </tr>
                              );
                            })
                          : this.state.showLoader === false && (
                              <tr className="table-updated-tr">
                                <td className="no-record no-float" colSpan={7}>
                                  {this.state.survey_sorry_no_record_found}
                                </td>
                              </tr>
                            )}
                      </tbody>
                    </table>
                  </div>
                  <div
                    className={
                      this.state.showLoader
                        ? "new-loader text-left displayBlock"
                        : "new-loader text-left"
                    }
                  >
                    <div className="loader-outer">
                      <img
                        alt=""
                        id="loader-outer"
                        src="/images/Eclipse.gif"
                        className="loader-img"
                      />
                      <div id="modal-confirm-text" className="popup-subtitle">
                        {this.state.survey_processing_please_wait}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div
          className={
            this.state.showLoadingText
              ? "loading-please-wait"
              : "loading-please-wait no-display "
          }
        >
          {this.state.globalLang.loading_please_wait_text}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.surveyReducer.action === "SURVEYS_LIST") {
    if (state.surveyReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.viewAllSurveysList = state.surveyReducer.data;
      returnState.timeStamp = new Date();
    }
  }

  if (state.BusinessInsightReducer.action === "DOWNLOAD_SURVEY_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.exportDetails = state.BusinessInsightReducer.data;
      returnState.showLoader = false;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      surveysList: surveysList,
      downloadSurveyData: downloadSurveyData,
      resetReducerAction: resetReducerAction,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BiViewAllSurveys);
