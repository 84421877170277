import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const clockInstance = axios.create();

clockInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

clockInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

clockInstance.interceptors.response.use(accountStatusResponseInterceptor);

export const emptyClockReducer = () => {
  return (dispatch) => {
    dispatch({ type: "EMPTY_CLOCK" });
  };
};

export function changeClockStatus(formData) {
  let type = "CHANGE_LOGGED_CLOCK_STATUS";

  if (formData && formData.user_id) {
    type = "CHANGE_SYSTEM_CLOCK_STATUS";
  }

  return async (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
    return clockInstance
      .post(
        process.env.REACT_APP_API_URL + "clock_in_out",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: type, payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: type, payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function getClockList(formData) {
  return (dispatch) => {
    clockInstance
      .get(
        process.env.REACT_APP_API_URL + "clock_list",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CLOCK_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLOCK_LIST", payload: error.response.data });
      });
  };
}

export function getClockPayPeriod() {
  return (dispatch) => {
    clockInstance
      .get(process.env.REACT_APP_API_URL + "get_account_settings")
      .then((response) => {
        dispatch({ type: "GET_PAY_PERIOD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PAY_PERIOD", payload: error.response.data });
      });
  };
}

export function saveClockPayPeriod(formData) {
  return (dispatch) => {
    clockInstance
      .post(
        process.env.REACT_APP_API_URL + "save_account_settings",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SAVE_PAY_PERIOD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_PAY_PERIOD", payload: error.response.data });
      });
  };
}

export function changeClockSettingStatus(formData) {
  return (dispatch) => {
    clockInstance
      .post(
        process.env.REACT_APP_API_URL + "toggle_account_settings",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "CHANGE_CLOCK_SETTING_STATUS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_CLOCK_SETTING_STATUS",
          payload: error.response.data,
        });
      });
  };
}
