import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import { getPosPayment,getPosExport,exportEmptyData, changePosStatus} from '../../../../Actions/Settings/settingsActions.js';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../../Common/Loader.js'
import { numberFormat,getCurrencyLabel,showFormattedDate, autoScrolling, getIsPosEnabled } from "../../../../Utils/services.js";
import PosTitle from './../PosTitle.js';
import { LoadMoreObserver } from '../../../../shared/LoadMoreObserver/LoadMoreObserver.js';
import moment from 'moment';
import { DateRangePicker } from '../../../../shared/DateRangePicker/DateRangePicker.js';

class PosPayments extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      hasMoreItems: true,
      next_page_url: '',
      userList: [],
      loadMore: true,
      startFresh: true,
      showLoader: false,
      showLoadingText: false,
      userData: '',
      page: 1,
      pagesize: 15,
      posPaymentList:[],
      payout_last_updated:'',
      posPaymentExport:{},
      fromDate: moment(),
      toDate: moment(),
    }
    localStorage.setItem('loadFresh', false);
    localStorage.setItem('sortOnly', false);
  }

  componentDidMount() {
    this.setState({
      startFresh: true,
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      posPaymentList: [],
      showLoader:true,
      next_page_url:'',
      showLoader:true, 
    });
    autoScrolling(true);
    this.props.getPosPayment({
      'params': {
        page: this.state.page,
        pagesize: this.state.pagesize,
        fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
        toDate:  moment(this.state.toDate).format('YYYY-MM-DD'),
      }
    }).then((response) => 
        this.setState({ posPaymentList: response.data.payments.data }))
      .finally(() => 
        this.setState({ showLoader: false }));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false && nextProps.showLoader != prevState.showLoader){
      nextProps.exportEmptyData();
      return {showLoader:false}
    } else if (nextProps.changePosMode != undefined && nextProps.changePosMode == true) {
      returnState.showLoader = false;
      returnState.pos_enabled = !prevState.pos_enabled;
      nextProps.exportEmptyData();
    }  else if (nextProps.posPaymentExport != undefined && nextProps.posPaymentExport != prevState.posPaymentExport){
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      returnState.posPaymentExport = nextProps.posPaymentExport;
      window.open(process.env.REACT_APP_API_URL+"download-data/"+nextProps.posPaymentExport.file, "_blank");
      return returnState;
    } if (nextProps.posPaymentList != undefined && nextProps.posPaymentList.payments != undefined &&
      nextProps.posPaymentList.payments.next_page_url !== prevState.next_page_url) {
      if (prevState.next_page_url == null) {
        localStorage.setItem("sortOnly", false);
        returnState.next_page_url = null;
        autoScrolling(false);
        return returnState;
      }
      if (prevState.posPaymentList.length == 0 && prevState.startFresh == true) {
        if (localStorage.getItem("sortOnly") == "false") {
          returnState.payout_last_updated = nextProps.posPaymentList.payout_last_updated;
          if (nextProps.posPaymentList.payments.next_page_url != null) {
          } else {
            returnState.next_page_url = nextProps.posPaymentList.payments.next_page_url;
          }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.showLoadingText = false;
          localStorage.setItem('showLoader', false);
        } else {
          localStorage.setItem("sortOnly", false);
        }
      } else if (prevState.posPaymentList != nextProps.posPaymentList.payments.data &&
        prevState.posPaymentList.length != 0) {
        returnState.next_page_url = nextProps.posPaymentList.payments.next_page_url;
        localStorage.setItem('showLoader', false);
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }
      autoScrolling(false);
      return returnState;
    }
    return returnState;

  }

  loadMore = () => {
      localStorage.setItem('sortOnly', false);
      this.setState({ 'loadMore': true, startFresh: true, showLoader: true, showLoadingText: true })
      autoScrolling(true);
      this.props.getPosPayment({
        'params': {
          page: this.state.page + 1,
          pagesize: this.state.pagesize,
          fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
          toDate:  moment(this.state.toDate).format('YYYY-MM-DD'),
        }
      }).then((response) => this.setState({ posPaymentList: [...this.state.posPaymentList, ...response.data.payments.data] })).finally(() => this.setState({ showLoader: false, page: this.state.page + 1 }));
  }

  getPosExport = (type) => {
    this.setState({showLoader:true})
    this.props.getPosExport({
      export_type : type, //required, string values[xls,csv]
      export_name : 'payments', //required, string values[payments,payouts]
      fromDate: moment(this.state.fromDate).format('YYYY-MM-DD'),
      toDate:  moment(this.state.toDate).format('YYYY-MM-DD')
    });
  }

  get userDateFormat() {
    return localStorage.getItem("dateFormat") || "MM/DD/YYYY";
  }

  render() {
    return (
      <div className="main protected">
        <div id="content" className="pos-content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <PosTitle history={this.props.history} settingsLang={this.state.settingsLang} globalLang={this.state.globalLang} activeMenu={'pos-payments'} />
              {(getIsPosEnabled() === true ) && <div className="setting-setion">
                <div className="setting-search-outer">
                  {(this.state.payout_last_updated != undefined && this.state.payout_last_updated != null && this.state.payout_last_updated != '') &&
                    <span className="pull-left search-text">{this.state.settingsLang.pos_payments_and_payouts_data_last_updated}: {showFormattedDate(this.state.payout_last_updated,true)} (UTC)</span>
                  }
                  <div className="memberRightActions flex">
                    <DateRangePicker
                      size="small"
                      dateFormat={this.userDateFormat}
                      onChange={({ startDate, endDate }) => {
                        this.setState({
                          fromDate: startDate,
                          toDate: endDate,
                          page: 1,
                          showLoader: true,
                        }, () => this.props.getPosPayment({
                          'params': {
                            page: 1,
                            pagesize: this.state.pagesize,
                            fromDate: moment(startDate).format('YYYY-MM-DD'),
                            toDate:  moment(endDate).format('YYYY-MM-DD'),
                          }
                        })
                        .then((response) => 
                          this.setState({ posPaymentList: response.data.payments.data }))
                        .finally(() => 
                          this.setState({ showLoader: false })));
                      }}
                      value={{
                        startDate: new Date(this.state.fromDate),
                        endDate: new Date(this.state.toDate),
                      }}
                    />
                    <a onClick={() => this.getPosExport('csv')} className="easy-link no-padding"><i className="fa fa-download m-r-5" />{this.state.globalLang.label_export_as_csv_text}</a>
                    <a onClick={() => this.getPosExport('xls')} className="easy-link no-padding"><i className="fa fa-download m-r-5" />{this.state.globalLang.label_export_as_excel_text}</a>
                  </div>
                </div>
                <div className="setting-container no-padding">
                  <div className="table-responsive">
                    <table className="table-updated no-hover setting-table table-min-width no-td-border">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="col-lg-2 col-xs-3 table-updated-th text-right">{this.state.globalLang.label_amount}</th>
                          <th className="col-lg-2 col-xs-3 table-updated-th no-border-left" />
                          <th className="col-lg-2 col-xs-3 table-updated-th">{"Invoice Number"}</th>
                          <th className="col-lg-2 col-xs-3 table-updated-th">{this.state.globalLang.label_customers}</th>
                          <th className="col-lg-3 col-xs-3 table-updated-th text-right">{this.state.globalLang.label_date}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(this.state.posPaymentList.length)
                          ?
                          this.state.posPaymentList.map((obj,idx) => {
                              return (
                                <tr className="table-updated-tr" key={'posPayment-'+idx}>
                                  <td className="table-updated-td text-right">
                                    <label className="payment-amount">{numberFormat(((obj.amount > 0) ? (obj.amount / 100) : 0), 'currency',2)}</label>
                                  </td>
                                  <td className="table-updated-td no-padding no-border-left">
                                    <span className="payment-currancy">{getCurrencyLabel().toUpperCase()}</span>
                                    <div className="payment-Succeeded">{this.state.settingsLang.setting_succeeded}</div>
                                  </td>
                                  <td className="table-updated-td">{obj.invoice_number }</td>
                                  <td className="table-updated-td">{obj.customer_name }</td>
                                  <td className="table-updated-td text-right">{showFormattedDate(obj.created,true)}</td>
                                </tr>
                              )
                          })
                          :
                          <tr className="table-updated-tr">
                            <td className="table-updated-td text-center" colSpan={5}>
                              {(this.state.showLoader === false) && <div className="no-record">{this.state.globalLang.sorry_no_record_found}</div>}
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                    {Boolean(this.state.posPaymentList.length) && <LoadMoreObserver
                      loadMore={this.loadMore}
                      hasMore={this.state.next_page_url !== null}
                      isLoading={false}
                    />}
                  </div>
                </div>
              </div>
            }
            <Loader showLoader={this.state.showLoader} isFullWidth={true} />
          </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  if (state.SettingReducer.action === "POS_PAYMENT_LIST") {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    }
  } else if (state.SettingReducer.action === "POS_PAYMENT_PAYOUT_EXPORT") {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.posPaymentExport = state.SettingReducer.data.data;
    }
  } else if (state.SettingReducer.action === "CHANGE_POS_STATUS") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.changePosMode = true;
    }
  } else if (state.SettingReducer.action === "EMPTY_DATA") {
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getPosPayment: getPosPayment,getPosExport:getPosExport,exportEmptyData:exportEmptyData, changePosStatus: changePosStatus}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(PosPayments);
