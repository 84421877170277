import React, { useState } from "react";
import styles from "./scss/chartFilters.module.scss";
import cx from "clsx";
import * as yup from "yup";
import { LayoutSettings } from "../../../../boxes/LayoutSettings/LayoutSettings";
import { EntityHeader } from "../../../../widgets/EntityHeader/EntityHeader";
import { Box } from "../../../../shared/Box/Box";
import { ContentLoader } from "../../../../boxes/ContentLoader/ContentLoader";
import { Table as SharedTable } from "../../../../shared/Table/Table";
import { useChartingFilterCategories } from "./hooks/useChartingFilterCategories";
import { Button } from "../../../../shared/Button/Button";
import { useFormik } from "formik";
import { Input } from "../../../../shared/Input/Input";
import { useEditChargingFilterCategories } from "./hooks/useEditChartingFilterCategories";
import { uiNotification } from "../../../../services/UINotificationService";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import CreateCategoryModal from "./components/CreateCategoryModal";
import { InputError } from "../../../../shared/InputError/InputError";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";

const ChartFilters = () => {
  const [editCategory, setEditCategory] = useState({});
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();

  const {
    data: categories,
    refetch: refetchCategories,
    isFetching,
  } = useChartingFilterCategories();

  const { mutateAsync: updateCategory, isLoading: isSaving } =
    useEditChargingFilterCategories(editCategory.id);

  const handleSave = async () => {
    try {
      const res = await updateCategory({ name: values.name });
      uiNotification.success(res.data.message);
      refetchCategories();
    } catch (e) {
      uiNotification.error(e.response.data.message);
    } finally {
      setEditCategory({});
    }
  };

  const schema = yup.object().shape({
    name: yup
      .string()
      .required(
        tSettings(
          "clinicalDocumentation.chartFilters.errors.categoryNameRequired",
        ),
      ),
  });

  const { values, setFieldValue, handleSubmit, errors } = useFormik({
    onSubmit: handleSave,
    validationSchema: schema,
    initialValues: { name: editCategory.name },
  });

  const tableData = categories?.map((category) => ({
    id: category.id,
    categoryName:
      editCategory.id !== category.id ? (
        category.name
      ) : (
        <>
          <Input
            value={values.name}
            onChange={(event) => setFieldValue("name", event.target.value)}
            size="small"
            isError={Boolean(errors.name)}
          />
          {errors.name && <InputError>{errors.name}</InputError>}
        </>
      ),
    actionButtons:
      editCategory.id !== category.id ? (
        <Button
          size="small"
          onClick={() => {
            setEditCategory(category);
            setFieldValue("name", category.name);
          }}
        >
          {tCommon("label.edit")}
        </Button>
      ) : (
        <div className={cx(styles.editModeButton)}>
          <Button
            size="small"
            onClick={handleSubmit}
            leftAdornment={
              isSaving ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
            isDisabled={isSaving}
          >
            {tCommon("label.save")}
          </Button>
          <Button
            size="small"
            onClick={() => setEditCategory({})}
            variant="outlined"
            isDisabled={isSaving}
          >
            {tCommon("label.cancel")}
          </Button>
        </div>
      ),
  }));

  return (
    <LayoutSettings contentClassName={cx(styles.root)}>
      <EntityHeader
        title={tSettings("clinicalDocumentation.chartFilters.title")}
        right={
          <Button size="small" onClick={() => setShowCreateModal(true)}>
            {tSettings("clinicalDocumentation.chartFilters.createButton")}
          </Button>
        }
      />
      <Box>
        <ContentLoader isLoading={isFetching}>
          <SharedTable
            bgColor="white"
            keyExtractor={(row) => row.id}
            onRowClick={null}
            colClassName={cx(styles.tableColumn)}
            data={tableData}
            cols={[
              {
                data: "Category Name",
                accessor: "categoryName",
              },
              {
                data: "Actions",
                accessor: "actionButtons",
                className: cx("flex"),
              },
            ]}
          />
        </ContentLoader>
      </Box>
      {showCreateModal && (
        <CreateCategoryModal
          onClose={() => setShowCreateModal(false)}
          refetchCategories={refetchCategories}
        />
      )}
    </LayoutSettings>
  );
};

export default ChartFilters;
