import axios from "axios";
import { toast } from "react-toastify";
import { getToken } from "../../Utils/services";

const http = axios.create();
http.defaults.headers.common["access-token"] = getToken();

const getAccountId = () => {
  return JSON.parse(localStorage.getItem("userData")).account.id;
};

export function getCardreaders(userAccountId) {
  return (dispatch) => {
    http
      .get(
        `${process.env.REACT_APP_API_URL}payment/card_readers/${userAccountId}`,
      )
      .then((response) => {
        dispatch({ type: "GET_CARDREADERS_SUCCESS", payload: response.data });
      })
      .catch((e) => {
        dispatch({ type: "GET_CARDREADERS_ERROR", payload: e.response.error });
      });
  };
}

export const fetchClinics = () => {
  return (dispatch) => {
    http
      .get(process.env.REACT_APP_API_URL + "clinics")
      .then((response) => {
        dispatch({ type: "CLINIC_LIST", payload: response.data });
      })
      .catch((e) => {
        dispatch({ type: "CLINIC_LIST", payload: e.response.data });
      });
  };
};

export const addCardreader = (data) => {
  const accountId = getAccountId();
  return (dispatch) => {
    http
      .post(`${process.env.REACT_APP_API_URL}payment/add_card_reader`, data)
      .then((response) => {
        if (Object.keys(response.data.error).length <= 0) {
          dispatch({ type: "ADD_CARDREADER_SUCCESS", payload: response.data });
          toast.success("Card reader successfully attached!");
          dispatch(getCardreaders(accountId));
        } else {
          dispatch({
            type: "ADD_CARDREADER_ERROR",
            payload: response.data.error,
          });
          if (
            typeof response.data.error === "string" &&
            response.data.error !== ""
          ) {
            toast.error(response.data.error);
          }
          if (typeof response.data.error === "object") {
            toast.error(response.data.error.serial_number[0]);
          }
        }
      })
      .catch((e) => {
        dispatch({ type: "ADD_CARDREADER_ERROR", payload: e.response.error });
      });
  };
};

export const updateCardreader = (cardreaderId, data) => {
  const accountId = getAccountId();
  return (dispatch) => {
    http
      .patch(
        `${process.env.REACT_APP_API_URL}payment/update_card_reader/${cardreaderId}`,
        data,
      )
      .then((response) => {
        dispatch({ type: "UPDATE_CARDREADER_SUCCESS", payload: response.data });
        if (data.payworks_status === "active") return;
        dispatch(getCardreaders(accountId));
      })
      .catch((e) => {
        dispatch({ type: "UPDATE_CARDREADER_ERROR", payload: e.response.data });
      });
  };
};

export const getCardreadersModels = (provider) => {
  return (dispatch) => {
    http
      .get(
        `${process.env.REACT_APP_API_URL}payment/card_readers_models/${provider}`,
      )
      .then((response) => {
        dispatch({
          type: "GET_CARDREADERS_MODELS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "GET_CARDREADERS_MODELS_ERROR",
          payload: e.response.data,
        });
      });
  };
};
