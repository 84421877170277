import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { positionFooterCorrectly,showFormattedDate } from '../../../Utils/services';

class CustomerNotes extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			clientLang : languageData.clients,
			globalLang : languageData.global,
			select_all : false
		};
	}
	
	static getDerivedStateFromProps(nextProps, prevState) {
		positionFooterCorrectly();
		let returnState = {};
		if(nextProps.notesDataTime != prevState.notesDataTime){
			if(nextProps.notes != undefined){
				returnState.notes = nextProps.notes;
				returnState.showLoader = false;
				nextProps.notes.map((data,index) => {
					returnState['note-' + data.id] = data.selected;
				})
				if(nextProps.notes.length > 0){
				let selectAllEnable;
				selectAllEnable = nextProps.notes.find(y => y.selected == false)
				if(selectAllEnable){
					returnState.select_all = false
				} else {
					returnState.select_all = true;
				}}
			}
			returnState.notesDataTime = nextProps.notesDataTime;
		}
		return returnState;
	}



	handleInputChange = (event) => {
		const target = event.target;
		let value= target.value;
		let name = event.target.name;
		let returnState = {}
		switch(target.type) {
			case 'checkbox': {
				value = target.checked;
				break;
			}
	  
			case 'radio' :{
			  value = target.value;
			  break;
		  }
		}
		if(target.type == 'checkbox' && target.checked == true && target.name == 'select_all') {
			let data = this.state.notes;
			data.map((obj, index) => {
				returnState['note-' + obj.id] = true;
			})

		} else if(target.type == 'checkbox' && target.checked == false && target.name == 'select_all') {
			let data = this.state.notes;
			data.map((obj, index) => {
				returnState['note-' + obj.id] = false;
			})
		}
	  returnState[event.target.name] = value;
	  this.setState(returnState, () => this.sendDataToParent());
	}

	sendDataToParent = () => {
		let notes = [];
		let checkStatus = [];
		if(this.state.notes.length > 0){
			this.state.notes.map((data,index) => {
				if(this.state['note-' + data.id] == false){
					checkStatus.push(data);
				}
			})
			if(checkStatus.length > 0){
				this.setState({ select_all : false });
			} else {
				this.setState({ select_all : true });
			}
		}
		this.state.notes.map((data, index) => {
			notes.push({
				added_by: data.added_by,
                created: data.created,
                edited_by: data.edited_by,
                hashtags: data.hashtags,
                id: data.id,
                modified: data.modified,
                notes: data.notes,
                patient_id: data.patient_id,
                title: data.title,
                user_id: data.user_id,
                selected : this.state['note-' + data.id ]
			})
		})
		let formData = {
			step7Data : {
				notes : notes
			}
		}
		this.props.handleChildState(formData);
	}


    render(){
        return(
        <div>
        <div className="merge-title full-width">
        <div className="row">
		<div className="col-xs-12">
			<div className="setting-setion full-width heightAuto">
				<div className="merge-main-title checkOuter">
				{this.state.notes && this.state.notes.length > 0 && <input type="checkbox" id="double-booking" 
				name="select_all" value={this.state.select_all} 
				checked={(this.state.select_all) ? "checked" : false} 
				onChange={this.handleInputChange} />}
					<span>{this.state.clientLang.clientprofile_customer_notes}</span>
					{this.state.notes && this.state.notes.length > 0 && <p>{this.state.clientLang.merge_right_information_message}</p>}
				</div>
			{this.state.notes && this.state.notes.map((data,index) => {
				return(
                    
					<div className="procedure-row checkOuter" key={data.id}>
					<input type="checkbox" value={data.selected} name={"note-"+data.id} 
					checked={(this.state["note-"+data.id]) ? "checked" : false} autoComplete="off" onChange={this.handleInputChange} />
					<div className="procedureName m-b-5">{data.title ? data.title : 'Note #' + (index + 1)}</div>
					<div className="procedureInformation">
						<div className="procedureDetails p-l-0">
							<div className="info-row">
								<span>{showFormattedDate(data.created, true)}</span>
							</div>
							<div className="info-row m-b-0">
								<span>{data.notes}</span>
							</div>
						</div>
					</div>
				</div>
			)
			})}
			{this.state.notes.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
			
			</div>
		</div>
		
	</div>
        </div>
        </div>
		)
    }}

export default CustomerNotes;