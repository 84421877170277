import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const traceInstance = axios.create();

traceInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

traceInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

traceInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getTraceData(clientID, procedureID) {
  return async (dispatch) => {
    return traceInstance
      .get(
        process.env.REACT_APP_API_URL +
          "traceability_info/" +
          clientID +
          "/" +
          procedureID,
      )
      .then((response) => {
        dispatch({ type: "GET_TRACE_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_TRACE_DATA", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getBatchByProcedure(productID, procedureID, type, traceID) {
  return async (dispatch) => {
    return traceInstance
      .get(
        process.env.REACT_APP_API_URL +
          "traceability/get_batch/" +
          procedureID +
          "/" +
          productID +
          "/" +
          type +
          "/" +
          traceID,
      )
      .then((response) => {
        dispatch({ type: "GET_BATCH_BY_PROCEDURE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_BATCH_BY_PROCEDURE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getUnits(formData) {
  return async (dispatch) => {
    return traceInstance
      .post(
        process.env.REACT_APP_API_URL + "traceability/get_units?type=diapason",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "GET_UNITS_BY_INVENTORY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_UNITS_BY_INVENTORY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveTrace(formData) {
  return async (dispatch) => {
    return traceInstance
      .post(
        process.env.REACT_APP_API_URL + "traceability/save",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "SAVE_TRACE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_TRACE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function updateTrace(formData) {
  return async (dispatch) => {
    return traceInstance
      .post(
        process.env.REACT_APP_API_URL + "traceability/update",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "UPDATE_TRACE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_TRACE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function deleteTrace(formData) {
  return async (dispatch) => {
    return traceInstance
      .post(
        process.env.REACT_APP_API_URL + "traceability/delete",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "DELETE_TRACE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "DELETE_TRACE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function searchProduct(formData) {
  return async (dispatch) => {
    return traceInstance
      .post(
        process.env.REACT_APP_API_URL + "search-product",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SEARCH_TRACE_PRODUCTS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SEARCH_TRACE_PRODUCTS",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveTraceProducts(formData) {
  return async (dispatch) => {
    return traceInstance
      .post(
        process.env.REACT_APP_API_URL + "add-injections",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ADD_INJECTIONS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "ADD_INJECTIONS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
