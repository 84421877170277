/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import moment from "moment";
import { withRouter } from "react-router";
import calenLogo from "../../../_legacy/images/calender.svg";
import { exportSalesSummary } from "../../../Actions/Sales/salesActions.js";
import { uiNotification } from "../../../services/UINotificationService.js";
import { composeDownloadDataUrl } from "../../../utilities/api.js";
import { featureToggleAccounts } from "../../../Utils/services.js";

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const getIdOfLocations = (locationsArray) => {
  let locationIdList = [];

  locationsArray.map((obj) => {
    locationIdList.push(obj.id);
  });
  return locationIdList;
};

const getIdOfEmployees = (employeeArray) => {
  let employeeIdList = [];
  employeeArray.map((obj) => {
    employeeIdList.push(obj.id);
  });
  return employeeIdList;
};

class SalesFilter extends Component {
  constructor(props) {
    super(props);
    const dateFormat = localStorage.getItem("dateFormat");
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    let selectionRangeLocalVar = "selectionRange";
    if (this.props.reportType == "invoices") {
      selectionRangeLocalVar = selectionRangeLocalVar + "-invoices";
    } else {
      selectionRangeLocalVar = selectionRangeLocalVar + "-report";
    }

    const dateData = localStorage.getItem(selectionRangeLocalVar);
    const valR = dateData ? JSON.parse(dateData) : "";
    const startD = valR.startDate;
    const endD = valR.endDate;

    this.state = {
      dateFormat: dateFormat,
      globalLang: languageData.global,
      settingsLang: languageData.settings,
      initialFocusedRange: [0, 0],
      dateRangePicker: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      },
      toDate: format(new Date(), "YYYY-MM-DD"),
      fromDate: format(new Date(), "YYYY-MM-DD"),
      showCalendar: false,
      search_key: "",
      dateRangeValue: "",
      churn_filter: "",
      churnActive: false,
      selctedLocationClass: "new-dropdown-menu no-display",
      selctedEmployeeClass: "new-dropdown-menu no-display",
      selctedInvoicesClass: "new-dropdown-menu no-display",
      islocationArrayListApi: false,
      isemployeeArrayListApi: false,
      isinvoicesArrayListApi: false,
      islocationArrayListUpdated: false,
      isemployeeArrayListUpdated: false,
      isinvoicesArrayListUpdated: false,
      totalLocationIdList: [],
      salesLang: languageData.sales,
      selectedLocationIdList: [],
      selectedEmployeeIdList: [],
      selectedInvoicesIdList: [],
      locationArray: [],
      employeeArray: [],
      invoicesArray: [],
      eventSelectedLocation: "unselect",
      eventSelectedEmployee: "unselect",
      eventSelectedInvoices: "unselect",
      expType: "csv",
      expoType: "xlsx",
      bothVal: "both",
      cancelClient: "manual",
      cancelPayment: "payment_failed",
      selectedInvoices: 6,
      focusSearch: false,
      startDate: dateData ? startD : new Date(),
      endDate: dateData ? endD : new Date(),
      clicked: 0,
      selectionRangeLocalVar: selectionRangeLocalVar,
      dateData: dateData,
      filterType:
        this.props &&
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.filterType
          ? this.props.match.params.filterType
          : "",
      filterID: this.props.match.params.filterID
        ? this.props.match.params.filterID
        : 0,
    };
    localStorage.setItem("showLoader", true);
  }

  componentDidMount() {
    var element = document.getElementById("root");
    element.scrollIntoView();
    let selectionRangeLocalVar = "selectionRange";
    if (this.props.reportType == "invoices") {
      selectionRangeLocalVar = selectionRangeLocalVar + "-invoices";
    } else {
      selectionRangeLocalVar = selectionRangeLocalVar + "-report";
    }
    const dateData = localStorage.getItem(selectionRangeLocalVar);
    const valR = dateData ? JSON.parse(dateData) : "";

    const startD = valR.startDate;
    const endD = valR.endDate;
    this.setState({
      startDate: startD,
      endDate: endD,
      selectionRangeLocalVar: selectionRangeLocalVar,
      dateData: dateData,
    });
    localStorage.setItem("showLoader", true);
    document.addEventListener("click", this.handleClick, false);
  }

  static getDerivedStateFromProps(props, state) {
    let returnState = {};

    if (
      props.locationArray !== undefined &&
      state.locationArray != props.locationArray
    ) {
      returnState.locationArray = props.locationArray;
      returnState.totalLocationIdList = state.userChanged
        ? state.totalLocationIdList
        : getIdOfLocations(returnState.locationArray);

      if (state.filterType && state.filterType === "clinic" && state.filterID) {
        returnState.selectedLocationIdList = state.userChanged
          ? state.selectedLocationIdList
          : [parseInt(state.filterID)];
      } else {
        returnState.selectedLocationIdList = state.userChanged
          ? state.selectedLocationIdList
          : getIdOfLocations(returnState.locationArray);
      }
    }
    if (
      props.employeeArray !== undefined &&
      state.employeeArray != props.employeeArray
    ) {
      returnState.employeeArray = props.employeeArray;
      returnState.totalEmployeeIdList = state.userChanged
        ? state.totalEmployeeIdList
        : getIdOfEmployees(returnState.employeeArray);

      if (
        state.filterType &&
        state.filterType === "provider" &&
        state.filterID
      ) {
        returnState.selectedEmployeeIdList = state.userChanged
          ? state.selectedEmployeeIdList
          : [parseInt(state.filterID)];
      } else {
        returnState.selectedEmployeeIdList = state.userChanged
          ? state.selectedEmployeeIdList
          : getIdOfEmployees(returnState.employeeArray);
      }
    }
    if (props.invoicesArray !== undefined && !state.userChanged) {
      let selectedInvoicesIdList = [];

      props.invoicesArray.map((obj) => {
        returnState[Object.keys(obj)] = state.userChanged
          ? state[Object.keys(obj)]
          : true;
        selectedInvoicesIdList.push(Object.keys(obj)[0]);
      });
      returnState.selectedInvoicesIdList = selectedInvoicesIdList;
      returnState.selectedInvoices = 6;
      returnState.invoicesArray = props.invoicesArray;
    }

    if (props.reportType != state.reportType) {
      returnState.reportType = props.reportType;
    }
    return returnState;
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  handleClick = (event) => {
    let flag = false;
    let returnState = {};
    if (event.target.className == "line-btn text-center") {
      return;
    }
    if (this.node.contains(event.target)) {
      if (this.state.showCalendar === true) {
        return;
      }
    }

    this.toggleCalendar(event.target);
    if (this.searchFocus.contains(event.target)) {
      return;
    } else {
      returnState.focusSearch = false;
    }

    let selctedLocationClass = "new-dropdown-menu no-display";
    let selctedEmployeeClass = "new-dropdown-menu no-display";
    let selctedInvoicesClass = "new-dropdown-menu no-display";

    if (this.muli_sel_btn_nps.contains(event.target)) {
      selctedLocationClass = "new-dropdown-menu";
      if (event.target.className === "multi-sel-btn") {
        if (this.state.selctedLocationClass === "new-dropdown-menu") {
          selctedLocationClass = "new-dropdown-menu no-display";
          if (this.state.islocationArrayListApi) {
            returnState.islocationArrayListApi = false;
            flag = true;
          }
        }
      }
    } else if (this.state.islocationArrayListApi) {
      returnState.islocationArrayListApi = false;
      flag = true;
    }

    if (this.muli_sel_btn_eps.contains(event.target)) {
      selctedEmployeeClass = "new-dropdown-menu";
      if (event.target.className === "multi-sel-btn") {
        if (this.state.selctedEmployeeClass === "new-dropdown-menu") {
          selctedEmployeeClass = "new-dropdown-menu no-display";
          if (this.state.isemployeeArrayListApi) {
            returnState.isemployeeArrayListApi = false;
            flag = true;
          }
        }
      }
    } else if (this.state.isemployeeArrayListApi) {
      returnState.isemployeeArrayListApi = false;
      flag = true;
    }

    if (this.muli_sel_btn_ips.contains(event.target)) {
      selctedInvoicesClass = "new-dropdown-menu";
      if (event.target.className === "multi-sel-btn") {
        if (this.state.selctedInvoicesClass === "new-dropdown-menu") {
          selctedInvoicesClass = "new-dropdown-menu no-display";
          if (this.state.isinvoicesArrayListApi) {
            returnState.isinvoicesArrayListApi = false;
            flag = true;
          }
        }
      }
    } else if (this.state.isinvoicesArrayListApi) {
      returnState.isinvoicesArrayListApi = false;
      flag = true;
    }

    let arr = [];
    if (flag) {
      if (this.state.invoicesArray) {
        this.state.invoicesArray.map((obj) => {
          if (this.state[Object.keys(obj)]) {
            arr.push(Object.keys(obj)[0]);
          }
        });
      }
      returnState.selectedInvoicesIdList = arr;
      this.setState(returnState, () => {
        this.props.handleChildSubmit({
          selectedEmployeeIdList: this.state.selectedEmployeeIdList,
          selectedLocationIdList: this.state.selectedLocationIdList,
          selectedInvoicesIdList: arr,
          fromDate: this.state.startDate,
          toDate: this.state.endDate,
          canSubmit: true,
        });
      });
    }
    returnState.selctedInvoicesClass = selctedInvoicesClass;
    returnState.selctedLocationClass = selctedLocationClass;
    returnState.selctedEmployeeClass = selctedEmployeeClass;
    returnState.userChanged = true;
    this.setState(returnState);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let arr = [];
    if (this.state.invoicesArray) {
      this.state.invoicesArray.map((obj) => {
        if (this.state[Object.keys(obj)]) {
          arr.push(Object.keys(obj)[0]);
        }
      });
    }
    this.props.handleChildSubmit({
      selectedEmployeeIdList: this.state.selectedEmployeeIdList,
      selectedLocationIdList: this.state.selectedLocationIdList,
      selectedInvoicesIdList: arr,
      fromDate: this.state.startDate,
      toDate: this.state.endDate,
      search_key: this.state.search_key,
      canSubmit: true,
    });
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar });
  };

  dateRangeOnChange = (payload) => {
    let payloadValue = {};
    if (payload) {
      payloadValue = {
        startDate: payload.selection.startDate,
        endDate: payload.selection.endDate,
        key: "selection",
      };
      localStorage.setItem(
        this.state.selectionRangeLocalVar,
        JSON.stringify(payloadValue),
      );
    }

    let startDate = payloadValue.startDate;
    let endDate = payloadValue.endDate;
    let clicked = this.state.clicked + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      showCalendar: showCalendar,
      fromDate: startDate,
      toDate: endDate,
      startDate: startDate,
      endDate: endDate,
      clicked: clicked,
      dateData: JSON.stringify(payloadValue),
    });

    if (clicked && clicked % 2 === 0) {
      this.props.handleChildSubmit({
        selectedInvoicesIdList: this.state.selectedInvoicesIdList,
        selectedLocationIdList: this.state.selectedLocationIdList,
        selectedEmployeeIdList: this.state.selectedEmployeeIdList,
        fromDate: startDate,
        toDate: endDate,
        canSubmit: true,
      });
    } else {
      this.props.handleChildSubmit({
        fromDate: startDate,
        toDate: endDate,
        canSubmit: false,
      });
    }
  };

  selectedInvoicesIdList = (event) => {
    const target = event.target;
    const name = event.target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let count = 0;
    let selectedInvoicesIdList = this.state.selectedInvoicesIdList;
    this.state.invoicesArray.map((obj) => {
      if (this.state[Object.keys(obj)]) {
        count += 1;
      }
      if (name == Object.keys(obj) && value) {
        if (selectedInvoicesIdList.indexOf(Object.keys(obj)[0]) == -1) {
          selectedInvoicesIdList.push(Object.keys(obj)[0]);
        }
        count = count + 1;
      } else if (name == Object.keys(obj) && !value) {
        count = count - 1;
        selectedInvoicesIdList.splice(
          selectedInvoicesIdList.indexOf(Object.keys(obj)[0]),
          1,
        );
      }
    });
    this.setState({
      [event.target.name]: value,
      isinvoicesArrayListApi: true,
      userChanged: true,
      selectedInvoices: count,
      selectedInvoicesIdList: selectedInvoicesIdList,
    });
  };

  selectedLocationIdList = (event) => {
    let checkboxName = event.target.name.split("-");
    let id = parseInt(checkboxName[1]);
    let checkboxValue = event.target.value;
    let existOrNot = this.state.selectedLocationIdList.indexOf(id);
    let selectedLocationIdList = this.state.selectedLocationIdList;
    if (checkboxValue == "true") {
      selectedLocationIdList.splice(existOrNot, 1);
    } else {
      selectedLocationIdList.push(id);
    }
    this.setState({
      selectedLocationIdList: selectedLocationIdList,
      islocationArrayListApi: true,
      userChanged: true,
    });
  };

  selectedEmployeeIdList = (event) => {
    let checkboxName = event.target.name.split("-");
    let id = parseInt(checkboxName[1]);
    let checkboxValue = event.target.value;
    let existOrNot = this.state.selectedEmployeeIdList.indexOf(id);
    let selectedEmployeeIdList = this.state.selectedEmployeeIdList;
    if (checkboxValue == "true") {
      selectedEmployeeIdList.splice(existOrNot, 1);
    } else {
      selectedEmployeeIdList.push(id);
    }
    this.setState({
      selectedEmployeeIdList: selectedEmployeeIdList,
      isemployeeArrayListApi: true,
      userChanged: true,
    });
  };

  toggleInvoicesFilter = () => {
    let eventSelectedInvoices = this.state.eventSelectedInvoices;
    let returnState = {};
    returnState.selectedInvoicesIdList = [];
    if (eventSelectedInvoices == "unselect") {
      eventSelectedInvoices = "select";
      this.state.invoicesArray.map((obj) => {
        returnState[Object.keys(obj)] = false;
        returnState.selectedInvoices = 0;
      });
    } else {
      if (eventSelectedInvoices == "select") {
        eventSelectedInvoices = "unselect";
        this.state.invoicesArray.map((obj) => {
          returnState[Object.keys(obj)] = true;
          returnState.selectedInvoices = 6;
          returnState.selectedInvoicesIdList.push(Object.keys(obj)[0]);
        });
      }
    }
    returnState.eventSelectedInvoices = eventSelectedInvoices;
    returnState.islocationArrayListApi = true;
    returnState.userChanged = true;
    this.setState(returnState);
  };

  toggleLocationFilter = () => {
    let selectedLocationIdList = this.state.selectedLocationIdList;
    let eventSelectedLocation = this.state.eventSelectedLocation;
    if (eventSelectedLocation == "unselect") {
      selectedLocationIdList = [];
      eventSelectedLocation = "select";
    } else {
      if (eventSelectedLocation == "select") {
        selectedLocationIdList = this.state.totalLocationIdList;
        eventSelectedLocation = "unselect";
      }
    }
    this.setState({
      selectedLocationIdList: selectedLocationIdList,
      eventSelectedLocation: eventSelectedLocation,
      islocationArrayListApi: true,
      userChanged: true,
    });
  };

  toggleEmployeeFilter = () => {
    let selectedEmployeeIdList = this.state.selectedEmployeeIdList;
    let eventSelectedEmployee = this.state.eventSelectedEmployee;
    if (eventSelectedEmployee == "unselect") {
      selectedEmployeeIdList = [];
      eventSelectedEmployee = "select";
    } else {
      if (eventSelectedEmployee == "select") {
        selectedEmployeeIdList = this.state.totalEmployeeIdList;
        eventSelectedEmployee = "unselect";
      }
    }
    this.setState({
      selectedEmployeeIdList: selectedEmployeeIdList,
      eventSelectedEmployee: eventSelectedEmployee,
      isemployeeArrayListApi: true,
      userChanged: true,
    });
  };

  showLoaderFunc = () => {
    localStorage.setItem("showLoader", false);
  };

  handleChurnedReason = (value) => {
    const dateData = this.state.dateData;
    const valR = dateData ? JSON.parse(dateData) : "";
    const startD = valR.startDate;
    const endD = valR.endDate;
    this.setState({
      churn_filter: value,
      churnActive: true,
    });
    this.props.handleChildSubmit({
      fromDate: startD,
      toDate: endD,
      churn_filter: value,
    });
  };

  handleExport = (value, reportType, newReq) => {
    const dateData = this.state.dateData;
    const valR = dateData ? JSON.parse(dateData) : "";
    const startD = valR.startDate;
    const endD = valR.endDate;
    let formData = {
      fromDate: apiDateFormat(startD),
      toDate: apiDateFormat(endD),
      export_type: value,
      export_name: this.props.reportType,
      churn_filter: this.state.churn_filter,
      exportType: value,
      reportType,
    };

    if (this.props.clinic_id && this.props.clinic_id.length > 0) {
      formData.clinic_id = this.props.clinic_id;
    } else {
      let clinicIds = this.state.locationArray.map((obj) => {
        return obj.id;
      });
      if (this.props.clinic_id) {
        formData.clinic_id = clinicIds;
      }
    }
    if (this.props.user_id && this.props.user_id.length > 0) {
      formData.user_id = this.props.user_id;
    } else {
      let empIds = this.state.employeeArray.map((obj) => {
        return obj.id;
      });
      if (this.props.user_id) {
        formData.user_id = empIds;
      }
    }
    if (this.props.invoice_status && this.props.invoice_status.length > 0) {
      formData.invoice_status = this.props.invoice_status;
    } else {
      let empIds = this.state.invoicesArray.map((obj) => {
        return Object.keys(obj)[0];
      });
      if (this.props.invoice_status) {
        formData.invoice_status = empIds;
      }
    }

    if (this.props.searchVal) {
      formData.search_key = this.props.searchVal;
    }

    this.showLoaderFunc();
    this.setState({
      showLoader: true,
    });

    this.props
      .exportSalesSummary(formData, reportType, newReq)
      .then((data) => {
        if (data?.data?.file) {
          window.location.href = composeDownloadDataUrl(data.data.file);
        } else {
          uiNotification.error("Unable to export. Try again later");
        }
      })
      .catch(() => {
        uiNotification.error("Unable to export. Try again later");
      })
      .finally(() => {
        this.setState({
          showLoader: false,
        });
      });
  };

  _onFocus() {
    this.setState({ focusSearch: true });
  }

  handleRangeChange = (which, payload) => {
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    startDate = format(startDate, "YYYY-MM-DD");
    endDate = format(endDate, "YYYY-MM-DD");

    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar: false,
      fromDate: startDate,
      toDate: endDate,
    });
  };

  handleSelect = (ranges) => {
    this.setState({
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate,
    });
  };

  get accountId() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData?.account?.id;
  }

  get isNewBiToggle() {
    return (
      featureToggleAccounts.includes(this.accountId) ||
      this.props.location.search.includes("newbi")
    );
  }

  render() {
    let selectionRange = {};
    if (this.state.dateData) {
      selectionRange = JSON.parse(this.state.dateData);
    } else {
      selectionRange = {
        startDate: this.state.startDate ? this.state.startDate : new Date(),
        endDate: this.state.endDate ? this.state.endDate : new Date(),
        key: "selection",
      };
    }
    let catStatusLabel = "";
    if (this.state.churn_filter == "both") {
      catStatusLabel = "All";
    } else if (this.state.churn_filter == "manual") {
      catStatusLabel = this.state.salesLang.sales_cancelled_by_client;
    } else if (this.state.churn_filter == "payment_failed") {
      catStatusLabel = "Cancelled by Payment Decline";
    } else {
      catStatusLabel = "All";
    }

    return (
      <div className="setting-search-outer">
        <form onSubmit={this.handleSubmit}>
          <div
            className={
              this.props.searchShow
                ? "search-bg new-search"
                : "search-bg new-search no-display"
            }
          >
            <i className="fas fa-search" />
            <input
              className="setting-search-input chart_search"
              name="search_key"
              placeholder="Search"
              value={this.state.search_key}
              onFocus={() => this.setState({ focusSearch: true })}
              autoComplete="off"
              onChange={this.handleInputChange}
              ref={(searchFocus) => {
                this.searchFocus = searchFocus;
              }}
            />
            <div
              className={
                this.state.focusSearch &&
                this.props.searchFocus &&
                this.state.search_key == ""
                  ? "giftcard-suggestion"
                  : "giftcard-suggestion no-display"
              }
              style={{}}
              ref={(node) => {
                this.node = node;
              }}
            >
              <ul
                ref={(node) => {
                  this.node = node;
                }}
              >
                <li className="suggestion-li">
                  <a
                    onClick={() => {
                      this.setState({ search_key: "to:", focusSearch: false });
                      this.searchFocus.focus();
                    }}
                    className="suggestion-a"
                  >
                    <span>{this.state.salesLang.sales_to}:</span>
                    {this.state.salesLang.sales_recipient_name}
                  </a>
                </li>
                <li className="suggestion-li">
                  <a
                    onClick={() => {
                      this.setState({
                        search_key: "from:",
                        focusSearch: false,
                      });
                      this.searchFocus.focus();
                    }}
                    className="suggestion-a"
                  >
                    <span>{this.state.salesLang.sales_from}:</span>
                    {this.state.salesLang.sales_buyer_name}
                  </a>
                </li>
                <li className="suggestion-li">
                  <a
                    onClick={() => {
                      this.setState({
                        search_key: "card number:",
                        focusSearch: false,
                      });
                      this.searchFocus.focus();
                    }}
                    className="suggestion-a"
                  >
                    <span>{this.state.salesLang.sales_card_number}:</span>
                    {this.state.salesLang.sales_redemption_code}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </form>
        <div
          className={
            this.props.dateRangePick
              ? "search-bg new-calender pull-left"
              : "no-display"
          }
          ref={(node) => {
            this.node = node;
          }}
        >
          <img alt="" src={calenLogo} />
          {this.state.showCalendar && (
            <DateRangePicker
              value={selectionRange}
              className={"CalendarPreviewArea"}
              ranges={[selectionRange]}
              onChange={this.dateRangeOnChange}
              maxDate={new Date()}
              dragSelectionEnabled={false}
            />
          )}
          <input
            onChange={this.handleInputChange}
            type="text"
            className="input-cal setting-search-input"
            name="calendar-input"
            value={
              moment(this.state.startDate).format(this.state.dateFormat) +
              `-` +
              moment(this.state.endDate).format(this.state.dateFormat)
            }
          />
        </div>
        <div
          onClick={() => this.setState({ refOnClickflag: true })}
          className={
            this.props.invoices ? "multi-sel-btn" : "multi-sel-btn no-display"
          }
          ref={(muli_sel_btn_ips) => {
            this.muli_sel_btn_ips = muli_sel_btn_ips;
          }}
        >
          {this.state.salesLang.sales_selected_invoices} (
          {this.state.selectedInvoices})
          <ul className={this.state.selctedInvoicesClass}>
            <li id="select_btn_li">
              <a
                className="line-btn text-center"
                name="ips"
                onClick={this.toggleInvoicesFilter}
              >
                {this.state.eventSelectedInvoices == "unselect"
                  ? this.state.salesLang.sales_unselect_all
                  : this.state.salesLang.sales_select_all}
              </a>
            </li>
            {this.state.invoicesArray.length > 0 &&
              this.state.invoicesArray.map((obj, idx) => {
                return (
                  <li key={"selected-invoices-" + idx}>
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          this.state[Object.keys(obj)] ? "checked" : false
                        }
                        value={Object.keys(obj)}
                        name={Object.keys(obj)}
                        onChange={this.selectedInvoicesIdList}
                      />{" "}
                      {Object.values(obj)}
                    </label>
                  </li>
                );
              })}
          </ul>
        </div>
        <div
          onClick={() => this.setState({ refOnClickflag: true })}
          className={
            this.props.location ? "multi-sel-btn" : "multi-sel-btn no-display"
          }
          ref={(muli_sel_btn_nps) => {
            this.muli_sel_btn_nps = muli_sel_btn_nps;
          }}
        >
          {this.state.salesLang.sales_selected_locations} (
          {this.state.selectedLocationIdList
            ? this.state.selectedLocationIdList.length
            : 0}
          )
          <ul className={this.state.selctedLocationClass}>
            <li id="select_btn_li">
              <a
                className="line-btn text-center"
                name="nps"
                onClick={this.toggleLocationFilter}
              >
                {this.state.eventSelectedLocation == "unselect"
                  ? this.state.salesLang.sales_unselect_all
                  : this.state.salesLang.sales_select_all}
              </a>
            </li>
            {this.state.locationArray.length > 0 &&
              this.state.locationArray.map((obj) => {
                return (
                  <li key={"selected-location-" + obj.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          this.state.selectedLocationIdList.indexOf(obj.id) > -1
                            ? "checked"
                            : false
                        }
                        value={
                          this.state.selectedLocationIdList.indexOf(obj.id) > -1
                            ? true
                            : false
                        }
                        name={"locations-" + obj.id}
                        onChange={this.selectedLocationIdList}
                      />{" "}
                      {obj.clinic_name}
                    </label>
                  </li>
                );
              })}
          </ul>
        </div>
        <div
          onClick={() => this.setState({ refOnClickflag: true })}
          className={
            this.props.employee ? "multi-sel-btn" : "multi-sel-btn no-display"
          }
          ref={(muli_sel_btn_eps) => {
            this.muli_sel_btn_eps = muli_sel_btn_eps;
          }}
        >
          {this.state.salesLang.sales_selected_employees} (
          {this.state.selectedEmployeeIdList
            ? this.state.selectedEmployeeIdList.length
            : 0}
          )
          <ul className={this.state.selctedEmployeeClass}>
            <li id="select_btn_li">
              <a
                className="line-btn text-center"
                name="eps"
                onClick={this.toggleEmployeeFilter}
              >
                {this.state.eventSelectedEmployee == "unselect"
                  ? this.state.salesLang.sales_unselect_all
                  : this.state.salesLang.sales_select_all}
              </a>
            </li>
            {this.state.employeeArray.length > 0 &&
              this.state.employeeArray.map((obj) => {
                return (
                  <li key={"selected-employee-" + obj.id}>
                    <label>
                      <input
                        type="checkbox"
                        checked={
                          this.state.selectedEmployeeIdList.indexOf(obj.id) > -1
                            ? "checked"
                            : false
                        }
                        value={
                          this.state.selectedEmployeeIdList.indexOf(obj.id) > -1
                            ? true
                            : false
                        }
                        name={"employees-" + obj.id}
                        onChange={this.selectedEmployeeIdList}
                      />{" "}
                      {obj.name}
                    </label>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="export pull-right m-l-5 churnFilterExport">
          <div className="dropdown pull-left">
            <button
              className="btn btn-default dropdown-toggle"
              type="button"
              id="dropdownMenu1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Invoice Export
              <i className="fas fa-angle-down"></i>
            </button>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
              <li>
                <a
                  name="expType"
                  onClick={this.handleExport.bind(
                    this,
                    this.state.expType,
                    "invoices",
                    null,
                  )}
                >
                  {this.state.salesLang.sales_export_csv}
                </a>
              </li>
              <li>
                <a
                  name="expoType"
                  onClick={this.handleExport.bind(
                    this,
                    this.state.expoType,
                    "invoices",
                    null,
                  )}
                >
                  {this.state.salesLang.sales_export_excel}
                </a>
              </li>
            </ul>
          </div>
        </div>
        {this.isNewBiToggle && (
          <div className="export pull-right m-l-5 churnFilterExport">
            <div className="dropdown pull-left">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Invoice Export New
                <i className="fas fa-angle-down"></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a
                    name="expType"
                    onClick={this.handleExport.bind(
                      this,
                      this.state.expType,
                      "invoices",
                      true,
                    )}
                  >
                    {this.state.salesLang.sales_export_csv}
                  </a>
                </li>
                <li>
                  <a
                    name="expoType"
                    onClick={this.handleExport.bind(
                      this,
                      this.state.expoType,
                      "invoices",
                      true,
                    )}
                  >
                    {this.state.salesLang.sales_export_excel}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        {this.isNewBiToggle && (
          <div className="export pull-right m-l-5 churnFilterExport">
            <div className="dropdown pull-left">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Invoice Breakdown Export
                <i className="fas fa-angle-down"></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a
                    name="expType"
                    onClick={this.handleExport.bind(
                      this,
                      this.state.expType,
                      "items",
                      true,
                    )}
                  >
                    {this.state.salesLang.sales_export_csv}
                  </a>
                </li>
                <li>
                  <a
                    name="expoType"
                    onClick={this.handleExport.bind(
                      this,
                      this.state.expoType,
                      "items",
                      true,
                    )}
                  >
                    {this.state.salesLang.sales_export_excel}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        {this.isNewBiToggle && (
          <div className="export pull-right m-l-5 churnFilterExport">
            <div className="dropdown pull-left">
              <button
                className="btn btn-default dropdown-toggle"
                type="button"
                id="dropdownMenu1"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Transaction Log
                <i className="fas fa-angle-down"></i>
              </button>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenu1">
                <li>
                  <a
                    name="expType"
                    onClick={this.handleExport.bind(
                      this,
                      this.state.expType,
                      null,
                      true,
                    )}
                  >
                    {this.state.salesLang.sales_export_csv}
                  </a>
                </li>
                <li>
                  <a
                    name="expoType"
                    onClick={this.handleExport.bind(
                      this,
                      this.state.expoType,
                      null,
                      true,
                    )}
                  >
                    {this.state.salesLang.sales_export_excel}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div
          className={
            this.props.ChurnedReason ? "export pull-right " : "no-display"
          }
        >
          <span className="search-text m-r-5">
            {this.state.salesLang.sales_churned_rsn}:
          </span>
          <div className="dropdown pull-left churnFilter">
            <button
              className="btn btn-default dropdown-toggle"
              type="button"
              id="dropdownMenu1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {catStatusLabel}
              <i className="fas fa-angle-down"></i>
            </button>
            <ul className="dropdown-menu " aria-labelledby="dropdownMenu1">
              <li>
                <a
                  name="bothVal"
                  onClick={this.handleChurnedReason.bind(
                    this,
                    this.state.bothVal,
                  )}
                >
                  {this.state.salesLang.sales_all_lbl}
                </a>
              </li>
              <li>
                <a
                  name="cancelClient"
                  onClick={this.handleChurnedReason.bind(
                    this,
                    this.state.cancelClient,
                  )}
                >
                  {this.state.salesLang.sales_cancelled_by_client}
                </a>
              </li>
              <li>
                <a
                  name="cancelPayment"
                  onClick={this.handleChurnedReason.bind(
                    this,
                    this.state.cancelPayment,
                  )}
                >
                  {this.state.salesLang.sales_cancelled_by_payment}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className={
            this.props.total_active_members_value == true
              ? "search-text pull-right m-r-5"
              : "no-display"
          }
        >
          <b>
            {this.state.salesLang.sales_total_active_members} :{" "}
            {this.props.total_active_members}
          </b>
        </div>
        <div
          className={
            this.props.total_liability_value == true
              ? "search-text pull-right m-r-5"
              : "no-display"
          }
        >
          <b>
            {this.state.salesLang.sales_total_liability} :{" "}
            {this.props.total_liability}
          </b>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock"
              : "new-loader text-left"
          }
          style={{ position: "fixed" }}
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              Please wait, we’re working hard to pull your report. <br /> Allow
              a few minutes before refreshing or closing out of this window
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      exportSalesSummary,
    },
    dispatch,
  );
}

export default connect(null, mapDispatchToProps)(withRouter(SalesFilter));
