import moment from "moment";
import { API_DATE_FORMAT, WORKSPACE_STATUS } from "../consts/api";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";

/**
 * @param {import("axios").AxiosError} e
 * @returns {unknown | null}
 */
export const extractApiError = (e) => {
  return e?.response?.data?.message || null;
};

/**
 * @param {import("axios").AxiosError} e
 * @returns {unknown | null}
 */
export const extractApiErrorData = (e) => {
  return e?.response?.data?.data || null;
};

/**
 * @template {Base} F
 * @param {import("axios").AxiosError} error
 * @param {F} fallback
 * @returns {unknown | null}
 */
export const handleApiErrorMessage = (error, fallback = null) => {
  let errorMessage = error?.response?.data?.message || null;

  const globalLanguageData =
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.languageData))?.global ||
    {};

  return (
    globalLanguageData[errorMessage] ||
    errorMessage ||
    fallback ||
    "Something went wrong, please try again."
  );
};

/**
 * @param {Record<string, unknown>} shape
 * @returns {string}
 */
export const createQueryKeyFromShape = (shape) => {
  return Object.entries(shape)
    .map((p) => p.join("-"))
    .join("#");
};

/**
 * @param {string} url
 * @returns {string}
 */
export const composeApiUrl = (url) => {
  const endpoint = process.env.REACT_APP_API_URL;
  if (typeof endpoint === "string") {
    const slashSeparator = endpoint.slice(-1)[0] === "/" ? "" : "/";
    const preparedUrl = url.slice(0, 1) === "/" ? url.slice(1) : url;
    return `${endpoint}${slashSeparator}${preparedUrl}`;
  }
  return url;
};

/**
 * @template {Base} T
 * @param {T} fileName
 * @returns {string | T}
 */
export const composeDownloadDataUrl = (fileName) => {
  const endpoint = process.env.REACT_APP_API_URL;
  if (typeof endpoint === "string") {
    const preparedEndpoint =
      endpoint.slice(-1)[0] === "/" ? endpoint.slice(0, -1) : endpoint;
    return `${preparedEndpoint}/download-data/${fileName}`;
  }
  return fileName;
};

/**
 * @template {Base} T
 * @param {T} fileName
 * @returns {string | T}
 */
export const composeDownloadExcelUrl = (fileName) => {
  const endpoint = process.env.REACT_APP_API_URL;
  if (typeof endpoint === "string") {
    const parsedUrl = new URL(endpoint);
    return `${parsedUrl.origin}/excel/${fileName}`;
  }
  return fileName;
};

/**
 * @param {string} status
 * @returns {boolean}
 */
export const isWorkspaceStatusInactive = (status) => {
  return (
    status === WORKSPACE_STATUS.inactive ||
    status === WORKSPACE_STATUS.cancelled ||
    status === WORKSPACE_STATUS.hold
  );
};

/**
 *
 * Takes `dateString` that is `src/consts/api.js@API_DATE_FORMAT` and returns a `Date` object.
 *
 * @param {string} dateString
 * @returns {Date}
 */
export const apiDateStringToDate = (dateString) => {
  if (typeof dateString !== "string") {
    throw new Error("Invalid date string.");
  }

  const splitDate = dateString.split("-");

  return new Date(splitDate[0], splitDate[1] - 1, splitDate[2]);
};

/**
 * @param {Date} date
 * @returns {string}
 */
export const dateToApiDateString = (date) => {
  if (!(date instanceof Date)) {
    throw new Error("Invalid date object.");
  }

  return moment(date).format(API_DATE_FORMAT);
};
