import React from "react";
import * as yup from "yup";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";

export function ViewChargeCash() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();
  const { chargeCash } = useInvoiceActions();

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const schema = yup.object({
    amount: composeAmountSchema(
      { amountToPay, currency: invoice?.currency },
      true,
    ),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeCash.initiate({ amount }).then(onClose);
      },
      initialValues: {
        amount: amountToPay || "",
      },
    });

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cash")}
      imageSrc={svg.cashPrimary}
      onCharge={submit}
      isCharging={chargeCash.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={!isValid}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
    </ModalCharge>
  );
}
