import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const inboxInstance = axios.create();

inboxInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

let source = axios.CancelToken.source();

inboxInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

inboxInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function listSMS(formData) {
  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "get-sms-notifications", formData)
      .then((response) => {
        dispatch({ type: "LIST_SMS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "LIST_SMS", payload: error.response.data });
      });
  };
}

export function searchPhoneNumber(formData) {
  return async (dispatch) => {
    return inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "search-phone-number",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SEARCH_PHONE_NUMBER", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SEARCH_PHONE_NUMBER", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function searchPatientByName(formData) {
  return (dispatch) => {
    if (typeof source != typeof undefined) {
      source.cancel("Operation canceled due to new request.");
    }

    source = axios.CancelToken.source();
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "search-client?letter_key=" +
          formData.client,
        { cancelToken: source.token },
      )
      .then((response) => {
        dispatch({ type: "SEARCH_PATIENTS", payload: response.data });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch({ type: "SEARCH_PATIENTS", payload: error.response.data });
        }
      });
  };
}

export const fetchSMSNotificationsPopups = (formData) => {
  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "get-patient", formData)
      .then((response) => {
        dispatch({ type: "SELECTED_SMS_POPUPS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SELECTED_SMS_POPUPS", payload: error.response.data });
      });
  };
};

export const createReply = (formData) => {
  return (dispatch) => {
    inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "send-sms-reply",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_REPLY_INBOX", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_REPLY_INBOX", payload: error.response.data });
      });
  };
};

export function getOptionAndList(type, formData) {
  formData = formData || {};

  return (dispatch) => {
    inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "sms-list/" + type,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "GET_SMS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SMS_LIST", payload: error.response.data });
      });
  };
}

export function getOptionsOnly(id, tId) {
  if (!tId) tId = 0;

  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "get-variable/" + id + "/" + tId)
      .then((response) => {
        dispatch({ type: "GET_OPTIONS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_OPTIONS_LIST", payload: error.response.data });
      });
  };
}

export function saveSmsFilterReport(formData) {
  return (dispatch) => {
    inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "save-sms-list-filter-report",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SAVE_SMS_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_SMS_LIST", payload: error.response.data });
      });
  };
}

export function getFilterReportData(id, formData) {
  return (dispatch) => {
    inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "get-sms-list-filter-report/" + id,
        formData,
      )
      .then((response) => {
        if (formData.is_download && formData.is_download == 1) {
          dispatch({ type: "DOWNLOAD_EXCEL_FILE", payload: response.data });
        } else {
          dispatch({ type: "GET_LIST_DATA", payload: response.data });
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_LIST_DATA", payload: error.response.data });
      });
  };
}

export function deleteList(formData) {
  return (dispatch) => {
    inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "delete-sms-list-filter-report",
        formData,
      )
      .then((response) => {
        dispatch({ type: "DELETE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_DATA", payload: error.response.data });
      });
  };
}

export function getSmsCampaignList(formData) {
  return (dispatch) => {
    inboxInstance
      .post(process.env.REACT_APP_API_URL + "get-sms-campaign-list", formData)
      .then((response) => {
        dispatch({ type: "GET_SMS_CAMPAIGN_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SMS_CAMPAIGN_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function createSmsCampaign(formData) {
  return (dispatch) => {
    inboxInstance
      .post(process.env.REACT_APP_API_URL + "save-sms-campaign", formData)
      .then((response) => {
        dispatch({ type: "CREATE_SMS_CAMPAIGN_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_SMS_CAMPAIGN_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getSmsCampaignById(id) {
  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "get-sms-campaign-by-id/" + id)
      .then((response) => {
        dispatch({ type: "EDIT_SMS_CAMPAIGN_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EDIT_SMS_CAMPAIGN_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getSMSTemplateData(formData) {
  return (dispatch) => {
    inboxInstance
      .post(process.env.REACT_APP_API_URL + "get-sms-template-list", formData)
      .then((response) => {
        dispatch({ type: "GET_SMS_TEMPLATE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SMS_TEMPLATE_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function addNewSMSTemplate(formData) {
  return (dispatch) => {
    inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "save-sms-template",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ADD_NEW_SMS_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_NEW_SMS_TEMPLATE",
          payload: error.response.data,
        });
      });
  };
}

export function deleteCampaign(id) {
  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "delete-sms-campaign/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_CAMPAIGN", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CAMPAIGN", payload: error.response.data });
      });
  };
}

export function getDataViaId(id) {
  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "get-sms-template-by-id/" + id)
      .then((response) => {
        dispatch({ type: "GET_SMS_TEMPLATE_BY_ID", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SMS_TEMPLATE_BY_ID",
          payload: error.response.data,
        });
      });
  };
}

export function deleteSMSID(id) {
  return (dispatch) => {
    inboxInstance
      .get(process.env.REACT_APP_API_URL + "delete-sms-template/" + id)
      .then((response) => {
        dispatch({ type: "DELETE_SMS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_SMS", payload: error.response.data });
      });
  };
}

export function confirmNumber(formData) {
  return async (dispatch) => {
    return inboxInstance
      .post(
        process.env.REACT_APP_API_URL + "save-twillio-number",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SAVE_TWILIO_NUMBER", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_TWILIO_NUMBER", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function smsReadUnread(formData) {
  return (dispatch) => {
    inboxInstance
      .put(
        process.env.REACT_APP_API_URL + "sms-read-unread",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SMS_READ_UNREAD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SMS_READ_UNREAD", payload: error.response.data });
      });
  };
}
