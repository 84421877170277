import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { getAllResources, exportEmptyData, createApplication, saveClearentData, getSavedClearentData, updateSignature, submitApp, downloadAgreement, deleteContact} from '../../../Actions/Settings/clearance.js';
import {changePosStatus} from '../../../Actions/Settings/settingsActions.js';
import { toast } from "react-toastify";
import { toggleBodyScroll, getIsPosEnabled, } from '../../../Utils/services.js';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { FormStep1, FormStep2, FormStep3, FormStep4, FormStep5, FormStep6, FormStep7 } from './Clearance'

import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader.js'
import PosTitle from './PosTitle.js';

const isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const apiDateFormat = date => {
  return moment(date).format("MM/DD/YYYY");
};
const maxAge = new Date();
maxAge.setFullYear(maxAge.getFullYear() - 13);

const initStep5 = () => {
  return {
    "ownershipAmount": "100",
    "contactOwnerShip": "0",
    "title": "",
    "emailAddress": "",
    "firstName": "",
    "lastName": "",
    "ssn": "",
    "dateOfBirth": maxAge,
    "phoneTypeCodeID": 1,
    "countryOfCitizenshipCode": 840,
    "line1": "",
    "line2": "",
    "line3": "",
    "city": "",
    "stateCode": "KY",
    "zip": "",
    "countryCode": "840",
    isCompassUser: true,
    isAuthorizedToPurchase: true,
    isAdmin: "1"
  }
}

class PosClearanceSetup extends Component {
  constructor(props) {
    super(props);
    this.state = this.initialState;
  }

  get initialState() {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const resourceData = JSON.parse(localStorage.getItem('resourceData'));
    const applicationData = JSON.parse(localStorage.getItem('applicationData'));
    return {
      appSubmitSuccess: false,
      //applicationData: (this.props.match.params.detailType) ? applicationData : ,
      formStep: (this.props.match.params.detailType) ? 0 : 1,
      clearanceAllResources:resourceData ? resourceData : {},
      formValue: {
        step_1: {
          "firstName": "",
          "lastName": "",
          "businessOwner": null
        },
        step_2: {
          "dbaName": "",
          "line1": "",
          "city": "",
          "stateCode": "",
          "zip": "",
          "countrycode": 840,
          "emailAddress": "",
          "companyTypeId": "",
          "webSite": "",
          "phoneTypeCodeID": 1,
          "phones": [
              {
                  "phoneTypeCodeID": 5,
                  "areaCode": "555",
                  "phoneNumber": "",
                  "extension": ""
              },
              {
                  "phoneTypeCodeID": 2,
                  "areaCode": "555",
                  "phoneNumber": "",
                  "extension": ""
              }
          ],
          "is_mailing_address_sameas_business": 'true',
          "is_seasonal_business" : "false",
          "mailing_address": {
              "line1": "",
              "city": "",
              "stateCode": "",
              "zip": "",
              "countrycode": 840
          },
          "acceptsPaperStatements": "true",
          "acceptsPaperTaxForms": "true",
          "legalFirstName": "",
          "legalLastName": "",
          "businessLegalName": "",
          "tinTypeID": "1",
          "tin": "",
          "stateIncorporatedCode": "",
          "previouslyAcceptedPaymentCards": "true",
          "previousProcessorId": "",
          "previouslyTerminatedOrIdentifiedByRiskMonitoring": "false",
          "reasonPreviouslyTerminatedOrIdentifiedByRisk": "",
          "currentlyOpenForBusiness": "false",
          "seasonalSchedule": {
              "january": true,
              "february": true,
              "march": true,
              "april": true,
              "may": true,
              "june": true,
              "july": true,
              "august": true,
              "september": true,
              "october": true,
              "november": true,
              "december": true
          }
        },
        step_3: {
          "mccCode": "7298",
          "cardPresentPercentage": 25,
          "isEcommerce": "false",
          "isFutureDelivery": "false",
          "futureDeliveryTypeID": "1",
          "futureDeliveryPercentage": 50,
          "otherDeliveryType": "",
          "returnRefundPolicy": "",
          "productsSold": "",
          "sellsFirearms": "false",
          "sellsFirearmAccessories": "false",
          "fireArmsLicense": "",
          "annualVolume": "",
          "averageTicket": "",
          "highTicket": "",
        },
        step_4: {
          "bankName": "",
          "bankAccountTypeID": "1",
          "bankAccountNameTypeID": "1",
          "isNameSameAsLegalOrDBAName": "true",
          "nameOnAccount": "",
          "aba": "",
          "accountNumber": "",
          "hasFees": "true",
          "hasFunds": "true",
          "hasChargebacks": "true",
          "voidedCheckDocumentID": ""
        },
        step_5: [initStep5()],
        step_6: {
          streetAddress: "",
          shippingStreet2: "",
          shippingCity: "",
          shippingState: "",
          shippingZipCode: "",
          timezone: "",
          defaultTimezone: ""
        },
        step_7: {
          is_signed_electronic_and_record_disclosure: "",
          is_signed_merchant_acceptance_of_application: "",
          is_signed_personal_guaranty: "",
          is_signed_w_9: "",
          is_signed_bank_disclosure: ""
        }
      },

      stripeAccount: (userData && userData.account && userData.account.pos_gateway === 'stripe' && userData.account.account_type === 'paid' && userData.account.stripe_mode === 'custom' && userData.user_type === 'superadmin') ? true : false,
      pos_enabled: getIsPosEnabled(),
      alertModalContent: '',
      isShowAlertModal: false,
      userData: userData,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      showLoader: true,
      backAction: '/settings/pos',
      posSetupData: {},
      posAdditionalSetupData: {},
      verificationData: [],
      userChanged: false,
      userChangedAdditional: false,
      type: '',
      country: '',
      typeClass: 'newSelectField',
      countryClass: 'newSelectField',
      typeList: [],
      countryList: [],
      currencyList: [],
      show_countries_status: true,
      defaultCountry: 'US',
      defaultCurrency: 'USD',
      countryCurrencyLabel: '',
      clinicId: this.props.match.params.clinicId,


    }
  }

  componentDidMount() {
    if(isEmpty(this.state.clearanceAllResources))
      this.props.getAllResources();

    if(this.props.match.params.detailType){
      this.props.getSavedClearentData(this.props.match.params.detailType);
    } else {
      localStorage.removeItem('applicationData');
      this.setState({showLoader:false});
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.clearanceAllResources !== undefined && nextProps.clearanceAllResources !== prevState.clearanceAllResources) {
      localStorage.setItem('resourceData', JSON.stringify(nextProps.clearanceAllResources))
      returnState.clearanceAllResources = nextProps.clearanceAllResources
      //returnState.showLoader = false;
    }
    if (nextProps.changePosMode != undefined && nextProps.changePosMode == true) {
      returnState.showLoader = false;
      returnState.pos_enabled = !prevState.pos_enabled;
      nextProps.exportEmptyData();
      nextProps.history.push(`/settings/pos`)
    }
    if (nextProps.applicationData !== undefined && nextProps.applicationData !== prevState.applicationData && nextProps.applicationDataTime != prevState.applicationDataTime) {
      localStorage.setItem('applicationData', JSON.stringify(nextProps.applicationData))
      returnState.applicationData = nextProps.applicationData
      returnState.applicationDataTime = nextProps.applicationDataTime;
      let lastStep = parseInt(prevState.formStep);
      //returnState.showLoader = false;
      let formData = { clearent_setup: { clinic_id: prevState.clinicId}}
      formData.merchantnumber = nextProps.applicationData.merchantNumber;
      formData.exchangeid = nextProps.applicationData.metadata.exchangeId;

      //formData.primary_information.contactTypeID = data.contactTypeID;
      let data = prevState.formValue['step_2'];
      formData.primary_information = {...prevState.formValue['step_1'], companyTypeId: data.companyTypeId};
        formData.business_information = {
          "dbaName": data.dbaName,
          "line1": data.line1,
          "city": data.city,
          "stateCode": data.stateCode,
          "zip": data.zip,
          "countrycode": data.countrycode,
          "emailAddress": data.emailAddress,
          "webSite": data.webSite,
          "phones": data.phones,
          "is_mailing_address_sameas_business": (data.is_mailing_address_sameas_business == "true") ? true : false,
          "mailing_address": data.mailing_address,
          "acceptsPaperStatements": (data.acceptsPaperStatements == "true") ? true : false,
          "acceptsPaperTaxForms": (data.acceptsPaperTaxForms == "true") ? true : false
        }
        formData.legal_information = {
          "legalFirstName":(data.companyTypeId == 1) ? data.legalFirstName : "",
          "legalLastName": (data.companyTypeId == 1)  ? data.legalLastName : "",
          "tinTypeID": data.tinTypeID,
          "tin": data.tin,
          "businessLegalName": (data.companyTypeId == 1) ? data.legalFirstName + " " + data.legalLastName : data.legalFirstName,
          "stateIncorporatedCode": data.stateIncorporatedCode,
          "previouslyAcceptedPaymentCards": (data.previouslyAcceptedPaymentCards == "true" || data.previouslyAcceptedPaymentCards == true) ? true : false,
          "previousProcessorId": data.previousProcessorId,
          "previouslyTerminatedOrIdentifiedByRiskMonitoring": (data.previouslyTerminatedOrIdentifiedByRiskMonitoring == "true") ? true : false,
          "reasonPreviouslyTerminatedOrIdentifiedByRisk": data.reasonPreviouslyTerminatedOrIdentifiedByRisk,
          "currentlyOpenForBusiness": (data.currentlyOpenForBusiness == "true") ? true : false,
          "seasonalSchedule": {
              "january": true,
              "february": true,
              "march": true,
              "april": true,
              "may": true,
              "june": true,
              "july": true,
              "august": true,
              "september": true,
              "october": true,
              "november": true,
              "december": true
          }
        }

      nextProps.saveClearentData(formData);
    }
    if (nextProps.clearentSavedData !== undefined && nextProps.clearentSavedData !== prevState.clearentSavedData && nextProps.clearentSavedDataTime != prevState.clearentSavedDataTime) {
      returnState.clearentSavedData = nextProps.clearentSavedData;
      returnState.clearentSavedDataTime = nextProps.clearentSavedDataTime;
      returnState.clearentData = nextProps.clearentSavedData;
      let savedData = nextProps.clearentSavedData;
      let applicationData = {merchantNumber: savedData.merchantNumber, metadata: savedData.metadata}
      localStorage.setItem('applicationData', JSON.stringify(applicationData))
      returnState.applicationData = applicationData;
      let formValue = prevState.formValue;
      let step1 = formValue['step_1'];
      let step2 = formValue['step_2'];
      let step3 = formValue['step_3'];
      let step4 = formValue['step_4'];
      let step5 = formValue['step_5'];
      let step6 = formValue['step_6'];
      let primary_information = savedData.primary_information;
      let business_information = savedData.business_information;
      let legal_information = savedData.legal_information;
      let type_of_sales = savedData.type_of_sales;
      let bank = savedData.bank;
      let contact = savedData.contacts;
      let surveyAnswers = (savedData.equipment_surveys) ? savedData.equipment_surveys.answers : [];
      let location_info = savedData.location_info;
      let documents = savedData.documents;
      let clinic_timezone = savedData.clinic_timezone;
      step6.defaultTimezone = clinic_timezone;
      let newStep1 = {
        firstName : (primary_information) ? primary_information.firstName : step1.firstName,
        lastName : (primary_information) ? primary_information.lastName : step1.lastName,
        businessOwner: null
      }



      let newStep2 = {
          companyTypeId: (primary_information) ? primary_information.companyTypeId : step1.companyTypeId,
          "dbaName": (business_information) ? business_information.dbaName : step2.dbaName,
          "line1": (business_information) ? business_information.line1 : step2.line1,
          "city": (business_information) ? business_information.city : step2.city,
          "stateCode": (business_information) ? business_information.stateCode : step2.stateCode,
          "zip": (business_information) ? business_information.zip : step2.zip,
          "countrycode": 840,
          "emailAddress": (business_information) ? business_information.emailAddress : step2.emailAddress,
          "webSite": (business_information) ? business_information.webSite : step2.webSite,
          "phones": (business_information) ? business_information.phones : step2.phones,
          "is_mailing_address_sameas_business": (business_information && business_information.is_mailing_address_sameas_business  != undefined) ? business_information.is_mailing_address_sameas_business.toString() : step2.is_mailing_address_sameas_business,
          "is_seasonal_business" :  step2.is_seasonal_business,
          "mailing_address": (business_information) ? business_information.mailing_address : step2.mailing_address,
          "acceptsPaperStatements": (business_information && business_information.acceptsPaperStatements  != undefined) ? business_information.acceptsPaperStatements.toString() : step2.acceptsPaperStatements,
          "acceptsPaperTaxForms": (business_information && business_information.acceptsPaperTaxForms  != undefined) ? business_information.acceptsPaperTaxForms.toString() : step2.acceptsPaperTaxForms,
          "legalFirstName": (legal_information) ? ((primary_information.companyTypeId == 1) ? legal_information.legalFirstName :  legal_information.businessLegalName ): step2.legalFirstName,
          "legalLastName": (legal_information) ? legal_information.legalLastName : step2.legalLastName,
          "businessLegalName": (legal_information) ? legal_information.businessLegalName : step2.businessLegalName,
          "tinTypeID": (legal_information && legal_information.tinTypeID) ? legal_information.tinTypeID.toString() : step2.tinTypeID,
          "tin": (legal_information) ? legal_information.tin : step2.tin,
          "stateIncorporatedCode": (legal_information) ? legal_information.stateIncorporatedCode : step2.stateIncorporatedCode,
          "previouslyAcceptedPaymentCards": (legal_information && legal_information.previouslyAcceptedPaymentCards  != undefined) ? legal_information.previouslyAcceptedPaymentCards.toString() : step2.previouslyAcceptedPaymentCards,
          "previousProcessorId": (legal_information) ? legal_information.previousProcessorId : step2.previousProcessorId,
          "previouslyTerminatedOrIdentifiedByRiskMonitoring": (legal_information && legal_information.previouslyTerminatedOrIdentifiedByRiskMonitoring != undefined) ? legal_information.previouslyTerminatedOrIdentifiedByRiskMonitoring.toString() : step2.previouslyTerminatedOrIdentifiedByRiskMonitoring,
          "reasonPreviouslyTerminatedOrIdentifiedByRisk": (legal_information) ? legal_information.reasonPreviouslyTerminatedOrIdentifiedByRisk : step2.reasonPreviouslyTerminatedOrIdentifiedByRisk,
          "currentlyOpenForBusiness": (legal_information && legal_information.currentlyOpenForBusiness  != undefined) ? legal_information.currentlyOpenForBusiness.toString() : step2.currentlyOpenForBusiness,
          "seasonalSchedule": (legal_information) ? legal_information.seasonalSchedule : step2.seasonalSchedule,

      }
      if(business_information && business_information.phones && business_information.phones.length) {
        business_information.phones.map((obj, idx) => {
          newStep2.phoneTypeCodeID = (obj.phoneTypeCodeID == 5 || obj.phoneTypeCodeID == 1) ? obj.phoneTypeCodeID : 1 ;
          if(obj.phoneTypeCodeID == 5 || obj.phoneTypeCodeID == 1) {
            newStep2.business_phone = String(obj.areaCode) + String(obj.phoneNumber);
          } else if(obj.phoneTypeCodeID == 2) {
            newStep2.business_fax = obj.phoneNumber;
          }
        })
      }
      let newStep3 = {
          "mccCode": (type_of_sales && type_of_sales != null) ? type_of_sales.mccCode : step3.mccCode,
          "cardPresentPercentage": (type_of_sales && type_of_sales != null) ? type_of_sales.cardPresentPercentage : step3.cardPresentPercentage,
          "isEcommerce": (type_of_sales && type_of_sales != null && type_of_sales.isEcommerce) ? type_of_sales.isEcommerce.toString() : step3.isEcommerce,
          "isFutureDelivery": (type_of_sales && type_of_sales != null && type_of_sales.isFutureDelivery) ? type_of_sales.isFutureDelivery.toString() : step3.isFutureDelivery,
          "futureDeliveryTypeID": (type_of_sales && type_of_sales != null && type_of_sales.futureDeliveryTypeID) ? type_of_sales.futureDeliveryTypeID.toString() : step3.futureDeliveryTypeID,
          "futureDeliveryPercentage": (type_of_sales && type_of_sales != null) ? type_of_sales.futureDeliveryPercentage : step3.futureDeliveryPercentage,
          "otherDeliveryType": (type_of_sales && type_of_sales != null) ? type_of_sales.otherDeliveryType : step3.otherDeliveryType,
          "returnRefundPolicy": (type_of_sales && type_of_sales != null) ? type_of_sales.returnRefundPolicy : step3.returnRefundPolicy,
          "productsSold": (type_of_sales && type_of_sales != null) ? type_of_sales.productsSold : step3.productsSold,
          "sellsFirearms": false,
          "sellsFirearmAccessories": false,
          "fireArmsLicense": '',
          "annualVolume": (type_of_sales && type_of_sales != null) ? type_of_sales.annualVolume : step3.annualVolume,
          "averageTicket": (type_of_sales && type_of_sales != null) ? type_of_sales.averageTicket : step3.averageTicket,
          "highTicket": (type_of_sales && type_of_sales != null) ? type_of_sales.highTicket : step3.highTicket,
        }


      let newStep4 = {
          "bankName": (bank) ? bank.bankName : step4.bankName,
          "bankAccountTypeID": (bank && bank.bankAccountTypeID) ? bank.bankAccountTypeID.toString() : step4.bankAccountTypeID,
          "bankAccountNameTypeID": (bank && bank.bankAccountTypeID) ? bank.bankAccountNameTypeID.toString() : step4.bankAccountNameTypeID,
          "isNameSameAsLegalOrDBAName": true,//(bank) ? bank.isNameSameAsLegalOrDBAName.toString() : step4.isNameSameAsLegalOrDBAName,
          "nameOnAccount": (bank) ? bank.nameOnAccount : step4.nameOnAccount,
          "aba": (bank) ? bank.aba : step4.aba,
          "accountNumber": (bank) ? bank.accountNumber : step4.accountNumber,
          "confirmAccountNumber": (bank) ? bank.accountNumber : step4.accountNumber,
          "hasFees": (bank && bank.hasFees) ? bank.hasFees.toString() : step4.hasFees,
          "hasFunds": (bank && bank.hasFunds) ? bank.hasFunds.toString() : step4.hasFunds,
          "hasChargebacks": (bank && bank.hasChargebacks) ? bank.hasChargebacks.toString() : step4.hasChargebacks,
          "voidedCheckDocumentID": (bank) ? bank.voidedCheckDocumentID : step4.voidedCheckDocumentID,
          "siteTypeID" : 2,
          "document_url": "",
          "fileName": ""
      }

      if(documents && documents.length > 0) {
        documents.map((obj, idx) => {
          if(obj.type === 'bank_voided_check') {
            newStep4.document_url = obj.document_url;
            newStep4.fileName = obj.fileName;
          }
        })
      }
      let newStep5 = []
      if(contact.length) {
        contact.map((obj, idx) => {
          let contact = {
            "id" : obj.id,
            "businessContactId" : obj.businessContactId,
            "ownershipAmount": obj.ownershipAmount,
            "title": obj.title,
            "emailAddress": obj.emailAddress,
            "firstName": obj.contact.firstName,
            "lastName": obj.contact.lastName,
            "ssn": obj.contact.ssn,
            "dateOfBirth": new Date(obj.contact.dateOfBirth),
            "countryOfCitizenshipCode": obj.contact.countryOfCitizenshipCode,
            "line1": obj.contact.address.line1,
            "city": obj.contact.address.city,
            "stateCode": obj.contact.address.stateCode,
            "zip": obj.contact.address.zip,
            "countryCode": obj.contact.address.countrycode,
            isCompassUser: obj.isAdmin == 1 ? true : false,
            isAuthorizedToPurchase: obj.isAdmin == 1 ? true : false,
            isAdmin: obj.isAdmin.toString(),
            clearent_business_information_id: obj.clearent_business_information_id,
            is_represent_merchant_address: true,
            is_represent_primary_contact: true,
          }
          if(obj.contactTypes.length > 0) {
            obj.contactTypes.map((type, typeId) => {
              contact["contactType-"+type.contactTypeID] = type.contactTypeID
            })
          }
          if(obj.phoneNumbers.length) {
            obj.phoneNumbers.map((phone, phoneId) => {
              contact.phoneTypeCodeID = (phone.phoneTypeCodeID == 5 || phone.phoneTypeCodeID == 1) ? phone.phoneTypeCodeID : 1 ;
              if(phone.phoneTypeCodeID == 5 || phone.phoneTypeCodeID == 1) {
                contact.contact_phone = String(phone.areaCode) + String(phone.phoneNumber);
              } else if(phone.phoneTypeCodeID == 2) {
                contact.contact_fax = phone.phoneNumber;
              }
            })
          }
          newStep5.push(contact);
        })
      } else {
        newStep5 = step5;
      }

      let newStep6 = prevState.formValue.step_6;
      if(surveyAnswers.length) {
        surveyAnswers.map((obj, idx) => {
          if(obj.id == 2837 || obj.id == 5536) {
            newStep6.streetAddress = obj.answer;
          }
          if(obj.id == 2838 || obj.id == 5537) {
            newStep6.shippingStreet2 = obj.answer;
          }
          if(obj.id == 2839 || obj.id == 5538) {
            newStep6.shippingCity = obj.answer;
          }
          if(obj.id == 2840 || obj.id == 5539) {
            newStep6.shippingState = obj.answer;
          }
          if(obj.id == 2841 || obj.id == 5540) {
            newStep6.shippingZipCode = obj.answer;
          }if(obj.id == 2827 || obj.id == 5544) {
            newStep6.timezone = (obj.answer) ? obj.answer : clinic_timezone;
          }
        })
      }

      returnState.formValue = {
        'step_1' : newStep1,
        'step_2' : newStep2,
        'step_3' : newStep3,
        'step_4' : newStep4,
        'step_5' : newStep5,
        'step_6' : newStep6,
      }
      returnState.showLoader = false;
      returnState.formStep = (savedData.clearent_setup.step_completed > 0) ? parseInt(savedData.clearent_setup.step_completed)+1 : 1;

    }

    if (nextProps.signatureData !== undefined && nextProps.signatureData !== prevState.signatureData && nextProps.signatureDataTime != prevState.signatureDataTime) {
      returnState.signatureData = nextProps.signatureData;
      returnState.signatureDataTime = nextProps.signatureDataTime;
      let formData = {}
      formData.merchantnumber = prevState.applicationData.merchantNumber;
      formData.exchangeid = prevState.applicationData.metadata.exchangeId;
      nextProps.submitApp(formData);
    }
    if (nextProps.appData !== undefined && nextProps.appData !== prevState.appData && nextProps.appDataTime != prevState.appDataTime) {
      returnState.appData = nextProps.appData;
      returnState.appDataTime = nextProps.appDataTime;
      returnState.appSubmitSuccess = true;
      returnState.showLoader = false;
      toast.success('Onboarding process completed successfully!');
      localStorage.removeItem('applicationData');
      if(prevState.userData && prevState.userData.account && prevState.userData.account.pos_gateway === 'stripe') {
        // Temporary commented due to SUPPORT-679
        // nextProps.history.push(`/settings/pos-onboarding`);
        nextProps.history.push(`/settings/pos`);
      } else {
        nextProps.history.push(`/settings/pos`);
      }
    }

    if (nextProps.clearentData !== undefined && nextProps.clearentData !== prevState.clearentData && nextProps.clearentDataTime != prevState.clearentDataTime) {
      returnState.clearentData = nextProps.clearentData;
      returnState.clearentDataTime = nextProps.clearentDataTime;
      let savedData = nextProps.clearentData;
      let contact = savedData.contacts;
      let formValue = prevState.formValue;
      let step1 = formValue['step_1'];
      let step2 = formValue['step_2'];
      let step3 = formValue['step_3'];
      let step4 = formValue['step_4'];
      let step5 = formValue['step_5'];
      let step6 = formValue['step_6'];
      let documents = savedData.documents;

      let newStep5 = [];
      if(contact.length) {
        contact.map((obj, idx) => {
          let contact = {
            "id" : obj.id,
            "businessContactId" : obj.businessContactId,
            "ownershipAmount": obj.ownershipAmount,
            "title": obj.title,
            "emailAddress": obj.emailAddress,
            "firstName": obj.contact.firstName,
            "lastName": obj.contact.lastName,
            "ssn": obj.contact.ssn,
            "dateOfBirth": new Date(obj.contact.dateOfBirth),
            "countryOfCitizenshipCode": obj.contact.countryOfCitizenshipCode,
            "line1": obj.contact.address.line1,
            "city": obj.contact.address.city,
            "stateCode": obj.contact.address.stateCode,
            "zip": obj.contact.address.zip,
            "countryCode": obj.contact.address.countrycode,
            isCompassUser: obj.isAdmin == 1 ? true : false,
            isAuthorizedToPurchase: obj.isAdmin == 1 ? true : false,
            isAdmin: obj.isAdmin.toString(),
            clearent_business_information_id: obj.clearent_business_information_id,
            is_represent_merchant_address: true,
            is_represent_primary_contact: true,
          }
          if(obj.contactTypes.length > 0) {
            obj.contactTypes.map((type, typeId) => {
              contact["contactType-"+type.contactTypeID] = type.contactTypeID
            })
          }
          if(obj.phoneNumbers.length) {
            obj.phoneNumbers.map((phone, phoneId) => {
              contact.phoneTypeCodeID = (phone.phoneTypeCodeID == 5 || phone.phoneTypeCodeID == 1) ? phone.phoneTypeCodeID : 1 ;
              if(phone.phoneTypeCodeID == 5 || phone.phoneTypeCodeID == 1) {
                contact.contact_phone = String(phone.areaCode) + String(phone.phoneNumber);
              } else if(phone.phoneTypeCodeID == 2) {
                contact.contact_fax = phone.phoneNumber;
              }
            })
          }
          newStep5.push(contact);
        })
      } else {
        newStep5 = [initStep5()];
      }
      if(documents && documents.length > 0) {
        documents.map((obj, idx) => {
          if(obj.type === 'bank_voided_check') {
            step4.document_url = obj.document_url;
            step4.fileName = obj.fileName;
          }
        })
      }
      returnState.formValue = prevState.formValue;
      returnState.formValue.step_5 = newStep5;
      returnState.formStep = (prevState.formStep + 1);
      returnState.showLoader = false;
    }
    if (nextProps.agreementData !== undefined && nextProps.agreementData !== prevState.agreementData && nextProps.agreementDataTime != prevState.agreementDataTime) {
      returnState.agreementData = nextProps.agreementData;
      returnState.agreementDataTime = nextProps.agreementDataTime;
      returnState.showLoader = false;
      nextProps.exportEmptyData();
      window.open(nextProps.agreementData);
    }
    if (nextProps.contactDeletedTime !== undefined && nextProps.contactDeletedTime !== prevState.contactDeletedTime && nextProps.contactDeletedTime != prevState.contactDeletedTime) {
      returnState.contactDeletedTime = nextProps.contactDeletedTime;
      returnState.showLoader = false;
    }

    if(nextProps.errorTime != undefined && nextProps.errorTime != prevState.errorTime) {
      returnState.showLoader = false;
      returnState.errorTime = nextProps.errorTime;
    }
    return returnState
  }


  componentDidUpdate() {

  }


  handleInputChange = (event) => {
    this.setState({ userChanged: true })
    const target = event.target;
    let value = target.value;
    let inputName = target.name;

    switch (event.target.type) {
      case 'checkbox':
        value = event.target.checked;
        break;
    }

    this.setState({ [inputName]: value });
  }



  handleSubmit = (event) => {

  }

  onChangeDatePicker = (fieldIndex, date) => {
    if (fieldIndex >= 0) {
      let verificationData = this.state.verificationData;
      verificationData[fieldIndex]['value'] = date;
      this.setState({ verificationData: verificationData })
    }
  }

  handlePosDisable = () => {
    toggleBodyScroll(false)
    this.setState({ isShowAlertModal: false })
  }

  handleAlertModal = () => {
    toggleBodyScroll(!this.state.isShowAlertModal)
    this.setState({ isShowAlertModal: !this.state.isShowAlertModal })
  }

  prepareFormData = (step) => {
    const applicationData = JSON.parse(localStorage.getItem('applicationData'));
    let data = this.state.formValue['step_'+step];
    let formData = { clearent_setup: { clinic_id: this.state.clinicId}}
    formData.exchangeid = applicationData.metadata.exchangeId;
    formData.merchantnumber = applicationData.merchantNumber;

    switch(parseInt(step)){
      case 2 : {
        formData.primary_information = this.state.formValue['step_'+(step-1)];
        formData.primary_information = {...this.state.formValue['step_1'], companyTypeId: data.companyTypeId};
        formData.business_information = {
          "dbaName": data.dbaName,
          "line1": data.line1,
          "city": data.city,
          "stateCode": data.stateCode,
          "zip": data.zip,
          "countrycode": data.countrycode,
          "emailAddress": data.emailAddress,
          "webSite": data.webSite,
          "phones": data.phones,
          "is_mailing_address_sameas_business": (data.is_mailing_address_sameas_business == "true") ? true : false,
          "mailing_address": data.mailing_address,
          "acceptsPaperStatements": (data.acceptsPaperStatements == "true") ? true : false,
          "acceptsPaperTaxForms": (data.acceptsPaperTaxForms == "true") ? true : false
        }
        formData.legal_information = {
          "businessLegalName": (data.companyTypeId == 1) ? data.legalFirstName +" "+data.legalLastName : data.legalFirstName,
          "legalFirstName":(data.companyTypeId == 1) ? data.legalFirstName : "",
          "legalLastName": (data.companyTypeId == 1)  ? data.legalLastName : "",
          "tinTypeID": data.tinTypeID,
          "tin": data.tin,
          "stateIncorporatedCode": data.stateIncorporatedCode,
          "previouslyAcceptedPaymentCards": (data.previouslyAcceptedPaymentCards == "true") ? true : false,
          "previousProcessorId": data.previousProcessorId,
          "previouslyTerminatedOrIdentifiedByRiskMonitoring": (data.previouslyTerminatedOrIdentifiedByRiskMonitoring == "true") ? true : false,
          "reasonPreviouslyTerminatedOrIdentifiedByRisk": data.reasonPreviouslyTerminatedOrIdentifiedByRisk,
          "currentlyOpenForBusiness": (data.currentlyOpenForBusiness == "true") ? true : false,
          "is_seasonal_business": false,
          "seasonalSchedule": {
              "january": true,
              "february": true,
              "march": true,
              "april": true,
              "may": true,
              "june": true,
              "july": true,
              "august": true,
              "september": true,
              "october": true,
              "november": true,
              "december": true
          }
        }
        return formData;
        break;
      }

      case 3 : {
        formData.primary_information = this.state.clearentData.primary_information;
        formData.business_information = this.state.clearentData.business_information;
        formData.legal_information = this.state.clearentData.legal_information;
        formData.type_of_sales = {
            "mccCode": data.mccCode,
            "cardPresentPercentage": data.cardPresentPercentage,
            "isEcommerce": false,
            "isFutureDelivery": false,
            "futureDeliveryTypeID": data.futureDeliveryTypeID,
            "futureDeliveryPercentage": data.futureDeliveryPercentage,
            "otherDeliveryType": data.otherDeliveryType,
            "returnRefundPolicy": data.returnRefundPolicy,
            "productsSold": data.productsSold,
            "sellsFirearms": false,
            "sellsFirearmAccessories": false,
            "fireArmsLicense": "",
            "annualVolume": data.annualVolume,
            "averageTicket": data.averageTicket,
            "highTicket": data.highTicket,
        }
        if(data.documents) {
          formData.documents = data.documents;
        }
        return formData;
        break;
      }

      case 4: {
        formData.primary_information = this.state.clearentData.primary_information;
        formData.business_information = this.state.clearentData.business_information;
        formData.legal_information = this.state.clearentData.legal_information;
        formData.type_of_sales = this.state.clearentData.type_of_sales;
        if(this.state.clearentData.documents) {
          formData.documents = this.state.clearentData.documents;
        }
        if(data.documents) {
          formData.documents = data.documents;
        }
        formData.location_info = {
          "siteTypeID": 2,
          "otherSiteTypeDescription": data.otherSiteTypeDescription
        }
        formData.bank = {
          "bankName": data.bankName,
          "bankAccountTypeID": data.bankAccountTypeID,
          "bankAccountNameTypeID": 1,//data.bankAccountNameTypeID,
          "isNameSameAsLegalOrDBAName": true,
          "nameOnAccount": data.nameOnAccount,
          "aba": data.aba,
          "accountNumber": data.accountNumber,
          "hasFees": (data.hasFees == "true") ? true : true,
          "hasFunds": (data.hasFunds == "true") ? true : true,
          "hasChargebacks": (data.hasChargebacks == "true") ? true : true,
          "voidedCheckDocumentID": data.voidedCheckDocumentID,
        }
        return formData;
        break;
      }

      case 5: {
        formData.primary_information = this.state.clearentData.primary_information;
        formData.business_information = this.state.clearentData.business_information;
        formData.legal_information = this.state.clearentData.legal_information;
        formData.type_of_sales = this.state.clearentData.type_of_sales;
        if(this.state.clearentData.documents) {
          formData.documents = this.state.clearentData.documents;
        }
        formData.location_info = this.state.clearentData.location_info;
        formData.bank = this.state.clearentData.bank;
        formData.contacts = [];
        if(data.length) {
          data.map((obj, idx) => {
            let contact = {
              ownershipAmount : obj.ownershipAmount,
              "title": obj.title,
              "emailAddress": obj.emailAddress,
              "contact": {
                  "firstName": obj.firstName,
                  "lastName": obj.lastName,
                  "ssn": obj.ssn,
                  "dateOfBirth": apiDateFormat(obj.dateOfBirth),
                  "countryOfCitizenshipCode": 840,
                  "address": {
                      "line1": obj.line1,
                      "line2": "",
                      "line3": "",
                      "city": obj.city,
                      "stateCode": obj.stateCode,
                      "zip": obj.zip,
                      "countryCode": "840"
                  }
              },
              "contactTypes": obj.contactTypes,
              "phoneNumbers": obj.phoneNumbers,
              isAdmin: obj.isAdmin,
              isCompassUser: obj.isAdmin == 1 ? true : false,
              isAuthorizedToPurchase: obj.isAdmin == 1 ? true : false
            }
            if(obj.id) {
              contact.id = obj.id;
              contact.businessContactId = obj.businessContactId;
            }
            formData.contacts.push(contact);
          })
        }
        return formData;
        break;
      }

      case 6: {
        formData.primary_information = this.state.clearentData.primary_information;
        formData.business_information = this.state.clearentData.business_information;
        formData.legal_information = this.state.clearentData.legal_information;
        formData.type_of_sales = this.state.clearentData.type_of_sales;
        formData.location_info = this.state.clearentData.location_info;
        formData.bank = this.state.clearentData.bank;
        formData.contacts = this.state.clearentData.contacts;
        if(this.state.clearentData.documents) {
          formData.documents = this.state.clearentData.documents;
        }
        if(this.state.clearentData.equipment_surveys && this.state.clearentData.equipment_surveys.data && this.state.clearentData.equipment_surveys.data.length) {
          let tmpSurvey = JSON.parse(JSON.stringify(this.state.clearentData.equipment_surveys.data));
          let answers = [];
          tmpSurvey.map((obj, idx) => {
            if(obj.id == 2837 || obj.id == 5536) {
              answers.push({id: obj.id, answer: data.streetAddress});
              obj.answers[0].answer = data.streetAddress;
            }
            if(obj.id == 2838 || obj.id == 5537) {
              answers.push({id: obj.id, answer: data.shippingStreet2});
              obj.answers[0].answer = data.shippingStreet2;
            }
            if(obj.id == 2839 || obj.id == 5538) {
              answers.push({id: obj.id, answer: data.shippingCity});
              obj.answers[0].answer = data.shippingCity;
            }
            if(obj.id == 2840 || obj.id == 5539) {
              answers.push({id: obj.id, answer: data.shippingState});
              obj.answers[0].answer = data.shippingState;
            }
            if(obj.id == 2841 || obj.id == 5540) {
              answers.push({id: obj.id, answer: data.shippingZipCode});
              obj.answers[0].answer = data.shippingZipCode;
            }
            if(obj.id == 2827 || obj.id == 5544) {
              answers.push({id: obj.id, answer: data.timezone});
              obj.answers[0].answer = data.timezone;
            }
            obj.answers[0].selected = true;
            tmpSurvey[idx] = obj;
          })
          formData.equipment_surveys = {};
          formData.equipment_surveys = {data: tmpSurvey, type: "request", answers: answers};

        }
        return formData;
        break;
      }

    return formData
  }
}

  handleFormStepChange = async(newFormStep, childState) => {
    let returnState = {}
    let stepCheck = (newFormStep > this.state.formStep) ? true : false;
    if(!stepCheck || this.state.formStep == 1) {
      returnState.formStep = newFormStep;
    }
    let formValue = this.state.formValue
    formValue = {
      ...formValue,
      ['step_' + this.state.formStep]: (childState) ? childState : this.state.formValue['step_' + this.state.formStep]
    }
    returnState.formValue = formValue;
    this.setState(returnState, () => {
      if(stepCheck && parseInt(newFormStep)-1 > 1) {
        if(localStorage.getItem('applicationData')) {
          let step = parseInt(newFormStep)-1;
          let formData = this.prepareFormData(step)
          this.setState({showLoader: true});
          this.props.saveClearentData(formData);
        } else {
          this.setState({showLoader: true});
          this.props.createApplication();
        }
      }
    })
  }

  handlePOSSubmit = () => {
    let formData = this.state.formValue;
  }

  download = (content) => {
    this.props.downloadAgreement(content);
  }

  deleteLocalContact = (data) => {
    this.setState({showLoader: true})
    this.props.deleteContact(data);
  }
  updateSignature = (content) => {
    let formData = {};
    this.setState({showLoader: true})
    const applicationData = JSON.parse(localStorage.getItem('applicationData'));
    formData.exchangeid = applicationData.metadata.exchangeId;
    formData.merchantnumber = applicationData.merchantNumber;
    formData.content = content
    this.props.updateSignature(formData);
  }

  toggleLoader = (loader) => {
    this.setState({showLoader: loader})
  }


  render() {
    return (
      <div className="main protected pos-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />


              {/* POS Title  START*/}
              <React.Fragment>
                <div className="title-sub-navigation">
                  {/* Alert Modal - START */}
                  <div className={this.state.isShowAlertModal ? "modalOverlay" : 'hide'}>
                    <div className="small-popup-outer">
                      <div className="small-popup-header">
                        <div className="popup-name">{this.state.globalLang.label_alert}</div>
                        <a className="small-cross" onClick={this.handleAlertModal} >×</a>
                      </div>
                      <div className="small-popup-content">
                        <div className="juvly-container no-padding-bottom">
                          <div className="row">
                            <div className="col-sm-12 col-xs-12">
                              <div className="setting-field-outer">
                                {(this.state.alertModalType != 'warning') &&
                                  <div className="new-field-label font-size-12 alert-modal-title">{this.state.globalLang.are_you_sure}</div>
                                }
                                <div className="new-field-label font-size-12 alert-modal-content">{this.state.alertModalContent}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="footer-static">
                        <a className="new-white-btn pull-right" onClick={this.handleAlertModal}>{this.state.globalLang.label_cancel}</a>
                        <a className="new-red-btn pull-left" onClick={this.handlePosDisable} >{this.state.settingsLang.yes_disable_it}</a>
                      </div>
                    </div>
                  </div>
                  {/* Alert Modal - END */}

                </div>
              </React.Fragment>
              {/* POS Title  START*/}

              {/* Page Container - START */}
              <div className="row business-row no-display">
                <div className="col-sm-6 new-stats-outer">
                  <div className="setting-setion m-b-10 newPOStripe">
                    <div className="pos-stripe-outer">
                      <input type="radio" onChange={this.handleInputChange} className="pos-stripe-input pos-stripe-option stripe-option" id="single-stripe" name="stripeConnectionMode" value="global" checked={this.state.stripeConnectionMode == 'global' ? 'checked' : false} />
                      <label className="pos-stripe-discrip" htmlFor="single-stripe">
                        <span className="pos-stripe-title">{(this.state.stripeMode === 'custom') ? this.state.settingsLang.pos_single_pos_account : this.state.settingsLang.pos_single_stripe_account}</span>
                        {this.state.settingsLang.pos_single_stripe_account_label}</label>
                    </div>
                  </div>
                </div>

                <div className="col-sm-6 new-stats-outer">
                  <div className="setting-setion m-b-10 newPOStripe">
                    <div className="pos-stripe-outer">
                      <input type="radio" onChange={this.handleInputChange} className="pos-stripe-input pos-stripe-option stripe-option" id="stripe-account" name="stripeConnectionMode" value="clinic" checked={this.state.stripeConnectionMode == 'clinic' ? 'checked' : false} />
                      <label className="pos-stripe-discrip" htmlFor="stripe-account">
                        <span className="pos-stripe-title">{(this.state.stripeMode === 'custom') ? this.state.settingsLang.pos_pos_account_per_clinic : this.state.settingsLang.pos_stripe_account_per_clinic}</span>
                        {this.state.settingsLang.pos_stripe_account_per_clinic_label}</label>
                    </div>
                  </div>
                </div>

              </div>
              <div className="business-setion memberWalletOuter">
              <PosTitle history={this.props.history} settingsLang={this.state.settingsLang} globalLang={this.state.globalLang} activeMenu={'pos'} pos_enabled={true} showPosBtn={(this.state.userData && this.state.userData.account && this.state.userData.account.pos_gateway === 'stripe') ? false : true} openFrom="onboarding" />

              {(this.state.appSubmitSuccess == false) &&
              <React.Fragment>
                  <div className={`form-step form-step-1 form-step-${(this.state.formStep === 1) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 1 && <FormStep1
                      formValue={this.state.formValue.step_1}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                    />}
                  </div>
                  {/* Step 1 - END */}

                  {/* Step 2 - START */}
                  <div className={`form-step form-step-2 form-step-${(this.state.formStep === 2) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 2 && <FormStep2
                      formValue={this.state.formValue.step_2}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                    />}
                  </div>
                  {/* Step 2 - END */}


                  {/* Step 3 - START */}
                  <div className={`form-step form-step-3 form-step-${(this.state.formStep === 3) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 3 && <FormStep3
                      formValue={this.state.formValue.step_3}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                    />
                    }
                  </div>
                  {/* Step 3 - END */}


                  {/* Step 4 - START */}

                  <div className={`form-step form-step-4 form-step-${(this.state.formStep === 4) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 4 && <FormStep4
                      formValue={this.state.formValue.step_4}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      toggleLoader = {this.toggleLoader}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                    />
                    }
                  </div>
                  {/* Step 4 - END */}

                  {/* Step 5 - START */}
                  <div className={`form-step form-step-5 form-step-${(this.state.formStep === 5) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 5 && <FormStep5
                      formValue={this.state.formValue.step_5}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      initStep5={initStep5()}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                      deleteContact={(data) => this.deleteLocalContact(data)}
                    />}
                  </div>
                  {/* Step 5 - END */}

                  {/* Step 6 - START */}
                  <div className={`form-step form-step-6 form-step-${(this.state.formStep === 6) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 6 && <FormStep6
                      formValue={this.state.formValue.step_6}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                    />}
                  </div>
                  {/* Step 6 - END */}
                  {/* Step 7 - START */}
                  <div className={`form-step form-step-7 form-step-${(this.state.formStep === 7) ? 'show' : 'hide'}`} >
                    {this.state.formStep === 7 && this.state.clearentData &&  this.state.clearentData.terms &&  this.state.clearentData.terms.content.length > 0 &&  <FormStep7
                      formValue={this.state.formValue.step_7}
                      handleFormStepChange={this.handleFormStepChange}
                      resources={this.state.clearanceAllResources}
                      content={this.state.clearentData.terms.content}
                      updateSignature={this.updateSignature}
                      merchantNumber={(this.state.applicationData) ? this.state.applicationData.merchantNumber : ""}
                      downloadAgreement={this.download}
                      isDocumentAdded={(this.state.clearentData && this.state.clearentData.documents && this.state.clearentData.documents.length) ? true : false}
                    />
                    }
                  </div>
                  </React.Fragment>
                }
              {(this.state.appSubmitSuccess == true) &&
                <div className={`setting-setion`} >
                  <div className="setting-container p20">
                    <div className="merchant-title">App Submitted Successfully!</div>
                        {this.state.applicationData && <p className="p-text m-b-20">App with merchant id - {this.state.applicationData.merchantNumber} is submitted Successfully.</p>}
                    </div>
                </div>
              }
              {/* Step 6 - END */}

              {/* Page Container - END */}

              <Loader showLoader={this.state.showLoader} isFullWidth={true} />
            </div>

          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};

  if (state.ClearanceReducer.action === "CLEARANCE_ALL_RESOURCES") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message]);
      returnState.errorTime = new Date();
    } else {
      returnState.clearanceAllResources = state.ClearanceReducer.data.data;
    }
  }
  if (state.ClearanceReducer.action === "CLEARANCE_CREATE_APPLICATION") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message]);
      returnState.errorTime = new Date();
    } else {
      returnState.applicationData = state.ClearanceReducer.data.data;
      returnState.applicationDataTime = new Date();
    }
  }
  if (state.ClearanceReducer.action === "CLEARANCE_SAVE_DATA") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message] ? languageData.global[state.ClearanceReducer.data.message] : state.ClearanceReducer.data.message);
      returnState.errorTime = new Date();
    } else {
      returnState.clearentData = state.ClearanceReducer.data.data;
      returnState.clearentDataTime = new Date();
    }
  }

  if (state.ClearanceReducer.action === "CLEARENT_SAVED_DATA") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message] ? languageData.global[state.ClearanceReducer.data.message] : state.ClearanceReducer.data.message);
      returnState.errorTime = new Date();
    } else {
      returnState.clearentSavedData = state.ClearanceReducer.data.data;
      returnState.clearentSavedDataTime = new Date();
    }
  }
  if (state.ClearanceReducer.action === "CLEARENT_UPDATE_SIGNATURE") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message] ? languageData.global[state.ClearanceReducer.data.message] : state.ClearanceReducer.data.message);
      returnState.errorTime = new Date();
    } else {
      returnState.signatureData = state.ClearanceReducer.data.data;
      returnState.signatureDataTime = new Date();
    }
  }
  if (state.ClearanceReducer.action === "SAVE_APP_DATA") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message] ? languageData.global[state.ClearanceReducer.data.message] : state.ClearanceReducer.data.message);
      returnState.errorTime = new Date();
    } else {
      returnState.appData = state.ClearanceReducer.data.data;
      returnState.appDataTime = new Date();
    }
  }
  if (state.ClearanceReducer.action === "DOWNLOAD_AGREEMENT") {
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message] ? languageData.global[state.ClearanceReducer.data.message] : state.ClearanceReducer.data.message);
      returnState.errorTime = new Date();
    } else {
      returnState.agreementData = state.ClearanceReducer.data.data;
      returnState.agreementDataTime = new Date();
    }
  }

  if (state.SettingReducer.action === "CHANGE_POS_STATUS") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.changePosMode = true;
    }
  }
  if (state.ClearanceReducer.action === "DELETE_CLEARENT_CONTACT") {
    toast.dismiss();
    if (state.ClearanceReducer.data.status != 200) {
      toast.error(languageData.global[state.ClearanceReducer.data.message] ? languageData.global[state.ClearanceReducer.data.message] : state.ClearanceReducer.data.message);
      returnState.errorTime = new Date();
    } else {
      toast.success(languageData.global[state.ClearanceReducer.data.message]);
      returnState.contactDeletedTime = new Date();
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getAllResources: getAllResources,
    changePosStatus: changePosStatus,
    exportEmptyData: exportEmptyData,
    createApplication: createApplication,
    saveClearentData: saveClearentData,
    getSavedClearentData: getSavedClearentData,
    updateSignature: updateSignature,
    submitApp: submitApp,
    downloadAgreement: downloadAgreement,
    deleteContact: deleteContact,
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PosClearanceSetup));
