import React from "react";
import * as yup from "yup";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { AvailableBalance } from "../../shared/AvailableBalance/AvailableBalance";

export function ViewChargeArWallet() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();
  const { chargeWallet } = useInvoiceActions();

  const amountMax =
    invoice?.patient?.walletBalance >= amountToPay
      ? amountToPay
      : invoice?.patient?.walletBalance;

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: amountMax,
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeWallet.initiate({ amount }).then(onClose);
      },
      initialValues: {
        amount: amountMax || "",
      },
    });

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.arWallet")}
      imageSrc={svg.arWalletPrimary}
      onCharge={submit}
      isCharging={chargeWallet.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={!isValid}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <AvailableBalance
          amount={formatCurrency(
            invoice?.patient?.walletBalance,
            invoice?.currency,
          )}
        />
      </ModalCharge.InputWrap>
    </ModalCharge>
  );
}
