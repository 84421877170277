/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import { WidgetLoader } from "../components/WidgetLoader.js";
import { fetchOfficeSalesGoals } from "../../../Actions/Dashboard/dashboardActions.js";

class WidgetOfficeSalesGoals extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      dash_in_office_sales_goals_text:
        languageData.dashboard["dash_in_office_sales_goals_text"],
      langData: languageData.dashboard,
      officeSalesGoalsData: [],
      officeSalesGoalsList: [],
      showLoader6: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.handleClick, false);
  }

  componentDidMount() {
    let formData = {
      params: {
        from_date: "2019-11-1",
        to_date: "2019-11-11",
      },
    };
    this.setState({
      showLoader6: true,
    });

    this.props.fetchOfficeSalesGoals(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.officeSalesGoalsData != undefined &&
      nextProps.officeSalesGoalsData.data !=
        prevState.officeSalesGoalsData.data &&
      nextProps.officeSalesGoalsData.status == 200
    ) {
      returnState.officeSalesGoalsData = nextProps.officeSalesGoalsData.data;
      returnState.officeSalesGoalsList =
        nextProps.officeSalesGoalsData.data.clinic_goals_array;
      returnState.showLoader6 = false;
    }
    return returnState;
  }

  render() {
    return (
      <div className="setting-setion m-b-15">
        <div className="dashboardSectionTitle">{"In Office Sales Goals"}</div>
        <div
          className={
            this.state.officeSalesGoalsList == undefined ||
            this.state.officeSalesGoalsList == "" ||
            this.state.officeSalesGoalsList == null
              ? "no-sales-goal newDashSalesGoal"
              : "no-display"
          }
        >
          <h6>{this.state.langData.dash_goals_not_defined_for_this_month}</h6>
          <a href="/settings/sales-goals/add" className="blue-btn add-goal">
            {this.state.langData.dash_add_goals}
          </a>
        </div>
        <div className="officeSaleNewDashboard">
          <Scrollbars
            style={{ height: 335 }}
            className={
              this.state.officeSalesGoalsList == undefined ||
              this.state.officeSalesGoalsList == "" ||
              this.state.officeSalesGoalsList == null
                ? "no-display"
                : "custome-scroll"
            }
          >
            <div
              className="dash-box-content office-goal"
              id="office-sales-goals-ccjuvly"
            >
              <div className="sales-goal-juvlycc mCustomScrollbar _mCS_1">
                <div
                  id="mCSB_1"
                  className="mCustomScrollBox mCS-dark mCSB_vertical mCSB_inside"
                  style={{ maxHeight: "none" }}
                >
                  <div
                    id="mCSB_1_container"
                    className="mCSB_container"
                    style={{ position: "relative", top: "0px", left: "0px" }}
                    dir="ltr"
                  >
                    {this.state.officeSalesGoalsList !== undefined &&
                      this.state.officeSalesGoalsList.map((obj, idx) => {
                        return (
                          <div className="custom-progress-outer" key={idx}>
                            <span className="progress-label-clinic">
                              {obj.clinic_name_juvcc}
                            </span>
                            <span className="pull-right total-prog-bar">
                              <b>{this.state.langData.dash_sales_goal}:</b>{" "}
                              {obj.clinic_goal_formatted}
                            </span>
                            <div className="custom-progress">
                              <div
                                href="#"
                                className="custom-progress-growth"
                                style={{
                                  width:
                                    obj.to_be_achieve_percentage > 100
                                      ? "100%"
                                      : obj.to_be_achieve_percentage + "%",
                                }}
                              ></div>
                              <div
                                href="#"
                                className="gray-progress-bar"
                                style={{
                                  width: obj.remaining_percentage + "%",
                                }}
                              ></div>
                            </div>
                            <div className="sale-pending">
                              <div className="pull-left">
                                <span>
                                  <b>Sales:</b>
                                </span>{" "}
                                {obj.net_sale_formatted}/
                                {obj.to_be_achieve_percentage}%
                              </div>
                              <div
                                className={
                                  obj.to_be_achieve_percentage < 100
                                    ? "pull-right"
                                    : "no-display"
                                }
                              >
                                <span>
                                  <b>{this.state.dash_pending_text}:</b>
                                </span>{" "}
                                {obj.remaining_goal_formatted}/
                                {obj.remaining_percentage}%
                              </div>
                            </div>
                            <div
                              className={
                                obj.to_be_achieve_percentage < 100
                                  ? "sales-need"
                                  : "no-display"
                              }
                            >
                              {
                                this.state.langData
                                  .dash_sales_needed_per_business_day
                              }
                              : {obj.remaining_per_day_goal}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                  <div
                    id="mCSB_1_scrollbar_vertical"
                    className="mCSB_scrollTools mCSB_1_scrollbar mCS-dark mCSB_scrollTools_vertical"
                    style={{ display: "block" }}
                  >
                    <a
                      href="#"
                      className="mCSB_buttonUp"
                      style={{ display: "block" }}
                    />
                    <div className="mCSB_draggerContainer">
                      <div
                        id="mCSB_1_dragger_vertical"
                        className="mCSB_dragger"
                        style={{
                          position: "absolute",
                          minHeight: "30px",
                          display: "block",
                          height: "224px",
                          maxHeight: "544px",
                          top: "0px",
                        }}
                      >
                        <div
                          className="mCSB_dragger_bar"
                          style={{ lineHeight: "30px" }}
                        />
                      </div>
                      <div className="mCSB_draggerRail" />
                    </div>
                    <a
                      href="#"
                      className="mCSB_buttonDown"
                      style={{ display: "block" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Scrollbars>
        </div>
        {this.state.showLoader6 && <WidgetLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  const returnState = {};

  if (state.DashboardReducer.action === "OFFICE_SALES_GOALS") {
    if (state.DashboardReducer.data.status !== 200) {
      toast.error(languageData.global[state.DashboardReducer.data.message]);
    } else {
      returnState.officeSalesGoalsData = state.DashboardReducer.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOfficeSalesGoals: fetchOfficeSalesGoals,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetOfficeSalesGoals);
