import axios from "axios";
import {
  setToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const signUpInstance = axios.create();

positionFooterCorrectly();

signUpInstance.interceptors.response.use(
  function (response) {
    if (response.headers.access_token) {
      setToken(response.headers.access_token);
    }

    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (error.response.data.status === 602) {
      localStorage.setItem("blockIP", 1);
      localStorage.removeItem("showRecaptcha");
      window.location.href = "/block-ip";
    }

    return Promise.reject(error);
  },
);

signUpInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function signUp() {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
  };
}

export function getSignUpAccountStatus(formData) {
  return (dispatch) => {
    signUpInstance
      .post(
        process.env.REACT_APP_API_URL + "signup-account-status",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "GET_SIGNUP_ACCOUNT_STATUS", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "GET_SIGNUP_ACCOUNT_STATUS",
            payload: error.response.data,
          });
        }
      });
  };
}

export function checkUniqueEmail(emailData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .post(process.env.REACT_APP_API_URL + "check-email-id-new", emailData)
      .then((response) => {
        dispatch({ type: "UNIQUE_EMAIL", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "UNIQUE_EMAIL", payload: error.response.data });
        }
      });
  };
}

export function getSignUpPlans() {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .get(process.env.REACT_APP_API_URL + "signup-plans")
      .then((response) => {
        dispatch({ type: "SIGN_UP_PLANS", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_PLANS", payload: error.response.data });
        }
      });
  };
}

export function signUpBasic(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .post(process.env.REACT_APP_API_URL + "signup-basic", signUpData)
      .then((response) => {
        dispatch({ type: "SIGN_UP_BASIC", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_BASIC", payload: error.response.data });
        }
      });
  };
}

export function signUpPremium(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .post(process.env.REACT_APP_API_URL + "signup-premium", signUpData)
      .then((response) => {
        dispatch({ type: "SIGN_UP_PREMIUM", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_PREMIUM", payload: error.response.data });
        }
      });
  };
}

export function signUpNationalMedicalDirectors(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .post(
        process.env.REACT_APP_API_URL + "signup/national-medical-directors",
        signUpData,
      )
      .then((response) => {
        dispatch({ type: "SIGN_UP_DIRECTORS", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_DIRECTORS", payload: error.response.data });
        }
      });
  };
}

export function verifyAccount(verificationCode) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .get(process.env.REACT_APP_API_URL + "verify-account/" + verificationCode)
      .then((response) => {
        dispatch({ type: "SIGN_UP_VERIFY_ACCOUNT", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({
            type: "SIGN_UP_VERIFY_ACCOUNT",
            payload: error.response.data,
          });
        }
      });
  };
}

export const checkEmail = (formData) => {
  return (dispatch) => {
    signUpInstance
      .post(
        process.env.REACT_APP_API_URL + "check-email-exists",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CHECK_EMAIL_ON_SIGNUP", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHECK_EMAIL_ON_SIGNUP",
          payload: error.response.data,
        });
      });
  };
};

export function signUpTouchMD(signUpData) {
  return (dispatch) => {
    dispatch({ type: "SIGN_UP" });
    signUpInstance
      .post(
        process.env.REACT_APP_API_URL + "signup-external-services",
        signUpData,
      )
      .then((response) => {
        dispatch({ type: "SIGN_UP_TOUCHMD", payload: response.data });
      })
      .catch((error) => {
        if (error.response) {
          dispatch({ type: "SIGN_UP_TOUCHMD", payload: error.response.data });
        }
      });
  };
}

export const searchPhoneNumberOauth = (formData) => {
  return (dispatch) => {
    signUpInstance
      .post(
        process.env.REACT_APP_API_URL + "search-phone-number-oauth",
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_APP_BACKEND_TOKEN_TWILIO}`,
          },
        },
      )
      .then((response) => {
        dispatch({ type: "SEARCH_PHONE_NUMBER_OAUTH", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SEARCH_PHONE_NUMBER_OAUTH",
          payload: error.response.data,
        });
      });
  };
};
