import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import { List } from "./List/List.js";

const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
};

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    window.scrollTo(0, 0);

    this.state = {
      userData: "",
      term: "",
      isTouchMd:
        userData && userData.account
          ? userData.account.account_preference.is_touch_md
          : 0,
      plan_selected: userData.account.account_subscription.plan_code,
      question_Create_Questionnaire:
        languageData.settings["question_Create_Questionnaire"],
      title_name_patient_work: languageData.settings["title_name_patient_work"],
      question_Number_Of_Questions:
        languageData.settings["question_Number_Of_Questions"],
      question_Questionnaires: languageData.settings["question_Questionnaires"],
      clinics_Search: languageData.settings["clinics_Search"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  render() {
    const userPermissions = JSON.parse(
      localStorage.getItem("userData"),
    ).permissions;
    let sideBarPermissions = [...userPermissions];

    return (
      <div>
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  {this.state.title_name_patient_work}
                  <div className="memberRightActions">
                    <form className="searchForm m-l-0">
                      <span className="searchOuter">
                        <i className="fa fa-search search-icon" />
                        <input
                          className="setting-search-input search-key"
                          data-url="/settings/questionnaires"
                          name="term"
                          placeholder={this.state.clinics_Search}
                          autoComplete="off"
                          value={this.state.term}
                          onChange={this.handleInputChange}
                        />
                      </span>
                    </form>
                    {checkPermission(
                      sideBarPermissions,
                      "manage-questionnaires",
                    ) && (
                      <Link
                        to="/settings/questionnaires/create"
                        className="new-blue-btn edit_setting"
                      >
                        {this.state.question_Create_Questionnaire}
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <div className="newTabsOuter">
                  {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking"
                    ? ""
                    : checkPermission(
                        sideBarPermissions,
                        "manage-questionnaires",
                      ) && (
                        <Link
                          to="/settings/questionnaires"
                          className="newTabs "
                        >
                          Cosmetic Questionnaires
                        </Link>
                      )}
                  {checkPermission(
                    sideBarPermissions,
                    "manage-questionnaires",
                  ) && (
                    <Link
                      to="/settings/procedure-templates"
                      className="newTabs disabled-tab"
                    >
                      Health Timeline Questionnaires
                    </Link>
                  )}
                  {checkPermission(sideBarPermissions, "manage-consents") && (
                    <Link
                      to="/settings/consents"
                      className="newTabs disabled-tab"
                    >
                      Consents
                    </Link>
                  )}
                  {this.state.isTouchMd == 1 &&
                  this.state.plan_selected == "practice_booking" ? (
                    ""
                  ) : (
                    <Link
                      to="/settings/membership-agreements"
                      className="newTabs disabled-tab"
                    >
                      Membership Agreements
                    </Link>
                  )}
                </div>
              </div>
              <div className="juvly-section full-width m-b-0">
                <div className="table-responsive">
                  <table className="table-updated setting-table no-td-border no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-6 table-updated-th sorting">
                          {this.state.question_Questionnaires}
                        </th>
                        <th className="col-xs-3 table-updated-th sorting text-left">
                          {this.state.question_Number_Of_Questions}
                        </th>
                        <th className="col-xs-3 table-updated-th sorting text-left">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <List searchTerm={this.state.term} />
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Questionnaire;
