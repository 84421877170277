class FileUtil {
  getExtensionFromName = (filename) => {
    if (filename.length === 0) return "";
    let dot = filename.lastIndexOf(".");
    if (dot === -1) return "";
    const extension = filename.substr(dot, filename.length);
    return extension;
  };

  shortenName = (filename, { start, end } = {}) => {
    if (typeof filename === "string") {
      const START = start || 10;
      const END = end || 7;
      if (filename.length <= START + END) {
        return filename;
      }
      return `${filename.slice(0, START)}...${filename.slice(
        filename.length - END,
        filename.length,
      )}`;
    } else {
      return filename;
    }
  };

  read = async (file, { method = "arrayBuffer" } = {}) => {
    const methodsMap = {
      text: "readAsText",
      arrayBuffer: "readAsArrayBuffer",
      dataUrl: "readAsDataURL",
    };
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader[methodsMap[method]](file);
    });
  };

  download = (url, filename) => {
    Object.assign(document.createElement("a"), {
      href: url,
      download: filename,
    }).click();
  };
}

export const fileUtil = new FileUtil();
