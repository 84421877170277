import { useEffect, useState } from "react";
import { useExportProcedureData } from "./useExportProcedureData";
import { useShowFile } from "./useShowFile";
import { useDownloadPrescriptionPdf } from "./useDownloadPrescriptionPdf";
import { useAgreementData } from "./useAgreementData";
import { useExpiredAgreementData } from "./useExpiredAgreementData";
import {
  DOCUMENT_SUB_TYPES,
  DOCUMENT_TYPES,
} from "../ExpirationActionButtons.consts";

export const useViewClientDocument = (document, redirectTo) => {
  const clientId = document?.patient_id;
  const type = document?.type;
  const subType = document?.sub_type;
  const objectId = document?.object_id;
  const parentId = document?.parent_id;
  const serviceId = document?.service_id;

  const [showAgreementModal, setShowAgreementModal] = useState(false);

  const { refetch: exportProcedureData, isFetching: isProcedureLoading } =
    useExportProcedureData(
      {
        clientId,
        procedureId: parentId,
      },
      {
        onSuccess: (data) => {
          window.open(data.data.data);
        },
      },
    );

  const { refetch: showFile, isFetching: isFileLoading } = useShowFile(
    {
      clientId,
      fileId: objectId,
    },
    {
      onSuccess: (data) => {
        window.open(data.data.data.document.download_path);
      },
    },
  );

  const {
    refetch: downloadPrescriptionPdf,
    isFetching: isPrescriptionLoading,
  } = useDownloadPrescriptionPdf(
    {
      id: objectId,
      clientId,
    },
    {
      onSuccess: (data) => {
        window.open(data.data.data.document.download_path);
      },
    },
  );

  const {
    refetch: viewAgreementData,
    data: agreementData,
    isFetching: isAgreementLoading,
  } = useAgreementData(
    {
      membershipId: parentId,
      objectId,
    },
    {
      onSuccess: () => setShowAgreementModal(true),
      select: (data) => {
        return {
          content: data?.data?.data?.agreement_text,
          patientSignature: data?.data?.data?.patient_signature_url,
          patientFullName: data?.data?.data?.patient?.full_name,
        };
      },
    },
  );
  const {
    refetch: viewExpiredAgreementData,
    isFetching: isExpiredAgreementDataLoading,
    data: expiredAgreementData,
  } = useExpiredAgreementData(
    {
      membershipId: parentId,
      objectId,
    },
    {
      onSuccess: () => setShowAgreementModal(true),
      select: (data) => {
        return {
          content: data?.data?.data?.agreement_text,
          patientSignature: data?.data?.data?.patient_signature_url,
          patientFullName: data?.data?.data?.patient?.full_name,
        };
      },
    },
  );

  const viewDocument = () => {
    switch (type) {
      case DOCUMENT_TYPES.procedure:
        exportProcedureData();
        break;
      case DOCUMENT_TYPES.questionnaries:
        if (subType === DOCUMENT_SUB_TYPES.procedure) {
          redirectTo(
            `/clients/${clientId}/questionnaire/${objectId}/profile/${parentId}`,
          );
        }
        if (subType === DOCUMENT_SUB_TYPES.appointment) {
          redirectTo(
            `/clients/${clientId}/appointment-questionnaire/${objectId}/appointment/${parentId}/service/${serviceId}`,
          );
        }
        break;
      case DOCUMENT_TYPES.consent:
        if (subType === DOCUMENT_SUB_TYPES.appointment) {
          redirectTo(
            `/clients/${clientId}/appointment/${objectId}/consent/${parentId}`,
          );
        } else if (subType === DOCUMENT_SUB_TYPES.health) {
          redirectTo(
            `/clients/${clientId}/health-appointment/${objectId}/consent/${parentId}`,
          );
        } else {
          redirectTo(
            `/clients/${clientId}/consent/${objectId}/profile/${parentId}`,
          );
        }
        break;
      case DOCUMENT_TYPES.agreement:
        if (subType === DOCUMENT_SUB_TYPES.expired_agreement) {
          viewExpiredAgreementData();
        } else {
          viewAgreementData();
        }
        break;
      case DOCUMENT_TYPES.file:
      case DOCUMENT_TYPES.gfe:
      case DOCUMENT_TYPES.efax:
        showFile();
        break;
      case DOCUMENT_TYPES.ePrescription:
        downloadPrescriptionPdf();
        break;
      case DOCUMENT_TYPES.health_questionnaries:
        if (subType === DOCUMENT_SUB_TYPES.appointment) {
          redirectTo(
            `/clients/${clientId}/health-questionnaires/${objectId}/appointment/${parentId}`,
          );
        } else {
          redirectTo(
            `/clients/${clientId}/health-questionnaires/${objectId}/procedure/${parentId}`,
          );
        }
    }
  };

  const agreementModal =
    subType === DOCUMENT_SUB_TYPES.expired_agreement
      ? expiredAgreementData
      : agreementData;

  useEffect(() => {
    viewDocument();
  }, [document]);

  return {
    isLoading:
      isProcedureLoading ||
      isFileLoading ||
      isPrescriptionLoading ||
      isAgreementLoading ||
      isExpiredAgreementDataLoading,
    agreementModal: {
      ...agreementModal,
      isShown: showAgreementModal,
      close: () => setShowAgreementModal(false),
    },
  };
};
