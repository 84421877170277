import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../Utils/services.js";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const clinicsInstance = axios.create();

clinicsInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

clinicsInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

clinicsInstance.interceptors.response.use(accountStatusResponseInterceptor);

export const fetchClinics = (formData) => {
  return (dispatch) => {
    clinicsInstance
      .get(process.env.REACT_APP_API_URL + "clinics", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "CLINIC_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLINIC_LIST", payload: error.response.data });
      });
  };
};

export const fetchSelectedClinic = (formData, clinicId) => {
  return (dispatch) => {
    clinicsInstance
      .get(
        process.env.REACT_APP_API_URL +
          "clinics/" +
          clinicId +
          "?scopes=business_hours",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SELECTED_CLINIC_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SELECTED_CLINIC_LIST",
          payload: error.response.data,
        });
      });
  };
};

export const fetchDefaultClinicData = () => {
  return (dispatch) => {
    clinicsInstance
      .get(process.env.REACT_APP_API_URL + "get_clinic_default_data")
      .then((response) => {
        dispatch({ type: "DEFAULT_CLINIC_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DEFAULT_CLINIC_DATA", payload: error.response.data });
      });
  };
};

export const createClinic = (formData) => {
  return (dispatch) => {
    clinicsInstance
      .post(process.env.REACT_APP_API_URL + "clinics", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "CREATE_CLINIC", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_CLINIC", payload: error.response.data });
      });
  };
};

export const updateSelectedClinic = (formData, clinicId) => {
  return (dispatch) => {
    clinicsInstance
      .patch(
        process.env.REACT_APP_API_URL + "clinics/" + clinicId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_SELECTED_CLINIC", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SELECTED_CLINIC",
          payload: error.response.data,
        });
      });
  };
};

export const fetchAllTimezone = () => {
  return (dispatch) => {
    clinicsInstance
      .get(process.env.REACT_APP_API_URL + "timezone-list")
      .then((response) => {
        dispatch({ type: "TIMEZONE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "TIMEZONE_LIST", payload: error.response.data });
      });
  };
};

export const fetchAllCountries = () => {
  return (dispatch) => {
    clinicsInstance
      .get(process.env.REACT_APP_API_URL + "country-list")
      .then((response) => {
        dispatch({ type: "COUNTRIES_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "COUNTRIES_LIST", payload: error.response.data });
      });
  };
};

export const deleteClinic = (clinicId) => {
  return async (dispatch) => {
    await clinicsInstance
      .delete(process.env.REACT_APP_API_URL + "clinics/" + clinicId)
      .then((response) => {
        dispatch({ type: "DELETE_CLINIC", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_CLINIC_ERROR", payload: error.response.data });
        if (
          error.response.data.message !==
            "delete_clinic_active_membership_error" &&
          error.response.data.message !== "delete_clinic_appointment_error"
        ) {
          toast.error(error.response.data.message);
        }
      });
  };
};

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}
