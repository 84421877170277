import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from 'react-router';
import { getProcedurePrescription, saveNewPrescription, savePharmacy, generatePrescriptionPDF, faxPrescriptionPDF, emailPrescriptionPDF, exportEmptyData } from '../../Actions/Clients/clientsAction.js';
import { toast } from "react-toastify";
import { showFormattedDate, displayName, isFormSubmit, isNumber, dateFormatPicker, validateEmail, getDateFormat } from '../../Utils/services.js';
import moment from 'moment';
import CustomDatePicker from '../Common/CustomDatePicker.js';
import Autocomplete from 'react-google-autocomplete';
import { SketchField, Tools } from 'react-sketch';
import { format } from 'date-fns'
import axios from 'axios';
import {
  accountStatusResponseInterceptor
} from "../AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { InputPhone } from "../../shared/InputPhone/InputPhone.js";
import { phoneUtil } from "../../utilities/phone";

const initNewPrescriptionField = () => {
  return {
    medicine_name   : '',
    form            : '',
    strength        : '',
    quantity        : '',
    frequency       : '',
    dosage          : '',
    refills         : '',
    medicationClass : 'tableInput p-l-5 p-r-5',
    formClass       : 'tableInput p-l-5 p-r-5',
    strengthClass   : 'tableInput p-l-5 p-r-5',
    quantityClass   : 'tableInput p-l-5 p-r-5',
    directionsClass : 'tableInput p-l-5 p-r-5',
    doseClass       : 'tableInput p-l-5 p-r-5',
    refillsClass    : 'tableInput p-l-5 p-r-5',
  }
}

const nullToString = (value) => {
  if ((typeof value != undefined && value != null)) {
    return value;
  } else {
    return '';
  }
}

const procedurePrescriptionInstance = axios.create();
procedurePrescriptionInstance.interceptors.response.use(accountStatusResponseInterceptor);
procedurePrescriptionInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
   if(!error.response) {
      return {data : {data : "", message : "file_type_error", status : 400}}
   }
});

class ProcedurePrescriptions extends Component {
  constructor(props) {
    super(props);
    window.onscroll = () => {
      return false;
    }
    const languageData  = JSON.parse(localStorage.getItem('languageData'));
    const userData      = JSON.parse(localStorage.getItem('userData'));
    this.state = {
      backURLType                         : (this.props.actionType) ? this.props.actionType : (this.props.match.params.actionType) ? this.props.match.params.actionType : 'clients',
      action                              : (this.props.type) ? this.props.type : (this.props.match.params.type) ? this.props.match.params.type : 'profile',

      clientID                            : (this.props.patientID) ? this.props.patientID : (this.props.match.params.patientID) ? this.props.match.params.patientID : 0,
      proID                               : (this.props.procedureID) ? this.props.procedureID : (this.props.match.params.procedureID) ? this.props.match.params.procedureID : 0,
      index                               : (this.props.procedureID) ? 0 : (this.props.match.params.objIndex) ? this.props.match.params.objIndex : -1,
      viewOnly                            : (this.props.viewOnly) ? this.props.viewOnly : (this.props.match.params.viewOnly && (this.props.match.params.viewOnly =='true' || this.props.match.params.viewOnly == true)) ? true : false,

      showLoader                          : false,
      languageData                        : languageData.clients,
      globalLang                          : languageData.global,

      showNewPrescriptionModal            : false,
      newPrescriptionField                : [initNewPrescriptionField()],
      newPrescriptionValue                : [],
      shipto_ohers_addr                   : '',
      ship_to                             : 'patient',
      prescription_note                   : '',
      prescription_note_class             : 'setting-textarea-box auto-height',
      pharmacy_id_class                   : 'setting-select-box',
      date_needed_class                   : 'setting-input-box',
      shipto_ohers_addr_class             : 'setting-input-box m-t-15',
      date_needed                         : '',
      pharmacy_name                       : '',
      pharmacy_fax: '',
      pharmacy_fax_error: false,
      pharmacy_phone: '',
      pharmacy_phone_error: false,
      pharmacy_name_class                 : 'setting-input-box',
      pharmacy_phone_class                : 'setting-input-box',
      defaultCountry                      : localStorage.getItem('cCode'),
      showNewPrescriptionModalInViewMode  : false,

      insurance_provider                  : '',
      insurance_policy_group              : '',
      insurance_id                        : '',
      insurancePhoneNumber: '',
      insurancePhoneNumber_error: false,
      insurancePhoneNumberClass           : 'setting-input-box',
      insurancePhoneNumberError           : false,
      insurance_employer                  : '',
      prescription_card                   : 'yes',
      insurance_carrier                   : '',
      insuranceProviderClass              : 'setting-input-box',
      insurancePolicyGroupClass           : 'setting-input-box',
      insuranceIDClass                    : 'setting-input-box',
      insuranceCarrierClass               : 'setting-input-box',
      isRender                            : false,
      state_license                       : '',
      dea                                 : '',
      npi                                 : '',
      medicaid_upin                       : '',
      state_licenseClass                  : 'setting-input-box',
      deaClass                            : 'setting-input-box',
      npiClass                            : 'setting-input-box',
      medicaid_upinClass                  : 'setting-input-box',
      pharmacy_email                      : '',
      pharmacy_email_class                : 'setting-input-box',
      pharmacy_fax_form_error             : false,
      pharmacy_fax_form_class             : 'setting-input-box',

      pharmacy_phone_form: '',
      pharmacy_phone_form_error_error: false,
      pharmacy_phone_form_class           : 'setting-input-box',

      patFirstNameClass                   : 'setting-input-box',
      pat_first_name                      : '',
      patLastNameClass                    : 'setting-input-box',
      pat_last_name                       : '',
      date_of_birth                       : '',
      gender                              : '',
      pat_address                         : '',
      pat_city                            : '',
      pat_state                           : '',
      pat_zipcode                         : '',
      patEvePhoneNumber: '',
      patEvePhoneNumber_error: false,
      patDayPhoneNumber: '',
      patDayPhoneNumber_error: false,
      patDayPhoneNumberClass              : 'setting-input-box',
      patEvePhoneNumberClass              : 'setting-input-box',
      pat_email                           : '',
      case_manager                        : '',
      pat_is_eligible                     : false,
      name_of_insured                     : '',
      relationship                        : 'self',
      relationship_other_value            : '',
      pat_is_other_tried                  : '',
      pro_firstname                       : '',
      pro_lastname                        : '',
      userData                            : userData,
      will_there_be_access_to             : '',
      primary_diagnosis_code              : '',
      other_diagnosis_conditions          : '',
      date_of_diagnosis                   : '',
      current_weight                      : '',
      weight_type                         : '',
      current_height                      : '',
      height_type                         : '',
      last_date_of_height                 : '',
      date_of_last_weight                 : '',
      allergies                           : '',

      pro_ofc_contact                     : '',
      prescriber_fax: '',
      prescriber_fax_error: false,
      prescriber_email                           : '',
      prescriber_best_time_to_call        : '',
      preferred_method_of_contact         : 'email',
      substitution_permitted              : 'yes',

      proFirstNameClass                   : 'setting-input-box',
      proLastNameClass                    : 'setting-input-box',
      proEmailClass                       : 'setting-input-box',
      pharmacy_email_form                 : '',
      office_contact                      : '',
      pharmacy_email_form_class           : 'setting-input-box',

      how_will_send_prescription          : 'email',
      prescriber_address                  : '',
      prescriber_city                     : '',
      prescriber_state                    : '',
      prescriber_zipcode                  : '',
      prescriber_phone: '',
      prescriber_phone_error: false,

      prescriberAddressClass              : 'setting-input-box',
      prescriberCityClass                 : 'setting-input-box',
      prescriberStateClass                : 'setting-input-box',
      prescriberPhoneClass                : 'setting-input-box',
      dobClass                            : 'setting-input-box',


      countryList                         : [],
      enter_details_manually              : false,
      pharmacy_phone_render_flag          : false,
      pharmacy_city                       : '',
      pharmacy_state                      : '',
      pharmacy_country                    : '',
      pharmacy_address                    : '',
      pharmacy_zipcode                    : '',
      pharmacy_city_class                 : 'setting-input-box',
      pharmacy_state_class                : 'setting-input-box',
      pharmacy_country_class              : 'setting-select-box',
      pharmacy_address_class              : 'setting-input-box',
      pharmacy_zipcode_class              : 'setting-input-box',


      pharmacy_address_form               : '',
      pharmacy_city_form                  : '',
      pharmacy_state_form                 : '',
      pharmacy_country_form               : '',
      pharmacy_zipcode_form               : '',
      pharmacy_address_form_class         : 'setting-input-box',
      pharmacy_city_form_class            : 'setting-input-box',
      pharmacy_state_form_class           : 'setting-input-box',
      pharmacy_country_form_class         : 'setting-input-box',
      pharmacy_zipcode_form_class         : 'setting-input-box',

      login_user_signature                : '',
      login_user_signature_url            : '',
      prescriber_signature                : '',
      prescriber_signature_url            : '',

      //for change signature
      showSignatureCanvas                 : false,
      signatureCanvasClass                : "signature-box sig-div no-display",
      signatureCanvasInputOut             : 'input-outer',
      signatureCanvasImgSrcClass          : 'img-src',
      isClearSignaturCanvasButton         : true,
      isResetSignaturCanvasButton         : false,
      signatureCanvasImageName            : '',
      signatureCanvasImageUrl             : '',
      signatureUploadedUrl                : '',

      procedurePrescriptionInformationId  : 0,
      loggedInUserId                      : (userData && userData.user && userData.user.id) ? userData.user.id : 0,
      procedureProviderID                 : 0,
    };
  }

  handleInputChange = (event) => {
    const target  = event.target;
    const value   = target.type === 'checkbox' ? target.checked : target.value;

    if(target.name === 'enter_details_manually'){
      document.getElementById('new-input').value = '';
      this.setState({pharmacy_phone_render_flag:false}, () => {
        this.setState({
          enter_details_manually              : value,
          pharmacy_phone_render_flag          : true,
          pharmacy_name                       : '',
          pharmacy_fax                        : '',
          pharmacy_phone                      : '',
          pharmacy_name_class                 : 'setting-input-box',
          pharmacy_phone_class                : 'setting-input-box',
          how_will_send_prescription          : 'email',
          defaultCountry                      : localStorage.getItem('cCode'),
          pharmacy_email                      : '',
          pharmacy_email_class                : 'setting-input-box',
          pharmacy_city                       : '',
          pharmacy_state                      : '',
          pharmacy_country                    : '',
          pharmacy_address                    : '',
          pharmacy_zipcode                    : '',
          pharmacy_city_class                 : 'setting-input-box',
          pharmacy_state_class                : 'setting-input-box',
          pharmacy_country_class              : 'setting-select-box',
          pharmacy_address_class              : 'setting-input-box',
          pharmacy_zipcode_class              : 'setting-input-box',
        });
      })
      return true;
    }

    const modal   = event.target.dataset.modal;
    if (modal === 'prescription') {
      const index                                 = event.target.dataset.index;
      let prescriptionField                       = this.state.prescriptionField;
      prescriptionField[index][event.target.name] = value;
      this.setState({prescriptionField: prescriptionField , dataChanged: true});
      return true;
    }

    if (modal === 'prescriptionNew') {
      const index                                 = event.target.dataset.index;
      let newPrescriptionField                    = this.state.newPrescriptionField;
      newPrescriptionField[index][event.target.name] = value;
      this.setState({newPrescriptionField:newPrescriptionField , dataChanged : true});
      return true;
    }

    this.setState({[event.target.name]: value , dataChanged : true, clicnicForCard: 0});


    if (event.target.name === 'pharmacy_id') {
      this.showPharmacyData(value);
    }

    if (event.target.name === 'relationship_other_value') {
      this.setState({relationship: value});
    }

    if (event.target.name === 'how_will_send_prescription') {
      this.setState({pharmacy_email_class: 'setting-input-box'});
    }

  }

  showPharmacyData(value) {
    let arr = this.state.clientData.pharmacy_list;

    if (this.state.clientData && this.state.clientData.pharmacy_list && this.state.clientData.pharmacy_list.length) {
      this.setState({isRender: false}, function(){
        for (var i=0, iLen=arr.length; i<iLen; i++) {
          if (arr[i].id == value) {
            this.setState({
              pharmacy_phone_form : (arr[i].phone) ? arr[i].phone : '',
              pharmacy_email_form : (arr[i].email) ? arr[i].email : '',
              pharmacy_address_form : (arr[i].address) ? arr[i].address : '',
              pharmacy_city_form : (arr[i].city) ? arr[i].city : '',
              pharmacy_state_form : (arr[i].state) ? arr[i].state : '',
              pharmacy_country_form : (arr[i].country) ? arr[i].country : '',
              pharmacy_zipcode_form : (arr[i].zipcode) ? arr[i].zipcode : '',
              isRender            : true,
            });

          }
        }
      });
    }
  }


  componentDidMount() {
    let isRender = false;
    let clientId = this.state.clientID;
    if (clientId == undefined) {
      clientId = 0;
      isRender = true;
    }
    this.setState({showLoader: true, isRender: isRender});
    this.props.getProcedurePrescription({patient_id:this.state.clientID, procedure_id:this.state.proID});
  }



  static getDerivedStateFromProps(props, state) {
    let returnState = {};

    if(props.showLoader === false){
        returnState.showLoader = false;
      props.exportEmptyData();
      return returnState;
    }

    if ( props.clientData &&  props.clientData !== state.clientData ) {
      props.exportEmptyData();
      returnState.clientData = props.clientData;

      let proID                 = state.proID;
      let index                 = state.index;
      let viewOnly              = state.viewOnly;
      let prescriptionField     = [];
      let prescription_note     = '';
      let pharmacy_id           = '';
      let date_needed           = '';
      let ship_to               = state.ship_to;
      let shipto_ohers_addr     = '';
      let pdf_url               = null;
      let pharmacy_fax_enabled  = 0;
      let pharmacy_email_enabled= 0;
      let healthProvider        = [];
      let pharmacy_email_form   = '';
      let pharmacy_phone_form   = '';
      let pharmacy_address_form = '';
      let pharmacy_city_form    = '';
      let pharmacy_state_form   = '';
      let pharmacy_country_form = '';
      let pharmacy_zipcode_form = '';
      let date_of_birth         = '';
      let pat_is_new_to_therapay        = '';
      let pat_is_on_therapay            = '';
      let pat_is_restart_therapay       = '';
      let clinical_start_date           = '';
      let primary_diagnosis_code        = '';
      let date_of_diagnosis             = '';
      let other_diagnosis_conditions    = '';
      let current_weight                = '';
      let weight_type                   = '';
      let current_height                = '';
      let height_type                   = '';
      let last_date_of_height           = '';
      let date_of_last_weight           = '';
      let other_therapies_tried_failed  = '';
      let allergies                     = '';
      let prescriber_fax                = '';
      let prescriber_best_time_to_call  = '';
      let preferred_method_of_contact   = 'email';
      let substitution_permitted        = 'yes';
      let office_contact                = '';

      let prescriber_address            = '';
      let prescriber_city               = '';
      let prescriber_state              = '';
      let prescriber_zipcode            = '';
      let prescriber_phone              = '';
      let clinicProvider                = [];
      let prescriber_email              = '';

      let signatureCanvasClass         = "signature-box sig-div";
      let signatureCanvasInputOut      = 'input-outer no-display';
      let signatureCanvasImgSrcClass   = 'img-src';

      let procedurePrescriptionInformationId = 0;

      // set country-list
      returnState.countryList = (props.clientData.countries) ? props.clientData.countries : []

      if (index > -1) {
        if (props.clientData.procedure_health && props.clientData.procedure_health.id == state.proID) {
          // set procedure-provider
          returnState.procedureProviderID           = (props.clientData.procedure_health['user_id']) ? props.clientData.procedure_health['user_id'] : 0;

          healthProvider                = props.clientData.procedure_health['user'];
          const procedure_prescription  = props.clientData.procedure_health['procedure_prescription'];
          pdf_url                       = props.clientData.procedure_health['prescription_pdf_url'];
          pharmacy_fax_enabled          = props.clientData.procedure_health['pharmacy_fax_enabled'];
          pharmacy_email_enabled        = props.clientData.procedure_health['pharmacy_email_enabled'];
          clinicProvider                = props.clientData.procedure_health['clinic'];


          // set signature of logged-in user
          if(healthProvider && healthProvider.signature_url){
            returnState.login_user_signature      =  healthProvider.signature;
            returnState.login_user_signature_url  =  healthProvider.signature_url;;
          }
          returnState.signatureUploadedUrl = returnState.login_user_signature_url
          if(returnState.login_user_signature){
            signatureCanvasClass         = "signature-box sig-div no-display";
            signatureCanvasInputOut      = 'input-outer';
            signatureCanvasImgSrcClass   = 'img-src';
          }

          prescriber_email  = (healthProvider.email_id) ? healthProvider.email_id : '';

          if (procedure_prescription.length > 0) {
            procedurePrescriptionInformationId = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.id : 0;
            prescription_note = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.prescription_note : '';
            pharmacy_id       = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.pharmacy_id : '';
            date_needed       = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.date_needed : '';
            ship_to           = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.ship_to : state.ship_to;
            shipto_ohers_addr = (procedure_prescription[0].procedure_prescription_information && procedure_prescription[0].procedure_prescription_information.ship_to != 'prescriber' && procedure_prescription[0].procedure_prescription_information.ship_to != 'patient') ? procedure_prescription[0].procedure_prescription_information.ship_to : '';

            pharmacy_phone_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['phone'] : '';

            pharmacy_email_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['email'] : '';

            pharmacy_address_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['address'] : '';

            pharmacy_city_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['city'] : '';

            pharmacy_state_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['state'] : '';

            pharmacy_country_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['country'] : '';

            pharmacy_zipcode_form = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['zipcode'] : '';

            procedure_prescription.map((ppObj, ppIdx) => {
              let precription           = {};
              precription               = initNewPrescriptionField();
              precription.medicine_name = ppObj.medicine_name;
              precription.form          = ppObj.form;
              precription.strength      = ppObj.strength;
              precription.quantity      = ppObj.quantity;
              precription.frequency     = ppObj.frequency;
              precription.dosage        = ppObj.dosage;
              precription.refills       = ppObj.refills;
              prescriptionField.push(precription)
            })


            pat_is_new_to_therapay                = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.is_patient_new_to_therapy) : '';

            pat_is_on_therapay                    = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.is_patient_currently_on_therapy) : '';

            pat_is_restart_therapay               = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.is_patient_restarting_therapy) : '';

            clinical_start_date                   = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.start_date) : '';

            primary_diagnosis_code                = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.primary_diagnosis_code) : '';

            date_of_diagnosis                     = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.date_of_diagnosis) : '';

            other_diagnosis_conditions            = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.other_diagnosis_conditions) : '';

            current_weight                        = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.current_weight) : '';

            weight_type                           = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.weight_type) : '';

            current_height                        = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.current_height) : '';

            height_type                           = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.height_type) : '';

            last_date_of_height                   = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.last_date_of_height) : '';

            date_of_last_weight                   = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.date_of_last_weight) : '';

            other_therapies_tried_failed          = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.other_therapies_tried_failed) : null;

            allergies                             = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.allergies) : '';

            prescriber_fax                        = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_fax) : '';

            prescriber_best_time_to_call          = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.best_time_to_call) : '';

            preferred_method_of_contact          = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.preferred_method_of_contact) : '';

            substitution_permitted              = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.substitution_permitted : 'yes';

            office_contact                       = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.office_contact) : '';

            prescriber_address                   = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_address) : '';
            prescriber_city                      = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_city) : '';
            prescriber_state                     = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_state) : '';
            prescriber_zipcode                   = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_zipcode) : '';
            prescriber_phone                     = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_phone) : '';
            prescriber_email                     = (procedure_prescription[0].procedure_prescription_information) ? nullToString(procedure_prescription[0].procedure_prescription_information.prescriber_email) : '';



            returnState.prescriber_signature  = (procedure_prescription[0].procedure_prescription_information && procedure_prescription[0].procedure_prescription_information.signature) ? procedure_prescription[0].procedure_prescription_information.signature : '';
            returnState.prescriber_signature_url  = (procedure_prescription[0].procedure_prescription_information &&  procedure_prescription[0].procedure_prescription_information.signature_url) ? procedure_prescription[0].procedure_prescription_information.signature_url : '';

            returnState.signatureUploadedUrl = returnState.prescriber_signature_url
            if(returnState.prescriber_signature){
              signatureCanvasClass         = "signature-box sig-div no-display";
              signatureCanvasInputOut      = 'input-outer';
              signatureCanvasImgSrcClass   = 'img-src';
            } else {
              signatureCanvasClass         = "signature-box sig-div";
              signatureCanvasInputOut      = 'input-outer no-display';
              signatureCanvasImgSrcClass   = 'img-src';
            }
          } else {
            prescriber_address                   = (clinicProvider && clinicProvider.address) ? nullToString(clinicProvider.address) : '';
            prescriber_city                      = (clinicProvider && clinicProvider.clinic_city) ? nullToString(clinicProvider.clinic_city) : '';
            prescriber_state                     = (clinicProvider && clinicProvider.clinic_state) ? nullToString(clinicProvider.clinic_state) : '';
            prescriber_phone                     = (clinicProvider && clinicProvider.contact_no) ? nullToString(clinicProvider.contact_no) : '';
            prescriber_zipcode                   = (clinicProvider && clinicProvider.clinic_zipcode) ? nullToString(clinicProvider.clinic_zipcode) : '';
          }
        }
      }

      if (prescriptionField.length === 0) {
        prescriptionField = [initNewPrescriptionField()]
      }

      date_of_birth        = (props.clientData.date_of_birth) ? props.clientData.date_of_birth : '';

      returnState.clientData                = props.clientData;
      returnState.showLoader                = false;

      returnState.showNewPrescriptionModal  = (viewOnly == "true" || viewOnly == true) ? false : true;
      returnState.showNewPrescriptionModalInViewMode = (viewOnly == "true" || viewOnly == true) ? true : false;

      returnState.newPrescriptionField      = prescriptionField;
      returnState.prescriptionValue         = [];
      returnState.prescriptionProcedureId   = proID;
      returnState.prescription_note         = prescription_note;
      returnState.pharmacy_id               = pharmacy_id;
      returnState.date_needed               = date_needed;
      returnState.ship_to                   = ship_to;
      returnState.shipto_ohers_addr         = shipto_ohers_addr;
      returnState.savedIndex                = index;
      returnState.prescription_pdf_url      = pdf_url;
      returnState.pharmacy_fax_enabled      = pharmacy_fax_enabled;
      returnState.pharmacy_email_enabled    = pharmacy_email_enabled;


      returnState.insurance_provider        =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.insurance_provider) : '';
      returnState.insurance_id              =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.policy_id) : '';
      returnState.insurance_policy_group    =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.policy_group) : '';
      returnState.insurancePhoneNumber      =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.phone) : '';
      returnState.insurance_employer        =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.employer) : '';
      returnState.prescription_card         =  (returnState.clientData.patient_insurence) ? returnState.clientData.patient_insurence.prescription_card : 'yes'
      returnState.insurance_carrier         =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.carrier) : '';
      returnState.isRender                  = true;

      returnState.relationship              =  (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.relationship) : '';

      returnState.state_license             = (healthProvider.state_license) ? healthProvider.state_license : '';
      returnState.dea                       = (healthProvider.dea) ? healthProvider.dea : '';
      returnState.npi                       = (healthProvider.npi) ? healthProvider.npi : '';
      returnState.medicaid_upin             = (healthProvider.medicaid_upin) ? healthProvider.medicaid_upin : '';
      returnState.pharmacy_phone_form       = (pharmacy_phone_form) ? nullToString(pharmacy_phone_form) : '';
      returnState.pharmacy_email_form       = (pharmacy_email_form) ? nullToString(pharmacy_email_form) : '';
      returnState.pharmacy_address_form     = (pharmacy_address_form) ? nullToString(pharmacy_address_form) : '';
      returnState.pharmacy_city_form        = (pharmacy_city_form) ? nullToString(pharmacy_city_form) : '';
      returnState.pharmacy_state_form       = (pharmacy_state_form) ? nullToString(pharmacy_state_form) : '';
      returnState.pharmacy_country_form     = (pharmacy_country_form) ? nullToString(pharmacy_country_form) : '';
      returnState.pharmacy_zipcode_form     = (pharmacy_zipcode_form) ? nullToString(pharmacy_zipcode_form) : '';
      returnState.pat_first_name            = (returnState.clientData.firstname) ? returnState.clientData.firstname : '';
      returnState.pat_last_name             = (returnState.clientData.lastname) ? returnState.clientData.lastname : '';
      returnState.date_of_birth             = nullToString(date_of_birth);
      returnState.gender                    = returnState.clientData.gender;
      returnState.pat_address               = nullToString(returnState.clientData.address_line_1);
      returnState.pat_city                  = nullToString(returnState.clientData.city);
      returnState.pat_state                 = nullToString(returnState.clientData.state);
      returnState.pat_zipcode               = nullToString(returnState.clientData.pincode);
      returnState.patEvePhoneNumber         = nullToString(returnState.clientData.phoneNumber);
      returnState.patDayPhoneNumber         = nullToString(returnState.clientData.phoneNumber_2);
      returnState.pat_email                 = nullToString(returnState.clientData.email);
      returnState.pat_is_eligible           = (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.is_patient_eligible_for_medicare) : '';

      returnState.name_of_insured           = (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.name_of_insured) : '';

      returnState.will_there_be_access_to   = (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.will_there_be_access_to) : '';
      returnState.case_manager              = (returnState.clientData.patient_insurence) ? nullToString(returnState.clientData.patient_insurence.case_manager) : '';

      returnState.pat_is_new_to_therapay    = pat_is_new_to_therapay;
      returnState.pat_is_on_therapay        = pat_is_on_therapay;
      returnState.pat_is_restart_therapay   = pat_is_restart_therapay;
      returnState.clinical_start_date       = clinical_start_date;
      returnState.primary_diagnosis_code    = primary_diagnosis_code;

      returnState.date_of_diagnosis         = date_of_diagnosis;
      returnState.other_diagnosis_conditions= other_diagnosis_conditions;
      returnState.current_weight            = current_weight;
      returnState.weight_type               = weight_type;
      returnState.current_height            = current_height;
      returnState.height_type               = height_type;
      returnState.last_date_of_height       = last_date_of_height;
      returnState.date_of_last_weight       = date_of_last_weight;

      returnState.other_therapies_tried_failed = other_therapies_tried_failed;
      returnState.pat_is_other_tried        = (other_therapies_tried_failed) ? true : false;
      returnState.allergies                 = allergies;
      returnState.pro_firstname             = healthProvider.firstname;
      returnState.pro_lastname              = healthProvider.lastname;

      returnState.pro_ofc_contact           = healthProvider.contact_number_2;
      returnState.prescriber_email                 = prescriber_email;
      returnState.prescriber_fax            = prescriber_fax;
      returnState.prescriber_best_time_to_call   = prescriber_best_time_to_call;
      returnState.preferred_method_of_contact   = preferred_method_of_contact;
      returnState.substitution_permitted   = substitution_permitted;
      returnState.office_contact            = office_contact;

      returnState.prescriber_address        = prescriber_address;
      returnState.prescriber_city           = prescriber_city;
      returnState.prescriber_state          = prescriber_state;
      returnState.prescriber_zipcode        = prescriber_zipcode;
      returnState.prescriber_phone          = prescriber_phone;


      returnState.signatureCanvasClass         = signatureCanvasClass;
      returnState.signatureCanvasInputOut      = signatureCanvasInputOut;
      returnState.signatureCanvasImgSrcClass   = signatureCanvasImgSrcClass;

      returnState.procedurePrescriptionInformationId = procedurePrescriptionInformationId;
      returnState.index = index;

    }

    if ( props.newPresData !== undefined && props.newPresData.status === 200 && props.newPresData.data !== state.newPresData ) {
      document.body.style = "";
       return {
         newPresData          : props.newPresData.data,
         showLoader           : false,
         showNewPrescriptionModal  : false,
         newPresStatus        : props.newPresData.data
       }
     } else if ( props.newPresData !== undefined && props.newPresData.status !== 200 && props.newPresData.data !== state.newPresData ) {
       return {
         newPresData          : props.newPresData.data,
         showLoader           : false,
       }
     }

     if ( props.pharmacyData !== undefined && props.pharmacyData.status === 200 && props.pharmacyData.data !== state.pharmacyData ) {
       if ((state.clientData) && state.clientData.pharmacy_list) {
         state.clientData.pharmacy_list.push({
          id: props.pharmacyData.data.id,
          name: state.pharmacy_name,
          phone: props.pharmacyData.data.phone,
          fax: props.pharmacyData.data.fax,
          email: props.pharmacyData.data.email,
          address: (props.pharmacyData.data.address) ? props.pharmacyData.data.address : '',
          city: (props.pharmacyData.data.city) ? props.pharmacyData.data.city : '',
          state: (props.pharmacyData.data.state) ? props.pharmacyData.data.state : '',
          country: (props.pharmacyData.data.country) ? props.pharmacyData.data.country : '',
          zipcode: (props.pharmacyData.data.zipcode) ? props.pharmacyData.data.zipcode : '',
        })
       }
        return {
          pharmacyData              : props.pharmacyData.data,
          showLoader                : false,
          showPharmacyModal         : false,
          showNewPrescriptionModal  : true,
          pharmacyDataStatus        : props.pharmacyData.data.id,
          pharmacy_id               : props.pharmacyData.data.id,
          pharmacy_phone_form       : (props.pharmacyData.data.phone) ? props.pharmacyData.data.phone : '',
          pharmacy_email_form       : (props.pharmacyData.data.email) ? props.pharmacyData.data.email : '',
          isRender                  : true,

          pharmacy_address_form                   : (props.pharmacyData.data.address) ? props.pharmacyData.data.address : '',
          pharmacy_city_form                       : (props.pharmacyData.data.city) ? props.pharmacyData.data.city : '',
          pharmacy_state_form                      : (props.pharmacyData.data.state) ? props.pharmacyData.data.state : '',
          pharmacy_country_form                    : (props.pharmacyData.data.country) ? props.pharmacyData.data.country : '',
          pharmacy_zipcode_form                    : (props.pharmacyData.data.zipcode) ? props.pharmacyData.data.zipcode : '',
          pharmacy_phone_render_flag               : false
        }
      } else if ( props.pharmacyData !== undefined && props.pharmacyData.status !== 200 && props.pharmacyData.data !== state.pharmacyData ) {
        return {
          pharmacyData         : props.pharmacyData.data,
          showLoader           : false,
        }
      }

      if ( props.generatePDFData !== undefined && props.generatePDFData.status === 200 && props.generatePDFData.data !== state.generatePDFData ) {
        document.body.style = "";
        let returnState                  = {};
        returnState.generatePDFData      = props.generatePDFData.data;
        returnState.showLoader           = false;

        if ( props.generatePDFData.data && props.generatePDFData.data.file ) {
          returnState.prescription_pdf_url = props.generatePDFData.data.file;
          returnState.generatePDFStatus    = moment()
          window.open(props.generatePDFData.data.file);
        }

        return returnState
       } else if ( props.generatePDFData !== undefined && props.generatePDFData.status !== 200 && props.generatePDFData.data !== state.generatePDFData ) {
         return {
           generatePDFData      : props.generatePDFData.data,
           showLoader           : false,
         }
       }

     if ( props.faxData !== undefined && props.faxData.status === 200 && props.faxData.data !== state.faxData ) {
        return {
          faxData              : props.faxData.data,
          showLoader           : false,
        }
      } else if ( props.faxData !== undefined && props.faxData.status !== 200 && props.faxData.data !== state.faxData ) {
        return {
          faxData               : props.faxData.data,
          showLoader           : false,
        }
      }

      if ( props.emailData !== undefined && props.emailData.status === 200 && props.emailData.data !== state.emailData ) {
         return {
           emailData            : props.emailData.data,
           showLoader           : false,
         }
       } else if ( props.emailData !== undefined && props.emailData.status !== 200 && props.emailData.data !== state.emailData ) {
         return {
           emailData            : props.emailData.data,
           showLoader           : false,
         }
       }

    return returnState
  }


  hidePrescriptionNew = () => {
    this.setState({showNewPrescriptionModal: false});
  }

  addNewPrescription = () => {
    let prescriptionField = this.state.newPrescriptionField;
    prescriptionField.push(initNewPrescriptionField());
    this.setState({ newPrescriptionField: prescriptionField });
  }

  removeNewPrescription = (index) => {
    if (index) {
      let prescriptionField = this.state.newPrescriptionField;
      if (prescriptionField.length == 1) { return false; }
      prescriptionField.splice(index, 1);
      this.setState({ newPrescriptionField: prescriptionField});
    }
  }

  saveNewProcedurePrescription = () => {
    if (isFormSubmit()) {
      let error             = false;
      let prescriptionField = this.state.newPrescriptionField;

      if (this.state.pharmacy_phone_form && !phoneUtil.isPossible(phoneUtil.compose(this.state.pharmacy_phone_form))) {
        error = true;
        this.setState({
          pharmacy_phone_form_error: true,
        });
      }

      if (this.state.patDayPhoneNumber && !phoneUtil.isPossible(phoneUtil.compose(this.state.patDayPhoneNumber))) {
        error = true;
        this.setState({
          patDayPhoneNumber_error: true,
        });
      }

      if (this.state.patEvePhoneNumber && !phoneUtil.isPossible(phoneUtil.compose(this.state.patEvePhoneNumber))) {
        error = true;
        this.setState({
          patEvePhoneNumber_error: true,
        });
      }

      if (this.state.insurancePhoneNumber && !phoneUtil.isPossible(phoneUtil.compose(this.state.insurancePhoneNumber))) {
        error = true;
        this.setState({
          insurancePhoneNumber_error: true,
        });
      }

      if (this.state.prescriber_fax && !phoneUtil.isPossible(phoneUtil.compose(this.state.prescriber_fax))) {
        error = true;
        this.setState({
          prescriber_fax_error: true,
        });
      }

      if (typeof this.state.pharmacy_id == undefined || this.state.pharmacy_id == null || this.state.pharmacy_id == '') {
          this.setState({pharmacy_id_class: 'setting-select-box field_error'})
          error = true;
      } else if (this.state.pharmacy_id) {
          this.setState({pharmacy_id_class: 'setting-select-box auto-height'})
      }

      if (typeof this.state.date_needed == undefined || this.state.date_needed == null || this.state.date_needed == '') {
          this.setState({date_needed_class: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.date_needed) {
          this.setState({date_needed_class: 'setting-input-box auto-height'})
      }

      if (this.state.ship_to && this.state.ship_to != 'prescriber' && this.state.ship_to != 'patient') {
        if (typeof this.state.shipto_ohers_addr == undefined || this.state.shipto_ohers_addr == null || this.state.shipto_ohers_addr == '') {
            this.setState({shipto_ohers_addr_class: 'setting-input-box field_error'})
            error = true;
        } else if (this.state.shipto_ohers_addr) {
            this.setState({shipto_ohers_addr_class: 'setting-input-box auto-height'})
        }
      }


      prescriptionField.map((obj,idx) => {
        if (obj.medicine_name === '' || obj.medicine_name === null || obj.medicine_name === undefined) {
          prescriptionField[idx]['medicationClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.medicine_name.trim() === '') {
            prescriptionField[idx]['medicationClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['medicationClass'] = "tableInput p-l-5 p-r-5"
          }
        }


        if (obj.form === '' || obj.form === null || obj.form === undefined) {
          prescriptionField[idx]['formClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.form.trim() === '') {
            prescriptionField[idx]['formClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['formClass'] = "tableInput p-l-5 p-r-5"
          }
        }

        if (obj.strength === '' || obj.strength === null || obj.strength === undefined) {
          prescriptionField[idx]['strengthClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.strength.trim() === '') {
            prescriptionField[idx]['strengthClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['strengthClass'] = "tableInput p-l-5 p-r-5"
          }
        }

        if (obj.quantity === '' || obj.quantity === null || obj.quantity === undefined) {
          prescriptionField[idx]['quantityClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.quantity === '' || !isNumber(obj.quantity)) {
            prescriptionField[idx]['quantityClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['quantityClass'] = "tableInput p-l-5 p-r-5"
          }
        }

        if (obj.frequency === '' || obj.frequency === null || obj.frequency === undefined) {
          prescriptionField[idx]['directionsClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.frequency === '') {
            prescriptionField[idx]['directionsClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['directionsClass'] = "tableInput p-l-5 p-r-5"
          }
        }

        if (obj.dosage === '' || obj.dosage === null || obj.dosage === undefined) {
          prescriptionField[idx]['doseClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.dosage === '') {
            prescriptionField[idx]['doseClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['doseClass'] = "tableInput p-l-5 p-r-5"
          }
        }

        if (obj.refills === '' || obj.refills === null || obj.refills === undefined) {
          prescriptionField[idx]['refillsClass'] = 'tableInput p-l-5 p-r-5 field_error'
          error = true;
        } else {
          if (obj.refills === '' || !isNumber(obj.refills)) {
            prescriptionField[idx]['refillsClass'] = 'tableInput p-l-5 p-r-5 field_error'
            error = true;
          } else {
            prescriptionField[idx]['refillsClass'] = "tableInput p-l-5 p-r-5"
          }
        }
      });

      this.setState({newPrescriptionField : prescriptionField});

      if (typeof this.state.pat_first_name == undefined || this.state.pat_first_name == null || this.state.pat_first_name == '') {
          this.setState({patFirstNameClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.pat_first_name) {
          this.setState({patFirstNameClass: 'setting-input-box auto-height'})
      }

      if (typeof this.state.pat_last_name == undefined || this.state.pat_last_name == null || this.state.pat_last_name == '') {
          this.setState({patLastNameClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.pat_last_name) {
          this.setState({patLastNameClass: 'setting-input-box auto-height'})
      }

      if (typeof this.state.prescriber_address == undefined || this.state.prescriber_address == null || this.state.prescriber_address == '') {
          this.setState({prescriberAddressClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.prescriber_address) {
          this.setState({prescriberAddressClass: 'setting-input-box auto-height'})
      }

      if (typeof this.state.prescriber_city == undefined || this.state.prescriber_city == null || this.state.prescriber_city == '') {
          this.setState({prescriberCityClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.prescriber_city) {
          this.setState({prescriberCityClass: 'setting-input-box auto-height'})
      }

      if (typeof this.state.prescriber_state == undefined || this.state.prescriber_state == null || this.state.prescriber_state == '') {
          this.setState({prescriberStateClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.prescriber_state) {
          this.setState({prescriberStateClass: 'setting-input-box auto-height'})
      }

      if (
        typeof this.state.prescriber_phone == undefined ||
        this.state.prescriber_phone == null ||
        this.state.prescriber_phone == '' || 
        !phoneUtil.isPossible(phoneUtil.compose(this.state.prescriber_phone))
      ) {
          this.setState({prescriber_phone_error: true})
          error = true;
      } 

      if (typeof this.state.prescriber_email == undefined || this.state.prescriber_email == null || this.state.prescriber_email == '') {
          this.setState({proEmailClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.prescriber_email) {
        if (!validateEmail(this.state.prescriber_email.trim())) {
           this.setState({proEmailClass: 'setting-input-box field_error'})
           error = true;
         } else {
           this.setState({proEmailClass: 'setting-input-box'})
         }
      }

      if (typeof this.state.date_of_birth == undefined || this.state.date_of_birth == null || this.state.date_of_birth == '' || this.state.date_of_birth == '0000-00-00') {
          this.setState({dobClass: 'setting-input-box field_error'})
          error = true;
      } else if (this.state.date_of_birth) {
          this.setState({dobClass: 'setting-input-box auto-height'})
      }

      let signature = ''
      if(this.state.procedurePrescriptionInformationId > 0){
        if(this.state.signatureCanvasImageName){
          signature = this.state.signatureCanvasImageName;
        } else if(this.state.prescriber_signature){
          signature = this.state.prescriber_signature;
        } else if(this.state.login_user_signature){
          signature = this.state.login_user_signature;
        }
      } else if(this.state.procedurePrescriptionInformationId <= 0 && this.state.login_user_signature){
        signature = this.state.login_user_signature;
      }

      if(signature === ''){
        toast.dismiss();
        toast.error(this.state.globalLang.validation_md_signature_required_if)
        error  = true;
        this.setState({signatureCanvasClass : 'signature-box sig-div field-error'})
      }



      if (error) {
        return false;
      } else {

        let formData = {
          procedure_id: this.state.prescriptionProcedureId,
          pharmacy : {
              pharmacy_id                 : this.state.pharmacy_id,
              phone                       : phoneUtil.compose(this.state.pharmacy_phone_form),
              email                       : (this.state.pharmacy_email_form) ? this.state.pharmacy_email_form : '',
              address                     : (this.state.pharmacy_address_form) ? this.state.pharmacy_address_form : '',
              city                        : (this.state.pharmacy_city_form) ? this.state.pharmacy_city_form : '',
              state                       : (this.state.pharmacy_state_form) ? this.state.pharmacy_state_form : '',
              country                     : (this.state.pharmacy_country_form) ? this.state.pharmacy_country_form : '',
              zipcode                     : (this.state.pharmacy_zipcode_form) ? this.state.pharmacy_zipcode_form : '',
          },

          procedure_prescriptions : prescriptionField,

          procedure_prescription_informations : {
            prescription_note : this.state.prescription_note,
            pharmacy_id       : this.state.pharmacy_id,
            date_needed       : moment(this.state.date_needed).format('YYYY-MM-DD'),
            ship_to           : this.state.ship_to,

            will_there_be_access_to            : this.state.will_there_be_access_to,
            is_patient_new_to_therapy          : this.state.pat_is_new_to_therapay,
            is_patient_restarting_therapy      : this.state.pat_is_restart_therapay,
            is_patient_currently_on_therapy    : this.state.pat_is_on_therapay,
            primary_diagnosis_code             : this.state.primary_diagnosis_code,
            date_of_diagnosis                  : moment(this.state.date_of_diagnosis).format('YYYY-MM-DD'),
            start_date                         : moment(this.state.clinical_start_date).format('YYYY-MM-DD'),
            other_diagnosis_conditions         : this.state.other_diagnosis_conditions,
            current_weight                     : this.state.current_weight,
            weight_type                        : this.state.weight_type,
            date_of_last_weight                : moment(this.state.date_of_last_weight).format('YYYY-MM-DD'),
            current_height                     : this.state.current_height,
            height_type                        : this.state.height_type,
            last_date_of_height                : moment(this.state.last_date_of_height).format('YYYY-MM-DD'),
            prescriber_fax                     : phoneUtil.compose(this.state.prescriber_fax),
            best_time_to_call                  : this.state.prescriber_best_time_to_call,
            preferred_method_of_contact        : this.state.preferred_method_of_contact,
            substitution_permitted             : this.state.substitution_permitted,
            allergies                          : this.state.allergies,
            office_contact                     : this.state.office_contact,

            prescriber_address                 : this.state.prescriber_address,
            prescriber_city                    : this.state.prescriber_city,
            prescriber_state                   : this.state.prescriber_state,
            prescriber_zipcode                 : this.state.prescriber_zipcode,
            prescriber_phone                   : phoneUtil.compose(this.state.prescriber_phone),
            signature                          : signature,
            prescriber_email                   : this.state.prescriber_email
          },

          patient : {
              firstname       : this.state.pat_first_name,
              lastname        : this.state.pat_last_name,
              date_of_birth   : format(this.state.date_of_birth, 'YYYY-MM-DD'),
              gender          : this.state.gender,
              address_line_1  : this.state.pat_address,
              city            : this.state.pat_city,
              state           : this.state.pat_state,
              pincode         : this.state.pat_zipcode,
              phoneNumber     : phoneUtil.compose(this.state.patDayPhoneNumber),
              phoneNumber_2   : phoneUtil.compose(this.state.patEvePhoneNumber),
              email           : this.state.pat_email
          },
        }

        if ( this.state.pat_is_other_tried ) {
          formData.procedure_prescription_informations.other_therapies_tried_failed = this.state.other_therapies_tried_failed
        } else {
          formData.procedure_prescription_informations.other_therapies_tried_failed = ''
        }

        if ( this.state.ship_to && this.state.ship_to != 'prescriber' && this.state.ship_to != 'patient' ) {
          formData.procedure_prescription_informations.shipto_ohers_addr = this.state.shipto_ohers_addr
        }

        formData.patient_information = {
          insurance_provider  : this.state.insurance_provider,
          policy_id           : this.state.insurance_id,
          phone               : phoneUtil.compose(this.state.insurancePhoneNumber),
          policy_group        : this.state.insurance_policy_group,
          employer            : this.state.insurance_employer,
          prescription_card   : this.state.prescription_card,
          carrier             : this.state.insurance_carrier,

          is_patient_eligible_for_medicare    : this.state.pat_is_eligible,
          name_of_insured                     : this.state.name_of_insured,
          will_there_be_access_to             : this.state.will_there_be_access_to,
          case_manager                        : this.state.case_manager,
          relationship                        : (this.state.relationship && this.state.relationship == 'self') ? 'self' : this.state.relationship_other_value
        };

        formData.provider_information = {
          state_license       : this.state.state_license,
          dea                 : this.state.dea,
          npi                 : this.state.npi,
          medicaid_upin       : this.state.medicaid_upin,
        };

        this.setState({showLoader:true});
        this.props.saveNewPrescription(formData);
      }
    }
  }

  hidePharmacyModal = () => {
    this.setState({showPharmacyModal: false,pharmacy_phone_render_flag:false});
  }

  showPharmacyModal = () => {
    document.getElementById('new-input').value = '';
    this.setState({
      pharmacy_name                   : '',
      pharmacy_fax                    : '',
      pharmacy_phone                  : '',
      pharmacy_name_class             : 'setting-input-box',
      pharmacy_phone_class            : 'setting-input-box',
      how_will_send_prescription      : 'email',
      defaultCountry                  : localStorage.getItem('cCode'),
      showPharmacyModal               : true,
      pharmacy_email                  : '',
      pharmacy_email_class            : 'setting-input-box',

      pharmacy_city                   : '',
      pharmacy_state                  : '',
      pharmacy_country                : '',
      pharmacy_address                : '',
      pharmacy_zipcode                : '',
      pharmacy_city_class             : 'setting-input-box',
      pharmacy_state_class            : 'setting-input-box',
      pharmacy_country_class          : 'setting-select-box',
      pharmacy_address_class          : 'setting-input-box',
      pharmacy_zipcode_class          : 'setting-input-box',
      pharmacy_phone_render_flag      : true,
      enter_details_manually          : false,
    });
  }

  savePharmacy = () => {
    if (isFormSubmit()) {
      let error = false;

      if (typeof this.state.pharmacy_name == undefined || this.state.pharmacy_name == null || this.state.pharmacy_name == '') {
          this.setState({pharmacy_name_class: 'setting-input-box field_error'})
          error = true;
      } else {
        if (this.state.pharmacy_name.trim() === '') {
          this.setState({pharmacy_name_class: 'setting-input-box field_error'})
          error = true;
        } else {
          this.setState({pharmacy_name_class: 'setting-input-box'})
        }
      }

      if (
        typeof this.state.pharmacy_phone == undefined ||
        this.state.pharmacy_phone == null ||
        this.state.pharmacy_phone == '' ||
        !phoneUtil.isPossible(phoneUtil.compose(this.state.pharmacy_phone))
      ) {
        error = true;
        this.setState({pharmacy_phone_error: true})
      }

      if (this.state.how_will_send_prescription && this.state.how_will_send_prescription === "email") {
        if (typeof this.state.pharmacy_email == undefined || this.state.pharmacy_email == null || this.state.pharmacy_email == '') {
            this.setState({pharmacy_email_class: 'setting-input-box field_error'})
            error = true;
        } else {
          if (this.state.pharmacy_email.trim() === '') {
            this.setState({pharmacy_email_class: 'setting-input-box field_error'})
            error = true;
          } else {
            if (!validateEmail(this.state.pharmacy_email.trim())) {
              this.setState({pharmacy_email_class: 'setting-input-box field_error'})
              error = true;
            } else {
              this.setState({pharmacy_email_class: 'setting-input-box'})
            }
          }
        }
      }

      if(this.state.enter_details_manually === true){
        if (typeof this.state.pharmacy_address == undefined || this.state.pharmacy_address == null || this.state.pharmacy_address == '') {
          error = true;
          this.setState({pharmacy_address_class: 'setting-input-box field_error'})
        } else if (this.state.pharmacy_address) {
            this.setState({pharmacy_address_class: 'setting-input-box'})
        }

        if (typeof this.state.pharmacy_city == undefined || this.state.pharmacy_city == null || this.state.pharmacy_city == '') {
          error = true;
          this.setState({pharmacy_city_class: 'setting-input-box field_error'})
        } else if (this.state.pharmacy_city) {
            this.setState({pharmacy_city_class: 'setting-input-box'})
        }

        if (typeof this.state.pharmacy_state == undefined || this.state.pharmacy_state == null || this.state.pharmacy_state == '') {
          error = true;
          this.setState({pharmacy_state_class: 'setting-input-box field_error'})
        } else if (this.state.pharmacy_state) {
            this.setState({pharmacy_state_class: 'setting-input-box'})
        }

        if (typeof this.state.pharmacy_country == undefined || this.state.pharmacy_country == null || this.state.pharmacy_country == '') {
          error = true;
          this.setState({pharmacy_country_class: 'setting-select-box field_error'})
        } else if (this.state.pharmacy_country) {
            this.setState({pharmacy_country_class: 'setting-select-box'})
        }

        if (typeof this.state.pharmacy_zipcode == undefined || this.state.pharmacy_zipcode == null || this.state.pharmacy_zipcode == '') {
          error = true;
          this.setState({pharmacy_zipcode_class: 'setting-input-box field_error'})
        } else if (this.state.pharmacy_zipcode) {
            this.setState({pharmacy_zipcode_class: 'setting-input-box'})
        }
      }

      if (error) {
        return false;
      } else {
        let formData = {
          name  : this.state.pharmacy_name,
          fax   : phoneUtil.compose(this.state.pharmacy_fax),
          phone : phoneUtil.compose(this.state.pharmacy_phone),
          email : this.state.pharmacy_email,
          how_will_send_prescription  : this.state.how_will_send_prescription,
          address : this.state.pharmacy_address,
          city : this.state.pharmacy_city,
          state : this.state.pharmacy_state,
          country : this.state.pharmacy_country,
          zipcode : this.state.pharmacy_zipcode,
        }

        this.setState({showPharmacyModal: false, showLoader:true, isRender: false});

        this.props.savePharmacy(formData);
      }

    }
  }

  phoneNumberChanged = (inputFiled, number) => {
    if (
      this.state?.[inputFiled + "_error"] &&
      phoneUtil.isPossible(phoneUtil.compose(number))
    ) {
      this.setState({
        [inputFiled + "_error"]: false,
      });
    }

    this.setState({ userChanged: true });

    this.setState({
      [inputFiled]: number,
    });
  }

  hideViewPrescriptionNew = () => {
    this.setState({showNewPrescriptionModalInViewMode: false});
  }

  downloadPrescriptionPDF = (proID) => {
    if (this.state.prescription_pdf_url) {
      window.open(this.state.prescription_pdf_url);
    } else {
      toast.error("Document not found.");
    }
  }

  generatePrescriptionPDF = (proID) => {
    if (proID) {
      this.setState({showLoader:true});
      this.props.generatePrescriptionPDF(proID);
    } else {
      toast.error("Procedure not found.");
    }
  }

  emailPrescriptionPDF = (proID) => {
    if (proID) {
      this.setState({showLoader:true});
      this.props.emailPrescriptionPDF(proID);
    } else {
      toast.error("Procedure not found.");
    }
  }

  faxPrescriptionPDF = (proID) => {
    if (proID) {
      this.setState({showLoader:true});
      this.props.faxPrescriptionPDF(proID);
    } else {
      toast.error("Procedure not found.");
    }
  }

  showPrescriptionNew = (proID, index, viewOnly) => {
    let prescriptionField = [];
    let prescription_note = '';
    let pharmacy_id       = '';
    let date_needed       = '';
    let ship_to           = this.state.ship_to;
    let shipto_ohers_addr = '';
    let pdf_url           = null;
    let pharmacy_fax_enabled    = 0;
    let pharmacy_email_enabled  = 0;
    let pharmacy_phone_form = '';
    let pharmacy_email_form = '';
    let pharmacy_address_form   = '';
    let pharmacy_city_form      = '';
    let pharmacy_state_form     = '';
    let pharmacy_country_form   = '';
    let pharmacy_zipcode_form   = '';

    if (index > -1) {
      if (this.state.clientData  && this.state.clientData.procedure_health &&  this.state.clientData.procedure_health.id == this.state.proID) {
        const procedure_prescription  = this.state.clientData.procedure_health['procedure_prescription'];
        pdf_url                       = this.state.clientData.procedure_health['prescription_pdf_url'];
        pharmacy_fax_enabled          = this.state.clientData.procedure_health['pharmacy_fax_enabled'];
        pharmacy_email_enabled        = this.state.clientData.procedure_health['pharmacy_email_enabled'];

        pharmacy_phone_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['phone'] : '';

        pharmacy_email_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['email'] : '';

        pharmacy_address_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['address'] : '';

        pharmacy_city_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['city'] : '';

        pharmacy_state_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['state'] : '';

        pharmacy_country_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['country'] : '';

        pharmacy_zipcode_form           = (procedure_prescription[0]['procedure_prescription_information'] && procedure_prescription[0]['procedure_prescription_information']['pharmacy']) ? procedure_prescription[0]['procedure_prescription_information']['pharmacy']['zipcode'] : '';

        if (procedure_prescription.length > 0) {

          prescription_note = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.prescription_note : '';
          pharmacy_id       = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.pharmacy_id : '';
          date_needed       = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.date_needed : '';
          ship_to           = (procedure_prescription[0].procedure_prescription_information) ? procedure_prescription[0].procedure_prescription_information.ship_to : this.state.ship_to;
          shipto_ohers_addr = (procedure_prescription[0].procedure_prescription_information && procedure_prescription[0].procedure_prescription_information.ship_to != 'prescriber' && procedure_prescription[0].procedure_prescription_information.ship_to != 'patient') ? procedure_prescription[0].procedure_prescription_information.ship_to : '';

          procedure_prescription.map((ppObj, ppIdx) => {
            let precription           = {};
            precription               = initNewPrescriptionField();
            precription.medicine_name = ppObj.medicine_name;
            precription.form          = ppObj.form;
            precription.strength      = ppObj.strength;
            precription.quantity      = ppObj.quantity;
            precription.frequency     = ppObj.frequency;
            precription.dosage        = ppObj.dosage;
            precription.refills       = ppObj.refills;
            prescriptionField.push(precription)
          })
        }
      }
    }

    if (prescriptionField.length === 0) {
      prescriptionField = [initNewPrescriptionField()]
    }

    let date_of_birth = (this.state.clientData.date_of_birth) ? this.state.clientData.date_of_birth : '';


    let signatureCanvasClass         = "signature-box sig-div";
    let signatureCanvasInputOut      = 'input-outer no-display';
    let signatureCanvasImgSrcClass   = 'img-src';
    let signatureUploadedUrl         = ''
    if(viewOnly){
      if(this.state.prescriber_signature){
        signatureCanvasClass         = "signature-box sig-div no-display";
        signatureCanvasInputOut      = 'input-outer';
        signatureCanvasImgSrcClass   = 'img-src';
        signatureUploadedUrl         = this.state.prescriber_signature_url;
      }
    } else {
      if(this.state.prescriber_signature){
        signatureUploadedUrl         = this.state.prescriber_signature_url;
      } else {
        signatureUploadedUrl         = this.state.login_user_signature_url;
      }
      if(signatureUploadedUrl){
        signatureCanvasClass         = "signature-box sig-div no-display";
        signatureCanvasInputOut      = 'input-outer';
        signatureCanvasImgSrcClass   = 'img-src';
      }
    }


    this.setState({
      showNewPrescriptionModal  : (viewOnly) ? false : true,
      showNewPrescriptionModalInViewMode : (viewOnly) ? true : false,
      newPrescriptionField      : prescriptionField,
      prescriptionValue         : [],
      prescriptionProcedureId   : proID,
      prescription_note         : prescription_note,
      pharmacy_id               : pharmacy_id,
      date_needed               : date_needed,
      ship_to                   : ship_to,
      shipto_ohers_addr         : shipto_ohers_addr,
      savedIndex                : index,
      prescription_pdf_url      : pdf_url,
      pharmacy_fax_enabled      : pharmacy_fax_enabled,
      pharmacy_email_enabled    : pharmacy_email_enabled,
      pharmacy_phone_form       : pharmacy_phone_form,
      pharmacy_email_form       : pharmacy_email_form,
      pharmacy_address_form     : pharmacy_address_form,
      pharmacy_city_form        : pharmacy_city_form,
      pharmacy_state_form       : pharmacy_state_form,
      pharmacy_country_form     : pharmacy_country_form,
      pharmacy_zipcode_form     : pharmacy_zipcode_form,
      pat_first_name            : (this.state.clientData.firstname) ? this.state.clientData.firstname : '',
      pat_last_name             : (this.state.clientData.lastname) ? this.state.clientData.lastname : '',

      date_of_birth             : nullToString(date_of_birth),
      gender                    : this.state.clientData.gender,
      pat_address               : nullToString(this.state.clientData.address_line_1),
      pat_city                  : nullToString(this.state.clientData.city),
      pat_state                 : nullToString(this.state.clientData.state),
      pat_zipcode               : nullToString(this.state.clientData.pincode),
      patEvePhoneNumber         : nullToString(this.state.clientData.phoneNumber),
      patDayPhoneNumber         : nullToString(this.state.clientData.phoneNumber_2),
      pat_email                 : nullToString(this.state.clientData.email),

      signatureCanvasClass        : signatureCanvasClass,
      signatureCanvasInputOut     : signatureCanvasInputOut,
      signatureCanvasImgSrcClass  : signatureCanvasImgSrcClass,
      signatureCanvasImageName    : '',
      signatureCanvasImageUrl     : '',
      signatureUploadedUrl        : signatureUploadedUrl,

    });

  }

  componentDidUpdate = (prevProps, prevState) => {
    if ( this.state.newPresData !== null && this.state.newPresData !== '' && this.state.newPresData !== prevState.newPresData && this.state.newPresStatus !== null && this.state.newPresStatus !== '' ) {
      this.setState({
        showLoader          : true,
        viewOnly            : true
      });
      this.props.getProcedurePrescription({patient_id:this.state.clientID, procedure_id:this.state.proID});
    }

    if ( this.state.generatePDFData !== null && this.state.generatePDFData !== '' && this.state.generatePDFData !== prevState.generatePDFData && this.state.generatePDFStatus !== null && this.state.generatePDFStatus !== '' ) {
      this.setState({
        showLoader          : true,
      });
      this.props.getProcedurePrescription({patient_id:this.state.clientID, procedure_id:this.state.proID});
    }
  }

  onChangeDatePicker = (value) => {
    this.setState(value)
  }


  selectLocation2 = (place) => {
    let zipcode ='';
    let city = '';
    let state = '';
    let country = '';
    let address = '';
    let phoneNumber = '';
    let name = '';

    let inputName = document.getElementById('new-input').value = '';
    if(place.address_components && place.name){
      // get name
      name = place.name

      // get address
      place.address_components.map((data,index) => {
        //get address
        if(data.types[0] == 'route' || data.types[0] == "street_number"){
          address = (data.long_name) ? (address + data.long_name + ' ') : '';
        }

        //get city
        if(data.types[0] == "locality" || data.types[0] == "postal_town"){
          city = (data.long_name) ? data.long_name : '';
        }
        if(city == ''){
          if(data.types[0] == 'administrative_area_level_2'){
            city = (data.long_name) ? data.long_name : '';

          }
        }

        //get state
        if(data.types[0] == "administrative_area_level_1"){
          state = (data.short_name) ? data.short_name : '';
        }

        //get country
        if(data.types[0] == "country"){
          country = (data.short_name) ? data.short_name : '';
        }

        //get zipcode
        if(data.types[0] == 'postal_code'){
          zipcode = (data.long_name) ? data.long_name : '';

        }
        if(zipcode == ''){
          if(data.types[0] == 'postal_code_prefix'){
            zipcode = (data.long_name) ? data.long_name : '';

          }
        }

      })

      // get phone-number
      if(place.international_phone_number){
        phoneNumber = place.international_phone_number.replace(/[ `~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi,'')
      }

      if(name){
        inputName = name;
      }
      document.getElementById('new-input').value = inputName;


      this.setState({pharmacy_phone_render_flag : false}, () => {
        this.setState({
          pharmacy_phone_render_flag          : true,
          pharmacy_name                       : name,
          pharmacy_address                    : address.trim(),
          pharmacy_city                       : city,
          pharmacy_state                      : state,
          pharmacy_country                    : country,
          pharmacy_zipcode                    : zipcode,
          pharmacy_phone                      : phoneNumber,
          pharmacy_name_class                 : 'setting-input-box',
          pharmacy_phone_class                : 'setting-input-box',
          pharmacy_city_class                 : 'setting-input-box',
          pharmacy_state_class                : 'setting-input-box',
          pharmacy_country_class              : 'setting-select-box',
          pharmacy_address_class              : 'setting-input-box',
          pharmacy_zipcode_class              : 'setting-input-box',
          how_will_send_prescription          : 'email',
          pharmacy_email                      : '',
          pharmacy_email_class                : 'setting-input-box',
          defaultCountry                      : localStorage.getItem('cCode'),
        })
      })

    }
  }

  dismissSignatureModal = () => {
    let signatureCanvasClass         = "signature-box sig-div";
    let signatureCanvasInputOut      = 'input-outer no-display';
    let signatureCanvasImgSrcClass   = 'img-src';
    let signatureUploadedUrl         = ''

    if(this.state.prescriber_signature){
      signatureUploadedUrl         = this.state.prescriber_signature_url;
    } else {
      signatureUploadedUrl         = this.state.login_user_signature_url;
    }
    if(signatureUploadedUrl){
      signatureCanvasClass         = "signature-box sig-div no-display";
      signatureCanvasInputOut      = 'input-outer';
      signatureCanvasImgSrcClass   = 'img-src';
    }

    this.setState({
        signatureCanvasInputOut      : signatureCanvasInputOut,
        signatureCanvasImgSrcClass   : signatureCanvasImgSrcClass,
        signatureCanvasClass         : signatureCanvasClass,
        signatureUploadedUrl         : signatureUploadedUrl,
        showSignatureCanvas          :false
    })
  }

  handleResetSignatureCanvas = () => {
      this.setState({
          isResetSignaturCanvasButton         : false,
          isClearSignaturCanvasButton         : true,
          signatureCanvasInputOut             : 'input-outer no-display',
          signatureCanvasImgSrcClass          : 'img-src no-display',
          signatureCanvasClass                : 'signature-box sig-div',
          signatureCanvasImageUrl             : '',
          signatureCanvasImageName            : '',
      })
  }

  handleClearSignatureCanvas = () => {
    if(this._refSignatureCanvasSketch){
      this._refSignatureCanvasSketch.clear();
      this._refSignatureCanvasSketch.setBackgroundFromDataUrl('');
    }
    this.setState({
        signatureCanvasInputOut              : 'input-outer no-display',
        signatureCanvasImgSrcClass           : 'img-src no-display',
        signatureCanvasClass                 : 'signature-box sig-div',
        showSignatureCanvas                  : true,
        signatureCanvasImageUrl              : '',
        signatureCanvasImageName             : '',
        isResetSignaturCanvasButton          : false,
        controlledValue                      : null,
        backgroundColor                      : 'transparent',
        fillWithBackgroundColor              : false,
    });
  }

  handleChangeSignatureCanvas = () => {
    if(this._refSignatureCanvasSketch){
      if (this._refSignatureCanvasSketch) {
        this._refSignatureCanvasSketch.clear();
        this._refSignatureCanvasSketch.setBackgroundFromDataUrl('');
      }
      this.setState({
          isSaveButton                     : true,
          isResetSignaturCanvasButton      : false,
          signatureCanvasClass             : 'signature-box sig-div',
          signatureCanvasInputOut          : 'input-outer no-display',
      })
    }
  }

  handleSaveSignatureCanvas = async () => {
    let returnFlag = false
    //if ( (this._refSignatureCanvasSketch && this._refSignatureCanvasSketch.toJSON().objects.length === 0 && this.state.signatureCanvasClass.indexOf('no-display') === -1) || (this.state.signatureCanvasClass.indexOf('no-display') > 0 && this.state.signatureCanvasImageUrl === '' ) ) {
    if (this._refSignatureCanvasSketch && this._refSignatureCanvasSketch.toJSON().objects.length === 0 && this._refSignatureCanvasSketch.toDataURL()) {
      toast.error(this.state.globalLang.validation_md_signature_required_if)
      return
    }
    this.setState({showLoader:true})
    await procedurePrescriptionInstance.post(process.env.REACT_APP_API_URL + "upload-and-save/signature", ({image_data : this._refSignatureCanvasSketch.toDataURL(), upload_type: 'signatures'})).then(response => {
          if ( response.data && response.data.status === 200 ) {
          //toast.success(this.state.globalLang['signature_'+response.data.message])
          this.setState({
            signatureCanvasImageUrl        : response.data.data.signature_url,
            signatureCanvasImageName       : response.data.data.file_name,
            login_user_signature_url       : response.data.data.signature_url,
            login_user_signature           : response.data.data.file_name,
            signatureUploadedUrl           : response.data.data.signature_url,
            signatureCanvasInputOut        : 'input-outer',
            signatureCanvasImgSrcClass     : 'img-src',
            signatureCanvasClass           : 'signature-box sig-div  no-display',
            isResetSignaturCanvasButton    : true,
            isClearSignaturCanvasButton    : false,
            showLoader                     : false,
            showSignatureCanvas            : false,
          })
          returnFlag = true
        } else {
          toast.error(this.state.globalLang[response.data.message])
          this.setState({showLoader:false})
          returnFlag = false
        }
      }).catch(error => {
          toast.error(this.state.globalLang.unknown_error_file_upload)
          this.setState({showLoader:false})
          returnFlag = false
      })
      return returnFlag
  }

  backToClientProfile = () => {
    //this.props.handleChildState({procedureIdForPopup:0, isShowProcedurePrescriptionsFlag: false, isViewOnlyProcedurePrescriptions: false})
    let returnUrl = '';

    if ( this.state.backURLType && this.state.backURLType === 'clients' ) {
      returnUrl = (this.props.match.params.type) ? "/" + this.state.backURLType + "/" + this.props.match.params.type + "/" + this.props.match.params.patientID  : "/" + this.state.backURLType
    }
    this.props.history.push(returnUrl);
  }

  render() {

    let patientName       = displayName(this.state.clientData);

    return (
      <div id="content">
          <div className="wide-popup">
            <div className="modal-blue-header">
              <a onClick={() => this.props.history.goBack()} className="popup-cross">×</a>
              {this.state.showLoader === false && <span className="popup-blue-name">{patientName + ` - Prescription/Pharmacy Intake Form`}</span>}
            </div>

            <div className="presc-container time-line">
              {/* SIGNATURE POPUP - START */}
              <div className={(this.state.showSignatureCanvas) ? 'modalOverlay showSignatureCanvas' : 'no-display showSignatureCanvas'}>
                <div className="small-popup-outer">
                  <div className="small-popup-header">
                    <div className="popup-name">{(this.state.signatureUploadedUrl) ? 'Edit Signature' : 'Add Signature'}</div>
                    <a onClick={this.dismissSignatureModal} className="small-cross">×</a>
                  </div>
                  <div className="juvly-container">
                      <div className={this.state.signatureCanvasClass} >
                      {((this.state.showSignatureCanvas) && this.state.signatureCanvasClass === 'signature-box sig-div') && <SketchField width='400px'
                       ref={c => (this._refSignatureCanvasSketch = c)}
                       height='200px'
                       tool={Tools.Pencil}
                       lineColor='black'
                       lineWidth={6}
                       />}
                      </div>
                      <div className="img-src" id="img-src">
                        <div className={this.state.signatureCanvasInputOut} style={{background: '#fff none repeat scroll 0 0'}}>
                          <img className=""  src={(this.state.signatureCanvasImageUrl) ? this.state.signatureCanvasImageUrl : this.state.login_user_signature_url}/>
                        </div>
                      </div>
                        <div className="img-src change-sig">
                          <div className="pull-left">
                            <button type="button"  onClick={this.handleClearSignatureCanvas} className={'new-white-btn no-margin'}>{'Clear'}</button>
                          </div>
                        {(this.state.isResetSignaturCanvasButton === -1) &&
                          <div className="pull-left">
                            <button type="button"  onClick={this.handleResetSignatureCanvas} className={'new-blue-btn'}>{'Reset'}</button>
                          </div>
                        }
                        <div className="pull-left">
                          <button type="button"  onClick={this.handleSaveSignatureCanvas} className={'new-blue-btn'}>{'Save Signature'}</button>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
              {/* SIGNATURE POPUP - END */}

            <div className={(this.state.showPharmacyModal === true ) ? 'modalOverlay' : ''}>
              <div className={(this.state.showPharmacyModal === true) ? 'small-popup-outer recieveProductPopup displayTable' : 'small-popup-outer recieveProductPopup no-display'}>
                <div className="small-popup-header">
                  <div className="popup-name">Add Pharmacy</div>
                  <a onClick={() => this.hidePharmacyModal()} className="small-cross">×</a>
                </div>
                <div className="small-popup-content no-scroll">
                  <div className="juvly-container no-padding-bottom">

                <div className="prescription-content">
                <div className="doc-section pharmacy-section">
                  <div className="col-xs-12 no-margin">
                        <div className="row prescription-row">

                          <div className="col-xs-12">
                            <div className="setting-field-outer m-b-0">
                              <div className="basic-checkbox-outer m-b-0 pull-right">
                                <input  className="basic-form-checkbox" name="enter_details_manually" type="checkbox" onChange={this.handleInputChange} value="enter_details_manually" checked={(this.state.enter_details_manually) ? 'checked' : false}/>
                                <label className="basic-form-text m-b-0" htmlFor="pref_pharmacy_email">Enter Details Manually</label>
                              </div>
                            </div>
                          </div>

                           <div className="col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">Name<span className="setting-require">*</span></div>
                              <div className={(this.state.enter_details_manually === false) ? "setting-input-outer relative" : "setting-input-outer relative no-display"}>
                                  <Autocomplete
                                    id='new-input'
                                    placeholder=''
                                    data-modal={'add_pharmacy'}
                                    className={this.state.pharmacy_name_class}
                                    onPlaceSelected={(place) => this.selectLocation2(place)}
                                    types={['establishment']}
                                    fields={['address_component','formatted_address', 'geometry','name', 'place_id','plus_code','type','formatted_phone_number','international_phone_number','opening_hours','website']}
                                  />
                                </div>
                                <div className={(this.state.enter_details_manually === true) ? "setting-input-outer relative" : "setting-input-outer relative no-display"}>
                                  <input name="pharmacy_name" value={this.state.pharmacy_name} className={this.state.pharmacy_name_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange} />
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="row prescription-row">

                          <div className="col-sm-6 col-xs-12">
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="setting-field-outer">
                                  <div className="new-field-label">Preferred Method To Send Prescription</div>
                                  <div className="setting-input-outer">
                                  </div>
                                </div>
                              </div>

                              {(this.state.how_will_send_prescription === "email") && <div className="col-xs-12">
                                <div className="setting-field-outer">
                                  <div className="new-field-label">Email{(this.state.how_will_send_prescription === "email") ? <span className="setting-require">*</span> : ''}</div>
                                  <div className="setting-input-outer">
                                    <input name="pharmacy_email" value={this.state.pharmacy_email} className={this.state.pharmacy_email_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange}/>
                                  </div>
                                </div>
                              </div>}
                            </div>
                          </div>

                          <div className="col-sm-6 col-xs-12">
                            <div className="row">
                              <div className="col-xs-12">
                                <div className="setting-field-outer">
                                  <div className="new-field-label">Phone<span className="setting-require">*</span></div>
                                  <div className="setting-input-outer">
                                    {this.state.pharmacy_phone_render_flag === true && (
                                      <InputPhone
                                        fullWidth
                                        variant="legacy"
                                        value={this.state.pharmacy_phone || ''}
                                        onChange={this.phoneNumberChanged.bind(this, 'pharmacy_phone')}
                                        isError={this.state.pharmacy_phone_error}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div className="col-xs-12">
                                <div className="setting-field-outer">
                                  <div className="new-field-label">Address{(this.state.enter_details_manually === true) && <span className="setting-require">*</span>}</div>
                                  <div className="setting-input-outer">
                                    <input name="pharmacy_address" value={this.state.pharmacy_address} className={this.state.pharmacy_address_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange}  disabled={(this.state.enter_details_manually === false) ? 'disabled' : false} />
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                        <div className="row prescription-row">

                          <div className="col-sm-6 col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">City{(this.state.enter_details_manually === true) && <span className="setting-require">*</span>}</div>
                              <div className="setting-input-outer">
                                <input name="pharmacy_city" value={this.state.pharmacy_city} className={this.state.pharmacy_city_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange}  disabled={(this.state.enter_details_manually === false) ? 'disabled' : false} />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">State{(this.state.enter_details_manually === true) && <span className="setting-require">*</span>}</div>
                              <div className="setting-input-outer">
                                <input name="pharmacy_state" value={this.state.pharmacy_state} className={this.state.pharmacy_state_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange}  disabled={(this.state.enter_details_manually === false) ? 'disabled' : false} />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">Country{(this.state.enter_details_manually === true) && <span className="setting-require">*</span>}</div>
                              <div className="setting-input-outer">
                                <select name="pharmacy_country" value={this.state.pharmacy_country} className={this.state.pharmacy_country_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange}  disabled={(this.state.enter_details_manually === false) ? 'disabled' : false} >
                                  <option value="">Select</option>
                                  {(this.state.countryList && this.state.countryList.length > 0) &&
                                    this.state.countryList.map((obj,idx) => {
                                      return (
                                        <option key={'countryList-'+idx} value={obj.country_code}>{obj.country_name}</option>
                                      )
                                    })
                                  }
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-6 col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">Zipcode{(this.state.enter_details_manually === true) && <span className="setting-require">*</span>}</div>
                              <div className="setting-input-outer">
                                <input name="pharmacy_zipcode" value={this.state.pharmacy_zipcode} className={this.state.pharmacy_zipcode_class} data-modal={'add_pharmacy'} type="text" autoComplete="off" onChange={this.handleInputChange}  disabled={(this.state.enter_details_manually === false) ? 'disabled' : false} />
                              </div>
                            </div>
                          </div>

                          </div>
                    </div>
                  </div>
                </div>

                  </div>
                </div>
                <div className="footer-static">
                  <a className="new-blue-btn" onClick={() => this.savePharmacy()}>{this.state.languageData.clientprofile_save}</a>
                </div>
              </div>
            </div>

            {<div className={(this.state.showNewPrescriptionModalInViewMode === true) ? 'displayBlock pres-view-mode' : 'no-display'}>
                <div className="juvly-container no-padding-bottom new-prescription-section">

                 <div className="row m-b-10 no-display">
                    <div className="col-xs-12">
                       <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Clinical Assessment</div>
                          <div className="setting-textarea-box auto-height">{this.state.prescription_note}</div>
                       </div>
                    </div>
                 </div>

                 <div className="prescriptionTitile">Pharmacy Information</div>


                 <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                       <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy</div>
                          <div className="setting-input-box">
                          {((this.state.clientData) && this.state.clientData.pharmacy_list && this.state.clientData.pharmacy_list.length > 0) &&
                            this.state.clientData.pharmacy_list.map((obj, idx) => {
                              if (obj.id == this.state.pharmacy_id) {
                                return obj.name;
                              }
                            })
                          }

                          </div>
                       </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy Phone</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_phone_form}
                      </div>
                    </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy Email</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_email_form}
                      </div>
                    </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy Address</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_address_form}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy City</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_city_form}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy State</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_state_form}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy Country</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_country_form}
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Pharmacy Zipcode</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pharmacy_zipcode_form}
                      </div>
                    </div>
                  </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12 calender">
                    <div className="setting-field-outer  m-b-15">
                      <div className="new-field-label">Date Needed </div>
                      <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true && this.state.date_needed) && showFormattedDate(this.state.date_needed)}</div>
                    </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-xs-12">
                    <div className="newInputFileldOuter m-t-0 preferMethodContact m-b-15">
                      <div className="new-field-label m-b-0">Ship To</div>
                      <div className="setting-input-box">
                      <div className="basic-checkbox-outer m-b-0">
                        {(this.state.ship_to && this.state.ship_to === 'prescriber') && 'Prescriber’s Office'}
                        {(this.state.ship_to && this.state.ship_to === 'patient') && 'Patient’s Home'}
                        {(this.state.ship_to && this.state.ship_to != 'prescriber' && this.state.ship_to != 'patient') && this.state.ship_to}
                      </div>
                      </div>
                    </div>
                  </div>
                 </div>


                 <div className="prescriptionTitile">Patient Information</div>

                   <div className="row m-b-30">
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">First Name</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_first_name}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Last Name</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_last_name}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">DOB</div>
                        <div className="setting-input-box m-b-0">
                          {(this.state.showNewPrescriptionModalInViewMode === true && this.state.date_of_birth) && format(this.state.date_of_birth, getDateFormat())}
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Gender</div>
                      <div className="setting-input-box m-b-0">
                        {(this.state.gender == 0) ? 'Male' : (this.state.gender == 1 ? 'Female' : 'Not Disclosed')}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Address </div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_address}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">City</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_city}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">State</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_state}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Zip code</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_zipcode}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Phone # (Daytime)</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.patDayPhoneNumber}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Phone # (Evening)</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.patEvePhoneNumber}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">E-mail Address</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pat_email}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Case Manager</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.case_manager}
                      </div>
                    </div>
                    </div>
                </div>

            <div className="row">
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">Insurance Provider</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.insurance_provider}</div>
             </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">ID #</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.insurance_id}</div>
             </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">Policy/Group #</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.insurance_policy_group}</div>
             </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">Phone</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.insurancePhoneNumber}</div>
             </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Patient is eligible for Medicare </div>
              <div className="setting-input-box m-b-0">
                {this.state.pat_is_eligible ? 'Yes' : 'No'}
              </div>
            </div>
            </div>
            <div className="col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Name of Insured</div>
              <div className="setting-input-box m-b-0">
                {this.state.name_of_insured}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">Employer</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.insurance_employer}</div>
             </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Relationship to Patient</div>
              <div className="setting-input-box m-b-0">
                {(this.state.relationship && this.state.relationship == 'self') ? 'Self' : this.state.relationship}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">Prescription Card</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && (this.state.prescription_card === "yes") ? "Yes" : "No"}</div>
             </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
             <div className="setting-field-outer m-b-15">
             <div className="new-field-label">Carrier</div>
             <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.insurance_carrier}</div>
             </div>
            </div>
            <div className="col-lg-3 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">WILL THERE BE ACCESS TO ANAPHYLACTIC MEDICATIONS AND OXYGEN AT THE ADMINISTRATION SITE?</div>
              <div className="setting-input-box m-b-0">
                {this.state.will_there_be_access_to}
              </div>
            </div>
            </div>

            </div>

            <div className="prescriptionTitile">Clinical Assessment</div>


            <div className="row">
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Patient is new to therapy</div>
              <div className="setting-input-box m-b-0">
                {this.state.pat_is_new_to_therapay ? 'Yes' : 'No'}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Patient is restarting therapy</div>
              <div className="setting-input-box m-b-0">
                {this.state.pat_is_restart_therapay ? 'Yes' : 'No'}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Patient is currently on therapy</div>
              <div className="setting-input-box m-b-0">
                {this.state.pat_is_on_therapay ? 'Yes' : 'No'}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Start date</div>
              <div className="setting-input-box m-b-0">
                {(this.state.showNewPrescriptionModalInViewMode === true && this.state.clinical_start_date) && showFormattedDate(this.state.clinical_start_date)}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Primary Diagnosis Code and Condition (ICD-10)</div>
              <div className="setting-input-box m-b-0">
                {this.state.primary_diagnosis_code}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Date of Diagnosis</div>
              <div className="setting-input-box m-b-0">
                {(this.state.showNewPrescriptionModalInViewMode === true && this.state.date_of_diagnosis) && showFormattedDate(this.state.date_of_diagnosis)}
              </div>
            </div>
            </div>
            <div className="col-lg-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Other Diagnosis/Conditions</div>
              <div className="setting-input-box m-b-0">
                {this.state.other_diagnosis_conditions}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Current Weight</div>
              <div className="setting-input-box m-b-0">
                {this.state.current_weight} {this.state.weight_type}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Date</div>
              <div className="setting-input-box m-b-0">
                {(this.state.showNewPrescriptionModalInViewMode === true && this.state.date_of_last_weight) && showFormattedDate(this.state.date_of_last_weight)}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Current Height</div>
              <div className="setting-input-box m-b-0">
                {this.state.current_height} {this.state.height_type}
              </div>
            </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Date</div>
              <div className="setting-input-box m-b-0">
                {(this.state.showNewPrescriptionModalInViewMode === true && this.state.last_date_of_height) && showFormattedDate(this.state.last_date_of_height)}
              </div>
            </div>
            </div>
            <div className="col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Other Therapies Tried & Failed</div>
              <div className="setting-input-box m-b-0">
                {this.state.other_therapies_tried_failed}
              </div>
            </div>
            </div>
            <div className="col-xs-12">
            <div className="setting-field-outer m-b-15">
              <div className="new-field-label">Allergies</div>
              <div className="setting-input-box m-b-0">
                {this.state.allergies}
              </div>
            </div>
            </div>
            </div>

            <div className="prescriptionTitile">Prescription Information</div>


                  <div className="table-responsive fixed-header-table orderInfoTable m-b-15">
                    <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="col-xs-3 table-updated-th p-r-0">Medication</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Form</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Strength</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Quantity</th>
                          <th className="col-xs-3 table-updated-th p-r-0">Directions/Frequency</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Dose</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Refills</th>
                          <th className="col-xs-1 table-updated-th p-r-0" />
                        </tr>
                      </thead>
                      <tbody className="ajax_body no-scroll">
                      { this.state.newPrescriptionField.map((obj,idx) => {
                        return (
                          <tr key={'prescription-row-view-'+idx} className="table-updated-tr">
                            <td className="col-xs-3 table-updated-td p-r-0">
                              {obj.medicine_name}
                            </td>
                            <td className="col-xs-1 table-updated-td p-r-0">
                              {obj.form}
                            </td>
                            <td className="col-xs-1 table-updated-td p-r-0">
                              {obj.strength}
                            </td>
                            <td className="col-xs-1 table-updated-td p-r-0">
                              {obj.quantity}
                            </td>
                            <td className="col-xs-3 table-updated-td p-r-0">
                              {obj.frequency}
                            </td>
                            <th className="col-xs-1 table-updated-th p-r-0">
                              {obj.dosage}
                            </th>
                            <td className="col-xs-1 table-updated-th p-r-0">
                              {obj.refills}
                            </td>
                            <td className="col-xs-1 table-updated-td p-r-0 orderInfoTotal"></td>
                          </tr>
                        )
                      })
                      }
                      </tbody>
                    </table>
                  </div>


                  <div className="prescriptionTitile">Prescriber Information</div>

                  <div className="row m-b-10">

                  <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">First Name </div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pro_firstname}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Last Name </div>
                      <div className="setting-input-box m-b-0">
                        {this.state.pro_lastname}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Practice/facility</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.userData && this.state.userData.account ? this.state.userData.account.name : ''}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Address</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_address}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">City</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_city}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">State </div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_state}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Zip code</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_zipcode}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Office contact</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.office_contact}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Phone</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_phone}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Fax</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_fax}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Email</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_email}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Best time to call</div>
                      <div className="setting-input-box m-b-0">
                        {this.state.prescriber_best_time_to_call}
                      </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                    <div className="setting-field-outer m-b-15">
                      <div className="new-field-label">Preferred method of contact</div>
                      <div className="setting-input-box m-b-0">
                        {(this.state.preferred_method_of_contact == 'email') ? 'Email' : (this.state.preferred_method_of_contact == 'phone' ? 'Phone' : '')}
                      </div>
                    </div>
                  </div>


                  <div className="col-lg-2 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">State License #</div>
                       <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.state_license}</div>
                     </div>
                   </div>

                   <div className="col-lg-2 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">DEA #</div>
                       <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.dea}</div>
                     </div>
                   </div>

                   <div className="col-lg-2 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">NPI #</div>
                       <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.npi}</div>
                     </div>
                   </div>

                   <div className="col-lg-3 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">Medicaid UPIN #</div>
                       <div className="setting-input-box">{(this.state.showNewPrescriptionModalInViewMode === true) && this.state.medicaid_upin}</div>
                     </div>
                   </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-sm-6 col-xs-12">
                <div className="setting-field-outer m-b-15">
                  <div className="new-field-label">Substitution permitted</div>
                  <div className="setting-input-box m-b-0">
                    {(this.state.substitution_permitted == 'yes') ? 'Yes' : 'No'}
                  </div>
                </div>
              </div>
            </div>

              {/*  DISPLAY SIGNATURE - START */}
                {(this.state.signatureUploadedUrl) &&
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="div-consent-signature patient-signature pull-right">
                          <div>
                          <img src={this.state.signatureUploadedUrl} className="consent-signature imp-patient-signature pull-right" />
                          </div>
                          <label className="signed-label text-right pull-left full-width">({'Prescriber Signature'})</label>
                        </div>
                    </div>
                  </div>
                }
              {/*  DISPLAY SIGNATURE - END */}


                </div>
                <div className="prescriberFooter">
                  {(this.state.prescription_pdf_url) ? <a className="new-blue-btn" onClick={() => this.downloadPrescriptionPDF(this.state.prescriptionProcedureId)}>Preview</a> : ''}
                  {(this.state.prescription_pdf_url) ? <a className="new-blue-btn" onClick={() => this.downloadPrescriptionPDF(this.state.prescriptionProcedureId)}>Download</a> : ''}

                  {(this.state.pharmacy_email_enabled) ? <a className="new-blue-btn" onClick={() => this.emailPrescriptionPDF(this.state.prescriptionProcedureId)}>Send email to pharmacy</a> : ''}
                  {/*(this.state.pharmacy_fax_enabled) ? <a className="new-blue-btn" onClick={() => this.faxPrescriptionPDF(this.state.prescriptionProcedureId)}>Send fax to pharmacy</a> : ''*/}
                  <a className="new-blue-btn" onClick={() => this.showPrescriptionNew(this.state.proID, this.state.index, false)}>Edit</a>
                </div>
            </div>}


              {<div className={(this.state.showNewPrescriptionModal  === true) ? 'displayBlock pres-edit-mode' : 'no-display'}>

                  <div className="juvly-container no-padding-bottom new-prescription-section">

                    <div className="prescriptionTitile">Pharmacy Information</div>


                    <div className="row">
                      <div className="col-lg-2 col-md-4 col-sm-4 col-xs-12">
                         <div className="setting-field-outer m-b-15">
                            <div className="new-field-label">Pharmacy<span className="setting-require">*</span></div>
                            <select className={this.state.pharmacy_id_class} name='pharmacy_id' value={this.state.pharmacy_id} onChange={this.handleInputChange}>
                              <option value=''>Select</option>
                              {((this.state.clientData) && this.state.clientData.pharmacy_list && this.state.clientData.pharmacy_list.length > 0) &&
                                this.state.clientData.pharmacy_list.map((obj, idx) => {
                                  return (
                                    <option key={'pharmacy_list-'+obj.id} value={obj.id} >{obj.name}</option>
                                  )
                                })
                              }
                            </select>
                         </div>
                      </div>
                      <div className="col-lg-1 col-md-2 col-sm-2 col-xs-11 p-r-0">
                         <a onClick={() => this.showPharmacyModal()} className="xs-blue-btn m-t-20 m-l-0 m-b-10 add-pharmacy">Add New</a>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy Phone</div>
                          <div className="setting-field-outer m-b-0">
                            {this.state.isRender && (
                              <InputPhone
                                fullWidth
                                variant="legacy"
                                value={this.state.pharmacy_phone_form || ''}
                                onChange={this.phoneNumberChanged.bind(this, 'pharmacy_phone_form')}
                                isError={this.state.pharmacy_phone_form_error}
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy Email</div>
                          <input className={this.state.pharmacy_email_form_class} type="text" name="pharmacy_email_form" value={this.state.pharmacy_email_form} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy Address</div>
                          <input className={this.state.pharmacy_address_form_class} type="text" name="pharmacy_address_form" value={this.state.pharmacy_address_form} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy City</div>
                          <input className={this.state.pharmacy_city_form_class} type="text" name="pharmacy_city_form" value={this.state.pharmacy_city_form} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy State</div>
                          <input className={this.state.pharmacy_state_form_class} type="text" name="pharmacy_state_form" value={this.state.pharmacy_state_form} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy Country</div>
                          <input className={this.state.pharmacy_country_form_class} type="text" name="pharmacy_country_form" value={this.state.pharmacy_country_form} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Pharmacy Zipcode</div>
                          <input className={this.state.pharmacy_zipcode_form_class} type="text" name="pharmacy_zipcode_form" value={this.state.pharmacy_zipcode_form} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12 calender">
                        <div className="setting-field-outer  m-b-15">
                          <div className="new-field-label">Date Needed <span className="setting-require">*</span></div>
                          <CustomDatePicker
                            name="date_needed"
                            onChangeDatePicker={this.onChangeDatePicker}
                            minDate={new Date()}
                            maxDate={new Date(moment().add(10, "years").toDate())}
                            value={(this.state.date_needed) ? this.state.date_needed : null}
                            class={this.state.date_needed_class}
                            format={dateFormatPicker()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-6 col-sm-7  col-xs-12 p-r-0">
                        <div className="newInputFileldOuter m-t-5 preferMethodContact m-b-15">
                          <div className="new-field-label m-b-0">Ship To</div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input id="Prescriber’s Office" className="basic-form-checkbox" name="ship_to" type="radio" onChange={this.handleInputChange} value={`prescriber`} checked={this.state.ship_to === 'prescriber'}/>
                            <label className="basic-form-text" htmlFor="Prescriber’s Office">Prescriber’s Office</label>
                          </div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input id="Patient’s Home" className="basic-form-checkbox" name="ship_to" type="radio" onChange={this.handleInputChange} value={`patient`} checked={this.state.ship_to === 'patient'}/>
                            <label className="basic-form-text" htmlFor="Patient’s Home">Patient’s Home</label>
                          </div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input id="Other1" className="basic-form-checkbox" name="ship_to" type="radio" onChange={this.handleInputChange} value={`other`} checked={this.state.ship_to != 'prescriber' && this.state.ship_to != 'patient'}/>
                            <label className="basic-form-text" htmlFor="Other1">Other</label>
                          </div>
                        </div>
                      </div>
                      {(this.state.ship_to && this.state.ship_to != 'prescriber' && this.state.ship_to != 'patient') && <div className="col-lg-5 col-md-6 col-sm-5 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <input className={this.state.shipto_ohers_addr_class} type="text" name="shipto_ohers_addr" value={this.state.shipto_ohers_addr} autoComplete="off" onChange={this.handleInputChange}/>
                        </div>
                      </div>}
                   </div>



                   <div className="prescriptionTitile">Patient Information</div>
                   <div className="row">
                   <div className="col-lg-3 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">First Name<span className="setting-require">*</span></div>
                       <div className="setting-field-outer m-b-0">
                         <input className={this.state.patFirstNameClass} type="text" name="pat_first_name" value={this.state.pat_first_name} autoComplete="off" onChange={this.handleInputChange} />
                       </div>
                     </div>
                   </div>

                   <div className="col-lg-3 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">Last Name<span className="setting-require">*</span></div>
                       <div className="setting-field-outer m-b-0">
                         <input className={this.state.patLastNameClass} type="text" name="pat_last_name" value={this.state.pat_last_name} autoComplete="off" onChange={this.handleInputChange} />
                       </div>
                     </div>
                   </div>

                   <div className="col-lg-3 col-sm-6 col-xs-12">
                     <div className="setting-field-outer m-b-15">
                       <div className="new-field-label">DOB<span className="setting-require">*</span></div>
                       <CustomDatePicker
                         name="date_of_birth"
                         onChangeDatePicker={this.onChangeDatePicker}
                         minDate={new Date(moment().subtract(100, "years").toDate())}
                         maxDate={new Date()}
                         value={this.state.date_of_birth}
                         class={this.state.dobClass}
                         format={dateFormatPicker()}
                       />
                     </div>
                   </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="newInputFileldOuter m-t-5 preferMethodContact m-b-20">
                        <div className="new-field-label m-b-0">Gender</div>
                        <div className="setting-field-outer m-b-0">
                        <select className="setting-select-box" name='gender' value={this.state.gender} onChange={this.handleInputChange}>
                          <option value="2">Not Disclosed</option>
                          <option value="0">Male</option>
                          <option value="1">Female</option>
                        </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Address</div>
                          <input className="setting-input-box" type="text" name="pat_address" value={this.state.pat_address} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">City</div>
                          <input className="setting-input-box" type="text" name="pat_city" value={this.state.pat_city} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">State</div>
                          <input className="setting-input-box" type="text" name="pat_state" value={this.state.pat_state} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Zip code</div>
                          <input className="setting-input-box" type="text" name="pat_zipcode" value={this.state.pat_zipcode} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Phone # (Daytime)</div>
                        <div className="setting-input-outer">
                        {this.state.isRender && (
                          <InputPhone
                            fullWidth
                            variant="legacy"
                            value={this.state.patDayPhoneNumber || ''}
                            onChange={this.phoneNumberChanged.bind(this, 'patDayPhoneNumber')}
                            isError={this.state.patDayPhoneNumber_error}
                          />
                        )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Phone # (Evening)</div>
                        <div className="setting-input-outer">
                        {this.state.isRender && (
                          <InputPhone
                            fullWidth
                            variant="legacy"
                            value={this.state.patEvePhoneNumber || ''}
                            onChange={this.phoneNumberChanged.bind(this, 'patEvePhoneNumber')}
                            isError={this.state.patEvePhoneNumber_error}
                          />
                        )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">E-mail Address</div>
                          <input className="setting-input-box" type="text" name="pat_email" value={this.state.pat_email} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Case Manager</div>
                          <input className="setting-input-box" type="text" name="case_manager" value={this.state.case_manager} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>
                    </div>



                    <div className="juvly-subtitle m-b-20 m-t-0">&nbsp;</div>

                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Insurance Provider</div>
                          <input className={this.state.insuranceProviderClass} type="text" name="insurance_provider" value={this.state.insurance_provider} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">ID #</div>
                          <input className={this.state.insuranceIDClass} type="text" name="insurance_id" value={this.state.insurance_id} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Policy/Group #</div>
                          <input className={this.state.insurancePolicyGroupClass} type="text" name="insurance_policy_group" value={this.state.insurance_policy_group} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Phone</div>
                          <div className="setting-input-outer">
                          {this.state.isRender && (
                            <InputPhone
                              fullWidth
                              variant="legacy"
                              value={this.state.insurancePhoneNumber || ''}
                              onChange={this.phoneNumberChanged.bind(this, 'insurancePhoneNumber')}
                              isError={this.state.insurancePhoneNumber_error}
                            />
                          )}
                          </div>
                        </div>
                      </div>


                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Patient is eligible for Medicare</div>
                            <div className="basic-checkbox-outer m-b-0">
                              <input id="is_elibible" className="basic-form-checkbox m-t-0" name="pat_is_eligible" type="checkbox" onChange={this.handleInputChange} checked={(this.state.pat_is_eligible) ? 'checked' : false}/>
                              <label className="basic-form-text m-b-0" htmlFor="is_elibible">Yes</label>
                            </div>
                        </div>
                      </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="setting-field-outer m-b-15">
                            <div className="new-field-label">Name of Insured</div>
                              <input className="setting-input-box" type="text" name="name_of_insured" value={this.state.name_of_insured} autoComplete="off" onChange={this.handleInputChange} />
                          </div>
                        </div>


                      <div className="col-lg-3  col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Employer</div>
                          <input className="setting-input-box" type="text" name="insurance_employer" value={this.state.insurance_employer} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-3 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Relationship to Patient</div>

                          <div className="basic-checkbox-outer m-b-0"><input id="self" className="basic-form-checkbox" name="relationship" type="radio" onChange={this.handleInputChange} value="self" checked={this.state.relationship === "self"}/><label className="basic-form-text m-b-0" htmlFor="self">Self</label></div>

                          <div className="basic-checkbox-outer m-b-0"><input id="other" className="basic-form-checkbox" name="relationship" type="radio" onChange={this.handleInputChange} value="other" checked={this.state.relationship != "self"}/><label className="basic-form-text m-b-0" htmlFor="other">Other</label></div>

                        </div>
                      </div>

                      {this.state.relationship != "self" && <div className="col-lg-3 col-sm-9 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">&nbsp;</div>
                          <input className="setting-input-box" type="text" name="relationship_other_value" value={this.state.relationship} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>}

                      <div className="col-lg-3 col-sm-3 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Prescription Card</div>
                          <div className="basic-checkbox-outer m-b-0"><input id="radio1" className="basic-form-checkbox" name="prescription_card" type="radio" onChange={this.handleInputChange} value="yes" checked={this.state.prescription_card === "yes"}/><label className="basic-form-text m-b-0" htmlFor="radio1">Yes</label></div>
                          <div className="basic-checkbox-outer m-b-0"><input id="radio2" className="basic-form-checkbox" name="prescription_card" type="radio" onChange={this.handleInputChange} value="no" checked={this.state.prescription_card === "no"}/><label className="basic-form-text m-b-0" htmlFor="radio2">No</label></div>
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Carrier</div>
                          <input className={this.state.insuranceCarrierClass} type="text" name="insurance_carrier" value={this.state.insurance_carrier} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>



                      <div className="col-lg-3 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Will there be access to anaphylactic medications and oxygen at the administration site?</div>
                            <input className="setting-input-box" type="text" name="will_there_be_access_to" value={this.state.will_there_be_access_to} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>

                    </div>


                    <div className="prescriptionTitile">Clinical Assessment</div>


                    <div className="row">
                      <div className="col-lg-3  col-xs-12">
                        <div className="setting-field-outer m-b-0">
                          <div className="new-field-label m-b-0">&nbsp;</div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input id="is_new" className="basic-form-checkbox m-t-0" name="pat_is_new_to_therapay" type="checkbox" onChange={this.handleInputChange} checked={(this.state.pat_is_new_to_therapay) ? 'checked' : false}/>
                            <label className="basic-form-text m-b-0" htmlFor="is_new">Patient is new to therapy</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3  col-xs-12">
                        <div className="setting-field-outer m-b-0">
                          <div className="new-field-label m-b-0">&nbsp;</div>
                          <div className="basic-checkbox-outer m-b-0">
                          <input id="is_restart" className="basic-form-checkbox m-t-0" name="pat_is_restart_therapay" type="checkbox" onChange={this.handleInputChange} checked={(this.state.pat_is_restart_therapay) ? 'checked' : false}/>
                          <label className="basic-form-text m-b-0" htmlFor="is_restart">Patient is restarting therapy</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3  col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label m-b-0">&nbsp;</div>
                          <div className="basic-checkbox-outer m-b-0">
                          <input id="is_on_therapy" className="basic-form-checkbox m-t-0" name="pat_is_on_therapay" type="checkbox" onChange={this.handleInputChange} checked={(this.state.pat_is_on_therapay) ? 'checked' : false}/>
                          <label className="basic-form-text m-b-0" htmlFor="is_on_therapy">Patient is currently on therapy</label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Start date</div>
                          <CustomDatePicker
                            name="clinical_start_date"
                            onChangeDatePicker={this.onChangeDatePicker}
                            minDate={new Date(moment().subtract(100, "years").toDate())}
                            maxDate={new Date(moment().add(100, "years").toDate())}
                            value={this.state.clinical_start_date}
                            class="setting-input-box"
                            format={dateFormatPicker()}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Primary Diagnosis Code and Condition (ICD-10)</div>
                            <input className="setting-input-box" type="text" name="primary_diagnosis_code" value={this.state.primary_diagnosis_code} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Date of Diagnosis</div>
                          <CustomDatePicker
                            name="date_of_diagnosis"
                            onChangeDatePicker={this.onChangeDatePicker}
                            minDate={new Date(moment().subtract(100, "years").toDate())}
                            maxDate={new Date(moment().add(100, "years").toDate())}
                            value={this.state.date_of_diagnosis}
                            class="setting-input-box"
                            format={dateFormatPicker()}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Other Diagnosis/Conditions</div>
                            <input className="setting-input-box" type="text" name="other_diagnosis_conditions" value={this.state.other_diagnosis_conditions} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Current Weight</div>
                          <div className="row">
                            <div className="col-xs-6">
                              <input className="setting-input-box" type="text" name="current_weight" value={this.state.current_weight} autoComplete="off" onChange={this.handleInputChange} />
                            </div>
                            <div className="col-xs-6">
                                <div className="basic-checkbox-outer m-b-0">
                                  <input id="lb" className="basic-form-checkbox m-r-5" name="weight_type" type="radio" onChange={this.handleInputChange} value={`lb`} checked={this.state.weight_type === 'lb'}/>
                                  <label className="basic-form-text m-r-5 m-b-0" htmlFor="lb">lb</label>
                                </div>
                                <div className="basic-checkbox-outer m-b-0">
                                  <input id="kg" className="basic-form-checkbox m-r-5" name="weight_type" type="radio" onChange={this.handleInputChange} value={`kg`} checked={this.state.weight_type === 'kg'}/>
                                  <label className="basic-form-text m-r-5 m-b-0" htmlFor="kg">Kg</label>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Date</div>
                            <CustomDatePicker
                              name="date_of_last_weight"
                              onChangeDatePicker={this.onChangeDatePicker}
                              minDate={new Date(moment().subtract(100, "years").toDate())}
                              maxDate={new Date(moment().add(100, "years").toDate())}
                              value={this.state.date_of_last_weight}
                              class="setting-input-box"
                              format={dateFormatPicker()}
                            />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Current Height</div>
                          <div className="row">
                            <div className="col-xs-6">
                              <input className="setting-input-box" type="text" name="current_height" value={this.state.current_height} autoComplete="off" onChange={this.handleInputChange} />
                            </div>
                            <div className="col-xs-6">
                                <div className="basic-checkbox-outer m-b-0">
                                  <input id="in" className="basic-form-checkbox m-r-5" name="height_type" type="radio" onChange={this.handleInputChange} value={`in`} checked={this.state.height_type === 'in'}/>
                                  <label className="basic-form-text m-r-5 m-b-0" htmlFor="in">In</label>
                                </div>
                                <div className="basic-checkbox-outer m-b-0">
                                  <input id="cm" className="basic-form-checkbox m-r-5" name="height_type" type="radio" onChange={this.handleInputChange} value={`cm`} checked={this.state.height_type === 'cm'}/>
                                  <label className="basic-form-text m-r-5 m-b-0" htmlFor="cm">Cm</label>
                                </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Date</div>
                            <CustomDatePicker
                              name="last_date_of_height"
                              onChangeDatePicker={this.onChangeDatePicker}
                              minDate={new Date(moment().subtract(100, "years").toDate())}
                              maxDate={new Date(moment().add(100, "years").toDate())}
                              value={this.state.last_date_of_height}
                              class="setting-input-box"
                              format={dateFormatPicker()}
                            />
                        </div>
                      </div>
                    </div>


                    <div className="row">
                      <div className="col-xs-12">
                        <div className="setting-field-outer m-b-15 m-t-5">
                          <div className="basic-form-text relative"><input id="other_therapies_tried_failed" className="basic-form-checkbox m-t-0" name="pat_is_other_tried" type="checkbox" onChange={this.handleInputChange} checked={(this.state.pat_is_other_tried) ? 'checked' : false}/>
                          Other Therapies Tried & Failed (Please List)</div>
                          {(this.state.pat_is_other_tried) && <div className="Therapies m-t-10">
                            <input className="setting-input-box" type="text" name="other_therapies_tried_failed" value={this.state.other_therapies_tried_failed} autoComplete="off" onChange={this.handleInputChange} />
                          </div>}
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Allergies</div>
                            <input className="setting-input-box" type="text" name="allergies" value={this.state.allergies} autoComplete="off" onChange={this.handleInputChange} />
                        </div>
                      </div>
                  </div>


                  <div className="prescriptionTitile">Prescription Information</div>

                  <div className="table-responsive fixed-header-table orderInfoTable m-b-15">
                    <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="col-xs-3 table-updated-th p-r-0">Medication</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Form</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Strength</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Quantity</th>
                          <th className="col-xs-3 table-updated-th p-r-0">Directions/Frequency</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Dose</th>
                          <th className="col-xs-1 table-updated-th p-r-0">Refills</th>
                          <th className="col-xs-1 table-updated-th p-r-0" />
                        </tr>
                      </thead>
                      <tbody className="ajax_body no-scroll">
                      { this.state.newPrescriptionField.map((obj,idx) => {
                        return (
                          <tr key={'prescription-row-'+idx} className="table-updated-tr">
                            <td className="col-xs-3 table-updated-td p-r-0">
                              <div className="setting-input-outer">
                                <input type="text" autoComplete="off" data-index={idx} data-modal={'prescriptionNew'} className={obj.medicationClass} name="medicine_name" onChange={this.handleInputChange} value={obj.medicine_name} placeholder="Medication"/>
                              </div>
                            </td>
                            <td className="col-xs-1 table-updated-td p-r-0">
                                <input data-index={idx} autoComplete="off" data-modal={'prescriptionNew'} type="text" className={obj.formClass} name="form" onChange={this.handleInputChange} value={(obj.form) ? obj.form : ''} placeholder="Form"/>
                            </td>
                            <td className="col-xs-1 table-updated-td p-r-0"><input data-index={idx} data-modal={'prescriptionNew'} type="text"  className={obj.strengthClass} autoComplete="off" name="strength" onChange={this.handleInputChange} value={(obj.strength) ? obj.strength : ''} placeholder="Strength"/></td>
                            <td className="col-xs-1 table-updated-td p-r-0"><input data-index={idx} data-modal={'prescriptionNew'} type="text"  className={obj.quantityClass} autoComplete="off" name="quantity" onChange={this.handleInputChange} value={(obj.quantity) ? obj.quantity : ''} placeholder="Quantity"/></td>
                            <td className="col-xs-3 table-updated-td p-r-0"><input data-index={idx} data-modal={'prescriptionNew'} type="text" className={obj.directionsClass} autoComplete="off" name="frequency" onChange={this.handleInputChange} value={(obj.frequency) ? obj.frequency : ''} placeholder="Directions/Frequency"/></td>
                            <td className="col-xs-1 table-updated-th p-r-0"><input data-index={idx} data-modal={'prescriptionNew'} type="text" className={obj.doseClass} autoComplete="off" name="dosage" onChange={this.handleInputChange} value={(obj.dosage) ? obj.dosage : ''} placeholder="Dose"/></td>
                            <td className="col-xs-1 table-updated-th p-r-0"><input data-index={idx} data-modal={'prescriptionNew'} type="text" className={obj.refillsClass} autoComplete="off" name="refills" onChange={this.handleInputChange} value={(obj.refills) ? obj.refills : ''} placeholder="Refills"/></td>
                            {(idx == 0) ? <td className="col-xs-1 table-updated-td p-r-0 orderInfoTotal">
                              <a className="easy-link p-t-5  p-r-0" onClick={() => this.addNewPrescription()}>
                                <i className="fa fa-plus m-r-5 m-t-2"></i>
                              </a>
                            </td> : <td className="col-xs-1 table-updated-td p-r-0 orderInfoTotal">
                              <a className="easy-link p-t-5  p-r-0" onClick={() => this.removeNewPrescription(idx)}>
                                <i className="fa fa-minus m-r-5 m-t-2"></i>
                              </a>
                            </td>}
                          </tr>
                        )
                      })
                      }
                      </tbody>
                    </table>
                  </div>

                  <div className="prescriptionTitile">Prescriber Information</div>

                    <div className="row">

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15 read-only">
                        <div className="new-field-label">First Name</div>
                        {this.state.pro_firstname}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15 read-only">
                        <div className="new-field-label">Last Name</div>
                        {this.state.pro_lastname}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15 read-only">
                        <div className="new-field-label">Practice/facility</div>
                        {this.state.userData && this.state.userData.account ? this.state.userData.account.name : ''}
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Address<span className="setting-require">*</span></div>
                        <input className={this.state.prescriberAddressClass} type="text" name="prescriber_address" value={this.state.prescriber_address} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">City<span className="setting-require">*</span></div>
                        <input className={this.state.prescriberCityClass} type="text" name="prescriber_city" value={this.state.prescriber_city} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">State<span className="setting-require">*</span></div>
                        <input className={this.state.prescriberStateClass} type="text" name="prescriber_state" value={this.state.prescriber_state} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Zip code</div>
                        <input className="setting-input-box" type="text" name="prescriber_zipcode" value={this.state.prescriber_zipcode} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Office contact</div>
                        <input className="setting-input-box" type="text" name="office_contact" value={this.state.office_contact} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Phone<span className="setting-require">*</span></div>
                        <div className="setting-input-outer">
                        {this.state.isRender && (
                          <InputPhone
                            fullWidth
                            variant="legacy"
                            value={this.state.prescriber_phone || ''}
                            onChange={this.phoneNumberChanged.bind(this, 'prescriber_phone')}
                            isError={this.state.prescriber_phone_error}    
                          />
                        )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Fax</div>
                        <div className="setting-input-outer">
                        {this.state.isRender && (
                          <InputPhone
                            fullWidth
                            variant="legacy"
                            value={this.state.prescriber_fax || ''}
                            onChange={this.phoneNumberChanged.bind(this, 'prescriber_fax')}
                            isError={this.state.prescriber_fax_error}
                          />
                        )}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Email<span className="setting-require">*</span></div>
                        <input className={this.state.proEmailClass} type="text" name="prescriber_email" value={this.state.prescriber_email} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Best time to call</div>
                        <input className="setting-input-box" type="text" name="prescriber_best_time_to_call" value={this.state.prescriber_best_time_to_call} autoComplete="off" onChange={this.handleInputChange} />
                      </div>
                    </div>

                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <div className="setting-field-outer m-b-15">
                        <div className="new-field-label">Preferred method of contact</div>
                        <div className="basic-checkbox-outer m-b-0"><input id="pref_email" className="basic-form-checkbox" name="preferred_method_of_contact" type="radio" onChange={this.handleInputChange} value="email" checked={this.state.preferred_method_of_contact === "email"}/><label className="basic-form-text m-b-0" htmlFor="pref_email">Email</label></div>

                        <div className="basic-checkbox-outer m-b-0"><input id="pref_phone" className="basic-form-checkbox" name="preferred_method_of_contact" type="radio" onChange={this.handleInputChange} value="phone" checked={this.state.preferred_method_of_contact === "phone"}/><label className="basic-form-text m-b-0" htmlFor="pref_phone">Phone</label></div>
                      </div>
                    </div>



                      <div className="col-lg-2 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">State License #</div>
                          <input name="state_license" id="state_license" className={this.state.state_licenseClass} value={this.state.state_license} onChange={this.handleInputChange} type="text" autoComplete="off" />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">DEA #</div>
                          <input name="dea" id="dea" className={this.state.deaClass} value={this.state.dea} onChange={this.handleInputChange} type="text" autoComplete="off" />
                        </div>
                      </div>

                      <div className="col-lg-2 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">NPI #</div>
                          <input name="npi" id="npi" className={this.state.npiClass} type="text" value={this.state.npi} onChange={this.handleInputChange} autoComplete="off" />
                        </div>
                      </div>

                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-15">
                          <div className="new-field-label">Medicaid UPIN #</div>
                          <input name="medicaid_upin" id="medicaid_upin" className={this.state.medicaid_upinClass} type="text" value={this.state.medicaid_upin} onChange={this.handleInputChange} autoComplete="off" />
                        </div>
                      </div>
                  </div>

                  <div className="row">
                      <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="setting-field-outer m-b-30">
                          <div className="new-field-label">Substitution permitted</div>
                          <select className="setting-select-box" name='substitution_permitted' value={this.state.substitution_permitted} onChange={this.handleInputChange}>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                  </div>

                {/*  DISPLAY SIGNATURE - START */}
                  <div className="row">
                    <div className="col-xs-12">
                      <div className="pull-right sign-box-outer profile-signature  profile-signature-block">
                        <div className={this.state.signatureCanvasClass} ></div>
                        <div className={this.state.signatureCanvasImgSrcClass} >
                          <div className={this.state.signatureCanvasInputOut} style={{background: '#fff none repeat scroll 0 0'}}>
                            <img className="signature_image" src={this.state.signatureUploadedUrl}/>
                          </div>
                        </div>
                        { /*(this.state.loggedInUserId > 0 && this.state.procedureProviderID > 0 && this.state.loggedInUserId === this.state.procedureProviderID) && */}
                          <div className="img-src change-sig">
                            <div className="pull-left">
                              <a  className="sign-link" id="change" onClick={() => this.handleClearSignatureCanvas()}><i className="fa fa-pencil-alt"></i></a>
                            </div>
                          </div>


                        <div className="sign-switch-outer">
                          <span className="setting-custom-switch pull-right">
                            <span className="switch-text full-width text-center">{(this.state.signatureUploadedUrl) ? 'Prescriber Signature' : 'Prescriber Signature'}</span>
                          </span>
                        </div>

                      </div>
                    </div>
                  </div>
                {/*  DISPLAY SIGNATURE - END */}



                  </div>
                  <div className="prescriberFooter">
                    {(this.state.viewOnly == 'true' || this.state.viewOnly == true) ? <a className="new-blue-btn" onClick={() => this.showPrescriptionNew(this.state.prescriptionProcedureId, this.state.savedIndex, true)}>Back</a> : ''}
              			<a className="new-blue-btn" onClick={() => this.saveNewProcedurePrescription()}>{this.state.languageData.clientprofile_save}</a>
              		</div>
              </div>}

            </div>


            <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock position_fixed' : 'new-loader text-left'}>
              <div className="loader-outer">
                <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
                <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.Please_Wait}</div>
              </div>
            </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  localStorage.setItem("showLoader", false);
  let returnState  = {};

  if ( state.ClientsReducer.action === "GET_PROCEDURE_PRESCRIPTION_DATA" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      if ( state.ClientsReducer.data.message === 'patient_not_found_or_deleted' ) {
        toast.dismiss();
        toast.error(languageData.global[state.ClientsReducer.data.message]);
        returnState.showLoader = false
      } else {
        toast.dismiss()
        toast.error(languageData.global[state.ClientsReducer.data.message]);
        returnState.showLoader = false
      }
    } else {
      returnState.clientData = state.ClientsReducer.data.data;
    }
  }

  if ( state.ClientsReducer.action === "SAVE_HEALTH_PRO_PRES" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      toast.dismiss()
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.newPresData = state.ClientsReducer.data;
    } else {
      toast.dismiss()
      toast.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.newPresData = state.ClientsReducer.data;
    }
  }

  if ( state.ClientsReducer.action === "SAVE_PHARMACY" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      toast.dismiss()
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.pharmacyData = state.ClientsReducer.data;
    } else {
      toast.dismiss()
      toast.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.pharmacyData = state.ClientsReducer.data;
    }
  }

  if ( state.ClientsReducer.action === "GENERATE_PRESC_PDF" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      toast.dismiss()
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.generatePDFData = state.ClientsReducer.data;
    } else {
      returnState.generatePDFData = state.ClientsReducer.data;
    }
  }

  if ( state.ClientsReducer.action === "FAX_PRESC_PDF" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      toast.dismiss()
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.faxData = state.ClientsReducer.data;
    } else {
      toast.dismiss()
      toast.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.faxData = state.ClientsReducer.data;
    }
  }

  if ( state.ClientsReducer.action === "EMAIL_PRESC_PDF" ) {
    if ( state.ClientsReducer.data.status !== 200 ) {
      toast.dismiss()
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.emailData = state.ClientsReducer.data;
    } else {
      toast.dismiss()
      toast.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.emailData = state.ClientsReducer.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getProcedurePrescription: getProcedurePrescription, saveNewPrescription: saveNewPrescription, savePharmacy: savePharmacy, generatePrescriptionPDF: generatePrescriptionPDF, faxPrescriptionPDF: faxPrescriptionPDF, emailPrescriptionPDF: emailPrescriptionPDF, exportEmptyData: exportEmptyData}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(ProcedurePrescriptions));
