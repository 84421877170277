import React from "react";
import classes from "./EventWrapper.module.scss";

const toPercent = (n) => `${n}%`;

export function EventWrapper({ style, event, children }) {
  return (
    <div
      className={classes.root}
      style={{
        ...style,
        height: toPercent(style.height),
        width: toPercent(style.width),
        top: toPercent(style.top),
        left: toPercent(style.xOffset),
        zIndex: event.zIndex,
      }}
    >
      {children}
    </div>
  );
}
