import React from "react";
import { POS_TYPES } from "../../../../../consts/api";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { usePosSwitchClinicsQuery } from "../../../../../api/queries/usePosSwitchClinicsQuery";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Select } from "../../../../../shared/Select/Select";
import classes from "./PosSwitchClinicPicker.module.scss";
import { CLINIC_NO_ASSOC_ID } from "./PosSwitchClinicPicker.consts";

/**
 * @param {Object} param0
 * @param {boolean} param0.isModalOpen
 * @param {string} param0.posType
 * @param {number} param0.values
 * @param {Function} param0.onChange
 */
export const PosSwitchClinicPicker = ({ isModalOpen, posType, value, onChange }) => {
  const { tSettings } = useAppTranslation.Settings();

  const { data, isLoading, isFetching } = usePosSwitchClinicsQuery({
    staleTime: 0,
    enabled: isModalOpen,
    onError: () => {
      uiNotification.error(tSettings("pos.switch.error.fetchClinics"));
    },
  });

  const options = [
    {
      label: tSettings("pos.switch.noClinicOptions"),
      value: CLINIC_NO_ASSOC_ID,
    },
    ...(data?.map((clinic) => ({
      label: clinic.clinic_name,
      value: clinic.id,
    })) || []),
  ];

  return (
    <div>
      <InputLabel className={classes.label}>
        {posType === POS_TYPES.single
          ? tSettings("pos.switch.toMulti.clinicSelectLabel")
          : tSettings("pos.switch.toSingle.clinicSelectLabel")}
      </InputLabel>
      <Select
        size="small"
        isDisabled={isLoading}
        options={options}
        value={options.find((o) => o.value === value)}
        onChange={(o) => onChange(o?.value)}
        isLoading={isFetching || isLoading}
      />
    </div>
  );
};
