import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import classes from "./ModalInfo.module.scss";
import { Button } from "../../shared/Button/Button";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { InfoIcon } from "../../assets/Icons/InfoIcon";

export function ModalInfo({ isOpen, onClose, onOk, children }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Modal
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      footerClassName={classes.footer}
      footer={<Button onClick={onOk}>{tCommon("label.ok")}</Button>}
      header={
        <Modal.Title className={classes.header}>
          <InfoIcon />
          {tCommon("label.info")}
        </Modal.Title>
      }
    >
      {children}
    </Modal>
  );
}

ModalInfo.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};
