/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { signUp, verifyAccount } from "../../Actions/SignUp/signUpAction.js";
import FooterTouchMD from "../../_legacy/Containers/Guest/FooterTouchMD.js";
import { clearToken, clearUserData } from "../../Utils/services.js";

class VerifyTouchMDAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      verificationCode: this.props.match.params.verificationCode
        ? this.props.match.params.verificationCode
        : "",
      showLoader: true,
    };
  }

  componentDidMount() {
    localStorage.removeItem("accountSetupFile");
    localStorage.removeItem("accountSetupStatus");

    clearToken();
    clearUserData();
    this.props.signUp();

    if (this.state.verificationCode) {
      this.props.verifyAccount(this.state.verificationCode);
    }
  }

  static getDerivedStateFromProps(nextProps) {
    let returnState = {};
    if (nextProps.isSuccess != undefined && nextProps.message != undefined) {
      nextProps.signUp();
      let message = nextProps.message ? nextProps.message : "";
      toast.dismiss();

      if (nextProps.isSuccess) {
        toast.success(message);
      } else {
        toast.error(message);
      }
    }

    return returnState;
  }

  render() {
    return (
      <div className="guest">
        <div className="header touchMDHeader">
          <div className="wrapper">
            <div className="mdWrapper">
              <a className="logo">
                <img src="/images/logo.png?v=123" alt="" />
              </a>
              <a className="touchMDlogo">
                <img
                  alt=""
                  src="https://images.squarespace-cdn.com/content/5d71a41af5026a0001fa93f8/1567735388033-TVI087B1Q1HUDYOXES5H/tmd_logo_wh.png?content-type=image%2Fpng"
                />
              </a>
            </div>
            <div className="header-right">
              <ul className="nav"></ul>
            </div>
          </div>
        </div>
        <div className="sign-up-account-setup m-t-65">
          <div className="container-fluid">
            <div className="thanku signup-sucessful signup-quick-setup m-t-65">
              <h2 id="currentStatus" className="colorAR setLineHeight">
                Thank you for sign up, your account will be active in 7 days.
                You will receive a confirmation email from us. Thank you for
                your patience.
              </h2>
            </div>
          </div>
        </div>
        <FooterTouchMD />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const returnState = {};
  if (state.SignUpReducer.action === "SIGN_UP_VERIFY_ACCOUNT") {
    if (state.SignUpReducer.data.status != 201) {
      returnState.isSuccess = false;
    } else {
      returnState.isSuccess = true;
    }
    returnState.message = state.SignUpReducer.data.message;
  }
  return returnState;
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      signUp: signUp,
      verifyAccount: verifyAccount,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(VerifyTouchMDAccount));
