import React from "react";
import PropTypes from "prop-types";
import ReactSelect, { components as reactSelectComponents } from "react-select";
import { THEME } from "../../consts/theme";
import { svg } from "../../assets/svg";
import { Z_INDEXES } from "../../consts/styleVariables";

const SELECT_SIZES = {
  medium: "medium",
  small: "small",
};

export function Select({
  value,
  onChange,
  options,
  autoFocus,
  className,
  placeholder,
  isSearchable,
  isMulti,
  isDisabled,
  isError,
  size,
  isFixed,
  closeMenuOnSelect,
  isLoading,
  inputValue,
  onInputChange,
  components,
  whiteChevron,
}) {
  const getBorderColor = (state) => {
    let result = THEME.colors.gray1;
    if (state.isFocused && !isError) {
      result = THEME.colors.primary;
    }
    if (isError) {
      result = THEME.colors.red;
    }
    return result;
  };

  const getBackgroundColor = (state) => {
    let result = THEME.colors.white;
    if (state.isDisabled) {
      result = THEME.colors.gray4;
    }
    if (isError) {
      result = THEME.colors.redDim2;
    }
    return result;
  };

  const getMultiValueBackgroundColor = () => {
    let result = THEME.colors.primaryDim;
    if (isError) {
      result = THEME.colors.redLighten;
    }
    if (isDisabled) {
      result = THEME.colors.gray2;
    }
    return result;
  };

  const getOptionColor = ({ isDisabled, isSelected }) => {
    if (isSelected && !isDisabled) {
      return THEME.colors.white;
    }
    if (isSelected) {
      return THEME.colors.white;
    }
    if (!isDisabled) {
      return THEME.colors.black;
    }
    return THEME.colors.gray0;
  };

  const getOptionBackgroundColor = ({ isSelected, isDisabled, fallback }) => {
    if (isSelected && !isDisabled) {
      return THEME.colors.primary;
    }
    if (isSelected) {
      return THEME.colors.primaryDim1;
    }
    if (!isDisabled) {
      return fallback;
    }
    return THEME.colors.gray4;
  };

  const DropdownIndicator = (props) => {
    return (
      <reactSelectComponents.DropdownIndicator {...props}>
        <img
          src={!whiteChevron ? svg.chevronDown : svg.chevronDownWhite}
          alt="chevron-down"
          className={props.selectProps.menuIsOpen ? "rotate-180" : ""}
        />
      </reactSelectComponents.DropdownIndicator>
    );
  };

  const ClearIndicator = (props) => {
    const {
      getStyles,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
      >
        <img src={svg.close} alt="x" width="11px" />
      </div>
    );
  };

  const minHeightBySize = {
    [SELECT_SIZES.medium]: "44px",
    [SELECT_SIZES.small]: "36px",
  };

  return (
    <ReactSelect
      inputValue={inputValue}
      onInputChange={onInputChange}
      value={value}
      onChange={(nextOption) => onChange(nextOption)}
      options={options}
      autoFocus={autoFocus}
      className={className}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isMulti={isMulti}
      isDisabled={isDisabled}
      isLoading={isLoading}
      closeMenuOnSelect={closeMenuOnSelect}
      menuPortalTarget={document.body}
      components={{ DropdownIndicator, ClearIndicator, ...components }}
      menuPosition={isFixed ? "fixed" : "absolute"}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: Z_INDEXES.max,
        }),
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderColor: getBorderColor(state),
          backgroundColor: getBackgroundColor(state),
          minHeight: minHeightBySize[size] || "50px",
          padding: "0 5px",
          fontSize: "16px",
          fontWeight: 400,
          borderWidth: "1px",
          borderRadius: size === SELECT_SIZES.small ? "4px" : "8px",
          boxShadow: "none",
          ":hover": {
            ...(isError && { borderColor: THEME.colors.red }),
          },
        }),
        indicatorSeparator: (baseStyles) => ({
          ...baseStyles,
          display: "none",
        }),
        multiValue: (base) => ({
          ...base,
          backgroundColor: getMultiValueBackgroundColor(),
          borderRadius: "5px",
          "& > *": {
            whiteSpace: "break-spaces !important",
          },
        }),
        placeholder: (base) => ({
          ...base,
          ...(isError && { color: THEME.colors.red }),
        }),
        option: (base, { isSelected, isDisabled }) => ({
          ...base,
          color: getOptionColor({ isSelected, isDisabled }),
          padding: `${size === "small" ? 4 : 8}px 12px !important`,
          backgroundColor: getOptionBackgroundColor({
            isSelected,
            isDisabled,
            fallback: base.backgroundColor,
          }),
        }),
      }}
      theme={(t) => ({
        ...t,
        colors: {
          ...t.colors,
          primary25: THEME.colors.primaryDim,
          primary: THEME.colors.primary,
          neutral80: THEME.colors.black,
        },
      })}
    />
  );
}

const selectOptionPropType = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isDisabled: PropTypes.bool,
});

Select.propTypes = {
  value: PropTypes.oneOfType([
    selectOptionPropType,
    PropTypes.arrayOf(selectOptionPropType),
  ]),
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(selectOptionPropType),
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  isSearchable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isError: PropTypes.bool,
  isFixed: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
  size: PropTypes.oneOf(Object.values(SELECT_SIZES)),
  closeMenuOnSelect: PropTypes.bool,
  isLoading: PropTypes.bool,
  inputValue: PropTypes.string,
  onInputChange: PropTypes.func,
  components: PropTypes.object,
};

Select.defaultProps = {
  value: undefined,
  onChange: () => {},
  options: [],
  autoFocus: false,
  className: undefined,
  placeholder: undefined,
  isSearchable: false,
  isMulti: false,
  isDisabled: false,
  isError: false,
  size: undefined,
  isFixed: false,
  closeMenuOnSelect: true,
  isLoading: false,
  inputValue: undefined,
  onInputChange: undefined,
  components: {},
};
