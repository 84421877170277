import React from "react";
import { Box } from "../../../../../shared/Box/Box";
import { Table as SharedTable } from "../../../../../shared/Table/Table";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";
import ProductSelect from "../ProductSelect/ProductSelect";
import { Empty } from "../../../../../shared/Empty/Empty";
import { ContentLoader } from "../../../../../boxes/ContentLoader/ContentLoader";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EditIcon } from "../../../../../assets/Icons/EditIcon";
import { Button } from "../../../../../shared/Button/Button";
import styles from "./styles.module.scss";

/**
 * @param {object} param0
 * @param {{
 *    value: Array<Record<string, unknown>>;
 *    hasMore: boolean;
 *    isNextFetching: boolean;
 *    isLoading: boolean;
 *    fetchMore: () => void;
 *    isSoftLoading: boolean;
 * }} param0.data
 */
export function Table({ data, editedRows, onProductSelect }) {
  const { tInventory } = useAppTranslation.Inventory();

  const isEmpty = data.value.length === 0 && !data.isLoading;
  const isVisible = !data.isLoading && !isEmpty;

  const tableData = data.value.map((i) => {
    const value =
      i.shopify_product_id && i.shopify_product_name
        ? {
            label: i.shopify_product_name,
            value: i.shopify_product_id,
          }
        : null;

    return {
      key: i.id,
      product_name: i.product_name,
      shopify_product_name:
        i.shopify_product_id && !editedRows.has(i.id) ? (
          <div className={styles.shopifyNameWrapper}>
            <span>{i.shopify_product_name}</span>
            <Button
              size="tiny"
              variant="text"
              leftAdornment={<EditIcon width="16px" />}
              onClick={() => onProductSelect(i.id, value)}
            >
              {tInventory("shopify_product.edit")}
            </Button>
          </div>
        ) : (
          <ProductSelect
            value={value}
            onSelect={(selected) => onProductSelect(i.id, selected)}
          />
        ),
    };
  });

  return (
    <Box className={styles.root}>
      {data.isLoading && !data.isSoftLoading && (
        <Skeleton count={15} height={40.5} borderRadius="0px" />
      )}
      {isEmpty && <Empty position="center" />}
      {isVisible && (
        <ContentLoader isLoading={data.isSoftLoading}>
          <SharedTable
            bgColor="white"
            data={tableData}
            cols={[
              {
                data: tInventory("shopify_product.table.name"),
                accessor: "product_name",
                headColClassName: styles.colBig,
              },
              {
                data: tInventory("shopify_product.table.shopify_product_name"),
                accessor: "shopify_product_name",
                headColClassName: styles.colSmall,
              },
            ]}
          />
          {data.hasMore && (
            <LoadMoreObserver
              noPaddingBottom
              loadMore={data.fetchMore}
              isLoading={data.isNextFetching}
            />
          )}
        </ContentLoader>
      )}
    </Box>
  );
}
