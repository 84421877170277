/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  getCancellationPolicy,
  updateCancellationPolicy,
  cancellationPolicyStatus,
} from "../../../Actions/Settings/settingsActions.js";
import { withToggleGuard } from "./withToggleGuard.js";
import { TextEditor } from "../../../shared/TextEditor/TextEditor.js";

const CANT_ENABLE_ERROR =
  "In order to use the cancellation policy, please turn point of sale on. Go to: Settings > Pos > Pos Settings and toggle this feature ON then complete the application.";

class CancellationPolicy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      policyData: {},
      cancellation_fees: "",
      cancelation_fee_charge_days: "",
      cc_auth_text: "",
      cancellation_policy: "",
      cancelation_policy_status: false,
      userChanged: false,
      clinic_patient_portal_access_body: "",
      clinic_patient_portal_access_subject: "",
      text: "",
      showLoader: false,
    };
  }

  handleEditorChange = (content) => {
    this.setState({ cancellation_policy: content, userChanged: true });
  };

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      cp_header: languageData.settings["cp_header"],
      cp_fees: languageData.settings["cp_fees"],
      cp_charge_fee: languageData.settings["cp_charge_fee"],
      cp_auth_text: languageData.settings["cp_auth_text"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      showLoader: true,
    });
    this.setState({ showLoader: true });
    this.props.getCancellationPolicy();
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.policyData !== undefined &&
      props.policyData.status === 200 &&
      props.policyData.data != null &&
      props.policyDataTimestamp != state.policyDataTimestamp
    ) {
      return {
        cancellation_policy: state.userChanged
          ? state.cancellation_policy
          : props.policyData.data.account_details.cancellation_policy,
        cancelation_policy_status: state.userChanged
          ? state.cancelation_policy_status
          : props.policyData.data.account_details.account_preference
              .cancelation_policy_status,
        cancellation_fees: state.userChanged
          ? state.cancellation_fees
          : props.policyData.data.account_details.cancellation_fees,
        cancelation_fee_charge_days: state.userChanged
          ? state.cancelation_fee_charge_days
          : props.policyData.data.account_details.account_preference
              .cancelation_fee_charge_days,
        cc_auth_text: state.userChanged
          ? state.cc_auth_text
          : props.policyData.data.account_details.account_preference
              .cc_auth_text,
        showLoader: false,
        policyDataTimestamp: props.policyDataTimestamp,
      };
    }
    if (
      props.showLoader !== undefined &&
      props.showLoader != state.showLoader
    ) {
      return {
        showLoader: props.showLoader,
      };
    }
    return null;
  }

  handleInputChange = (event) => {
    const { toggleGuard } = this.props;
    const target = event.target;
    let returnState = {};
    let value = target.value;
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        if (value && !toggleGuard.isCanEnableCancellationPolicy) {
          return toast.error(CANT_ENABLE_ERROR);
        }
        break;
      }
      case "file": {
        value = target.files[0];
        break;
      }
      default:
        break;
    }

    if (target.name == "cancelation_policy_status") {
      returnState.showLoader = true;
      this.props.cancellationPolicyStatus({
        field_name: "cancelation_policy_status",
        is_enabled: value ? 1 : 0,
      });
    }

    if (target.name === "cancellation_fees") {
      if (!/^[0-9]*[.]{0,1}[0-9]*$/.test(target.value)) return;
      if (target.value.includes(".") && target.value.split(".")[1].length > 2) {
        return;
      }
      if (!target.value.includes(".") && target.value.length > 3) return;
    }

    returnState[event.target.name] = value;
    returnState.userChanged = true;
    this.setState(returnState);
  };

  handleSubmit = () => {
    let error = false;
    this.setState({
      cancellation_policy_Error: false,
      cancellation_fees_Error: false,
      cc_auth_text_Error: false,
      cancelation_fee_charge_days_Error: false,
    });
    if (
      typeof this.state.cancellation_policy === undefined ||
      this.state.cancellation_policy === null ||
      this.state.cancellation_policy === "" ||
      this.state.cancellation_policy.trim() === ""
    ) {
      this.setState({
        cancellation_policy_Error: true,
      });
      error = true;
    }
    if (!this.state.cancellation_fees || this.state.cancellation_fees < 1) {
      this.setState({ cancellation_fees_Error: true });
      error = true;
      toast.error(`${this.state.cp_fees} must be 1 or greater`);
    }
    if (
      typeof this.state.cc_auth_text === undefined ||
      this.state.cc_auth_text === null ||
      this.state.cc_auth_text === "" ||
      this.state.cc_auth_text.trim() === ""
    ) {
      this.setState({
        cc_auth_text_Error: true,
      });
      error = true;
    }
    if (
      typeof this.state.cancelation_fee_charge_days === undefined ||
      this.state.cancelation_fee_charge_days === null ||
      this.state.cancelation_fee_charge_days === "" ||
      this.state.cancelation_fee_charge_days === "SelectDays"
    ) {
      this.setState({
        cancelation_fee_charge_days_Error: true,
      });
      error = true;
    }
    if (error === true) {
      return;
    }
    this.setState({ showLoader: true });
    let formData = {
      account_details: {
        cancellation_policy: this.state.cancellation_policy,
        cancellation_fees: this.state.cancellation_fees,
        account_preference: {
          cc_auth_text: this.state.cc_auth_text,
          cancelation_fee_charge_days: this.state.cancelation_fee_charge_days,
        },
      },
    };
    this.props.updateCancellationPolicy(formData);
  };

  handleChange = (value) => {
    this.setState({ text: value });
  };

  render() {
    return (
      <div>
        <div>
          <div className="setting-title m-b-5">
            {this.state.cp_header}
            <label className="setting-switch pull-right">
              <input
                type="checkbox"
                name="cancelation_policy_status"
                id="cancel_policy"
                className="setting-custom-switch-input"
                onChange={this.handleInputChange}
                checked={
                  this.state.cancelation_policy_status ? "checked" : false
                }
              />
              <span className="setting-slider" />
            </label>
          </div>
          <div className="d-flex gap-8 align-center text-orange float-left m-b-20">
            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
            Any changes made will only apply to future appointments
          </div>
          <div
            name="cancellation_policy"
            className={
              this.state.cancelation_policy_status ? "row" : "row no-display"
            }
            id="view_policy"
          >
            <div
              className={
                this.state.cancellation_policy_Error === true
                  ? "col-sm-12 cancel-policy m-b-15 field_error"
                  : "col-sm-12 cancel-policy m-b-15"
              }
            >
              <TextEditor
                value={this.state.cancellation_policy || ""}
                onChange={this.handleEditorChange}
              />
            </div>
            <div className="col-lg-3 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">
                  {this.state.cp_fees}
                  <span className="setting-require">*</span>
                </div>
                <input
                  type="text"
                  name="cancellation_fees"
                  onChange={this.handleInputChange}
                  className={
                    this.state.cancellation_fees_Error === true
                      ? "newInputField field_error"
                      : "newInputField "
                  }
                  autoComplete="off"
                  value={this.state.cancellation_fees}
                />
              </div>
            </div>
            <div className="col-lg-9 col-xs-12">
              <div className="row ques-radio-right m-b-0">
                <div className="col-md-5 col-xs-12 newInputFileldOuter">
                  <div className="newInputLabel">
                    {this.state.cp_charge_fee}
                    <span className="setting-require">*</span>
                  </div>
                  <div
                    className={
                      this.state.cancelation_fee_charge_days_Error === true
                        ? "setting-input-outer field_error"
                        : "setting-input-outer"
                    }
                  >
                    <select
                      className={
                        this.state.cancelation_fee_charge_days_Error === true
                          ? "newSelectField field_error"
                          : "newSelectField "
                      }
                      name="cancelation_fee_charge_days"
                      value={this.state.cancelation_fee_charge_days}
                      onChange={this.handleInputChange}
                    >
                      {}
                      <option value="SelectDays">Select Days</option>
                      <option value={1}>24 hrs of Appointment time</option>
                      <option value={2}> 2 days of Appointment time </option>
                      <option value={3}> 3 days of Appointment time </option>
                      <option value={4}> 4 days of Appointment time </option>
                      <option value={5}> 5 days of Appointment time </option>
                      <option value={6}> 6 days of Appointment time </option>
                      <option value={7}> 7 days of Appointment time </option>
                      <option value={8}> 8 days of Appointment time </option>
                      <option value={9}> 9 days of Appointment time </option>
                      <option value={10}> 10 days of Appointment time </option>
                      <option value={11}> 11 days of Appointment time </option>
                      <option value={12}> 12 days of Appointment time </option>
                      <option value={13}> 13 days of Appointment time </option>
                      <option value={14}> 14 days of Appointment time </option>
                      <option value={15}> 15 days of Appointment time </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">
                  {this.state.cp_auth_text}
                  <span className="setting-require">*</span>
                </div>
                <div className="setting-input-outer">
                  <textarea
                    name="cc_auth_text"
                    onChange={this.handleInputChange}
                    className={
                      this.state.cc_auth_text_Error === true
                        ? "newtextareaField field_error"
                        : "newtextareaField "
                    }
                    value={this.state.cc_auth_text}
                    style={{ resize: "none" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.cancelation_policy_status
              ? "footer-static p-r-0 p-l-0 m-t-20"
              : "footer-static p-r-0 p-l-0 m-t-20 no-display"
          }
        >
          <a
            className="new-blue-btn pull-right"
            onClick={this.handleSubmit}
            id="c_policy"
          >
            Save
          </a>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.clinic_Please_Wait}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  toast.dismiss();
  if (state.SettingReducer.action === "GET_CANCELLATION_POLICY") {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.policyData = state.SettingReducer.data;
      returnState.policyDataTimestamp = new Date();
    }
  } else if (state.SettingReducer.action === "UPDATE_CANCELLATION_POLICY") {
    if (state.SettingReducer.data.status == 200) {
      returnState.policyDataTimestamp = new Date();
      returnState.policyData = state.SettingReducer.data;
    } else {
      returnState.showLoader = false;
    }
  } else if (state.SettingReducer.action === "CANCELLATION_POLICY_STATUS") {
    returnState.showLoader = false;
    if (state.SettingReducer.data.status == 200) {
      returnState.policyData = state.SettingReducer.data;
      returnState.policyDataTimestamp = new Date();
    } else {
      returnState.showLoader = false;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCancellationPolicy: getCancellationPolicy,
      updateCancellationPolicy: updateCancellationPolicy,
      cancellationPolicyStatus: cancellationPolicyStatus,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withToggleGuard(CancellationPolicy));
