import React, { Component } from "react";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import InsightsSideBar from "./InsightsSideBar.js";
import TimeCardTitle from "./TimeCard/TimeCardTitle.js";
import TimeCardFilter from "./TimeCard/TimeCardFilter.js";
import TimeCardInsightReport from "./TimeCard/TimeCardInsightReport.js";
import TimeCardInsightReportDetail from "./TimeCard/TimeCardInsightReportDetail.js";
import Loader from "../Common/Loader.js";
import {
  resetReducerAction,
  fetchTimeCardReport,
  fetchTimeCardReportDetails,
  exportTimeCardReport,
} from "../../Actions/BusinessInsights/businessInsightActions.js";
import {
  emptyClockReducer,
  getClockPayPeriod,
} from "../../Actions/Clock/clockActions";
import {
  getTimeClockSetting,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import {
  showSelectedWeek,
  showSelectedMonth,
  dateLeftPadding,
} from "../../Utils/date-services.js";

class TimeCardInsights extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    const dateData = JSON.parse(localStorage.getItem("insight-tiimeCard-date"));
    const pay_period_on_start = dateLeftPadding("1");
    let selectedDate;
    if (dateData && dateData.startDate) {
      selectedDate = dateData.startDate;
    } else {
      selectedDate = moment().format(
        "YYYY-MM-" + pay_period_on_start + " 00:00:00",
      );
    }
    let startDate = selectedDate;
    const calculatedDateMonth = showSelectedMonth(startDate, true, true);
    let endDate = calculatedDateMonth.end_date;

    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      reportData: [],
      reportTitle:
        languageData.business_insights &&
        languageData.business_insights.bi_time_card_practice
          ? languageData.business_insights.bi_time_card_practice
          : "",
      pay_period_type: "monthly",
      pay_period_on_start: pay_period_on_start,
      selectedDate: startDate,
      startDate: startDate,
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: endDate,
      toDate: moment(endDate).format("YYYY-MM-DD"),
      timeCardPracticeDetail: {},
      detailData: [],
      detailTotal: {},
      isTimeCardPracticeDetail: false,
      detailProviderId: -1,
      detailProviderName: "",
      detailSelectedDate: startDate,
      detailStartDate: null,
      detailFromDate: null,
      detailEndDate: null,
      detailToDate: null,

      payPeriodData: {},
      isReMountFilter: false,
    };
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    if (!getTimeClockSetting()) {
      return this.props.history.push("/business-insight/sales");
    }
    this.setState({ showLoader: true });
    this.props.getClockPayPeriod();
    positionFooterCorrectly();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (!getTimeClockSetting()) {
      return nextProps.history.push("/business-insight/sales");
    }

    if (
      nextProps.payPeriodData &&
      nextProps.payPeriodData !== prevState.payPeriodData
    ) {
      nextProps.emptyClockReducer();
      returnState.payPeriodData = nextProps.payPeriodData;
      returnState.pay_period_type = nextProps.payPeriodData.pay_period_type
        ? nextProps.payPeriodData.pay_period_type
        : "monthly";
      returnState.pay_period_on_start =
        nextProps.payPeriodData.pay_period_starts_on >= 0
          ? nextProps.payPeriodData.pay_period_starts_on
          : "1";
      returnState.isPayPeriodDataEmpty = false;

      const dateData = JSON.parse(
        localStorage.getItem("insight-tiimeCard-date"),
      );
      let pay_period_on_start;
      let selectedDate;
      if (dateData && dateData.startDate) {
        selectedDate = dateData.startDate;
      } else {
        if (returnState.pay_period_type === "weekly") {
          let weekStartDay = returnState.pay_period_on_start;
          let dateOfDay = moment()
            .day(weekStartDay)
            .format("YYYY-MM-DD 00:00:00");
          let weekCurrentDay = moment().day();
          if (weekStartDay > weekCurrentDay) {
            dateOfDay = moment(dateOfDay).subtract(1, "weeks").toDate();
          }
          selectedDate = moment(dateOfDay).toDate();
        } else {
          pay_period_on_start = dateLeftPadding(
            returnState.pay_period_on_start,
          );
          let monthStartDay = returnState.pay_period_on_start;
          let dateOfDay = moment().format(
            "YYYY-MM-" + pay_period_on_start + " 00:00:00",
          );
          let monthCurrentDay = moment().format("D");
          if (monthStartDay > monthCurrentDay) {
            dateOfDay = moment(dateOfDay).subtract(1, "months").toDate();
          }
          selectedDate = moment(dateOfDay).toDate();
        }
      }
      let startDate = selectedDate;
      let calculatedDate;
      if (returnState.pay_period_type === "weekly") {
        calculatedDate = showSelectedWeek(selectedDate, true, true);
      } else {
        calculatedDate = showSelectedMonth(selectedDate, true, true);
      }
      let endDate = calculatedDate.end_date;

      returnState.selectedDate = selectedDate;
      returnState.startDate = startDate;
      returnState.fromDate = moment(returnState.startDate).format("YYYY-MM-DD");
      returnState.endDate = endDate;
      returnState.toDate = moment(returnState.endDate).format("YYYY-MM-DD");

      returnState.isReMountFilter = true;
      nextProps.fetchTimeCardReport({
        params: { from: returnState.fromDate, to: returnState.toDate },
      });
    }

    if (nextProps.isPayPeriodDataEmpty === true) {
      returnState.isPayPeriodDataEmpty = true;
      returnState.showLoader = false;
      nextProps.emptyClockReducer();
    }

    if (nextProps.reportData && nextProps.reportData !== prevState.reportData) {
      nextProps.resetReducerAction();
      returnState.reportData = nextProps.reportData;
      returnState.isReMountFilter = false;
      returnState.showLoader = false;
    } else if (
      nextProps.exportDetails &&
      nextProps.exportDetails.status === 200 &&
      nextProps.exportDetails.data !== prevState.exportDetails
    ) {
      nextProps.resetReducerAction();
      returnState.exportDetails = nextProps.exportDetails.data;
      returnState.showLoader = false;

      if (nextProps.exportDetails.data && nextProps.exportDetails.data.file) {
        window.open(
          process.env.REACT_APP_API_URL +
            "download-data/" +
            nextProps.exportDetails.data.file,
          "_blank",
        );
      }
      nextProps.resetReducerAction();
    } else if (
      nextProps.timeCardPracticeDetail !== undefined &&
      nextProps.timeCardPracticeDetail !== prevState.timeCardPracticeDetail
    ) {
      returnState.timeCardPracticeDetail = nextProps.timeCardPracticeDetail;
      returnState.detailData = nextProps.timeCardPracticeDetail.details
        ? nextProps.timeCardPracticeDetail.details
        : [];
      returnState.detailTotal = nextProps.timeCardPracticeDetail.total_details
        ? nextProps.timeCardPracticeDetail.total_details
        : [];
      returnState.isTimeCardPracticeDetail = true;
      returnState.showLoader = false;

      returnState.detailStartDate = prevState.detailStartDate;
      returnState.detailFromDate = prevState.detailFromDate;
      returnState.detailEndDate = prevState.detailEndDate;
      returnState.detailToDate = prevState.detailToDate;
      returnState.isReMountFilter = false;

      nextProps.resetReducerAction();
    } else if (nextProps.showLoader === false) {
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    }
    positionFooterCorrectly();
    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value, dataChanged: true });
  };

  fetchTimeCardReportDetails = (chaildState) => {
    let detailProviderId = chaildState.detailProviderId;
    let detailProviderName = chaildState.detailProviderName;
    this.setState(
      {
        detailProviderId,
        detailProviderName,
        reportTitle: "Time Card - " + detailProviderName,
        showLoader: true,
        detailSelectedDate: this.state.selectedDate,
        isReMountFilter: !this.state.isTimeCardPracticeDetail ? true : false,
      },
      () => {
        this.props.fetchTimeCardReportDetails({
          params: {
            from: this.state.fromDate,
            to: this.state.toDate,
            user_id: detailProviderId,
          },
        });
      },
    );
  };

  backTimeCardReport = () => {
    this.setState(
      {
        isTimeCardPracticeDetail: false,
        reportTitle: this.state.businessInsightLang.bi_time_card_practice,
        isReMountFilter: true,
      },
      () => {
        this.setState({ isReMountFilter: false });
      },
    );
    positionFooterCorrectly();
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  handleDateFilter = (childState) => {
    this.setState(childState);
    this.setState({ showLoader: true });
    if (this.state.isTimeCardPracticeDetail) {
      this.props.fetchTimeCardReportDetails({
        params: {
          from: childState.fromDate,
          to: childState.toDate,
          user_id: this.state.detailProviderId,
        },
      });
    } else {
      this.props.fetchTimeCardReport({
        params: { from: childState.fromDate, to: childState.toDate },
      });
    }
  };

  handleDownload = () => {
    this.setState({ showLoader: true });
    let params = { from: this.state.fromDate, to: this.state.toDate };
    if (this.state.isTimeCardPracticeDetail) {
      params.user_id = this.state.detailProviderId;
    }
    this.props.exportTimeCardReport({ params: params });
  };

  refetchUserLogs = () => {
    if (this.state.detailProviderId) {
      this.props.fetchTimeCardReportDetails({
        params: {
          from: this.state.fromDate,
          to: this.state.toDate,
          user_id: this.state.detailProviderId,
        },
      });
    }
  };

  render() {
    const selectedUser = this.state.isTimeCardPracticeDetail
      ? {
          id: this.state.detailProviderId,
          name: this.state.detailProviderName,
        }
      : {};

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            <TimeCardTitle
              history={this.props.history}
              reportTitle={this.state.reportTitle}
              isTimeCardPracticeDetail={this.state.isTimeCardPracticeDetail}
              businessInsightLang={this.state.businessInsightLang}
              globalLang={this.state.globalLang}
              backTimeCardReport={this.backTimeCardReport}
              selectedUser={selectedUser}
              refetchUserLogs={this.refetchUserLogs}
            />
            {this.state.isReMountFilter === false && (
              <TimeCardFilter
                businessInsightLang={this.state.businessInsightLang}
                globalLang={this.state.globalLang}
                isDetailSelectedDate={this.state.isTimeCardPracticeDetail}
                selectedDate={
                  this.state.isTimeCardPracticeDetail
                    ? this.state.detailSelectedDate
                    : this.state.selectedDate
                }
                startDate={
                  this.state.isTimeCardPracticeDetail
                    ? this.state.detailStartDate
                    : this.state.startDate
                }
                endDate={
                  this.state.isTimeCardPracticeDetail
                    ? this.state.detailEndDate
                    : this.state.endDate
                }
                fromDate={
                  this.state.isTimeCardPracticeDetail
                    ? this.state.detailFromDate
                    : this.state.fromDate
                }
                toDate={
                  this.state.isTimeCardPracticeDetail
                    ? this.state.detailToDate
                    : this.state.toDate
                }
                calendarView={
                  this.state.pay_period_type === "weekly" ? "week" : "month"
                }
                pay_period_on_start={this.state.pay_period_on_start}
                handleDateFilter={this.handleDateFilter}
                handleChildState={this.handleChildState}
                handleDownload={this.handleDownload}
              />
            )}

            {this.state.isTimeCardPracticeDetail === false && (
              <TimeCardInsightReport
                businessInsightLang={this.state.businessInsightLang}
                globalLang={this.state.globalLang}
                fetchTimeCardReportDetails={this.fetchTimeCardReportDetails}
                reportData={this.state.reportData}
              />
            )}

            {this.state.isTimeCardPracticeDetail === true && (
              <TimeCardInsightReportDetail
                businessInsightLang={this.state.businessInsightLang}
                globalLang={this.state.globalLang}
                fetchTimeCardReportDetails={this.fetchTimeCardReportDetails}
                detailTotal={this.state.detailTotal}
                detailData={this.state.detailData}
                detailProviderId={this.state.detailProviderId}
                detailProviderName={this.state.detailProviderName}
              />
            )}
          </div>
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.BusinessInsightReducer.action === "TIME_CARD_REPORT") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.reportData = state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }

  if (state.BusinessInsightReducer.action === "TIME_CARD_REPORT_DETAILS") {
    if (state.BusinessInsightReducer.data.status === 200) {
      returnState.timeCardPracticeDetail =
        state.BusinessInsightReducer.data.data;
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    }
  }

  if (state.BusinessInsightReducer.action === "TIME_CARD_REPORT_EXPORT") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.exportDetails = state.BusinessInsightReducer.data;
    }
  }

  if (state.ClockReducer.action === "GET_PAY_PERIOD") {
    if (state.ClockReducer.data.status === 200) {
      if (state.ClockReducer.data.data) {
        returnState.payPeriodData = state.ClockReducer.data.data;
      } else {
        returnState.isPayPeriodDataEmpty = true;
      }
    } else {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(languageData.global[state.ClockReducer.data.message]);
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction: resetReducerAction,
      fetchTimeCardReport: fetchTimeCardReport,
      fetchTimeCardReportDetails: fetchTimeCardReportDetails,
      exportTimeCardReport: exportTimeCardReport,
      emptyClockReducer: emptyClockReducer,
      getClockPayPeriod: getClockPayPeriod,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TimeCardInsights));
