/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { yupResolver } from "@hookform/resolvers/yup";
import cx from "clsx";
import moment from "moment";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { weekTemplate } from "../../../_legacy/Constants";
import {
  useDeleteDayScheduleQuery,
  useDeleteProviderScheduleQuery,
  useUpdateProviderScheduleQuery,
} from "../../../_legacy/Queries";
import { convertTime12to24, handleScheduleMasking } from "../../../Utils";
import { ConfirmModal } from "../../../boxes/ConfirmModal/ConfirmModal";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import DeleteIcon from "../../../_legacy/images/delete-dustbin.svg";
import { uiNotification } from "../../../services/UINotificationService";
import { Button } from "../../../shared/Button/Button";
import { checkOverlappingTime } from "../../../utilities/general";
import { CommonDialog } from "../../Common";
import Loader from "../../Common/Loader";
import classes from "./sass/schedule.module.scss";

const editProviderScheduleSchema = yup.object().shape({
  timeSchedules: yup.array().of(
    yup.object().shape({
      fromTime: yup.string().required("Required"),
      toTime: yup
        .string()
        .required("Required")
        .test(
          "to time is greater than from time",
          "Invalid Schedule",
          (toTime, context) => {
            const { fromTime } = context.parent;
            return (
              toTime &&
              fromTime &&
              parseInt(toTime.replace(":", "")) >
                parseInt(fromTime.replace(":", ""))
            );
          },
        ),
      availableFor: yup.string().required("Required"),
    }),
  ),
});

const EditProviderSchedule = (props) => {
  const {
    selectedDayClinicSchedules,
    onClose,
    providerId,
    setSchedulingErrors,
    refetchSchedule,
    editScheduleModalMetaData: { scheduleDate, clinic, isEditable },
  } = props;

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteManualSchedule, setDeleteManualSchedule] = useState(null);
  const [ifManualSchedulesDeleted, setIfManualSchedulesDeleted] =
    useState(false);

  const { tCommon } = useAppTranslation.Common();

  const { mutate: updateSchedule, isLoading: isUpdatingSchedule } =
    useUpdateProviderScheduleQuery();

  const { mutate: deleteSchedule, isLoading: isDeleting } =
    useDeleteDayScheduleQuery();
  const {
    mutate: deleteManualSchedules,
    isLoading: isDeleteingManualSchedules,
  } = useDeleteProviderScheduleQuery(false);

  const formMethods = useForm({
    defaultValues: {
      timeSchedules: selectedDayClinicSchedules.map(
        ({ start, end, available_for: availableFor, schedule_type, id }) => ({
          fromTimeHour: moment(start).format("hh:mm"),
          fromTime: moment(start).format("HH:mm"),
          fromTimeOption: moment(start).format("A"),
          toTimeHour: moment(end).format("hh:mm"),
          toTime: moment(end).format("HH:mm"),
          toTimeOption: moment(end).format("A"),
          availableFor,
          isManual: schedule_type === "manual",
          id: id.split("_")[0],
        }),
      ),
    },
    resolver: yupResolver(editProviderScheduleSchema),
  });

  const {
    setValue,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const formValues = watch();

  const { timeSchedules } = formValues;

  const updateProviderSchedule = (formData) => {
    const { timeSchedules } = formData;

    let overlappingTimeError = false;

    const isTimeSlotsOverlapping = checkOverlappingTime(timeSchedules);
    if (isTimeSlotsOverlapping) {
      overlappingTimeError = true;
      isTimeSlotsOverlapping.forEach((slotIndex) => {
        setError(`timeSchedules[${slotIndex}].fromTime`);
        setError(`timeSchedules[${slotIndex}].toTime`);
      });
    }

    if (overlappingTimeError) {
      uiNotification.error("Time entered for clinic is overlapping");
      return false;
    }

    updateSchedule(
      {
        provider_id: providerId,
        schedule_start_date: moment(scheduleDate).format("YYYY-MM-DD"),
        clinic_id: clinic.value,
        clinic_schedules: [
          {
            day: weekTemplate[moment(scheduleDate).day()].dayFullName,
            time_schedules: timeSchedules.map(
              ({ fromTime, toTime, availableFor }) => ({
                from_time: fromTime,
                to_time: toTime,
                available_for: availableFor,
              }),
            ),
          },
        ],
      },
      {
        onSuccess: () => {
          refetchSchedule();
        },
        onError: (error) => {
          if (error.response.data.data?.length) {
            setSchedulingErrors({
              ...error.response.data,
              message: tCommon(
                `providerSchedule.error.${error.response.data.message}`,
              ),
            });
          } else {
            uiNotification.error(
              tCommon([
                `providerSchedule.error.${error.response?.data?.message}`,
                `error.fallback`,
              ]),
            );
          }
        },
      },
    );
  };

  const maskChange = (newState, _, userInput) => {
    const { value } = newState;
    const selection = newState.selection;
    const cursorPosition = selection ? selection.start : null;
    return handleScheduleMasking(value, selection, cursorPosition, userInput);
  };

  const setDayTime = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const { scheduleindex: scheduleIndex } = event.currentTarget.dataset;

    const { timeSchedules } = watch();

    const tempSchedules = [...timeSchedules];

    tempSchedules[scheduleIndex][name] = value;

    switch (name) {
      case "fromTimeHour":
      case "fromTimeOption": {
        const { fromTimeHour, fromTimeOption } = tempSchedules[scheduleIndex];
        const convertedFromTime = convertTime12to24(
          [fromTimeHour, fromTimeOption].join(" "),
        );

        const fromTime =
          convertedFromTime === "00:00" ? null : convertedFromTime;

        tempSchedules[scheduleIndex].fromTime = fromTime;
        setValue(
          `tempSchedules.${scheduleIndex}`,
          tempSchedules[scheduleIndex],
          {
            shouldValidate: Boolean(errors.fromTime),
          },
        );
        break;
      }
      case "toTimeHour":
      case "toTimeOption": {
        const { toTimeHour, toTimeOption } = tempSchedules[scheduleIndex];
        const convertedToTime = convertTime12to24(
          [toTimeHour, toTimeOption].join(" "),
        );

        const toTime = convertedToTime === "00:00" ? null : convertedToTime;

        tempSchedules[scheduleIndex].toTime = toTime;
        setValue(
          `tempSchedules.${scheduleIndex}`,
          tempSchedules[scheduleIndex],
          {
            shouldValidate: Boolean(errors.toTime),
          },
        );
        break;
      }
      default:
        setValue(name, value);
        break;
    }
  };

  const deleteScheduleItem = (scheduleItemIndex, shouldValidate = false) => {
    if (timeSchedules[scheduleItemIndex].isManual) {
      setDeleteManualSchedule({
        ...timeSchedules[scheduleItemIndex],
        itemIndex: scheduleItemIndex,
      });
      return false;
    }
    const tempTimeSchedules = [...timeSchedules];
    tempTimeSchedules.splice(scheduleItemIndex, 1);

    setValue(`timeSchedules`, tempTimeSchedules, { shouldValidate });
  };

  const toggleDeleteModal = () => {
    setDeleteModalOpen((modalStatus) => !modalStatus);
  };

  const deleteClinicSchedule = () => {
    deleteSchedule(
      {
        providerId,
        clinic_id: clinic.value,
        schedule_date: moment(scheduleDate).format("YYYY-MM-DD"),
      },
      {
        onSuccess: () => {
          refetchSchedule();
        },
        onSettled: toggleDeleteModal,
      },
    );
  };

  const deleteManualScheduleConfirmed = () => {
    deleteManualSchedules(
      {
        schedule_ids: [deleteManualSchedule?.id],
        provider_id: providerId,
      },
      {
        onSuccess: (response) => {
          const ifScheduleDeleted = response.data.data.i === 1;
          if (ifScheduleDeleted) {
            const tempTimeSchedules = [...timeSchedules];
            tempTimeSchedules.splice(deleteManualSchedule?.itemIndex, 1);

            setValue(`timeSchedules`, tempTimeSchedules);
            if (!ifManualSchedulesDeleted) {
              setIfManualSchedulesDeleted(true);
            }
          } else {
            uiNotification.error(
              "Schedule with appointment can not be deleted",
            );
          }
        },
        onSettled: () => {
          toggleManualScheduleDeleteModal();
        },
      },
    );
  };

  const toggleManualScheduleDeleteModal = () => {
    setDeleteManualSchedule(null);
  };

  const closeModal = () => {
    if (ifManualSchedulesDeleted) {
      refetchSchedule();
    }
    onClose();
  };

  return (
    <div className={classes.modalRoot}>
      <CommonDialog
        title="Schedule Details"
        onClose={closeModal}
        style="primary"
        className={
          isEditable
            ? classes.editScheduleModal
            : classes.viewScheduleDetailsModal
        }
        footer={
          isEditable && (
            <div className={classes.editScheduleModalFooter}>
              <Button onClick={toggleDeleteModal} color="error" size="small">
                Delete
              </Button>
              <Button
                type="submit"
                form="edit-provider-clinic-day-schedule-form"
                size="small"
              >
                Save
              </Button>
            </div>
          )
        }
      >
        <form
          onSubmit={handleSubmit(updateProviderSchedule)}
          id="edit-provider-clinic-day-schedule-form"
        >
          <div className="">
            <div className="row m-t-10">
              <div className="col-xs-12 col-sm-6">
                <div className={classes.selectTitle}>
                  DATE :{" "}
                  <span className={classes.scheduleDetails}>
                    {moment(scheduleDate).format("MM/DD/YYYY")}
                  </span>
                </div>
              </div>

              <div className="col-xs-12 col-sm-6">
                <div className={classes.selectTitle}>
                  CLINIC :{" "}
                  <span className={classes.scheduleDetails}>
                    {clinic.label}
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.weekDaysSchedule}>
              <div className={classes.daySchedule}>
                <div className={classes.dayScheduleSlots}>
                  {timeSchedules.map((slotDetails, scheduleItemIndex) => {
                    const {
                      fromTimeHour,
                      fromTimeOption,
                      toTimeHour,
                      toTimeOption,
                      availableFor,
                    } = slotDetails;

                    const { fromTime: fromTimeError, toTime: toTimeError } =
                      errors?.timeSchedules?.[scheduleItemIndex] || {};

                    return (
                      <div
                        className={cx(
                          classes.scheduleRow,
                          "fromandtoTime  no-padding row schedule-row items-center",
                        )}
                        key={`timeSchedules-${scheduleItemIndex}`}
                      >
                        <div className={classes.scheduleTimeFields}>
                          <div
                            className={cx(
                              classes.newInputFileldOuter,
                              "newInputFileldOuter",
                            )}
                          >
                            <InputMask
                              name="fromTimeHour"
                              data-scheduleindex={scheduleItemIndex}
                              data-shouldvalidate={fromTimeError}
                              value={fromTimeHour}
                              mask="99:99"
                              className={cx(
                                {
                                  [classes.timeFieldError]: fromTimeError,
                                },
                                classes.inputTime,
                                `setting-input-box hours-time from-time-input `,
                              )}
                              placeholder="00:00"
                              onChange={setDayTime}
                              maskChar=""
                              beforeMaskedValueChange={maskChange}
                              autoComplete="off"
                            />
                            <select
                              name="fromTimeOption"
                              data-scheduleindex={scheduleItemIndex}
                              data-shouldvalidate={fromTimeError}
                              value={fromTimeOption}
                              className={cx(
                                classes.inputTimeOption,
                                {
                                  [classes.timeOptionFieldError]: fromTimeError,
                                },
                                `newSelectField hours-pm fromTimeOption `,
                              )}
                              onChange={setDayTime}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                          <div
                            className={cx(
                              classes.newInputFileldOuter,
                              "newInputFileldOuter",
                            )}
                          >
                            &nbsp;-&nbsp;
                          </div>
                          <div
                            className={cx(
                              classes.newInputFileldOuter,
                              "newInputFileldOuter",
                            )}
                          >
                            <InputMask
                              name="toTimeHour"
                              data-scheduleindex={scheduleItemIndex}
                              data-shouldvalidate={toTimeError}
                              value={toTimeHour}
                              mask="99:99"
                              className={cx(
                                classes.inputTime,
                                {
                                  [classes.timeFieldError]: toTimeError,
                                },
                                `setting-input-box hours-time from-time-input`,
                              )}
                              placeholder="00:00"
                              onChange={setDayTime}
                              maskChar=""
                              beforeMaskedValueChange={maskChange}
                              autoComplete="off"
                            />
                            <select
                              name="toTimeOption"
                              data-scheduleindex={scheduleItemIndex}
                              data-shouldvalidate={toTimeError}
                              value={toTimeOption}
                              className={cx(
                                classes.inputTimeOption,
                                {
                                  [classes.timeOptionFieldError]: toTimeError,
                                },
                                `newSelectField hours-pm fromTimeOption `,
                              )}
                              onChange={setDayTime}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </div>

                        <div
                          className={cx(
                            classes.newInputFileldOuter,
                            classes.appointmentTypeField,
                            "newInputFileldOuter",
                          )}
                        >
                          <select
                            name="availableFor"
                            data-scheduleindex={scheduleItemIndex}
                            value={availableFor}
                            className={cx(
                              classes.appointmentTypeSelect,
                              " newSelectField ",
                            )}
                            onChange={setDayTime}
                          >
                            <option value="both">All Appointment Types</option>
                            <option value="inperson">In-Person Only</option>
                            <option value="virtual">Virtual Only</option>
                          </select>
                        </div>

                        {isEditable && (
                          <>
                            {timeSchedules.length > 1 && (
                              <div
                                className={classes.deleteScheduleIcon}
                                onClick={() =>
                                  deleteScheduleItem(
                                    scheduleItemIndex,
                                    Boolean(fromTimeError || toTimeError),
                                  )
                                }
                              >
                                <img src={DeleteIcon} alt="delete" />
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
        {isUpdatingSchedule && <Loader showLoader />}
      </CommonDialog>

      {deleteManualSchedule?.id && (
        <ConfirmModal
          className={cx("confirm-modal-center", classes.deleteDayScheduleModal)}
          isOpen
          onConfirm={deleteManualScheduleConfirmed}
          onCancel={toggleManualScheduleDeleteModal}
          onClose={toggleManualScheduleDeleteModal}
          confirmTitle="Delete"
        >
          Are you sure you want to delete the time schedule from{" "}
          {`${deleteManualSchedule?.fromTimeHour} ${deleteManualSchedule?.fromTimeOption} to `}
          {`${deleteManualSchedule?.toTimeHour} ${deleteManualSchedule?.toTimeOption}`}
          {isDeleteingManualSchedules && <Loader showLoader />}
        </ConfirmModal>
      )}

      {deleteModalOpen && (
        <ConfirmModal
          className={cx("confirm-modal-center", classes.deleteDayScheduleModal)}
          isOpen={deleteModalOpen}
          onConfirm={deleteClinicSchedule}
          onCancel={toggleDeleteModal}
          onClose={toggleDeleteModal}
          confirmTitle="Delete"
        >
          Are you sure you want to delete Schedule for the clinic{" "}
          <span className="font-weight-6">({clinic.label}) </span>
          on the date (
          <span className="font-weight-6">
            {moment(scheduleDate).format("MM/DD/YYYY")}
          </span>
          )?
          {isDeleting && <Loader showLoader />}
        </ConfirmModal>
      )}
    </div>
  );
};

export default EditProviderSchedule;
