import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import TextareaAutosize from "react-textarea-autosize";
import classes from "./Textarea.module.scss";

export function Textarea({
  name,
  rows,
  value,
  placeholder,
  onChange,
  onBlur,
  onFocus,
  className,
  isError,
  isDisabled,
  isReadonly,
  maxLength,
  maxRows,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.error]: isError,
      [classes.disabled]: isDisabled,
      [classes.readonly]: isReadonly,
    },
    className,
  );

  return (
    <TextareaAutosize
      maxLength={maxLength}
      maxRows={maxRows}
      name={name}
      rows={rows}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      disabled={isDisabled}
      readOnly={isReadonly}
      className={rootClasses}
      placeholder={placeholder}
    />
  );
}

Textarea.propTypes = {
  name: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
  isError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isReadonly: PropTypes.bool,
  maxLength: PropTypes.number,
  maxRows: PropTypes.number,
};

Textarea.defaultProps = {
  name: undefined,
  rows: 5,
  value: undefined,
  placeholder: undefined,
  onChange: undefined,
  onBlur: undefined,
  onFocus: undefined,
  className: undefined,
  isError: false,
  isDisabled: false,
  isReadonly: false,
  maxLength: undefined,
  maxRows: undefined,
};
