import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { connect } from "react-redux";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { AvailableBalance } from "../../shared/AvailableBalance/AvailableBalance";
import { formatCurrency } from "../../../../../utilities/general";
import { useCherry } from "../../hooks/useCherry";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";
import { PaymentAwaitLoader } from "./shared/PaymentAwaitLoader/PaymentAwaitLoader";
import { PendingPaymentAlert } from "./shared/PendingPaymentAlert/PendingPaymentAlert";

function ViewChargeCherry({ isCherryPaymentWatchMode }) {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();

  const onClosePaymentView = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const {
    cherryPatient,
    chargeCherry,
    cancelOngoingCherryPayment,
    cancelPendingCherryPayment,
    ignorePendingCherryPayment,
    pendingCherryPayment,
  } = useCherry({
    onPaymentSuccess: onClosePaymentView,
  });

  const amountMax =
    cherryPatient?.balance?.amount >= amountToPay
      ? amountToPay
      : cherryPatient?.balance?.amount;

  const onClose = () => {
    if (!isCherryPaymentWatchMode) {
      onClosePaymentView();
    }
  };

  const schema = yup.object({
    amount: composeAmountSchema({
      amountToPay: amountMax,
      currency: invoice?.currency,
    }),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount }) => {
        chargeCherry.initiate({ amount });
      },
      initialValues: {
        amount: amountMax,
      },
    });

  const isChargeDisabled = Boolean(
    !isValid ||
      chargeCherry.isLoading ||
      isCherryPaymentWatchMode ||
      pendingCherryPayment,
  );

  const cancelOnGoingPayment = () => {
    cancelOngoingCherryPayment.initiate();
    dispatch(checkoutInvoice.actions.changeIsCherryPaymentWatchMode(false));
  };

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.cherry")}
      imageSrc={svg.cherryPay}
      onCharge={submit}
      isCharging={chargeCherry.isLoading}
      isCancelDisabled={isCherryPaymentWatchMode}
      isChargeDisabled={isChargeDisabled}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <ModalCharge.InputWrap>
        <AvailableBalance
          amount={formatCurrency(
            cherryPatient?.balance?.amount,
            invoice?.currency,
          )}
        />
      </ModalCharge.InputWrap>
      {isCherryPaymentWatchMode && (
        <PaymentAwaitLoader
          onCancelPayment={cancelOnGoingPayment}
          isCancelling={cancelOngoingCherryPayment.isLoading}
        />
      )}
      {pendingCherryPayment && (
        <PendingPaymentAlert
          message={pendingCherryPayment.message}
          onCancelPayment={cancelPendingCherryPayment.initiate}
          onIgnore={ignorePendingCherryPayment}
          isCancelling={cancelPendingCherryPayment.isLoading}
        />
      )}
    </ModalCharge>
  );
}

ViewChargeCherry.propTypes = {
  isCherryPaymentWatchMode: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isCherryPaymentWatchMode:
    checkoutInvoice.selectors.selectIsCherryPaymentWatchMode(state),
});

export default connect(mapStateToProps)(ViewChargeCherry);
