import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { toast } from "react-toastify";
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader.js'
import AlertModal from '../../Common/AlertModal.js'
import { weekOfArray, isFormSubmit, isPositiveNumber, getTimeClockSetting, positionFooterCorrectly } from '../../../Utils/services.js';
import {emptyClockReducer,getClockPayPeriod, saveClockPayPeriod, changeClockSettingStatus} from '../../../Actions/Clock/clockActions';
import { Checkbox } from '../../../shared/Checkbox/Checkbox.js';

class TimeClockSettings extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));

    let daysHtml = []
    for (let counter = 1; counter <= 28; counter++) {
      daysHtml.push(<option key={'day-'+counter} value={counter}>{counter}</option>);
    }

    let weeksHtml = weekOfArray().map((obj,idx) => {
      return (
        <option key={'weekday-'+idx} value={(obj.value - 1)}>{obj.label}</option>
      )
    });

    let hoursHtml = [];
    for (let counter = 0; counter <= 23; counter++) {
      let strPad = counter.toString().padStart(2,'0')
      hoursHtml.push(<option key={'hours-'+strPad} value={counter}>{strPad}</option>);
    }

    let minutesHtml = [];
    for (let counter = 0; counter <= 59; counter++) {
      let strPad = counter.toString().padStart(2,'0')
      minutesHtml.push(<option key={'minutes-'+strPad} value={counter}>{strPad}</option>);
    }

    this.state = {
      settingLang: languageData.settings,
      globalLang: languageData.global,
      payPeriodData: {},
      time_clock_setting : getTimeClockSetting(),
      isTimeClockSettingChanged: false,
      isPayPeriodDataEmpty: false,
      prompt_clockout_hours: 8,
      prompt_clockout_min: 0,
    	force_clockout_hours: 10,
      force_clockout_min: 0,
      pay_period_type: "monthly",
      pay_period_type_month: '1',
      pay_period_type_week: '1',
      prompt_clockout_hoursClass: 'simpleSelect p-l-10',
      prompt_clockout_minClass: 'simpleSelect p-l-10',
    	force_clockout_hoursClass: 'simpleSelect p-l-10',
      force_clockout_minClass: 'simpleSelect p-l-10',
      pay_period_typeClass: 'simpleSelect p-l-10',
      pay_period_type_monthClass: 'simpleSelect p-l-10',
      pay_period_type_weekClass: 'simpleSelect p-l-10',
      weekOfArray: weeksHtml,
      dayOfHtml: daysHtml,
      hoursHtml: hoursHtml,
      minutesHtml: minutesHtml,
      showLoader: true,

      showAlertModal: false,
      alertMsg: '',
      alertAction: '',
      alertBtnActionLabel: 'Yes, Disable it!',
      allowOvertime: false,
    };
  }

  componentDidMount() {
    this.props.getClockPayPeriod();
    positionFooterCorrectly();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {}
    if (nextProps.payPeriodData && nextProps.payPeriodData !== prevState.payPeriodData) {
      nextProps.emptyClockReducer()
      returnState.payPeriodData = nextProps.payPeriodData;
      returnState.prompt_clockout_hours = (nextProps.payPeriodData.prompt_clockout_hours >= 0) ? nextProps.payPeriodData.prompt_clockout_hours : 8;
      returnState.prompt_clockout_min = (nextProps.payPeriodData.prompt_clockout_min >= 0) ? nextProps.payPeriodData.prompt_clockout_min : 0;
      returnState.force_clockout_hours = (nextProps.payPeriodData.force_clockout_hours >= 0) ? nextProps.payPeriodData.force_clockout_hours : 10;
      returnState.force_clockout_min = (nextProps.payPeriodData.force_clockout_min >= 0) ? nextProps.payPeriodData.force_clockout_min : 0;

      returnState.pay_period_type = (nextProps.payPeriodData.pay_period_type) ? nextProps.payPeriodData.pay_period_type : 'monthly';
      returnState.pay_period_type_month = (returnState.pay_period_type === 'monthly') ? (nextProps.payPeriodData.pay_period_starts_on) ? nextProps.payPeriodData.pay_period_starts_on : '1' : '1';
      returnState.pay_period_type_week = (returnState.pay_period_type === 'weekly') ? (nextProps.payPeriodData.pay_period_starts_on) ? nextProps.payPeriodData.pay_period_starts_on : '1' : '1';
      returnState.allowOvertime = Boolean(nextProps.payPeriodData?.overtime_enabled);
      returnState.isPayPeriodDataEmpty = false;
      returnState.showLoader = false
    }

    if (nextProps.isPayPeriodDataEmpty === true) {
      returnState.isPayPeriodDataEmpty = true;
      returnState.showLoader = false;
      nextProps.emptyClockReducer();
    }

    if (nextProps.isTimeClockSettingChanged === true) {
      returnState.isTimeClockSettingChanged = getTimeClockSetting();
      returnState.time_clock_setting = returnState.isTimeClockSettingChanged;
      returnState.showLoader = false;
      nextProps.emptyClockReducer()
    }

    if (nextProps.showLoader === false) {
      returnState.showLoader = false;
      nextProps.emptyClockReducer()
    }
    positionFooterCorrectly();
    return returnState;
  }


  handleInputChange = (event) => {
    let returnState = {}
    let value = event.target.value;
    let name = event.target.name;
    switch (event.target.type) {
      case 'checkbox':
        value = event.target.checked;
        break;
    }
    if(name === 'time_clock_setting' && !this.state.isPayPeriodDataEmpty){
      if(!value){
        returnState.showAlertModal = true;
        returnState.alertMsg = "You will not be able to track employee timings.";
        returnState.alertBtnActionLabel = "Yes, Disable it!";
        this.setState(returnState);
      } else {
        //this.setState({time_clock_setting:1});
        this.setState({showLoader:true});
        this.props.changeClockSettingStatus({is_enabled:1})
      }
    } else {
      this.setState({ [event.target.name]: value })
    }
  }

  handleSubmit = () => {
    if(isFormSubmit()){
      let error = false;
      this.setState({
        prompt_clockout_hoursClass: 'simpleSelect p-l-10',
        prompt_clockout_minClass: 'simpleSelect p-l-10',
      	force_clockout_hoursClass: 'simpleSelect p-l-10',
      	force_clockout_minClass: 'simpleSelect p-l-10',
        pay_period_typeClass: 'simpleSelect p-l-10',
        pay_period_type_monthClass: 'simpleSelect p-l-10',
        pay_period_type_weekClass: 'simpleSelect p-l-10',
      })
      let toastMessage = ''
      if (this.state.prompt_clockout_hours == 0 && this.state.prompt_clockout_min == 0){
        this.setState({ prompt_clockout_hoursClass: 'simpleSelect p-l-10 field_error', prompt_clockout_minClass: 'simpleSelect p-l-10 field_error' })
        toastMessage = this.state.globalLang.fill_atleat_prompt_clockout_hours_or_min
        error = true;
      } else {
        this.setState({ prompt_clockout_hoursClass: 'simpleSelect p-l-10', prompt_clockout_minClass: 'simpleSelect p-l-10' })
      }

      if (this.state.force_clockout_hours == 0 && this.state.force_clockout_min == 0){
        this.setState({ force_clockout_hoursClass: 'simpleSelect p-l-10 field_error', force_clockout_minClass: 'simpleSelect p-l-10 field_error' })
        if(toastMessage === ''){
          toastMessage = this.state.globalLang.fill_atleat_force_clockout_hours_or_min
        }
        error = true;
      } else {
        this.setState({ force_clockout_hoursClass: 'simpleSelect p-l-10', force_clockout_minClass: 'simpleSelect p-l-10' })
      }

      if (typeof this.state.pay_period_type_week == undefined || this.state.pay_period_type_week == null || this.state.pay_period_type_week == '') {
        this.setState({ pay_period_type_weekClass: 'simpleSelect p-l-10 field_error' })
        error = true;
      } else if (this.state.pay_period_type_week) {
        this.setState({ pay_period_type_weekClass: 'simpleSelect p-l-10' })
      }

      if(this.state.pay_period_type === 'weekly'){
        if (typeof this.state.pay_period_type_week == undefined || this.state.pay_period_type_week == null || this.state.pay_period_type_week == '') {
          this.setState({ pay_period_type_weekClass: 'simpleSelect p-l-10 field_error' })
          error = true;
        } else if (this.state.pay_period_type_week) {
          this.setState({ pay_period_type_weekClass: 'simpleSelect p-l-10' })
        }
      } else if(this.state.pay_period_type === 'monthly'){
        if (typeof this.state.pay_period_type_month == undefined || this.state.pay_period_type_month == null || this.state.pay_period_type_month == '') {
          this.setState({ pay_period_type_monthClass: 'simpleSelect p-l-10 field_error' })
          error = true;
        } else if (this.state.pay_period_type_month) {
          this.setState({ pay_period_type_monthClass: 'simpleSelect p-l-10' })
        }
      } else {
        this.setState({pay_period_typeClass: 'simpleSelect p-l-10 simpleField_error'})
        error = true;
      }

      if(error){
        if(toastMessage){
          toast.dismiss()
          toast.error((toastMessage));
        }
        return false;
      }

      let formData = {
        prompt_clockout_hours : this.state.prompt_clockout_hours,
        prompt_clockout_min : this.state.prompt_clockout_min,
        force_clockout_hours : this.state.force_clockout_hours,
        force_clockout_min : this.state.force_clockout_min,
        pay_period_type : this.state.pay_period_type,
        pay_period_starts_on: (this.state.pay_period_type === 'weekly') ? this.state.pay_period_type_week : this.state.pay_period_type_month,
        overtime_enabled: this.state.allowOvertime ? 1 : 0,
      }
      this.setState({showLoader:true})
      this.props.saveClockPayPeriod(formData);
    }
  }

  alertAction = () => {
    this.setState({showLoader:true});
    this.props.changeClockSettingStatus({is_enabled:0})
  }

  onResetAlert = (resetState) => {
    this.setState(resetState)
  }

  render() {
    return (
      <div className="main protected users-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            {(this.state.showAlertModal === true) && <AlertModal showAlertModal={this.state.showAlertModal} alertMsg={this.state.alertMsg} alertAction={this.alertAction} onResetAlert={this.onResetAlert} alertBtnActionLabel={this.state.alertBtnActionLabel} />
            }
            <div className="memberWalletOuter relative  ">
              <div className="setting-setion m-b-10">
                <div className="membership-title">Time Clock Settings
                <span className="setting-custom-switch pull-right no-margin">
                    <span id="enabled-text">
                      {this.state.time_clock_setting ? "Enabled (Please disable if you don't want to use Time Tracking)" : "Disabled (Please enable if you want to use Time Tracking)" }
                      </span>
                    <label className="setting-switch pull-right">
                      <input type="checkbox" id="pos_enabled_title" name="time_clock_setting" className=" setting-custom-switch-input" checked={this.state.time_clock_setting ? "checked" : false} value={this.state.time_clock_setting} onChange={this.handleInputChange} />
                      <span className="setting-slider" />
                    </label>
                  </span>
                </div>
              </div>
              {(this.state.time_clock_setting == true) &&
                <div className="row">
                  <div className="col-sm-12">
                    <div className="setting-setion m-b-15">
                      <div className="wallet-edit-block">
                        <div className="membershipSectionContent p20">

                          <div className="row">
                            <div className='col-sm-12 col-xs-12'>
                                <div className="inlineTextInput"><span>If employee stays clocked in for more than</span>
                                  <div className="simpleField">
                                    <div className="simpleLabel">Hrs</div>
                                    <select onChange={this.handleInputChange} name="prompt_clockout_hours" className={this.state.prompt_clockout_hoursClass} value={this.state.prompt_clockout_hours}>
                                      {this.state.hoursHtml}
                                    </select>
                                  </div>
                                  <div className="simpleField">
                                    <div className="simpleLabel">Min</div>
                                  <select onChange={this.handleInputChange} name="prompt_clockout_min" className={this.state.prompt_clockout_hoursClass} value={this.state.prompt_clockout_min}>
                                    {this.state.minutesHtml}
                                  </select>
                                </div>
                                  <span>prompt the clock out screen.</span>
                                </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className='col-sm-12 col-xs-12'>
                                <div className="inlineTextInput"><span>If employee stays clocked in for more than</span>
                                  <div className="simpleField">
                                    <div className="simpleLabel">Hrs</div>
                                    <select onChange={this.handleInputChange} name="force_clockout_hours" className={this.state.force_clockout_hoursClass} value={this.state.force_clockout_hours}>
                                      {this.state.hoursHtml}
                                    </select>
                                  </div>
                                  <div className="simpleField">
                                    <div className="simpleLabel">Min</div>
                                    <select onChange={this.handleInputChange} name="force_clockout_min" className={this.state.force_clockout_minClass} value={this.state.force_clockout_min}>
                                      {this.state.minutesHtml}
                                    </select>
                                  </div>
                                  <span>clock out automatically.</span>
                                </div>
                            </div>
                          </div>
                          
                          <div className="row m-b-10">
                            <div className="col-sm-12">
                              <Checkbox 
                                size="small" 
                                label="Allow overtime" 
                                isChecked={this.state.allowOvertime} 
                                onChange={() => this.setState({
                                  allowOvertime: !this.state.allowOvertime,
                                })} 
                              />
                            </div>
                          </div>

                          <div className="row">
                              <div className='col-sm-4 col-xs-12'>
                                <div className="simpleField">
                                  <div className="simpleLabel">Pay Period Type<span className="setting-require">*</span></div>
                                  <select onChange={this.handleInputChange} name="pay_period_type" className={this.state.pay_period_typeClass} value={this.state.pay_period_type}>
                                    <option value="weekly">Weekly</option>
                                    <option value="monthly">Monthly</option>
                                  </select>
                              </div>
                            </div>

                          {(this.state.pay_period_type == 'monthly') && <div className='col-sm-4 col-xs-12'>
                            <div className="simpleField">
                              <div className="simpleLabel">Pay Period Start On (Day of Month)<span className="setting-require">*</span></div>
                              <select onChange={this.handleInputChange} name="pay_period_type_month" className={this.state.pay_period_type_weekClass} value={this.state.pay_period_type_month}>
                                {this.state.dayOfHtml}
                              </select>
                            </div>
                          </div>}

                          {(this.state.pay_period_type == 'weekly') && <div className='col-sm-4 col-xs-12'>
                            <div className="simpleField">
                              <div className="simpleLabel">Pay Period Start On (Day of Week)<span className="setting-require">*</span></div>
                              <select onChange={this.handleInputChange} name="pay_period_type_week" className={this.state.pay_period_type_weekClass} value={this.state.pay_period_type_week}>
                                {this.state.weekOfArray}
                              </select>
                            </div>
                          </div>}
                        </div>

                        </div>
                      </div>
                      <div className="footer-static">
                        <input className="new-blue-btn pull-right" id="editsave" onClick={this.handleSubmit} type="submit" value="Save" />
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    )
  }
}


function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.ClockReducer.action === "GET_PAY_PERIOD") {
    if (state.ClockReducer.data.status === 200) {
      if(state.ClockReducer.data.data){
        returnState.payPeriodData = state.ClockReducer.data.data;
      } else {
        returnState.isPayPeriodDataEmpty = true
      }
    } else {
      returnState.showLoader = false
      toast.error(languageData.global[state.ClockReducer.data.message]);
    }
  }
  if (state.ClockReducer.action === "SAVE_PAY_PERIOD") {
    if (state.ClockReducer.data.status === 200) {
      returnState.payPeriodData = state.ClockReducer.data.data;
      returnState.showLoader = false
      toast.success(languageData.global[state.ClockReducer.data.message]);
    } else {
      returnState.showLoader = false
      toast.error(languageData.global[state.ClockReducer.data.message]);
    }
  }
  if (state.ClockReducer.action === "CHANGE_CLOCK_SETTING_STATUS") {
    if (state.ClockReducer.data.status === 200) {
      returnState.isTimeClockSettingChanged = true;
      returnState.showLoader = false
      toast.success(languageData.global[state.ClockReducer.data.message]);
    } else {
      returnState.showLoader = false
      toast.error(languageData.global[state.ClockReducer.data.message]);
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({emptyClockReducer:emptyClockReducer, getClockPayPeriod:getClockPayPeriod, saveClockPayPeriod:saveClockPayPeriod, changeClockSettingStatus:changeClockSettingStatus  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TimeClockSettings));
