import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    questionnaires: yup.array().of(
      yup.object({
        id: yup.number().required(),
        consultationTitle: yup.string().required(),
        questionsCount: yup.number().required(),
      }),
    ),
  }),
);

const composeResponse = (res, req) => {
  const data = unwrapOr(() => res.data.data.data, []);
  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data, {}),
      pageSize: req.pageSize,
    }),
    questionnaires: data.map((p) => ({
      id: p.id,
      consultationTitle: p.consultation_title,
      questionsCount: p.questions_count || 0,
    })),
  };
};

function createQuestionnairesQueryKey({ searchTerm, pageSize }) {
  return [QUERY_KEYS.questionnaires, searchTerm, pageSize].filter(Boolean);
}

export function useQuestionnairesQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createQuestionnairesQueryKey({
      searchTerm: req.searchTerm,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getQuestionnaires(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          term: req.searchTerm || null,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
