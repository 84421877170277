import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { isEqual } from "lodash";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import {
  clearRecentlyDeletedPaperworkAction,
  clearRestorePaperworkAction,
  getRecentlyDeletedPaperworkAction,
  restoreRecentlyDeletedPaperworkAction,
} from "../../../../Actions/Settings/settingsActions.js";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { format } from "date-fns";
import moment from "moment";
import { autoScrolling } from "../../../../Utils/services.js";
import Loader from "../../../Common/Loader.js";
import PaperworkTabs from "./components/PaperworkTabs.js";
import PaperworkTable from "./components/PaperworkTable.js";
import ConfirmationModal from "../../../Appointment/Calendar/ConfirmationModal/ConfirmationModal";
import PaperworkHeader from "./components/PaperworkHeader.js";

localStorage.removeItem("rdpDateRange");

class RecentlyDeleted extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0)
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    
    const rdpDateRange = localStorage.getItem("rdpDateRange");
    const valR = rdpDateRange ? JSON.parse(rdpDateRange) : "";
    const startDate = valR.startDate;
    const endDate = valR.endDate;
    
    this.state = {
      globalLang: languageData.global,
      settingsLang: languageData.settings,
      recentlyDeletedList: [],
      selected: [],
      next_page_url: "",
      showLoadingText: false,
      page: 1,
      pagesize: 15,
      isShowDeletedModal: false,
      dateRangePicker: {
        selection: {
          startDate: (startDate) ? new Date(startDate) : new Date(),
          endDate: (endDate) ? new Date(endDate) :new Date(),
          key: "selection",
        },
      },
      to_date : (endDate) ? moment(endDate).format("YYYY-MM-DD") : moment().endOf("day").format("YYYY-MM-DD"),
      from_date : (startDate) ? moment(startDate).format("YYYY-MM-DD") : moment().startOf("day").format("YYYY-MM-DD"),
      showCalendar: false,
      term: "",
      clicked: 0,
      selectAll: false,
      innerbox_select_all: false,
      activeTab: "questionnaires",
    }
    
    window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
      if (document.documentElement.offsetHeight - (window.innerHeight + scrollTop) <=5  && this.state.next_page_url != null) {
        this.loadMore();
      }
    };
  }
  
  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
    let formData = {
      params: {
        term: this.state.term,
        page: this.state.page,
        pagesize: this.state.pagesize,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
      }
    }
    autoScrolling(true)
    this.props.getRecentlyDeletedPaperworkAction(formData, this.state.activeTab);
  }

  componentDidUpdate(prevProps, prevState) {
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    if (this.props.paperworkData && !isEqual(this.props.paperworkData, prevProps.paperworkData)) {
      const returnState = {};
      autoScrolling(false);

      returnState.recentlyDeletedList = [
        ...prevState.recentlyDeletedList,
        ...this.props.paperworkData.data,
      ];
      returnState.next_page_url = this.props.paperworkData.next_page_url;
      returnState.showLoadingText = false;

      if (this.props.paperworkData.next_page_url) {
        returnState.page = prevState.page + 1;
      }
      this.setState(returnState);
    }

    if (this.props.restorePaperworkSuccess && this.props.restorePaperworkSuccess !== prevProps.restorePaperworkSuccess) {
      toast.success(languageData.global[this.props.restorePaperworkSuccess]);
      this.props.clearRestorePaperworkAction();
      const formData = {
        params: {
          page: 1,
          pagesize: this.state.pagesize,
          term: this.state.term,
          from_date: this.state.from_date,
          to_date: this.state.to_date,
        }
      };
      this.setState({
        page: 1,
        next_page_url: "",
        recentlyDeletedList: [],
        selected: [],
        innerbox_select_all: false,
      })
      autoScrolling(true);
      this.props.getRecentlyDeletedPaperworkAction(formData, this.state.activeTab);
    }
  }

  componentWillUnmount() {
    this.props.clearRecentlyDeletedPaperworkAction();
    document.removeEventListener("click", this.handleClick, false);
  }
  
  handleAllChecked = (event) => {
    const allCheckboxChecked = event.target.checked;
    const { recentlyDeletedList } = this.state;
    
    let selected = [];
    if (allCheckboxChecked) {
      if (recentlyDeletedList && recentlyDeletedList.length > 0) {
        recentlyDeletedList.map((obj) => {
          selected.push(obj.id);
        });
      }
    }
    this.setState({ innerbox_select_all: allCheckboxChecked, selected });
  }
  
  handlChecked  = (id, event) => {
    const checked = event.target.checked;
    let selected = this.state.selected;
    let innerbox_select_all = false;
    let existOrNot = this.state.selected.indexOf(id);
    if (existOrNot > -1 && !checked) {
      selected.splice(existOrNot, 1);
    } else {
      selected.push(id);
    }
    if (this.state.recentlyDeletedList && this.state.recentlyDeletedList.length  == selected.length){
      innerbox_select_all = true
    }
    this.setState({ selected: selected, innerbox_select_all: innerbox_select_all });
  }
  
  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    const idx = target.id;
    let returnState = {};
    returnState.dataChanged = true;
    switch (target.type) {
      case "checkbox":
        value = (target.checked) ? true : false;
        break;
    }
    returnState[name] = value;
    returnState[idx] = value;
    this.setState(returnState);
    if (target.type === "checkbox") {
      let id = parseInt(idx.split("-")[1]);
      let selected = this.state.selected;
      let existOrNot = this.state.selected.indexOf(id);
      if (existOrNot > -1 && value != true) {
        selected.splice(existOrNot, 1);
      } else {
        selected.push(id);
      }
      this.setState({ selected: selected });
    }
  }
  
  handleClick = (e) => {
    if (!(this.node.contains(e.target) && this.state.showCalendar === true)) {
      this.toggleCalendar(e.target);
    }
  }
  
  toggleCalendar = (elem) => {
    if (!(elem.name !== "calendar-input" && this.state.showCalendar === false)) {
      const showCalendar = this.state.showCalendar === false && elem.name === "calendar-input";
      this.setState({ showCalendar })
    }
  }
  
  handleSubmit = (event, value) => {
    if (typeof event === "object" ) {
      event.preventDefault();
    }
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        from_date: (value && value.from_date) ? value.from_date : this.state.from_date,
        to_date: (value && value.to_date) ? value.to_date : this.state.to_date,
      }
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      next_page_url: "",
      recentlyDeletedList: [],
      next_page_url: "",
      innerbox_select_all: false
    });
    autoScrolling(true)
    this.props.getRecentlyDeletedPaperworkAction(formData, this.state.activeTab);
  }
  
  loadMore = () => {
    if(!autoScrolling()){
      this.setState({ showLoadingText: true });
      const formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          term: this.state.term,
          from_date: this.state.from_date,
          to_date: this.state.to_date,
        }
      }
      autoScrolling(true);
      this.props.getRecentlyDeletedPaperworkAction(formData, this.state.activeTab);
    }
  }

  handleRestoreModal = () => {
    this.setState({ isShowDeletedModal: !this.state.isShowDeletedModal })
  }
  
  restoreSelected = () => {
    this.props.clearRestorePaperworkAction();
    this.props.restoreRecentlyDeletedPaperworkAction(
      { recently_deleted_ids: this.state.selected }, this.state.activeTab
    );
    this.setState({ isShowDeletedModal: !this.state.isShowDeletedModal })
  }

  handleRangeChange = (which, payload) => {
    if(payload){
      let payloadValue = {
        startDate: payload.selection.startDate,
        endDate: payload.selection.endDate,
        key: "selection",
      }
      localStorage.setItem("rdpDateRange", JSON.stringify(payloadValue));
    }
    let startDate = payload.selection.startDate;
    let endDate   = payload.selection.endDate;
    startDate     = format(startDate, "YYYY-MM-DD");
    endDate       = format(endDate, "YYYY-MM-DD");
    
    let clicked   = this.state.clicked + 1;
    
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = (localPref && localPref === "oneClick") ? true : false;
    
    if (canBypass) {
      clicked = 2;
    }
    
    const showCalendar = clicked % 2 !== 0;
    
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
      showCalendar : showCalendar,
      from_date    : startDate,
      to_date      : endDate,
      clicked      : clicked
    });
    
    if ( clicked && !showCalendar ) {
      this.handleSubmit(which, {"from_date" : startDate, "to_date" : endDate});
    }
  }

  handleTabChange = (tab) => {
    const formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        term: this.state.term,
        from_date: this.state.from_date,
        to_date: this.state.to_date,
      }
    };
    this.setState({ 
      activeTab: tab,
      selected: [],
      page: 1,
      next_page_url: "",
      recentlyDeletedList: [],
      innerbox_select_all: false,
    });
    autoScrolling(true);
    this.props.getRecentlyDeletedPaperworkAction(formData, tab);
  }

  render() {
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <PaperworkHeader
                handleSubmit={this.handleSubmit}
                term={this.state.term}
                handleInputChange={this.handleInputChange}
                calendarRef={node => this.node = node}
                showCalendar={this.state.showCalendar}
                dateRangePickerSelection={this.state.dateRangePicker.selection}
                handleRangeChange={this.handleRangeChange}
                from_date={this.state.from_date}
                to_date={this.state.to_date}
                selected={this.state.selected}
                handleRestoreModal={this.handleRestoreModal}
              />
              <PaperworkTabs
                activeTab={this.state.activeTab}
                handleTabChange={this.handleTabChange}
              />
              <PaperworkTable 
                innerbox_select_all={this.state.innerbox_select_all}
                handleAllChecked={this.handleAllChecked}
                recentlyDeletedList={this.state.recentlyDeletedList}
                selected={this.state.selected}
                handlChecked={this.handlChecked}
                showLoader={this.props.isFetching}
              />
              <div
                className={(this.state.showLoadingText) ?
                  "loading-please-wait no-margin-top" :
                  "loading-please-wait no-margin-top no-display "}
              >
                {this.state.globalLang.loading_please_wait_text}
                </div>
            </div>
          </div>

          <ConfirmationModal
            isOpen={this.state.isShowDeletedModal}
            confirmFn={this.restoreSelected}
            cancelFn={this.handleRestoreModal}
            message="Are you sure you want to restore selected paperwork? The paperwork will be restored within 10 minutes."
            title={this.state.globalLang.delete_confirmation}
          />
          <Loader showLoader={this.props.isFetching} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  const paperworkError = state.settingRecentlyDeletedPaperworkReducer.paperworkData.isError;
  const restorePaperworkError = state.settingRecentlyDeletedPaperworkReducer.restorePaperworkData.isError;

  paperworkError && toast.error(languageData.global[paperworkError.message] || "Something went wrong", { toastId: 1 });
  restorePaperworkError && toast.error(languageData.global[restorePaperworkError.message] || "Something went wrong", { toastId: 1 });

  return {
    isFetching: state.settingRecentlyDeletedPaperworkReducer.isFetching,
    paperworkData: state.settingRecentlyDeletedPaperworkReducer.paperworkData.data,
    restorePaperworkSuccess: state.settingRecentlyDeletedPaperworkReducer.restorePaperworkData.data,
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    clearRecentlyDeletedPaperworkAction,
    clearRestorePaperworkAction,
    getRecentlyDeletedPaperworkAction,
    restoreRecentlyDeletedPaperworkAction,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(RecentlyDeleted);
