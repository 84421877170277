/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import moment from "moment";
import InsightsSideBar from "../InsightsSideBar.js";
import PatientsTitle from "./PatientsTitle.js";
import PatientsReportTitle from "./PatientsReportTitle.js";
import PatientsReportOptions from "./PatientsReportOptions.js";
import Loader from "../../Common/Loader.js";
import {
  autoScrolling,
  getCurrencySymbol,
  isFormSubmit,
  showFormattedDate,
} from "../../../Utils/services.js";
import {
  resetReducerAction,
  getOptionAndList,
  saveReport,
  getReportVariables,
  getReportData,
  downloadReportData,
  getReportDetail,
  saveSmsFilterReport,
  shareReportData,
} from "../../../Actions/BusinessInsights/businessInsightActions.js";
import SaveReport from "../SaveReport.js";
import PatientReferralReport from "./PatientReferralReport.js";
import PatientsInsightDetailReport from "./PatientsInsightDetailReport.js";
import SaveList from "../saveList";
import SaveShareList from "../SaveShareList.js";
import { SHARE_REPORT_SERVICES } from "../../../consts/api.js";
import { LeadArShare } from "./components/LeadArShare";
import { getMailchimpData } from "../../../Actions/Integrations/integrationActions.js";
import { LoadMoreObserver } from "../../../shared/LoadMoreObserver/LoadMoreObserver.js";
import { SortAdornment } from "../../../shared/SortAdornment/SortAdornment.js";
import ExpirationActionButtons from "./components/ExpirationActionButtons.js";
import { withExpirationActionButtons } from "./withExpirationActionButtons.js";
import AgreementModal from "./components/AgreementModal.js";

class PatientsInsightReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      showCreateOptions: false,
      type: "clients",
      report_type_sysname: "",
      report_type_id: "",
      reportOptionAndList: {},
      reportOptions: props.reportOptions ? props.reportOptions : [],
      reportVariableData: [],
      reportData: {},

      languageData: languageData.business_insights,
      page: 1,
      pagesize: 15,
      startFresh: true,
      next_page_url: "",
      showLoadingText: false,
      tableColumns: [],
      reportListData: [],
      totalRow: null,
      grammar: null,
      graphData: [],

      mailchimpIntegrationStatus: {
        isLoaing: true,
        isConnect: false,
      },

      reportType: this.props.match.params.reportType
        ? this.props.match.params.reportType
        : "create",
      reportID: this.props.match.params.reportID
        ? this.props.match.params.reportID
        : 0,
      isBreadcrumbs:
        this.props.match.params && this.props.match.params.reportID > 0
          ? false
          : true,
      insightID:
        this.props.match.params && this.props.match.params.insightID > 0
          ? this.props.match.params.insightID
          : 0,

      showTableSection: false,
      dataLoadedOnce: false,
      showNameSection: false,
      reportNameClass: "simpleInput",
      listNameClass: "simpleInput",
      downloadData: {},
      showSaveListModal: false,
      listName: "",
      showGeneralReport: true,
      reportDetail: {},
      detailReportListData: [],
      detailTableColumns: [],
      business_insight_child_id: 0,
      clinicName: "",
      clinic_child_id: 0,
      detailReportFlag: "",

      isAverageSizeDetail: false,
      isAverageVisitDetail: false,
      isTopVipClientDetail: false,

      share_login_url: [],
      shareObject: null,
      showShareNameModal: false,
      shareQueryString: {},
      shareNameList: "",
      shareNameListClass: "simpleInput",
      childWindowListener: false,
      shareApiKey: "",
      shareApiKeyClass: "simpleInput",
      shareApiUrl: "",
      shareApiUrlClass: "simpleInput",
      shareReportType: "",
      clinics: [],
      reports_without_clinics: [
        "average-transaction-size",
        "average-number-of-visits-per-year",
        "top-20-percent-vip-patients-who-spend-the-most-with-our-practice",
        "patient-discounts",
        "leads-conversion",
        "documents-expiration",
      ],
      clinic_id: 0,
      selectedGraphOption: "",
      availableSources: [],
      reportFilter: {},
      sortBy: "date",
      sortOrder: "desc",

      isLeadArShareOpen: false,
      showExpirationModal: null,
      searchTerm: "",
    };
  }

  componentDidMount() {
    autoScrolling(true);
    this.setState({ showLoader: true });
    let reportOptionFilter = {};
    if (
      this.state.reportType === "view" &&
      this.state.reportID &&
      this.state.insightID
    ) {
      this.setState({
        dataLoadedOnce: true,
        selected_report_type_id: this.state.insightID,
      });
      reportOptionFilter.page = this.state.page;
      reportOptionFilter.pagesize = this.state.pagesize;
      reportOptionFilter.business_insight_reports_id = this.state.reportID;
      this.props.getReportData(this.state.insightID, reportOptionFilter);
    } else {
      if (this.state.reportType === "edit" && this.state.reportID > 0) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
      }
      this.props.getOptionAndList(this.state.type, reportOptionFilter);
    }

    this.props
      .getMailchimpData()
      .then((res) => {
        this.setState({
          mailchimpIntegrationStatus: {
            ...this.state.mailchimpIntegrationStatus,
            isConnect: Boolean(
              res?.data?.is_enabled === 1 && res?.data?.auth_data?.access_token,
            ),
          },
        });
      })
      .finally(() => {
        this.setState({
          mailchimpIntegrationStatus: {
            ...this.state.mailchimpIntegrationStatus,
            isLoaing: false,
          },
        });
      });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.reportData !== undefined &&
      nextProps.reportData !== prevState.reportData &&
      nextProps.reportDataTimeStamp != prevState.reportDataTimeStamp
    ) {
      returnState.reportData = nextProps.reportData;
      returnState.reportDataTimeStamp = nextProps.reportDataTimeStamp;
      returnState.tableColumns = nextProps.reportData.columns
        ? nextProps.reportData.columns
        : prevState.tableColumns;

      if (prevState.reportListData && prevState.reportListData.length === 0) {
        returnState.insightReportData = nextProps.reportData;
        if (nextProps.reportData.next_page_url === null) {
          returnState.next_page_url = nextProps.reportData.next_page_url;
        }
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
        returnState.reportListData = nextProps.reportData.data
          ? nextProps.reportData.data
          : [];
        returnState.showTableSection = true;
        returnState.dataLoadedOnce = true;
        returnState.totalRow = nextProps.reportData.total
          ? nextProps.reportData.total
          : 0;
        returnState.grammar = nextProps.reportData.grammar
          ? nextProps.reportData.grammar
          : "";
        returnState.clinics = nextProps.reportData.clinics
          ? nextProps.reportData.clinics
          : "";
        returnState.share_login_url = nextProps.reportData.share_login_url
          ? nextProps.reportData.share_login_url
          : [];
      } else if (
        prevState.insightReportData !== nextProps.reportData &&
        prevState.insightReportData.length !== 0
      ) {
        if (prevState.page === 1) {
          returnState.reportListData = nextProps.reportData.data;
        }
        if (prevState.page !== 1) {
          returnState.reportListData = [
            ...prevState.reportListData,
            ...nextProps.reportData.data,
          ];
        }
        returnState.insightReportData = nextProps.reportData;
        returnState.next_page_url = nextProps.reportData.next_page_url;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }
      if (prevState.selected_report_type_id == 11) {
        returnState.graphData = nextProps.reportData.graph;
      }
      returnState.shareObject = null;
      autoScrolling(false);
    } else if (
      nextProps.reportOptionAndList !== undefined &&
      nextProps.reportOptionAndList !== prevState.reportOptionAndList
    ) {
      returnState.reportOptionAndList = nextProps.reportOptionAndList;
      returnState.reportOptions = nextProps.reportOptionAndList.reportOptions;
      returnState.allData = nextProps.reportOptionAndList.allData;
      let data = nextProps.reportOptionAndList.allData.templates.find(
        (y) =>
          y.id == nextProps.reportOptionAndList.allData.business_insight_id,
      );
      returnState.sms_filter_id = data != undefined ? data.sms_filter_id : "";
      returnState.showLoader = false;
      if (
        prevState.reportType !== "view" &&
        prevState.showCreateOptions === false &&
        prevState.dataLoadedOnce === false
      ) {
        returnState.showCreateOptions = true;
      }
    } else if (
      nextProps.reportVariableData &&
      nextProps.reportVariableData !== prevState.reportVariableData
    ) {
      returnState.reportVariableData = nextProps.reportVariableData;
      returnState.showLoader = false;
    } else if (
      nextProps.downloadData !== undefined &&
      nextProps.downloadData !== prevState.downloadData
    ) {
      returnState.showLoader = false;
      returnState.downloadData = nextProps.downloadData;
      nextProps.resetReducerAction();
      window.open(
        process.env.REACT_APP_API_URL +
          "download-data/" +
          nextProps.downloadData.file,
        "_blank",
      );
    } else if (
      nextProps.redirect !== undefined &&
      nextProps.redirect === true
    ) {
      nextProps.resetReducerAction();
      toast.success(nextProps.message);
      nextProps.history.push(`/business-insight/${prevState.type}`);
    } else if (
      nextProps.showLoader !== undefined &&
      nextProps.showLoader === false
    ) {
      returnState.showLoader = false;
      nextProps.resetReducerAction();
    } else if (
      nextProps.reportDetail !== undefined &&
      nextProps.reportDetail !== prevState.reportDetail
    ) {
      returnState.reportDetail = nextProps.reportDetail;
      returnState.detailReportListData = [
        ...(prevState.detailReportListData || []),
        ...(nextProps.reportDetail.data || []),
      ];
      returnState.detailReportCurrentPage =
        nextProps.reportDetail.current_page || 1;
      returnState.detailReportHasNextPage = Boolean(
        nextProps.reportDetail.next_page_url,
      );
      returnState.grammar = nextProps.reportDetail.grammar
        ? nextProps.reportDetail.grammar
        : "";
      returnState.detailTableColumns = nextProps.reportDetail.columns
        ? nextProps.reportDetail.columns
        : prevState.detailTableColumns;
      returnState.showGeneralReport = false;
      returnState.isAverageSizeDetail = false;
      returnState.isAverageVisitDetail = false;
      returnState.isTopVipClientDetail = false;
      returnState.showLoader = false;

      switch (prevState.business_insight_child_id) {
        case 37:
          returnState.isAverageSizeDetail = true;
          returnState.detailReportFlag = "isAverageSizeDetail";
          break;
        case 38:
          returnState.isAverageVisitDetail = true;
          returnState.detailReportFlag = "isAverageVisitDetail";
          break;
        case 39:
          returnState.isTopVipClientDetail = true;
          returnState.detailReportFlag = "isTopVipClientDetail";
          break;
        default:
          returnState.showGeneralReport = true;
          break;
      }
      autoScrolling(true);
      nextProps.resetReducerAction();
    }

    if (
      nextProps.redirect != undefined &&
      nextProps.redirect == true &&
      nextProps.redirectTime != prevState.redirectTime &&
      nextProps.dataValue != undefined
    ) {
      let value = nextProps.dataValue.sms_list_filter_report_id;
      returnState.sms_list_filter_report_id =
        nextProps.dataValue.sms_list_filter_report_id;
      nextProps.history.push(`/inbox/campaign/${value}`);
    }
    return returnState;
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.reportSavedData !== null &&
      this.state.reportSavedData !== "" &&
      this.state.reportSavedData !== prevState.reportSavedData &&
      this.state.savedStatus !== null &&
      this.state.savedStatus !== ""
    ) {
      toast.dismiss();
      toast.success(this.state.globalLang[this.state.savedStatus]);
      return (
        <div>
          {this.props.history.push(`/business-insight/${this.state.type}`)}
        </div>
      );
    }
    if (prevState.selectedGraphOption !== this.state.selectedGraphOption) {
      let formData = this.state.reportFilter;
      formData.page = 1;
      formData.pagesize = this.state.pagesize;
      formData.clinic_id = this.state.clinic_id;
      formData.source_name = this.state.selectedGraphOption.toLowerCase();
      if (this.state.reportType === "view") {
        if (
          ["mtd", "qtd", "ytd", "custom"].includes(this.props.reportData.date)
        ) {
          formData.date = this.props.reportData.date;
          if (this.props.reportData.date === "custom") {
            formData.from_date = this.props.reportData.from_date;
            formData.to_date = this.props.reportData.to_date;
          }
        } else {
          const formatedDate = this.props.reportData.date.split("|");
          formData.date = "custom";
          formData.from_date = formatedDate[0];
          formData.to_date = formatedDate[1];
        }
      }
      this.props.getReportData(
        this.state.report_type_id || this.state.insightID,
        formData,
      );
      this.setState({ showLoader: true });
    }
    if (
      !prevProps.reportData &&
      this.props.reportData &&
      this.props.reportData.graph
    ) {
      const availableSources = this.props.reportData.graph.filter(
        (data) => data.count > 0,
      );
      this.setState({ availableSources: availableSources });
    }

    if (
      prevProps.reportData &&
      this.props.reportData &&
      prevProps.reportData.date !== this.props.reportData.date
    ) {
      this.setState({ selectedGraphOption: "" });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("message", this.handleChildWindow, false);
  }

  loadMore = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      autoScrolling(true);
      let reportOptionFilter = {};
      reportOptionFilter.search_term = this.state.searchTerm;
      if (
        this.state.reportType === "view" &&
        this.state.reportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;
        if (this.state.clinic_id) {
          reportOptionFilter.clinic_id = this.state.clinic_id;
        }
        this.setState({ showLoadingText: true, startFresh: false });
        this.props.getReportData(this.state.insightID, reportOptionFilter);
      } else {
        reportOptionFilter = JSON.parse(
          JSON.stringify(this.state.reportFilter),
        );
        reportOptionFilter.page = this.state.page;
        reportOptionFilter.pagesize = this.state.pagesize;
        reportOptionFilter.search_term = this.state.searchTerm;
        if (this.state.clinic_id) {
          reportOptionFilter.clinic_id = this.state.clinic_id;
        }
        if (reportOptionFilter) {
          this.setState({ showLoadingText: true, startFresh: false });
          this.props.getReportData(
            reportOptionFilter.report_type_id,
            reportOptionFilter,
          );
        } else {
          autoScrolling(false);
        }
      }
    });
  };

  setSaveList = () => {
    this.setState({ showSaveListModal: true });
  };
  handleSearchInputChange = (event) => {
    this.setState({ searchTerm: event.target.value });
  };

  handleSearchSubmit = (event) => {
    event.preventDefault();
    this.setState({ showLoader: true, page: 1 });
    this.props
      .getReportData(this.state.report_type_id, {
        ...this.state.requestData,
        search_term: this.state.searchTerm,
        sort_by: this.state.sortBy,
        sort_order: this.state.sortOrder,
      })
      .then((response) =>
        this.setState({
          reportListData: response.data.data,
        }),
      );
  };
  showCreateOptions = (reportType) => {
    if (reportType === "create") {
      return (
        <div>
          {this.props.history.push(
            `/business-insight/${this.state.type}/${reportType}`,
          )}
        </div>
      );
    } else {
      this.setState({ showCreateOptions: true });
    }
  };

  hideCreateOptions = (childSate) => {
    if (this.state.dataLoadedOnce === false) {
      return (
        <div>
          {this.props.history.push(`/business-insight/${this.state.type}`)}
        </div>
      );
    } else {
      this.setState(childSate);
    }
  };

  getData = (childSate) => {
    if (isFormSubmit()) {
      autoScrolling(true);
      this.setState(childSate);

      let formData = childSate.reportFilter;
      formData.page = 1;
      formData.pagesize = this.state.pagesize;
      if (this.state.selectedGraphOption) {
        formData.source_name = this.state.selectedGraphOption;
      }
      this.setState(
        {
          reportListData: [],
          showLoader: true,
          page: 1,
          startFresh: true,
          next_page_url: "",
          selected_report_type_id: childSate.report_type_id,
          selected_report_type_sysname: childSate.report_type_sysname,
          totalRow: null,
          grammar: null,
          clinic_id: 0,
          requestData: formData,
        },
        () => {
          this.props.getReportData(childSate.report_type_id, formData);
        },
      );
    }
  };
  getReportVariables = (childSate, isVariableData) => {
    this.setState(childSate);
    if (isVariableData) {
      this.props.getReportVariables(childSate.report_type_id);
    }
  };

  downloadData = () => {
    let reportOptionFilter = {};
    if (this.state.showGeneralReport) {
      if (
        this.state.reportType === "view" &&
        this.state.reportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
        reportOptionFilter.is_download = 1;

        if (this.state.clinic_id && this.state.insightID == 11) {
          reportOptionFilter.clinic_id = this.state.clinic_id;
        }

        this.setState({ showLoader: true });
        this.props.downloadReportData(this.state.insightID, reportOptionFilter);
      } else {
        reportOptionFilter = JSON.parse(
          JSON.stringify(this.state.reportFilter),
        );
        if (this.state.clinic_id) {
          reportOptionFilter.clinic_id = this.state.clinic_id;
        }
        reportOptionFilter.is_download = 1;
        this.setState({ showLoader: true });
        this.props.downloadReportData(
          reportOptionFilter.report_type_id,
          reportOptionFilter,
        );
      }
    } else {
      if (
        this.state.reportType === "view" &&
        this.state.reportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
      } else {
        reportOptionFilter = JSON.parse(
          JSON.stringify(this.state.reportFilter),
        );
      }
      reportOptionFilter.is_download = 1;
      if (
        this.state.isAverageSizeDetail ||
        this.state.isAverageVisitDetail ||
        this.state.isTopVipClientDetail
      ) {
        reportOptionFilter.clinic_id = this.state.clinic_child_id;
      }
      this.setState({ showLoader: true });
      this.props.downloadReportData(
        this.state.business_insight_child_id,
        reportOptionFilter,
      );
    }
  };

  getReportName = () => {
    this.setState({ showNameSection: true });
  };

  hideNameSection = (childState) => {
    this.setState(childState);
  };

  hideModal = (childState) => {
    this.setState(childState);
  };

  setList = () => {
    let reportName = this.state.listName;
    if (this.state.grammar === null || this.state.grammar === undefined) {
      toast.dismiss();
      toast.error("Grammar is missing");
      return false;
    }
    if (reportName && reportName.trim() !== "") {
      this.setState({
        showLoader: true,
        reportNameError: true,
        reportName: "",
        showNameSection: false,
      });

      let reportFilter = this.state.reportFilter;
      reportFilter.name = reportName;
      reportFilter.sms_list_filter_id = this.state.sms_filter_id;
      if (this.state.grammar) {
        reportFilter.grammar = this.state.grammar;
      }
      this.props.saveSmsFilterReport(reportFilter);
    } else {
      this.setState({ reportNameError: false });
      return;
    }
  };

  saveThisReport = () => {
    let reportName = this.state.reportName;
    if (this.state.grammar === undefined || this.state.grammar === null) {
      toast.dismiss();
      toast.error("Grammar is missing");
      return false;
    }

    if (reportName && reportName.trim() !== "") {
      this.setState({
        showLoader: true,
        reportNameClass: "simpleInput",
        reportName: "",
        showNameSection: false,
      });

      let reportFilter = this.state.reportFilter;
      reportFilter.business_insight_id = this.state.reportFilter.report_type_id;
      reportFilter.name = reportName;
      reportFilter.grammar = this.state.grammar;
      if (
        this.state.reportID &&
        this.state.reportID > 0 &&
        this.state.reportType === "edit"
      ) {
        reportFilter.business_insight_report_id = this.state.reportID;
      }
      reportFilter.sort_by =
        this.state.sortBy ||
        (this.state.report_type_sysname === "leads-conversion"
          ? "bi_conversion_date"
          : "");
      reportFilter.sortOrder = this.state.sortOrder;
      if (this.state.report_type_sysname === "documents-expiration") {
        reportFilter.document_expiration_date = this.state.reportFilter.date;
      }
      this.props.saveReport(this.state.type, reportFilter);
    } else {
      this.setState({ reportNameClass: "simpleInput field-error" });
      return;
    }
  };

  handleChildState = (childState) => {
    this.setState(childState);
    autoScrolling(false);
  };

  goToDetail = (reportTypeId, clinicId, clinicName, page = 1) => {
    if (reportTypeId && clinicId) {
      let reportOptionFilter = {};
      if (
        this.state.reportType === "view" &&
        this.state.reportID &&
        this.state.insightID
      ) {
        reportOptionFilter.business_insight_reports_id = this.state.reportID;
      } else {
        reportOptionFilter = JSON.parse(
          JSON.stringify(this.state.reportFilter),
        );
      }
      reportOptionFilter.clinic_id = clinicId;
      this.setState({
        clinicName: clinicName,
        showLoader: true,
        business_insight_child_id: reportTypeId,
        clinic_child_id: clinicId,
      });
      if (
        this.state.selected_report_type_id == 14 ||
        this.state.selected_report_type_id == 15 ||
        this.state.selected_report_type_id == 17
      ) {
        this.props.getReportDetail(reportTypeId, {
          ...reportOptionFilter,
          page,
        });
      }
    }
  };

  loadMoreDetail = () => {
    if (!this.state.showLoader && this.state.detailReportHasNextPage) {
      this.goToDetail(
        this.state.business_insight_child_id,
        this.state.clinic_child_id,
        this.state.clinicName,
        (this.state.detailReportCurrentPage || 0) + 1,
      );
    }
  };

  dismissCloseDetail = (childState) => {
    this.setState(childState);
  };

  shareReport = (shareObject) => {
    if (shareObject.name === SHARE_REPORT_SERVICES.leadAr) {
      return this.setState({
        isLeadArShareOpen: true,
      });
    }

    if (
      shareObject &&
      (shareObject.url || shareObject.name === "ActiveCampaign")
    ) {
      this.setState({ shareObject: shareObject });

      let reportOptionFilter = {};
      if (this.state.showGeneralReport) {
        if (
          this.state.reportType === "view" &&
          this.state.reportID &&
          this.state.insightID
        ) {
          reportOptionFilter.business_insight_reports_id = this.state.reportID;
          reportOptionFilter.insight_id_report_id = this.state.insightID;
        } else {
          reportOptionFilter = JSON.parse(
            JSON.stringify(this.state.reportFilter),
          );
          reportOptionFilter.insight_id_report_id =
            reportOptionFilter.report_type_id;
        }
      } else {
        if (
          this.state.reportType === "view" &&
          this.state.reportID &&
          this.state.insightID
        ) {
          reportOptionFilter.business_insight_reports_id = this.state.reportID;
        } else {
          reportOptionFilter = JSON.parse(
            JSON.stringify(this.state.reportFilter),
          );
        }
        if (
          this.state.isAverageSizeDetail ||
          this.state.isAverageVisitDetail ||
          this.state.isTopVipClientDetail
        ) {
          reportOptionFilter.clinic_id = this.state.clinic_child_id;
        }
        reportOptionFilter.insight_id_report_id =
          this.state.business_insight_child_id;
      }
      reportOptionFilter.is_share_contact = 1;
      reportOptionFilter.share_type = shareObject.name;

      localStorage.setItem(
        "shareReportOptionFilter",
        JSON.stringify(reportOptionFilter),
      );
      this.setState({
        showShareNameModal: false,
        shareQueryString: {},
        shareNameList: "",
        shareNameListClass: "simpleInput",
        shareReportType: reportOptionFilter.share_type,
      });
      if (reportOptionFilter.share_type === "ActiveCampaign") {
        this.state.shareReportType = "ActiveCampaign";
        this.setState({ shareReportType: "ActiveCampaign", showLoader: true });
        this.handleShareNameListSubmit();
      } else {
        if (!this.state.childWindowListener) {
          const _self = this;
          window.addEventListener(
            "message",
            function (e) {
              _self.handleChildWindow(e.data);
            },
            false,
          );
          this.setState({ childWindowListener: true });
        }

        if (
          shareObject.name === "Mailchimp" &&
          this.state.mailchimpIntegrationStatus.isConnect
        ) {
          this.setState({
            shareQueryString: {},
            shareNameList: "",
            shareNameListClass: "simpleInput",
            showShareNameModal: true,
          });
        } else {
          window.open(
            shareObject.url,
            "Ratting",
            "width=550,height=550,left=150,top=200,toolbar=0,status=0,scrollbars=1",
            "_blank",
          );
        }
      }
    }
  };

  handleChildWindow = (message) => {
    if (message && message.childWindow && message.childWindow == "response") {
      if (message.query) {
        const query = message.query;
        if (query.error) {
          toast.dismiss();
          toast.error(query.error_message);
        } else {
          this.setState(
            {
              showShareNameModal: false,
              shareQueryString: query,
              shareNameList: "",
              shareNameListClass: "simpleInput",
            },
            () => {
              let reportOptionFilter = JSON.parse(
                localStorage.getItem("shareReportOptionFilter"),
              );
              if (
                (reportOptionFilter &&
                  reportOptionFilter.share_type == "Intercom") ||
                reportOptionFilter.share_type == "Zoho"
              ) {
                this.handleShareNameListSubmit();
              } else {
                this.setState({ showShareNameModal: true });
              }
            },
          );
        }
      }
    }
  };

  handleShareNameListState = (childState) => {
    this.setState(childState);
  };

  handleShareNameListSubmit = () => {
    this.setState({
      showShareNameModal: false,
    });
    let reportOptionFilter = JSON.parse(
      localStorage.getItem("shareReportOptionFilter"),
    );

    if (
      reportOptionFilter &&
      (this.state.shareQueryString ||
        this.state.shareReportType === "ActiveCampaign")
    ) {
      reportOptionFilter.code = this.state.shareQueryString.code || null;
      reportOptionFilter.list_name = this.state.shareNameList;

      this.setState({
        showLoader: true,
        shareQueryString: {},
        shareNameList: "",
        shareNameListClass: "simpleInput",
      });
      localStorage.removeItem("shareReportOptionFilter");
      this.props.shareReportData(
        reportOptionFilter.insight_id_report_id,
        reportOptionFilter,
      );
    }
  };

  showReportByClinic = (clinicId) => {
    if (isFormSubmit()) {
      autoScrolling(true);
      this.props.resetReducerAction();
      let reportOptionFilter = {};
      let id = "";
      if (this.state.showGeneralReport) {
        if (
          this.state.reportType === "view" &&
          this.state.reportID &&
          this.state.insightID
        ) {
          reportOptionFilter.business_insight_reports_id = this.state.reportID;
          id = this.state.insightID;
        } else {
          reportOptionFilter = JSON.parse(
            JSON.stringify(this.state.reportFilter),
          );
          id = reportOptionFilter.report_type_id;
        }
      } else {
        if (
          this.state.reportType === "view" &&
          this.state.reportID &&
          this.state.insightID
        ) {
          reportOptionFilter.business_insight_reports_id = this.state.reportID;
        } else {
          reportOptionFilter = JSON.parse(
            JSON.stringify(this.state.reportFilter),
          );
        }
        id = this.state.business_insight_child_id;
      }

      reportOptionFilter.clinic_id = clinicId;
      reportOptionFilter.page = 1;
      reportOptionFilter.pagesize = this.state.pagesize;
      this.setState({
        showLoader: true,
        clinic_id: clinicId,
        reportListData: [],
        page: 1,
        next_page_url: "",
      });
      this.props.getReportData(id, reportOptionFilter);
    }
  };

  selectOption = (selectedGraphOption) => {
    this.setState({ selectedGraphOption: selectedGraphOption, page: 1 });
  };

  isSortableColumn = (column) => {
    const sortableColumns = [
      "bi_client_name",
      "bi_lead_date",
      "bi_conversion_date",
      "bi_referral_source",
      "doc_expire_patient",
      "doc_expire_date",
      "doc_expire_name",
      "doc_expire_type",
      "doc_expire_appointment_date",
    ];

    const sortableReportSysname = [
      "documents-expiration",
      "leads-conversion",
      "patient-referral-report",
    ];

    return (
      sortableReportSysname.includes(this.state.report_type_sysname) &&
      sortableColumns.includes(column)
    );
  };

  onSort = (sortBy) => {
    let sortOrder = this.state.sortOrder === "asc" ? "desc" : "asc";
    this.setState(
      {
        sortBy: sortBy,
        sortOrder: sortOrder,
        page: 1,
        showLoader: true,
      },
      () => {
        let formData = this.state.reportFilter;
        formData.page = 1;
        formData.pagesize = this.state.pagesize;
        formData.clinic_id = this.state.clinic_id;
        formData.sort_by = this.state.sortBy;
        formData.sort_order = this.state.sortOrder;
        formData.search_term = this.state.searchTerm;
        this.props.getReportData(this.state.report_type_id, formData);
      },
    );
  };
  reportList = () => {
    const list = this.state.reportListData.map((obj) => {
      return {
        ...obj,
        bi_lead_date: showFormattedDate(obj.bi_lead_date, true),
      };
    });
    return list;
  };
  getDaysUntilExpiration = (expirationDate) => {
    return moment(expirationDate).diff(moment(), "days");
  };

  handleTimerClick = (document) => {
    const {
      name,
      date,
      type,
      object_id: documentId,
      expiration_date: expirationDate,
      label,
    } = document;
    this.setState({
      showExpirationModal: {
        name,
        date,
        type,
        documentId,
        expirationDate,
        label,
      },
    });
  };
  handleExpirationModalClose = () => {
    this.setState({ showExpirationModal: null });
  };

  render() {
    return (
      <div id="content">
        <SaveReport
          showNameSection={this.state.showNameSection}
          hideNameSection={this.hideNameSection}
          reportNameClass={this.state.reportNameClass}
          saveThisReport={this.saveThisReport}
          reportName={this.state.reportName}
          handleChildState={this.handleChildState}
          reportMode={this.state.reportType}
          editReportID={this.state.reportID}
          propReportType={this.state.reportType}
        />

        <SaveList
          showSaveListModal={this.state.showSaveListModal}
          hideModal={this.hideModal}
          setList={this.setList}
          listNameClass={this.state.listNameClass}
          listName={this.state.listName}
          handleChildState={this.handleChildState}
          reportMode={this.state.reportType}
          editReportID={this.state.reportID}
          propReportType={this.state.reportType}
        />
        <PatientsReportOptions
          showCreateOptions={this.state.showCreateOptions}
          getData={this.getData}
          hideCreateOptions={this.hideCreateOptions}
          getReportVariables={this.getReportVariables}
          reportOptions={this.state.reportOptions}
          reportVariableData={this.state.reportVariableData}
          type={this.state.type}
          reportID={this.state.reportID}
          allData={this.state.allData}
          businessInsightLang={this.state.businessInsightLang}
          globalLang={this.state.globalLang}
          history={this.props.history}
        />

        <div className="container-fluid content setting-wrapper">
          <InsightsSideBar />

          <div className="memberWalletOuter business-section">
            {this.state.showGeneralReport === true && (
              <>
                <PatientsTitle
                  history={this.props.history}
                  isCreateButton={
                    this.state.reportType === "view" ? true : false
                  }
                  isBreadcrumbs={this.state.isBreadcrumbs}
                  showCreateOptions={this.showCreateOptions}
                  businessInsightLang={this.state.businessInsightLang}
                  globalLang={this.state.globalLang}
                />
                <div
                  className={
                    this.state.dataLoadedOnce
                      ? "setting-setion m-b-10"
                      : "setting-setion m-b-10 no-display"
                  }
                >
                  <PatientsReportTitle
                    selectOption={this.selectOption}
                    downloadData={this.downloadData}
                    showReportByClinic={this.showReportByClinic}
                    showCreateOptions={this.showCreateOptions}
                    saveReport={this.getReportName}
                    saveList={this.setSaveList}
                    parentState={this.state}
                    shareReport={this.shareReport}
                    isMailchimpLoading={
                      this.state.mailchimpIntegrationStatus.isLoaing
                    }
                    handleSearchInputChange={this.handleSearchInputChange}
                    handleSearchSubmit={this.handleSearchSubmit}
                  />
                  {this.state.selected_report_type_id != 11 && (
                    <div className="table-responsive clients-table no-border">
                      <table className="table-updated  table-min-width no-td-border no-hover">
                        <thead className="table-updated-thead">
                          <tr>
                            {this.state.tableColumns &&
                              this.state.tableColumns.length > 0 &&
                              this.state.tableColumns.map((tobj, tidx) => {
                                const columnLabel =
                                  this.state.languageData[tobj];
                                return (
                                  <th key={tidx} className={`table-updated-th`}>
                                    {this.isSortableColumn(tobj) ? (
                                      <SortAdornment
                                        value={
                                          this.state.sortBy === tobj
                                            ? this.state.sortOrder
                                            : undefined
                                        }
                                        onToggle={() => {
                                          this.onSort(tobj);
                                        }}
                                      >
                                        <span className="text-no-wrap">
                                          {columnLabel}
                                        </span>
                                      </SortAdornment>
                                    ) : (
                                      columnLabel
                                    )}
                                  </th>
                                );
                              })}
                          </tr>
                        </thead>
                        <tbody className="ajax_body">
                          {this.state.reportListData &&
                            this.state.reportListData.length > 0 &&
                            this.reportList().map((robj, ridx) => {
                              return (
                                <tr
                                  key={ridx}
                                  className="table-updated-tr break-word"
                                >
                                  {this.state.tableColumns &&
                                    this.state.tableColumns.map(
                                      (tobj, tidx) => {
                                        if (
                                          tobj === "doc_expire_action_buttons"
                                        ) {
                                          return (
                                            <td
                                              className="table-updated-td"
                                              key={tidx}
                                            >
                                              <ExpirationActionButtons
                                                setSelectedDocument={
                                                  this.props.setSelectedDocument
                                                }
                                                viewClientDocumentData={
                                                  this.props
                                                    .viewClientDocumentData
                                                }
                                                document={robj}
                                                refetchData={async () => {
                                                  const reqPayload = this.state
                                                    .requestData || {
                                                    business_insight_reports_id:
                                                      this.state.reportID,
                                                    page: 1,
                                                    pagesize:
                                                      this.state.pagesize,
                                                  };
                                                  this.setState({
                                                    reportListData: [],
                                                    showLoader: true,
                                                    page: 1,
                                                  });
                                                  await this.props
                                                    .getReportData(
                                                      this.state.insightID ||
                                                        this.state
                                                          .report_type_id,
                                                      reqPayload,
                                                    )
                                                    .finally(() =>
                                                      this.setState({
                                                        showLoader: false,
                                                      }),
                                                    );
                                                }}
                                              />
                                            </td>
                                          );
                                        }
                                        return (
                                          <td
                                            key={`${ridx}_${tidx}`}
                                            className={
                                              [
                                                "bi_email",
                                                "bi_phone",
                                                "doc_expire_name",
                                                "doc_expire_name",
                                              ].indexOf(tobj) > -1
                                                ? "table-updated-td word-break"
                                                : "table-updated-td"
                                            }
                                          >
                                            {[
                                              "bi_amount_of_money_spent",
                                              "bi_refund",
                                              "bi_average_transection",
                                              "bi_amount_of_funds_spent",
                                              "bi_discount",
                                            ].indexOf(tobj) > -1
                                              ? getCurrencySymbol()
                                              : null}
                                            {tobj ===
                                              "doc_expire_appointment_date" &&
                                            robj[tobj] === null ? (
                                              <span className="upApp">-</span>
                                            ) : (
                                              robj[tobj]
                                            )}
                                          </td>
                                        );
                                      },
                                    )}
                                </tr>
                              );
                            })}
                          {this.state.reportListData &&
                            this.state.reportListData.length <= 0 &&
                            !this.state.showLoader &&
                            !this.state.startFresh && (
                              <tr className="table-updated-tr break-word">
                                <td
                                  className="table-updated-td no-record no-float"
                                  colSpan={10}
                                >
                                  {
                                    this.state.businessInsightLang
                                      .bi_no_record_found
                                  }
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                      {this.state.reportListData?.length > 0 &&
                        this.state.reportData.next_page_url && (
                          <LoadMoreObserver
                            loadMore={this.loadMore}
                            hasMore={this.state.next_page_url !== null}
                            isLoading={false}
                          />
                        )}
                    </div>
                  )}
                  {this.state.selected_report_type_id == 11 && (
                    <PatientReferralReport
                      selectOption={this.selectOption}
                      selectedOption={this.state.selectedGraphOption}
                      selectedClinicId={this.state.clinic_id}
                      graphData={this.state.graphData}
                      reportListData={this.state.reportListData}
                      tableColumns={this.state.tableColumns}
                      parentState={this.state}
                      isSortableColumn={this.isSortableColumn}
                      sortBy={this.state.sortBy}
                      sortOrder={this.state.sortOrder}
                      onSort={this.onSort}
                    />
                  )}
                  <div
                    className={
                      this.state.showLoadingText
                        ? "loading-please-wait no-margin-top"
                        : "loading-please-wait no-margin-top no-display"
                    }
                  >
                    {this.state.globalLang.loading_please_wait_text}
                  </div>
                </div>
              </>
            )}
            {this.state.showGeneralReport === false &&
              (this.state.selected_report_type_id == 14 ||
                this.state.selected_report_type_id == 15 ||
                this.state.selected_report_type_id == 17) && (
                <PatientsInsightDetailReport
                  parentState={this.state}
                  detailReportListData={this.state.detailReportListData}
                  downloadData={this.downloadData}
                  grammar={this.state.grammar}
                  categoryId={this.state.categoryId}
                  dismissCloseDetail={this.dismissCloseDetail}
                  tableColumns={this.state.detailTableColumns}
                  clinicName={this.state.clinicName}
                  detailReportFlag={this.state.detailReportFlag}
                  loadMore={this.loadMoreDetail}
                />
              )}

            <SaveShareList
              showShareNameModal={this.state.showShareNameModal}
              shareNameListClass={this.state.shareNameListClass}
              shareNameList={this.state.shareNameList}
              shareApiKeyClass={this.state.shareApiKeyClass}
              shareApiKey={this.state.shareApiKey}
              shareApiUrlClass={this.state.shareApiUrlClass}
              shareApiUrl={this.state.shareApiUrl}
              shareReportType={this.state.shareReportType}
              handleShareNameListState={this.handleShareNameListState}
              handleShareNameListSubmit={this.handleShareNameListSubmit}
            />
          </div>
        </div>
        <LeadArShare
          reportId={this.props.match.params.reportID}
          insightId={
            this.props.match.params.insightID ||
            this.state.reportFilter?.report_type_id
          }
          isOpen={this.state.isLeadArShareOpen}
          reportFilter={this.state.reportFilter}
          setIsOpen={(status) =>
            this.setState({
              isLeadArShareOpen: status,
            })
          }
        />
        <Loader
          showLoader={
            this.state.showLoader || this.props.viewClientDocumentData.isLoading
          }
          isFullWidth={true}
        />
        {this.props.viewClientDocumentData.agreementModal.isShown && (
          <AgreementModal
            onClose={this.props.viewClientDocumentData.agreementModal.close}
            agreementModal={this.props.viewClientDocumentData.agreementModal}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.BusinessInsightReducer.action === "GET_CLIENTS_REPORT") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportOptionAndList = {
        savedReports: state.BusinessInsightReducer.patientSavedReports,
        reportOptions: state.BusinessInsightReducer.patientReportOptions,
        allData: state.BusinessInsightReducer.data.data,
      };
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_VARIABLES") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      returnState.reportVariableData = state.BusinessInsightReducer.data.data;
    }
  }

  if (state.BusinessInsightReducer.action === "GET_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportData = state.BusinessInsightReducer.data.data;
      returnState.reportDataTimeStamp = new Date();
    }
  }

  if (state.BusinessInsightReducer.action === "GET_CLIENTS_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportData = state.BusinessInsightReducer.data.data;
      returnState.reportDataTimeStamp = new Date();
    }
  }

  if (state.BusinessInsightReducer.action === "UPDATE_CLIENTS_SAVED_REPORTS") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      toast.dismiss();
      returnState.savedReports =
        state.BusinessInsightReducer.patientSavedReports;
      returnState.redirect = true;
      returnState.message =
        languageData.global[state.BusinessInsightReducer.data.message];
    }
  }

  if (state.BusinessInsightReducer.action === "DOWNLOAD_REPORT_DATA") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.downloadData = state.BusinessInsightReducer.data.data;
    }
  }
  if (state.BusinessInsightReducer.action === "GET_REPORT_DETAIL") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.reportDetail = state.BusinessInsightReducer.data.data;
    }
  }

  if (state.BusinessInsightReducer.action === "SAVE_SMS_LIST") {
    if (state.BusinessInsightReducer.data.status != 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    } else {
      returnState.redirect = true;
      toast.success(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.dataValue = state.BusinessInsightReducer.data.data;
      returnState.redirectTime = new Date();
    }
  }
  if (state.BusinessInsightReducer.action === "SHARE_REPORT_DATA") {
    toast.dismiss();
    if (state.BusinessInsightReducer.data.status != 200) {
      if (state.BusinessInsightReducer.data.message == "third_party_error") {
        toast.error(state.BusinessInsightReducer.data.data);
      } else {
        toast.error(
          languageData.global[state.BusinessInsightReducer.data.message],
        );
      }
      returnState.showLoader = false;
    } else {
      toast.success(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
      returnState.showLoader = false;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      resetReducerAction: resetReducerAction,
      getOptionAndList: getOptionAndList,
      saveReport: saveReport,
      getReportVariables: getReportVariables,
      getReportData: getReportData,
      downloadReportData: downloadReportData,
      getReportDetail: getReportDetail,
      saveSmsFilterReport: saveSmsFilterReport,
      shareReportData: shareReportData,
      getMailchimpData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withExpirationActionButtons(PatientsInsightReport)));
