/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { useGetProviderTempaltesQuery } from "../../../../_legacy/Queries";
import { truncateString } from "../../../../utilities/general";
import useAdvancedState from "../../../../utilities/hooks/useAdvancedState";
import classes from "../sass/schedule.module.scss";

const EditScheduleTemplateButton = ({
  providerId,
  editTemplate,
  selectedClinics = [],
}) => {
  const {
    state: { isMenuOpen },
    toggleField,
  } = useAdvancedState({
    isMenuOpen: false,
  });

  const { data: providerTemplatesData, dataUpdatedAt } =
    useGetProviderTempaltesQuery({ id: providerId });

  const providerTemplates = useMemo(() => {
    if (selectedClinics.length) {
      return providerTemplatesData?.data?.data.filter(({ clinic_id }) =>
        selectedClinics.includes(clinic_id),
      );
    }

    return (
      providerTemplatesData?.data?.data?.filter(
        (templateData) => templateData.is_active,
      ) || []
    );
  }, [dataUpdatedAt, selectedClinics]);

  const editTemplateDetails = (templateDetails) => {
    editTemplate(templateDetails);
    toggleField("isMenuOpen");
  };

  return (
    providerTemplates?.length > 0 && (
      <div className={classes.selectedTemplatesDropdown}>
        <button
          onClick={() => toggleField("isMenuOpen")}
          className="calendar-dropdown new-blue-btn"
        >
          Edit&nbsp;Template
        </button>

        {isMenuOpen && (
          <Dropdown
            id="edit-schedule-template-dropdown"
            open={isMenuOpen}
            onToggle={() => toggleField("isMenuOpen")}
          >
            <Dropdown.Toggle className="no-display" />
            <Dropdown.Menu className={classes.dropdownMenu}>
              <div className={classes.dropdownContent}>
                {providerTemplates?.map(({ name, id }) => {
                  return (
                    <div
                      className={classes.templateName}
                      onClick={() => editTemplateDetails({ name, id })}
                      key={`template-${id}`}
                    >
                      {truncateString(name)}
                    </div>
                  );
                })}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    )
  );
};

export default EditScheduleTemplateButton;
