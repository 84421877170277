import { useTranslation } from "react-i18next";
import { I18N_NAMESPACES } from "../consts/i18n";
import { i18n } from "./setup";

export const useAppTranslation = {
  Common: () => {
    const { t, ...rest } = useTranslation(I18N_NAMESPACES.common);
    return {
      ...rest,
      tCommon: t,
    };
  },
  Auth: () => {
    const { t, ...rest } = useTranslation(I18N_NAMESPACES.auth);
    return {
      ...rest,
      tAuth: t,
    };
  },
  Settings: () => {
    const { t, ...rest } = useTranslation(I18N_NAMESPACES.settings);
    return {
      ...rest,
      tSettings: t,
    };
  },
  BusinessInsights: () => {
    const { t, ...rest } = useTranslation(I18N_NAMESPACES.businessInsights);
    return {
      ...rest,
      tBi: t,
    };
  },
  Sales: () => {
    const { t, ...res } = useTranslation(I18N_NAMESPACES.sales);
    return {
      ...res,
      tSales: t,
    };
  },
  Inbox: () => {
    const { t, ...res } = useTranslation(I18N_NAMESPACES.inbox);
    return {
      ...res,
      tInbox: t,
    };
  },
  Clients: () => {
    const { t, ...res } = useTranslation(I18N_NAMESPACES.clients);
    return {
      ...res,
      tClients: t,
    };
  },
  Appointments: () => {
    const { t, ...res } = useTranslation(I18N_NAMESPACES.appointments);
    return {
      ...res,
      tAppointments: t,
    };
  },
  Inventory: () => {
    const { t, ...res } = useTranslation(I18N_NAMESPACES.inventory);
    return {
      ...res,
      tInventory: t,
    };
  },
};

const tBase =
  (ns) =>
  (...args) => {
    const [key, ...rest] = args;
    return i18n.t(`${ns}:${key}`, ...rest);
  };

export const tCommon = tBase(I18N_NAMESPACES.common);
export const tAuth = tBase(I18N_NAMESPACES.auth);
export const tBi = tBase(I18N_NAMESPACES.businessInsights);
export const tSales = tBase(I18N_NAMESPACES.sales);
export const tClients = tBase(I18N_NAMESPACES.clients);
export const tAppointments = tBase(I18N_NAMESPACES.appointments);
