import { useInfiniteQuery } from "@tanstack/react-query";
import { http } from "../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../consts/api";

export const SERVICES_PERFORMED_REPORTS_ORDER_BY = {
  amountDiscount: "amount_discount",
  subTotal: "amount_sold",
  categoryName: "category_name",
  netAmount: "net_amount",
  providerName: "provider_name",
  quantityUsed: "quantity_used",
  serviceName: "service_name",
  taxableAmount: "taxable_amount",
  unitsUsed: "quantity_used",
  customDiscount: "custom_discount",
  taxesOwed: "total_tax_amount",
};

export const useServicesPerformed = (req, options = {}) => {
  return useInfiniteQuery({
    queryKey: [
      req.order,
      req.by,
      req.categoryIds,
      req.clinicsIds,
      req.provider_id,
      req.serviceIds,
      req.fromDate,
      req.toDate,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      return http.get(HTTP_ENDPOINTS.getServiceSales(), {
        params: {
          fromDate: req.fromDate,
          toDate: req.toDate,
          clinicsIds: req.clinicsIds,
          categoryIds: req.categoryIds,
          serviceIds: req.serviceIds,
          provider_id: req.provider_id,
          page: pageParam,
          orderBy: req.by,
          order: req.order,
        },
      });
    },
    ...options,
    getNextPageParam: (_lastPage, pages) => {
      const lastPage = pages[0].data.data.last_page;
      if (pages.length < lastPage) {
        return pages.length + 1;
      }
      return null;
    },
  });
};
