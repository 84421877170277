import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { getCurrencySymbol, positionFooterCorrectly,showFormattedDate } from '../../../Utils/services';

class UpcomingAppointments extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			clientLang : languageData.clients,
			salesLang  : languageData.sales,
			globalLang : languageData.global,
			select_all_appointments : false,
			select_all_payments : false
		};
	}

	static getDerivedStateFromProps(nextProps, prevState){
		let returnState = {};
		positionFooterCorrectly();
		if(nextProps.appointmentDataTime != prevState.appointmentDataTime){
			if(nextProps.appointmentData != undefined){
				returnState.showLoader = false;
				returnState.appointmentData = nextProps.appointmentData;
				nextProps.appointmentData.map((data,index) =>{
					returnState['appointment-' + data.id] = data.selected;
				})
			if(nextProps.appointmentData.length > 0){
			let enableSelectAll;
			enableSelectAll = nextProps.appointmentData.find(y => y.selected == false);
			if(enableSelectAll){
				returnState.select_all_appointments = false
			} else{
				returnState.select_all_appointments = true;
			}}
				
			}
			if(nextProps.paymentData != undefined){
				returnState.showLoader = false;
				returnState.paymentData = nextProps.paymentData;
				nextProps.paymentData.map((data,index) => {
					returnState['payment-' + data.id] = data.selected;
				})
			if(nextProps.paymentData.length > 0){
			let enableSelectAll;
			enableSelectAll = nextProps.paymentData.find(y => y.selected == false);
			if(enableSelectAll){
				returnState.select_all_payments = false
			} else{
				returnState.select_all_payments = true;
			}}
			}
			returnState.appointmentDataTime = nextProps.appointmentDataTime;
		}
		return returnState;
	}

	handleInputChange = (event) => {
		const target = event.target;
		let value= target.value;
		let name = event.target.name;
		let returnState = {}
		switch(target.type) {
			case 'checkbox': {
				value = target.checked;
				break;
			}
	  
			case 'radio' :{
			  value = target.value;
			  break;
		  }
		}
		if(target.type == 'checkbox' && target.checked == true && target.name == 'select_all_appointments') {
			let data = this.state.appointmentData;
			data.map((obj, index) => {
				returnState['appointment-' + obj.id] = true;
			})

		} else if(target.type == 'checkbox' && target.checked == false && target.name == 'select_all_appointments') {
			let data = this.state.appointmentData;
			data.map((obj, index) => {
				returnState['appointment-' + obj.id] = false;
			})
		}

		if(target.type == 'checkbox' && target.checked == true && target.name == 'select_all_payments') {

			let data = this.state.paymentData;
			data.map((obj, index) => {
				returnState['payment-' + obj.id] = true;
			})

		} else if(target.type == 'checkbox' && target.checked == false && target.name == 'select_all_payments') {
			let data = this.state.paymentData;
			data.map((obj, index) => {
				returnState['payment-' + obj.id] = false;
			})
		}
	  returnState[event.target.name] = value;
	  this.setState(returnState, () => this.sendDataToParent());
	}

	sendDataToParent = () => {
		let checkStatus = [];
		let checkPaymentStatus = [];
		if(this.state.appointmentData.length > 0){
		this.state.appointmentData.map((obj,index) => {
			if(this.state['appointment-' + obj.id] == false){
				checkStatus.push(obj);
			}
		})
		if(checkStatus.length > 0){
			this.setState({ select_all_appointments : false });
		} else {
			this.setState({ select_all_appointments : true });
		}}

		if(this.state.paymentData.length > 0){
		this.state.paymentData.map((obj,index) => {
			if(this.state['payment-' + obj.id] == false){
				checkPaymentStatus.push(obj);
			}
		})
		if(checkPaymentStatus.length > 0){
			this.setState({ select_all_payments : false });
		} else {
			this.setState({ select_all_payments : true });
		}
	}
		
		let appointmentData = [];
		let paymentData = [];
		this.state.appointmentData.map((obj,index) =>{
			appointmentData.push({
				appointment_services : obj.appointment_services,
                id : obj.id,
                provider : obj.provider,
                clinic : obj.clinic,
                date : obj.appointment_datetime,
				selected : this.state['appointment-' + obj.id],
				notes : obj.notes
			})
		})

		this.state.paymentData.map((obj,index) => {
			paymentData.push({
				id : obj.id,
                invoice_number : obj.invoice_number,
                procedure : obj.procedure,
                pos_transaction : obj.pos_transaction,
				selected : this.state['payment-' + obj.id],
				created : obj.created
			})
		})

		let formData = {
			step6Data : {
				appointmentData : appointmentData,
				paymentData : paymentData
			}
		}
		this.props.handleChildState(formData);
	}
    render(){
        return(
        <div>
        <div className="merge-title full-width">

        <div className="row backgroundColorGray">
		<div className="col-md-6 col-xs-12">
			<div className="setting-setion full-width">
				<div className="merge-main-title checkOuter">
				{this.state.appointmentData && this.state.appointmentData.length > 0 && <input type="checkbox" id="double-booking" 
				name="select_all_appointments" value={this.state.select_all_appointments} 
				checked={(this.state.select_all_appointments) ? "checked" : false} 
				onChange={this.handleInputChange} />}
					<span>{this.state.clientLang.clientprofile_upcoming_appointments}</span>
					<p>{this.state.clientLang.merge_right_information_message}</p>
				</div>
				{this.state.appointmentData && this.state.appointmentData.map((data, index) => {
					return(
                    <div className="procedure-row checkOuter" key={data.id}>
					<input type="checkbox" value={data.selected} name={"appointment-"+data.id} 
					checked={(this.state["appointment-"+data.id]) ? "checked" : false} autoComplete="off" onChange={this.handleInputChange} />
					<div className="procedureName">Appointment #{index + 1}</div>
					<div className="procedureInformation">
						
						<div className="procedureDetails p-l-0">
							<div className="info-row">
								<label>{this.state.clientLang.client_service_name}</label>
								
								<span>{data.appointment_services && data.appointment_services.map((obj,i) => {
									return(<p key={i}>{obj.service ? obj.service.name : ''}</p>)
									
								})}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.client_provider_name}</label>
								<span><p>{data.provider ? data.provider.firstname : ''} {data.provider ? data.provider.lastname : ''}</p></span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.pro_clinic}</label>
								<span>{data.clinic.clinic_name}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_notes}</label>
								<span>{data.notes != null ? data.notes.appointment_notes : 'N/A'}</span>
							</div>
							
							<div className="info-row">
								<label>{this.state.clientLang.wallet_date}</label>
								<span>{showFormattedDate(data.date, true)}</span>
							</div>
						</div>
					</div>
				</div>
					)
				})}
				{this.state.appointmentData.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
			</div>
		</div>
		<div className="col-md-6 col-xs-12 mergeRightSection">
			<div className="setting-setion full-width">
				<div className="merge-main-title checkOuter">
				{/* <input type="checkbox" id="double-booking" 
				name="select_all_payments" value={this.state.select_all_payments} 
				checked={true} 
				disabled={true} /> */}
					<span>{this.state.clientLang.clientprofile_payment_history}</span>
					{/* <p>{this.state.clientLang.merge_right_information_message}</p> */}
				</div>
			{this.state.paymentData && this.state.paymentData.map((data,index) => {
				return(
					<div className="procedure-row checkOuter" key={data.id}>
					{/* <input type="checkbox" value={data.selected} name={"payment-"+data.id} 
					checked={true} autoComplete="off"  disabled={true} /> */}
					<div className="procedureName">#{data.invoice_number}</div>
					<div className="procedureInformation">
						<div className="procedureDetails p-l-0">
							<div className="info-row">
								<label>{this.state.clientLang.pro_pro_name}</label>
								<span>{data.procedure != null ? data.procedure.procedure_name : 'N/A'}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.pro_pro_date}</label>
								<span>{data.procedure != null ? showFormattedDate(data.procedure.procedure_date, true) : 'N/A'}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.client_total_amount}</label>
								<span>{getCurrencySymbol()} {data.pos_transaction ? data.pos_transaction.total_amount : ''}</span>
							</div>
                            <div className="info-row">
								<label>{this.state.clientLang.client_payment_mode}</label>
								<span className='text-uppercase'>{data.pos_transaction ? data.pos_transaction.payment_mode  : ''}</span>
							</div>
                            <div className="info-row">
								<label>{this.state.clientLang.client_payment_status}</label>
								<span className='text-capitalize'>{data.pos_transaction ? data.pos_transaction.payment_status : ''}</span>
							</div>
                            <div className="info-row">
								<label>{this.state.clientLang.client_charged_on}</label>
								<span>{data.pos_transaction ? showFormattedDate(data.pos_transaction.transaction_datetime, true) : ''}</span>
							</div>
						</div>
					</div>
				</div>
				)
			})}
			{this.state.paymentData.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
			</div>
		</div>
		
	</div>
    </div>
    </div>
        )
    }
}

export default UpcomingAppointments;