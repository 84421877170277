import axios from "axios";
import {
  getToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const clockInstance = axios.create();

clockInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

clockInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      return Promise.reject(error);
    }
  },
);

clockInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function fetchClockStatus(user_id = null) {
  let type = "LOGGED_CLOCK_STATUS";
  let formData = null;

  if (user_id) {
    type = "USER_CLOCK_STATUS";
    formData = {
      params: {
        user_id: user_id,
      },
    };
  }

  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
    clockInstance
      .get(
        process.env.REACT_APP_API_URL + "clock_data",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: type, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: type, payload: error.response.data });
      });
  };
}
