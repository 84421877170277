import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { unwrapOr } from "../../utilities/general.js";
import { uiNotification } from "../../services/UINotificationService.js";

positionFooterCorrectly();

const appointmentInstance = axios.create();

appointmentInstance.defaults.headers.common["access-token"] = getToken();

const languageData = JSON.parse(localStorage.getItem("languageData"));

appointmentInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }
      let msg = error.response.data.message;
      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

appointmentInstance.interceptors.response.use(accountStatusResponseInterceptor);

export const getServiceCategories = (formData) => {
  return async (dispatch) => {
    return appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "services-categories",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SERVICE_CAT_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SERVICE_CAT_LIST", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
};

export function fetchServiceCategory(formData, postId) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "services-categories/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SERVICE_CATEGORY_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SERVICE_CATEGORY_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function createServiceCategory(formData) {
  return async (dispatch) => {
    return appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "services-categories",
        formData ? formData : "",
      )
      .then((response) => {
        if (formData.from && formData.from == "services") {
          dispatch({
            type: "CREATE_SERVICE_CATEGORY_FROM_SERVICE",
            payload: response.data,
          });
        } else {
          dispatch({ type: "CREATE_SERVICE_CATEGORY", payload: response.data });
        }

        return response.data;
      })
      .catch((error) => {
        if (formData.from && formData.from == "services") {
          dispatch({
            type: "CREATE_SERVICE_CATEGORY_FROM_SERVICE",
            payload: error.response.data,
          });
        } else {
          dispatch({
            type: "CREATE_SERVICE_CATEGORY",
            payload: error.response.data,
          });
        }

        return Promise.reject(error.response.data);
      });
  };
}

export function updateServiceCategory(formData, postId) {
  return async (dispatch) => {
    return appointmentInstance
      .patch(
        process.env.REACT_APP_API_URL + "services-categories/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_SERVICE_CATEGORY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SERVICE_CATEGORY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function deleteServiceCategory(postId) {
  return async (dispatch) => {
    return appointmentInstance
      .delete(process.env.REACT_APP_API_URL + "services-categories/" + postId)
      .then((response) => {
        dispatch({ type: "DELETE_SERVICE_CATEGORY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_SERVICE_CATEGORY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getServiceList(formData) {
  return (dispatch) => {
    appointmentInstance
      .get(process.env.REACT_APP_API_URL + "services", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "SERVICE_SERVICES_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SERVICE_SERVICES_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function updateServiceSortOrder(formData, postId) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL +
          "services/" +
          postId +
          "/service-orders",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SERVICE_SORT_ORDER_UPDATE", payload: response.data });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "SERVICE_SORT_ORDER_UPDATE",
          payload: error.response.data,
        });
        dispatch(exportEmptyData({}));
      });
  };
}

export function fetchServiceAndListData(formData, postId) {
  return async (dispatch) => {
    return appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "services/clone/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SERVICE_DATA_AND_LIST_DATA",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SERVICE_DATA_AND_LIST_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function createService(formData, serviceCategoryId) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "service/add/" + serviceCategoryId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_SERVICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_SERVICE", payload: error.response.data });
      });
  };
}

export function updateService(formData, _, postId) {
  return (dispatch) => {
    appointmentInstance
      .put(
        process.env.REACT_APP_API_URL + "service/edit/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_SERVICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_SERVICE", payload: error.response.data });
      });
  };
}

export function deleteService(postId) {
  return (dispatch) => {
    appointmentInstance
      .delete(process.env.REACT_APP_API_URL + "services/" + postId)
      .then((response) => {
        dispatch({ type: "DELETE_SERVICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_SERVICE", payload: error.response.data });
      });
  };
}

export function fetchServicesPackages(formData) {
  return (dispatch) => {
    appointmentInstance
      .get(process.env.REACT_APP_API_URL + "packages", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "SERVICES_PACKAGES_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SERVICES_PACKAGES_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getServicesPackagesById(formData, postId) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "packages/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SERVICES_PACKAGES_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SERVICES_PACKAGES_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function createServicesPackages(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "packages/0",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_SERVICES_PACKAGES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_SERVICES_PACKAGES",
          payload: error.response.data,
        });
      });
  };
}

export function updateServicesPackages(formData, postId) {
  return (dispatch) => {
    appointmentInstance
      .put(
        process.env.REACT_APP_API_URL + "packages/" + postId,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_SERVICES_PACKAGES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SERVICES_PACKAGES",
          payload: error.response.data,
        });
      });
  };
}

export function deleteServicesPackages(postId) {
  return (dispatch) => {
    appointmentInstance
      .delete(process.env.REACT_APP_API_URL + "packages/" + postId)
      .then((response) => {
        dispatch({ type: "DELETE_SERVICES_PACKAGES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_SERVICES_PACKAGES",
          payload: error.response.data,
        });
      });
  };
}

export function fetchProviderSchedule(formData) {
  return async (dispatch) => {
    return appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "provider-schedules",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "PROVIDER_SCHEDULE_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "PROVIDER_SCHEDULE_LIST",
          payload: error?.response?.data,
        });
        return Promise.reject(error?.response?.data);
      });
  };
}

export function dynamicOrderingProviderSchedule(formData) {
  return async (dispatch) => {
    return appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "provider-schedules/dynamic-ordering",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "PROVIDER_SCHEDULE_DYNAMIC_ORDERING",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "PROVIDER_SCHEDULE_DYNAMIC_ORDERING",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function updateProviderScheduleSortOrder(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(process.env.REACT_APP_API_URL + "update/provider/order", formData)
      .then((response) => {
        dispatch({
          type: "PROVIDER_SCHEDULE_SORT_ORDER_UPDATE",
          payload: response.data,
        });
        dispatch(exportEmptyData({}));
      })
      .catch((error) => {
        dispatch({
          type: "PROVIDER_SCHEDULE_SORT_ORDER_UPDATE",
          payload: error.response.data,
        });
        dispatch(exportEmptyData({}));
      });
  };
}

export function getProviderScheduleById(formData, postId) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "provider-schedules-new/" + postId,
        formData || "",
      )
      .then((response) => {
        dispatch({ type: "PROVIDER_SCHEDULE_DATA", payload: response.data });
      })
      .catch((error) => {
        toast.error(languageData.global[error?.response?.data?.message]);
        dispatch({
          type: "PROVIDER_SCHEDULE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function fetchEquipmentSchedule(formData) {
  return (dispatch) => {
    appointmentInstance
      .get(process.env.REACT_APP_API_URL + "devices", formData ? formData : "")
      .then((response) => {
        dispatch({ type: "EQUIPMENT_SCHEDULE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EQUIPMENT_SCHEDULE_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function fetchResourceSchedule(formData) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "resources",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "RESOURCE_SCHEDULE_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "RESOURCE_SCHEDULE_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function createDevice(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "devices/0",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_DEVICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_DEVICE", payload: error.response.data });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_APPOINTMENT_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function getAppointments(formData, isVerticals) {
  return async (dispatch) => {
    if (isVerticals) {
      return appointmentInstance
        .post(process.env.REACT_APP_API_URL + "vertical-appointment", {
          clinic_id: formData.clinic_id,
          provider_id: formData.provider_id,
          date: formData.start,
        })
        .then((response) => {
          dispatch({ type: "GET_APPOINTMENTS", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({ type: "GET_APPOINTMENTS", payload: error.response.data });
          return Promise.reject(error?.response?.data);
        });
    } else {
      return appointmentInstance
        .post(
          process.env.REACT_APP_API_URL + "appointment",
          formData ? formData : "",
        )
        .then((response) => {
          dispatch({ type: "GET_APPOINTMENTS", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({ type: "GET_APPOINTMENTS", payload: error.response.data });
          return Promise.reject(error?.response?.data);
        });
    }
  };
}

export function getAppointment(id, mode, clinicId, url) {
  return async (dispatch) => {
    const reqUrl = url || "edit-appointment";
    let requestURL = `${process.env.REACT_APP_API_URL}${reqUrl}/${id}`;

    if (clinicId) {
      requestURL += "/" + clinicId;
    }

    if (mode == "view") {
      dispatch({ type: "APPOINTMENT_GET_PENDING" });
    }

    return appointmentInstance
      .get(requestURL)
      .then((response) => {
        if (mode == "view") {
          dispatch({ type: "APPOINTMENT_GET", payload: response.data });
        } else {
          dispatch({ type: "APPOINTMENT_EDIT_GET", payload: response.data });
        }

        return response.data;
      })
      .catch((error) => {
        if (mode == "edit") {
          dispatch({ type: "APPOINTMENT_GET", payload: error.response.data });
        } else {
          dispatch({
            type: "APPOINTMENT_EDIT_GET",
            payload: error.response.data,
          });
        }

        return Promise.reject(unwrapOr(() => error.response.data));
      });
  };
}

export function markNoShow(formData) {
  return async (dispatch) => {
    return appointmentInstance
      .get(
        process.env.REACT_APP_API_URL +
          "mark-as-noshow/" +
          formData.appointment_id +
          "/" +
          formData.charge,
      )
      .then((response) => {
        dispatch({ type: "MARKED_NO_SHOW", payload: response.data });
        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "MARKED_NO_SHOW", payload: error.response.data });
        uiNotification.error(error.response.data.message);
      });
  };
}

export function updateNotes(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(process.env.REACT_APP_API_URL + "add-appointment-note", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_NOTES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_NOTES", payload: error.response.data });
      });
  };
}

export function checkin(id) {
  return (dispatch) => {
    appointmentInstance
      .get(process.env.REACT_APP_API_URL + "appointment-check-in/" + id)
      .then((response) => {
        dispatch({ type: "GET_CHECKIN_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CHECKIN_DATA", payload: error.response.data });
      });
  };
}

export function makePatientCheckin(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "appointment-check-in/" + formData.id,
        formData,
      )
      .then((response) => {
        dispatch({ type: "POST_CHECKIN_DATA", payload: response.data });
        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "POST_CHECKIN_DATA", payload: error.response.data });
      });
  };
}

export function sendNotification(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL +
          "send-appointment-notification/" +
          formData.appointment_id,
        formData,
      )
      .then((response) => {
        if (formData.notifyFrom && formData.notifyFrom == "create") {
          dispatch({ type: "SEND_NOTIFICATION", payload: response.data });
        } else {
          dispatch({
            type: "SEND_NOTIFICATION_CALENDAR",
            payload: response.data,
          });
          if (response.data.status === 200) {
            toast.success(languageData.global[response.data.message]);
          }
        }
      })
      .catch((error) => {
        if (formData.notifyFrom && formData.notifyFrom == "create") {
          dispatch({ type: "SEND_NOTIFICATION", payload: error.response.data });
        } else {
          dispatch({
            type: "SEND_NOTIFICATION_CALENDAR",
            payload: error.response.data,
          });
        }
      });
  };
}

export function getAppointmentFees(appointment_id) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL +
          "get-appointment-fees/" +
          appointment_id,
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_FEES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "APPOINTMENT_FEES", payload: error.response.data });
      });
  };
}

export function getCancelNotification(appointment_id) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL +
          "get-notification-popup/" +
          appointment_id +
          "/cancel/true",
      )
      .then((response) => {
        dispatch({
          type: "CANCEL_NOTIFICATION_MESSAGE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CANCEL_NOTIFICATION_MESSAGE",
          payload: error.response.data,
        });
      });
  };
}

export function cancelAppointment(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL +
          "cancel-appointment/" +
          formData.appointment_id,
        formData,
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_CANCELLED", payload: response.data });
        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_CANCELLED",
          payload: error.response.data,
        });
      });
  };
}

export function checkRescheduleTime(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL +
          "check-if-reschedule-available/" +
          formData.appointment_id,
        formData,
      )
      .then((response) => {
        dispatch({ type: "RESCHEDULE_TIME", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "RESCHEDULE_TIME", payload: error.response.data });
      });
  };
}

export function rescheduleAppointment(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL +
          "reschedule-appointment/" +
          (formData.patient_to_be_charged ? "true" : "false"),
        formData,
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_RESCHEDULED", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_RESCHEDULED",
          payload: error.response.data,
        });
      });
  };
}

export function fetchBookingHistory(page, pagesize, formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL +
          "appointment-history?page=" +
          page +
          "&pagesize=" +
          pagesize,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "BOOKING_HISTORY_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "BOOKING_HISTORY_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function printAppointment(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "appointment-print",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "PRINT_APPOINTMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "PRINT_APPOINTMENT", payload: error.response.data });
      });
  };
}

export function fetchAppointmentReports(formData) {
  return (dispatch) => {
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL + "appointment-reports",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "FETCH_REPORTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_REPORTS", payload: error.response.data });
      });
  };
}

export function updateSortOrder(formData, type) {
  return async (dispatch) => {
    return appointmentInstance
      .put(process.env.REACT_APP_API_URL + "update/row/order/" + type, formData)
      .then((response) => {
        dispatch({ type: "SORT_ORDER_UPDATE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SORT_ORDER_UPDATE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function fetchServicesProvider(formData) {
  return (dispatch) => {
    dispatch({ type: "PROVIDER_SERVICES_PROVIDERS_LOADING" });
    appointmentInstance
      .get(
        process.env.REACT_APP_API_URL +
          "services/byProvider/listGroupedByCategories",
        formData ? formData : "",
      )
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({
            type: "PROVIDER_SERVICES_PROVIDERS_SUCCESS",
            payload: response.data,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "PROVIDER_SERVICES_PROVIDERS",
          payload: error.response.data,
        });
      });
  };
}

export function saveServicesProvider(_, formData) {
  return (dispatch) => {
    dispatch({ type: "SAVE_PROVIDER_SERVICES" });
    appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "services/byProvider/saveProvided",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SAVE_PROVIDER_SERVICES", payload: response.data });
        toast.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_PROVIDER_SERVICES",
          payload: error.response.data,
        });
        toast.error("Services Required.");
      });
  };
}

export function getProviderAppointments(formData, isVerticals) {
  return async (dispatch) => {
    if (isVerticals) {
      return appointmentInstance
        .post(
          process.env.REACT_APP_API_URL + "week-vertical-appointment",
          formData,
        )
        .then((response) => {
          dispatch({
            type: "PROVIDER_WEEK_APPOINTMENTS",
            payload: response.data,
          });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "PROVIDER_WEEK_APPOINTMENTS",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    } else {
      return appointmentInstance
        .post(process.env.REACT_APP_API_URL + "week-appointment", formData)
        .then((response) => {
          dispatch({
            type: "PROVIDER_WEEK_APPOINTMENTS",
            payload: response.data,
          });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "PROVIDER_WEEK_APPOINTMENTS",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    }
  };
}

export function deleteEvent(formData) {
  return (dispatch) => {
    appointmentInstance
      .post(process.env.REACT_APP_API_URL + "delete-out-of-office", formData)
      .then((response) => {
        dispatch({ type: "DELETE_EVENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_EVENT", payload: error.response.data });
      });
  };
}

export function confirmAppointmentStatus(formData) {
  return async (dispatch) => {
    return appointmentInstance
      .post(process.env.REACT_APP_API_URL + "confirm-appointment", formData)
      .then((response) => {
        dispatch({ type: "CONFIRM_APPOINTMENT", payload: response.data });

        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        }

        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "CONFIRM_APPOINTMENT", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function updateProvider(formData) {
  return async (dispatch) => {
    return appointmentInstance
      .post(
        process.env.REACT_APP_API_URL + "update-appointment-provider",
        formData,
      )
      .then((response) => {
        dispatch({ type: "UPDATE_PROVIDER", payload: response.data });
        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_PROVIDER", payload: error.response.data });
        return Promise.reject(unwrapOr(() => error.response.data, {}));
      });
  };
}

export function unCheckAppt(id) {
  return (dispatch) => {
    appointmentInstance
      .post(process.env.REACT_APP_API_URL + "appointment-uncheck-in/" + id)
      .then((response) => {
        dispatch({ type: "GET_UNCHECKIN_DATA", payload: response.data });
        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "GET_UNCHECKIN_DATA", payload: error.response.data });
      });
  };
}

export function servicesExport() {
  return (dispatch) => {
    dispatch({ type: "SERVICES_EXPORT_LOADING" });
    appointmentInstance
      .post(process.env.REACT_APP_API_URL + "services-export")
      .then((response) => {
        dispatch({ type: "SERVICES_EXPORT_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SERVICES_EXPORT", payload: error.response.data });
      });
  };
}
