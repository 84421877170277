import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  getNextPaginationPage,
  paginationSchema,
  composePaginationSchema,
} from "../../../../../../schemas/pagination";
import {
  packedListOr,
  removeNullishFromShape,
  unwrapOr,
} from "../../../../../../utilities/general";
import {
  API_DATE_FORMAT,
  HTTP_ENDPOINTS,
  QUERY_KEYS,
} from "../../../../../../consts/api";
import { createQueryKeyFromShape } from "../../../../../../utilities/api";
import { http } from "../../../../../../services/HttpService";

export const SALES_CHURN_REPORTS_REASON_FILTER_KEYS = {
  provider: "provider",
  nonPayment: "non_payment",
  expired: "expired",
};

export const SALES_CHURN_REPORTS_ORDER_BY = {
  clinic: "clinic",
  patient: "patient",
  tier: "tier",
  paymentFrequency: "payment_frequency",
  churnOnDate: "churn_on",
  churnReason: "churn_reason",
};

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),
    tierIds: yup.array().of(yup.number()).nullable(),
    churnReason: yup.string().nullable(),
  }),

  limit: yup.number().nullable(),

  order: yup
    .object({
      by: yup.string().required(),
      direction: yup.string().required(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    stats: yup
      .object({
        totalChurn: yup.number().required(),
        totalChurnSum: yup.number().required(),
        currency: yup.string().required(),
      })
      .required(),

    reports: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          clinicId: yup.number().nullable(),
          clinicName: yup.string().nullable(),
          patientName: yup.string().nullable(),
          tier: yup.string().nullable(),
          tierId: yup.number().nullable(),
          churnOnDate: yup.string().required(),
          churnReason: yup.string().nullable(),
          yearlyFees: yup.number().nullable(),
          monthlyFees: yup.number().nullable(),
          paymentFrequency: yup.string().nullable(),
          currency: yup.string().required(),
        }),
      )
      .required(),
  }),
);

/*
  COMPOSERS
*/

const composeResponse = (res, req) => {
  const reports = unwrapOr(() => res.data.data.report.data, []);
  const stats = unwrapOr(() => res.data.data.header, {});
  const posData = unwrapOr(() => res.data.global_settings.pos_data, {});

  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data.report, {}),
      pageSize: req.pageSize,
    }),
    stats: {
      totalChurn: stats.total_churn || 0,
      totalChurnSum: stats.total_churn_sum || 0,
      currency: posData.currency_code,
    },
    reports: reports.map((report) => ({
      id: report.id,
      clinicId: report.clinicId || null,
      clinicName: report.clinic || null,
      patientName: report.patient || null,
      tier: report.tier || null,
      tierId: report.tierId || null,
      churnOnDate: report.churn_on,
      churnReason: report.churn_reason || null,
      yearlyFees: report.yearly_fees || null,
      monthlyFees: report.monthly_fees || null,
      paymentFrequency: report.payment_frequency || null,
      currency: posData.currency_code,
    })),
  };
};

// ---------

function createMembershipChurnQueryKey({
  order,
  filter,
  fromDate,
  toDate,
  limit,
  pageSize,
}) {
  return [
    QUERY_KEYS.salesChurnReports,
    order,
    filter,
    fromDate,
    toDate,
    limit,
    pageSize,
  ];
}

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export function useMembershipChurnReport({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createMembershipChurnQueryKey({
      order: createKeyFromShape(req.order),
      filter: createKeyFromShape(req.filter),
      fromDate,
      toDate,
      limit: req.limit,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getMembershipChurnReport(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          fromDate,
          toDate,
          clinic_ids: packedListOr(req.filter.clinicIds, null),
          tier_ids: packedListOr(req.filter.tierIds, null),
          churn_reason: unwrapOr(() => req.filter.churnReason, null),
          sortBy: unwrapOr(() => req.order.by, null),
          order: unwrapOr(() => req.order.direction, null),
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
