import { useFormik } from "formik";

export function useChargeForm({ onSubmit, schema, initialValues }) {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    errors,
    touched,
    isValid,
    handleSubmit,
  } = useFormik({
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
    initialValues,
  });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  const changeFormValue = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  return {
    form: values,
    changeFormValue,
    hasError,
    isValid,
    errors,
    submit: handleSubmit,
  };
}
