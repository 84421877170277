import { PROVIDER_ROOM_ROUTES } from "../../consts/routes";

export const getProcedureStatuses = (procedure) => {
  return {
    hasPictures: Boolean(
      procedure.image || procedure.image45 || procedure.isProcedureImage,
    ),

    hasTraceability: procedure.hasTraceability,

    hasNotes: procedure.notesCount > 0,

    hasConsent:
      procedure.consentIds.length > 0 || procedure.showConsentsBtn > 0,

    hasPaymentTransaction: procedure.posInvoicesCount > 0,

    hasPrescription:
      procedure.prescriptionCount > 0 ||
      procedure.scriptsurePrescription !== null,

    hasQuestionnaire:
      procedure.answersCount > 0 ||
      procedure.answersMultiplesCount > 0 ||
      procedure.showQuestionnaireBtn > 0,

    isQuestionnaireView: procedure.templateUsed === 0,
  };
};

export const getProcedureRoutes = ({ procedure, type }) => {
  const common = {
    notesRoute: PROVIDER_ROOM_ROUTES.procedureNotes({
      id: procedure.id,
      patientId: procedure.patientId,
      type,
    }),
    consentRoute: PROVIDER_ROOM_ROUTES.procedureConsents({
      id: procedure.id,
      type,
    }),
    questionnaireRoute: PROVIDER_ROOM_ROUTES.procedureQuestionnaire({
      id: procedure.id,
      type,
    }),
    prescriptionRoute: PROVIDER_ROOM_ROUTES.procedurePrescription({
      id: procedure.id,
      type,
    }),
  };

  return {
    cosmetic: {
      ...common,

      infoRoute: PROVIDER_ROOM_ROUTES.procedureDetail({
        id: procedure.id,
        type,
      }),
    },
    health: {
      ...common,

      infoRoute: PROVIDER_ROOM_ROUTES.procedureHealthDetail({
        id: procedure.id,
        type,
      }),

      viewQuestionnaireRoute: PROVIDER_ROOM_ROUTES.procedureQuestionnaireView({
        id: procedure.id,
        patientId: procedure.patientId,
        type,
      }),
    },
  };
};
