import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../services/HttpService";
import { QueryKeys } from "./QueryKeys";
import { handleApiErrorMessage } from "../../utilities/api";
import { uiNotification } from "../../services/UINotificationService";

const GetPatientMemershipsList = ({ id }) => {
  const url = `get-membership-type/${id}`;
  return http.get(url);
};

export const useGetPatientMemershipsListQuery = (payload) => {
  return useQuery(
    [QueryKeys.GET_PATIENT_MEMBERSHIPS, payload],
    () => GetPatientMemershipsList(payload),
    { enabled: Boolean(payload.id), refetchOnMount: "always" },
  );
};

const GetNewMemership = (id) => {
  const url = `new-membership-type/${id}`;
  return http.get(url);
};

export const useGetNewMemershipQuery = (id) => {
  return useQuery([QueryKeys.GET_NEW_MEMBERSHIP, id], () =>
    GetNewMemership(id),
  );
};

const GetMemershipDetails = (payload) => {
  const url = `get-membership-details/`;
  return http.post(url, payload);
};

export const useGetMemershipDetailsQuery = (payload) => {
  return useQuery(
    [QueryKeys.GET_MEMBERSHIP_DETAILS, payload],
    () => GetMemershipDetails(payload),
    {
      enabled: Boolean(payload.clinic_id && payload.payment_frequency),
    },
  );
};

const ValidateCouponCode = (payload) => {
  const { clientId, ...data } = payload;
  const url = `/validate-coupon-code/${clientId}`;
  return http.post(url, data);
};

export const useValidateCouponCodeQuery = () => {
  return useMutation(ValidateCouponCode, {
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const UploadSignature = (payload) => {
  const { clientId: _, ...data } = payload;
  const url = `upload/signature/`;
  return http.post(url, data);
};

export const useUploadSignatureQuery = () => {
  return useMutation(UploadSignature, {
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const GetMembershipAgreement = (membershipId) => {
  const url = `get-membership-agreement/${membershipId}`;
  return http.get(url);
};

export const useGetMembershipAgreementQuery = (membershipId) => {
  return useQuery(
    [QueryKeys.GET_MEMBERSHIP_AGREEMENT, membershipId],
    () => GetMembershipAgreement(membershipId),
    {
      enabled: false,
    },
  );
};

const AddNewMembership = (payload) => {
  const { clientId, ...data } = payload;
  const url = `add-membership-subscription/${clientId}`;
  return http.post(url, data);
};

export const useAddNewMembershipQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(AddNewMembership, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
      uiNotification.success("Membership saved successfully!");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
  });
};

const CancelMembership = (payload) => {
  const { memId, clientID, immediateCancel } = payload;
  const url = `cancel-membership-subscription/${clientID}`;
  return http.post(url, {
    membership_id: memId,
    immediate_cancel: immediateCancel,
  });
};

export const useCancelMembershipQuery = ({ closeLoader }) => {
  const queryClient = useQueryClient();
  return useMutation(CancelMembership, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
      uiNotification.success("Membership canceled successfully!");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
    onSettled: () => {
      closeLoader();
    },
  });
};

const PayPastDueInvoice = (payload) => {
  const url = `pay-past-due-invoice`;
  return http.post(url, payload);
};

const updateSoldByMembershipDetails = (payload) => {
  const url = `membership-edit-sold-by`;
  return http.post(url, payload);
};

export const useUpdateMembershipDetailsSoldBy = (closeLoader) => {
  const queryClient = useQueryClient();
  return useMutation(updateSoldByMembershipDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(
        QueryKeys.UPDATE_SOLD_BY_MEMBERSHIP_DETAILS,
      );
      uiNotification.success("Membership sold by update successfully!");
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
    onSettled: () => {
      closeLoader();
    },
  });
};

export const usePayPastDueInvoiceQuery = ({ closeLoader }) => {
  const queryClient = useQueryClient();
  return useMutation(PayPastDueInvoice, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
      uiNotification.success(
        "Payment done for membership past due invoice successfully.",
      );
    },
    onError: (error) => {
      uiNotification.error(handleApiErrorMessage(error));
    },
    onSettled: () => {
      closeLoader();
    },
  });
};

const GetAllActiveUserData = (selectedClinicId) => {
  const url = `all-users-of-clinic`;
  return http.get(url, { params: { clinic_id: selectedClinicId } });
};

export const useGetAllActiveUserQuery = (selectedClinicId) => {
  return useQuery(
    [QueryKeys.GET_ALL_ACTIVE_USER_MEMBERSHIPS, selectedClinicId],
    () => GetAllActiveUserData(selectedClinicId),
    {
      enabled: Boolean(selectedClinicId),
    },
  );
};
