import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Sidebar from "../../../_legacy/Containers/Settings/sidebar.js";
import {
  isInteger,
  numberFormat,
  checkIfPermissionAllowed,
  isFormSubmit,
  toggleBodyScroll,
} from "../../../Utils/services.js";
import {
  getMembershipWallet,
  updateWallet,
  updateMembership,
  emptyMembershipWallet,
  searchProductByName,
  getMembershipMultiTier,
  saveMembershipMultiTier,
  changeMembershipMultiTierStatus,
  deleteMembershipMultiTier,
  getMembershipAgreement,
  getMembershipLogs,
} from "../../../Actions/Settings/membershipWalletActions.js";
import { getUser } from '../../../Actions/Settings/settingsActions'
import ConfirmationModal from "../../../Components/Common/ConfirmationModal.js";
import Loader from "../../../Components/Common/Loader.js";
import MembershipTypeModal from "./MembershipTypeModal.js";
import DiscountCoupons from "./DiscountCoupons.js";
import { Input } from "../../../shared/Input/Input.js";
import { Textarea } from "../../../shared/Textarea/Textarea.js";
import MemberShipUserLogList from "./MemberShipUserLogList.js";
import { tCommon } from "../../../i18n/useAppTranslation.js";
import DeclinePaymentSMSEmailConfigration from "./DeclineEmailAndSmsConfigration/DeclinePaymentSMSEmailConfigration.js";
import { getUserId } from "../../../utilities/localStorage";
import { LOCAL_STORAGE_KEYS } from "../../../consts/localStorage";

const SNIPPET_VARIABLE_ERROR = {
  declined_payment_sms_body: "Your message with substituted snippet variables is too long. Please reduce the length of your message",
}

class MembershipWallet extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      showLoader: false,
      userId: getUserId(LOCAL_STORAGE_KEYS.userData),
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      isEditWallet: false,
      isEditMemberShip: false,

      wallet_item_expiry: "",
      wallet_dollar_expiry: "",
      wallet_send_expiry_email: true,
      wallet_send_expiry_reminder: true,
      wallet_reminder_days_before: "1",
      walletData: {
        wallet_item_expiry: "",
        wallet_dollar_expiry: "",
        wallet_send_expiry_email: true,
        wallet_send_expiry_reminder: true,
        wallet_reminder_days_before: "1",
        is_patient_portal_wallet_enabled: false,
      },
      wallet_item_expiryClass: "",
      wallet_dollar_expiryClass: "",
      wallet_reminder_days_beforeClass:
        "text-inline-input input-fill-box filled-border",

      is_membership_enable: true,
      recurly_program_name: "",
      membership_tier: "multiple",
      mothly_membership_type: "free",
      thankyou_message: "",
      mothly_membership_fees: "0.00",
      one_time_membership_setup: "0.00",
      add_fees_to_client_wallet: false,
      membershipData: {
        is_membership_enable: true,
        recurly_program_name: "",
        membership_tier: "multiple",
        mothly_membership_type: "free",
        mothly_membership_fees: "0.00",
        one_time_membership_setup: "0.00",
        thankyou_message: "",
        add_fees_to_client_wallet: false,
        show_membership_on_portal: false,
      },
      recurly_program_nameClass: "",
      mothly_membership_feesClass: "",
      one_time_membership_setupClass: "",
      thankyou_messageClass: "",
      yearly_membership_feesClass: "",

      membershipTypeList: [],
      membershipTypeData: {},
      showMembershipTypeModal: false,
      membershipTierId: 0,
      isActive: null,
      wallet_expiry: "Never",

      autoProductData: {},

      showConfirmationModal: false,
      confirmationMsg: "",
      confirmationAction: "",
      frequency_option: "monthly",
      yearly_membership_fee: "",
      autoRenew: false,

      membership_color: "",
      membershipColorList: [],
      membership_colorError: false,
      membershipAgreeData: [],
      membershipLogsData: {},
      agreement_id: "",
      agreementClass: "",
      dollar_expiry: "Never",
      show_membership_on_portal: true,
      membership_description_for_booking_portal: "",
      isLoadMoreUserLog: false,
      isEmailAndSMSConfigModalOpen: false,
      declined_payment_email_body: '',
      declined_payment_email_subject: '',
      declined_payment_sms_body: '',
      declined_payment_template_status: false,
      isEmailOrSms: false,
      declined_payment_email_logo: '',
      clinic_location: {}
    };
  }
  componentDidMount() {
    this.setState({ showLoader: true });
    this.props.getMembershipWallet();
    this.props.getMembershipAgreement();
    this.props.getMembershipLogs();
    this.props.getClinicLocation(this.state.userId, 'profile')
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isUpdateSettingMembership && this.props.isUpdateSettingMembership && this.state.isEmailAndSMSConfigModalOpen) {
      this.props.getMembershipWallet();
      this.setState({ isEmailAndSMSConfigModalOpen: false });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.emptyMembershipWallet();
      returnState.showLoader = false;
    }
    if (nextProps.clinic_location) {
      returnState.clinic_location = nextProps.clinic_location
    }

    if (
      nextProps.membershipWalletData !== undefined &&
      prevState.membershipWalletData !== nextProps.membershipWalletData
    ) {
      returnState.membershipWalletData = nextProps.membershipWalletData;
      returnState.showLoader = false;

      if (nextProps.isResetWallet) {
        returnState.wallet_item_expiry =
          nextProps.membershipWalletData.wallet_item_expiry == 50000
            ? ""
            : nextProps.membershipWalletData.wallet_item_expiry;
        returnState.wallet_dollar_expiry =
          nextProps.membershipWalletData.wallet_dollar_expiry == 50000
            ? ""
            : nextProps.membershipWalletData.wallet_dollar_expiry;
        returnState.wallet_expiry =
          nextProps.membershipWalletData.wallet_item_expiry == 50000
            ? "Never"
            : "custom";
        returnState.dollar_expiry =
          nextProps.membershipWalletData.wallet_dollar_expiry == 50000
            ? "Never"
            : "custom";
        returnState.wallet_send_expiry_email = nextProps.membershipWalletData
          .wallet_send_expiry_email
          ? true
          : false;
        returnState.wallet_send_expiry_reminder = nextProps.membershipWalletData
          .wallet_send_expiry_reminder
          ? true
          : false;
        returnState.wallet_reminder_days_before = nextProps.membershipWalletData
          .wallet_reminder_days_before
          ? nextProps.membershipWalletData.wallet_reminder_days_before
          : "1";
        returnState.is_patient_portal_wallet_enabled = nextProps
          .membershipWalletData.is_patient_portal_wallet_enabled
          ? true
          : false;
        returnState.autoRenew =
          nextProps.membershipWalletData.membership_auto_renew;

        returnState.walletData = {
          wallet_item_expiry:
            returnState.wallet_item_expiry == 50000
              ? ""
              : returnState.wallet_item_expiry,
          wallet_dollar_expiry: returnState.wallet_dollar_expiry,
          wallet_send_expiry_email: returnState.wallet_send_expiry_email,
          wallet_send_expiry_reminder: returnState.wallet_send_expiry_reminder,
          wallet_reminder_days_before: returnState.wallet_reminder_days_before,
          is_patient_portal_wallet_enabled:
            returnState.is_patient_portal_wallet_enabled,
          wallet_expiry: returnState.wallet_expiry,
          dollar_expiry: returnState.dollar_expiry,
        };
        returnState.isEditWallet = false;
      }

      if (nextProps.isResetMembership) {
        returnState.is_membership_enable = nextProps.membershipWalletData
          .is_membership_enable
          ? true
          : false;
        returnState.recurly_program_name =
          nextProps.membershipWalletData.recurly_program_name;
        returnState.membership_tier =
          nextProps.membershipWalletData.membership_tier;
        returnState.mothly_membership_type =
          nextProps.membershipWalletData.mothly_membership_type;
        returnState.mothly_membership_fees = nextProps.membershipWalletData
          .mothly_membership_fees
          ? nextProps.membershipWalletData.mothly_membership_fees
          : "0.00";
        returnState.one_time_membership_setup = nextProps.membershipWalletData
          .one_time_membership_setup
          ? nextProps.membershipWalletData.one_time_membership_setup
          : "0.00";
        returnState.thankyou_message =
          nextProps.membershipWalletData.thankyou_message;
        if (nextProps.isUpdateEmailAndSMSData) {
          const {
            declined_payment_email_body,
            declined_payment_template_status,
            declined_payment_sms_body,
            declined_payment_email_subject,
            declined_payment_email_logo,
          } = nextProps.membershipWalletData;

          returnState = {
            ...returnState,
            declined_payment_email_body,
            declined_payment_template_status: Boolean(declined_payment_template_status),
            declined_payment_sms_body,
            declined_payment_email_subject,
            declined_payment_email_logo,
            originallDeclinedPaymentEmailSmsData: {
              declined_payment_email_body,
              declined_payment_email_subject,
              declined_payment_sms_body,
              declined_payment_template_status: Boolean(declined_payment_template_status),
            }
          }
        }
        returnState.add_fees_to_client_wallet = nextProps.membershipWalletData
          .add_fees_to_client_wallet
          ? true
          : false;
        returnState.show_membership_on_portal = nextProps.membershipWalletData
          .show_membership_on_portal
          ? true
          : false;
        returnState.frequency_option =
          nextProps.membershipWalletData.membership_payment_options;
        returnState.yearly_membership_fee =
          nextProps.membershipWalletData.yearly_membership_fees;
        returnState.membershipColorList = nextProps.membershipWalletData
          .membership_colors
          ? nextProps.membershipWalletData.membership_colors
          : [];
        returnState.membership_color = nextProps.membershipWalletData
          .membership_color
          ? nextProps.membershipWalletData.membership_color
          : "#F5CB42";
        returnState.membership_colorError = false;
        returnState.autoRenew = nextProps.membershipWalletData
          .membership_auto_renew
          ? true
          : false;
        returnState.membershipTypeList = nextProps.membershipWalletData
          .membership_tier_plans.length
          ? nextProps.membershipWalletData.membership_tier_plans
          : [];
        returnState.membership_description_for_booking_portal =
          nextProps.membershipWalletData.membership_description_for_booking_portal;

        returnState.membershipData = {
          is_membership_enable: returnState.is_membership_enable,
          recurly_program_name: returnState.recurly_program_name,
          membership_tier: returnState.membership_tier,
          mothly_membership_type: returnState.mothly_membership_type,
          mothly_membership_fees: returnState.mothly_membership_fees,
          one_time_membership_setup: returnState.one_time_membership_setup,
          thankyou_message: returnState.thankyou_message,
          add_fees_to_client_wallet: returnState.add_fees_to_client_wallet,
          frequency_option: returnState.frequency_option,
          yearly_membership_fee:
            nextProps.membershipWalletData.yearly_membership_fees,
          membership_color: returnState.membership_color,
          autoRenew: returnState.autoRenew,
          show_membership_on_portal: returnState.show_membership_on_portal,
        };
        returnState.isEditMemberShip = false;
        returnState.mothly_membership_fees = numberFormat(
          returnState.mothly_membership_fees,
          "decimal",
          2,
        );
        returnState.one_time_membership_setup = numberFormat(
          returnState.one_time_membership_setup,
          "decimal",
          2,
        );
        returnState.agreement_id =
          nextProps.membershipWalletData.membership_agreement_id;
      }
      if (nextProps.isResetMembershipType) {
        toggleBodyScroll(false);
        returnState.showMembershipTypeModal = false;
        returnState.membershipTierId = 0;
        returnState.isActive = null;
        if (
          nextProps.membershipWalletData.membership_tier_plans !== undefined &&
          nextProps.membershipWalletData.membership_tier_plans !== null
        ) {
          returnState.membershipTypeList =
            nextProps.membershipWalletData.membership_tier_plans;
        } else {
          returnState.membershipTypeList = [];
        }
      }
      nextProps.emptyMembershipWallet();
      return returnState;
    } else if (
      nextProps.autoProductData !== undefined &&
      prevState.autoProductData !== nextProps.autoProductData
    ) {
      returnState.autoProductData = nextProps.autoProductData;
      returnState.showLoader = false;
    } else if (
      nextProps.membershipTypeData !== undefined &&
      prevState.membershipTypeData !== nextProps.membershipTypeData
    ) {
      returnState.membershipTypeData = nextProps.membershipTypeData;
      returnState.showLoader = false;
      returnState.showMembershipTypeModal = true;
      toggleBodyScroll(true);
      nextProps.emptyMembershipWallet();
    } else if (
      nextProps.membershipAgreeData !== undefined &&
      prevState.membershipAgreeData !== nextProps.membershipAgreeData
    ) {
      returnState.membershipAgreeData = nextProps.membershipAgreeData
        ? nextProps.membershipAgreeData
        : prevState.membershipAgreeData;
    } else if (
      nextProps.membershipLogsData !== undefined &&
      prevState.membershipLogsData !== nextProps.membershipLogsData
    ) {
      returnState.membershipLogsData = nextProps.membershipLogsData || prevState.membershipLogsData;
      returnState.isLoadMoreUserLog = false
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (value === "settings/membership-agreements/create") {
      this.props.history.push(`/${value}`);
    }

    if (name === "agreement_id") {
      this.setState({ agreement_id: value });
    }

    const processedValue = type === "checkbox" ? checked : value;
    this.setState({
      [name]: processedValue,
      userChanged: true,
    });
  };

  handleSelectChange = (selectedValue) => {
    const { name, value } = selectedValue;
    this.setState({
      [name]: value,
      userChanged: true,
    });
  };

  handleEditWallet = () => {
    this.setState({
      wallet_item_expiry: this.state.walletData.wallet_item_expiry,
      wallet_dollar_expiry: this.state.walletData.wallet_dollar_expiry,
      wallet_send_expiry_email: this.state.walletData.wallet_send_expiry_email,
      wallet_send_expiry_reminder:
        this.state.walletData.wallet_send_expiry_reminder,
      wallet_reminder_days_before:
        this.state.walletData.wallet_reminder_days_before,
      is_patient_portal_wallet_enabled:
        this.state.is_patient_portal_wallet_enabled,
      isEditWallet: !this.state.isEditWallet,
    });
  };

  handleCancelWallet = () => {
    this.handleEditWallet();
  };

  handleSubmitWallet = (event) => {
    event.preventDefault();
    if (!isFormSubmit()) return;

    let error = this.checkFields([
      "wallet_item_expiry",
      "wallet_dollar_expiry",
      "wallet_reminder_days_before",
    ]);
    if (error) return;

    const {
      wallet_send_expiry_email,
      wallet_send_expiry_reminder,
      wallet_item_expiry,
      wallet_expiry,
      wallet_dollar_expiry,
      dollar_expiry,
      is_patient_portal_wallet_enabled,
      wallet_reminder_days_before,
    } = this.state;

    let formData = {
      wallet_send_expiry_email: wallet_send_expiry_email ? 1 : 0,
      wallet_send_expiry_reminder: wallet_send_expiry_reminder ? 1 : 0,
      wallet_item_expiry:
        wallet_expiry === "custom" ? wallet_item_expiry : "50000",
      wallet_dollar_expiry:
        dollar_expiry === "custom" ? wallet_dollar_expiry : "50000",
      is_patient_portal_wallet_enabled: is_patient_portal_wallet_enabled
        ? 1
        : 0,
    };

    if (wallet_send_expiry_reminder) {
      formData.wallet_reminder_days_before = wallet_reminder_days_before;
    }

    this.setState({ showLoader: true });
    this.props.updateWallet(formData);
  };

  checkFields = (fields) => {
    let error = false;
    for (let field of fields) {
      const fieldValue = this.state[field];
      const fieldExpiry = field.split("_")[1];
      const isCustom = this.state[`${fieldExpiry}_expiry`] === "custom";

      if (!isCustom || fieldValue || isInteger(fieldValue, 1)) {
        this.setState({ [`${field}Class`]: "simpleInput" });
        continue;
      }

      this.setState({ [`${field}Class`]: "simpleInput field-error" });
      error = true;
    }

    const { wallet_send_expiry_reminder, wallet_reminder_days_before } =
      this.state;

    if (!wallet_reminder_days_before && wallet_send_expiry_reminder) {
      this.setState({
        wallet_reminder_days_beforeClass:
          "text-inline-input input-fill-box filled-border field-error",
      });
      error = true;
    } else {
      this.setState({
        wallet_reminder_days_beforeClass:
          "text-inline-input input-fill-box filled-border",
      });
    }

    return error;
  };

  handleEditMembership = () => {
    this.setState({
      is_membership_enable: this.state.membershipData.is_membership_enable,
      recurly_program_name: this.state.membershipData.recurly_program_name,
      membership_tier: this.state.membershipData.membership_tier,
      mothly_membership_type: this.state.membershipData.mothly_membership_type,
      mothly_membership_fees: this.state.membershipData.mothly_membership_fees,
      one_time_membership_setup:
        this.state.membershipData.one_time_membership_setup,
      thankyou_message: this.state.membershipData.thankyou_message,
      add_fees_to_client_wallet:
        this.state.membershipData.add_fees_to_client_wallet,
      show_membership_on_portal:
        this.state.membershipData.show_membership_on_portal,
      membership_color: this.state.membershipData.membership_color,
      autoRenew: this.state.membershipData.autoRenew,
      isEditMemberShip: !this.state.isEditMemberShip,
    });
    this.props.getMembershipAgreement();
  };

  handleCancelMembership = () => {
    this.handleEditMembership();
  };

  handleSubmitMembership = (event) => {
    event.preventDefault();
    const formData = {
      is_membership_enable: +this.state.is_membership_enable,
      membership_description_for_booking_portal:
        this.state.membership_description_for_booking_portal,
      recurly_program_name: this.state.recurly_program_name,
      membership_tier: "multiple",
      membership_payment_options: this.state.frequency_option.value,
      mothly_membership_fees: this.state.mothly_membership_fees,
      yearly_membership_fees: this.state.yearly_membership_fee,
      thankyou_message: this.state.thankyou_message,
      add_fees_to_client_wallet: +this.state.add_fees_to_client_wallet,
      show_membership_on_portal: +this.state.show_membership_on_portal,
      membership_color: this.state.membership_color,
      membership_agreement_id: this.state.agreement_id,
      mothly_membership_type: this.state.mothly_membership_type,
      one_time_membership_setup: +this.state.one_time_membership_setup,
      membership_auto_renew: 0,
      declined_payment_email_subject: this.state.declined_payment_email_subject,
      declined_payment_email_body: this.state.declined_payment_email_body,
      declined_payment_sms_body: this.state.declined_payment_sms_body,
      declined_payment_template_status: +this.state.declined_payment_template_status
    };

    if (!this.validateForm(formData)) {
      return;
    }

    this.setState({ showLoader: true });
    this.props.updateMembership(formData)
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  validateForm = (formData) => {
    const errors = [];

    if (!formData.recurly_program_name) {
      errors.push("Recurly program name is required");
    }

    if (!formData.thankyou_message) {
      errors.push("Thank you message is required");
    }

    if (!formData.membership_color) {
      errors.push("Membership color is required");
    }

    if (errors.length) {
      this.setState({
        formErrors: errors,
      });
      return false;
    }

    return true;
  };

  toggleFlag = (flag) => {
    this.setState({ [flag]: !this.state[flag] });
  };

  showMembershipTypeModal = (membershipTierId) => {
    toggleBodyScroll(true);
    this.setState({
      membershipTierId: membershipTierId,
      showMembershipTypeModal: true,
    });
  };

  addMembershipType = (membershipTierId) => {
    toggleBodyScroll(true);
    this.setState({
      showMembershipTypeModal: true,
      membershipTierId: 0,
      autoProductData: {},
      membershipTypeData: {},
    });
  };

  editMembershipType = (membershipTierId) => {
    if (membershipTierId > 0) {
      this.setState({
        showLoader: true,
        membershipTierId: membershipTierId,
        autoProductData: {},
        membershipTypeData: {},
      });
      this.props.getMembershipMultiTier(membershipTierId);
    }
  };

  activeMembershipType = () => {
    if (this.state.membershipTierId > 0) {
      this.setState({ showLoader: true });
      this.props.changeMembershipMultiTierStatus(this.state.membershipTierId, {
        active: this.state.isActive,
      });
      //this.resetConfirmation();
    }
  };

  saveMembershipMultiTier = async (formData) => {
    this.setState({ showLoader: true });
    await this.props.saveMembershipMultiTier(this.state.membershipTierId, formData);
    this.setState({ showLoader: false });
    this.props.getMembershipLogs();
  };

  deleteMembershipType = () => {
    if (this.state.membershipTierId > 0) {
      this.setState({ showLoader: true });
      this.props.deleteMembershipMultiTier(this.state.membershipTierId);
    }
  };

  checkdeleteMembershipTypePermission = (membershipTierId) => {
    if (checkIfPermissionAllowed("update-existing-membership-fee-discount")) {
      this.setState({
        membershipTierId,
        showConfirmationModal: true,
        confirmationMsg:
          this.state.settingsLang
            .membership_type_tier_delete_message,
        confirmationAction:
          "deleteMembershipType",
      })
    } else {
      toast.error(this.state.globalLang?.action_not_allowed);
    }
  };

  searchProductByName = (formData) => {
    this.props.searchProductByName(formData);
  };

  hideMembershipTypeModal = (resetState) => {
    toggleBodyScroll(false);
    this.setState(resetState);
  };

  confirmationAction = () => {
    const action = this.state.confirmationAction;

    switch (action) {
      case "activeMembershipType":
        this.activeMembershipType();
        break;
      case "deleteMembershipType":
        this.deleteMembershipType();
        break;
      default:
        this.onResetConfirmation({
          confirmationAction: "",
          confirmationMsg: "",
          showConfirmationModal: false,
        });
        break;
    }
  };

  onResetConfirmation = (resetState) => {
    this.setState(resetState);
  };

  handleLoadMoreUserLog = (pageNumber) => {
    this.setState({ isLoadMoreUserLog: true });
    this.props.getMembershipLogs(pageNumber);
  };

  handleEmailAndSMSConfigModal = (isPreviewState) => {
    this.setState(isPreviewState);
  }

  handleChangeEmailAndSms = (event) => {
    const { type, name, checked, value: targetValue } = event.target;
    const value = type === 'checkbox' ? checked : targetValue;

    if (type === 'checkbox') {
      this.setState({ [name]: value });
      return;
    }

    if (name === 'declined_payment_sms_body') {
      const maxCharCount = 1300;
      if (value.length <= maxCharCount) {
        this.setState({ [name]: value });
      }
    }
  };

  handleEditorChange = (childState) => {
    this.setState(childState);
  };

  render() {
    let agreeData = this.state.membershipAgreeData
      ? this.state.membershipAgreeData
      : [];
    let agreeName = [];
    agreeData.map((obj, idx) => {
      if (obj.id == this.state.agreement_id) {
        agreeName.push(obj.name);
      }
    });

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />

          {this.state.showConfirmationModal && (
            <ConfirmationModal
              showConfirmationModal={this.state.showConfirmationModal}
              confirmationMsg={this.state.confirmationMsg}
              confirmationAction={this.confirmationAction}
              onResetConfirmation={this.onResetConfirmation}
            />
          )}

          {this.state.showMembershipTypeModal && (
            <MembershipTypeModal
              showMembershipTypeModal={this.state.showMembershipTypeModal}
              membershipTierId={
                this.state.membershipTierId ? this.state.membershipTierId : 0
              }
              membershipTypeData={this.state.membershipTypeData}
              saveMembershipMultiTier={this.saveMembershipMultiTier}
              autoProductData={this.state.autoProductData}
              searchProductByName={this.searchProductByName}
              hideMembershipTypeModal={this.hideMembershipTypeModal}
              membershipColorList={this.state.membershipColorList}
              membershipAgreeData={this.state.membershipAgreeData}
            />
          )}

          {this.state.isEmailAndSMSConfigModalOpen &&
            <DeclinePaymentSMSEmailConfigration
              isEmailAndSMSConfigModalOpen={this.state.isEmailAndSMSConfigModalOpen}
              declined_payment_email_body={this.state.declined_payment_email_body}
              declined_payment_template_status={this.state.declined_payment_template_status}
              declined_payment_sms_body={this.state.declined_payment_sms_body}
              declined_payment_email_subject={this.state.declined_payment_email_subject}
              clinic_address={this.state.clinic_location}
              emailLogo={this.state.declined_payment_email_logo}
              handleEmailAndSMSConfigModal={this.handleEmailAndSMSConfigModal}
              handleChangeEmailAndSms={this.handleChangeEmailAndSms}
              handleSubmitMembership={this.handleSubmitMembership}
              handleEditorChange={this.handleEditorChange}
              isEmailOrSms={this.state.isEmailOrSms}
              originalData={this.state.originallDeclinedPaymentEmailSmsData}
              showLoader={this.state.showLoader}
              patientOrClient={this.state.settingsLang["AppointmentEmailSMS_Patient_Name"]}
            />
          }

          <div className="memberWalletOuter relative  ">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                {this.state.settingsLang.sidebar_membership_wallet_menu}
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="setting-setion m-b-15">
                  <div className="membershipSectionTitle">
                    {this.state.settingsLang.Patient_Membership_Program}
                    {this.state.isEditMemberShip === false && (
                      <a
                        onClick={() => this.handleEditMembership()}
                        className="easy-link pull-right no-padding"
                      >
                        <i className="fa fa-pencil-alt m-r-5" />
                        {this.state.globalLang.label_edit}
                      </a>
                    )}
                  </div>
                  {this.state.isEditMemberShip === false && (
                    <div className="membership-view-block">
                      <div className="membershipSectionContent">
                        <div className="memberPoint">
                          {this.state.membershipData.is_membership_enable ===
                            true && (
                              <a>
                                <i className="fa fa-check-circle m-r-5" />
                              </a>
                            )}
                          {this.state.membershipData.is_membership_enable ===
                            false && (
                              <a>
                                <i className="fa fa-circle m-r-5" />
                              </a>
                            )}
                          <span>
                            {this.state.settingsLang.settings_membership}
                          </span>{" "}
                          <span className="primary-color">
                            {" "}
                            {this.state.membershipData.is_membership_enable
                              ? this.state.settingsLang.twoFA_enabled
                              : this.state.settingsLang.twoFA_disabled}
                          </span>
                        </div>

                        <div className="row">
                          <div className="row memberPoint m-t-15 m-l-15">
                            {this.state.declined_payment_template_status && (
                              <div>
                                <i className="fa fa-check-circle m-r-5" />
                              </div>
                            )}
                            {!this.state.declined_payment_template_status && (
                              <div>
                                <i className="fa fa-circle m-r-5" />
                              </div>
                            )}
                            <span>
                              {tCommon("label.configureDeclineEmailSMS")}
                            </span>{" "}
                            <span className="primary-color">
                              {" "}
                              {this.state.declined_payment_template_status
                                ? this.state.settingsLang.twoFA_enabled
                                : this.state.settingsLang.twoFA_disabled}
                            </span>
                          </div>
                          <span
                            onClick={() => this.handleEmailAndSMSConfigModal({ isEmailAndSMSConfigModalOpen: true })}
                            className="easy-link pull-right m-t-10 m-r-5 cursor-pointer"
                          >
                            <i className="fa fa-pencil-alt m-r-5" />
                            {this.state.globalLang.label_edit}
                          </span>
                        </div>

                        {this.state.membershipData.is_membership_enable && (
                          <div className="membership-enabled-block">
                            <div className="m-t-15">
                              <span>
                                {
                                  this.state.settingsLang
                                    .Patient_THANK_YOU_MESSAGE
                                }
                              </span>{" "}
                              <span className="primary-color">
                                {this.state.membershipData.thankyou_message}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Membership program in edit mode started */}
                  {this.state.isEditMemberShip && (
                    <div className="membership-edit-block">
                      {/* Membership program content in edit mode started */}
                      <div className="membershipSectionContent">
                        <div className="simpleField">
                          <div className="setting-custom-switch wallet-switch no-border p-t-0 p-b-0 m-t-0">
                            {this.state.is_membership_enable
                              ? this.state.settingsLang
                                .Patient_Membership_Enabled
                              : this.state.settingsLang
                                .Patient_Membership_Disabled}
                            <label className="setting-switch pull-right">
                              <input
                                type="checkbox"
                                id="is_membership_enable"
                                className="setting-custom-switch-input"
                                name="is_membership_enable"
                                checked={
                                  this.state.is_membership_enable
                                    ? "checked"
                                    : false
                                }
                                onChange={this.handleInputChange}
                              />
                              <span className="setting-slider" />
                            </label>
                          </div>
                        </div>
                        {/* Membership program content in edit mode started Membership Enabled */}
                        {this.state.is_membership_enable && (
                          <div className="membership-enabled-block">
                            <div className="simpleField">
                              <div className="simpleLabel">
                                {this.state.settingsLang.Patient_PROGRAM_NAME}
                                <span className="setting-require">*</span>
                              </div>
                              <Input
                                type="text"
                                name="recurly_program_name"
                                className={this.state.recurly_program_nameClass}
                                value={this.state.recurly_program_name}
                                onChange={this.handleInputChange}
                              />
                            </div>
                            <div className="simpleField">
                              <div className="simpleLabel">
                                {
                                  this.state.settingsLang
                                    .Patient_THANK_YOU_MESSAGE
                                }{" "}
                                <span className="setting-require">*</span>
                              </div>
                              <Textarea
                                className={this.state.thankyou_messageClass}
                                autoComplete="off"
                                name="thankyou_message"
                                value={this.state.thankyou_message}
                                onChange={this.handleInputChange}
                              />
                              {/* <textarea className={this.state.thankyou_messageClass} autoComplete="off" name="thankyou_message" value={this.state.thankyou_message} onChange={this.handleInputChange} /> */}
                            </div>

                            <div className={"no-display"}>
                              Auto renew yearly subscriptions{" "}
                              <input
                                type="checkbox"
                                name="autoRenew"
                                className="productDirectoryCheck"
                                checked={
                                  this.state.autoRenew == 1 ? "checked" : false
                                }
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Membership program content in edit mode ended */}

                      <div className="footer-static">
                        <button
                          className="new-blue-btn pull-right"
                          onClick={this.handleSubmitMembership}
                        >
                          {this.state.globalLang.label_save}
                        </button>
                        <button
                          className="new-white-btn pull-right"
                          onClick={() => this.handleCancelMembership()}
                        >
                          {this.state.globalLang.label_cancel}
                        </button>
                      </div>
                    </div>
                  )}
                  {/* Membership program in edit mode ended */}
                </div>
                {this.state.is_membership_enable &&
                  this.state.membership_tier === "multiple" && (
                    <div className="membership-enabled-block membership-type-multiple-block">
                      <div className="setting-setion m-b-15">
                        <div className="membershipSectionTitle">
                          {this.state.settingsLang.settings_membership_type}
                          <a
                            onClick={() => this.addMembershipType(0)}
                            className="easy-link pull-right no-padding"
                          >
                            <i className="fa fa-plus-circle m-r-5" /> Add new
                            type
                          </a>
                        </div>
                        <div className="membershipSectionContent p-t-10">
                          <div className="table-responsive no-border">
                            <table className="membership-type-table">
                              <tbody>
                                {this.state.membershipTypeList.length > 0 ? (
                                  this.state.membershipTypeList.map(
                                    (obj, idx) => {
                                      return (
                                        <tr key={"membershipTypeList-" + idx}>
                                          <td className="col-xs-6 word-break">
                                            {obj.tier_name}
                                          </td>
                                          {obj.id > 0 && (
                                            <td className="text-right col-xs-2">
                                              <label className="setting-switch pull-right sm-switch">
                                                <input
                                                  type="checkbox"
                                                  name="membership_tier_active"
                                                  checked={
                                                    obj.active === false ||
                                                      obj.active == 0
                                                      ? "checked"
                                                      : false
                                                  }
                                                  className="setting-custom-switch-input"
                                                  onChange={() =>
                                                    this.setState({
                                                      membershipTierId: obj.id,
                                                      isActive: obj.active
                                                        ? 0
                                                        : 1,
                                                      showConfirmationModal: true,
                                                      confirmationMsg:
                                                        obj.active
                                                          ? this.state
                                                            .settingsLang
                                                            .membership_type_tier_enable_message
                                                          : this.state
                                                            .settingsLang
                                                            .membership_type_tier_disable_message,
                                                      confirmationAction:
                                                        "activeMembershipType",
                                                    })
                                                  }
                                                />
                                                <span
                                                  className={
                                                    obj.id > 0
                                                      ? "setting-slider"
                                                      : "no-display"
                                                  }
                                                />
                                              </label>
                                            </td>
                                          )}
                                          {obj.id > 0 && (
                                            <td className="text-right col-xs-2 p-r-5">
                                              <a
                                                onClick={() =>
                                                  this.editMembershipType(
                                                    obj.id,
                                                  )
                                                }
                                                className={
                                                  obj.id > 0
                                                    ? "easy-link pull-right no-padding"
                                                    : "no-display"
                                                }
                                              >
                                                <i className="fa fa-pencil-alt m-r-5" />
                                                {
                                                  this.state.globalLang
                                                    .label_edit
                                                }
                                              </a>
                                            </td>
                                          )}
                                          {obj.id > 0 && (
                                            <td className="text-right col-xs-2">
                                              <a
                                                onClick={() =>
                                                  this.checkdeleteMembershipTypePermission(
                                                    obj.id
                                                  )
                                                }
                                                className={
                                                  obj.id > 0
                                                    ? "easy-link pull-right no-padding"
                                                    : "no-display"
                                                }
                                              >
                                                <i className="fa fa-trash-alt m-r-5" />
                                                {
                                                  this.state.globalLang
                                                    .label_delete
                                                }
                                              </a>
                                            </td>
                                          )}
                                          {obj.id == 0 && (
                                            <td
                                              colspan="4"
                                              className={
                                                obj.id == 0
                                                  ? "text-right col-xs-6"
                                                  : "no-display"
                                              }
                                            >
                                              (Single Tier Membership)
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    },
                                  )
                                ) : (
                                  <tr>
                                    <td colSpan={4} className="text-center">
                                      {
                                        this.state.globalLang
                                          .sorry_no_record_found
                                      }
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
              {checkIfPermissionAllowed("wallet-settings") && (
                <div className="col-sm-6 memberProgram-right">
                  <div className="setting-setion m-b-15">
                    <div className="membershipSectionTitle">
                      {this.state.settingsLang.Patient_Wallet_Settings}
                      {this.state.isEditWallet === false && (
                        <a
                          onClick={() => this.handleEditWallet()}
                          className="easy-link pull-right no-padding"
                        >
                          <i className="fa fa-pencil-alt m-r-5" />
                          {this.state.globalLang.label_edit}
                        </a>
                      )}
                    </div>
                    {this.state.isEditWallet === false && (
                      <div className="wallet-view-block">
                        <div className="membershipSectionContent walletSettingMinHeight">
                          {this.state.wallet_expiry == "custom" && (
                            <div>
                              <span>
                                {
                                  this.state.settingsLang
                                    .Patient_Items_in_client_wallet_will_expire_in
                                }
                              </span>{" "}
                              <span className="primary-color">
                                {" "}
                                {this.state.walletData.wallet_item_expiry}{" "}
                                {this.state.settingsLang.settings_days}
                              </span>
                            </div>
                          )}
                          {this.state.wallet_expiry == "Never" && (
                            <div>
                              <span>
                                {
                                  this.state.settingsLang
                                    .Patient_Items_in_client_wallet_will_expire
                                }
                              </span>{" "}
                              <span className="primary-color">
                                {" "}
                                {this.state.walletData.wallet_expiry}{" "}
                              </span>{" "}
                            </div>
                          )}
                          {this.state.dollar_expiry == "custom" && (
                            <div className="m-t-15">
                              <span>
                                {
                                  this.state.settingsLang
                                    .settings_wallet_dollar_credits_expiry_setting
                                }
                              </span>{" "}
                              <span className="primary-color">
                                {" "}
                                {
                                  this.state.walletData.wallet_dollar_expiry
                                }{" "}
                                {this.state.settingsLang.settings_days}{" "}
                              </span>
                            </div>
                          )}
                          {this.state.dollar_expiry == "Never" && (
                            <div className="m-t-15">
                              <span>
                                {
                                  this.state.settingsLang
                                    .settings_wallet_dollar_credits_expiry_setting
                                }
                              </span>{" "}
                              <span className="primary-color">
                                {" "}
                                {this.state.walletData.dollar_expiry}{" "}
                              </span>
                            </div>
                          )}
                          <div className="memberPoint m-t-15">
                            {this.state.walletData.wallet_send_expiry_email ===
                              true && (
                                <a>
                                  <i className="fa fa-check-circle m-r-5" />
                                </a>
                              )}
                            {this.state.walletData.wallet_send_expiry_email ===
                              false && (
                                <a>
                                  <i className="fa fa-circle m-r-5" />
                                </a>
                              )}
                            <span>
                              {
                                this.state.settingsLang
                                  .settings_send_an_email_to_the_customer_when_a_product_expires
                              }
                            </span>{" "}
                            <span className="primary-color">
                              {" "}
                              {this.state.walletData.wallet_send_expiry_email
                                ? this.state.settingsLang.twoFA_enabled
                                : this.state.settingsLang.twoFA_disabled}
                            </span>
                          </div>
                          <div className="memberPoint m-t-15">
                            {this.state.walletData
                              .is_patient_portal_wallet_enabled && (
                                <a>
                                  <i className="fa fa-check-circle m-r-5" />
                                </a>
                              )}
                            {this.state.walletData
                              .is_patient_portal_wallet_enabled === false && (
                                <a>
                                  <i className="fa fa-circle m-r-5" />
                                </a>
                              )}
                            <span>
                              Show what's in your wallet details in patient
                              portal?
                            </span>{" "}
                            <span className="primary-color">
                              {this.state.walletData
                                .is_patient_portal_wallet_enabled
                                ? this.state.settingsLang.twoFA_enabled
                                : this.state.settingsLang.twoFA_disabled}
                            </span>
                          </div>
                          <div className="memberPoint m-t-15">
                            {this.state.walletData
                              .wallet_send_expiry_reminder && (
                                <a>
                                  <i className="fa fa-check-circle m-r-5" />
                                </a>
                              )}
                            {this.state.walletData
                              .wallet_send_expiry_reminder === false && (
                                <a>
                                  <i className="fa fa-circle m-r-5" />
                                </a>
                              )}
                            <span>
                              {
                                this.state.settingsLang
                                  .settings_send_a_reminder_email_to_the_customer_when_a_product_is_about_to_expire
                              }
                            </span>{" "}
                            <span className="primary-color">
                              {this.state.walletData.wallet_send_expiry_reminder
                                ? this.state.settingsLang.twoFA_enabled
                                : this.state.settingsLang.twoFA_disabled}
                            </span>
                          </div>
                          <div className="row"></div>
                          {this.state.walletData.wallet_send_expiry_reminder ===
                            true && (
                              <div className="m-t-15">
                                <div>
                                  <span>
                                    {
                                      this.state.settingsLang
                                        .settings_send_reminder_email
                                    }
                                  </span>{" "}
                                  <span className="primary-color">
                                    {" "}
                                    {
                                      this.state.walletData
                                        .wallet_reminder_days_before
                                    }
                                  </span>{" "}
                                  <span>
                                    {
                                      this.state.settingsLang
                                        .settings_days_before_wallet_expiration
                                    }
                                  </span>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    )}
                    {this.state.isEditWallet && (
                      <div className="wallet-edit-block">
                        <div className="membershipSectionContent">
                          <div className="simpleField">
                            <p className="p-text m-b-0">
                              {
                                this.state.settingsLang
                                  .Patient_Items_in_client_wallet_will_expire_in
                              }
                            </p>
                            <div className="no-margin">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="radiobutton3"
                                  className="basic-form-checkbox"
                                  name="wallet_expiry"
                                  type="radio"
                                  value="Never"
                                  checked={
                                    this.state.wallet_expiry === "Never"
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="radiobutton3"
                                >
                                  Never
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="radiobutton4"
                                  className="basic-form-checkbox"
                                  name="wallet_expiry"
                                  type="radio"
                                  value="custom"
                                  checked={
                                    this.state.wallet_expiry === "custom"
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="radiobutton4"
                                >
                                  Custom
                                </label>
                              </div>
                              {this.state.wallet_expiry == "custom" && (
                                <div className="simpleField">
                                  <div className="simpleLabel">
                                    Days
                                    <span className="setting-require">*</span>
                                  </div>
                                  <Input
                                    type="text"
                                    className={
                                      this.state.wallet_item_expiryClass
                                    }
                                    placeholder={
                                      this.state.settingsLang.pos_days
                                    }
                                    autoComplete="off"
                                    name="wallet_item_expiry"
                                    value={this.state.wallet_item_expiry}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="simpleField">
                            <p className="p-text m-b-0">
                              {
                                this.state.settingsLang
                                  .settings_wallet_dollar_credits_expiry_setting
                              }
                            </p>
                            <div className="no-margin">
                              <div className="basic-checkbox-outer">
                                <input
                                  id="radiobutton3"
                                  className="basic-form-checkbox"
                                  name="dollar_expiry"
                                  type="radio"
                                  value="Never"
                                  checked={
                                    this.state.dollar_expiry === "Never"
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="radiobutton3"
                                >
                                  Never
                                </label>
                              </div>
                              <div className="basic-checkbox-outer">
                                <input
                                  id="radiobutton4"
                                  className="basic-form-checkbox"
                                  name="dollar_expiry"
                                  type="radio"
                                  value="custom"
                                  checked={
                                    this.state.dollar_expiry === "custom"
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <label
                                  className="basic-form-text"
                                  htmlFor="radiobutton4"
                                >
                                  Custom
                                </label>
                              </div>
                              {this.state.dollar_expiry == "custom" && (
                                <div className="simpleField">
                                  <div className="simpleLabel">
                                    Days
                                    <span className="setting-require">*</span>
                                  </div>
                                  <Input
                                    type="text"
                                    className={
                                      this.state.wallet_dollar_expiryClass
                                    }
                                    placeholder={
                                      this.state.settingsLang.pos_days
                                    }
                                    autoComplete="off"
                                    name="wallet_dollar_expiry"
                                    value={this.state.wallet_dollar_expiry}
                                    onChange={this.handleInputChange}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="simpleField">
                            <div className="setting-custom-switch wallet-switch no-border p-t-0 p-b-0">
                              {
                                this.state.settingsLang
                                  .Patient_Product_Expires_Message
                              }
                              <label className="setting-switch pull-right">
                                <input
                                  type="checkbox"
                                  id="wallet_send_expiry_email"
                                  className="setting-custom-switch-input"
                                  name="wallet_send_expiry_email"
                                  checked={
                                    this.state.wallet_send_expiry_email
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <span className="setting-slider" />
                              </label>
                            </div>
                          </div>
                          <div className="simpleField">
                            <div className="setting-custom-switch wallet-switch no-border p-t-0 p-b-0">
                              Show what's in your wallet details in patient
                              portal?
                              <label className="setting-switch pull-right">
                                <input
                                  type="checkbox"
                                  id="is_patient_portal_wallet_enabled"
                                  className="setting-custom-switch-input"
                                  name="is_patient_portal_wallet_enabled"
                                  checked={
                                    this.state.is_patient_portal_wallet_enabled
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <span className="setting-slider" />
                              </label>
                            </div>
                          </div>
                          <div className="simpleField">
                            <div className="setting-custom-switch wallet-switch no-border p-t-0 p-b-0">
                              {
                                this.state.settingsLang
                                  .Patient_Product_Expire_Reminder_Message
                              }
                              <label className="setting-switch pull-right">
                                <input
                                  type="checkbox"
                                  id="wallet_send_expiry_reminder"
                                  className="setting-custom-switch-input"
                                  name="wallet_send_expiry_reminder"
                                  checked={
                                    this.state.wallet_send_expiry_reminder
                                      ? "checked"
                                      : false
                                  }
                                  onChange={this.handleInputChange}
                                />
                                <span className="setting-slider" />
                              </label>
                            </div>
                          </div>
                          {this.state.wallet_send_expiry_reminder && (
                            <div className="simpleField" id="days_before">
                              <div className="new-field-label">
                                {
                                  this.state.settingsLang
                                    .settings_send_reminder_email
                                }
                                <input
                                  id="wallet_reminder_days_before"
                                  type="text"
                                  className={
                                    this.state.wallet_reminder_days_beforeClass
                                  }
                                  autoComplete="off"
                                  name="wallet_reminder_days_before"
                                  value={this.state.wallet_reminder_days_before}
                                  onChange={this.handleInputChange}
                                />
                                {
                                  this.state.settingsLang
                                    .settings_days_before_wallet_expiration
                                }{" "}
                                <span className="setting-require">*</span>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="footer-static">
                          <button
                            className="new-blue-btn pull-right"
                            onClick={this.handleSubmitWallet}
                          >
                            {this.state.globalLang.label_save}
                          </button>
                          <button
                            className="new-white-btn pull-right"
                            onClick={() => this.handleCancelWallet()}
                          >
                            {this.state.globalLang.label_cancel}
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  {checkIfPermissionAllowed("wallet-settings") && (
                    <DiscountCoupons isEditWallet={this.state.isEditWallet} />
                  )}

                  {this.state?.membershipLogsData?.data?.length > 0 && (
                    <MemberShipUserLogList
                      loadMore={this.handleLoadMoreUserLog}
                      membershipLogData={this.state?.membershipLogsData}
                      isLoadingMore={this.state.isLoadMoreUserLog}
                    />
                  )
                  }
                </div>
              )}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader && !this.state.isEmailAndSMSConfigModalOpen} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps({ MembershipWalletReducer, SettingReducer }) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const { action, data } = MembershipWalletReducer;
  const returnState = {};



  if (action === "GET_MEMBERSHIP_WALLET_DATA") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
      returnState.showLoader = true;
    } else {
      returnState.membershipWalletData = data.data;
      returnState.isResetWallet = true;
      returnState.isResetMembership = true;
      returnState.isResetMembershipType = true;
      returnState.isUpdateSettingMembership = false;
      returnState.isUpdateEmailAndSMSData = true;
    }
  } else if (action === "UPDATE_SETTING_WALLET_DATA") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
    } else {
      returnState.membershipWalletData = data.data;
      returnState.isResetWallet = true;
      returnState.isResetMembership = false;
      returnState.isResetMembershipType = false;
      showSuccessToast(languageData.global[data.message]);
    }
  } else if (action === "UPDATE_SETTING_MEMBERSHIP_DATA") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message] || SNIPPET_VARIABLE_ERROR?.[data?.message] || data.message);
    } else {
      returnState.membershipWalletData = data.data;
      returnState.isResetWallet = false;
      returnState.isResetMembership = true;
      returnState.isResetMembershipType = false;
      returnState.isUpdateSettingMembership = true;
      returnState.isUpdateEmailAndSMSData = false;
      showSuccessToast(languageData.global[data.message]);
    }
  } else if (action === "MEMBERSHIP_PRODUCT_SEARCH_LIST") {
    if (data.status !== 200) {
      //returnState.autoProductData = [];//data.data;
    } else {
      returnState.autoProductData = data.data;
    }
  } else if (action === "GET_MEMBERSHIP_MULTI_TIER") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
    } else {
      returnState.membershipTypeData = data.data;
    }
  } else if (action === "SAVE_MEMBERSHIP_MULTI_TIER") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
    } else {
      returnState.membershipWalletData = data.data;
      returnState.isResetWallet = false;
      returnState.isResetMembership = false;
      returnState.isResetMembershipType = true;
      showSuccessToast(languageData.global[data.message]);
    }
  } else if (
    action === "CHANGE_MEMBERSHIP_MULTI_TIER_STATUS" ||
    action === "DELETE_MEMBERSHIP_MULTI_TIER"
  ) {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
    } else {
      returnState.membershipWalletData = data.data;
      returnState.isResetWallet = false;
      returnState.isResetMembership = false;
      returnState.isResetMembershipType = true;
      showSuccessToast(languageData.global[data.message]);
    }
  } else if (action === "MEMEBERSHIP_AGREEMENT_DATA") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
    } else {
      returnState.membershipAgreeData = data.data;
      returnState.isResetWallet = false;
      returnState.isResetMembership = false;
      returnState.isResetMembershipType = true;
    }
  } else if (action === "MEMEBERSHIP_LOGS_DATA") {
    if (data.status !== 200) {
      showErrorToast(languageData.global[data.message]);
    } else {
      returnState.membershipLogsData = data.data;
      returnState.isResetWallet = false;
      returnState.isResetMembership = false;
      returnState.isResetMembershipType = true;
    }
  }
  if (SettingReducer.action === "PROFILE_GET") {
    returnState.clinic_location = SettingReducer.data?.data || {}
  }

  return { ...returnState, languageData };
}

function showErrorToast(message) {
  toast.dismiss();
  toast.error(message);
}

function showSuccessToast(message) {
  toast.dismiss();
  toast.success(message);
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMembershipWallet: getMembershipWallet,
      updateWallet: updateWallet,
      updateMembership: updateMembership,
      emptyMembershipWallet: emptyMembershipWallet,
      searchProductByName: searchProductByName,
      getMembershipMultiTier: getMembershipMultiTier,
      saveMembershipMultiTier: saveMembershipMultiTier,
      changeMembershipMultiTierStatus: changeMembershipMultiTierStatus,
      deleteMembershipMultiTier: deleteMembershipMultiTier,
      getMembershipAgreement: getMembershipAgreement,
      getMembershipLogs,
      getClinicLocation: getUser,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipWallet);
