import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import ReactSelect from '../../../Components/Common/ReactSelect';
import { positionFooterCorrectly,showFormattedDate } from '../../../Utils/services';

class ProfileInformation extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			profileInformation : props.profileInformation,
			profileDropDowns : props.profileDropDowns,
			clientLang : languageData.clients
		}
	}
	componentDidMount(){
	}

	static getDerivedStateFromProps(nextProps, prevState){
		let returnState = {};
		// Commernt
		positionFooterCorrectly();
		if(nextProps.defaultProfileData != undefined && nextProps.mergeDataTime != prevState.mergeDataTime){
			returnState.profileDropDowns = nextProps.profileDropDowns;
			returnState.mergeDataTime = nextProps.mergeDataTime;
			let selected_emailObject = [];
			let selected_email = [];
			let selected_phones = [];
			let selected_phonesObject = [];
			let email ;
			let phoneNumber;
			if(nextProps.defaultProfileData.selected_emailObject == undefined){
			if(nextProps.defaultProfileData.email != '' && nextProps.defaultProfileData.email != null){
			prevState.profileDropDowns.email.map((data,index) => {
				if(data.value == nextProps.defaultProfileData.email){
					selected_emailObject.push({
						value : data.id,
						label : data.value
					});
					selected_email.push(data.id);
				}
			})
			} else {
				if(prevState.profileDropDowns.email && prevState.profileDropDowns.email.length > 0){
					selected_emailObject.push({
						value : prevState.profileDropDowns.email[0].id,
						label : prevState.profileDropDowns.email[0].value
					})
					selected_email.push(prevState.profileDropDowns.email[0].id)
				}
				
			}
		} else{
				selected_emailObject = nextProps.defaultProfileData.selected_emailObject;
				selected_email = nextProps.defaultProfileData.selected_email;

			}
			if(nextProps.defaultProfileData.selected_phonesObject == undefined){
				if(nextProps.defaultProfileData.phoneNumber != '' && nextProps.defaultProfileData.phoneNumber != null){
				prevState.profileDropDowns.phone.map((data,index) => {
				if(data.value == nextProps.defaultProfileData.phoneNumber){
				selected_phonesObject.push({
					value : data.id,
					label : data.value
				});
				selected_phones.push(data.id);
				}
				})
				} else {
					if(prevState.profileDropDowns.phone && prevState.profileDropDowns.phone.length > 0){
						selected_phonesObject.push({
							value : prevState.profileDropDowns.phone[0].id,
							label : prevState.profileDropDowns.phone[0].value	
						})
						selected_phones.push(prevState.profileDropDowns.phone[0].id)
					}
				}
			}else{
					selected_phonesObject = nextProps.defaultProfileData.selected_phonesObject;
					selected_phones = nextProps.defaultProfileData.selected_phones;
	
				}
			returnState.defaultProfileData = nextProps.defaultProfileData;
			returnState.selected_firstname = nextProps.defaultProfileData.firstname;
			returnState.selected_lastname = nextProps.defaultProfileData.lastname;
			returnState.selected_gender = nextProps.defaultProfileData.gender;
			returnState.selected_dob = nextProps.defaultProfileData.date_of_birth ? nextProps.defaultProfileData.date_of_birth : nextProps.profileDropDowns.dob && nextProps.profileDropDowns.dob.length > 0 ? nextProps.profileDropDowns.dob[0] : '';
			returnState.selected_address = nextProps.defaultProfileData.address_line_1 ? nextProps.defaultProfileData.address_line_1 : nextProps.profileDropDowns.address && nextProps.profileDropDowns.address.length > 0 ? nextProps.profileDropDowns.address[0] : '';
			returnState.selected_address_line_2 = nextProps.defaultProfileData.address_line_2 ? nextProps.defaultProfileData.address_line_2 : nextProps.profileDropDowns.addressline2 && nextProps.profileDropDowns.addressline2.length > 0 ? nextProps.profileDropDowns.addressline2[0] : '';
			returnState.selected_city = nextProps.defaultProfileData.city ? nextProps.defaultProfileData.city : nextProps.profileDropDowns.city && nextProps.profileDropDowns.city.length > 0 ? nextProps.profileDropDowns.city[0] : '';
			returnState.selected_state = nextProps.defaultProfileData.state ? nextProps.defaultProfileData.state : nextProps.profileDropDowns.state && nextProps.profileDropDowns.state.length > 0 ? nextProps.profileDropDowns.state[0] : '';
			let check;
			if(nextProps.defaultProfileData.country){
			    check = nextProps.profileDropDowns.country.find(y=>y.value == nextProps.defaultProfileData.country);
			}
			returnState.selected_country = check ? check : nextProps.profileDropDowns.country && nextProps.profileDropDowns.country.length > 0 ? nextProps.profileDropDowns.country[0] : '';
			returnState.selected_pincode = nextProps.defaultProfileData.pincode ? nextProps.defaultProfileData.pincode : nextProps.profileDropDowns.pincode && nextProps.profileDropDowns.pincode.length > 0 ? nextProps.profileDropDowns.pincode[0] : '';
			returnState.selected_email = selected_email;
			returnState.selected_emailObject = selected_emailObject;
			returnState.selected_phones = selected_phones;
			returnState.selected_phonesObject = selected_phonesObject;
			returnState.default_email = nextProps.defaultProfileData.default_email ? nextProps.defaultProfileData.default_email : selected_emailObject && selected_emailObject.length > 0 ? selected_emailObject[0].label : '';
			returnState.default_phone = nextProps.defaultProfileData.default_phone ? nextProps.defaultProfileData.default_phone : selected_phonesObject && selected_phonesObject.length > 0 ? selected_phonesObject[0].label : '';
			returnState.selected_image = nextProps.defaultProfileData.user_image_url ? nextProps.defaultProfileData.user_image_url : '/images/appfemale.png';
		}
		return returnState;
		    
	}
	handleInputChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value,
		}, () => {
			this.sendDataToParent();
		});	
	}

	sendDataToParent = () => {
		let defaultProfileData = {
			firstname : this.state.selected_firstname,
			lastname : this.state.selected_lastname,
			gender : this.state.selected_gender,
			date_of_birth : this.state.selected_dob,
			address_line_1 : this.state.selected_address,
			address_line_2 : this.state.selected_address_line_2,
			city : this.state.selected_city,
			state : this.state.selected_state,
			country : this.state.selected_country,
			country_name : this.state.selected_country.full_name,
			pincode : this.state.selected_pincode,
			ccId: this.state.selected_cc ? parseInt(this.state.selected_cc) : null,
			user_image_url : this.state.selected_image,
			selected_email : this.state.selected_email,
			selected_emailObject : this.state.selected_emailObject,
			selected_phonesObject : this.state.selected_phonesObject,
			selected_phones : this.state.selected_phones,
			email : '',
			phoneNumber : '',
			user_image : this.state.image,
			default_email : this.state.default_email,
			default_phone : this.state.default_phone
		}
		let data = {
			step1Data : defaultProfileData
		}
		this.props.handleChildState(data);
	}

	handleReactSelect = (selectState) => {
		this.setState(selectState, () => {
		 this.sendDataToParent();
		})
		
		if(selectState.selected_emailObject && selectState.selected_emailObject.length > 0){
			let check = selectState.selected_emailObject.find(y=>y.label == this.state.default_email);
			if(!check){
				this.setState({ default_email : ''})
			}
		}
		if(selectState.selected_phonesObject && selectState.selected_phonesObject.length > 0){
			let check = selectState.selected_phonesObject.find(y=>y.label == this.state.default_phone);
			if(!check){
				this.setState({ default_phone : ''})
			}
		}
	}

	  setImage = (image) => {
		  this.setState({ selected_image : image.user_image_url, image : image.user_image }, () => {
			this.sendDataToParent();
		  });

	  }
	  removePic = () => {
		  this.setState({ selected_image : '/images/appfemale.png'},() => {
			this.sendDataToParent();
		  });
		  
	  }
	  
	
    render(){
        return(
    <div>
	<div className="merge-title full-width">
    <div className="row backgroundColorGray">
		<div className="col-md-4 col-xs-12">
			<div className="setting-setion full-width mergeLeftSection"> 
				<div className="infoSectionTitle">{this.state.clientLang.merge_preview_profile_information}</div>
				<div className="infoContent">
					
					<div className="preview-profile-pic">
						<div className="preview-picture" onClick={this.nextStep}>
							<img src={this.state.selected_image} className="mergeProfileImg"/>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_first_name}</label>
							<span>{this.state.selected_firstname}</span>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_last_name}</label>
							<span>{this.state.selected_lastname}</span>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_gender}</label>
							<span>{this.state.selected_gender == 0 ? 'Male' : this.state.selected_gender == 1 ? 'Female' : 'Not disclosed'}</span>
						</div>
						<div className="info-row">
							<label>{this.state.clientLang.client_date_of_birth}</label>
							<span>{this.state.selected_dob ? showFormattedDate(this.state.selected_dob, false) : ''}</span>
						</div>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_email}</label>
						<span>{this.state.selected_emailObject && this.state.selected_emailObject.map((data, index) =>{
							return(
								<p key={index}>{data.label} {data.label == this.state.default_email && <span><i className='fa fa-check m-l-5 greenTick'></i></span>}</p>
							)
						})}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_phone}</label>
						<span>{this.state.selected_phonesObject && this.state.selected_phonesObject.map((data, index) => {
							return(
								<p key={index}>{data.label} {data.label == this.state.default_phone && <span><i className='fa fa-check m-l-5 greenTick'></i></span>}</p>
								)
						
						})}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.clientprofile_address}</label>
						<span>{this.state.selected_address} {this.state.selected_address && this.state.selected_address_line_2 && <span>,</span>} {this.state.selected_address_line_2 ? this.state.selected_address_line_2 : ''}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_city}</label>
						<span>{this.state.selected_city}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_state}</label>
						<span>{this.state.selected_state}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_country}</label>
						<span>{this.state.selected_country.full_name}</span>
					</div>
					
					<div className="info-row">
						<label>{this.state.clientLang.client_zip_code}</label>
						<span>{this.state.selected_pincode}</span>
					</div>
				</div>
			</div>
		</div>
		<div className="col-md-8 col-xs-12">
			<div className="setting-setion full-width">
				<div className="merge-main-title">
					<span>{this.state.clientLang.merge_profile_information}</span>
					<p>{this.state.clientLang.merge_right_information_message}</p>
				</div>
				<div className="infoContent">
					{(this.state.profileDropDowns.images && this.state.profileDropDowns.images.length > 0) && <div className="AddPicturesOuter">
						<p><span>{this.state.clientLang.label_photo}</span> </p>
						{this.state.profileDropDowns.images && this.state.profileDropDowns.images.map((obj,index) =>{
							return(
								<div key={index} className={this.state.selected_image == obj.user_image_url ? 'preview-picture multiple-pictures active' : 'preview-picture multiple-pictures'}>
								<div style={{backgroundImage: `url(${obj.user_image_url})`}} onClick={() => this.setImage(obj)} className="profileImgThumb"></div>
								{/* <span className={this.state.selected_image == obj.user_image_url? 'profileImgCross' : 'profileImgCross no-display'}  onClick={this.removePic}><i className="fa fa-times" ></i></span> */}
						       </div>

							)
						})}
					</div>}
					
					<div className="row">
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_first_name}</div>
								<select className="simpleSelect" name='selected_firstname' value={this.state.selected_firstname} onChange={this.handleInputChange}>
									{this.state.profileDropDowns.firstname && this.state.profileDropDowns.firstname.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_last_name}</div>
								<select className="simpleSelect" name='selected_lastname' value={this.state.selected_lastname} onChange={this.handleInputChange}>
									{this.state.profileDropDowns.lastname && this.state.profileDropDowns.lastname.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_gender}</div>
								<select className="simpleSelect" name='selected_gender' value={this.state.selected_gender} onChange={this.handleInputChange}>
									{this.state.profileDropDowns.gender && this.state.profileDropDowns.gender.map((obj,index)=> {
										let value = '';
										if(obj == 1 || obj == '1'){
											value = 'Female';
										} else if(obj == 0 || obj == '0'){
											value = 'Male'
										} else if(obj == '2' || obj == 2){
											value = 'Not Disclosed'
										}
										return(
											<option key={index} value={obj}>{value}</option>
										)
									})}
									{/* <option value='2'>Not disclosed</option>
									<option value='0'>Male</option>
									<option value='1'>Female</option> */}
								</select>
							</div>
						</div>
                        <div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_date_of_birth}</div>
								<select className="simpleSelect" name='selected_dob' value={this.state.selected_dob} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{this.state.profileDropDowns.dob && this.state.profileDropDowns.dob.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{showFormattedDate(obj,false)}</option>
										)
									})}
								</select>
							</div>
						</div>
						<div className="col-lg-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_email}</div>
								<ReactSelect name='selected_email' selectedId={this.state.selected_email ? this.state.selected_email : ''} 
								list={this.state.profileDropDowns.email} value={'id'} 
								label={'value'} 
								handleReactSelect={this.handleReactSelect} isValid={!this.state.clinic_listError} 
								isMulti={true} />
							</div>
						</div>
                       
						<div className="col-lg-6  col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_phone}</div>
								<ReactSelect name='selected_phones' selectedId={this.state.selected_phones ? this.state.selected_phones : ''} 
								list={this.state.profileDropDowns.phone} value={'id'} 
								label={'value'} 
								handleReactSelect={this.handleReactSelect} isValid={!this.state.clinic_listError} 
								isMulti={true} />
							</div>
						</div>
						<div className='row no-margin'>
							<div className='col-lg-6 col-sm-6 col-xs-12'>
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{'Default Email'}</div>
								<select className="simpleSelect" name='default_email' value={this.state.default_email} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{this.state.selected_emailObject && this.state.selected_emailObject.map((obj, index) =>{
										return(
										<option key={index} value={obj.label}>{obj.label}</option>
										)
									})}
									
								</select>
							</div>
							</div>
							<div className='col-lg-6 col-sm-6 col-xs-12'>
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{'Default Phone'}</div>
								<select className="simpleSelect" name='default_phone' value={this.state.default_phone} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{this.state.selected_phonesObject && this.state.selected_phonesObject.map((obj, index) =>{
										return(
										<option key={index} value={obj.label}>{obj.label}</option>
										)
									})}
									
								</select>
							</div>
							</div>
						</div>
						<div className='row no-margin'>
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.merge_address_line}</div>
								<select className="simpleSelect" name='selected_address' value={this.state.selected_address} onChange={this.handleInputChange}>
								<option value=''>Select</option>
									{this.state.profileDropDowns.address && this.state.profileDropDowns.address.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
									
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_address2}</div>
								<select className="simpleSelect" name='selected_address_line_2' value={this.state.selected_address_line_2} onChange={this.handleInputChange}>
								<option value=''>Select</option>
									{this.state.profileDropDowns.addressline2 && this.state.profileDropDowns.addressline2.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
								</select>
							</div>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_city}</div>
								<select className="simpleSelect" name='selected_city' value={this.state.selected_city} onChange={this.handleInputChange}>
								    <option value=''>Select</option>
									{this.state.profileDropDowns.city && this.state.profileDropDowns.city.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_state}</div>
								<select className="simpleSelect" name='selected_state' value={this.state.selected_state} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{this.state.profileDropDowns.state && this.state.profileDropDowns.state.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_country}</div>
								<select className="simpleSelect" name='selected_country' value={this.state.selected_country} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{this.state.profileDropDowns.country && this.state.profileDropDowns.country.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj.full_name}</option>
										)
									})}
								</select>
							</div>
						</div>
						
						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">{this.state.clientLang.client_zip_code}</div>
								<select className="simpleSelect" name='selected_pincode' value={this.state.selected_pincode} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{this.state.profileDropDowns.pincode && this.state.profileDropDowns.pincode.map((obj, index) =>{
										return(
										<option key={index} value={obj}>{obj}</option>
										)
									})}
								</select>
							</div>
						</div>

						<div className="col-lg-3 col-sm-6 col-xs-12">
							<div className="simpleField m-b-15">
								<div className="simpleLabel">CC on File</div>
								<select className="simpleSelect" name='selected_cc' value={this.state.selected_cc} onChange={this.handleInputChange}>
									<option value=''>Select</option>
									{(this.state.profileDropDowns.creditCards || []).map((card) =>{
										return(
											<option key={card.id} value={card.id}>{card.card_number}</option>
										)
									})}
								</select>
							</div>
						</div>
						</div>
					</div>
					
				</div>
			</div>
		</div>
		
	</div>
    </div>
    </div>
        )
    }
}

export default ProfileInformation;

