import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { tSales } from "../../i18n/useAppTranslation.js";
import { uiNotification } from "../../services/UINotificationService.js";

positionFooterCorrectly();

const invoiceInstance = axios.create();

invoiceInstance.defaults.headers.common["access-token"] = getToken();

const languageData = JSON.parse(localStorage.getItem("languageData"));

invoiceInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

invoiceInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getInvoiceDetails(invoiceID, clientID) {
  return async (dispatch) => {
    return invoiceInstance
      .get(
        process.env.REACT_APP_API_URL +
          "sales/invoice_details/" +
          invoiceID +
          "/" +
          clientID,
        {
          headers: {
            "Cache-Control": "no-cache",
          },
        },
      )
      .then((response) => {
        dispatch({ type: "GET_INVOICE_DETAILS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_INVOICE_DETAILS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getPaymentHistory(invoiceID) {
  return async (dispatch) => {
    return invoiceInstance
      .get(
        process.env.REACT_APP_API_URL +
          "sales/invoices/payment-history/" +
          invoiceID,
      )
      .then((response) => {
        dispatch({ type: "GET_PAYMENT_HISTORY", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PAYMENT_HISTORY", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function sendAndDownloadInvoice(invoiceID, type) {
  return async (dispatch) => {
    return invoiceInstance
      .get(
        process.env.REACT_APP_API_URL +
          "sales/send_invoice/" +
          invoiceID +
          "/" +
          type,
      )
      .then((response) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_INVOICE",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_INVOICE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function sendAndDownloadRefundReceipt(invoiceID, type) {
  return (dispatch) => {
    invoiceInstance
      .get(
        process.env.REACT_APP_API_URL +
          "sales/send_refund/" +
          invoiceID +
          "/" +
          type,
      )
      .then((response) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_REFUND_RECEIPT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "EMAIL_AND_DOWNLOAD_REFUND_RECEIPT",
          payload: error.response.data,
        });
      });
  };
}

export function getRefundDetails(invoiceID) {
  return async (dispatch) => {
    return invoiceInstance
      .get(process.env.REACT_APP_API_URL + "sales/issue-refund/" + invoiceID)
      .then((response) => {
        dispatch({ type: "GET_REFUND_DETAILS", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_REFUND_DETAILS", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function payRefund(formData) {
  return (dispatch) => {
    invoiceInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/invoices/pay-refund",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ISSUE_REFUND", payload: response.data });
        if (response.data.status !== 200) {
          toast.error(languageData.global[response.data.message]);
        } else {
          toast.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "ISSUE_REFUND", payload: error.response.data });
      });
  };
}

export function changeUserID(formData) {
  return async (dispatch) => {
    return invoiceInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/update-invoice-item-employee",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_USER_IN_INVOICE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_USER_IN_INVOICE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function saveAndSendEmail(formData) {
  return async (dispatch) => {
    return invoiceInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/save-email",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SAVE_AND_SEND_EMAIL", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_AND_SEND_EMAIL", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function refundIssue(id) {
  return async (dispatch) => {
    return invoiceInstance
      .post(process.env.REACT_APP_API_URL + "refund-membership-invoice/" + id)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "MONTHLY_MEMBERSHIP_ISSUE_REFUND",
          payload: response.data,
        });
        if (response.data.status === 200) {
          toast.success(languageData.global[response.data.message]);
        } else {
          toast.error(languageData.global[response.data.message]);
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "MONTHLY_MEMBERSHIP_ISSUE_REFUND",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function voidAndRefund(formData) {
  return (dispatch) => {
    invoiceInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/void-refund-invoice",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "VOID_REFUND_INVOICE", payload: response.data });
        if (response.data.status !== 200) {
          toast.error(
            languageData.global[response.data.message] ||
              tSales(
                `invoicePreview.apiError.voidAndRefund.${response.data.message}`,
              ),
          );
        } else {
          toast.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "VOID_REFUND_INVOICE", payload: error.response.data });
        uiNotification.error(
          tSales(
            `invoicePreview.apiError.voidAndRefund.${error.response.data.message}`,
          ),
        );
      });
  };
}

export const editTipsPerItem = async (invoiceId, formData) => {
  return invoiceInstance.put(
    `${process.env.REACT_APP_API_URL}sales/update-invoice-item-tips/${invoiceId}`,
    formData,
  );
};
