import React from "react";
import { useAppTranslation } from "../../../../../../../../i18n/useAppTranslation";
import { MonthRangeSwitch } from "../../../../../../../../shared/MonthRangeSwitch/MonthRangeSwitch";
import { useCurrentUserQuery } from "../../../../../../../../api/queries/useUserQuery";
import { EntityHeader } from "../../../../../../../../widgets/EntityHeader/EntityHeader";
import { Button } from "../../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../../shared/CircularProgress/CircularProgress";
import { DownloadIcon } from "../../../../../../../../assets/Icons/DownloadIcon";
import { Maybe } from "../../../../../../../../utilities/fp";
import {
  apiDateStringToDate,
  dateToApiDateString,
} from "../../../../../../../../utilities/api";
import { DEFAULT_DATE_FORMAT } from "../../../../../../../../consts/general";
import styles from "./styles.module.scss";  

/**
 * @param {object} param0
 *
 * @param {{
 *    value: { from: string; to: string; };
 *    update: (value: { from: string; to: string; }) => void;
 *    isNextDisabled: boolean;
 * }} param0.period
 *
 * @param {boolean} param0.isLoading
 *
 * @param {{
 *    isLoading: boolean;
 *    initiate: () => void;
 * }} param0.download
 */
export function Header({ period, isLoading, download }) {
  const { tSettings } = useAppTranslation.Settings();
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const startDate = Maybe.of(period.value.from)
    .map(apiDateStringToDate)
    .orElse(() => new Date())
    .value();

  const endDate = Maybe.of(period.value.to)
    .map(apiDateStringToDate)
    .orElse(() => new Date())
    .value();

  return (
    <EntityHeader
      title={tSettings("smsUsageLog.title")}
      right={
        <div className={styles.right}>
          <MonthRangeSwitch
            isDisabled={isLoading}
            isNextDisabled={period.isNextDisabled}
            startDate={startDate}
            endDate={endDate}
            dateFormat={user?.previewDateFormat || DEFAULT_DATE_FORMAT}
            onChange={({ startDate, endDate }) => {
              period.update({
                from: dateToApiDateString(startDate),
                to: dateToApiDateString(endDate),
              });
            }}
          />
          <Button
            size="small"
            variant="outlined"
            onClick={download.initiate}
            isDisabled={download.isLoading}
            leftAdornment={
              download.isLoading ? (
                <CircularProgress size="small" />
              ) : (
                <DownloadIcon width="16px" />
              )
            }
          >
            {tCommon("label.downloadExcel")}
          </Button>
        </div>
      }
    />
  );
}
