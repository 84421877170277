import React, {useEffect, useState, useRef} from "react";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import { Input, Select, CheckBox, RadioButton } from '../../../Common/ReactHookForm'
import { Scrollbars } from "react-custom-scrollbars";
import axios from 'axios';
import { ToastContainer, toast } from "react-toastify";
import {
  accountStatusResponseInterceptor
} from "../../../AccountHoldRedirect/AccountHoldRedirectInterceptor";
const clearentLicense = axios.create();
clearentLicense.interceptors.response.use(accountStatusResponseInterceptor);
clearentLicense.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
   if(!error.response) {
      return {data : {data : "", message : "file_type_error", status : 400}}
   }
});

const FormStep4 = (props) => {
  const { handleFormStepChange, formValue, resources, toggleLoader, merchantNumber } = props;
  const { register, handleSubmit, watch, errors, control } = useForm({ defaultValues: formValue });
  const accountNumber = useRef({});
  const [fileName, setFilename] = useState((formValue.fileName) ? formValue.fileName : "");
  const [fileUrl, setFileUrl] = useState((formValue.document_url) ? formValue.document_url : "");
  const license_photo = watch('firearms_license_photo');
  const onSubmit = data => {
    if(fileName) {
      data.documents = []
      data.documents.push({type: "bank_voided_check", fileName: fileName});
    }
    handleFormStepChange(5, data)
    //alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation
  
  const uploadFile = (e) => {
    if(e.target.files.length) {
      
      const formData = new FormData();
      formData.append('file',e.target.files[0]);

      const name = e.target.files[0].name;
      const lastDot = name.lastIndexOf('.');

      const fileName = name.substring(0, lastDot);
      const ext = name.substring(lastDot + 1);

      if(ext == 'pdf' || ext == 'PDF') {
        toggleLoader(true);
        clearentLicense.post(process.env.REACT_APP_API_URL + "media/upload?upload_type=clearent", (formData)).then(response => {
           if (response && response.data && response.data.status === 200 ) {
             setFilename(response.data.data.file_name);
             setFileUrl(response.data.data.url);
             toggleLoader(false);
           } else {
              toast.error('Invalid file format. File of pdf format is supported.');
              toggleLoader(false);
           }
        })
      } else {
        toast.error('Invalid file format. File of pdf format is supported.');
        return false
      }
    }
  }

  const deleteFile = () => {
    setFilename('');
  }
  const copyCallback = (e) => {
      e.preventDefault();
      return false;
    }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-setion">
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={window.innerHeight-240}
        className="full-width"
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      >
        <div className="setting-container p20">
        
			 {merchantNumber &&  <a className="easy-link p-l-0" onClick={() => {
            handleFormStepChange(3)
          }}><i className="fa fa-angle-left m-r-5"></i> Go Back</a>}
          {merchantNumber && merchantNumber != '' && <a className="easy-link p-l-0 pull-right"> Merchant Id - {merchantNumber}</a>}
        
          {merchantNumber && 
            <React.Fragment>
              <div className="merchant-title">Merchant Application</div>
              <p className="p-text m-b-20">Welcome to the merchant facing application! Thank you for taking the time to help speed up your company's boarding process. Please fill out the application below to the best of your ability and click send when you're finished. This will alert your local rep.</p>

              <div className="settings-subtitle m-b-20 merchantSteps">3 out of 4: General Business Information </div>
            </React.Fragment>
          }

          <div className="merchant-subtitle m-b-10">{!merchantNumber ? "Bank Information" : "Banking"}:</div>

          <div className="row">
            <div className="col-sm-4 col-xs-12 m-b-15">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Bank Name<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.bankName) ? 'field_error' : ''}`}
                  placeholder="Bank Name"
                  name='bankName'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
          </div>


         {/* <div className="p-text m-b-10">Is your account under Legal Name or DBA?</div>
         
                   <div className="row no-margin m-b-15">
                     <div className="basic-checkbox-outer">
                       <input type="radio"
                         className={`basic-form-checkbox ${(errors && errors.isNameSameAsLegalOrDBAName) ? 'field_error' : ''}`}
                         name={'isNameSameAsLegalOrDBAName'}
                         value={true}
                         id={'isNameSameAsLegalOrDBAName_1'}
                         ref={register}
                         isRequired={false}
                         errors={errors}
                         control={control}
                       />
                       <label className="basic-form-text" htmlFor="isNameSameAsLegalOrDBAName_1">Legal Name</label>
                     </div>
                     <div className="basic-checkbox-outer">
                       <input type="radio"
                         className={`basic-form-checkbox ${(errors && errors.isNameSameAsLegalOrDBAName) ? 'field_error' : ''}`}
                         name={'isNameSameAsLegalOrDBAName'}
                         value={false}
                         id={'isNameSameAsLegalOrDBAName_2'}
                         ref={register}
                         isRequired={false}
                         errors={errors}
                         control={control}
                       />
                       <label className="basic-form-text" htmlFor="isNameSameAsLegalOrDBAName_2">Business Name</label>
                     </div>
                     <div className="basic-checkbox-outer">
                       <input type="radio"
                         className={`basic-form-checkbox ${(errors && errors.isNameSameAsLegalOrDBAName) ? 'field_error' : ''}`}
                         name={'isNameSameAsLegalOrDBAName'}
                         value={false}
                         id={'isNameSameAsLegalOrDBAName_3'}
                         ref={register}
                         isRequired={false}
                         errors={errors}
                         control={control}
                       />
                       <label className="basic-form-text" htmlFor="isNameSameAsLegalOrDBAName_3">Other</label>
                     </div>
                   </div>*/}

          <div className="row m-b-15">
            <div className="col-sm-4 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Account Number<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.accountNumber) ? 'field_error' : ''}`}
                  placeholder="Account Number"
                  name='accountNumber'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  onCopy={false}
                  onPaste={false}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Confirm Account Number<span className="setting-require">*</span>
                </div>
                <input
                  className={`newInputField ${(errors && errors.confirmAccountNumber) ? 'field_error' : ''}`}
                  placeholder="Confirm Account Number"
                  name='confirmAccountNumber'
                  ref={register({
                    required: true,
                    validate: (value) =>{
                      return value === watch('accountNumber') || "Account Number do not match"
                    }
                  })}
                  isRequired={true}
                  errors={errors}
                  control={control}
                  onCopy={(e) => copyCallback(e)}
                  onPaste={(e) => copyCallback(e)}
                />
              </div>
            </div>
            <div className="col-sm-4 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">Routing Number<span className="setting-require">*</span>
                </div>
                <Input
                  className={`newInputField ${(errors && errors.aba) ? 'field_error' : ''}`}
                  placeholder="Routing Number"
                  name='aba'
                  register={register}
                  isRequired={true}
                  errors={errors}
                  control={control}
                />
              </div>
            </div>
          </div>

          <div className="p-text m-b-10">Account Type</div>

          <div className="row no-margin m-b-20">
            <div className="basic-checkbox-outer">
              <input type="radio"
                className={`basic-form-checkbox ${(errors && errors.bankAccountTypeID) ? 'field_error' : ''}`}
                name={'bankAccountTypeID'}
                value={"1"}
                id={'bankAccountTypeID_1'}
                ref={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
              <label className="basic-form-text" htmlFor="bankAccountTypeID_1">Checking</label>
            </div>
            <div className="basic-checkbox-outer">
              <input type="radio"
                className={`basic-form-checkbox ${(errors && errors.bankAccountTypeID) ? 'field_error' : ''}`}
                name={'bankAccountTypeID'}
                value={"2"}
                id={'bankAccountTypeID_2'}
                ref={register}
                isRequired={true}
                errors={errors}
                control={control}
              />
              <label className="basic-form-text" htmlFor="bankAccountTypeID_2">Savings</label>
            </div>
          </div>
          {errors.bankAccountTypeID && <div className="row clearent-error-message">Please select one option.</div>}

          <div className="p-text m-b-10 p-l-5">Upload A Voided Check From Your Bank Account or DDA Letter From Your Bank</div>

          {fileName == '' && <div className="file-container file-upload-img" title="">
              <img className="" src="../../../../images/upload.png" />
              <span className="file-name-hide no-display"> </span>
              <span className="file-size-hide no-display"></span>
              <div className="upload">Upload
  											<input name="void_check_photo" onChange={e => uploadFile(e)} type="file" className="image_questionnaire" autoComplete="off" data-index="0" title="No file chosen" />
              </div>
            </div>
          }
          {fileName != '' && <div className="filename">{fileName} <a onClick={() => deleteFile()}></a></div>}
        </div>
       {!merchantNumber &&  <button type="submit" className="new-blue-btn m-l-20 m-t-10"> Save </button>}
    </Scrollbars>
    {merchantNumber && 
  		<div class="footer-static merchantFooter">
  			<div class="footerProgressOuter"> 
  			 <p><span>Wohoo!</span> Complete this page</p>
  			 <p>and you are done!</p>
  			<div class="footerProgressBg"><span class="footerProgressBar" style={{width: "70%"}}></span></div>
  			</div>
  			<button class="new-blue-btn m-t-5" type="submit">Next</button>
  		</div>
    }

        {/*<div className="footer-static">
                  <button className="new-blue-btn pull-right" type="submit">Next</button>
                  <button className="new-line-btn pull-left" type="button" onClick={() => {
                    handleFormStepChange(3)
                  }}>Back</button>
                </div>*/}
        
       
        
        
        
      </div>
    </form>
  );
}
export default FormStep4;
