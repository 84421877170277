import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import { ToastContainer, toast } from "react-toastify";
import { displayName, capitalizeFirstLetter } from '../../../Utils/services';
import { fetchDocumentHealthQuestionnaire, getDocumentProcedureHealthQuestionnaire, getAppointmentQuestionnaires} from '../../../Actions/Clients/clientsAction';
import defLogo from '../../../_legacy/images/upload.png';
class AppointmentQuestionnaires extends Component{
constructor(props){
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.state = {
        appointmentID : this.props.match.params.appointmentID ? this.props.match.params.appointmentID : '',
        globalLang            : languageData.global,
        languageData          : languageData.procedure
    };
    window.onscroll = () => {   
      return false
    }
}
componentDidMount(){
    this.setState({ showLoader : true });
    this.props.getAppointmentQuestionnaires(this.state.appointmentID)
   
}

static getDerivedStateFromProps(nextProps,prevState){
    let returnState = {};
    if(nextProps.appointmentQuestionnaire != undefined && nextProps.appointmentQuestionnaireDataTime != prevState.appointmentQuestionnaireDataTime){
        let appointmentQuestionnaireData = nextProps.appointmentQuestionnaire.data;
        appointmentQuestionnaireData && appointmentQuestionnaireData.map((data,index) => {
            data.health_timeline_answer.map((obj,index2) => {
                if(obj.question_type == 'File Upload'){
                    let key = obj.answer.split('.');
                    obj.file_type = key[key.length-1];
                    let m = nextProps.appointmentQuestionnaire.image_base_url + '/' + obj.answer;
                    obj.answer = m;
                }
                
            })
        })
        returnState.appointmentQuestionnaireData = appointmentQuestionnaireData;
        returnState.appointmentQuestionnaireDataTime = nextProps.appointmentQuestionnaireDataTime;
        if(appointmentQuestionnaireData && appointmentQuestionnaireData.length == 1){
          returnState['showQuestionnaire-' + appointmentQuestionnaireData[0].id] = true;
        }
        returnState.image_url = nextProps.appointmentQuestionnaire.image_base_url;
        returnState.showLoader = false;
    }
    return returnState;
}

viewUploadedQuestionnaireFile = (downloadPath, event) => {
    if(downloadPath){
      window.open(downloadPath,'_blank');
    }
  }

  setCollapse = (selectedId, id) => {
      let returnState = {};
      const state = this.state;
      Object.keys(state).forEach(function (key) {
        if (typeof key === 'string' && key.startsWith('showQuestionnaire-')) {
          returnState[key] = false
        }
      });
      returnState['showQuestionnaire-' + selectedId] = true;
      this.setState(returnState)
  }
render(){
    return(
        <div id="content">
         <div className="container-fluid content setting-wrapper">
            <div className="juvly-section full-width m-t-15">
               <div className="juvly-container">
               <div className="juvly-title m-b-30">{"QUESTIONNAIRES"}
                     <a onClick={() => this.props.history.goBack()} className="pull-right"><img className="pull-right m-t-5" src="/images/close.png"/></a>
                     </div>
                  {this.state.appointmentQuestionnaireData && this.state.appointmentQuestionnaireData.length > 0 && this.state.appointmentQuestionnaireData.map((data,index) => {
 return(
 <div className="accordion questionnaireAccordion" id="accordionExample">
 <div key={"questionnaireList"} className='membershipAccordion'>
   <div className="membershipAccordionHead" id="headingOne">
     <h2 className="mb-0">
       <button className="btn btn-link cursor-default" type="button" data-toggle={'collapse'} onClick={this.setCollapse.bind(this, data.id)}  data-target={'questionnaire-' + index} aria-expanded='false' aria-controls="collapseOne">
           <span>{index + 1} {data.procedure_template ? data.procedure_template.title : ''} </span>
          
       </button>
     </h2>
   </div>
   <div id={'questionnaire-' + index} className={this.state['showQuestionnaire-' + data.id] === true ? 'collapse show' : 'collapse'} aria-labelledby="headingOne" data-parent="#accordionExample">
     <div className={"proQuesAccordion"}>
       { /* HEALTH TIMELINE QUESTIONNAIRE - APPOINTMENT START */}
       
         <div>

           {data.health_timeline_answer && data.health_timeline_answer.length > 0 && data.health_timeline_answer.map((obj,index2)=> {
               return(<div key={'templateQuestionList-' + obj.id} className="survey-qus-ans">
               <div className="survey-ques"><span className="que-label">{this.state.languageData.pro_que}</span> {obj.question.question}</div>
               {obj.question_type!='File Upload' && <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>{(obj.question_type) == 'Opinion Scale' ? (obj.score ? obj.score : '') :  (obj.answer ? obj.answer : '')}&nbsp;</div>}
               {obj.question_type == 'File Upload' && 
                         <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>
<div className="row add-doc-section">
<div className="col-xs-6 m-b-20">
<div className={"file-container file-upload-img"} title={(obj.answer) ? obj.answer : ''}>
{(obj.answer) ? 
<img
 className={"full-custom-img cursor-pointer"}
 src={(obj.file_type && (obj.file_type == 'jpg' || obj.file_type == 'png' || obj.file_type == 'jpeg')) ? obj.answer : defLogo}
 onClick={this.viewUploadedQuestionnaireFile.bind(this,obj.answer)}
/>
:
<img
 className={""}
 src={defLogo}
/>
}
<span onClick={this.viewUploadedQuestionnaireFile.bind(this,obj.answer)} className={(obj.answer) ? "file-name file-info cursor-pointer" : "file-name-hide no-display"}> { obj.answer}</span>
</div>
</div>
</div>
</div>}


             </div>
           )})}

         </div>
       
       { /* HEALTH TIMELINE QUESTIONNAIRE APPOINTMENT - END */}

     </div>
   </div>
 </div>


</div>)
                  })}
                    
            </div>
         </div>
         <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
           <div className="loader-outer">
             <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
             <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
           </div>
         </div>
      </div>
      </div>
    )
}
}
function mapStateToProps(state) {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const returnState = {};
    if(state.ClientsReducer.action === 'GET_APPOINTMENT_HEALTH_QUESTIONNAIRE'){
        if(state.ClientsReducer.data.status === 200){
            returnState.appointmentQuestionnaire = state.ClientsReducer.data.data;
            returnState.appointmentQuestionnaireDataTime = new Date();
        } else {
            toast.dismiss();
            toast.error(languageData.global[state.ClientsReducer.data.message]);
        }
    }

    if(state.ClientsReducer.action === 'GET_PROCEDURE_HEALTH_QUESTIONNAIRE'){
      if(state.ClientsReducer.data.status === 200){
          returnState.appointmentProcedureQuestionnaire = state.ClientsReducer.data.data;
          returnState.appointmentProcedureQuestionnaireDataTime = new Date();
      } else {
          toast.dismiss();
          toast.error(languageData.global[state.ClientsReducer.data.message]);
      }
  }

  if(state.ClientsReducer.action === 'GET_APPOINTMENT_QUESTIONNAIRES'){
    if(state.ClientsReducer.data.status === 200){
        returnState.appointmentQuestionnaire = state.ClientsReducer.data.data;
        returnState.appointmentQuestionnaireDataTime = new Date();
    } else {
        toast.dismiss();
        toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
}

    return returnState;
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchDocumentHealthQuestionnaire : fetchDocumentHealthQuestionnaire,
        getDocumentProcedureHealthQuestionnaire : getDocumentProcedureHealthQuestionnaire,
        getAppointmentQuestionnaires : getAppointmentQuestionnaires
     }, dispatch)
  }
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppointmentQuestionnaires));
