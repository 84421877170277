import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const salesInstance = axios.create();

salesInstance.defaults.headers.common["access-token"] = getToken();

salesInstance.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status === 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg === "invalid_token" ||
        msg === "session_timeout" ||
        msg === "server_error" ||
        msg === "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

salesInstance.interceptors.response.use(accountStatusResponseInterceptor);

export const fetchIsCherryAvailable = () => {
  return (dispatch) => {
    dispatch({
      type: "IS_CHERRY_AVAILABLE",
      payload: { status: "loading", data: { isCherryAvailable: false } },
    });
    salesInstance
      .get(process.env.REACT_APP_API_URL + "pos-cherry/isAvailable")
      .then((response) => {
        dispatch({
          type: "IS_CHERRY_AVAILABLE",
          payload: {
            status: "success",
            data: { isCherryAvailable: response.data.data.cherryAvailable },
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "IS_CHERRY_AVAILABLE",
          payload: {
            status: "error",
            data: { isCherryAvailable: false, message: error.response.data },
          },
        });
      });
  };
};

export const fetchCherryPatientByCherryPatientId = ({
  patientId,
  cherryPatientId,
  phoneNumber = "",
}) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .get(
        process.env.REACT_APP_API_URL +
          `pos-cherry/fetchPatientByCherryId/${patientId}/${cherryPatientId}/${phoneNumber}`,
      )
      .then((response) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_CHERRY_PATIENT_ID",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const refreshCherryPatient = ({ patientId, cherryPatientId }) => {
  return (dispatch) => {
    dispatch({
      type: "REFRESH_CHERRY_PATIENT",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .patch(
        process.env.REACT_APP_API_URL +
          `pos-cherry/refreshPatient/${patientId}/${cherryPatientId}`,
      )
      .then((response) => {
        dispatch({
          type: "REFRESH_CHERRY_PATIENT",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "REFRESH_CHERRY_PATIENT",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const fetchCherryPatientByPhone = ({ patientId, phoneNumber }) => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_CHERRY_PATIENT_BY_PHONE",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .get(
        process.env.REACT_APP_API_URL +
          `pos-cherry/fetchPatientByPhone/${patientId}/${phoneNumber}`,
      )
      .then((response) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_PHONE",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_CHERRY_PATIENT_BY_PHONE",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};

export const clearCurrentPatient = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_PATIENT" });
  };
};

export const createCherryPatient = (patientId, phone) => {
  return (dispatch) => {
    dispatch({
      type: "CREATE_CHERRY_PATIENT",
      payload: { status: "loading", data: null },
    });
    salesInstance
      .post(process.env.REACT_APP_API_URL + "pos-cherry/createPatient", {
        patient: { patientId, phone },
      })
      .then((response) => {
        dispatch({
          type: "CREATE_CHERRY_PATIENT",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_CHERRY_PATIENT",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
};
