/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useRef } from "react";
import { getCountries } from "libphonenumber-js";
import axios from "axios";
import UpgradeClinicModal from "./UpgradeClinicModal";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../AccountHoldRedirect/AccountHoldRedirectInterceptor";

const clinicsInstance = axios.create();

clinicsInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

clinicsInstance.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }
    positionFooterCorrectly();
    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status === 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }
      let msg = error.response.data.message;
      if (
        msg === "invalid_token" ||
        msg === "session_timeout" ||
        msg === "server_error" ||
        msg === "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

clinicsInstance.interceptors.response.use(accountStatusResponseInterceptor);

function ClinicModalList({ ...props }) {
  const [allClinics, setAllClinics] = useState([]);
  const [errors, setErrors] = useState("");
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [visibility, setVisibility] = useState("flex");
  const [modalOverflow, setModalOverflow] = useState("auto");
  const [updatedClinics, setUpdatedClinics] = useState([]);

  const ref = useRef(null);
  const submitErrorMessage = "At least one of the fields is invalid";
  const defaultCountryInputValue = "Select a country";

  function toggleOverflow() {
    if (data.length > 0) {
      setModalOverflow("scroll");
    }
  }

  function getCountriesAndStates(allData, countryList) {
    setStates(getCountries());
    let existingCountries = [
      {
        countryCode: defaultCountryInputValue,
        countryName: defaultCountryInputValue,
      },
    ];
    let index = 1;
    for (let item of allData) {
      if (item.requires_update) {
        if (!existingCountries.includes(item.country)) {
          existingCountries[index++] = item.country;
        }
      }
    }
    for (let { country_code, country_name } of countryList) {
      if (existingCountries.includes(country_code)) {
        existingCountries[existingCountries.indexOf(country_code)] = {
          countryCode: country_code,
          countryName: country_name,
        };
      }
    }

    setCountries(existingCountries);
  }

  function filterCountries(e) {
    const countrySelected = e.target.value;
    if (countrySelected === defaultCountryInputValue) {
      setData(allClinics);
      return;
    }
    let filteredData = [];
    let index = 0;

    for (let item of allClinics) {
      if (item.country === countrySelected) filteredData[index++] = item;
    }

    setData(filteredData);
  }

  function closePopup() {
    setVisibility("none");
  }

  function handleClickOutside(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      setModalOverflow("auto");
      document.body.style.overflow = "scroll";
    } else {
      setModalOverflow("auto");
    }
  }

  function updateClinics() {
    const doPostRequest = async () =>
      await clinicsInstance.post(
        process.env.REACT_APP_API_URL + "update_clinics",
        { clinics_data: updatedClinics },
      );
    doPostRequest();
    closePopup();
  }

  function handleValidate() {
    for (let item of updatedClinics) {
      for (let property in item) {
        if (
          property === "address" ||
          property === "clinic_city" ||
          property === "clinic_zipcode" ||
          property === "clinic_state"
        ) {
          if (!item[property].trim() || item[property].length === 0) {
            setErrors(submitErrorMessage);
            return;
          }
          if (
            property === "clinic_zipcode" &&
            item[property] &&
            item[property].length < 5
          ) {
            setErrors(submitErrorMessage);
            return;
          }
        }
      }
    }
    setErrors("");
    updateClinics();
  }

  useEffect(() => {
    async function getClinics() {
      const { data: newData } = await clinicsInstance.get(
        process.env.REACT_APP_API_URL + "clinics",
      );
      const { data: countryData } = await clinicsInstance.get(
        process.env.REACT_APP_API_URL + "get_clinic_default_data",
      );

      setData(newData.data);
      setAllClinics(newData.data);
      getCountriesAndStates(newData.data, countryData.data.country_list);
    }
    getClinics();
    toggleOverflow();

    document.addEventListener("click", handleClickOutside, true);
    document.body.style.overflow = "hidden";
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  let clinicsThatRequireUpdate = allClinics.filter(
    (clinic) => clinic.requires_update,
  );
  let clinicsThatRequireUpdateFiltered = data.filter(
    (clinic) => clinic.requires_update,
  );

  useEffect(() => {
    if (clinicsThatRequireUpdate.length <= 0) {
      document.body.style.overflow = "scroll";
    }
  }, [clinicsThatRequireUpdate]);

  return (
    clinicsThatRequireUpdate.length > 0 && (
      <>
        <section
          {...props}
          style={{
            width: "100%",
            height: "100%",
            padding: "10px",
            backgroundColor: "rgba(0,0,0,0.5)",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "start",
            position: "fixed",
            zIndex: "30",
            display: visibility,
          }}
        >
          <div
            ref={ref}
            style={{
              maxHeight: "90vh",
              position: "relative",
              zIndex: "30",
              overflowY: modalOverflow,
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <div
              className="UpgradeClinic-modal-wrapper popup-modal-header"
              style={{
                backgroundColor: "rgb(194, 224, 242)",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "5px",
              }}
            >
              <div
                style={{ fontSize: "15px", color: "black", paddingTop: "15px" }}
              >
                <p>Please Review & Confirm Your Clinic Addresses</p>
              </div>
              <span
                onClick={closePopup}
                style={{
                  fontSize: "20px",
                  fontWeight: "bold",
                  cursor: "pointer",
                  color: "gray",
                }}
              >
                x
              </span>
            </div>
            <div
              className="row d-flex flex-row-reverse popup-modal-header"
              style={{ backgroundColor: "white" }}
            >
              <div className="col">
                <div className="newInputFileldOuter m-t-0">
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "30px",
                      marginInline: "40px",
                    }}
                  >
                    We need your help to confirm the clinic address found inside
                    of AR matches the address found on Google. This ensures the
                    proper location is provided in appointment-related
                    communications.{" "}
                  </p>
                  <p
                    style={{
                      textAlign: "center",
                      paddingBottom: "15px",
                      marginInline: "30px",
                    }}
                  >
                    Please update each location if needed and save!
                  </p>
                </div>
              </div>
            </div>
            <div
              className="row d-flex flex-row-reverse popup-modal-header"
              style={{ backgroundColor: "white" }}
            >
              <div className="col col-lg-3 col-md-6 col-sm-6 ">
                <div className="newInputFileldOuter m-t-0">
                  <div className="newInputLabel">
                    Country<span className="setting-require">*</span>
                  </div>
                  <select
                    onChange={filterCountries}
                    className="newSelectField"
                    type="text"
                    name="country"
                  >
                    {countries.length > 0 &&
                      countries.map(
                        (country) =>
                          country.countryName &&
                          country.countryCode && (
                            <option
                              key={country.countryName}
                              value={country.countryCode}
                            >
                              {country.countryName}
                            </option>
                          ),
                      )}
                  </select>
                </div>
              </div>
            </div>
            {clinicsThatRequireUpdateFiltered.length > 0 &&
              clinicsThatRequireUpdateFiltered.map((modal) => {
                return (
                  <UpgradeClinicModal
                    states={states}
                    allClinics={allClinics}
                    setAllClinics={setAllClinics}
                    key={modal.id}
                    updatedClinics={updatedClinics}
                    setUpdatedClinics={setUpdatedClinics}
                    data={modal}
                  />
                );
              })}
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                textAlign: "center",
                paddingBottom: "60px",
                paddingTop: "10px",
              }}
            >
              {errors.length > 0 && <p style={{ color: "red" }}>{errors}</p>}
              <button
                type="button"
                onClick={handleValidate}
                className="ClockLoggedInBtn"
              >
                Update Information
              </button>
            </div>
          </div>
        </section>
      </>
    )
  );
}

export default ClinicModalList;
