import React from "react";
import { ToastContainer } from "react-toastify";
import moment from "moment";

class FooterTouchMD extends React.Component {
  constructor(props) {
    super(props);

    document.body.classList.add("guest-body");
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang:
        languageData && languageData.global ? languageData.global : {},
      year: moment().format("YYYY"),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.globalLang && nextProps.globalLang !== prevState.globalLang) {
      returnState.globalLang = nextProps.globalLang;
    }
    return returnState;
  }

  render() {
    return (
      <footer className="footer-fixed" id="guest-footer-fixed">
        <div className="wrapper">
          {this.state.globalLang && (
            <p>
              {"© " +
                this.state.year +
                " " +
                "Aesthetic Record. All Rights Reserved."}{" "}
            </p>
          )}
          <ul id="social"></ul>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </footer>
    );
  }
}

export default FooterTouchMD;
