/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable react/no-string-refs */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { animateScroll } from "react-scroll";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  geClientNotes,
  saveClientNote,
  deleteClientNote,
  getAClientNote,
} from "../../Actions/ClientNotes/clientNotesActions.js";
import { checkIfPermissionAllowed, getUser } from "../../Utils/services.js";
import "react-tagsinput/react-tagsinput.css";
import CustomerNote from "./CustomerNote";
import "./client-notes.scss";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal.js";
import Loader from "../Common/Loader.js";

class ClientNotes extends Component {
  constructor(props) {
    super(props);

    this.escFunction = this.escFunction.bind(this);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      backURLType: this.props.match.params.actionType
        ? this.props.match.params.actionType
        : "clients",
      action: this.props.match.params.type
        ? this.props.match.params.type
        : "profile",
      patientID:
        this.props.match.params.patientID !== null &&
        this.props.match.params.patientID !== "" &&
        this.props.match.params.patientID
          ? this.props.match.params.patientID
          : 0,

      showLoader: false,
      customerNotes: [],
      patientData: [],
      customerNote: [],
      showModal: false,
      showTitleModal: false,
      dataChanged: false,
      noteText: "",
      noteTitle: "",
      noteClass: "setting-textarea-box",
      tags: [],
      noteTobeDeleted: 0,
      noteTobeEdited: 0,
      languageData: languageData.procedure,
      globalLang: languageData.global,
      showSearchResult: false,
      caretPos: -1,
    };
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.setState({ showSearchResult: false, caretPos: -1 });
    }
  }

  fetchNotesList = () => {
    this.setState({ showLoader: true });

    let formData = {
      params: {
        patient_id: this.state.patientID,
        sortby: "created",
      },
    };
    this.showLoaderFunc();
    this.props.geClientNotes(formData);
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.fetchNotesList();
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  showLoaderFunc = () => {
    this.setState({ showLoader: true });
    localStorage.setItem("showLoader", true);
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.customerNotes !== undefined &&
      props.customerNotes.status === 200 &&
      props.customerNotes !== state.customerNotes
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        localStorage.setItem("showLoader", true);

        if (!props.customerNotes.data.patient_detail) {
          setTimeout(function () {
            window.location.href = "/clients";
          }, 0);
        }

        return {
          customerNotes: props.customerNotes.data.patient_notes,
          patientData: props.customerNotes.data.patient_detail,
          showLoader: false,
          dotPhrases: props.customerNotes.data.dot_phrases_list,
          dotPhrasesArr: props.customerNotes.data.dot_phrases_list,
        };
      }
    } else if (
      props.customerNotes !== undefined &&
      props.customerNotes.status !== 200 &&
      props.customerNotes !== state.customerNotes
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        localStorage.setItem("showLoader", true);

        return {
          customerNotes: props.customerNotes.data.patient_notes,
          patientData: props.customerNotes.data.patient_detail,
          showLoader: false,
          dotPhrases: props.customerNotes.data.dot_phrases_list,
          dotPhrasesArr: props.customerNotes.data.dot_phrases_list,
        };
      }
    }

    if (
      props.customerNote !== undefined &&
      props.customerNote.status === 200 &&
      props.customerNote.data &&
      props.customerNote.data !== state.customerNote.data &&
      state.noteTobeEdited > 0
    ) {
      let hashtags =
        props.customerNote.data.hashtags &&
        props.customerNote.data.hashtags !== undefined &&
        props.customerNote.data.hashtags !== "" &&
        props.customerNote.data.hashtags !== null
          ? props.customerNote.data.hashtags.split(",")
          : "";

      let note =
        props.customerNote.data.notes &&
        props.customerNote.data.notes !== "" &&
        props.customerNote.data.notes !== null
          ? props.customerNote.data.notes
          : "";

      let title =
        props.customerNote.data.title &&
        props.customerNote.data.title !== "" &&
        props.customerNote.data.title !== null
          ? props.customerNote.data.title
          : "";

      if (localStorage.getItem("showLoader") == "false") {
        return {
          customerNote: props.customerNote.data,
          showLoader: false,
          tags: state.dataChanged ? state.tags : hashtags,
          noteText: state.dataChanged ? state.noteText : note,
          noteTitle: state.dataChanged ? state.noteTitle : title,
        };
      }
    } else if (
      props.customerNote !== undefined &&
      props.customerNote.status !== 200 &&
      props.customerNote.data &&
      props.procedureNote.data !== state.customerNote.data &&
      state.noteTobeEdited > 0
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        return {
          customerNote: props.procedureNote.data,
          showLoader: false,
          noteTobeEdited: 0,
        };
      }
    }

    if (
      props.deleteNoteData !== undefined &&
      props.deleteNoteData.status === 200 &&
      props.deleteNoteData.data !== state.deleteNoteData &&
      state.noteTobeDeleted > 0
    ) {
      return {
        customerNotes: state.customerNotes.filter(
          (noteDetails) => noteDetails.id !== state.noteTobeDeleted,
        ),
        deleteNoteData: props.deleteNoteData.data,
        showLoader: false,
        noteTobeDeleted: 0,
        editNoteID: 0,
      };
    } else if (
      props.deleteNoteData !== undefined &&
      props.deleteNoteData.status !== 200 &&
      props.deleteNoteData.data !== state.deleteNoteData
    ) {
      return {
        deleteNoteData: props.deleteNoteData.data,
        showLoader: false,
      };
    }

    return null;
  }

  getLoggedInUserData = () => {
    let userData = JSON.parse(getUser());

    if (userData) {
      return userData.user.id;
    }

    return 0;
  };

  showDeleteModal = (obj) => {
    this.state.noteTobeDeleted = obj.id;
    this.setState({
      showModal: true,
      showSearchResult: false,
      dataChanged: false,
      noteTobeDeleted: obj.id,
    });
  };

  showTitleModal = () => {
    if (!this.state.noteText) {
      this.setState({
        noteClass: "setting-textarea-box setting-input-box-invalid",
      });
    } else {
      this.setState({ showTitleModal: true });
    }
  };

  editThisNote = (e) => {
    e.preventDefault();
    this.state.noteTobeEdited = e.target.name;
    this.showLoaderFunc();

    if (this.state.noteTobeEdited > 0) {
      this.setState({ showSearchResult: false, dataChanged: false });
      this.props.getAClientNote(this.state.noteTobeEdited).catch((res) => {
        toast.error(
          this.state.globalLang[res.message] || "Unable To Retrieve Note",
        );
        this.setState({ showLoader: false });
      });
    }
  };

  dismissDeleteModal = () => {
    this.state.noteTobeDeleted = 0;
    this.setState({ showModal: false, noteTobeDeleted: 0 });
  };

  dismissTitleModal = () => {
    this.setState({ showTitleModal: false });
  };

  deleteNote = () => {
    let noteID = this.state.noteTobeDeleted;
    let patientID = this.state.patientID;

    if (noteID > 0 && patientID > 0) {
      this.showLoaderFunc();
      this.setState({
        tags: [],
        dataChanged: false,
        customerNote: [],
        noteText: "",
        noteClass: "setting-textarea-box",
        showLoader: true,
        showModal: false,
        noteTobeDeleted: noteID,
      });

      this.props
        .deleteClientNote(noteID, patientID)
        .then((res) => {
          toast.success(
            this.state.globalLang[res.message] ||
              "Note Has Been Deleted Successfully",
          );
        })
        .catch((res) => {
          toast.error(
            this.state.globalLang[res.message] || "Unable To Delete Note",
          );
        })
        .finally(() => {
          this.setState({ showLoader: false });
          this.fetchNotesList();
        });

      let notesRef = this.refs.notes;

      this.state.customerNote = [];
      this.state.tags = [];
      this.state.noteText = "";
      notesRef.value = "";
    } else {
      toast.error("Can not delete this note at this time");
    }
  };

  handleSubmit = (event, is_sticky = 0) => {
    event.preventDefault();
    let noteID = this.state.noteTobeEdited;

    const patientNoteText = this.state.noteText.trim();

    if (!patientNoteText) {
      this.setState({
        noteClass: "setting-textarea-box setting-input-box-invalid",
      });
    }
    if (patientNoteText && !this.state.noteTitle.trim() && is_sticky) {
      this.setState({
        noteTitleClass: "setting-textarea-box setting-input-box-invalid",
      });
    } else if (patientNoteText) {
      this.showLoaderFunc();
      this.setState({
        noteClass: "setting-textarea-box",
        noteTitleClass: "",
      });

      const isEditingNoteSticky =
        (!Array.isArray(this.state.customerNote) && this.state.customerNote.id
          ? this.state.customerNote.is_sticky
          : is_sticky) || is_sticky;

      let formData = {
        patient_id: this.state.patientID,
        notes: patientNoteText,
        is_sticky: isEditingNoteSticky,
        sortby: "created",
      };

      if (is_sticky) {
        formData["title"] = this.state.noteTitle;
      }

      let notesRef = this.refs.notes;
      let titleRef = this.refs.noteTitle;

      if (this.state.tags && this.state.tags.length > 0) {
        formData.tags = this.state.tags;
      }

      this.props
        .saveClientNote({ formData, noteID })
        .then((res) => {
          toast.success(
            this.state.globalLang[res.message] || "Note Saved Successfully",
          );

          notesRef.value = "";
          if (is_sticky) {
            titleRef.value = "";
          }

          this.setState({
            tags: [],
            showTitleModal: false,
            dataChanged: false,
            customerNote: [],
            noteText: "",
            noteTobeEdited: 0,
            noteTitle: "",
          });
        })
        .catch((res) => {
          toast.error(
            this.state.globalLang[res.message] ||
              res.message ||
              "Unable To Save Note",
          );
        })
        .finally(() => this.setState({ showLoader: false }));
    }
  };

  handleChange = (value) => {
    this.setState({ tags: value, dataChanged: true });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    let filteredArray = [];

    let strValue = value.substring(0, event.target.selectionStart);
    let strArray = strValue.split(".");
    let strToCheck = "";
    strToCheck = "." + strArray[strArray.length - 1];

    if (
      event.target.name === "noteText" &&
      strToCheck.length > 0 &&
      strValue.lastIndexOf(".") !== -1 &&
      strToCheck.lastIndexOf(".") !== -1 &&
      strToCheck.indexOf(" ", strToCheck.lastIndexOf(".") + 1) === -1
    ) {
      if (this.state.dotPhrases && this.state.dotPhrases.length > 0) {
        this.state.dotPhrases.map((obj) => {
          let name = obj.name.toLowerCase();
          let needle = strToCheck.toLowerCase();
          needle = needle.substr(1);

          if (name.includes(needle) || name === needle) {
            let filterObj = {};

            filterObj.id = obj.id;
            filterObj.name = obj.name;
            filterObj.title = obj.title;
            filterObj.phrase = obj.phrase;

            filteredArray.push(filterObj);
          }
        });
      }

      this.setState(
        {
          [event.target.name]: value,
          dataChanged: true,
          showSearchResult: true,
          caretPos: event.target.selectionStart,
          dotPhrasesArr: filteredArray,
        },
        this.scrollToTop,
      );
    } else {
      this.setState({
        [event.target.name]: value,
        dataChanged: true,
        showSearchResult: false,
        caretPos: -1,
      });
    }
  };

  scrollToTop() {
    animateScroll.scrollToTop({
      containerId: "dotPhraseList",
      duration: 10,
      delay: 10,
    });
  }

  cancelEditing = (event) => {
    event.preventDefault();

    let notesRef = this.refs.notes;
    this.state.customerNote = [];
    this.state.tags = [];
    this.state.noteText = "";
    notesRef.value = "";

    this.setState({
      tags: [],
      dataChanged: false,
      customerNote: [],
      noteText: "",
      noteTitle: "",
      noteTobeEdited: 0,
      noteClass: "setting-textarea-box",
    });
  };

  selectPhrase = (obj) => {
    let noteText = this.state.noteText;
    let phrase = obj.phrase;
    let position = this.state.caretPos;

    let strValue = noteText.substring(0, position);
    let strArray = strValue.split(".");
    let deduction = strArray[strArray.length - 1];
    deduction = deduction.length + 1;
    let output = [
      noteText.slice(0, position - deduction),
      phrase,
      noteText.slice(position),
    ].join("");

    this.setState({ noteText: output, showSearchResult: false, caretPos: -1 });

    document.getElementById("notes").focus();
  };

  setNotesList = (updatedDetails) => this.setState(updatedDetails);

  openStickyTitleModal = (event) => {
    const patientNoteText = this.state.noteText.trim();

    if (!patientNoteText) {
      this.setState({
        noteClass: "setting-textarea-box setting-input-box-invalid",
      });
    } else {
      this.setState({
        noteClass: "setting-textarea-box",
      });
      event.preventDefault();
      this.showTitleModal();
    }
  };

  render() {
    let returnUrl = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      returnUrl = this.props.match.params.type
        ? "/" +
          this.state.backURLType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.patientID +
          "/1"
        : "/" + this.state.backURLType;
    }

    let noteTimelineClass =
      this.state.customerNotes &&
      this.state.customerNotes.length > 0 &&
      checkIfPermissionAllowed("view-customer-notes")
        ? "note-timeline can-see displayBlock"
        : "note-timeline no-display";

    let noRecordClass =
      this.state.customerNotes && this.state.customerNotes.length > 0
        ? "no-record no-display"
        : "no-record";

    let firstname =
      this.state.patientData && this.state.patientData.firstname
        ? this.state.patientData.firstname
        : "";
    let lastname =
      this.state.patientData && this.state.patientData.lastname
        ? this.state.patientData.lastname
        : "";

    let patientName = firstname + " " + lastname;

    return (
      <div id="content">
        <div className="wide-popup">
          <div className="modal-blue-header">
            <Link to={returnUrl} className="popup-cross">
              ×
            </Link>
            {this.state.showLoader === false && (
              <span className="popup-blue-name">
                {patientName +
                  ` - ${this.state.languageData.cus_head_cus_notes_text}`}
              </span>
            )}
          </div>
          <div className="wide-popup-wrapper time-line displayBlock client-notes-container">
            {checkIfPermissionAllowed("add-update-customer-notes") && (
              <div>
                <div className="juvly-subtitle">
                  {this.state.languageData.pro_add_note_text}
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <form
                      id="add-notes"
                      name="ar-add-notes-form"
                      className="nobottommargin"
                      action="#"
                      method="post"
                    >
                      <div className="row">
                        <div className="col-xs-12">
                          <div className="setting-field-outer relative">
                            <div className="new-field-label">
                              {this.state.languageData.pro_note_text}{" "}
                              <span className="setting-require">*</span>
                            </div>
                            <textarea
                              placeholder="Type your notes and hashtags here"
                              ref="notes"
                              id="notes"
                              style={{ overFlow: "" }}
                              className={this.state.noteClass}
                              name="noteText"
                              onChange={this.handleInputChange}
                              value={this.state.noteText}
                            ></textarea>
                            <ul
                              id="dotPhraseList"
                              className={
                                this.state.dotPhrasesArr &&
                                this.state.dotPhrasesArr.length > 0 &&
                                this.state.showSearchResult
                                  ? " search-dropdown dot-phrase-list"
                                  : "cal-dropdown clinicname-dropdown no-display"
                              }
                            >
                              {this.state.dotPhrasesArr &&
                                this.state.dotPhrasesArr.length > 0 &&
                                this.state.dotPhrasesArr.map((obj, idx) => {
                                  return (
                                    <li
                                      key={"phrase_" + idx}
                                      onClick={() => this.selectPhrase(obj)}
                                    >
                                      <a>
                                        <span>
                                          {obj && obj.name && obj.name}
                                        </span>
                                        <p>{obj && obj.title && obj.title}</p>
                                      </a>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <input
                            onClick={this.handleSubmit}
                            className="new-blue-btn pull-left no-margin"
                            value={this.state.languageData.pro_save_btn_text}
                            type="submit"
                            id="save-profile"
                          />

                          {!(
                            this.state.noteTobeEdited &&
                            this.state.customerNote.is_sticky
                          ) && (
                            <input
                              onClick={this.openStickyTitleModal}
                              className="save-sticky-note-button"
                              value="Save as Sticky Note"
                              type="button"
                              id="save-sticky-note"
                            />
                          )}

                          {this.state.noteTobeEdited &&
                          this.state.noteTobeEdited > 0 ? (
                            <input
                              onClick={this.cancelEditing}
                              className="new-red-btn  no-margin note-cancel-button"
                              value="Cancel"
                              type="button"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
            {this.state.showModal && (
              <ConfirmModal
                isOpen
                onConfirm={this.deleteNote}
                onCancel={this.dismissDeleteModal}
                onClose={this.dismissDeleteModal}
                className="confirm-modal-center"
              >
                {this.state.languageData.pro_are_you_sure_text}
              </ConfirmModal>
            )}
            {this.state.showTitleModal && (
              <ConfirmModal
                title="Add Sticky Note Title"
                isOpen
                onConfirm={(event) => this.handleSubmit(event, 1)}
                onClose={this.dismissTitleModal}
                className="confirm-modal-center sticky-note-add-title-modal"
                footerClassName={"sticky-note-add-title-modal-footer"}
                confirmTitle="Save Sticky Note"
              >
                <textarea
                  placeholder="Type Notes Title here..."
                  ref="noteTitle"
                  id="noteTitle"
                  style={{ overFlow: "" }}
                  className={`setting-textarea-box ${this.state.noteTitleClass} `}
                  name="noteTitle"
                  onChange={this.handleInputChange}
                  value={this.state.noteTitle}
                ></textarea>
                {this.state.showLoader === true && <Loader showLoader />}
              </ConfirmModal>
            )}

            {this.state.showLoader === false && (
              <div className={noRecordClass}>
                {this.state.languageData.pro_no_notes_text}
              </div>
            )}
            {this.state.showLoader === true && (
              <div className="no-record">
                {this.state.languageData.pro_loading_dot}
              </div>
            )}
            <div className={noteTimelineClass}>
              {checkIfPermissionAllowed("view-customer-notes") &&
                this.state.customerNotes !== undefined &&
                this.state.customerNotes.map((noteobj, noteidx) => {
                  return (
                    <CustomerNote
                      noteobj={noteobj}
                      languageData={this.state.languageData}
                      globalLang={this.state.globalLang}
                      noteidx={noteidx}
                      noteToShow={noteobj.notes}
                      showDeleteModal={this.showDeleteModal}
                      getLoggedInUserData={this.getLoggedInUserData}
                      editThisNote={this.editThisNote.bind(this)}
                      key={noteidx.id}
                      fetchNotesList={this.fetchNotesList}
                      updateNotesList={this.setNotesList}
                    />
                  );
                })}
            </div>
          </div>
          <div
            className={
              this.state.showLoader
                ? "new-loader text-left displayBlock"
                : "new-loader text-left"
            }
          >
            <div className="loader-outer">
              <img
                alt=""
                id="loader-outer"
                src="/images/Eclipse.gif"
                className="loader-img"
              />
              <div id="modal-confirm-text" className="popup-subtitle">
                {this.state.globalLang.Please_Wait}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  localStorage.setItem("showLoader", false);

  if (state.ClientNotesReducer.action === "GET_CLIENT_NOTES") {
    return {
      customerNotes: state.ClientNotesReducer.data,
    };
  }

  if (
    state.ClientNotesReducer.action === "SAVE_CLIENT_NOTES" &&
    state.ClientNotesReducer.data.status === 201
  ) {
    return {
      customerNotes: state.ClientNotesReducer.data,
    };
  }

  if (state.ClientNotesReducer.action === "DELETE_CLIENT_NOTE") {
    return {
      deleteNoteData: state.ClientNotesReducer.data,
    };
  }

  if (
    state.ClientNotesReducer.action === "UPDATE_CLIENT_NOTE" &&
    state.ClientNotesReducer.data.status === 201
  ) {
    return {
      customerNotes: state.ClientNotesReducer.data,
    };
  }

  if (state.ClientNotesReducer.action === "GET_CLIENT_NOTE") {
    return {
      customerNote: state.ClientNotesReducer.data,
    };
  }

  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      geClientNotes,
      saveClientNote,
      deleteClientNote,
      getAClientNote,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ClientNotes));
