import React from "react";
import * as yup from "yup";
import { Trans } from "react-i18next";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { Select } from "../../../../../../../shared/Select/Select";
import { CardReaderDesc } from "../../../../shared/CardReaderDesc/CardReaderDesc";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { composeAmountSchema } from "../../../../SalesCheckoutInvoice.schemas";
import { useCardReaders } from "../../../../hooks/useCardReaders";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import { usePaymentsChangeWatcher } from "../../../../hooks/usePaymentsChangeWatcher";
import { LoadingAlert } from "../../../../../../../boxes/LoadingAlert/LoadingAlert";

export function FormSwipeCard() {
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();
  const { transferPaymentToReader } = useInvoiceActions();

  const {
    cardReaders,
    isCardReadersLoading,
    defaultCardReader,
    setDefaultCardReader,
  } = useCardReaders();

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const { watchPaymentsChange } = usePaymentsChangeWatcher({
    onPaymentsUpdated: onClose,
  });

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    readerId: yup
      .number()
      .required(tSales("checkoutInvoice.formError.cardReaderRequired")),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: (values) => {
        transferPaymentToReader
          .initiate({
            amount: values.amount,
            cardReaderId: values.readerId,
          })
          .then(watchPaymentsChange.initiate);
      },
      initialValues: {
        amount: amountToPay || "",
        readerId: defaultCardReader?.id,
      },
    });

  const readerOptions = cardReaders.map((r) => ({
    label: `${r.serialNumber} (${r.clinic.name})`,
    value: r.id,
  }));

  const onSelectReader = (option) => {
    const cardReader = cardReaders.find((r) => r.id);
    changeFormValue("readerId", option.value);
    setDefaultCardReader({
      clinicId: cardReader?.clinic?.id,
      cardReaderId: option.value,
    });
  };

  return (
    <div>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.cardReader")}
          </InputLabel>
          <Select
            size="medium"
            isError={hasError("readerId")}
            isLoading={isCardReadersLoading}
            value={readerOptions.find((o) => o.value === form.readerId)}
            onChange={onSelectReader}
            options={readerOptions}
          />
          {hasError("readerId") && <InputError>{errors.readerId}</InputError>}
        </ModalCharge.InputWrap>
        {form.readerId && (
          <ModalCharge.InputWrap>
            <CardReaderDesc
              serialNumber={
                cardReaders?.find((r) => r.id === form.readerId)?.serialNumber
              }
              clinicName={
                cardReaders.find((r) => r.id === form.readerId)?.clinic?.name
              }
            />
          </ModalCharge.InputWrap>
        )}
        {watchPaymentsChange.isLoading && (
          <LoadingAlert>
            <Trans
              i18nKey={tSales("checkoutInvoice.warn.awaitingForReaderPayment")}
              components={{
                b: <b />,
              }}
            />
          </LoadingAlert>
        )}
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={onClose}
        isCharging={transferPaymentToReader.isLoading}
        onCharge={submit}
        isCancelDisabled={watchPaymentsChange.isLoading}
        isChargeDisabled={
          !isValid ||
          transferPaymentToReader.isLoading ||
          watchPaymentsChange.isLoading
        }
      />
    </div>
  );
}
