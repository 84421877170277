import axios from "axios";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";
import {
  getLocalStorageItem,
  localStorageKeys,
  getPaginationParams,
} from "./useLocalStorage";

const AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const authTokens = getLocalStorageItem(localStorageKeys.access_token);

AxiosInstance.interceptors.request.use(async (config) => {
  if (authTokens) {
    config.headers = {
      ...config.headers,
      "access-token": authTokens,
    };
  }

  if (
    config &&
    (Boolean(config.data && "handlePagination" in config.data) ||
      Boolean(config.params && "handlePagination" in config.params))
  ) {
    config.params = { ...getPaginationParams(config.params) };
  }

  return config;
});

AxiosInstance.interceptors.response.use(
  (response) => {
    accountStatusResponseInterceptor(response);

    return Promise.resolve(response.data);
  },
  async (error) => {
    return Promise.reject(error.response.data);
  },
);

export default AxiosInstance;
