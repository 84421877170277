import React from "react";
import { Header } from "./shared/Header/Header";
import { Table } from "./shared/Table/Table";
import { useReport } from "./hooks/useReport";
import { useDownload } from "./hooks/useDownload";
import { ReportStats } from "../../../../../../boxes/ReportStats/ReportStats";
import styles from "./styles.module.scss";

export function SmsUsage() {
  const { reports, period, stats } = useReport();

  const download = useDownload({
    period,
  });

  return (
    <div className={styles.root}>
      <Header
        period={period}
        download={download}
        isLoading={reports.isLoading}
      />
      <ReportStats isLoading={reports.isLoading} data={stats} />
      <Table data={reports} />
    </div>
  );
}
