import { useState } from "react";
import { useQualiphyCreateExamInviteMutation } from "../../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError, extractApiErrorData } from "../../../utilities/api";
import { SERVER_MESSAGE_KEYS } from "../../../consts/api";
import { fileUtil } from "../../../utilities/file";
import { passOr } from "../../../utilities/general";
import { phoneUtil } from "../../../utilities/phone";

export const getAlreadyRequestedFormExams = (
  formExams = [],
  alreadyRequested = [],
) => {
  return alreadyRequested.filter((requestedExam) =>
    Boolean(formExams.find((exam) => requestedExam.id === exam.value)),
  );
};

export function useCreateInvite({ onSuccess, patientId, appointmentId }) {
  const { tCommon } = useAppTranslation.Common();
  const [additionPatientFields, setAdditionPatientFields] = useState([]);
  const [alreadyRequestedExams, setAlreadyRequestedExams] = useState([]);
  const { mutateAsync, isLoading } = useQualiphyCreateExamInviteMutation();

  const initiate = async (values) => {
    try {
      const isOverwriteExams =
        getAlreadyRequestedFormExams(values.exams, alreadyRequestedExams)
          .length > 0;

      const attachments = await Promise.all(
        values.attachments.map((attachment) =>
          fileUtil.read(attachment.file, { method: "dataUrl" }),
        ),
      );

      setAlreadyRequestedExams([]);
      await mutateAsync({
        clinicId: Number(values.clinicId),
        exams: values.exams.map((exam) => ({
          id: exam.value,
          name: exam.label,
        })),
        patient: {
          id: Number(patientId),
          firstName: values?.firstName || null,
          lastName: values?.lastName || null,
          email: values?.email || null,
          phone: passOr(values?.phone, null, () =>
            phoneUtil.compose(values?.phone),
          ),
          dateOfBirth: values?.dateOfBirth || null,
        },
        appointmentId,
        requestNew: isOverwriteExams,
        attachments,
      });

      uiNotification.success(
        tCommon("createQualifyProcedure.success.createInvite"),
      );
      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      const errorKey = extractApiError(e);
      if (errorKey === SERVER_MESSAGE_KEYS.qualiphy.needMorePatientData) {
        const data = extractApiErrorData(e);

        const additionFields = data?.empty_fields || [];
        const requestedExams = data?.existing_exams || [];

        setAdditionPatientFields(additionFields);
        setAlreadyRequestedExams(
          requestedExams
            .map((exam) => ({
              id: exam?.id,
              name: exam?.exam_name,
              date: exam?.exam_date,
            }))
            .filter((exam) => Boolean(exam.id)),
        );

        if (additionFields.length > 0) {
          uiNotification.warn(
            tCommon("createQualifyProcedure.apiError.need_more_data"),
          );
        }
      } else if (errorKey === SERVER_MESSAGE_KEYS.qualiphy.error) {
        const errorMessage = extractApiErrorData(e);

        uiNotification.error(
          errorMessage || tCommon("createQualifyProcedure.error.createInvite"),
        );
      } else {
        uiNotification.error(
          tCommon([
            `apiError.${errorKey}`,
            `createQualifyProcedure.apiError.${errorKey}`,
            "createQualifyProcedure.error.createInvite",
          ]),
        );
      }
    }
  };

  return {
    initiate,
    isLoading,
    additionPatientFields,
    alreadyRequestedExams,
  };
}
