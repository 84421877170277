import React, { useCallback } from "react";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { EntityHeader } from "../../../../../widgets/EntityHeader/EntityHeader";
import { useBulkUpdateShopifyProductListQuery } from "../../../../../api/queries/useBulkUpdateShopifyProductListQuery";
import styles from "./styles.module.scss";

/**
 * @param {object} param0
 * @param {{
 *    value: string;
 *    onChange: (value: string) => void;
 *    isLoading: boolean;
 * }} param0.search
 */
export function Header({ search, editedRows, filterMapped }) {
  const { tInventory } = useAppTranslation.Inventory();
  const { mutate: bulkUpdateList } = useBulkUpdateShopifyProductListQuery();

  const handleSave = useCallback(() => {
    bulkUpdateList({
      products: Array.from(editedRows.entries()).map(([id, product]) => ({
        product_id: id,
        shopify_product_id: product.value,
      })),
    });
  }, [editedRows]);

  return (
    <EntityHeader
      title={tInventory("shopify_product.label")}
      searchValue={search.value}
      onChangeSearch={search.onChange}
      isSearching={search.isLoading}
      right={
        <div className={styles.right}>
          <Button size="small" variant="text" onClick={filterMapped.toggle}>
            {filterMapped.value
              ? tInventory("shopify_product.filter_all")
              : tInventory("shopify_product.filter_mapped")}
          </Button>
          <Button size="small" onClick={handleSave}>
            {tInventory("shopify_product.save")}
          </Button>
        </div>
      }
    />
  );
}
