import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const languageData = JSON.parse(localStorage.getItem("languageData"));

const salesInstance = axios.create();

salesInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

salesInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

salesInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function exportSalesSummary(formData, reportType, newReq) {
  return async (dispatch) => {
    const apiUrl = process.env.REACT_APP_API_URL;

    const url = !newReq
      ? `${apiUrl}sales/export`
      : !reportType
      ? `${apiUrl}sales/export-transactions`
      : `${apiUrl}sales/export-invoices`;

    const request = !newReq
      ? salesInstance.post(url, formData || "")
      : salesInstance.get(url, formData ? { params: formData } : "");

    return request
      .then((response) => {
        dispatch({ type: "EXPORT_FILE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        const errorMsg = error.response?.data;
        dispatch({ type: "EXPORT_FILE", payload: errorMsg });
        return Promise.reject(errorMsg);
      });
  };
}

export function fetchInvoices(formData) {
  return async (dispatch) => {
    return salesInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/invoices",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "INVOICES_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "INVOICES_LIST", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function createEditInvoiceText(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/invoice_text",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_EDIT_INVOICES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CREATE_EDIT_INVOICES",
          payload: error.response.data,
        });
      });
  };
}

export function fetchInvoiceText(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "sales/invoice_text",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "FETCH_INVOICES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FETCH_INVOICES", payload: error.response.data });
      });
  };
}

export function getCashDrawer() {
  return (dispatch) => {
    salesInstance
      .post(process.env.REACT_APP_API_URL + "cash-drawer")
      .then((response) => {
        dispatch({ type: "GET_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CASH_DRAWER", payload: error.response.data });
      });
  };
}

export function changeDrawerLocation(clinicID) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "change-clinic-location/" + clinicID,
      )
      .then((response) => {
        dispatch({ type: "CHANGE_DRAWER_LOCATION", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_DRAWER_LOCATION",
          payload: error.response.data,
        });
      });
  };
}

export function viewOpenedDrawer(clinicID) {
  return (dispatch) => {
    salesInstance
      .get(process.env.REACT_APP_API_URL + "show-opened-drawer/" + clinicID)
      .then((response) => {
        dispatch({ type: "VIEW_OPENED_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_OPENED_DRAWER", payload: error.response.data });
      });
  };
}

export function updateCashDrawer(clinicID) {
  return (dispatch) => {
    salesInstance
      .post(process.env.REACT_APP_API_URL + "update-cash-drawer/" + clinicID)
      .then((response) => {
        dispatch({ type: "TAKE_DRAWER_CONTROL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "TAKE_DRAWER_CONTROL", payload: error.response.data });
      });
  };
}

export function getOpenCashDrawer(clinicID) {
  return (dispatch) => {
    salesInstance
      .get(process.env.REACT_APP_API_URL + "open-cash-drawer/" + clinicID)
      .then((response) => {
        dispatch({ type: "GET_OPEN_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_OPEN_CASH_DRAWER",
          payload: error.response.data,
        });
      });
  };
}

export function openCashDrawer(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "open-cash-drawer",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "POST_OPEN_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "POST_OPEN_CASH_DRAWER",
          payload: error.response.data,
        });
      });
  };
}

export function closeCashDrawer(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "close-cash-drawer",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CLOSE_CASH_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLOSE_CASH_DRAWER", payload: error.response.data });
      });
  };
}

export function cashInDrawer(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "cash-in-drawer",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CASH_IN_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CASH_IN_DRAWER", payload: error.response.data });
      });
  };
}

export function cashOutDrawer(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "cash-out-drawer",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CASH_OUT_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CASH_OUT_DRAWER", payload: error.response.data });
      });
  };
}

export function bankDropDrawer(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "bank-drop",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "BANK_DROP_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "BANK_DROP_DRAWER", payload: error.response.data });
      });
  };
}

export function cashRegisterLogs(formData) {
  return (dispatch) => {
    salesInstance
      .get(
        process.env.REACT_APP_API_URL + "cash-register-logs",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CASH_REGISTER_LOGS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CASH_REGISTER_LOGS", payload: error.response.data });
      });
  };
}

export function cashDrawerHistory(formData) {
  return (dispatch) => {
    salesInstance
      .get(
        process.env.REACT_APP_API_URL + "drawer-filter",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "GET_CASH_DRAWER_HISTORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CASH_DRAWER_HISTORY",
          payload: error.response.data,
        });
      });
  };
}

export function editClosedDrawer(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "drawer-close-edit",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "EDIT_CLOSED_DRAWER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EDIT_CLOSED_DRAWER", payload: error.response.data });
      });
  };
}

export function addEditPopupReceipt(formData) {
  return (dispatch) => {
    salesInstance
      .post(
        process.env.REACT_APP_API_URL + "add-receipt",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "ADD_EDIT_POPUP_RECEIPT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_EDIT_POPUP_RECEIPT",
          payload: error.response.data,
        });
      });
  };
}

export function exportCashRegisterLogs(formData) {
  return (dispatch) => {
    salesInstance
      .get(
        process.env.REACT_APP_API_URL + "export-cash-register-logs",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "EXPORT_CASH_DRAWER_LOG", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EXPORT_CASH_DRAWER_LOG",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export const fetchFrontdeskInvoices = (formData) => {
  return async (dispatch) => {
    return salesInstance
      .post(process.env.REACT_APP_API_URL + "get-frontdesk-invoices", formData)
      .then((response) => {
        dispatch({ type: "FRONTDESK_INVOICES_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "FRONTDESK_INVOICES_LIST",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
};

export const fetchInvoiceDetail = (id) => {
  return (dispatch) => {
    dispatch({ type: "FRONTDESK_INVOICE_DATA_LOADING" });
    salesInstance
      .get(process.env.REACT_APP_API_URL + "get-invoice-detail/" + id)
      .then((response) => {
        dispatch({ type: "FRONTDESK_INVOICE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "FRONTDESK_INVOICE_DATA",
          payload: error.response ? error.response.data : "",
        });
        toast.error(languageData.global[error.response.data.message]);
      });
  };
};

export function getCardreaders(accountId) {
  return (dispatch) => {
    salesInstance
      .get(`${process.env.REACT_APP_API_URL}payment/card_readers/${accountId}`)
      .then((response) => {
        dispatch({
          type: "GET_CARDREADERS_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({ type: "GET_CARDREADERS_ERROR", payload: e.response.error });
      });
  };
}

export const fetchAllClinics = () => {
  return (dispatch) => {
    salesInstance
      .get(process.env.REACT_APP_API_URL + "clinics")
      .then((response) => {
        dispatch({ type: "ALL_CLINIC_LIST", payload: response.data.data });
      })
      .catch((e) => {
        dispatch({ type: "ALL_CLINIC_LIST", payload: e.response.data });
      });
  };
};

export const chargePaymentStripeCardReader = (formdata) => {
  return (dispatch) => {
    dispatch({ type: "CHARGE_STRIPE_CARDREADER_LOADING" });
    salesInstance
      .post(`${process.env.REACT_APP_API_URL}payment/checkout`, formdata)
      .then((response) => {
        dispatch({
          type: "CHARGE_STRIPE_CARDREADER_SUCCESS",
          payload: response.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "CHARGE_STRIPE_CARDREADER_ERROR",
          payload: e.response.data,
        });
      });
  };
};

export const mergeAndCheckoutInvoice = (formData) => {
  return (dispatch) => {
    dispatch({ type: "MERGE_AND_CHECKOUT_INVOICE_LOADING" });
    salesInstance
      .post(`${process.env.REACT_APP_API_URL}pos-invoice-merge/merge`, formData)
      .then((response) => {
        dispatch({
          type: "MERGE_AND_CHECKOUT_INVOICE_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: "MERGE_AND_CHECKOUT_INVOICE_ERROR",
          payload: e.response.data,
        });
      });
  };
};
