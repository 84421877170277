import React from "react";
import PropTypes from "prop-types";

export function Header({ onAddWidget }) {
  return (
    <div className="setting-setion m-b-10">
      <div className="membership-title">
        <span className="cursor-pointer">Dashboard</span>
        <div className="memberRightActions">
          {onAddWidget && (
            <button onClick={onAddWidget} className="new-blue-btn pull-right">
              Add Widgets
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

Header.propTypes = {
  onAddWidget: PropTypes.func,
};

Header.defaultProps = {
  onAddWidget: undefined,
};
