import React from "react";
import { tCommon } from "../../../../i18n/useAppTranslation";

export default function DeclinePaymentEmailSmsSnippet({ title }) {
  return (
    <div className="setting-variable snippets m-b-30">
      <p>
        <b>{title}:</b> {`{{PATIENTNAME}}`}
      </p>
      <p>
        <b>{tCommon("label.membershipName")}:</b> {`{{MEMBERSHIPNAME}}`}{" "}
      </p>
      <p>
        <b>{tCommon("label.membershipFee")}:</b> {`{{MEMBERSHIPFEE}}`}{" "}
      </p>
      <p>
        <b>{tCommon("label.clinicName")}:</b> {`{{CLINICNAME}}`}{" "}
      </p>
      <p>
        <b>{tCommon("label.patientPortalLink")}:</b>{" "}
        {`{{CLIENTPATIENTURL}}`}{" "}
      </p>
    </div>
  );
}
