import React from "react";

export const ChevronLeftIcon = () => {
  return (
    <svg
      width="8"
      height="13"
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.74268 12.1859L1.76513 7.81095C1.17729 7.29428 1.17729 6.4488 1.76513 5.93212L6.74268 1.55713"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
