import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

positionFooterCorrectly();

const integrationInstance = axios.create();

integrationInstance.defaults.headers.common["access-token"] = getToken();

integrationInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

integrationInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getSyncData() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "get-touch-md")
      .then((response) => {
        dispatch({ type: "GET_SYNC_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SYNC_DATA", payload: error.response.data });
      });
  };
}

export function setSyncConfiguration(formData) {
  return (dispatch) => {
    integrationInstance
      .put(
        process.env.REACT_APP_API_URL + "save-touch-md",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SET_SYNC_CONFIG", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SET_SYNC_CONFIG", payload: error.response.data });
      });
  };
}

export function getEditSyncData() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "get-touch-md")
      .then((response) => {
        dispatch({ type: "GET_EDIT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_EDIT_DATA", payload: error.response.data });
      });
  };
}

export function getZohoData() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "zoho-auth")
      .then((response) => {
        dispatch({ type: "GET_ZOHO_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ZOHO_DATA", payload: error.response.data });
      });
  };
}

export function shareIntegrationData(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "zoho-save-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SHARE_ZOHO_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SHARE_ZOHO_DATA", payload: error.response.data });
      });
  };
}

export function getShopifyData() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "shopify/get-details")
      .then((response) => {
        dispatch({ type: "GET_SHOPIFY_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_SHOPIFY_DATA", payload: error.response.data });
      });
  };
}

export function shareIntegrationShopifyData(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "shopify/save-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SHARE_SHOPIFY_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SHARE_SHOPIFY_DATA", payload: error.response.data });
      });
  };
}

export function getHubspotData() {
  return async (dispatch) => {
    return integrationInstance
      .get(process.env.REACT_APP_API_URL + "hubspot-auth")
      .then((response) => {
        dispatch({ type: "GET_HUBSPOT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_HUBSPOT_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function shareIntegrationHubspotData(formData) {
  return async (dispatch) => {
    return integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "hubspot-save-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SHARE_HUBSPOT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SHARE_HUBSPOT_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function getMailchimpData() {
  return async (dispatch) => {
    return integrationInstance
      .get(process.env.REACT_APP_API_URL + "mailchimp-auth")
      .then((response) => {
        dispatch({ type: "GET_MAILCHIMP_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_MAILCHIMP_DATA", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function shareIntegrationMailchimpData(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "mailchimp-save-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SHARE_MAILCHIMP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SHARE_MAILCHIMP_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getActiveCampaignData() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "active-campaign-auth")
      .then((response) => {
        dispatch({ type: "GET_ACTIVE_COMPAIGN_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_ACTIVE_COMPAIGN_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function shareIntegrationActiveCampaignData(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "active-campaign-save-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SHARE_ACTIVE_COMPAIGN_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SHARE_ACTIVE_COMPAIGN_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getConstantContactData() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "constant-contact-auth")
      .then((response) => {
        dispatch({ type: "GET_CONSTANT_CONTACT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CONSTANT_CONTACT_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function shareIntegrationConstantContactData(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "constant-contact-save-details",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "SHARE_CONSTANT_CONTACT_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SHARE_CONSTANT_CONTACT_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function getZapierDetails() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "zapier/authorize_detail")
      .then((response) => {
        dispatch({ type: "GET_ZAPIER_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ZAPIER_DATA", payload: error.response.data });
      });
  };
}

export function switchONZapier(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "zapier/authorize",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SWITCH_ZAPIER_ON", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SWITCH_ZAPIER_ON", payload: error.response.data });
      });
  };
}

export function switchOFFZapier(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "zapier/authorize",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "SWITCH_ZAPIER_OFF", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SWITCH_ZAPIER_OFF", payload: error.response.data });
      });
  };
}

export function changeRefreshStatus(formData) {
  return (dispatch) => {
    integrationInstance
      .post(
        process.env.REACT_APP_API_URL + "zapier/authorize",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({
          type: "CHANGE_ZAPIER_REFRESH_CREDS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_ZAPIER_REFRESH_CREDS",
          payload: error.response.data,
        });
      });
  };
}

export function getCherryConfig() {
  return (dispatch) => {
    integrationInstance
      .get(process.env.REACT_APP_API_URL + "cherry-config")
      .then((response) => {
        dispatch({ type: "GET_CHERRY_CONFIG", payload: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        dispatch({ type: "GET_CHERRY_CONFIG", payload: error.response.data });
      });
  };
}

export function putCherryConfig(params) {
  return (dispatch) => {
    integrationInstance
      .put(process.env.REACT_APP_API_URL + "cherry-config", {
        cherryConfig: params,
      })
      .then((response) => {
        if (response.data.data.is_connected) {
          toast.success(`Cherry integration successful!`);
        } else {
          toast.error(`Couldn't connect to Cherry. Please check you API Key!`);
        }
        dispatch({ type: "PUT_CHERRY_CONFIG", payload: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        dispatch({ type: "PUT_CHERRY_CONFIG", payload: error.response.data });
      });
  };
}

export function patchCherryConfig(params) {
  return (dispatch) => {
    integrationInstance
      .patch(process.env.REACT_APP_API_URL + "cherry-config", {
        cherryConfig: params,
      })
      .then((response) => {
        if (!params.hasOwnProperty("clinics")) {
          toast.info(
            response.data.data.is_active
              ? "Cherry is enabled!"
              : "Cherry is disabled!",
          );
        } else {
          toast.success("Cherry clinics updated!");
        }
        dispatch({ type: "PATCH_CHERRY_CONFIG", payload: response.data });
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        dispatch({ type: "PATCH_CHERRY_CONFIG", payload: error.response.data });
      });
  };
}
