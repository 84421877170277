import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./InputLabel.module.scss";

export function InputLabel({ children, className, htmlFor }) {
  return (
    <label className={cx(classes.root, className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
}

InputLabel.propTypes = {
  children: PropTypes.string.isRequired,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
};

InputLabel.defaultProps = {
  className: undefined,
  htmlFor: undefined,
};
