/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import Header from "../../_legacy/Containers/Guest/Header.js";
import Footer from "../../_legacy/Containers/Guest/Footer.js";
import { signUp, getSignUpPlans } from "../../Actions/SignUp/signUpAction.js";
import {
  accountReset,
  upgradeTrailToPaid,
} from "../../Actions/Accounts/accountAction.js";
import {
  setRedirectTo,
  getRedirectTo,
  numberFormat,
  getToken,
} from "../../Utils/services.js";
import { switchWorkspaces } from "../../Actions/Settings/settingsActions.js";
import { getSetupIntentForAccount } from "../../Actions/Stripe/StripeActions";
import Loader from "../Common/Loader.js";
import StripePaymentForm from "../Sales/FrontdeskCheckout/StripePaymentForm";

class TrailToPaid extends React.Component {
  constructor(props) {
    super(props);

    let tempLoggedUserData = localStorage.getItem("tempLoggedUserData")
      ? JSON.parse(localStorage.getItem("tempLoggedUserData"))
      : {};

    this.state = {
      showModal: false,
      globalLang: {},
      isShowHippa: true,
      isShowBba: false,
      agree_checkbox: false,
      isHppaPolicyAccepted: false,
      isBbaPolicyAccepted: false,
      tempLoggedUserData: {},

      stripeToken: "",
      setupIntentID: "",
      processingLoder: false,
      isShowUpgradeModal: false,
      isShowPolicyModal: false,
      planAndCountryData: {},
      loginData: {},
      privilegeData: {},
      one_time_setup_fees: "0.00",

      upgrade_subscription_plan: "practice_essentials",
      upgrade_subscription_type: "monthly",
      subscription_plans: {},
      frequency_type: {},
      workspaces:
        tempLoggedUserData.userData.workspaces &&
        tempLoggedUserData.userData.workspaces.length > 1
          ? tempLoggedUserData.userData.workspaces
          : [],
      selected_workspace: "",
      no_reminder: false,
      defaultWorkspace:
        tempLoggedUserData.userData.workspaces &&
        tempLoggedUserData.userData.workspaces.length > 1
          ? tempLoggedUserData.userData.workspaces.find(
              (y) => y.is_default_web == 1,
            )
          : "",
      is_edit_card: false,
    };
  }

  componentDidMount() {
    this.props.getSignUpPlans();
    this.props.accountReset();
    this.props.getSetupIntentForAccount();

    let tempLoggedUserData = {};

    if (
      localStorage.getItem("tempLoggedUserData") &&
      getRedirectTo() == "/upgrade-subscription-plan"
    ) {
      tempLoggedUserData = JSON.parse(
        localStorage.getItem("tempLoggedUserData"),
      );
    } else {
      this.redirectToLogin();
    }

    this.setState({ tempLoggedUserData: tempLoggedUserData });

    let langData = {};

    if (localStorage.getItem("languageData")) {
      langData = JSON.parse(localStorage.getItem("languageData"));
    }

    if (!langData || langData.global === undefined || !langData.global) {
      axios
        .get(process.env.REACT_APP_API_URL + `getLanguageText/1/global`)
        .then((res) => {
          const languageData = res.data.data;
          localStorage.setItem("languageData", JSON.stringify(languageData));
          this.setState({ globalLang: languageData.global });
        })
        .catch(function () {});
    } else {
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      this.setState({ globalLang: languageData.global });
    }

    if (window.Stripe) {
      this.setState(
        { stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) },
        () => {
          this.generateStripeInput();
        },
      );
    } else {
      document.querySelector("#stripe-js").addEventListener(
        "load",
        () => {
          this.setState({
            stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY),
          });
        },
        () => {
          this.generateStripeInput();
        },
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      returnState.is_edit_card = false;
    } else if (
      nextProps.planAndCountryData != undefined &&
      nextProps.planAndCountryData != prevState.planAndCountryData
    ) {
      returnState.planAndCountryData = nextProps.planAndCountryData;
      returnState.subscription_plans = returnState.planAndCountryData
        .subscription_plans
        ? returnState.planAndCountryData.subscription_plans
        : {};
      returnState.frequency_type = returnState.planAndCountryData.frequency_type
        ? returnState.planAndCountryData.frequency_type
        : {};
      returnState.one_time_setup_fees = nextProps.planAndCountryData
        .one_time_setup_fees
        ? nextProps.planAndCountryData.one_time_setup_fees
        : "0.00";
      nextProps.signUp();
    } else if (
      nextProps.loginData != undefined &&
      Object.keys(nextProps.loginData).length > 0 &&
      nextProps.loginData !== prevState.loginData
    ) {
      toast.dismiss();
      toast.success(prevState.globalLang[nextProps.message]);
      returnState.loginData = nextProps.loginData;

      setRedirectTo(nextProps.loginData.redirect_to);

      if (
        nextProps.loginData.is_bba_signed != undefined &&
        nextProps.loginData.is_bba_signed == 1
      ) {
        localStorage.setItem(
          "currentUserRole",
          nextProps.loginData.user.user_role_id,
        );
        localStorage.setItem("userData", JSON.stringify(nextProps.loginData));
        localStorage.setItem("isLoggedIn", 1);
        localStorage.setItem(
          "user_listing_settings",
          JSON.stringify(nextProps.loginData.user_listing_settings),
        );
        localStorage.setItem(
          "languageData",
          JSON.stringify(nextProps.loginData.languageData),
        );
        localStorage.setItem(
          "globalPrivileges",
          JSON.stringify(nextProps.loginData.globalPrivileges),
        );
        localStorage.removeItem("tempLoggedUserData");
        nextProps.history.push(getRedirectTo());
      } else {
        setRedirectTo("accept-agreement");
        let tempLoggedUserData = prevState.tempLoggedUserData;
        tempLoggedUserData.userData = nextProps.loginData;
        localStorage.setItem(
          "tempLoggedUserData",
          JSON.stringify(tempLoggedUserData),
        );
        nextProps.accountReset();
        nextProps.history.push(getRedirectTo());
      }
    }

    if (
      nextProps.userDataTime != prevState.userDataTime &&
      nextProps.userData != undefined
    ) {
      returnState.userDataTime = nextProps.userDataTime;
      returnState.workspaces = nextProps.userData.workspaces;
      returnState.user_name =
        nextProps.userData && nextProps.userData.current_wc_full_name
          ? nextProps.userData.current_wc_full_name
          : "";
      returnState.showLoader = false;
      returnState.defaultWorkspace = nextProps.userData.workspaces.find(
        (y) => y.is_default_web == 1,
      );

      if (nextProps.status == 604) {
        nextProps.history.push("/upgrade-account-to-stripe");
      } else if (nextProps.status == 603) {
        nextProps.history.push("/upgrade-subscription-plan");
      } else if (nextProps.status == 602) {
        nextProps.history.push("/accept-agreement");
      } else if (nextProps.status == 605) {
        nextProps.history.push("/two-factor-login");
      } else if (nextProps.status == 200) {
        localStorage.setItem(
          "languageData",
          JSON.stringify(nextProps.userData.languageData),
        );
        localStorage.setItem(
          "globalPrivileges",
          JSON.stringify(nextProps.userData.globalPrivileges),
        );
        localStorage.setItem(
          "need_clinic_city_state",
          nextProps.userData.need_clinic_city_state,
        );
        returnState.need_clinic_city_state =
          nextProps.userData.need_clinic_city_state == true ? "true" : "false";

        if (nextProps.userData.need_clinic_city_state == true) {
          localStorage.setItem(
            "clinic_list",
            JSON.stringify(nextProps.userData.clinic_list),
          );
        }

        localStorage.setItem("cCode", nextProps.userData.country_code);
        localStorage.setItem("current_wc_full_name", returnState.user_name);
        localStorage.setItem("isClockPromptLoginDisplay", 1);
        localStorage.removeItem("isLoggedClockCancelled");
        localStorage.removeItem("clockPromptCancelTime");
        nextProps.history.push(nextProps.redirect_url);
      }
    }

    return returnState;
  }

  generateStripeInput = () => {};

  upgradeTrailToPaid = () => {
    this.setState({ setup_intent_id: "", processingLoder: true });
    toast.dismiss();

    if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
      this.setState({
        setup_intent_id: this.props.stripeIntent.setupIntentId,
        processingLoder: false,
      });
      this.handlePolicyModal();
    }
  };

  handleSubmit = () => {
    this.setState({ processingLoder: true });

    let formData = {
      setup_intent_id: this.props.stripeIntent.setupIntentId,
      subscription_type: this.state.upgrade_subscription_type,
      subscription_plan: this.state.upgrade_subscription_plan,
      return_type: "login",
    };

    this.props.upgradeTrailToPaid(formData);
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [event.target.name]: value,
    });

    if (event.target.name == "selected_workspace") {
      this.switchWorkspace(value);
    }
  };

  switchWorkspace = (value) => {
    this.setState({ showLoader: true });

    let check = this.state.workspaces.find((y) => y.is_default_web == 1);

    let formData = {
      workspace_id: value,
      old_workspace_id: check.id,
      remove_old_workspace: this.state.no_reminder ? 1 : 0,
    };

    this.props.switchWorkspaces(formData);
  };

  redirectToLogin = () => {
    this.props.history.push("/logout");
  };

  handleUpgradeModal = () => {
    this.setState({ isShowUpgradeModal: !this.state.isShowUpgradeModal });
  };

  handlePolicyModal = () => {
    this.setState({ isShowPolicyModal: !this.state.isShowPolicyModal });
  };

  renderSubcriptionPlan = () => {
    let htmlList = [];

    if (this.state.subscription_plans) {
      let subscription_plans =
        this.state.upgrade_subscription_type === "monthly"
          ? this.state.subscription_plans.monthly
          : this.state.subscription_plans.yearly;

      if (subscription_plans) {
        Object.keys(subscription_plans).forEach((idx) => {
          htmlList.push(
            <option key={"subscription_plans-" + idx} value={idx}>
              {subscription_plans[idx]}
            </option>,
          );
        });
      }
    }

    return htmlList;
  };

  render() {
    return (
      <div className="guest">
        <Header />
        <div className="wrapper">
          {/*  Card Details START  */}
          {this.state.workspaces && this.state.workspaces.length == 0 && (
            <div className="login-main  sign-up-main trail-to-paid-main">
              <form>
                <h1 className="login-title">
                  {this.state.globalLang.update_credit_card_details}
                </h1>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <label>
                      {this.state.globalLang.signup_label_subscription_plan}
                    </label>
                    <select
                      name="upgrade_subscription_plan"
                      value={this.state.upgrade_subscription_plan}
                      onChange={this.handleInputChange}
                      className="form-control"
                    >
                      {this.renderSubcriptionPlan()}
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <label>
                      {this.state.globalLang.signup_label_subscription_type}
                    </label>
                    <select
                      name="upgrade_subscription_type"
                      value={this.state.upgrade_subscription_type}
                      onChange={this.handleInputChange}
                      className="form-control"
                    >
                      <option value="monthly">
                        {this.state.frequency_type.monthly}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-sm-12">
                    <label>{this.state.globalLang.one_time_setup_fees}</label>
                    <input
                      name="one_time_setup_fees"
                      value={numberFormat(
                        this.state.one_time_setup_fees,
                        "currency",
                        2,
                      )}
                      onChange={this.handleInputChange}
                      className="form-control form-control-disabled"
                      disabled={"dissabled"}
                    />
                  </div>
                </div>

                {this.props.stripeIntent &&
                  this.props.stripeIntent.setupIntentId && (
                    <StripePaymentForm
                      type="setupIntent"
                      onSubmit={this.upgradeTrailToPaid}
                    />
                  )}
              </form>
            </div>
          )}

          {this.state.workspaces && this.state.workspaces.length > 0 && (
            <div className="login-main  recurly-to-stripe-main sign-up-main creditCardMain">
              <form>
                <p className="p-text text16px m-b-30 m-t-20">
                  <b>
                    Hello. we noticed you are part of / own more than one
                    Aesthetic Record workspace and one of them is expired.
                  </b>
                </p>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="creditCardSections">
                      <p className="p-text">
                        To reactivate your account{" "}
                        {this.state.defaultWorkspace.account.name}, please
                        update your payment and plan information.
                      </p>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label>
                            {
                              this.state.globalLang
                                .signup_label_subscription_plan
                            }
                          </label>
                          <select
                            name="upgrade_subscription_plan"
                            value={this.state.upgrade_subscription_plan}
                            onChange={this.handleInputChange}
                            className="form-control"
                          >
                            {this.renderSubcriptionPlan()}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label>
                            {
                              this.state.globalLang
                                .signup_label_subscription_type
                            }
                          </label>
                          <select
                            name="upgrade_subscription_type"
                            value={this.state.upgrade_subscription_type}
                            onChange={this.handleInputChange}
                            className="form-control"
                          >
                            <option value="monthly">
                              {this.state.frequency_type.monthly}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <label>
                            {this.state.globalLang.one_time_setup_fees}
                          </label>
                          <input
                            name="one_time_setup_fees"
                            value={numberFormat(
                              this.state.one_time_setup_fees,
                              "currency",
                              2,
                            )}
                            onChange={this.handleInputChange}
                            className="form-control form-control-disabled"
                            disabled={"dissabled"}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          {this.props.stripeIntent &&
                            this.props.stripeIntent.setupIntentId && (
                              <StripePaymentForm
                                type="setupIntent"
                                onSubmit={this.upgradeTrailToPaid}
                              />
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="creditCardSections switchTo">
                      <div className="form-group row">
                        <div className="col-sm-12 first-name">
                          <label>Join another workspace</label>
                          <select
                            className="form-control"
                            name="selected_workspace"
                            value={this.state.selected_workspace}
                            onChange={this.handleInputChange}
                          >
                            <option value="">----Select----</option>
                            {this.state.workspaces &&
                              this.state.workspaces.length > 0 &&
                              this.state.workspaces.map((data) => {
                                if (data.is_default_web == 0) {
                                  return (
                                    <option key={data.id} value={data.id}>
                                      {data.account.name}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row m-t-10">
                        <div className="col-sm-12 terms text-left">
                          <input
                            type="checkbox"
                            id="UserTos"
                            onChange={this.handleInputChange}
                            className="validate[custom[tosRequired]]"
                            name="no_reminder"
                            value={this.state.no_reminder ? "checked" : false}
                          />{" "}
                          Do not remind me again.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}

          {/*  Card Details START  */}
          {/* Upgrade Modal - START */}
          <div className={this.state.isShowUpgradeModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.isShowUpgradeModal
                ? "modal fade in displayBlock"
                : "modal fade no-display"
            }
          >
            <div className="modal-dialog trail-to-paid-modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.redirectToLogin}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.globalLang.upgrade_subscription}
                  </h4>
                </div>
                <div className="modal-body add-patient-form filter-patient">
                  {
                    this.state.globalLang
                      .upgrade_subscription_trial_expiration_msg
                  }
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.redirectToLogin}
                    >
                      {this.state.globalLang.label_cancel}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.handleUpgradeModal}
                    >
                      {this.state.globalLang.upgrade_account}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Upgrade Modal - END */}
          {/* Policy Modal - START */}
          <div className={this.state.isShowPolicyModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.isShowPolicyModal
                ? "modal fade in displayBlock"
                : "modal fade no-display"
            }
          >
            <div className="modal-dialog trail-to-paid-modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.redirectToLogin}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {
                      this.state.globalLang
                        .subscription_payments_are_non_refundable
                    }
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  YOU MAY CANCEL YOUR SUBSCRIPTION TO THE SERVICE AT LEAST 24
                  HOURS PRIOR TO THE NEXT DATE OF SUBSCRIPTION RENEWAL, AFTER
                  WHICH AESTHETIC RECORD LLC WILL NOT RENEW YOUR SUBSCRIPTION.{" "}
                  <b>
                    <u>SUBSCRIPTION PAYMENTS ARE NON-REFUNDABLE</u>
                  </b>
                  {`. ALL SUBSCRIPTIONS ARE FINAL, REGARDLESS OF A YEARLY
                  SUBSCRIPTION OR MONTHLY SUBSCRIPTION. YOU MAY CANCEL YOUR
                  SUBSCRIPTION THROUGH THE FUNCTIONALITY PROVIDED THROUGH THE
                  SETTINGS TAB ON THE WEB APPLICATION, UNDER THE "ACCOUNT
                  INFORMATION" TAB. It is your responsibility to manage any
                  subscription changes to your account.`}
                  <br />
                  <br />
                  Recurring Charges. YOU AUTHORIZE AESTHETIC RECORD LLC TO
                  CHARGE THE PAYMENT METHOD THAT OUR PAYMENT PROCESSOR HAS ON
                  FILE FOR YOU TO PAY FOR ANY RENEWAL SUBSCRIPTION. YOU WILL BE
                  BILLED FOR THE SAME SUBSCRIPTION PLAN (OR THE MOST SIMILAR
                  SUBSCRIPTION PLAN, IF YOUR PRIOR PLAN IS NO LONGER AVAILABLE)
                  AT THE THEN-CURRENT MONTHLY, OR ANNUAL SUBSCRIPTION PRICE PLUS
                  ANY APPLICABLE TAXES. ADDITIONAL TERMS AND CONDITIONS MAY
                  APPLY UPON RENEWAL, AND SUBSCRIPTION FEES MAY CHANGE AT ANY
                  TIME, TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW.
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left">
                    <button
                      disabled={this.state.processingLoder}
                      className="btn btn-success green"
                      onClick={this.handleSubmit}
                    >
                      {this.state.processingLoder
                        ? this.state.globalLang.signup_please_wait
                        : this.state.globalLang.signup_button_agree}
                      <img
                        alt=""
                        className={
                          this.state.processingLoder ? "" : "no-display"
                        }
                        src="/images/btn-load.gif"
                      />
                    </button>
                    <button
                      disabled={this.state.processingLoder}
                      className="btn btn-danger m-r-10"
                      data-dismiss="modal"
                      onClick={this.redirectToLogin}
                    >
                      {this.state.globalLang.signup_button_disagree}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Policy Modal - END */}
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  if (state.SignUpReducer.action === "SIGN_UP_PLANS") {
    toast.dismiss();
    if (state.SignUpReducer.data.status != 200) {
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      toast.error(languageData.global[state.SignUpReducer.data.message]);
      returnState.showLoader = true;
    } else {
      returnState.planAndCountryData = state.SignUpReducer.data.data;
    }
  } else if (state.AccountReducer.action === "UPGRADE_TRAIL_TO_PAID") {
    if (state.AccountReducer.data.status != 200) {
      toast.dismiss();
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      toast.error(languageData.global[state.AccountReducer.data.message]);
      returnState.processingLoder = true;
    } else {
      returnState.loginData = state.AccountReducer.data.data;
      returnState.message = state.AccountReducer.data.message;
    }
  } else if (state.SettingReducer.action == "SWITCH_WORKSPACE") {
    if (state.SettingReducer.data.status == 200) {
      toast.dismiss();
      toast.success(languageData.global[state.SettingReducer.data.message]);

      const userData = state.SettingReducer.data.data;

      localStorage.removeItem("recentlyViewedData");
      localStorage.removeItem("focusedRange");
      localStorage.removeItem("dateFormat");
      localStorage.removeItem("twilioSetup");
      localStorage.removeItem("currencySymbol");
      localStorage.removeItem("accountType");
      localStorage.removeItem("twilioFromNumber");
      localStorage.removeItem("accountStatus");
      localStorage.removeItem("subscriptionType");
      localStorage.removeItem("cCode");
      localStorage.removeItem("currencyLabel");
      localStorage.removeItem("stripeConnection");
      localStorage.removeItem("timeFormat");
      localStorage.removeItem("time_clock_setting");
      localStorage.removeItem("isMembershipEnabled");
      localStorage.removeItem("cTerm");
      localStorage.removeItem("current_wc_full_name");
      returnState.userDataTime = new Date();

      if (userData.render_view) {
        returnState.status = 605;
        returnState.twoFactorData = userData;
        localStorage.setItem("twoFactorData", JSON.stringify(userData));
      } else {
        returnState.status = 200;
        returnState.login_attempts = 1;
        returnState.loggingIn = true;
        localStorage.removeItem("showRecaptcha");
        localStorage.removeItem("login_attempts");
        returnState.userData = state.SettingReducer.data.data;
        if (
          userData.redirect_to != undefined &&
          userData.redirect_to != null &&
          userData.redirect_to != ""
        ) {
          setRedirectTo(userData.redirect_to);
          returnState.redirect_to = userData.redirect_to;
          returnState.redirect_url = getRedirectTo();
        }
        if (
          userData.redirect_to != undefined &&
          userData.redirect_to != "upgrade-account-to-stripe" &&
          userData.redirect_to != "upgrade-subscription-plan" &&
          userData.is_bba_signed != undefined &&
          userData.is_bba_signed == 1
        ) {
          returnState.status = 200;
          localStorage.setItem("currentUserRole", userData.user.user_role_id);
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("isLoggedIn", 1);
          localStorage.setItem(
            "user_listing_settings",
            JSON.stringify(userData.user_listing_settings),
          );
        } else {
          if (
            userData.redirect_to != undefined &&
            userData.redirect_to == "upgrade-account-to-stripe"
          ) {
            returnState.status = 604;
          } else if (
            userData.redirect_to != undefined &&
            userData.redirect_to == "upgrade-subscription-plan"
          ) {
            returnState.status = 603;
          } else {
            setRedirectTo("accept-agreement");
            returnState.status = 602;
          }
          const tempLoggedUserData = {
            userData: userData,
            currentUserRole: userData.user.user_role_id,
            isLoggedIn: 1,
            user_listing_settings: userData.user_listing_settings,

            access_token: getToken(),
          };
          localStorage.setItem(
            "tempLoggedUserData",
            JSON.stringify(tempLoggedUserData),
          );
        }
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.accountInactiveDataTime = new Date();
    }
  }

  returnState.stripeIntent = state.StripeReducer.stripeIntent;

  return returnState;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      accountReset,
      getSignUpPlans,
      signUp,
      switchWorkspaces,
      getSetupIntentForAccount,
      upgradeTrailToPaid,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(TrailToPaid));
