import React, { Component } from "react";
import * as _ from "lodash";
import { detectEntity } from "../comprehend/DetectEntities";
import { lowConfidenceStyle } from "./TranscriptionWindow";

export const tonkenizeTranscript = async (transcipt, results) => {
  let itemList = _.filter(results[0].Alternatives[0].Items, (item) => {
    return item.Type === "pronunciation";
  });

  var originalTranscript = "";
  var transcriptWordList = [];

  let wordList = transcipt.split(" ");
  var runningIndex = 0;

  _.map(wordList, (word, i) => {
    transcriptWordList.push({
      text: word,
      start: runningIndex,
      end: runningIndex + word.length,
      labels: [],
      confidence: itemList[i].Confidence,
    });

    originalTranscript = originalTranscript + word + " ";
    runningIndex = runningIndex + word.length + 1;
  });

  let response = await detectEntity(originalTranscript);
  let entities = response["Entities"];

  _.map(transcriptWordList, (word) => {
    _.map(entities, (e) => {
      if (
        parseInt(e["BeginOffset"]) === parseInt(word.start) ||
        parseInt(e["EndOffset"]) === parseInt(word.end) ||
        parseInt(e["EndOffset"]) === parseInt(word.end) - 1 ||
        (parseInt(e["BeginOffset"]) <= parseInt(word.start) &&
          parseInt(e["EndOffset"]) >= parseInt(word.end))
      ) {
        word.labels.push(e);
      }

      _.map(e["Attributes"], (attr) => {
        if (
          parseInt(attr["BeginOffset"]) === parseInt(word.start) ||
          parseInt(attr["EndOffset"]) === parseInt(word.end) ||
          parseInt(attr["EndOffset"]) === parseInt(word.end) - 1 ||
          (parseInt(attr["BeginOffset"]) <= parseInt(word.start) &&
            parseInt(attr["EndOffset"]) >= parseInt(word.end))
        ) {
          word.labels.push(e);
        }
      });
    });
  });

  return { wordTokens: transcriptWordList, segmentEntities: entities };
};

export default class Transcript extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startTime: props.startTime,
      wordTokenList: props.words,
      annotationStyle: props.annotationStyle,
      showConfidence: props.showConfidence,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      wordTokenList: props.words,
      annotationStyle: props.annotationStyle,
      showConfidence: props.showConfidence,
    });
  }

  entityStyle = (token) => {
    let entities = token.labels;
    let { annotationStyle, showConfidence } = this.state;
    let style = {
      color: "black",
      "text-decoration": "none",
      "font-size": "18px",
    };

    _.map(entities, (e) => {
      _.map(annotationStyle, (v, k) => {
        if (v !== undefined && e["Category"] === k) {
          if (v["mid"]) {
            style = { ...style, ...v["mid"] };

            if (e["BeginOffset"] === token.start) {
              style = { ...style, ...v["left"] };
            }

            if (
              e["EndOffset"] === token.end ||
              e["EndOffset"] === token.end - 1
            ) {
              style = { ...style, ...v["right"] };
            }

            _.map(e["Attributes"], (attr) => {
              if (attr["BeginOffset"] === token.start) {
                style = { ...style, ...v["left"] };
              }

              if (
                attr["EndOffset"] === token.end ||
                attr["EndOffset"] === token.end - 1
              ) {
                style = { ...style, ...v["right"] };
              }
            });
          } else {
            style = { ...style, ...v["all"] };
          }
        }
      });
    });

    if (showConfidence && token.confidence < 0.9) {
      style = { ...style, ...lowConfidenceStyle };
    }

    return style;
  };

  isRightMostBorder = (token) => {
    let entities = token.labels;
    var isRightMost = false;
    _.forEach(entities, (e) => {
      if (
        e["Category"] === "PROTECTED_HEALTH_INFORMATION" ||
        e["Category"] === "MEDICAL_CONDITION"
      ) {
        if (e["EndOffset"] === token.end || e["EndOffset"] === token.end - 1) {
          isRightMost = true;
        }
      }
    });
    return isRightMost;
  };

  formatNumber = (n) => {
    if (n < 10) {
      return `0${n}`;
    } else {
      return `${n}`;
    }
  };
  formatTimestamp = (timestamp) => {
    let time = parseInt(timestamp);
    let hr = Math.floor(time / 3600);
    let min = Math.floor(time / 60);
    let sec = Math.floor(time % 60);

    return `${this.formatNumber(hr)}:${this.formatNumber(
      min,
    )}:${this.formatNumber(sec)}`;
  };

  render() {
    let { wordTokenList } = this.state;

    return (
      <div className="col mr-n3">
        <div className="row mx-n3">
          <div className="col-11 mr-n3 pr-0 pl-2">
            <p>
              {_.map(wordTokenList, (token) => {
                let display = this.isRightMostBorder(token)
                  ? token.text
                  : token.text + " ";

                return token.labels.length > 0 ? (
                  <span>
                    {display}
                    {this.isRightMostBorder(token) && <span> </span>}
                  </span>
                ) : (
                  <span>{display}</span>
                );
              })}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
