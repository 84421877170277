import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {
  fetchClinics,
  exportEmptyData,
} from "../../../../Actions/clinicsActions.js";
import { autoScrolling } from "../../../../Utils/services.js";
import Loader from "../../../../Components/Common/Loader.js";
import ReactTooltip from "react-tooltip";
import { withCurrentAccountQuery } from "../../../../api/queries/useAccountQuery.js";
import { ACCOUNT_TIME_FORMATS } from "../../../../consts/api.js";

class Clinics extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const userData = JSON.parse(localStorage.getItem("userData"));

    this.state = {
      clinic_name: "",
      contact_no: "",
      address: "",
      clinic_business_hours: [],
      id: userData.user.id,
      tax: "",
      clinicList: [],
      showLoadingText: false,
      page: 1,
      pagesize: 15,
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      scopes: "business_hours",
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    let formData = {
      params: {
        sortorder: "asc",
        term: this.state.term,
        scopes: this.state.scopes,
      },
    };
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      clinic_Create_ClinicBtn: languageData.settings["clinic_Create_ClinicBtn"],
      clinics_Address: languageData.settings["clinics_Address"],
      clinics_Business_Hours: languageData.settings["clinics_Business_Hours"],
      clinics_Contact_Number: languageData.settings["clinics_Contact_Number"],
      clinics_Search: languageData.settings["clinics_Search"],
      clinics_Clinic_Name: languageData.settings["clinics_Clinic_Name"],
      clinics_Tax: languageData.settings["clinics_Tax"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.fetchClinics(formData);
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        sortorder: this.state.sortorder,
        term: this.state.term,
        scopes: this.state.scopes,
      },
    };
    this.setState({
      sortorder: this.state.sortorder == "asc" ? "asc" : "desc",
      loadMore: true,
      startFresh: true,
      next_page_url: "",
      clinicList: [],
      showLoader: true,
    });
    autoScrolling(true);
    this.props.fetchClinics(formData);
  };

  onSort = (sortby) => {
    let sortorder = this.state.sortorder === "asc" ? "desc" : "asc";
    let formData = {
      params: {
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
      },
    };
    this.setState({
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      clinicList: [],
    });
    localStorage.setItem("sortOnly", false);
    autoScrolling(true);
    this.props.fetchClinics(formData);
  };
  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {
        params: {
          sortorder:
            this.state.sortorder && this.state.sortorder === "asc"
              ? "asc"
              : this.state.sortorder == "desc"
              ? "desc"
              : "",
          term: this.state.term,
          scopes: this.state.scopes,
        },
      };
      autoScrolling(true);
      this.props.fetchClinics(formData);
    }
  };
  userEdit = (id) => {
    return <div>{this.props.history.push(`/settings/clinic/${id}/edit`)}</div>;
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      return { showLoader: false };
    }
    if (
      nextProps.clinicList != undefined &&
      nextProps.clinicListTime !== prevState.clinicListTime
    ) {
      let returnState = {};
      returnState.clinicList = nextProps.clinicList.data;
      returnState.showLoader = false;
      returnState.showLoadingText = false;
      returnState.clinicListTime = nextProps.clinicListTime;
      return returnState;
    }
    return null;
  }

  shouldComponentUpdate() {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  to12Time = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h;
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  dayOfWeekAsInteger = (day) => {
    var weekday = new Array(7);
    weekday[2] = "Mon";
    weekday[3] = "Tue";
    weekday[4] = "Wed";
    weekday[5] = "Thu";
    weekday[6] = "Fri";
    weekday[7] = "Sat";
    weekday[1] = "Sun";
    return weekday[day];
  };

  componentDidUpdate() {
    if (this.state.showLoader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }

  prepareTime = (time) => {
    if (
      this.props.currentAccountQuery?.data?.preferences?.timeFormat ===
      ACCOUNT_TIME_FORMATS.h12
    ) {
      return this.to12Time(time);
    }
    return time?.split(":")?.slice(0, 2)?.join(":");
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          {/*Clinic Head Start*/}
          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="cursor-pointer">Clinics</span>
                <form onSubmit={this.handleSubmit} className="searchForm">
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      data-url="/settings/clinics"
                      name="term"
                      placeholder={this.state.clinics_Search}
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </span>
                </form>
                <div className="memberRightActions">
                  <Link
                    to="/settings/clinic/create"
                    className="new-blue-btn pull-right"
                  >
                    {" "}
                    {this.state.clinic_Create_ClinicBtn}{" "}
                  </Link>
                </div>
              </div>
            </div>
            {/*Clinic Head END*/}
            <div className="full-width">
              <div className="row new-sale-row">
                {this.state.clinicList !== undefined &&
                  this.state.clinicList.map((obj, idx) => {
                    return (
                      <div
                        className="col-sm-6 col-xs-12 new-sale-col"
                        key={idx}
                      >
                        <div className="setting-setion m-b-15 clinic-card">
                          <div className="clinicHeading ">
                            <p
                              className="text-ellipsis"
                              data-tip
                              data-for={"smsToolTip" + idx}
                            >
                              {obj.clinic_name}
                            </p>
                            <button
                              className="easy-link pull-right"
                              key={idx}
                              onClick={this.userEdit.bind(this, obj.id)}
                              title="Edit Clinic"
                            >
                              <i className="fa fa-pencil-alt m-r-5 " />
                              Edit
                            </button>
                          </div>
                          <ReactTooltip id={"smsToolTip" + idx} effect="solid">
                            <span>{obj.clinic_name}</span>
                          </ReactTooltip>
                          <div className="table-responsive clinicAddressSection">
                            <table className="records-table">
                              <tbody>
                                <tr>
                                  <td>
                                    <p>{obj.contact_no}</p>
                                    <p>{`${obj.address}${
                                      obj.suite_number
                                        ? `, ${obj.suite_number} `
                                        : ""
                                    }`}</p>
                                    <p>{`${obj.clinic_city} ${obj.clinic_state} ${obj.clinic_zipcode}`}</p>
                                    <p>{obj.country} </p>
                                  </td>
                                </tr>
                                {obj.clinic_business_hours &&
                                  obj.clinic_business_hours.length > 0 && (
                                    <>
                                      <tr>
                                        <td>
                                          <b>Business hours</b>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          {obj.clinic_business_hours.map(
                                            (innobj, innidx) => {
                                              var time1 = this.prepareTime(
                                                `${innobj.to_time}`,
                                              );
                                              var time = this.prepareTime(
                                                `${innobj.from_time}`,
                                              );
                                              var dayCon =
                                                this.dayOfWeekAsInteger(
                                                  `${innobj.day}`,
                                                );
                                              return (
                                                <div key={innidx}>
                                                  <div className="businessWeekrow">
                                                    <span className="businessWeekName">
                                                      {dayCon}
                                                    </span>{" "}
                                                    {time} - {time1}{" "}
                                                  </div>
                                                </div>
                                              );
                                            },
                                          )}
                                        </td>
                                      </tr>
                                    </>
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>

            {this.state.showLoader === false && (
              <div
                className={
                  this.state.clinicList != undefined &&
                  this.state.clinicList.length == 0
                    ? "juvly-section full-width no-record"
                    : " juvly-section full-width no-record no-display"
                }
              >
                <div
                  className=" m-t-10"
                  style={{
                    float: "left",
                    width: "100%",
                    fontSize: "13px",
                    textAlign: "center",
                    marginTop: "0px",
                    padding: "0px",
                  }}
                >
                  {this.state.clinic_No_Record_Found}
                </div>
              </div>
            )}

            <div
              className={
                this.state.showLoadingText
                  ? "loading-please-wait no-margin-top"
                  : "loading-please-wait no-margin-top no-display "
              }
            >
              {this.state.loading_please_wait_text}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.ClinicReducer.action === "CLINIC_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.clinicList = state.ClinicReducer.data;
      returnState.clinicListTime = new Date();
    }
  }
  if (state.ClinicReducer.action === "CLINIC_SEARCH") {
    if (state.ClinicReducer.data.status != 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.clinicSearch = state.ClinicReducer.data.data;
    }
  }
  if (state.ClinicReducer.action === "SELECTED_CLINIC_LIST") {
    if (state.ClinicReducer.data.status != 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.clinicSearch = state.ClinicReducer.data.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchClinics,
      exportEmptyData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withCurrentAccountQuery(Clinics));
