import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { emptyProcedureReducer, vieweHealthProcedureData, fetchSelectMD, signProcedure, getProcedureQuestionnaireList, getProcedureQuestionnaireData, saveProcedureQuestionnaireHealth, addEditProceudreQuestionnaireIds} from '../../Actions/Procedures/procedureActions.js';
import { restoreRecentlyDeleted } from '../../Actions/Settings/settingsActions.js';
import { withRouter } from 'react-router';
import defLogo from '../../_legacy/images/upload.png';
import axios from 'axios';
import ProcedureQuestionnaireCosmeticEdit from './ProcedureQuestionnaireCosmeticEdit.js';
import ProcedureQuestionnaireHealthEdit from './ProcedureQuestionnaireHealthEdit.js';
import Select from 'react-select';
import {
  accountStatusResponseInterceptor
} from "../AccountHoldRedirect/AccountHoldRedirectInterceptor";

const proHealthDetInstance = axios.create();
proHealthDetInstance.interceptors.response.use(accountStatusResponseInterceptor);
// use function for already uplaoded file
const getFileType = (value) => {
  value = String (value);
  value = value.split('.').pop();
  if(!value){
    return null
  }
  value = value.toLowerCase()
  let fileType = '';
  if (["pdf"].indexOf(value) > -1 ) {
    fileType = 'pdf';
  } else if (["png", "jpg", "jpeg", "gif"].indexOf(value) > -1 ) {
    fileType = 'image';
  } else if(["xls", "xlsx"].indexOf(value) > -1){
    fileType = 'xls';
  }  else if(["doc", "docs"].indexOf(value) > -1){
    fileType = 'doc';
  }
  return fileType;
}

class ProcedureQuestionnaireList extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    const userData = JSON.parse(localStorage.getItem('userData'));
    let isConsentRequired = userData.user.is_md_consent_required;
    let showSigPopup = userData.user.show_signature_popup;
    let mdUserID = userData.user.md_user_id;

    this.state = {
      action: (props.match.params.type) ? props.match.params.type : 'pending',
      backURLType: (this.props.match.params.actionType) ? this.props.match.params.actionType : 'clients',
      clientID: this.props.match.params.clientID,
      procedureID: (this.props.match.params.procedureID) ? this.props.match.params.procedureID : 0,
      appointmentID: (this.props.match.params.appointmentID) ? this.props.match.params.appointmentID : 0,
      serviceID: (this.props.match.params.serviceID) ? this.props.match.params.serviceID : 0,
      globalLang: languageData.global,
      settingsLang: languageData.settings,
      returnTo: '',
      showLoader: false,
      client_name: '',
      procedure_name: '',
      procedureQuestionnaireData: [],
      templateQuestionList: [],
      isShowDeletedModal: false,
      languageData: languageData.procedure,
      nextProcedureID: 0,
      prevProcedureID: 0,
      languageData: languageData.procedure,
      isConsentRequired: (isConsentRequired) ? 1 : 0,
      showSigPopup: (showSigPopup) ? 1 : 0,
      md_id: (mdUserID) ? mdUserID : 0,
      showSignModal: false,
      canvasClass: "signature-box sig-div",
      inputOut: 'input-outer',
      clearClass: 'new-white-btn no-margin clear no-display',
      resetClass: 'new-blue-btn reset no-display',
      changeClass: 'new-blue-btn no-margin Change',
      uploadedSignature: '',
      uploadedSignature_url: '',
      mdList: [],
      roomType: this.props.match.params.actionType,
      template_used: 0,
      title: '',
      note: '',
      procedure_date: '',
      clinic_name: '',
      provider_name: '',
      questionnaireList: [],
      question_type: 'cosmetic',
      isEditQuestionnaireCosmetic: false,
      isEditQuestionnaireHealth: false,
      questionnaireData: null,

      procedureTemplateList: [],

      getParamsData: {},

      allQuestionnaireList: [],
      selectedQuestionnaireOptions: [],
      questionnaireOptions: [],
      isCloseAllAccordion : false,
      type : this.props.match.params.type



    }
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      return false;
    }

  }

  componentDidMount() {
    //getProcedureQuestionnaireList
    this.setState({ showLoader: true })
    let returnTo = '';
    if (this.state.backURLType && this.state.backURLType === 'clients') {
      returnTo = (this.props.match.params.type) ? "/" + this.state.backURLType + "/" + this.props.match.params.type + "/" + this.props.match.params.clientID : "/" + this.state.backURLType
    } else if (this.state.backURLType && this.state.backURLType === 'provider-room') {
      returnTo = (this.state.backURLType) ? "/" + this.state.backURLType + "/" + this.state.action : 'pending'
    } else if (this.state.backURLType && this.state.backURLType === 'md-room') {
      returnTo = (this.state.backURLType) ? "/" + this.state.backURLType + "/" + this.state.action : 'pending'
    } else if (this.state.backURLType && this.state.backURLType === 'settings') {
      returnTo = "/" + this.state.backURLType + "/" + this.state.action
    }
    
    if (this.state.procedureID) {
      let formData = {
        'params': {
          //room_type : (this.props.match.params.actionType === 'provider-room') ? 'provider' : 'md',
          action: this.props.match.params.type
        }
      }
      if (this.state.backURLType !== 'settings') {
        formData.params.room_type = (this.props.match.params.actionType === 'provider-room') ? 'provider' : 'md'
      }
      if (this.state.appointmentID) {
        formData.params.appointment_id = this.state.appointmentID
      }
      if (this.state.serviceID) {
        formData.params.service_id = this.state.serviceID
      }
      if (this.state.procedureID) {
        formData.params.procedure_id = this.state.procedureID
      }
      if (this.state.clientID) {
        formData.params.client_id = this.state.clientID
      }
      this.setState({ getParamsData: formData, returnTo: returnTo })
      this.props.getProcedureQuestionnaireList(formData);

      if (this.state.backURLType === 'provider-room' && this.state.action === 'pending') {
        this.props.fetchSelectMD();
      }
    } else {
      toast.dismiss(this.state.globalLang.procedure_not_found)
      setTimeout(function () {
        this.props.history.push(returnTo);
      }, 1700)
    }

  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {}
    if (nextProps.procedureQuestionnaireData && nextProps.procedureQuestionnaireData !== prevState.procedureQuestionnaireData) {
      nextProps.emptyProcedureReducer();
      returnState.showLoader = false
      returnState.isEditQuestionnaireCosmetic = false
      returnState.isEditQuestionnaireHealth = false
      returnState.procedureQuestionnaireData = nextProps.procedureQuestionnaireData
      returnState.procedure_name = (nextProps.procedureQuestionnaireData.procedure_name) ? nextProps.procedureQuestionnaireData.procedure_name : ''
      returnState.client_name = (nextProps.procedureQuestionnaireData.client_name) ? nextProps.procedureQuestionnaireData.client_name : ''
      let questionnaireList = (nextProps.procedureQuestionnaireData.data) ? nextProps.procedureQuestionnaireData.data : []
      returnState.question_type = (nextProps.procedureQuestionnaireData.type === 'template' || nextProps.procedureQuestionnaireData.type === 'health') ? 'health' : 'cosmetic';
      if (returnState.question_type === 'health') {
        const image_base_url = (nextProps.procedureQuestionnaireData.image_base_url) ? nextProps.procedureQuestionnaireData.image_base_url +'/' : ''
        questionnaireList.map((obj, index) => {
          let filled_Question_answer = [];
          let question_answer = (obj.procedure_template_question) ? obj.procedure_template_question : []
          let nextQuestionId = 0
          question_answer.map((question, idx) => {
            if(question.id == nextQuestionId || nextQuestionId === 0){
              let answerObj = (obj.answers) ? obj.answers.find(ans => ans.question_id === question.id) : {}

              let answer = '';
              if (answerObj !== undefined && answerObj.answer !== undefined && answerObj.answer !== null) {
                answer = answerObj.answer
              }

              let answer_id = 0
              switch (question.question_type) {
                case "Textbox":
                  question.field_name = 'Textbox_' + question.id
                  question.field_value = answer
                  question.class_name = 'report-input'
                  break;
                case "Multiple Choice":
                  question.field_name = 'Multiple_Choice_' + question.id
                  question.field_value = answer
                  question.class_name = 'col-sm-12 multisel-outer'
                  break;
                case "File Upload":
                  question.field_name = 'File_Upload_' + question.id
                  question.field_value = answer
                  question.class_name = 'file-container file-upload-img'
                  question.file_thumbnail = (answer) ? image_base_url +  answer : ''
                  question.file_size = ''
                  question.download_path = (answer) ? image_base_url  + answer : ''
                  question.file_type = (answer) ? getFileType(answer) : ''
                  //question.download_path = (answer) ? returnState.image_base_url +answerObj.file_path : ''

                  break;
                case "Yes/No":
                  question.field_name = 'Yes_No_' + question.id
                  question.field_value = answer
                  question.class_name = 'col-sm-12 multisel-outer'
                  break;
                case "Single Choice":
                  question.field_name = 'Single_Choice_' + question.id
                  question.field_value = answer
                  question.class_name = 'col-sm-12 multisel-outer'
                  break;
                case "Opinion Scale":
                  question.field_name = 'Opinion_Scale_' + question.id
                  question.field_value = answer
                  question.field_name_why = 'Opinion_Scale_Why_' + question.id
                  question.field_value_why = (answer) ? ((answerObj.comment) ? answerObj.comment : '') : ''
                  question.class_name = 'survey-rate-outer'
                  question.display_step = 0
                  break;
                default:
                  return null
                  break;
              }
              question.error_flag = false
              question.isShow = (idx === 0) ? true : false;
              question.button_next = (question_answer[idx + 1] !== undefined) ? question_answer[idx + 1]['id'] : 0;
              question.button_back = (idx !== 0) ? question_answer[idx - 1]['id'] : 0;

              nextQuestionId = question.button_next;
              if(question.question_type === "Yes/No" || question.question_type === "Single Choice" || question.question_type === "Opinion Scale"){
                 let optionObj =  null;
                 if(question.procedure_template_question_option){
                  optionObj = question.procedure_template_question_option.find(x => x.question_option == question.field_value)
                  if(optionObj){
                    if (question.procedure_templates_logic && question.procedure_templates_logic.length > 0) {

                      // fetch logic-jump-question if set for selected option (value)
                      let procedureTemplatesLogic = question.procedure_templates_logic.find(logicJump => (logicJump.procedure_question_option_id == optionObj.id || logicJump.procedure_question_option_id == question.field_value))

                      // fetch logic-jump-question if not set for selected option (value) or default next question-id
                      if (procedureTemplatesLogic === undefined) {
                        procedureTemplatesLogic = question.procedure_templates_logic.find(logicJump => logicJump.procedure_question_option_id == 0)
                      }

                      if (procedureTemplatesLogic  && procedureTemplatesLogic.jump_to_question && procedureTemplatesLogic.jump_to_question > 0 && (question_answer.find(x => x.id === procedureTemplatesLogic.jump_to_question))) {
                        // get next-question-id if logic jump found
                        nextQuestionId = procedureTemplatesLogic.jump_to_question;
                      }
                    }
                  }
                 }
                question.next_question_id = nextQuestionId

              }
              filled_Question_answer.push(question)
            }
          });
          questionnaireList[index]['consultation_title'] = obj.title;
          questionnaireList[index]['question_answer'] = question_answer
          questionnaireList[index]['is_filled'] = (obj.answers && obj.answers.length > 0) ? true : false
          questionnaireList[index]['filled_Question_answer'] = filled_Question_answer
        })
      }
      returnState.questionnaireList = questionnaireList

      returnState.allQuestionnaireList = (nextProps.procedureQuestionnaireData.all_questionnaires) ? nextProps.procedureQuestionnaireData.all_questionnaires : ''
      let questionnaireOptions = []
      if (returnState.allQuestionnaireList.length > 0 ) {
        questionnaireOptions = returnState.allQuestionnaireList.map((obj, idx) => {
          return {value: obj.id, label: (returnState.question_type === 'health') ? obj.title : obj.consultation_title}
        })
      }
      returnState.questionnaireOptions = questionnaireOptions;

      let selectedQuestionnaireOptions = [];
      if (questionnaireList.length > 0 ) {
        selectedQuestionnaireOptions = questionnaireList.map((obj, idx) => {
          return {value: obj.id, label: (returnState.question_type === 'health') ? obj.title : obj.consultation_title}
        })
      }
      returnState.selectedQuestionnaireOptions = selectedQuestionnaireOptions;
    } if (nextProps.questionnaireData && nextProps.questionnaireData !== prevState.questionnaireData) {
      nextProps.emptyProcedureReducer()
      returnState.showLoader = false
      returnState.isEditQuestionnaireCosmetic = false
      returnState.isEditQuestionnaireHealth = false
      returnState.questionnaireData = nextProps.questionnaireData
      if (prevState.question_type === 'health') {
        returnState.isEditQuestionnaireHealth = true
        const image_base_url = (nextProps.questionnaireData.image_base_url) ? nextProps.questionnaireData.image_base_url +'/' : ''
        let question_answer = (returnState.questionnaireData.procedure_template_question) ? returnState.questionnaireData.procedure_template_question : []
        question_answer.map((question, idx) => {
          let answerObj = (returnState.questionnaireData.answers) ? returnState.questionnaireData.answers.find(ans => ans.question_id === question.id) : {}

          let answer = '';
          if (answerObj !== undefined && answerObj.answer !== undefined && answerObj.answer !== null) {
            answer = answerObj.answer
          }
          switch (question.question_type) {
            case "Textbox":
              question.field_name = 'Textbox_' + question.id
              question.field_value = answer
              question.class_name = 'report-input'
              break;
            case "Multiple Choice":
              question.field_name = 'Multiple_Choice_' + question.id
              question.field_value = [] //(answer) ? answer.split(',').map(function(item) {return parseInt(item, 10);}) : []
              if (answer) {
                answer = answer.split(',').map(function (item) { return item.trim() })
                answer.map((obj1, idx1) => {
                  const tempAnswer0 = question.procedure_template_question_option.find(x => x.question_option == answer[idx1]);
                  if (tempAnswer0) {
                    question.field_value.push(tempAnswer0.id);
                  }
                })
              }
              question.class_name = 'col-sm-12 multisel-outer'
              break;
            case "File Upload":
              question.field_name = 'File_Upload_' + question.id
              question.field_value = answer
              question.class_name = 'file-container file-upload-img'
              question.file_thumbnail = (answer) ? image_base_url + answer : ''
              question.file_size = ''
              question.download_path = (answer) ? image_base_url + answer : ''
              question.file_type = (answer) ? getFileType(answer) : ''
              //question.download_path = (answer) ? answerObj.file_path : ''

              break;
            case "Yes/No":
              question.field_name = 'Yes_No_' + question.id
              question.field_value = ''  //parseInt(answer)
              if (answer) {
                const tempAnswer = question.procedure_template_question_option.find(x => x.question_option == answer);
                if (tempAnswer) {
                  question.field_value = tempAnswer.id;
                }
              }
              question.class_name = 'col-sm-12 multisel-outer'
              break;
            case "Single Choice":
              question.field_name = 'Single_Choice_' + question.id
              question.field_value = '';//parseInt(answer)
              if (answer) {
                const tempAnswer1 = question.procedure_template_question_option.find(x => x.question_option == answer);
                if (tempAnswer1) {
                  question.field_value = tempAnswer1.id;
                }
              }
              question.class_name = 'col-sm-12 multisel-outer'

              break;
            case "Opinion Scale":
              question.field_name = 'Opinion_Scale_' + question.id
              question.field_value = ''; //parseInt(answer)
              if (answer) {
                answer = parseInt(answer) - 1;
                const tempAnswer2 = ((question.procedure_template_question_option) && (question.procedure_template_question_option[answer])) ? question.procedure_template_question_option[answer] : '';
                if (tempAnswer2) {
                  question.field_value = tempAnswer2.id;
                }
              }
              question.field_name_why = 'Opinion_Scale_Why_' + question.id
              question.field_value_why = (answer) ? ((answerObj.comment) ? answerObj.comment : '') : ''
              question.class_name = 'survey-rate-outer'
              question.display_step = 0
              break;
            default:
              return null
              break;
          }
          question.error_flag = false
          question.isShow = (idx === 0) ? true : false;
          question.button_next = (question_answer[idx + 1] !== undefined) ? question_answer[idx + 1]['id'] : 0;
          question.button_back = (idx !== 0) ? question_answer[idx - 1]['id'] : 0;
        });
        question_answer.consultation_title = returnState.questionnaireData.title;
        returnState.questionnaireData.question_answer = question_answer
      } else {
        returnState.isEditQuestionnaireCosmetic = true

        let question_answer = (returnState.questionnaireData.questions) ? returnState.questionnaireData.questions : []
        if (question_answer) {
          question_answer.map((question, idx) => {
            if (question.question_type == 'yesno') {
              let field_value = ''
              let field_value_comment = ''
              if (question.answers.length) {
                field_value = question.answers[0].answer
                //returnState['q-'+question_answer.id+'-'+question.id+'-yesno'] = question.answers[0].answer;
                if (question.answers[0].answer) {
                  //question.field_value_comment = question.answers[0].comment
                  //returnState['q-'+question_answer.id+'-'+question.id+'-comment'] = question.answers[0].comment;
                }
              }
              question.field_value = field_value
              question.field_value_comment = field_value_comment
            } else {
              let field_value = []
              question.question_choices.map((x, y) => {
                let index = question.answers.findIndex(y => y.question_choice_id === x.id);
                if (question.multiple_selection) {
                  if (index > -1) {
                    field_value.push(x.id)
                    //returnState['q-'+question_answer.id+'-'+question.id+'-'+x.id] = (index > -1) ? 1 : 0;
                  }
                } else {
                  let selection = question.answers.find(y => y.question_choice_id === x.id);
                  if (selection) {
                    field_value.push(x.id)
                    //returnState['q-'+question_answer.id+'-'+question.id] = x.id
                  }
                }
              })
              question.field_value = field_value
            }
          })
        }
        returnState.questionnaireData.question_answer = question_answer
      }

    } else if (nextProps.showLoader === false) {
      returnState.showLoader = false
      nextProps.emptyProcedureReducer()
    } else if (nextProps.isRefreshPage == true) {
      nextProps.emptyProcedureReducer()
      nextProps.getProcedureQuestionnaireList(prevState.getParamsData);
      if(nextProps.isCloseAllAccordion){
        Object.keys(prevState).forEach(function (key) {
          if (typeof key === 'string' && key.startsWith('showQuestionnaire-')) {
            returnState[key] = false
          }
        });
      }
    } else if (nextProps.redirect !== undefined && nextProps.redirect === true) {
      nextProps.emptyProcedureReducer()
      toast.success(nextProps.message)
      nextProps.history.push(prevState.returnTo);
    } else if (nextProps.procedureNotFound !== undefined && nextProps.procedureNotFound === true) {
      setTimeout(function () {
        nextProps.history.push(prevState.returnTo);
      }, 1700)
    }



    return returnState
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
  };




  handleRestoreModal = () => {
    this.setState({ isShowDeletedModal: !this.state.isShowDeletedModal })
  }
  restoreSelected = () => {
    if (this.state.procedureID && this.props.match.params.type == 'recently-deleted') {
      const procedureIds = [this.state.procedureID];
      this.setState({ showLoader: true })
      this.props.restoreRecentlyDeleted({ 'procedure_ids': procedureIds })
      this.setState({ isShowDeletedModal: !this.state.isShowDeletedModal })
    }
  }



  getProcedureDataByID = (e) => {
    e.preventDefault();
    let type = e.target.parentNode.name
    let procedureID = 0

    if (type && type == 'getNext') {
      procedureID = this.state.nextProcedureID
    } else if (type && type == 'getPrev') {
      procedureID = this.state.prevProcedureID
    }

    if (procedureID && procedureID > 0) {
      this.props.history.push(`/${this.props.match.params.actionType}/procedure-health-detail/${procedureID}/${this.props.match.params.type}`);
    }
  }

  setCollapse = (questionnaireID, isFilled, event) => {
    event.preventDefault();
    if(isFilled){
      let returnState = {};
      const state = this.state;
      Object.keys(state).forEach(function (key) {
        if (typeof key === 'string' && key.startsWith('showQuestionnaire-')) {
          returnState[key] = false
        }
      });
      returnState['showQuestionnaire-' + questionnaireID] = true;
      this.setState(returnState, () => { })
    }
  }

  editQuestionnaire = (questionnaireID, isFilled, event) => {
    event.preventDefault();
     if (this.state.roomType == "md-room") {
       return false
     }
    let returnState = {
      isEditQuestionnaireHealth: false,
      isEditQuestionnaireCosmetic: false,
      questionnaireID: questionnaireID,
      showLoader: true
    }


    const state = this.state;
    Object.keys(state).forEach(function (key) {
      if (typeof key === 'string' && key.startsWith('showQuestionnaire-')) {
        returnState[key] = false
      }
    });
    returnState['showQuestionnaire-' + questionnaireID] = isFilled;

    let formData = {
      params: {
        id: questionnaireID,
        type: 'questionnaire',
        procedure_id: this.state.procedureID,
        appointment_id: this.state.appointmentID,
        service_id: this.state.serviceID,
      }
    }
    if (questionnaireID) {
      if (this.state.question_type === 'health') {
        formData.params.type = 'template'
      } else {

      }
    }
    this.setState(returnState)
    this.props.getProcedureQuestionnaireData(formData)

  }

  saveQuestionnaire = (formData) => {
    this.setState({ showLoader: true })
    if (this.state.question_type === 'health') {
      this.props.saveProcedureQuestionnaireHealth(formData)
    } else {

    }
  }

  handleDismissQuestionnaire = () => {
    this.setState({
      isEditQuestionnaireHealth: false,
      isEditQuestionnaireCosmetic: false,
      questionnaireID: 0,
      showLoader: false
    })
  }

  handleChildState = (childState) => {
    this.setState(childState)
  }

  viewUploadedQuestionnaireFile = (downloadPath, event) => {
    if(downloadPath){
      window.open(downloadPath,'_blank');
    }
  }

  handleQuestionChange = (selectedQuestionnaireOptions) => {
    this.setState({selectedQuestionnaireOptions : selectedQuestionnaireOptions});
  }

  handleSubmitQuestionnaire = () => {
    this.setState({showLoader:true})
    let formData = {};
    formData.patient_id = this.state.clientID
    formData.procedure_id = this.state.procedureID
    formData.clinic_id = this.state.clinic_id
    formData.type = (this.state.question_type === 'health') ? 'template' : 'cosmetic'



    let questionnaires_ids = []
    if (this.state.selectedQuestionnaireOptions && this.state.selectedQuestionnaireOptions.length > 0 ) {
      this.state.selectedQuestionnaireOptions.map((obj, idx) => {
        questionnaires_ids.push(obj.value)
      })
    }
    formData.questionnaires_ids = questionnaires_ids
    this.props.addEditProceudreQuestionnaireIds(formData);
  }

  renderHealthQiestionAnswer = (questionAnswer, index) => {
    let templateQuestionList = ''
    if (questionAnswer.length > 0) {
      templateQuestionList = questionAnswer.map((questionObj, questionIdx) => {
        return (
          <div key={'templateQuestionList-' + questionObj.id} className="survey-qus-ans">
            <div className="survey-ques"><span className="que-label">{this.state.languageData.pro_que}</span> {questionObj.question}</div>
            {this.renderHealthAnswer(questionObj, questionIdx)}
          </div>
        )
      })
    }
    return templateQuestionList;
  }

  renderHealthAnswer = (questionObj, questionIdx) => {
    let returnHtml = '';
    let answerObj = (questionObj.answers) ? questionObj.answers.find(ans => ans.question_id === questionObj.id) : {}

    let answer = '';
    if (answerObj !== undefined && answerObj.answer !== undefined && answerObj.answer !== null) {
      answer = answerObj.answer
    } else {
      answerObj = {}
    }

    switch (questionObj.question_type) {
      case "Textbox":
      case "Yes/No":
      case "Single Choice":
      case "Multiple Choice":
        returnHtml = (
          <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>{(questionObj.field_value) ? questionObj.field_value : ' '}&nbsp;</div>
        )
        break;
      case "Multiple Choice1":
        let multipleAnswer = []
        if ((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) {
          questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
            if (questionObj.field_value.indexOf(optionObj.id) > -1) {
              multipleAnswer.push(optionObj.question_option)
            }
          })
        }
        returnHtml = (
          <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>{(questionObj.field_value) ? questionObj.field_value : ' '}</div>
        )

        break;
      case "File Upload":
        returnHtml = (
          <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>

            <div className="row add-doc-section">
              <div className="col-xs-6 m-b-20">
                <div className={"file-container file-upload-img"} title={(questionObj.field_value) ? questionObj.field_value : ''}>
                  {(questionObj.field_value) ?
                    <img
                      className={"full-custom-img cursor-pointer"}
                      src={(questionObj.file_type && questionObj.file_type == 'image') ? questionObj.download_path : defLogo}
                      onClick={this.viewUploadedQuestionnaireFile.bind(this,questionObj.download_path)}
                    />
                    :
                    <img
                      className={""}
                      src={defLogo}
                    />
                  }
                  <span onClick={this.viewUploadedQuestionnaireFile.bind(this,questionObj.download_path)} className={(questionObj.field_value) ? "file-name file-info cursor-pointer" : "file-name-hide no-display"}> {questionObj.field_value}</span>
                </div>
              </div>
            </div>
          </div>
        )
        break;
      case "Yes/No1":
      case "Single Choice1":
        if ((questionObj.procedure_template_question_option) && questionObj.procedure_template_question_option.length > 0) {
          questionObj.procedure_template_question_option.map((optionObj, optionIdx) => {
            if (questionObj.field_value == optionObj.id) {
              returnHtml = (
                <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>{optionObj.question_option}</div>
              )
            }
          })
        }
        break;
      case "Opinion Scale":
        returnHtml = (
          <div>
            <div className="survey-ans">
              <span className="ans-label">{this.state.languageData.pro_ans}</span>
              {questionObj.field_value}
            </div>
            {questionObj.why_choose == 1 && questionObj.field_value_why !== '' &&
              <div className="survey-ans survey-ans-why-choose"><span className="ans-label ans-label-why-choose">{this.state.languageData.pro_why_choose}</span> {questionObj.field_value_why}</div>
            }
          </div>
        )
        break;
      default:
        return null
        break;
    }
    if (returnHtml == '') {
      returnHtml = (
        <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span> &nbsp; </div>
      )
    }
    return returnHtml;
  }

  renderCosmeticQiestionAnswer = (questionAnswer, index) => {
    let templateQuestionList = ''
    if (questionAnswer && questionAnswer.length > 0) {
      templateQuestionList = questionAnswer.map((questionObj, questionIdx) => {
        return (
          <div key={'templateQuestionList-' + questionObj.id} className="accordion-discription-row">
            <div className="accordian-section col-xs-6 no-border-left">{questionObj.question}</div>
            {(questionObj.question_type == 'yesno') ?
              <div className="accordian-section col-xs-6 border-left">
                {questionObj.answers && questionObj.answers != undefined && questionObj.answers.map((obj2, idx2) => {
                  return (
                    <div key={idx2}>
                      {(obj2.answer) == 1 ? 'Yes' : (obj2.answer) == 0 ? 'No' : '' }
                      {((obj2.answer) == 1 && obj2.comment) && <div>{obj2.comment}</div>}
                    </div>
                  )
                })}
              </div>
              : (questionObj.question_type == 'multitext') ?
                <div className="accordian-section col-xs-6 border-left">
                  {questionObj.answers && questionObj.question_choices != undefined && questionObj.question_choices.map((obj2, idx2) => {
                    return (
                      <div key={idx2}>
                        {(obj2.is_selected) == 1 ? obj2.text : null}
                        {(this.props.match.params.type == 'health' && obj2.question_option) ? obj2.question_option : null}
                      </div>
                    )
                  })}
                </div>
                :
                <div className="accordian-section col-xs-6 border-left">
                  {questionObj.answers && questionObj.question_choices != undefined && questionObj.question_choices.map((obj2, idx2) => {
                    return (
                      <div className="question-img-outer sel" key={idx2}>
                        <div className="question-img">
                          <img src={obj2.image_url} />
                        </div>
                        <div className="qusst-name">{obj2.image_label}</div>
                        {(obj2.is_selected) ? <div className="check-quest"></div> : ''}
                      </div>
                    )
                  })}
                </div>
            }
          </div>
        )
      })
    }
    return templateQuestionList;
  }

  render() {

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="wide-popup">
            <div className="modal-blue-header questHealtimeTitle">
              <a onClick={() => this.props.history.goBack()} className="popup-cross">×</a>

              {this.state.showLoader === false && <span className="popup-blue-name">{(this.state.client_name) ? this.state.client_name + " - " : ""}{(this.state.procedure_name) ? this.state.procedure_name + " - ": ""}{'Questionnaire'}</span>}

            </div>

            <div className="wide-popup-wrapper time-line questHealthTimeline">

              {(this.state.isEditQuestionnaireCosmetic === false && this.state.isEditQuestionnaireHealth === false) &&
                <React.Fragment>
                {(this.state.roomType !== "md-room") &&
                  <div className="list-all-questionnaire">
                    <div className="setting-field-outer m-b-20">
                      <div className="new-field-label">{'Add Questionnaire'}</div>
                      <div className="setting-input-outer">
                        <div className="tag-auto-select">
                          {(this.state.questionnaireOptions) && <Select
                            onChange={this.handleQuestionChange}
                            value={this.state.selectedQuestionnaireOptions}
                            isClearable
                            isSearchable
                            options={this.state.questionnaireOptions}
                            isMulti={true}
                          />}
                        </div>
                      </div>
                    </div>

                    <button type="button" id="save_and_exit" className="new-blue-btn pull-right" onClick={this.handleSubmitQuestionnaire.bind(this,0)}>{'Save'}</button>
                  </div>
                }

                <div className="accordion questionnaireAccordion" id="accordionExample">
                  {this.state.questionnaireList && this.state.questionnaireList.length > 0 && this.state.questionnaireList.map((data, index) => {
                    return (
                      <div key={"questionnaireList-" + index} className='membershipAccordion'>
                        <div className="membershipAccordionHead" id="headingOne">
                          <h2 className="mb-0">
                            <button className="btn btn-link cursor-default" type="button" data-toggle={(data.is_filled) ? "collapse" : ""}  data-target={'#questionnaire-' + data.id} aria-expanded='false' aria-controls="collapseOne">
                                <span>{index + 1}. {data.consultation_title} {'-'}</span> <span onClick={this.setCollapse.bind(this, data.id,data.is_filled)} className={(data.is_filled) ? "easy-link no-padding" : "easy-link no-padding disable"}>{'Click to View'}</span>
                                {(this.state.roomType != "md-room") && <span onClick={this.editQuestionnaire.bind(this, data.id, data.is_filled)} style={{ float: 'right' }} className={"easy-link no-padding cursor-pointer"}>{(data.is_filled) ? <i className="fa fa-check m-r-5 text-success"></i> : <i className="fa fa-times m-r-5 text-danger"></i>}Edit</span>}
                            </button>
                          </h2>
                        </div>
                        <div id={'questionnaire-' + data.id} className={this.state['showQuestionnaire-' + data.id] == true ? 'collapse show' : 'collapse'} aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div className={(this.state.question_type === 'health') ? "proQuesAccordion" : "proQuesAccordionOuter"}>
                            { /* HEALTH TIMELINE QUESTIONNAIRE - START */}
                            {(this.state.question_type === 'health') &&
                              <div>

                                {this.renderHealthQiestionAnswer((data.is_filled) ? data.filled_Question_answer : data.question_answer, index)}

                              </div>
                            }
                            { /* HEALTH TIMELINE QUESTIONNAIRE - END */}

                            { /* COSMETIC QUESTIONNAIRE - START */}
                            {(this.state.question_type === 'cosmetic') &&
                              this.renderCosmeticQiestionAnswer(data.questions, index)
                            }
                            { /* COSMETIC QUESTIONNAIRE - END */}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
                </React.Fragment>
              }
              {(this.state.isEditQuestionnaireCosmetic === true) &&
                <ProcedureQuestionnaireCosmeticEdit
                  questionnaireID={this.state.questionnaireID}
                  procedureID={this.state.procedureID}
                  clientID={this.state.clientID}
                  appointmentID={this.state.appointmentID}
                  serviceID={this.state.serviceID}
                  questionnaireData={this.state.questionnaireData}
                  handleChildState={this.handleChildState}
                  handleDismissQuestionnaire={this.handleDismissQuestionnaire}
                  saveQuestionnaire={this.saveQuestionnaire}
                />
              }
              {(this.state.isEditQuestionnaireHealth === true) &&
                <ProcedureQuestionnaireHealthEdit
                  questionnaireID={this.state.questionnaireID}
                  procedureID={this.state.procedureID}
                  clientID={this.state.clientID}
                  appointmentID={this.state.appointmentID}
                  serviceID={this.state.serviceID}
                  questionnaireData={this.state.questionnaireData}
                  handleChildState={this.handleChildState}
                  handleDismissQuestionnaire={this.handleDismissQuestionnaire}
                  saveQuestionnaire={this.saveQuestionnaire}
                />
              }
            </div>

            <div className={this.state.showLoader ? "new-loader text-left displayBlock proDetailLoader" : "new-loader text-left"} >
              <div className="loader-outer">
                <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
                <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.Please_Wait}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.ProcedureReducer.action === 'PROCEDURE_QUESTIONNAIRE_LIST') {
    if (state.ProcedureReducer.data.status !== 200) {
      toast.dismiss()
      toast.error(languageData.global[state.ProcedureReducer.data.message]);
      if (state.ProcedureReducer.data.message === 'procedure_not_found') {
        returnState.message = languageData.global[state.ProcedureReducer.data.message]
        returnState.procedureNotFound = true
      } else {
        returnState.showLoader = false
      }
    } else {
      returnState.procedureQuestionnaireData = state.ProcedureReducer.data.data
    }
  } else if (state.ProcedureReducer.action === "PROCEDURE_QUESTIONNAIRE_DATA") {
    if (state.ProcedureReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.ProcedureReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.questionnaireData = state.ProcedureReducer.data.data
    }
  } else if (state.ProcedureReducer.action === "SAVE_PROCEDURE_QUESTIONNAIRE_HEALTH") {
    toast.dismiss();
    if (state.ProcedureReducer.data.status != 200) {
      toast.error(languageData.global[state.ProcedureReducer.data.message]);
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.ProcedureReducer.data.message]);
      returnState.isRefreshPage = true
      returnState.isCloseAllAccordion = false
    }
  }  else if (state.ProcedureReducer.action === "ADD_EDIT_PROCEDURE_QUESTIONNAIRE_IDS") {
    toast.dismiss();
    if (state.ProcedureReducer.data.status != 200) {
      toast.error(languageData.global[state.ProcedureReducer.data.message]);
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.ProcedureReducer.data.message]);
      returnState.isRefreshPage = true
      returnState.isCloseAllAccordion = true
    }
  } else if (state.ProcedureReducer.action === 'PRO_MDS_LIST') {
    toast.dismiss();

    if (state.ProcedureReducer.data.status !== 200) {
      //  toast.error(languageData.global[state.ProcedureReducer.data.message]);
    } else {
      return {
        mdList: state.ProcedureReducer.data.data
      }
    }
    return {}
  } else if (state.ProcedureReducer.action === 'SIGN_HEALTH_PROCEDURE') {
    toast.dismiss();

    if (state.ProcedureReducer.data.status !== 201) {
      toast.error(languageData.global[state.ProcedureReducer.data.message]);
    } else {
      toast.success(languageData.global[state.ProcedureReducer.data.message]);
      return {
        procedureSignData: state.ProcedureReducer.data,
      }
    }
    return {}
  } else if(state.ProcedureReducer.action === 'GET_APPOINTMENT_QUESTIONNAIRES') {
    toast.dismiss();

    if (state.ProcedureReducer.data.status !== 200) {
      toast.error(languageData.global[state.ProcedureReducer.data.message]);
    } else {
      toast.success(languageData.global[state.ProcedureReducer.data.message]);
      return {
        procedureQuestionnaireData: state.ProcedureReducer.data.data,
      }
    }
    return {}
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getProcedureQuestionnaireList, getProcedureQuestionnaireList, getProcedureQuestionnaireData: getProcedureQuestionnaireData, saveProcedureQuestionnaireHealth: saveProcedureQuestionnaireHealth, vieweHealthProcedureData: vieweHealthProcedureData, emptyProcedureReducer: emptyProcedureReducer, restoreRecentlyDeleted: restoreRecentlyDeleted, fetchSelectMD: fetchSelectMD, signProcedure: signProcedure, addEditProceudreQuestionnaireIds:addEditProceudreQuestionnaireIds }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProcedureQuestionnaireList));
