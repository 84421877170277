import axios from "axios";
import { getToken } from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const verticalInstance = axios.create();

verticalInstance.defaults.headers.common["access-token"] = getToken();

export function fetchVerticals(formData) {
  return (dispatch) => {
    verticalInstance
      .get(process.env.REACT_APP_API_URL + "verticals", formData)
      .then((response) => {
        dispatch({ type: "VERTICAL_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VERTICAL_LIST", payload: error.response.data });
      });
  };
}

verticalInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function createVertical(formData) {
  return (dispatch) => {
    verticalInstance
      .post(
        process.env.REACT_APP_API_URL + "verticals",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "CREATE_VERTICAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_VERTICAL", payload: error.response.data });
      });
  };
}

export function updateVertical(verticalID, formData) {
  return (dispatch) => {
    verticalInstance
      .put(
        process.env.REACT_APP_API_URL + "verticals/" + verticalID,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_VERTICAL", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_VERTICAL", payload: error.response.data });
      });
  };
}

export function verticalEnableView(formData) {
  return (dispatch) => {
    verticalInstance
      .post(
        process.env.REACT_APP_API_URL + "verticals/enable-view",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "VERTICAL_ENABLE_VIEW", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "VERTICAL_ENABLE_VIEW",
          payload: error.response.data,
        });
      });
  };
}

export function deleteVertical(verticalID) {
  return (dispatch) => {
    verticalInstance
      .delete(process.env.REACT_APP_API_URL + "verticals/" + verticalID)
      .then((response) => {
        dispatch({
          type: "DELETE_VERTICAL",
          payload: response.data,
          verticalID: verticalID,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_VERTICAL",
          payload: error.response.data,
          verticalID: verticalID,
        });
      });
  };
}
