import React from "react";
import PropTypes from "prop-types";
import classes from "./Payments.module.scss";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { prettifyPaymentInfo } from "../../../../SalesCheckoutInvoice.utils";
import { PAYMENT_MODE_ICONS_BY_TYPE } from "../../../../SalesCheckoutInvoice.consts";
import { formatCurrency } from "../../../../../../../utilities/general";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { svg } from "../../../../../../../assets/svg";
import { PAYMENT_MODES } from "../../../../../../../consts/api";

export function Payments({ list, currency }) {
  const { tSales } = useAppTranslation.Sales();
  const { invoice } = useInvoice();

  return (
    <table className={classes.root}>
      <thead>
        <tr>
          <th>{tSales("checkoutInvoice.stageSuccess.history.option")}</th>
          <th>{tSales("checkoutInvoice.stageSuccess.history.amount")}</th>
        </tr>
      </thead>
      <tbody>
        {list.map((payment) => {
          const { modeLabel, amount } = prettifyPaymentInfo(payment, currency);
          const paymentImg = {
            klarna: PAYMENT_MODE_ICONS_BY_TYPE[PAYMENT_MODES.klarna],
            link: PAYMENT_MODE_ICONS_BY_TYPE[PAYMENT_MODES.link],
            default: PAYMENT_MODE_ICONS_BY_TYPE[payment.mode],
          };

          return (
            <tr key={payment.id}>
              <td className={classes.option}>
                {payment?.mode && (
                  <div className={classes.imgWrap}>
                    <img
                      src={
                        paymentImg[modeLabel.toLowerCase()] ||
                        paymentImg["default"]
                      }
                      alt={payment.mode}
                    />
                  </div>
                )}
                {modeLabel}
              </td>
              <td className={classes.amount}>{amount}</td>
            </tr>
          );
        })}
      </tbody>
      <tbody>
        {invoice?.redeemedGiftCards?.map((payment) => {
          return (
            <tr key={payment.id}>
              <td className={classes.option}>
                <div className={classes.imgWrap}>
                  <img src={svg.giftCardPrimary} alt={payment.mode} />
                </div>
                {tSales("checkoutInvoice.summary.eGiftCardAmount", {
                  code: String(payment.redemptionCode),
                })}
              </td>
              <td>{`${formatCurrency(
                payment.redeemedAmount,
                invoice?.currency,
              )}`}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

Payments.propTypes = {
  list: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
};
