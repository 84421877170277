import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube";
import { faGooglePlus } from "@fortawesome/free-brands-svg-icons/faGooglePlus";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { ToastContainer } from "react-toastify";
import moment from "moment";

const FOOTER_TEXT_FALLBACK = "Aesthetic Record. All Rights Reserved.";

class Footer extends React.Component {
  constructor(props) {
    super(props);

    document.body.classList.add("guest-body");
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      globalLang:
        languageData && languageData.global ? languageData.global : {},
      year: moment().format("YYYY"),
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (nextProps.globalLang && nextProps.globalLang !== prevState.globalLang) {
      returnState.globalLang = nextProps.globalLang;
    }

    return returnState;
  }

  render() {
    return (
      <footer className="footer-fixed" id="guest-footer-fixed">
        <div className="wrapper">
          {this.state.globalLang && (
            <p>
              {"© " +
                this.state.year +
                " " +
                (this.state.globalLang.global_footer ||
                  FOOTER_TEXT_FALLBACK)}{" "}
              <a
                href="https://www.aestheticrecord.com/terms-of-service"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.globalLang.global_terms_of_service}
              </a>
              &nbsp;&nbsp;{" "}
              <a
                className="ar-policy-link"
                href="https://www.aestheticrecord.com/policies"
                target="_blank"
                rel="noopener noreferrer"
              >
                {this.state.globalLang.global_ar_policies}
              </a>
            </p>
          )}

          <ul id="social">
            <li>
              <a
                target="_blank"
                href="https://twitter.com/Aesthetic_Rec"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTwitter} />{" "}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.facebook.com/aesthetic.rec/"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.youtube.com/channel/UCY2W0j0DbqLsg4qomXL0c1g"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faYoutube} />{" "}
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://plus.google.com/b/110237484009069856390/110237484009069856390"
              >
                <FontAwesomeIcon icon={faGooglePlus} />
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/aestheticrecord/"
              >
                <FontAwesomeIcon icon={faInstagram} />{" "}
              </a>
            </li>
          </ul>
        </div>
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </footer>
    );
  }
}

export default Footer;
