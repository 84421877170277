import * as yup from "yup";
import { useFormik } from "formik";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { CREATE_EXAM_INVITE_ADDITION_PATIENT_FIELDS as ADDITION_FIELDS } from "../../../api/qualiphy/useQualiphyCreateExamInviteMutation";
import { phoneUtil } from "../../../utilities/phone";

export function useForm({ onSubmit, additionPatientFields, initialValues }) {
  const { tCommon } = useAppTranslation.Common();

  const additionPatientFieldSchemasByType = {
    [ADDITION_FIELDS.dateOfBirth]: yup.object({
      dateOfBirth: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.dateOfBirthRequired"),
        ),
    }),
    [ADDITION_FIELDS.email]: yup.object({
      email: yup
        .string()
        .email(tCommon("formError.emailInvalid"))
        .required(tCommon("createQualifyProcedure.formError.emailRequired")),
    }),
    [ADDITION_FIELDS.firstName]: yup.object({
      firstName: yup
        .string()
        .required(
          tCommon("createQualifyProcedure.formError.firstNameRequired"),
        ),
    }),
    [ADDITION_FIELDS.lastName]: yup.object({
      lastName: yup
        .string()
        .required(tCommon("createQualifyProcedure.formError.lastNameRequired")),
    }),
    [ADDITION_FIELDS.phone]: yup.object({
      phone: yup
        .string()
        .test({
          test: (value) =>
            !value || phoneUtil.isPossible(phoneUtil.compose(value)),
          message: tCommon("formError.phoneInvalid"),
        })
        .required(tCommon("createQualifyProcedure.formError.phoneRequired")),
    }),
  };

  const getSchema = () => {
    const schema = yup.object({
      clinicId: yup
        .number()
        .required(tCommon("createQualifyProcedure.formError.clinicRequired")),

      exams: yup
        .array()
        .of(
          yup.object({
            value: yup.number(),
            label: yup.string(),
            isAttachmentsRequired: yup.bool(),
          }),
        )
        .min(1, tCommon("createQualifyProcedure.formError.examsMin1"))
        .required(tCommon("createQualifyProcedure.formError.examsRequired")),

      attachments: yup.lazy((_, { context }) => {
        const schema = yup.array().of(yup.object());
        if (context.exams.some((e) => e.isAttachmentsRequired)) {
          return schema.min(1);
        }
        return schema;
      }),
    });

    const schemaToConcat = Object.values(ADDITION_FIELDS)
      .filter((f) => (additionPatientFields || []).includes(f))
      .reduce(
        (res, f) => res.concat(additionPatientFieldSchemasByType[f]),
        yup.object({}),
      );

    return schema.concat(schemaToConcat);
  };

  const {
    values,
    setFieldTouched,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    isValid,
  } = useFormik({
    validationSchema: getSchema(),
    enableReinitialize: true,
    onSubmit,
    initialValues: {
      clinicId: "",
      exams: [],
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      dateOfBirth: "",
      attachments: [],
      ...initialValues,
    },
  });

  const hasError = (field) => {
    return Boolean(touched[field] && errors[field]);
  };

  const setFormValue = (field, value) => {
    setFieldTouched(field);
    setFieldValue(field, value);
  };

  return {
    form: values,
    errors,
    isValid,
    hasError,
    setFormValue,
    submit: handleSubmit,
  };
}
