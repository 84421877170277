import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { ModalCharge } from "../../../../shared/ModalCharge/ModalCharge";
import { dispatch } from "../../../../../../../store/store";
import { checkoutInvoice } from "../../../../../../../store/checkoutInvoice";
import { useAppTranslation } from "../../../../../../../i18n/useAppTranslation";
import { useChargeForm } from "../../../../hooks/useChargeForm";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../../../shared/InputCurrency/InputCurrency";
import { InputError } from "../../../../../../../shared/InputError/InputError";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import { useInvoice } from "../../../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../../../hooks/useInvoiceActions";
import {
  getClearentToken,
  initializeClearentSdk,
} from "../../../../../../../Utils";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { Input } from "../../../../../../../shared/Input/Input";
import {
  composeAmountSchema,
  composeEmailSchema,
  composeZipCodeSchema,
} from "../../../../SalesCheckoutInvoice.schemas";

export function FormNewCard() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay, isGuest } = useInvoice();
  const { chargeCard } = useInvoiceActions();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    saveCard: yup.bool().required(),
    email: composeEmailSchema(),
    zipCode: composeZipCodeSchema(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: async (values) => {
        try {
          setIsSubmitting(true);
          const clearentToken = await getClearentToken();
          if (!clearentToken) throw new Error();
          chargeCard
            .initiate({
              clearentEmail: values.email,
              clearentZip: values.zipCode,
              saveCard: values.saveCard,
              isSavedCard: false,
              amount: values.amount,
              clearentToken,
            })
            .then(onClose);
        } catch {
          uiNotification.error(tCommon("error.invalidCardDetails"));
        } finally {
          setIsSubmitting(false);
        }
      },
      initialValues: {
        amount: amountToPay || "",
        saveCard: false,
        email: invoice?.patient?.email || "",
        zipCode: invoice?.patient?.pinCode || "",
      },
    });

  useEffect(() => {
    if (invoice?.clinic?.publicKey) {
      initializeClearentSdk(invoice.clinic.publicKey, "payment-form");
    } else {
      uiNotification.error(tCommon("error.paymentFormInit"));
    }
  }, [invoice?.clinic?.publicKey]);

  const isLoading = isSubmitting || chargeCard.isLoading;

  return (
    <div>
      <ModalCharge.Content>
        <ModalCharge.InputWrap>
          <InputLabel>
            {tSales("checkoutInvoice.formInputLabel.amount")}
          </InputLabel>
          <InputCurrency
            size="medium"
            prefix={invoice?.currencySymbol}
            value={form.amount}
            isError={hasError("amount")}
            onChange={(value) => changeFormValue("amount", value)}
            placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
          />
          {hasError("amount") && <InputError>{errors.amount}</InputError>}
        </ModalCharge.InputWrap>
        <div id="payment-form" />
        <ModalCharge.InputRow>
          <ModalCharge.InputWrap fullWidth>
            <InputLabel>{tCommon("label.email")}</InputLabel>
            <Input
              type="email"
              size="medium"
              value={form.email}
              isError={hasError("email")}
              onChange={(e) => changeFormValue("email", e.target.value)}
              placeholder={tCommon("formPlaceholder.email")}
            />
            {hasError("email") && <InputError>{errors.email}</InputError>}
          </ModalCharge.InputWrap>
          <ModalCharge.InputWrap width="170px">
            <InputLabel>
              {tSales("checkoutInvoice.formInputLabel.zipCode")}
            </InputLabel>
            <Input
              type="number"
              size="medium"
              value={form.zipCode}
              isError={hasError("zipCode")}
              onChange={(e) => changeFormValue("zipCode", e.target.value)}
              placeholder={tSales(
                "checkoutInvoice.formInputPlaceholder.zipCode",
              )}
            />
            {hasError("zipCode") && <InputError>{errors.zipCode}</InputError>}
          </ModalCharge.InputWrap>
        </ModalCharge.InputRow>
        {!isGuest && (
          <ModalCharge.InputWrap>
            <Checkbox
              size="small"
              label={tSales("checkoutInvoice.saveCcToAccount")}
              isChecked={form.saveCard}
              onChange={() => changeFormValue("saveCard", !form.saveCard)}
            />
          </ModalCharge.InputWrap>
        )}
      </ModalCharge.Content>
      <ModalCharge.Footer
        onClose={onClose}
        isCharging={isLoading}
        onCharge={submit}
        isChargeDisabled={!isValid || isLoading}
      />
    </div>
  );
}
