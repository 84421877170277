import { useQuery } from "@tanstack/react-query";
import * as yup from "yup";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  wpuId: yup.number().required(),
});

const responseSchema = yup.object({
  appId: yup.string().required(),
  created: yup.string().required(),
  email_id: yup.string().required(),
  firstname: yup.string().required(),
  id: yup.number().required(),
  lastname: yup.string().required(),
});

const composeResponse = (res) => {
  return res?.data?.data || {};
};

const createIntercomUserQueryKey = (wpuId) => [
  QUERY_KEYS.intercom.getUser,
  wpuId,
];

export function useIntercomUserQuery({ payload = {}, options = {} } = {}) {
  return useQuery(
    createIntercomUserQueryKey(payload.wpuid),
    async () => {
      const req = requestSchema.validateSync(payload, { strict: true });
      const res = await http.get(HTTP_ENDPOINTS.intercom.getUser(req.wpuId));

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
