import AWS from "aws-sdk";
import { AWS_CREDENTIALS } from "../../../consts/aws";

const comprehendMedical = new AWS.ComprehendMedical(AWS_CREDENTIALS);

export async function detectEntity(text) {
  if (text === undefined || text.replace(/\s/g, "") === "") {
    // Transcript is empty, nothing to detect, also CompMed would through exception
    return [];
  }

  // clients can be shared by different commands
  const params = {
    Text: text,
  };

  return new Promise((resolve, reject) => {
    comprehendMedical.detectEntitiesV2(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
}
