import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import { ToastContainer, toast } from "react-toastify";
import { displayName, capitalizeFirstLetter } from '../../../Utils/services';
import { fetchAppointmentQuestionnaires } from '../../../Actions/Clients/clientsAction';
class DocumentQuestionnaire extends Component{
constructor(props){
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
        globalLang      : languageData.global,
        serviceID       : this.props.match.params.serviceID ? this.props.match.params.serviceID : '',
        clientID        : this.props.match.params.clientID ? this.props.match.params.clientID : '',
        appointmentID   : this.props.match.params.appointmentID ? this.props.match.params.appointmentID : '',
        consultationID  : this.props.match.params.consultationID ? this.props.match.params.consultationID : '',
    };
    window.onscroll = () => {
      return false
    }
}
componentDidMount(){
    this.setState({ showLoader : true })
    let formData = {
        consultation_id : this.state.consultationID,
        appointment_id  : this.state.appointmentID,
        service_id      : this.state.serviceID,
        patient_id      : this.state.clientID
    }
    this.props.fetchAppointmentQuestionnaires(formData)
}

static getDerivedStateFromProps(nextProps, prevState){
    let returnState = {};
    if(nextProps.appointmentQuestionnaire != undefined && nextProps.appointmentQuestionnaireDataTime != prevState.appointmentQuestionnaireDataTime){
        returnState.questionnaire_data = nextProps.appointmentQuestionnaire.questionnaire_data;
        returnState.questions = nextProps.appointmentQuestionnaire.questions;
        returnState.appointmentQuestionnaireDataTime = nextProps.appointmentQuestionnaireDataTime;
        returnState.showLoader = false;
    }
    return returnState;
}
render(){
    return(
        <div id="content">
         <div className="container-fluid content setting-wrapper">
            <div className="juvly-section full-width m-t-15">
               <div className="juvly-container">
               <div className="juvly-title m-b-30">{(this.state.questionsAllData && this.state.questionsAllData.patient) && displayName(this.state.questionsAllData.patient) + ` - `} {(this.state.questionsAllData && this.state.questionsAllData.procedure_name) && capitalizeFirstLetter(this.state.questionsAllData.procedure_name) + ` - `}
                  {this.state.questionnaireID ? 'SELECTED QUESTIONNAIRE DETAILS' : 'QUESTIONNAIRE DETAILS'}
                     <a onClick={() => this.props.history.goBack()} className="pull-right"><img className="pull-right m-t-5" src="/images/close.png"/></a>
                     </div>
                  <div class="accordion" id="accordionExample">
                    <div className='membershipAccordion'>
                    <div className="membershipAccordionHead" id="headingOne">
                    <h2 className="mb-0">
                    <button className="btn btn-link" type="button" data-toggle="collapse" onClick={() => this.setCollapse()} data-target='questionnaire' aria-expanded='false' aria-controls="collapseOne">
                    {this.state.questionnaire_data ? this.state.questionnaire_data.consultation_title : ''}
                    </button>
                    </h2>
                    </div>
                    <div id='questionnaire' className={'collapse show'} aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div className="membershipAccordionContent">
                    <div className="table-responsive m-b-15">
                    <div className="accordion-table">
                    {this.state.questions && this.state.questions.map((obj1,idx1)=>{
                           return(
                           <div key={idx1} className="accordion-discription-row">
                              <div className="accordian-section col-xs-6 no-border-left gray-bg">{obj1.question}</div>
                              {(obj1.question_type == 'yesno')?
                              <div className="accordian-section col-xs-6 border-left">
                                 <div>
                                  {(obj1.your_answer) == 1 ? 'Yes' : (obj1.your_answer) == 0 ? 'No' : '' }
                                  {((obj1.your_answer) == 1 && obj1.your_comment) && <div>{obj1.your_comment}</div>}
                                 </div>

                              </div>
                              :(obj1.question_type == 'multitext') ?
                              <div className="accordian-section col-xs-6 border-left">
                                 {obj1.choice_answers && obj1.question_choices!=undefined  && obj1.question_choices.map((obj2,idx2)=>{
                                 return(
                                 <div key={idx2}>
                                  {(obj2.is_selected) == 1 ? obj2.text :null}
                                  {(this.props.match.params.type == 'health' && obj2.question_option) ? obj2.question_option: null}
                                 </div>
                                 )})}
                              </div>
                              :
                              <div className="accordian-section col-xs-6 border-left">
                              {obj1.choice_answers && obj1.question_choices!=undefined  && obj1.question_choices.map((obj2,idx2)=>{
                              return(
                                 <div className="question-img-outer sel" key={idx2}>
                                    <div className="question-img">
                                       <img src={obj2.image_url} />
                                    </div>
                                    <div className="qusst-name">{obj2.image_label}</div>
                                    {(obj2.is_selected) ? <div className="check-quest"></div> : ''}
                                 </div>
                               )})}
                              </div>
                              }
                           </div>
                           )})}
                           </div>
                           </div>
                      </div>
                      </div>

                    </div>

                  </div>
               </div>
            </div>
         </div>
         <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
           <div className="loader-outer">
             <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
             <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
           </div>
         </div>
      </div>
    )
}
}

function mapStateToProps(state) {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const returnState = {};
    if(state.ClientsReducer.action === 'GET_APPOINTMENT_QUESTIONNAIRE'){
        if(state.ClientsReducer.data.status === 200){
            returnState.appointmentQuestionnaire = state.ClientsReducer.data.data;
            returnState.appointmentQuestionnaireDataTime = new Date();
        } else {
            toast.dismiss();
            toast.error(languageData.global[state.ClientsReducer.data.message]);
        }
    }
    return returnState;
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchAppointmentQuestionnaires : fetchAppointmentQuestionnaires
     }, dispatch)
  }
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentQuestionnaire));
