import { useIntercomUserQuery } from "../../api/intercom/useIntercomUserQuery";
import { authService } from "../../services/AuthService";
import { intercomService } from "../../services/IntercomService";

export function IntercomMessanger() {
  const authData = authService.getLoginData();

  useIntercomUserQuery({
    payload: {
      wpuId: authData.wpuId,
    },
    options: {
      enabled: !!authData.wpuId,
      onSuccess: (data) => {
        intercomService.boot({
          appId: data.appId,
          userId: data.id,
          name: `${data.firstname} ${data.lastname}`,
          email: data.email_id,
          createdAt: data.created,
        });
      },
    },
  });

  return null;
}
