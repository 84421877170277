import React from "react";
import { useChartingFilterCategories } from "../../Settings/ManageClinics/ChartFilters/hooks/useChartingFilterCategories";

const ProcedureTypeSelect = ({ onChange, value }) => {
  const { data } = useChartingFilterCategories();

  return (
    <div className="setting-field-outer">
      <div className="new-field-label">Procedure Type</div>
      <div className="setting-input-outer">
        <select
          name="procedure_type"
          className="setting-select-box"
          onChange={onChange}
          value={value}
        >
            <option value={null}>N/A</option>
          {data?.map((category) => (
            <option value={category.id}>{category.name}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ProcedureTypeSelect;
