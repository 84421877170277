import React, { useMemo, useState } from "react";
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import { Box } from "../../../../shared/Box/Box";
import cx from "clsx";
import { ContentLoader } from "../../../../boxes/ContentLoader/ContentLoader";
import { Table as SharedTable } from "../../../../shared/Table/Table";
import { useDefaultDocumentExpirationDateQuery } from "../../../../api/queries/useDefaultDocumentsQuery";
import { Button } from "../../../../shared/Button/Button";
import { Input } from "../../../../shared/Input/Input";
import { ButtonSecondary } from "../../../../shared/ButtonSecondary/ButtonSecondary";
import { useFormik } from "formik";
import { useEditDefaultDocumentExpirationDate } from "../../../../api/mutations/useEditDefaultDocumentExpirationDate";
import { uiNotification } from "../../../../services/UINotificationService";
import * as yup from "yup";
import { InputError } from "../../../../shared/InputError/InputError";
import { tCommon } from "../../../../i18n/useAppTranslation";

const ClinicalDocumentationSettings = () => {
  const [editModeType, setEditModeType] = useState("");
  const { data, isFetching, refetch } = useDefaultDocumentExpirationDateQuery();
  const { mutate, isLoading: isMutating } =
    useEditDefaultDocumentExpirationDate({
      onSuccess: () =>
        refetch().then(() => {
          setEditModeType("");
          uiNotification.success(
            tCommon("documentsExpirationDate.successfulUpdated"),
          );
        }),
      onError: () =>
        uiNotification.error(tCommon("documentsExpirationDate.failedUpdated")),
    });

  const formattedData = useMemo(() => {
    if (data?.data) {
      return Object.keys(data?.data).reduce((acc, key) => {
        acc[key] = data?.data[key].default_expire_date;
        return acc;
      }, {});
    }
  }, [data])

  const schema = yup.object().shape({
    [editModeType]: yup
      .number()
      .typeError(tCommon("formError.valueNumber"))
      .required(tCommon("formError.required")),
  });

  const { values, handleChange, handleSubmit, resetForm, errors } = useFormik({
    initialValues: { ...formattedData } || {},
    enableReinitialize: true,
    validationSchema: schema,
    onSubmit: (values) => {
      mutate({
        documentType: editModeType,
        valabilityDays: values[editModeType],
      });
    },
  });

  const handleCancel = () => {
    setEditModeType("");
    resetForm();
  };

  const documents = useMemo(
    () => 
      Object.entries(data?.data || {}).map(([type, expirationDays]) =>        
        type === "health_questionnaries"
        ? null
        : {
        type: expirationDays.label,
        expirationDays:
          editModeType === type ? (
            <>
              <Input
                size="small"
                value={values[type]}
                name={type}
                onChange={handleChange}
              />
              {errors[type] && <InputError>{errors[type]}</InputError>}
            </>
          ) : (
            expirationDays.default_expire_date || "N/A"
          ),
        actionButtons:
          editModeType !== type ? (
            <div className="flex gap-8">
              <Button size="small" onClick={() => setEditModeType(type)}>
                Edit
              </Button>
              <ButtonSecondary
                size="small"
                onClick={() =>
                  mutate({ documentType: type, valabilityDays: 0 })
                }
              >
                Reset
              </ButtonSecondary>
            </div>
          ) : (
            <div className="flex gap-8">
              <Button
                size="small"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <ButtonSecondary size="small" onClick={handleCancel}>
                Cancel
              </ButtonSecondary>
            </div>
          ),
      }).filter(Boolean),
    [data, editModeType, values, errors],
  );

  return (
    <div id="content">
      <div className="container-fluid content setting-wrapper">
        <Sidebar />
        <div className="business-setion memberWalletOuter">
          <Box className={cx("m-b-10")}>
            <h4>Clinical Documentation</h4>
          </Box>
          <Box>
            <ContentLoader isLoading={isFetching || isMutating}>
              <SharedTable
                headClassName={""}
                bgColor="white"
                keyExtractor={(row) => row.type}
                onRowClick={null}
                data={documents}
                cols={[
                  {
                    data: "Document Type",
                    accessor: "type",
                  },
                  {
                    data: "Expiration Days",
                    accessor: "expirationDays",
                    className: "max-w-100",
                  },
                  {
                    data: "Actions",
                    accessor: "actionButtons",
                  },
                ]}
              />
            </ContentLoader>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default ClinicalDocumentationSettings;
