import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import { getCurrencySymbol, positionFooterCorrectly, showFormattedDate, numberFormat } from '../../../Utils/services';

class MemberShip extends Component{
	constructor(props){
		super(props);
		const languageData = JSON.parse(localStorage.getItem('languageData'));
		this.state = {
			globalLang : languageData.global,
			clientLang : languageData.clients,
			salesLang  : languageData.sales,
			monthArray : ['January','February','March','April','May','June','July','August','September','October','November','December']
		};
	}

	static getDerivedStateFromProps(nextProps, prevState){
		let returnState = {};
		positionFooterCorrectly();
		if(nextProps.membershipData != undefined && nextProps.membershipDataTime != prevState.membershipDataTime){
			returnState.showLoader = false;
			returnState.membershipData = nextProps.membershipData;
			nextProps.membershipData.map((obj,index) => {
				returnState['membership-' + index] = obj.selected;
			})
		}
		if(nextProps.treatmentPlans != undefined && nextProps.membershipDataTime != prevState.membershipDataTime){
			returnState.showLoader = false;
			returnState.treatmentPlans = nextProps.treatmentPlans;
			nextProps.treatmentPlans.map((obj,index) =>{
				returnState['treatmentPlans-' + index] = obj.selected;
			})
		}
		returnState.membershipDataTime = nextProps.membershipDataTime;
		return returnState;
	}

	handleInputChange = (event) => {
		const target = event.target;
		let value= target.value;
		let name = event.target.name;
		let returnState = {}
		switch(target.type) {
			case 'checkbox': {
				value = target.checked;
				break;
			}
	  
			case 'radio' :{
			  value = target.checked;
			  break;
		  }
		}

		// if(target.name.split('-')[0] == 'membership' && target.checked==true){
		// 	this.state.membershipData.map((data,index) =>{
		// 		if(target.name.split('-')[1] != index.toString()){
		// 			returnState['membership-' + index] = false;
		// 		}
		// 	})
		// }
		if(target.name.split('-')[0] == 'treatmentPlans' && target.checked==true){
			this.state.treatmentPlans.map((data,index) =>{
				if(target.name.split('-')[1] != index.toString()){
					returnState['treatmentPlans-' + index] = false;
				}
			})
		}
	  returnState[event.target.name] = value;
      this.setState(returnState, () => this.sendDataToParent());
	}
	
	sendDataToParent = () => {
		let membershipData = [];
		let treatmentPlans = [];
		this.state.membershipData.map((data,index) => {
			membershipData.push({
			id : data.id,
            membership_tier : data.membership_tier,
            card_details : data.card_details,
            subscription_started_at : data.subscription_started_at,
            mothly_membership_fees : data.mothly_membership_fees,
            recurly_program_name : data.recurly_program_name,
			selected : this.state['membership-' + index] ? this.state['membership-' + index] : false,
			tier_name : data.tier_name,
			payment_frequency : data.payment_frequency,
			one_time_membership_setup : data.one_time_membership_setup,
			yearly_membership_fee : data.yearly_membership_fee
			})
		})

		this.state.treatmentPlans.map((data,index) =>{
			treatmentPlans.push({
				plan_type : data.plan_type,
                patient_treatment_plan_schedule : data.patient_treatment_plan_schedule,
                monthly_amount : data.monthly_amount,
                id : data.id,
                prescribed_plans : data.prescribed_plans,
                skincare_goal : data.skincare_goal,
                selected : this.state['treatmentPlans-' + index] ? this.state['treatmentPlans-' + index] : false,
				total_amount : data.total_amount,
				provider : data.provider
			})
		})

		let formData = {
			step4Data : {
				membershipData : membershipData,
				treatmentPlans : treatmentPlans
			}
		}
		this.props.handleChildState(formData);
	}



    render(){
        return(
        <div>
        <div className="merge-title full-width">

        <div className="row backgroundColorGray">
		<div className="col-md-6 col-xs-12">
			<div className="setting-setion full-width">
				<div className="merge-main-title">
					<span>{this.state.salesLang.sales_membership}</span>
					<p>{this.state.clientLang.merge_right_information_message}</p>
				</div>
				{this.state.membershipData && this.state.membershipData.map((obj,index) => {
					return(
					<div className="procedure-row checkOuter" key={index}>
					<input id="checkbox" className="basic-form-checkbox" 
					name={'membership-' + index} type="checkbox" value={this.state['membership-'+ index] == true ? true : false} onChange={this.handleInputChange} 
					checked={(this.state['membership-' + index]) ? 'checked' : false} />
					<div className="procedureName">{obj.tier_name ? obj.tier_name  : obj.recurly_program_name}</div>
					<div className="procedureInformation">
						
						<div className="procedureDetails p-l-0">
							{/* <div className="info-row">
								<label>{this.state.clientLang.clientprofile_membership_type}</label>
								<span>{obj.tier_name? obj.tier_name : ''}</span>
							</div> */}
							
							{obj.payment_frequency=='monthly' && <div className="info-row">
								<label>{this.state.clientLang.label_price_per_month}</label>
								<span>{numberFormat(obj.mothly_membership_fees,'currency',2)} </span>
							</div>}
							{obj.payment_frequency == 'yearly' && <div className="info-row">
								<label>{'Price Per Year'}</label>
								<span>{numberFormat(obj.yearly_membership_fee,'currency',2)} </span>
							</div>}
							<div className="info-row">
								<label>{this.state.globalLang.one_time_setup_fees}</label>
								<span>{numberFormat(obj.one_time_membership_setup,'currency',2)} </span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_start_date}</label>
								<span>{showFormattedDate(obj.subscription_started_at,true)}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_client_card}</label>
								<span>{obj.card_details}</span>
							</div>
						</div>
					</div>
				</div>)
				})}

               {this.state.membershipData.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
				
				
		
			</div>
		</div>
		
		<div className="col-md-6 col-xs-12 mergeRightSection">
			<div className="setting-setion full-width">
				<div className="merge-main-title">
					<span>{this.state.salesLang.sales_treatment_plans}</span>
					<p>{this.state.clientLang.merge_right_information_message}</p>
				</div>
			
				{this.state.treatmentPlans && this.state.treatmentPlans.map((obj,index) =>{
					return(
						<div className="procedure-row checkOuter" key={index}>
					<input id="radiobutton1" className="basic-form-checkbox" 
					name={'treatmentPlans-' + index} type="radio" value={this.state['treatmentPlans-'+ index] == true ? true : false} onChange={this.handleInputChange} 
					checked={(this.state['treatmentPlans-' + index]) ? 'checked' : false} />
					<div className="procedureName">{obj.plan_type =='monthly' ? "Monthly" : "Pay as you go"} Treatment Plan</div>
					<div className="procedureInformation">
						
						<div className="procedureDetails p-l-0">
							{obj.plan_type == 'monthly' && <div className="info-row">
								<label>{this.state.clientLang.merge_skin_care_goal}</label>
								<span>{obj.skincare_goal}</span>
							</div>}
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_provider}</label>
								<span>{obj.provider}</span>
							</div>
							<div className="info-row">
								<label>{this.state.salesLang.frontdesk_products}</label>
								<span>{obj.plan_type == 'monthly' && obj.patient_treatment_plan_schedule.map((data,i) =>{
								return(
								<p key={i}>{data.product ? data.product.product_name : 'N/A'} ({data.units} units in {this.state.monthArray[data.month - 1]} )</p>)

							})}
							
							{obj.plan_type == 'payg' && obj.patient_treatment_plan_schedule.map((data,i) =>{
								return(
								<p key={i}>{data.product_name} ({data.units} units every {data.repeat_val} {data.repeat_by})</p>)

							})}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.clientprofile_total}</label>
								<span>{numberFormat(obj.total_amount,'currency',2)}</span>
							</div>
							{/* <div className="info-row">
								<label>{this.state.clientLang.clientprofile_total}</label>
								<span>{getCurrencySymbol()} {obj.total_amount}</span>
							</div>
							<div className="info-row">
								<label>{this.state.clientLang.merge_prescribed_plans}</label>
								<span>{obj.prescribed_plans && obj.prescribed_plans.map((data,i) =>{
									return(
										<p>{data.plan_type}</p>
									)
								} )}</span>
							</div> */}
						</div>
					</div>
				</div>
					)
				})}
				{this.state.treatmentPlans.length == 0 && <div>
				<div className="table-responsive">
                <table className="table-updated setting-table">
				   <tbody>
				   <tr className="table-updated-tr">
                    <td className="no-record no-float" colSpan={7}>{this.state.clientLang.clientprofile_no_rec_found}</td></tr>
				   </tbody>
			   </table>
				</div>
				
			</div>}
			</div>
		</div>
		
	</div>
    </div>
    </div>
        )
    }
}

export default MemberShip;