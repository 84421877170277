import React from "react";
import PropTypes from "prop-types";
import LoadingSkeleton from "react-loading-skeleton";
import cx from "clsx";
import classes from "./Skeleton.module.scss";

export function Skeleton({
  count,
  className,
  containerClassName,
  circle,
  style,
  borderRadius,
  height,
  fullHeight,
  width,
}) {
  return (
    <LoadingSkeleton
      count={count}
      className={className}
      containerClassName={cx(containerClassName, {
        [classes.containerFullHeight]: fullHeight,
      })}
      circle={circle}
      height={!fullHeight ? height : undefined}
      style={{
        ...style,
        borderRadius,
        height: fullHeight ? "100%" : undefined,
        width: width || undefined,
      }}
    />
  );
}

Skeleton.propTypes = {
  count: PropTypes.number,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  circle: PropTypes.bool,
  style: PropTypes.object,
  borderRadius: PropTypes.string,
  height: PropTypes.number,
  fullHeight: PropTypes.bool,
  width: PropTypes.string,
};

Skeleton.defaultProps = {
  count: 1,
  className: undefined,
  containerClassName: undefined,
  circle: false,
  style: {},
  borderRadius: "8px",
  height: 20,
  fullHeight: false,
  width: undefined,
};
