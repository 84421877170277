import React, { Component } from "react";
import axios from "axios";
import Header from "../Protected/Header";
import Footer from "../Protected/Footer";
import StripeInfoUpdateModal from "../../../Components/StripeInfoUpdateModal/StripeInfoUpdateModal";
import { getToken } from "../../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const layoutInstance = axios.create();

layoutInstance.defaults.headers.common["access-token"] = getToken();
layoutInstance.interceptors.response.use(accountStatusResponseInterceptor);

class ARLayout extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main protected">
        <Header />
        {this.props.children}
        <Footer />
        <StripeInfoUpdateModal />
      </div>
    );
  }
}

export default ARLayout;
