import React from "react";
import { PageHeader } from "../../../../../boxes/PageHeader/PageHeader";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { DateRangePicker } from "../../../../../shared/DateRangePicker/DateRangePicker";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { Button } from "../../../../../shared/Button/Button";
import { svg } from "../../../../../assets/svg";
import { Box } from "../../../../../shared/Box/Box";
import classes from "./Header.module.scss";

export const Header = ({
  isFilterActive,
  onFilterOpen,
  period,
  isReportsLoading,
}) => {
  const { tCommon } = useAppTranslation.Common();
  const { tAppointments } = useAppTranslation.Appointments();
  const { data: user } = useCurrentUserQuery();

  return (
    <Box>
      <PageHeader
        withBackBtn
        title={tAppointments("noShows.title")}
        rightAdornment={
          <div className={classes.right}>
            <DateRangePicker
              isDisabled={isReportsLoading}
              size="small"
              dateFormat={user?.dateFormat}
              onChange={({ startDate, endDate }) => {
                period.update({
                  from: startDate,
                  to: endDate,
                });
              }}
              value={{
                startDate: period.value.from,
                endDate: period.value.to,
              }}
            />
            <Button
              size="small"
              variant="outlined"
              onClick={onFilterOpen}
              isDisabled={isReportsLoading}
              leftAdornment={
                isFilterActive ? (
                  <img
                    src={svg.filterPrimaryActive}
                    alt="filter"
                    width="16px"
                  />
                ) : (
                  <img src={svg.filterPrimary} alt="filter" width="14px" />
                )
              }
            >
              {tCommon("label.filter")}
            </Button>
          </div>
        }
      />
    </Box>
  );
};
