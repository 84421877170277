import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { LayoutGuest } from "../../boxes/LayoutGuest/LayoutGuest";
import { Input } from "../../shared/Input/Input";
import { Button } from "../../shared/Button/Button";
import classes from "./Login.module.scss";
import { unwrapOr } from "../../utilities/general";
import { AlertBox } from "../../shared/AlertBox/AlertBox";
import { ROUTES } from "../../consts/routes";
import { Recaptcha } from "../../shared/Recaptcha/Recaptcha";
import { useFormLogin } from "./hooks/useFormLogin";
import { InputError } from "../../shared/InputError/InputError";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import { useLogin } from "./hooks/useLogin";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";

function Login({ history }) {
  const { tAuth } = useAppTranslation.Auth();

  const showLoginDelayMessage = unwrapOr(
    () => history.location.state.showDelayMessage,
    false,
  );

  const { login, finishLogin, isLoggingIn, confirmLogin } = useLogin();

  const {
    form,
    isFormValid,
    onFormValueBlur,
    onFormValueChange,
    submit,
    onRecaptchaStatusChange,
    hasError,
    errors,
  } = useFormLogin({ onSubmit: login });

  return (
    <LayoutGuest>
      <div className={classes.root}>
        <h1 className={classes.header}>{tAuth("login.header")}</h1>
        <div className={classes.form}>
          <div>
            <Input
              type="email"
              name="email"
              value={form.email}
              onChange={onFormValueChange}
              onBlur={onFormValueBlur}
              isError={hasError("email")}
              isDisabled={isLoggingIn}
              placeholder={tAuth("label.email")}
            />
            {hasError("email") && <InputError>{errors.email}</InputError>}
          </div>
          <div>
            <Input
              type="password"
              name="password"
              value={form.password}
              onChange={onFormValueChange}
              onBlur={onFormValueBlur}
              isError={hasError("password")}
              isDisabled={isLoggingIn}
              placeholder={tAuth("label.password")}
            />
            {hasError("password") && <InputError>{errors.password}</InputError>}
          </div>
          <div>
            <Recaptcha
              onChange={(token) => onRecaptchaStatusChange(Boolean(token))}
              onExpired={() => onRecaptchaStatusChange(false)}
            />
            {hasError("isRecaptchaValid") && (
              <InputError>{errors.isRecaptchaValid}</InputError>
            )}
          </div>
          <Button
            fullWidth
            onClick={submit}
            isDisabled={!isFormValid || isLoggingIn}
            leftAdornment={
              isLoggingIn ? (
                <CircularProgress size="small" color="white" />
              ) : undefined
            }
          >
            {tAuth("label.login")}
          </Button>
          {showLoginDelayMessage && (
            <AlertBox size="small">{tAuth("login.delay")}</AlertBox>
          )}
          <Button
            variant="text"
            size="small"
            onClick={() => history.push(ROUTES.forgotPasswd())}
            isDisabled={isLoggingIn}
          >
            {tAuth("label.forgotPasswd")}
          </Button>
        </div>
      </div>
      {confirmLogin.isOpen && (
        <ConfirmModal
          isOpen={confirmLogin.isOpen}
          onClose={confirmLogin.close}
          onCancel={confirmLogin.close}
          onConfirm={finishLogin}
          title={tAuth("login.alreadyLoggedIn.title")}
        >
          {tAuth("login.alreadyLoggedIn.text")}
        </ConfirmModal>
      )}
    </LayoutGuest>
  );
}

Login.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Login);
