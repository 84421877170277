import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

export const ASSIGN_TO_SERVICES_MODEL_TYPE = {
  consent: "consent",
  cosmeticQuestionnaire: "cosmetic_questionnaire",
  healthQuestionnaire: "health_questionnaire",
  preTreatmentInstruction: "pre_treatment_instruction",
  postTreatmentInstruction: "post_treatment_instruction",
};

const dtoSchema = yup.object({
  modelId: yup.number().required(),
  modelType: yup
    .string()
    .test("oneOfModel", "", function (value) {
      return Object.values(ASSIGN_TO_SERVICES_MODEL_TYPE).includes(value);
    })
    .required(),
  serviceIds: yup.array().of(yup.number()).required(),
});

export function useAssignServiceToModelMutation(options = {}) {
  return useMutation((payload) => {
    const dto = dtoSchema.validateSync(payload, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.postAssignServicesToModel(), {
      model_id: dto.modelId,
      model_type: dto.modelType,
      services: dto.serviceIds,
    });
  }, options);
}
