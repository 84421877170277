import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
} from "../Utils/services.js";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const http = axios.create();

http.defaults.headers.common["access-token"] = getToken();

http.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }
    return response;
  },
  function (error) {
    let msg = error.response.data.message;
    if (
      msg === "invalid_token" ||
      msg === "session_timeout" ||
      msg === "server_error" ||
      msg === "token_not_found"
    ) {
      handleInvalidToken();
    }

    return Promise.reject(error);
  },
);

http.interceptors.response.use(accountStatusResponseInterceptor);

export const fetchStockEmailAlerts = () => {
  return (dispatch) => {
    dispatch({
      type: "FETCH_STOCK_EMAIL_ALERTS",
      payload: { status: "loading" },
    });
    http
      .get(process.env.REACT_APP_API_URL + "user/stock-alert-email-days")
      .then((response) => {
        dispatch({
          type: "FETCH_STOCK_EMAIL_ALERTS",
          payload: {
            status: "success",
            isEnabled: Boolean(response.data.data.stock_alert_email_status),
            selectedDays: response.data.data.stock_alert_email_days,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "FETCH_STOCK_EMAIL_ALERTS",
          payload: { status: "error" },
        });
      });
  };
};

export const saveStockEmailAlerts = (isEnabled, selectedDays) => {
  return (dispatch) => {
    dispatch({
      type: "SAVE_STOCK_EMAIL_ALERTS",
      payload: { status: "loading" },
    });
    http
      .post(process.env.REACT_APP_API_URL + "user/stock-alert-email-days", {
        stock_alert_email_status: isEnabled,
        stock_alert_email_days: selectedDays,
      })
      .then((response) => {
        dispatch({
          type: "SAVE_STOCK_EMAIL_ALERTS",
          payload: { status: "success" },
        });
        dispatch({
          type: "FETCH_STOCK_EMAIL_ALERTS",
          payload: {
            status: "success",
            isEnabled: Boolean(response.data.data.stock_alert_email_status),
            selectedDays: response.data.data.stock_alert_email_days,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: "SAVE_STOCK_EMAIL_ALERTS",
          payload: { status: "error" },
        });
      });
  };
};

export function resetStockEmailAlertStatus() {
  return (dispatch) => {
    dispatch({ type: "RESET_STOCK_EMAIL_ALERTS" });
  };
}
