import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const apptInstance = axios.create();

apptInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

apptInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

apptInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getAppointmentSurveys(formData) {
  return (dispatch) => {
    apptInstance
      .get(
        process.env.REACT_APP_API_URL + "appointments/setting/survey_email_sms",
        formData,
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_SURVEY_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_SURVEY_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function postAppointmentSurveys(formData) {
  return (dispatch) => {
    apptInstance
      .put(
        process.env.REACT_APP_API_URL + "appointments/setting/survey_email_sms",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "APPOINTMENT_SURVEY_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPOINTMENT_SURVEY_UPDATE",
          payload: error.response.data,
        });
      });
  };
}
