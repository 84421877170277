import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import cx from "clsx";
import classes from "./DatePicker.module.scss";
import { svg } from "../../assets/svg";
import { tCommon } from "../../i18n/useAppTranslation";

export const DatePicker = forwardRef(
  (
    {
      className,
      value,
      onChange,
      dateFormat,
      name,
      selected,
      showMonthDropdown,
      showYearDropdown,
      minDate,
      minTime,
      maxDate,
      maxTime,
      showDisabledMonthNavigation,
      isDisabled,
      pickerClassName,
      onClickOutside,
      isError,
      onChangeRaw,
      onMonthChange,
      onCalendarOpen,
      openToDate,
      renderCustomHeader,
      includeDates,
      showTimeSelect,
      timeIntervals,
      placeholder,
    },
    ref,
  ) => {
    const getIcon = () => {
      let result = svg.calendar;
      if (isError) {
        result = svg.calendarError;
      }
      if (isDisabled) {
        result = svg.calendarGray0;
      }
      return result;
    };
    return (
      <div className={cx(classes.root, className)}>
        <ReactDatePicker
          showTimeSelect={showTimeSelect}
          timeIntervals={timeIntervals}
          value={value}
          onChange={onChange}
          dateFormat={dateFormat}
          placeholderText={placeholder}
          name={name}
          selected={selected}
          autoComplete="off"
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          minDate={minDate}
          minTime={minTime}
          maxDate={maxDate}
          maxTime={maxTime}
          showDisabledMonthNavigation={showDisabledMonthNavigation}
          disabled={isDisabled}
          className={cx(
            {
              [classes.error]: isError,
              [classes.disabled]: isDisabled,
            },
            pickerClassName,
          )}
          ref={ref}
          onClickOutside={onClickOutside}
          onChangeRaw={onChangeRaw}
          onMonthChange={onMonthChange}
          onCalendarOpen={onCalendarOpen}
          openToDate={openToDate}
          includeDates={includeDates}
          renderCustomHeader={renderCustomHeader}
          dropdownMode="select"
        />
        <img src={getIcon()} alt="" />
      </div>
    );
  },
);

DatePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  name: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  showMonthDropdown: PropTypes.bool,
  showYearDropdown: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  minTime: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  showDisabledMonthNavigation: PropTypes.bool,
  isDisabled: PropTypes.bool,
  pickerClassName: PropTypes.string,
  onClickOutside: PropTypes.func,
  isError: PropTypes.bool,
  onChangeRaw: PropTypes.func,
  onMonthChange: PropTypes.func,
  onCalendarOpen: PropTypes.func,
  openToDate: PropTypes.instanceOf(Date),
  renderCustomHeader: PropTypes.func,
  showTimeSelect: PropTypes.bool,
  timeIntervals: PropTypes.number,
  placeholder: PropTypes.string,
};

DatePicker.defaultProps = {
  className: undefined,
  value: undefined,
  onChange: () => {},
  dateFormat: undefined,
  name: undefined,
  selected: undefined,
  showMonthDropdown: false,
  showYearDropdown: false,
  minDate: undefined,
  minTime: undefined,
  maxDate: undefined,
  maxTime: undefined,
  showDisabledMonthNavigation: false,
  isDisabled: false,
  pickerClassName: undefined,
  onClickOutside: () => {},
  isError: false,
  onChangeRaw: () => {},
  onMonthChange: () => {},
  onCalendarOpen: () => {},
  openToDate: undefined,
  renderCustomHeader: undefined,
  showTimeSelect: false,
  timeIntervals: undefined,
  placeholder: tCommon("label.selectDate"),
};
