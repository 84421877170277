import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  API_DATE_FORMAT,
  HTTP_ENDPOINTS,
  ORDER_TYPES,
  QUERY_KEYS,
} from "../../../../../../consts/api";
import {
  getNextPaginationPage,
  paginationSchema,
  composePaginationSchema,
} from "../../../../../../schemas/pagination";
import {
  removeNullishFromShape,
  unwrapOr,
} from "../../../../../../utilities/general";
import { createQueryKeyFromShape } from "../../../../../../utilities/api";
import { http } from "../../../../../../services/HttpService";

export const SALES_DECLINE_REPORTS_ORDER_BY = {
  clinic: "clinic",
  patient: "patient",
  tier: "tier",
  paymentFrequency: "payment_frequency",
  paymentDate: "payment_datetime",
  reason: "reason",
};

export const SALES_DECLINE_REPORT_PAYMENT_STATUSES = {
  paid: "paid",
  failed: "failed",
};

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  limit: yup.number().nullable(),

  order: yup
    .object({
      by: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) =>
            Object.values(SALES_DECLINE_REPORTS_ORDER_BY).includes(value),
        })
        .required(),

      direction: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) => Object.values(ORDER_TYPES).includes(value),
        })
        .required(),
    })
    .nullable(),

  paymentStatus: yup
    .string()
    .test({
      test: (value) =>
        Object.values(SALES_DECLINE_REPORT_PAYMENT_STATUSES).includes(value),
    })
    .required(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    reports: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          clinicId: yup.number().nullable(),
          clinicName: yup.string().nullable(),
          patientName: yup.string().nullable(),
          tier: yup.string().nullable(),
          tierId: yup.number().nullable(),
          paymentDate: yup.string().nullable(),
          paymentFrequency: yup.string().nullable(),
          reason: yup.string().nullable(),
          yearlyFees: yup.number().nullable(),
          monthlyFees: yup.number().nullable(),
          currency: yup.string().required(),
        }),
      )
      .required(),
  }),
);

/*
  COMPOSERS
*/

const composeResponse = (res, req) => {
  const reports = unwrapOr(() => res.data.data.report.data, []);
  const posData = unwrapOr(() => res.data.global_settings.pos_data, {});

  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data.report, {}),
      pageSize: req.pageSize,
    }),
    reports: reports.map((report) => ({
      id: report.id,
      clinicId: report.clinicId || null,
      clinicName: report.clinic || null,
      patientName: report.patient || null,
      tier: report.tier || null,
      tierId: report.tierId || null,
      paymentDate: report.payment_datetime || null,
      paymentFrequency: report.payment_frequency || null,
      reason: report.reason || null,
      yearlyFees: report.yearly_fees || null,
      monthlyFees: report.monthly_fees || null,
      currency: posData.currency_code,
    })),
  };
};

// ---------

function createMembershipDeclineReportsQueryKey({
  order,
  fromDate,
  toDate,
  limit,
  pageSize,
}) {
  return [
    QUERY_KEYS.salesDeclineReports,
    order,
    fromDate,
    toDate,
    limit,
    pageSize,
  ];
}

const createKeyFromShape = (shape) =>
  createQueryKeyFromShape(removeNullishFromShape(shape));

export function useMembershipDeclineReportsQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createMembershipDeclineReportsQueryKey({
      order: createKeyFromShape(req.order),
      fromDate,
      toDate,
      limit: req.limit,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getMembershipDeclineReport(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          fromDate,
          toDate,
          sortBy: unwrapOr(() => req.order.by, null),
          order: unwrapOr(() => req.order.direction, null),
          payment_status: req.paymentStatus || null,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
