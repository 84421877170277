import moment from "moment";

/**
 * @param {number} monthsCount
 * @returns {{ years: number; months: number }}
 */
export function splitMonthsByYearsAndMonths(monthsCount) {
  const result = {
    years: 0,
    months: 0,
  };
  if (typeof monthsCount === "number" && monthsCount >= 0) {
    result.years = Math.floor(monthsCount / 12);
    result.months = monthsCount % 12;
  }
  return result;
}

/**
 * @param {string | number | Date} date
 * @returns {import("moment").Moment}
 */
export const toExactMoment = (date) => {
  return moment(date, "YYYY-MM-DDTHH:mm:ssZ");
};

/**
 * @param {string | Date} date
 * @returns {{
 *  [key:string]: number[],
 * }}
 */
export function getDateMonthsDayOccurrences(date) {
  if (date) {
    const dateMoment = moment(date);
    const year = dateMoment.year();
    const month = dateMoment.month() + 1;
    const daysCount = dateMoment.daysInMonth();

    return Array.from(Array(daysCount).keys()).reduce((carry, day) => {
      const preparedDay = day + 1;

      const dayName = moment(`${year}-${month}-${preparedDay}`)
        .format("dddd")
        .toLowerCase();

      return {
        ...carry,
        [dayName]: [...(carry?.[dayName] || []), preparedDay],
      };
    }, {});
  }

  return date;
}
