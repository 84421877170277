import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Modal } from "../../../../../../shared/Modal/Modal";
import classes from "./ModalSummary.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Group } from "../../shared/Group/Group";
import { Button } from "../../../../../../shared/Button/Button";
import { Option } from "./shared/Option/Option";
import { useCurrentUserQuery } from "../../../../../../api/queries/useUserQuery";
import { PREVIEW_DATE_FORMAT } from "../../../../../../consts/general";
import { getSummarySectionsVisibilityStatuses } from "./ModalSummary.utils";
import { Empty } from "../../../../../../shared/Empty/Empty";

export function ModalSummary({ isOpen, onClose, onConfirm, intersection }) {
  const { tClients } = useAppTranslation.Clients();
  const { tCommon } = useAppTranslation.Common();
  const { data: currentUser } = useCurrentUserQuery();

  const socialHistory = intersection?.social_history || {};
  const allergy = intersection?.allergy || {};
  const currentMedications = intersection?.current_medication || {};
  const familyHealth = intersection?.family_health_history || {};
  const currentMedConditions = intersection?.current_medical_history || {};
  const pastMedConditions = intersection?.past_medical_history || {};

  const visibilityStatuses = getSummarySectionsVisibilityStatuses(intersection);
  const isNothingVisible = Object.values(visibilityStatuses).every((v) => !v);

  return (
    <Modal
      footerNoBorder
      size="small"
      isOpen={isOpen}
      onClose={onClose}
      contentClassName={classes.root}
      header={
        <Modal.Title>
          {tClients("medicalHistory.merge.summaryLabel")}
        </Modal.Title>
      }
      footer={
        <div className={classes.footer}>
          <Button variant="outlined" onClick={onClose}>
            {tCommon("label.cancel")}
          </Button>
          <Button onClick={onConfirm}>{tCommon("label.continue")}</Button>
        </div>
      }
    >
      {isNothingVisible && (
        <Empty position="center">
          {tClients("medicalHistory.merge.empty")}
        </Empty>
      )}

      {/* Patient info */}

      {visibilityStatuses.patientInfo && (
        <Group
          row
          labelGray
          noPadding
          label={tClients("medicalHistory.patientInfo.label")}
        >
          {Boolean(intersection?.height) && (
            <div className={classes.singleProp}>
              <b>{tCommon("label.height")}:</b>
              {intersection.height}
            </div>
          )}
          {Boolean(intersection?.weight) && (
            <div className={classes.singleProp}>
              <b>{tCommon("label.weight")}:</b>
              {intersection.weight}
            </div>
          )}
        </Group>
      )}

      {/* Social history */}

      {visibilityStatuses.socialHistory && (
        <Group
          labelGray
          noPadding
          label={tClients("medicalHistory.socialHistory.label")}
        >
          {Boolean(socialHistory.tobacco_use) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.socialHistory.tobaccoUse")}:</b>
              {tClients(
                "medicalHistory.merge.socialHistory.tobaccoOptionLabel",
                {
                  packs: socialHistory.tobacco_use_week,
                  years: socialHistory.tobacco_use_year,
                },
              )}
            </div>
          )}
          {Boolean(socialHistory.drug_use) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.socialHistory.drugUse")}:</b>
              {tClients("medicalHistory.merge.socialHistory.drugOptionLabel", {
                times: socialHistory.drug_use_week,
                period: socialHistory.drug_use_period,
                type: socialHistory.drug_use_type,
              })}
            </div>
          )}
          {Boolean(intersection?.social_history?.alcohol_use) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.socialHistory.alcoholUse")}:</b>
              {tClients(
                "medicalHistory.merge.socialHistory.alcoholOptionLabel",
                {
                  times: intersection?.social_history?.alcohol_use_week,
                },
              )}
            </div>
          )}
          {Boolean(intersection?.social_history?.weight) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.socialHistory.weight")}:</b>
              {tClients(
                "medicalHistory.merge.socialHistory.weightOptionLabel",
                {
                  amount: intersection?.social_history?.weight_info,
                  type:
                    intersection?.social_history?.weight === 2
                      ? tCommon("label.gain")
                      : tCommon("label.loss"),
                },
              )}
            </div>
          )}
        </Group>
      )}

      {/* Allergies */}

      {visibilityStatuses.allergies && (
        <Group
          labelGray
          noPadding
          label={tClients("medicalHistory.allergies.label")}
        >
          {Boolean(allergy.drugs) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.allergies.drug")}:</b>
              {allergy.allergy_drug?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tCommon("label.medication"),
                      value: i.medication,
                    },
                    {
                      label: tCommon("label.reaction"),
                      value: i.reaction,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(allergy.foods) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.allergies.food")}:</b>
              {allergy.allergy_food?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tCommon("label.food"),
                      value: i.food,
                    },
                    {
                      label: tCommon("label.reaction"),
                      value: i.reaction,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(allergy.environment) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.allergies.env")}:</b>
              {allergy.allergy_environment?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tCommon("label.environment"),
                      value: i.environment,
                    },
                    {
                      label: tCommon("label.reaction"),
                      value: i.reaction,
                    },
                  ]}
                />
              ))}
            </div>
          )}
        </Group>
      )}

      {/* Current Medications */}

      {visibilityStatuses.currentMedications && (
        <Group
          labelGray
          noPadding
          label={tClients("medicalHistory.currentMedications.label")}
        >
          {Boolean(currentMedications.prescription_medications) && (
            <div className={classes.propCol}>
              <b>
                {tClients("medicalHistory.currentMedications.prescriptions")}:
              </b>
              {currentMedications.prescription_medication?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tCommon("label.medication"),
                      value: i.medication,
                    },
                    {
                      label: tCommon("label.dose"),
                      value: i.dose,
                    },
                    {
                      label: tCommon("label.frequency"),
                      value: i.frequency,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(currentMedications.vitamins) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.currentMedications.vitamins")}:</b>
              {currentMedications.vitamin?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tCommon("label.medication"),
                      value: i.medication,
                    },
                    {
                      label: tCommon("label.dose"),
                      value: i.dose,
                    },
                    {
                      label: tCommon("label.frequency"),
                      value: i.frequency,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(currentMedications.over_medications) && (
            <div className={classes.propCol}>
              <b>
                {tClients("medicalHistory.currentMedications.otcMedications")}:
              </b>
              {currentMedications.over_medication?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tCommon("label.medication"),
                      value: i.medication,
                    },
                    {
                      label: tCommon("label.dose"),
                      value: i.dose,
                    },
                    {
                      label: tCommon("label.frequency"),
                      value: i.frequency,
                    },
                  ]}
                />
              ))}
            </div>
          )}
        </Group>
      )}

      {/* Family Health */}

      {visibilityStatuses.familyHealth && (
        <Group
          labelGray
          noPadding
          label={tClients("medicalHistory.familyHealth.label")}
        >
          <div className={classes.propCol}>
            {familyHealth.medical_issues?.map((i) => (
              <Option
                key={i.id}
                list={[
                  {
                    label: tCommon("label.diagnosis"),
                    value: i.diagnosis,
                  },
                  {
                    label: tClients(
                      "medicalHistory.familyHealth.relationshipToPatient",
                    ),
                    value: i.relationship,
                  },
                ]}
              />
            ))}
          </div>
        </Group>
      )}

      {/* Current Medical Conditions */}

      {visibilityStatuses.currentMedConditions && (
        <Group
          labelGray
          noPadding
          label={tClients("medicalHistory.currentMedConditions.label")}
        >
          {Boolean(
            currentMedConditions.pregnancy === 1 ||
              currentMedConditions.breastfeeding === 1,
          ) && (
            <>
              <div className={classes.singleProp}>
                <b>{tCommon("label.pregnancy")}:</b>
                {currentMedConditions.pregnancy === 1
                  ? tCommon("label.yes")
                  : tCommon("label.no")}
              </div>
              <div className={classes.singleProp}>
                <b>{tCommon("label.breastfeeding")}:</b>
                {currentMedConditions.breastfeeding === 1
                  ? tCommon("label.yes")
                  : tCommon("label.no")}
              </div>
            </>
          )}
          {Boolean(currentMedConditions.ongoing_conditions) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.currentMedConditions.ongoing")}:</b>
              {currentMedConditions.ongoing_condition?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tClients(
                        "medicalHistory.currentMedConditions.diagnosisType",
                      ),
                      value: i.type,
                    },
                    {
                      label: tClients(
                        "medicalHistory.currentMedConditions.diagnosisDate",
                      ),
                      value: moment(i.date).format(
                        currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                      ),
                    },
                    {
                      label: tClients(
                        "medicalHistory.currentMedConditions.physicianName",
                      ),
                      value: i.name,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(currentMedConditions.nutrition) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.currentMedConditions.diets")}:</b>
              {currentMedConditions.nutrition_history || (
                <span className={classes.empty}>{tCommon("label.none")}</span>
              )}
            </div>
          )}
        </Group>
      )}

      {/* Past Medical Conditions */}

      {visibilityStatuses.pastMedConditions && (
        <Group
          labelGray
          noPadding
          label={tClients("medicalHistory.pastMedHistory.label")}
        >
          {Boolean(pastMedConditions.surgeries) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.pastMedHistory.surgeries")}:</b>
              {pastMedConditions.surgery?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.surgeryType",
                      ),
                      value: i.type,
                    },
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.surgeryDate",
                      ),
                      value: moment(i.date).format(
                        currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                      ),
                    },
                    {
                      label: tCommon("label.physician"),
                      value: i.physician,
                    },
                    {
                      label: tCommon("label.city"),
                      value: i.city,
                    },
                    {
                      label: tCommon("label.state"),
                      value: i.state,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(pastMedConditions.hospitalizations) && (
            <div className={classes.propCol}>
              <b>
                {tClients("medicalHistory.pastMedHistory.hospitalizations")}:
              </b>
              {pastMedConditions.hospitalization?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.hospitalizationReason",
                      ),
                      value: i.reason,
                    },
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.hospitalizationDate",
                      ),
                      value: moment(i.date).format(
                        currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                      ),
                    },
                    {
                      label: tCommon("label.hospital"),
                      value: i.hospital,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(pastMedConditions.implants) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.pastMedHistory.implants")}:</b>
              {pastMedConditions.implant?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.implantDate",
                      ),
                      value: moment(i.date).format(
                        currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                      ),
                    },
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.implantType",
                      ),
                      value: i.type,
                    },
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.implantPlace",
                      ),
                      value: i.place,
                    },
                  ]}
                />
              ))}
            </div>
          )}
          {Boolean(pastMedConditions.vaccines) && (
            <div className={classes.propCol}>
              <b>{tClients("medicalHistory.pastMedHistory.vaccines")}:</b>
              {pastMedConditions.vaccine?.map((i) => (
                <Option
                  key={i.id}
                  list={[
                    {
                      label: tClients("medicalHistory.pastMedHistory.vaccine"),
                      value: i.vaccine,
                    },
                    {
                      label: tClients(
                        "medicalHistory.pastMedHistory.vaccinationDate",
                      ),
                      value: moment(i.date).format(
                        currentUser?.previewDateFormat || PREVIEW_DATE_FORMAT,
                      ),
                    },
                  ]}
                />
              ))}
            </div>
          )}
        </Group>
      )}
    </Modal>
  );
}

ModalSummary.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  intersection: PropTypes.object,
};
