import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import {
  getAspireConfig,
  patchAspireConfig,
  saveAspireConfig
} from '../../../Actions/Integrations/aspireIntegrationActions';
import UpgradeAccountModal from '../../Common/UpgradeAccountModal';
import {
  getIsAcceleratorPlan,
  getIsPosEnabled,
  showFormattedDate, showWithoutRounding
} from '../../../Utils/services';
import { Link } from 'react-router-dom';

class AspireIntegration extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.settingsLang = languageData.settings;
    this.globalLang = languageData.global;

    this.state = {
      aspireConfig: {},

      is_active: false,
      is_connected: false,
      mckesson_id: '',
      hcp_email: '',
      updated_at: '',
      products: [],

      showLoader: false,

      isShowUpgradeAlertModal: !getIsAcceleratorPlan(),
    };
  }

  componentDidMount () {
    if (this.isIntegrationToggleEnabled()) {
      this.props.getAspireConfig();
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.aspireConfigData.status !== 'error' && this.props.aspireConfigData.status === 'error') {
      toast.error(this.props.aspireConfigData.error);
    }

    if (prevProps.aspireConfigData.status !== 'success' && this.props.aspireConfigData.status === 'success') {
      this.setState({
        ...this.props.aspireConfigData.aspireConfig,
      });
    }
  }

  static getDerivedStateFromProps (props) {
    if (props.aspireConfigData.invokeRedirect) {
      props.history.push('/settings/profile');
    }
    return {
      showLoader: props.aspireConfigData.status === 'loading',
    };
  }

  handleToggleActive = (event) => {
    this.props.patchAspireConfig({ is_active: event.target.checked });
  };

  handleSubmit = () => {
    this.props.saveAspireConfig({
      is_active: this.state.is_active,
      mckesson_id: this.state.mckesson_id,
      hcp_email: this.state.hcp_email,
    });
  };

  handleToggleEditView = () => {
    this.setState({ is_connected: false });
  };

  handleSearchInInventory (productName) {
    const searchTerm = productName.split('-')[0].trim();
    this.props.history.push(`/inventory/products/active/${searchTerm}`)
  }

  handleMcKessonIdChange = (e) => {
    this.setState({ mckesson_id: e.target.value });
  };

  handleEmailChange = (e) => {
    this.setState({ hcp_email: e.target.value });
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsPosEnabled() && getIsAcceleratorPlan();

  cancel = () => {
    this.props.getAspireConfig();
  };

  connectedView = () => {
    return <div className="membershipSectionContent p5">
      <div className="row">
        <div className="col-xs-12 m-t-20 m-b-15">
          <div className="col-lg-6 col-xs-12">
            <p className="AuthorizedSync">
              <i className="fa fa-check-circle"></i>
              Authorized on {showFormattedDate(this.state.updated_at, true)}
            </p>
            <p className="p-text m-t-15">If you would like to change the settings, please
              click the update button bellow.</p>
            <button className="new-blue-btn pull-left m-t-15 m-b-10 m-l-0"
                    onClick={this.handleToggleEditView}>Update Aspire HCP Settings
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-10">
          <div className="table-responsive">
            <table className="records-table">
              <thead className="table-updated-thead">
              <tr>
                <th className="col-xs-3">Aspire Product</th>
                <th className="col-xs-6">Mapped Products</th>
              </tr>
              </thead>
              <tbody>
              {
                this.state.products && this.state.products.map((obj, idx) => {
                  return (
                    <tr key={idx}>
                      <td className="text-ellipsis">{(obj.product_name)}</td>
                      <td className="text-ellipsis">
                        <ul>
                          {(obj.products && obj.products.length) ?
                            obj.products.map((product) => {
                              return <li key={product.id}><Link
                                to={`/inventory/product/edit/${product.id}`}>{product.product_name}</Link></li>
                            })
                            :
                            <button className="new-blue-btn pull-left m-t-15 m-b-10 m-l-0"
                                    onClick={() => this.handleSearchInInventory(obj.product_name)}>
                              Inventory search</button>
                          }
                        </ul>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>;
  };

  editView = () => {
    const isSaveDisabled = !this.state.hcp_email || !this.state.mckesson_id;
    return <>
      <div className="membershipSectionContent p5">
        <div className="row">
          <div className="col-xs-12 m-t-20 m-b-15">
            <div className="col-lg-6 col-xs-12">
              <div className="newInputFileldOuter">
                <div className="newInputLabel">McKesson ID<span className="setting-require">*</span></div>
                <div className="setting-input-outer">
                  <input
                    name="mcKessonId"
                    className="newInputField"
                    type="text"
                    value={this.state.mckesson_id}
                    autoComplete="off"
                    onChange={this.handleMcKessonIdChange}
                  />
                </div>
              </div>

              <div className="newInputFileldOuter">
                <div className="newInputLabel">Master email address<span className="setting-require">*</span></div>
                <div className="setting-input-outer">
                  <input
                    name="email"
                    className="newInputField"
                    type="text"
                    value={this.state.hcp_email}
                    autoComplete="off"
                    onChange={this.handleEmailChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-static">
        <button className="new-blue-btn pull-right"
                onClick={this.handleSubmit} disabled={isSaveDisabled}>{this.globalLang.label_save}</button>
        <button className="new-white-btn pull-right"
                onClick={this.cancel}>{this.globalLang.label_cancel}</button>
      </div>
    </>;
  };

  render () {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
                             isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
                             handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}/>
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">Aspire HCP Integration
                <div className="memberRightActions">
                  <span className="switch-text"/>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="is_active" id="is_active"
                           disabled={!this.isIntegrationToggleEnabled()}
                           checked={this.state.is_active && this.isIntegrationToggleEnabled()}
                           onChange={this.handleToggleActive}/>
                    <span className="setting-slider"/>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {!getIsPosEnabled() ? (
                <div className="col-sm-12">
                  <div className="setting-setion membershipSectionTitle">
                    <p className="font-weight-6 m-t-15 warning">
                      <i className="fa fa-circle-exclamation"/>
                      In order to use this integration, please activate the AR
                      Point-of-Sale. Once this is completed, you can configure and connect to third party service!</p>
                  </div>
                </div>
              ) : (
                (this.state.is_active === true && this.isIntegrationToggleEnabled()) &&
                <div className="col-sm-12">
                  <div className="setting-setion">
                    <div className="membershipSectionTitle">Aspire HCP Settings</div>
                    <div className="wallet-edit-block">
                      {this.state.is_connected ? this.connectedView() : this.editView()}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  return {
    aspireConfigData: state.AspireIntegrationReducer.aspireConfigData,
  };
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getAspireConfig,
    patchAspireConfig,
    saveAspireConfig,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AspireIntegration);
