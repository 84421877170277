import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
} from "../Utils/services.js";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const salesInstance = axios.create();

salesInstance.defaults.headers.common["access-token"] = getToken();

salesInstance.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status === 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg === "invalid_token" ||
        msg === "session_timeout" ||
        msg === "server_error" ||
        msg === "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

salesInstance.interceptors.response.use(accountStatusResponseInterceptor);

export const fetchCountrySuggestions = (searchText) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUGGESTIONS_LOADING" });
    salesInstance
      .get(
        process.env.REACT_APP_API_URL +
          `countries/suggestion?q=${searchText}&limit=5`,
      )
      .then((response) => {
        dispatch({
          type: "FETCH_SUGGESTIONS_SUCCESS",
          payload: response.data.data,
        });
      })
      .catch(() => {
        dispatch({ type: "FETCH_SUGGESTIONS_ERROR" });
      });
  };
};

export const resetCountrySuggestions = () => {
  return (dispatch) => {
    dispatch({ type: "RESET_SUGGESTIONS_LOADING" });
  };
};
