import React, { useMemo } from "react";
import cx from "clsx";
import styles from "../../ClientProfile/scss/clientProfile.module.scss";
import { Select } from "../../../../shared/Select/Select";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { DateRangePicker } from "../../../../shared/DateRangePicker/DateRangePicker";
import { InputSearch } from "../../../../boxes/InputSearch/InputSearch";
import { useChartingFilterCategories } from "../../../Settings/ManageClinics/ChartFilters/hooks/useChartingFilterCategories";
import { Button } from "../../../../shared/Button/Button";
import { tCommon } from "../../../../i18n/useAppTranslation";

const ProcedureFilters = ({
  period,
  handlePeriod,
  categoryFilter,
  providerFilter,
  searchValue,
  onChangeSearch,
  providersOptions,
  handleProcedureSearch,
  handleResetFilters,
}) => {
  const { data: user } = useCurrentUserQuery();
  const { data: categories } = useChartingFilterCategories();

  const categoriesOptions = useMemo(() => {
    return categories?.map((category) => {
      return { label: category.name, value: category.id };
    });
  }, [categories]);

  return (
    <div className={styles.procedureFilters}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleProcedureSearch(e);
        }}
      >
        <InputSearch
          value={searchValue}
          onChange={(value) => onChangeSearch(value)}
          className={styles.minHeightButtons}
          placeholder="Procedure Name"
        />
      </form>

      <DateRangePicker
        size="small"
        className={styles.procedureDatePicker}
        buttonClassName={cx(styles.minHeightButtons, styles.datePicker)}
        dateFormat={user?.dateFormat}
        onChange={({ startDate, endDate }) =>
          handlePeriod({ startDate, endDate })
        }
        value={{
          startDate: period.startDate,
          endDate: period.endDate,
        }}
      />
      <Select
        size="small"
        options={[{ label: "All Providers", value: "" }, ...providersOptions]}
        className={styles.tabListSelects}
        placeholder="Select Provider"
        value={providerFilter.value}
        onChange={(options) => providerFilter.update(options)}
        isSearchable
      />
      <Select
        size="small"
        options={[
          { label: "All Categories", value: "" },
          ...(categoriesOptions || []),
        ]}
        className={styles.tabListSelects}
        placeholder="Select Category"
        value={categoryFilter.value}
        onChange={(options) => categoryFilter.update(options)}
      />
      <Button
        size="small"
        className={cx(styles.minHeightButtons)}
        onClick={handleResetFilters}
      >
        {tCommon("label.resetFilters")}
      </Button>
    </div>
  );
};

export default ProcedureFilters;
