import parsePhoneNumber, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "libphonenumber-js";

class PhoneUtil {
  /**
   * @template {string} T
   * @param {T} code
   * @returns {string | T}
   */
  onlyDigits = (phone) => {
    if (typeof phone === "string") {
      return phone.replace(/\D/g, "");
    }
    return phone;
  };

  /**
   * @template {string | number} T
   * @param {T} code
   * @returns {string | T}
   */
  composeDialCode = (code) => {
    if (typeof code === "string" || typeof code === "number") {
      return `+${this.onlyDigits(String(code))}`;
    }
    return code;
  };

  /**
   * @template {string | number} T
   * @param {T} dialCode
   * @param {T} phone
   * @returns {string | null}
   */
  composeWithDialCode = (dialCode, phone) => {
    if (
      [dialCode, phone].every(
        (arg) => typeof arg === "string" || typeof arg === "number",
      )
    ) {
      const composedDialCode = this.composeDialCode(dialCode);
      const withoutDialCode = this.onlyDigits(
        `+${this.onlyDigits(phone)}`.replace(composedDialCode, ""),
      );
      return `${composedDialCode}${withoutDialCode}`;
    }
    return null;
  };

  /**
   * @template {string} T
   * @param {T} phone
   * @returns {string | T}
   */
  formatWithDashes = (phone) => {
    if (typeof phone === "string" && isValidPhoneNumber(phone)) {
      const parsed = parsePhoneNumber(phone);
      const code = `+${parsed.countryCallingCode}`;
      return parsed
        .formatInternational()
        .replace(/\s+/g, "-")
        .replace(`${code}-`, code);
    }
    return phone;
  };

  /**
   * @template {string} T
   * @param {T} phone
   * @returns {string | null}
   */
  extractDialCode = (phone) => {
    if (typeof phone === "string") {
      const parsed = parsePhoneNumber(phone);
      if (parsed) {
        return parsed.countryCallingCode;
      }
    }
    return null;
  };

  /**
   * @template {string} T
   * @param {T} phone
   * @returns {string | T}
   */
  withPrefix = (phone) => {
    const PREFIX = "+";
    if (
      typeof phone === "string" &&
      phone.trim().length > 0 &&
      phone[0] !== PREFIX
    ) {
      return `${PREFIX}${phone}`;
    }
    return phone;
  };

  /**
   * @param {string | number} phone
   * @param {string} code
   * @returns {boolean}
   */
  isValid = (phone, code) => {
    return isValidPhoneNumber(String(phone), code);
  };

  /**
   * @param {string | number} phone
   * @returns {boolean}
   */
  isPossible = (phone) => {
    return isPossiblePhoneNumber(String(phone));
  };

  /**
   * @template {string} T
   * @param {T} phone
   * @returns {string | T}
   */
  compose = (phone) => {
    return this.withPrefix(this.onlyDigits(phone));
  };
}

export const phoneUtil = new PhoneUtil();
