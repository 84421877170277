import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Loader from '../../../../Components/Common/Loader.js'
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import {
  deleteQuestion,
  getQuestionById,
  getQuestionnaireById,
  createQuestionnaire,
  createQuestion,
  uploadImage,
  updateQuestion,
  deleteQuestionnaire,
  updateQuestionnaire,
  updateSortOrder,
  exportEmptyData
} from "../../../../Actions/Settings/settingsActions.js";
import {
  accountStatusResponseInterceptor
} from "../../../AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { Button } from "../../../../shared/Button/Button.js";
import { AssignServicesModal } from "../components/AssignServicesModal.js";
import { ASSIGN_TO_SERVICES_MODEL_TYPE } from "../../../../api/mutations/useAssignServiceToModelMutation.js";
import { SERVICES_BY_CATEGORIES_MODEL_TYPE } from "../../../../api/queries/useServicesGroupedByCategoriesQuery.js";

const questionnaireInstance = axios.create();
questionnaireInstance.interceptors.response.use(accountStatusResponseInterceptor);
questionnaireInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (!error.response) {
      return { data: { data: "", message: "file_type_error", status: 400 } };
    }
  }
);

const initMuiltiText = () => {
  return { id:'', text: "", divClass: "choice-question-row" }
}

const initMuiltiImage = () => {
  return  { id:'', image: "", image_label: "", divClass: "choice-question-row" }
}

class CreateQuestionnaries extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pictures: [],
      consultation_title: "",
      userChanged: false,
      questionnaireData: {},
      question: "",
      question_type: "yesno",
      div_display_text: "row add-questions-multi-text no-margin no-display ",
      div_display_image: "row add-questions-multi-text no-margin no-display",
      div_edit_display_text: "row edit-questions-multi-text no-margin",
      div_edit_display_image: "row edit-questions-multi-images no-margin",
      itemArray: [],
      questionId: "",
      yes_no: "yesno",
      multi_text: "multitext",
      multi_images: "multiimage",
      multiTextArr: [initMuiltiText()],
      multiImageArr: [initMuiltiImage()],
      text: "",
      question_data: [],
      multiple_selection: 1,
      display_labels: 1,
      file: "",
      image_label: "",
      isDisabled: true,
      isEditDisabled: true,
      questionnaires: "",
      edit_question_type: "",
      edit_multiple_selection: "1",
      edit_question: "",
      showLoader: false,
      multiple_selection_image: "1",
      display_labels: 1,
      sortedListIds: [],
      editMultiTextArr: [initMuiltiText()],
      editMultiImageArr: [initMuiltiImage()],
      uploadingImageIndex : null,
      uploadingImageArray : null,
      isAssignToServicesModalOpen: false,
      assignedServiceIds: [],
    };
    window.onscroll = () => {
      return false;
    };
  }

  showDeleteModal = event => {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let question_id = event.target.dataset.id;
    this.setState({
      showModal: true,
      deleteId: question_id,
      delete_warning: languageData.settings["question_delete_warning"]
    });
  };

  deleteEditRow = e => {
    this.setState({ showLoader: true });
    this.props.getQuestionnaireById(this.state.qId);
  };

  showQuestionnaireDeleteModal = event => {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let question_id = event.target.dataset.id;
    this.setState({
      showModal: true,
      deleteId: question_id,
      delete_warning: languageData.settings["questionnaire_delete_warning"]
    });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deleteQuestion = () => {
    this.setState({ showLoader: true, hideBtns: true });
    this.dismissModal();
    let cId = this.state.deleteId;
    if (cId) {
      this.props.deleteQuestion(this.state.qId, cId);
    } else {
      this.props.deleteQuestionnaire(this.state.qId);
    }
  };

  createProject = () => {
    const item = this.state.itemArray;
    const question = this.state.question;
    const question_type = this.state.question_type;
    let new_managed_state = [...this.state.multiTextArr];
    if (question.trim() == "") {
      this.setState({ question_Error: true });
      return;
    } else {
      this.setState({ question_Error: false });
    }
    let questionObj = {};
    questionObj.question = question;
    questionObj.question_type = question_type;

    if (question_type == "multitext") {
      let multiTextArr = this.state.multiTextArr;
      let error = false;
      if (multiTextArr.length) {
        multiTextArr.map((obj, idx) => {
          if (obj.text != undefined && obj.text.trim() != "") {
            multiTextArr[idx]['divClass'] = 'choice-question-row'
          } else {
            multiTextArr[idx]['divClass'] = 'choice-question-row field_error'
            error = true;
          }
        });
        this.setState({multiTextArr:multiTextArr});
        if (error) {
          return false;
        }
        questionObj.multiple_selection = this.state.multiple_selection;
        questionObj.multi_text_data =  multiTextArr;
      }
    } else if (question_type == "multiimage") {
      let multiImageArr = this.state.multiImageArr;
      let error = false;
      if (multiImageArr.length) {
        multiImageArr.map((obj, idx) => {
          if (obj.image != undefined && obj.image.trim() != "") {
            multiImageArr[idx]['divClass'] = 'choice-question-row'
          } else {
            multiImageArr[idx]['divClass'] = 'choice-question-row field_error'
            error = true;
          }
          if(this.state.display_labels == 1){
            if (obj.image_label != undefined && obj.image_label.trim() != "") {
              multiImageArr[idx]['divClass'] = 'choice-question-row'
            } else {
              multiImageArr[idx]['divClass'] = 'choice-question-row field_error'
              error = true;
            }
          }
        });
        this.setState({multiImageArr:multiImageArr});
        if (error) {
          return false;
        }
        questionObj.multi_image_data = multiImageArr;
        questionObj.multiple_selection = this.state.multiple_selection_image;
        questionObj.display_labels = this.state.display_labels;
      }
    } else {
      questionObj.multiple_selection = 0;
    }
//return false;
    if (this.state.qId) {
      this.setState({
        itemArray: item,
        multiTextArr: [initMuiltiText()],
        multiImageArr: [initMuiltiImage()],
        question: "",
        question_type: "yesno",
        isDisabled: false,
        div_display_image: "row  add-questions-multi-text no-margin no-display",
        div_display_text: "row add-questions-multi-text no-margin no-display",
        question_Error: false,
        multiple_selection: 1,
        showLoader: true,
        multiple_selection_image: "1",
        display_labels: "1"
      });
      this.props.createQuestion(questionObj, this.state.qId);
    } else {
      item.push(questionObj);
      this.setState({
        itemArray: item,
        multiTextArr: [initMuiltiText()],
        multiImageArr: [initMuiltiImage()],
        question: "",
        question_type: "yesno",
        isDisabled: false,
        div_display_image: "row  add-questions-multi-text no-margin no-display",
        div_display_text: "row add-questions-multi-text no-margin no-display",
        question_Error: false,
        multiple_selection: 1,
        multiple_selection_image: "1",
        display_labels: "1"
      });
    }
  };

  deleteItem = event => {
    const item = this.state.itemArray;
    const id = event.target.dataset.id;
    item.splice(id, 1);
    this.setState({ itemArray: item });
  };

  editItem = event => {
    const id = event.target.dataset.id;
    this.setState({ edit_question_id: id, showLoader: true });
    this.props.getQuestionById(this.state.qId, id);
  };

  deleteTextOption = event => {
    if (this.state.multiTextArr.length > 1) {
      const multiTextArr = this.state.multiTextArr;
      const index = event.target.dataset.optionid;
      multiTextArr.splice(index, 1);
      this.setState({multiTextArr:multiTextArr});
    }
  };

  deleteImageOption = event => {
    if (this.state.multiImageArr.length > 1) {
      const multiImageArr = this.state.multiImageArr;
      const index = event.target.dataset.optionid;
      multiImageArr.splice(index, 1);
      this.setState({multiImageArr:multiImageArr});
    }
    return false;
    let q = {};
    let returnState = {}
    let item = this.state.multiImageArr;

    const id = event.target.dataset.optionid;
    if (item.length == 1) {
      return false;
    }
    //delete item[id];

    item.splice(id, 1);
    for(let x in this.state) {
      if(x.startsWith('image_')) {
        delete this.state[x];
      }
    }

    returnState = this.state
    /*if (this.state["image_" + id])
      delete returnState["image_" + id];

    if (this.state["image_label_" + id])
      delete returnState["image_label_" + id];
    //let arr = this.state.multiTextArr;*/
    item.map((obj, idx) => {
      returnState["image_" + idx] = obj.image;
      returnState["image_label_" + idx] = obj.image_label;
    });
    returnState.multiImageArr = item;
    this.setState(returnState);
  };

  deleteEditTextOption = event => {
    if (this.state.editMultiTextArr.length > 1) {
      const index = event.target.dataset.index;
      const editMultiTextArr = this.state.editMultiTextArr;
      editMultiTextArr.splice(index, 1);
      this.setState({editMultiTextArr:editMultiTextArr})
    }
  };

  deleteEditImageOption = event => {
    if (this.state.editMultiImageArr.length > 1) {
      const index = event.target.dataset.index;
      const editMultiImageArr = this.state.editMultiImageArr;
      editMultiImageArr.splice(index, 1);
      this.setState({editMultiImageArr:editMultiImageArr})
    }
    return false
    let q = {};
    let returnState = {};
    let item = this.state.edit_choices;
    if (item.length == 1) {
      return false;
    }
    let id = event.target.dataset.optionid;
    //delete item[id];

    item.splice(id, 1);

    for(let x in this.state) {
      if(x.startsWith('edit_image_')) {
        delete this.state[x];
      }
    }

    //let arr = this.state.multiTextArr;
    item.map((obj, idx) => {
      returnState["edit_image_" + idx] = obj.image;
      returnState["edit_image_label_" + idx] = obj.image_label;
    });
    returnState.edit_choices = item;
    this.setState(returnState);
  };

  addMultiTextOption = () => {
    let multiTextArr = this.state.multiTextArr;
    let error = false;
    if (multiTextArr.length) {
      multiTextArr.map((obj, idx) => {
        if (obj.text != undefined && obj.text.trim() != "") {
          multiTextArr[idx]['divClass'] = 'choice-question-row'
        } else {
          multiTextArr[idx]['divClass'] = 'choice-question-row field_error'
          error = true;
        }
      });
      if (!error) {
        multiTextArr.push(initMuiltiText());
      }
      this.setState({multiTextArr:multiTextArr});
    }
  };

  editMultiTextOption = () => {
    let editMultiTextArr = this.state.editMultiTextArr;
    let error = false;
    if (editMultiTextArr.length) {
      editMultiTextArr.map((obj, idx) => {
        if (obj.text != undefined && obj.text.trim() != "") {
          editMultiTextArr[idx]['divClass'] = 'choice-question-row'
        } else {
          editMultiTextArr[idx]['divClass'] = 'choice-question-row field_error'
          error = true;
        }
      });
      if (!error) {
        editMultiTextArr.push(initMuiltiText());
      }
      this.setState({editMultiTextArr:editMultiTextArr});
    }
  };

  addMultiImageOption = () => {
    let multiImageArr = this.state.multiImageArr;
    let error = false;
    if (multiImageArr.length) {
      multiImageArr.map((obj, idx) => {
        if (obj.image != undefined && obj.image.trim() != "") {
          multiImageArr[idx]['divClass'] = 'choice-question-row'
        } else {
          multiImageArr[idx]['divClass'] = 'choice-question-row field_error'
          error = true;
        }
        if(this.state.display_labels == 1){
          if (obj.image_label != undefined && obj.image_label.trim() != "") {
            multiImageArr[idx]['divClass'] = 'choice-question-row'
          } else {
            multiImageArr[idx]['divClass'] = 'choice-question-row field_error'
            error = true;
          }
        }
      });
      if (!error) {
        multiImageArr.push(initMuiltiImage());
      }
      this.setState({multiImageArr:multiImageArr});
    }
  };

  editMultiImageOption = () => {
    let editMultiImageArr = this.state.editMultiImageArr;
    let error = false;
    if (editMultiImageArr.length) {
      editMultiImageArr.map((obj, idx) => {
        if (obj.image != undefined && obj.image.trim() != "") {
          editMultiImageArr[idx]['divClass'] = 'choice-question-row'
        } else {
          editMultiImageArr[idx]['divClass'] = 'choice-question-row field_error'
          error = true;
        }
        if(this.state.edit_display_labels == 1){
          if (obj.image_label != undefined && obj.image_label.trim() != "") {
            editMultiImageArr[idx]['divClass'] = 'choice-question-row'
          } else {
            editMultiImageArr[idx]['divClass'] = 'choice-question-row field_error'
            error = true;
          }
        }
      });
      if (!error) {
        editMultiImageArr.push(initMuiltiImage());
      }
      this.setState({editMultiImageArr:editMultiImageArr});
    }
    return false
    let arr = this.state.edit_choices,
      err = false,
      errArr = {},
      returnState = {};

    if (arr.length) {
      arr.map((obj, idx) => {
        if (
          this.state["edit_image_" + idx] != undefined &&
          this.state["edit_image_" + idx].trim() != ""
        ) {
          if (
            this.state["edit_image_label_" + idx] != undefined &&
            this.state["edit_image_label_" + idx].trim() != ""
          ) {
            arr[idx].image = this.state["edit_image_" + idx];
            arr[idx].image_label = this.state["edit_image_label_" + idx];
            err = true;
            returnState["multiEditImageError_" + idx] = false;
          } else {
            returnState["multiEditImageError_" + idx] = true;
            err = false;
          }
        } else {
          returnState["multiEditImageError_" + idx] = true;
          err = false;
        }
      });

      if (Object.keys(errArr).length != 0) {
        this.setState(returnState);
        return;
      }
      if (err) {
        arr.push(initMuiltiImage());
      }
    }
    returnState.multiImageArr = arr;
    this.setState(returnState);
  };

  createDiv = () => {
    const divItem = this.state.multiTextArr;
    const text = this.state.text;
    divItem.push({ text });
    this.setState({ multiTextArr: divItem });
  };

  componentDidMount() {
    this.props.exportEmptyData()
    window.onscroll = () => {
      return false;
    }
    const qId = this.props.match.params.id;
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      add_questionnaire: languageData.settings["add_questionnaire"],
      view_questionnaire: languageData.settings["view_questionnaire"],
      create_questionnaire: languageData.settings["create_questionnaire"],
      add_new_question: languageData.settings["add_new_question"],
      questionnaire_name: languageData.settings["questionnaire_name"],
      question_data: languageData.settings["question_data"],
      question_type_data: languageData.settings["question_type_data"],
      question_choices: languageData.settings["question_choices"],
      select_yes: languageData.settings["select_yes"],
      select_yes_images: languageData.settings["select_yes_images"],
      question_choices_image: languageData.settings["question_choices_image"],
      settings_question:languageData.settings["settings_question"],
      question_choice_lable: languageData.settings["question_choice_lable"],
      no_option: languageData.settings["no_option"],
      yes_option: languageData.settings["yes_option"],
      showLoader: qId ? true : false,
      qId: qId ? qId : undefined,
      yes_option: languageData.settings["yes_option"],
      no_option: languageData.settings["no_option"],
      delete_warning: "",
      delete_confirmation:languageData.global['delete_confirmation'],
      questionnaire_Label: languageData.settings['questionnaire_Label'],
      Questionnaire_Choose_File: languageData.settings['Questionnaire_Choose_File'],
      questionnaire_Add:  languageData.settings['questionnaire_Add'],
      editUsers_Drop_Files_To_Upload: languageData.settings['editUsers_Drop_Files_To_Upload'],
      yes_option: languageData.settings['yes_option'],
      no_option: languageData.settings['no_option'],
      questionnaire_No_file:  languageData.settings['questionnaire_No_file'],
      question_choices:  languageData.settings['question_choices'],
      questionnaire_Add:  languageData.settings['questionnaire_Add'],
      select_yes:  languageData.settings['select_yes'],
      select_yes_images:  languageData.settings['select_yes_images'],
      questionnaire_Edit_Question: languageData.settings['questionnaire_Edit_Question'],
      question_type_data:  languageData.settings['question_type_data'],
      questionnaire_Multiple_Selection_Text:  languageData.settings['questionnaire_Multiple_Selection_Text'],
      questionnaire_Multiple_Selection_Images:  languageData.settings['questionnaire_Multiple_Selection_Images'],
      editUsers_CancelBtn:  languageData.settings['editUsers_CancelBtn'],
      questionnaire_Question:  languageData.settings['questionnaire_Question'],
      questionnaire_Update:  languageData.settings['questionnaire_Update'],
      questionnaire_A: languageData.settings['questionnaire_A'],
      questionnaire_Questions: languageData.settings['questionnaire_Questions'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],
      edit_Questionnaire: languageData.settings['edit_Questionnaire'],
      settings_view_questionnaire: languageData.settings['settings_view_questionnaire'],

    });

    if (qId) this.props.getQuestionnaireById(qId);
  }

  resetStatevalues = () => {
    let new_managed_state = [...this.state.multiTextArr];
    const new_text_state_data = {};
    new_managed_state.map((obj, index) => {
       new_text_state_data[`text_${index}`] = '';
    });

    this.setState(new_text_state_data);
  }
  cancelQuestion = () => {
    this.resetStatevalues();
    this.setState({
      multiTextArr: [initMuiltiText()],
      question: "",
      question_type: "yesno",
      isDisabled: false,
      div_display_image: "row  add-questions-multi-text no-margin no-display",
      div_display_text: "row 00-questions-multi-text no-margin no-display",
      question_Error: false,
      multiple_selection: 1
    });
  };

  static getDerivedStateFromProps(props, state) {
    if(props.sortedListIds && props.sortedListIds.length > 0){
      let itemArray = props.sortedListIds.map((obj, idx) => {
        const item = state.itemArray.filter(x => x.id === obj)
        if (item.length === 1) {
          return item[0];
        }
      });
      return {itemArray : itemArray}

    }
    if(props.showLoader) {
        props.exportEmptyData()
        return {showLoader : false};
    }
    if (
      props.questionnaireData !== undefined &&
      (props.questionnaireData.status === 200 ||
        props.questionnaireData.status === 201) &&
      props.questionnaireData.data != state.questionnaireData &&
      props.questionnaireDataTimeStamp != state.questionnaireDataTimeStamp
    ) {
      props.exportEmptyData()
      var itemArray = [];
      var questions = props.questionnaireData.data.questions;
      if (questions.length) {
        questions.map((obj, idx) => {
          itemArray.push({
            question: obj.question,
            question_type: obj.question_type,
            id: obj.id
          });
        });
      }
      return {
        deleteId: (state.userChanged) ? state.deleteId : null,
        questionnaireDataTimeStamp: props.questionnaireDataTimeStamp,
        edit_question_id: null,
        questionData: undefined,
        edit_choices: [],
        editQuestionId: null,
        showLoader: false,
        itemArray: itemArray,
        consultation_title: state.userChanged
          ? state.consultation_title
          : props.questionnaireData.data.consultation_title,
        questionnaireData: state.userChanged
          ? state.questionnaireData
          : props.questionnaireData.data,
        id: state.userChanged
          ? state.userChanged
          : props.questionnaireData.data.id,
        questionnaires: state.userChanged
          ? state.userChanged
          : props.questionnaireData.data.questionnaires,
        multiTextArr: state.userChanged ? state.multiTextArr : [initMuiltiText()],
        question: state.userChanged ? state.question : "",
        question_type: state.userChanged ? state.question_type : "yesno",
        //isDisabled : false,
        div_display_image: state.userChanged
          ? state.div_display_image
          : "row  add-questions-multi-text no-margin no-display",
        div_display_text: state.userChanged
          ? state.div_display_text
          : "row add-questions-multi-text no-margin no-display",
        question_Error: state.userChanged ? state.question_Error : false,
        multiple_selection: state.userChanged ? state.multiple_selection : 1,
        assignedServiceIds: props.questionnaireData.data.services_ids,
      };
    }
    if (
      props.questionData !== undefined &&
      props.questionData.status === 200 &&
      props.questionData.data != state.questionData &&
      props.questionDataTimeStamp != state.questionDataTimeStamp
    ) {
      props.exportEmptyData()
      let edit_choices = [];
      let choicesText = {};
      choicesText.editMultiTextArr = [initMuiltiText()];
      choicesText.editMultiImageArr = [initMuiltiImage()];
      if (props.questionData.data.question_type == "multitext") {
        if (props.questionData.data.question_choices) {
          let editMultiTextArr = []
          props.questionData.data.question_choices.map((obj, idx) => {
            let choice = initMuiltiText();
            choice.id = obj.id
            choice.text = obj.text
            editMultiTextArr.push(choice);
          });
          choicesText.editMultiTextArr = editMultiTextArr
        }
      } else if (props.questionData.data.question_type == "multiimage") {
        if (props.questionData.data.question_choices) {
          let editMultiImageArr = []
          props.questionData.data.question_choices.map((obj, idx) => {
            let choice = initMuiltiImage();
            choice.id = obj.id
            choice.image = obj.image
            choice.image_label = obj.image_label
            editMultiImageArr.push(choice);
          });
          choicesText.editMultiImageArr = editMultiImageArr
        }
      }
      if (props.questionData.data.question_choices.length) {
        choicesText.edit_multiple_selection =
          props.questionData.data.question_choices[0].multiple_selection;
        choicesText.edit_display_labels =
          props.questionData.data.question_choices[0].display_labels;
      }

      choicesText.showLoader = false;
      choicesText.edit_choices = edit_choices;
      choicesText.questionData = props.questionData.data;
      choicesText.edit_question = props.questionData.data.question;
      choicesText.edit_question_type = props.questionData.data.question_type;
      choicesText.edit_question_id = props.questionData.data.id;
      choicesText.questionDataTimeStamp = props.questionDataTimeStamp;
      return choicesText;
    }
    if (props.redirect != undefined && props.redirect == true) {

          toast.success(props.message)

      props.history.push("/settings/questionnaires");
    }
     else return null;
  }

  handleInputChange = event => {
    const target = event.target;
    let value = target.value;
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
      case "file": {
        value = target.files[0];
        this.setState({ showLoader: true, uploadingImageIndex : target.dataset.index, uploadingImageArray : target.name });
        this.handleUpload(event.target.files[0], event.target.name, target.dataset.index, target.name);
      }
    }
    let qType = {};
    if (target.name == "question_type") {
      qType[event.target.name] = value;
      if (value === "yesno") {
        qType.isDisabled = false;
        qType.div_display_text =
          "row add-questions-multi-text no-margin no-display ";
        qType.div_display_image =
          "row  add-questions-multi-text no-margin no-display";
      } else if (value === "multitext") {
        qType.isDisabled = false;
        qType.div_display_text =
          "row add-questions-multi-text no-margin display ";
        qType.div_display_image =
          "row  add-questions-multi-text no-margin no-display ";
      } else if (value === "multiimage") {
        qType.isDisabled = false;
        qType.div_display_image =
          "row  add-questions-multi-text no-margin display";
        qType.div_display_text =
          "row add-questions-multi-text no-margin no-display ";
      }
    } else if (target.name == "edit_question_type") {
      qType[event.target.name] = value;
      if (value === "yesno") {
        qType.isEditDisabled = false;
        qType.div_edit_display_text =
          "row edit-questions-multi-text no-margin no-display ";
        qType.div_edit_display_image =
          "row  edit-questions-multi-text no-margin no-display";
      } else if (value === "multitext") {
        //if(this.state.questionData.question_choices.length == 0) {
        qType.questionData = this.state.questionData;
        qType.questionData.question_choices = [{ multiple_selection: 1 }];
        qType.questionData.multiple_selection = 1;
        qType.questionData.question_type = "multitext";
        //}
        qType.isEditDisabled = false;
        qType.div_edit_display_text =
          "row edit-questions-multi-text no-margin display ";
        qType.div_edit_display_image =
          "row  edit-questions-multi-text no-margin no-display ";
      } else if (value === "multiimage") {
        qType.isEditDisabled = false;
        qType.questionData = this.state.questionData;
        qType.div_edit_display_image =
          "row  edit-questions-multi-text no-margin display";
        qType.div_edit_display_text =
          "row edit-questions-multi-text no-margin no-display ";
        qType.edit_multiple_selection = 1;
        qType.questionData.question_type = "multiimage";
        qType.edit_display_labels = "1";
      }
    } else if (target.name == "multiTextArr") {
      const dataset = target.dataset;
      let multiTextArr = this.state.multiTextArr
      multiTextArr[dataset.index]['text'] = value
      qType.multiTextArr = multiTextArr
    } else if (target.name == "editMultiTextArr") {
      const dataset = target.dataset;
      let editMultiTextArr = this.state.editMultiTextArr
      editMultiTextArr[dataset.index]['text'] = value
      qType.editMultiTextArr = editMultiTextArr
    } else if (target.name == "multiImageArr") {
      const dataset = target.dataset;
      if(dataset.name == 'image_label'){
        let multiImageArr = this.state.multiImageArr
        multiImageArr[dataset.index]['image_label'] = value
        qType.multiImageArr = multiImageArr
      }
    } else if (target.name == "editMultiImageArr") {
      const dataset = target.dataset;
      if(dataset.name == 'image_label'){
        let editMultiImageArr = this.state.editMultiImageArr
        editMultiImageArr[dataset.index]['image_label'] = value
        qType.editMultiImageArr = editMultiImageArr
      }
    } else if (target.name.startsWith("text_")) {
      let arr = this.state.multiTextArr;
      let currentId = target.name.split("_")[1];

      arr.map((obj, idx) => {
        if (currentId != idx) {
          arr[idx].text = this.state["text_" + idx];
        } else {
          arr[idx].text = value;
        }
      });
      qType.multiTextArr = arr;
    } else if (target.name.startsWith("image_label_")) {
      let arr = this.state.multiImageArr;
      let currentId = target.name.split("_")[2];
      arr.map((obj, idx) => {
        if (currentId != idx) {
          arr[idx].image = obj.image;
          arr[idx].image_label = obj.image_label;
        } else {
          arr[idx].image_label = value;
        }
      });
      qType.multiImageArr = arr;
    } else if (target.name.startsWith("edit_text_")) {
      let arr = this.state.edit_choices;
      let currentId = target.name.split("_")[2];

      arr.map((obj, idx) => {
        if (currentId != idx) {
          arr[idx].text = this.state["edit_text_" + idx];
        } else {
          arr[idx].text = value;
        }
      });
      qType.edit_choices = arr;
    } else if (target.name.startsWith("edit_image_label")) {
      let arr = this.state.edit_choices;
      let currentId = target.name.split("_")[3];

      arr.map((obj, idx) => {
        if (currentId != idx) {
          arr[idx].image = this.state["edit_image_" + idx];
          arr[idx].image_label = this.state["edit_image_label_" + idx]
            ? this.state["edit_image_label_" + idx]
            : "";
        } else {
          arr[idx].image_label = value;
        }
      });
      qType.edit_choices = arr;
    } else {
      if (target.type != "file") {
        qType[event.target.name] = value;
      }
    }


    qType.userChanged = true;
    this.setState(qType);
  };

  handleUpload = (file, name, uploadingImageIndex, uploadingImageArray) => {
    const data = new FormData();
    data.append("file", file, file.name);
    let endpoint = process.env.REACT_APP_API_URL + `media/upload?upload_type=questionnaires`;
    axios
      .post(endpoint, data)
      .then(res => {
        if(res && res.data && res.data.data && res.data.data.file_name){
          let fileName = res.data.data.file_name;
          const imageArray = this.state[uploadingImageArray]
          if(imageArray){
            imageArray[uploadingImageIndex]['image'] = fileName
            this.setState({[uploadingImageArray] : imageArray, uploadingImageIndex : null, uploadingImageArray : null, showLoader: false})
          }
        } else {
          let languageData = JSON.parse(localStorage.getItem("languageData"));
          toast.error(languageData.global[(res.data.data.message) ? res.data.data.message : res.data.message]);
          this.setState({showLoader: false})
        }
      })
      .catch(error => {
        let languageData = JSON.parse(localStorage.getItem("languageData"));
        toast.error(languageData.global["file_type_error"]);
        this.setState({showLoader: false})
      });
  };

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  getListStyle = isDraggingOver => {
    return {
      background: isDraggingOver ? "lightblue" : "lightgrey"
    }
  };

  getItemStyle = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
      background: isDragging ? "#f7fbfd" : "#ffffff",
      ...draggableStyle
    };
  };

  getListStyleChild = isDraggingOver => {
    return {
      background: isDraggingOver ? "#F7FBFD" : "#F7FBFD"
    }
  };

  getItemStyleChild = (isDragging, draggableStyle) => {
    return {
      userSelect: "none",
 background: isDragging ? "#f7fbfd" : "#f7fbfd",
 display: "inline-flex",
 width:'100%',
 padding: isDragging ? "10px" : '0',
      ...draggableStyle
    };
  };

  reOrderList = list => {
    let formData = {
      object_ids: list
    };
    this.props.updateSortOrder(formData, "Question");
  };

  handleSubmit = (event, questionId) => {
    let error = false;
    this.setState({
      consultation_title_Error: false,
      question_Error: false,
      question_type_Error: false
    });

    if (
      !this.state.consultation_title ||
      this.state.consultation_title.trim() == ""
    ) {
      this.setState({ consultation_title_Error: true });
      error = true;
    }
    if (error === true) {
      return;
    }

    let formData = {};
    formData.consultation_title = this.state.consultation_title;

    if (
      this.state.itemArray.length ||
      this.state.consultation_title.trim() == ""
    ) {
      formData.question_data = this.state.itemArray;
    }
    if (this.state.qId) {
      this.props.updateQuestionnaire(formData, this.state.qId);
    } else {
      this.setState({showLoader: true})
      this.props.createQuestionnaire(formData);
    }
  };

  updateQuestion = () => {
    let formData = {};
    let returnState = {};
    if (
      this.state.edit_question == "" ||
      this.state.edit_question == undefined
    ) {
      this.setState({ editQuestionError: true });
      return false;
    }

    // Prepare formData
    formData.question = this.state.edit_question;
    formData.question_type = this.state.edit_question_type;

    if (this.state.edit_question_type == "yesno") {
      formData.multiple_selection = 0;
      formData.display_labels = 0;
    } else if (this.state.edit_question_type == "multitext") {
      let editMultiTextArr = this.state.editMultiTextArr;
      let error = false;
      if (editMultiTextArr.length) {
        editMultiTextArr.map((obj, idx) => {
          if (obj.text != undefined && obj.text.trim() != "") {
            editMultiTextArr[idx]['divClass'] = 'choice-question-row'
          } else {
            editMultiTextArr[idx]['divClass'] = 'choice-question-row field_error'
            error = true;
          }
        });
        this.setState({editMultiTextArr:editMultiTextArr});
        if (error) {
          return false;
        }
        formData.multi_text_data = editMultiTextArr;
        formData.multiple_selection = this.state.edit_multiple_selection;
        formData.display_labels = 0;
      }
    } else {

      let editMultiImageArr = this.state.editMultiImageArr;
      let error = false;
      if (editMultiImageArr.length) {
        editMultiImageArr.map((obj, idx) => {
          if (obj.image != undefined && obj.image.trim() != "") {
            editMultiImageArr[idx]['divClass'] = 'choice-question-row'
          } else {
            editMultiImageArr[idx]['divClass'] = 'choice-question-row field_error'
            error = true;
          }
          if(this.state.edit_display_labels == 1){
            if (obj.image_label != undefined && obj.image_label.trim() != "") {
              editMultiImageArr[idx]['divClass'] = 'choice-question-row'
            } else {
              editMultiImageArr[idx]['divClass'] = 'choice-question-row field_error'
              error = true;
            }
          }
        });
        this.setState({editMultiImageArr:editMultiImageArr});
        if (error) {
          return false;
        }
        formData.multi_image_data = editMultiImageArr;
        formData.multiple_selection = this.state.edit_multiple_selection;
        formData.display_labels = this.state.edit_display_labels;
      }
    }
    returnState.showLoader = true;
    this.setState(returnState);
    this.props.updateQuestion(
      formData,
      this.state.qId,
      this.state.edit_question_id
    );
  };

  handleSelect = event => {
    if (this.state.question_type === "yesno") {
      this.setState({
        isDisabled: false,
        div_display_text: "row add-questions-multi-text no-margin no-display ",
        div_display_image: "row  add-questions-multi-text no-margin no-display "
      });
    } else if (this.state.question_type === "multitext") {
      this.setState({
        isDisabled: false,
        div_display_text: "row add-questions-multi-text no-margin display ",
        div_display_image: "row  add-questions-multi-text no-margin no-display "
      });
    } else if (this.state.question_type === "multiimage") {
      this.setState({
        isDisabled: false,
        div_display_image: "row  add-questions-multi-text no-margin display",
        div_display_text: "row add-questions-multi-text no-margin no-display "
      });
    }
  };
  render() {
    var onDragEnd = result => {
      // dropped outside the list

      /*  const { source, destination } = result;
    if (!source.droppableId) {
      return;
    }

    if (source.droppableId != destination.droppableId) {
      return;
    }*/

      let finalArr = [];
      if (!result.destination) {
        return;
      }

      if(result.type.indexOf("droppableEditMultiText") != -1){
        const sortedItems = this.reorder(
          this.state.editMultiTextArr,
          result.source.index,
          result.destination.index
        );
        if(sortedItems){
          this.setState({editMultiTextArr : sortedItems})
        }
        return false;
      } else if(result.type.indexOf("droppableEditMultiImage") != -1){
        const sortedItems = this.reorder(
          this.state.editMultiImageArr,
          result.source.index,
          result.destination.index
        );
        if(sortedItems){
          this.setState({editMultiImageArr : sortedItems})
        }
        return false;
      }
      const sortedItems = this.reorder(
        this.state.itemArray,
        result.source.index,
        result.destination.index
      );
      if(sortedItems){
        this.setState({itemArray : sortedItems})
      }

      list = sortedItems;
      finalArr = sortedItems.map((obj, idx) => {
        return obj.id;
      });
      this.reOrderList(finalArr);
    };

    var onAddTextDragEnd = result => {
      let finalArr = [];
      if (!result.destination) {
        return;
      }

      if(this.state.question_type === "multitext"){
        const sortedItems = this.reorder(
          this.state.multiTextArr,
          result.source.index,
          result.destination.index
        );
        if(sortedItems){
          this.setState({multiTextArr:sortedItems})
        }
        return false;
      } else if(this.state.question_type === "multiimage"){
        const sortedItems = this.reorder(
          this.state.multiImageArr,
          result.source.index,
          result.destination.index
        );
        if(sortedItems){
          this.setState({multiTextArr:sortedItems})
        }
        return false;
      }
    };

    let editChoiceHtml = "",
      choicesHtml = "",
      choices = "";
    if (this.state.questionData !== undefined) {
      let edit_qData = this.state.questionData;
      let edit_qType = edit_qData.question_type;
      let edit_choices = edit_qData.question_choices;
      let edit_question = edit_qData.question;

      /// multitext edit question html
      if (this.state.edit_question_id > 0 && this.state.editMultiTextArr && edit_qType == "multitext") {
        choices = this.state.editMultiTextArr.map((obj, idx) => {
          return {
            content: (
              <React.Fragment key={"fragment_edit_text" + obj.id}>
                <div
                  className={obj.divClass}
                  data-order_by="0"
                  id={"edit_multiTextRow_"+obj.id}
                >
                  <a className="drag-dots" />
                  <span className="choice-question-alpha">
                    -
                  </span>
                  <input
                    type="text"
                    className="setting-input-box add-questions"
                    name={'editMultiTextArr'}
                    value={obj.text}
                    autoComplete="off"
                    onChange={this.handleInputChange}
                    data-index={idx}
                  />
                  <a className={(idx == 0) ? "choice-question-cross no-display" : "choice-question-cross"}>
                    <img
                      data-optionid={idx}
                      data-index={idx}
                      onClick={this.deleteEditTextOption.bind(this)}
                      src="/images/close.png"
                    />
                  </a>
                </div>
              </React.Fragment>
            ),
            id: obj.id
          };
        });
        choicesHtml = (

          <div className={this.state.div_edit_display_text}>
            <div className="col-lg-6 col-xs-12">
              <div className="settings-subtitle">
                {this.state.question_choices}
                <a
                  onClick={this.editMultiTextOption}
                  className="add-btn pull-right"
                >
                  <span>+</span>
                  {this.state.questionnaire_Add}
                </a>
              </div>
              <Droppable droppableId={`${this.state.edit_question_id}`}  type={`droppableEditMultiText-${this.state.edit_question_id}`} >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    style={this.getListStyleChild(snapshot.isDraggingOver, 'subItem')}
                  >


                      {choices.map((item, index) => (
                        <Draggable
                          key={`draggbleEditMultiText-${this.state.edit_question_id}-${index}`}
                          draggableId={`draggbleEditMultiText-${this.state.edit_question_id}-${index}`}
                          index={index}
                          //type="editChoiceText"
                          //isDragDisabled={(this.state.qId) ? false : true}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              style={this.getItemStyleChild(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                'subItem'
                              )}
                              {...provided.dragHandleProps}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
            </div>
            <div className="col-lg-6 col-xs-12">
              <div className="row ques-radio-right">
                <div className="col-lg-7 col-md-12">
                  {this.state.select_yes}
                </div>
                <div className="col-lg-5 col-md-12">
                  <div className="basic-checkbox-outer">
                    <input
                      id="is_multiple_select_text_y"
                      className="basic-form-checkbox"
                      name={"edit_multiple_selection"}
                      type="radio"
                      value="1"
                      checked={
                        this.state.edit_multiple_selection == "1"
                          ? "checked"
                          : false
                      }
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="is_multiple_select_text_y"
                    >
                      {this.state.yes_option}
                    </label>
                  </div>
                  <div className="basic-checkbox-outer">
                    <input
                      id="is_multiple_select_text_n"
                      className="basic-form-checkbox"
                      name={"edit_multiple_selection"}
                      type="radio"
                      value="0"
                      checked={
                        this.state.edit_multiple_selection == "0"
                          ? "checked"
                          : false
                      }
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="is_multiple_select_text_n"
                    >
                      {this.state.no_option}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

        );
      } else if (this.state.edit_question_id > 0 && this.state.editMultiImageArr && edit_qType == "multiimage") {
        choices = this.state.editMultiImageArr.map((obj, idx) => {
          let imageName = obj.image;
          if (!imageName) {
            imageName = this.state.questionnaire_No_file;
          }
          return {
            content: (
              <div
                className="choice-question-outer ui-sortable"
                id="add_sortable_multi-images"
                key={"edit_image_" + idx}
              >
                <div className="choice-question-row no-border no-margin">
                    <div className="qus-chice-label col-xs-6 no-padding quest-img-title">{this.state.settings_question}</div>
                    <div className="qus-chice-label col-xs-6 no-padding quest-label-title">{this.state.questionnaire_Label}</div>
                </div>

                <div
                  className={obj.divClass}
                  key={"edit_multiImageRow_" + idx}
                >
                  <a className="drag-dots" />
                  <span className="choice-question-alpha">
                    -
                  </span>
                  <div className="col-xs-6 no-padding dropzone-filename">
                    <div className="new-white-btn choose-file dz-clickable">
                      {this.state.Questionnaire_Choose_File}
                      <div className="dz-default dz-message">
                        <span>{this.state.editUsers_Drop_Files_To_Upload}</span>
                      </div>
                    </div>
                    <span className={"span_dropzone_"+idx}>
                      {imageName}
                    </span>
                    <input
                      type="file"
                      name={'editMultiImageArr'}
                      //disabled={this.state.isDisabled}
                      onChange={this.handleInputChange}
                      className="image_questionnaire"
                      id={"image_"+idx}
                      data-index={idx}
                      data-name={'image'}
                    />
                  </div>
                  <input
                    type="text"
                    name={'editMultiImageArr'}
                    onChange={this.handleInputChange}
                    value={obj.image_label}
                    placeholder="label"
                    className="col-xs-6 no-padding no-bg img-label"
                    id={"image_label_"+idx}
                    data-index={idx}
                    data-name={'image_label'}
                  />
                  <a
                    className={(idx == 0) ? "choice-question-cross no-display" : "choice-question-cross"}
                  >
                    <img
                      onClick={this.deleteEditImageOption.bind(this)}
                      src="/images/close.png"
                      data-optionid={idx}
                      data-index={idx}
                      data-name={'close'}
                    />
                  </a>
                </div>
              </div>
            ),
            id: idx + 1
          };
        });

        choicesHtml = (
          <div className={this.state.div_edit_display_image}>
            <div className="col-lg-6 col-xs-12">
              <div className="settings-subtitle">
                {this.state.question_choices}
                <a
                  onClick={this.editMultiImageOption}
                  className="add-btn pull-right"
                >
                  <span>+</span>
                  {this.state.questionnaire_Add}
                </a>
              </div>
                <Droppable droppableId={`${this.state.edit_question_id}`}  type={`droppableEditMultiImage-${this.state.edit_question_id}`} >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={this.getListStyleChild(snapshot.isDraggingOver)}
                    >
                      {choices.map((item, index) => (
                        <Draggable
                          key={`draggbleEditMultiImage-${this.state.edit_question_id}-${index}`}
                          draggableId={`draggbleEditMultiImage-${this.state.edit_question_id}-${index}`}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={this.getItemStyleChild(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {item.content}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
            </div>

            <div className="col-lg-6 col-xs-12">
              <div className="row ques-radio-right">
                <div className="col-lg-7 col-xs-12">
                  {this.state.select_yes}
                </div>
                <div className="col-lg-5 col-xs-12">
                  <div className="basic-checkbox-outer">
                    <input
                      id="is_multiple_select_image_y"
                      className="basic-form-checkbox"
                      name="edit_multiple_selection"
                      value="1"
                      type="radio"
                      checked={
                        this.state.edit_multiple_selection == "1"
                          ? "checked"
                          : false
                      }
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="is_multiple_select_image_y"
                    >
                      {this.state.yes_option}
                    </label>
                  </div>
                  <div className="basic-checkbox-outer">
                    <input
                      id="is_multiple_select_image_n"
                      className="basic-form-checkbox"
                      name="edit_multiple_selection"
                      value="0"
                      type="radio"
                      checked={
                        this.state.edit_multiple_selection == "0"
                          ? "checked"
                          : false
                      }
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="is_multiple_select_image_n"
                    >
                      {this.state.no_option}
                    </label>
                  </div>
                </div>
              </div>
              <div className="row ques-radio-right">
                <div className="col-lg-7 col-xs-12">
                  {this.state.select_yes_images}
                </div>
                <div className="col-lg-5 col-xs-12">
                  <div className="basic-checkbox-outer">
                    <input
                      id="is_multiple_select_labels_y"
                      className="basic-form-checkbox"
                      name="edit_display_labels"
                      value="1"
                      type="radio"
                      checked={
                        this.state.edit_display_labels == "1"
                          ? "checked"
                          : false
                      }
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="is_multiple_select_labels_y"
                    >
                      {this.state.yes_option}
                    </label>
                  </div>
                  <div className="basic-checkbox-outer">
                    <input
                      id="is_multiple_select_labels_n"
                      className="basic-form-checkbox"
                      name="edit_display_labels"
                      value="0"
                      type="radio"
                      checked={
                        this.state.edit_display_labels == "0"
                          ? "checked"
                          : false
                      }
                      onChange={this.handleInputChange}
                    />
                    <label
                      className="basic-form-text"
                      htmlFor="is_multiple_select_labels_n"
                    >
                      {this.state.no_option}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }

      editChoiceHtml = (
        <div className="setting-container bg-light-blue addHere">
          <div className="setting-title m-b-40">
            {this.state.questionnaire_Edit_Question}
          </div>
          <div className="row">
            <div className="col-sm-6 col-xs-12">
              <div className="setting-field-outer no-ques-margin">
                <div className="new-field-label">
                  {this.state.questionnaire_Question}
                  <span className="setting-require">*</span>
                </div>
                <div className="setting-input-outer">
                  <input
                    type="text"
                    className="setting-input-box add-questions"
                    id="add_question"
                    name="edit_question"
                    autoComplete="off"
                    value={this.state.edit_question}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 col-xs-12">
              <div className="setting-field-outer">
                <div className="new-field-label">
                  {this.state.question_type_data}
                  <span className="setting-require">*</span>
                </div>
                <div className="setting-input-outer">
                  <select
                    name="edit_question_type"
                    className="question_type setting-select-box"
                    value={this.state.edit_question_type}
                    onChange={this.handleInputChange}
                  >
                    <option value="yesno">
                      {this.state.yes_option}/{this.state.no_option}
                    </option>
                    <option value="multitext">
                      {this.state.questionnaire_Multiple_Selection_Text}
                    </option>
                    <option value="multiimage">
                      {this.state.questionnaire_Multiple_Selection_Images}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {choicesHtml}
          <div className="col-xs-12">
            <a
              className="new-white-btn cancelAddAction"
              onClick={this.deleteEditRow.bind(this)}
            >
              {this.state.editUsers_CancelBtn}
            </a>
            <a
              id="save_newQuestion"
              onClick={this.updateQuestion}
              className="new-blue-btn"
            >
              {this.state.questionnaire_Update}
            </a>
          </div>
        </div>
      );
    }

    let list = [];
    if (this.state.itemArray.length) {
      list = this.state.itemArray.map((item, index) => {
        var question_type = item.question_type;
        let questionTypeLabel = "";

        if (question_type == "yesno") {
          questionTypeLabel = "Yes/No";
        } else if (question_type == "multitext") {
          questionTypeLabel = "Text";
        } else if (question_type == "multiimage") {
          questionTypeLabel = "Image";
        }
        return {
          content: (
            <React.Fragment key={"fragment_" + item.id}>

                <td className="col-xs-2 table-updated-td Questionnaire-name">
                  {questionTypeLabel}
                </td>
                <td className="col-xs-2 table-updated-td text-center ">
                  <a
                    className={
                      item.id
                        ? "easy-link col-xs-6 edit_question"
                        : "easy-link col-xs-6 edit_question no-display"
                    }
                    data-id={item.id}
                    onClick={this.editItem.bind(this)}
                  >
                    Edit
                  </a>
                  <a
                    className={
                      item.id
                        ? "easy-link col-xs-6 delete_question  no-display"
                        : "easy-link col-xs-6 delete_question"
                    }
                    data-id={index}
                    onClick={this.deleteItem.bind(this)}
                  >
                    Delete
                  </a>
                  <a
                    className={
                      item.id
                        ? "easy-link col-xs-6 delete_question"
                        : "easy-link col-xs-6 delete_question no-display"
                    }
                    data-id={item.id}
                    onClick={this.showDeleteModal.bind(this)}
                  >
                    Delete
                  </a>
                </td>
            </React.Fragment>
          ),
          id: item.id ? item.id : index,
          question: item.question ? item.question : ''
        };
      });
    }

    let cList = [];
    if (this.state.question_type === 'multitext' &&  this.state.multiTextArr.length) {
      cList = this.state.multiTextArr.map((divItem, index) => {
        return {
          content: (
            <div
              className={divItem.divClass}
              key={"addt_multiTextRow_" + index}
            >
              <a className={(this.state.multiTextArr.length > 1) ? "drag-dots" : "drag-dots cursor-not-allowed"} />
              <span className="choice-question-alpha">
                -
              </span>
              <input
                type="text"
                className="setting-input-box add-questions"
                data-row-num="0"
                onChange={this.handleInputChange}
                name={'multiTextArr'}
                autoComplete="off"
                value={divItem.text}
                data-index={index}
                autoFocus
              />
              <a className={(index == 0) ? "choice-question-cross no-display" : "choice-question-cross"}
              >
                <img
                  src="/images/close.png"
                  data-optionid={index}
                  data-index={index}
                  onClick={this.deleteTextOption.bind(this)}
                />
              </a>
            </div>
          ),
          id: "addTextChoice-" + index
        };
      });
    } else if (this.state.question_type === 'multiimage' &&  this.state.multiImageArr.length) {
      cList = this.state.multiImageArr.map((divItem, index) => {
        let imageName = divItem.image;
        if (!imageName) {
          imageName = this.state.questionnaire_No_file;
        }
        return {
          content: (
            <div
              className={divItem.divClass}
              key={"add_multiImageRow_" + index}
            >
              <a className={(this.state.multiImageArr.length > 1) ? "drag-dots" : "drag-dots cursor-not-allowed"} />
              <span className="choice-question-alpha">
                -
              </span>
              <div className="col-xs-6 no-padding dropzone-filename">
                <div className="new-white-btn choose-file">
                  Choose file
                  <div className="dz-default dz-message">
                    <span>Drop files here to upload</span>
                  </div>
                </div>
                <span className="span_dropzone_0">
                  {imageName}
                </span>
                <input
                  type="file"
                  name={'multiImageArr'}
                  //disabled={this.state.isDisabled}
                  onChange={this.handleInputChange}
                  className="image_questionnaire"
                  id={"image_"+index}
                  data-index={index}
                  data-name={'image'}
                />
                <div className="dz-default dz-message">
                  <span>
                    {this.state.editUsers_Drop_Files_To_Upload}
                  </span>
                </div>
              </div>
              {/*<span className="span_dropzone_0"> No file</span>*/}
              <input
                //disabled={this.state.isDisabled}
                type="hidden"
                data-row-num={index}
                className="add-questions"
                id={"dropzone_"+index}
                data-index={index}
                data-name={'dropzone'}
              />
              <input
                //disabled={this.state.isDisabled}
                type="text"
                name={'multiImageArr'}
                onChange={this.handleInputChange}
                value={divItem.image_label}
                placeholder="label"
                className="col-xs-6 no-padding no-bg img-label"
                id={"image_label_"+index}
                data-index={index}
                data-name={'image_label'}
              />
              <a
                data-optionid={index}
                onClick={this.deleteImageOption.bind(this)}
                className={(index == 0) ? "choice-question-cross no-display" : "choice-question-cross"}
                data-index={index}
                data-name={'close'}
              >
                <img src="/images/close.png" data-optionid={index} data-index={index} />
              </a>
            </div>
          ),
          id: "addImageChoice-" + index
        };
      });
    }

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="setting-setion m-b-60">
              <div className="setting-container bg-white-scetion">
                <div className="setting-title m-b-40 items-center">
                  {this.state.qId  ? this.state.settings_view_questionnaire : this.state.add_questionnaire}
                  {this.state.qId && (
                    <Button
                      size="small"
                      className="m-l-10"
                      variant="outlined"
                      onClick={() => this.setState({ isAssignToServicesModalOpen: true })}
                    >
                      Assign to Services
                    </Button>
                  )}
                  <Link to="/settings/questionnaires" className="pull-right cancelAction pos-top-right">
                    <img src="/images/close.png" />
                  </Link>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="setting-field-outer no-ques-margin questionId:this.state.idn">
                      <div className="new-field-label">
                        {this.state.questionnaire_name}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer">
                        <input
                          name="consultation_title"
                          onChange={this.handleInputChange}
                          className={
                            this.state.consultation_title_Error === true
                              ? "setting-input-box field_error"
                              : "setting-input-box"
                          }
                          id="consultation_title"
                          value={this.state.consultation_title}
                          type="text"
                          autoComplete="off"
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="setting-container bg-light-blue addHere">
                <div className="setting-title m-b-40">
                  {this.state.add_new_question}
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="setting-field-outer no-ques-margin">
                      <div className="new-field-label">
                        {this.state.question_data}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer">
                        <input
                          type="text"
                          className={
                            this.state.question_Error === true
                              ? "setting-input-box add-questions field_error"
                              : "setting-input-box add-questions"
                          }
                          onChange={this.handleInputChange}
                          value={this.state.question}
                          id="add_question"
                          name="question"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-sm-6">
                    <div className="setting-field-outer">
                      <div className="new-field-label">
                        {this.state.question_type_data}
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer">
                        <select
                          name="question_type"
                          className="question_type setting-select-box"
                          value={this.state.question_type}
                          onChange={this.handleInputChange}
                        >
                          <option value="yesno">
                            {this.state.yes_option}/{this.state.no_option}
                          </option>
                          <option value="multitext">
                            {this.state.questionnaire_Multiple_Selection_Text}
                          </option>
                          <option value="multiimage">
                            {this.state.questionnaire_Multiple_Selection_Images}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className={this.state.div_display_text}>
                    <div className="col-lg-6 col-xs-12">
                      <div className="settings-subtitle">
                        {this.state.question_choices}
                        <a
                          onClick={this.addMultiTextOption}
                          className="add-btn pull-right"
                        >
                        </a>
                      </div>
                      <div
                        className="choice-question-outer ui-sortable"
                        id="add_sortable_multi-text"
                      >
                        <DragDropContext onDragEnd={onAddTextDragEnd}>
                          <Droppable droppableId="droppableCreateText" type="droppableCreateText">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={this.getListStyleChild(
                                  snapshot.isDraggingOver
                                )}
                              >
                                {cList.map((item, index) => (
                                  <Draggable
                                    key={`dragglebleCreateText-0-${index}`}
                                    draggableId={`dragglebleCreateText-0-${index}`}
                                    index={index}
                                    isDragDisabled={(cList.length > 1) ? false : true}
                                  >
                                     {/* isDragDisabled={(this.state.qId) ? false : true} */}
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={this.getItemStyleChild(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {item.content}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                      <div className="settings-subtitle">
                        <a
                          onClick={this.addMultiTextOption}
                          className="add-btn pull-right"
                        >
                          <span>+</span> {this.state.questionnaire_Add}
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-6 col-xs-12">
                      <div className="row ques-radio-right">
                        <div className="col-lg-7 col-md-12">
                          {this.state.select_yes}
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <div className="basic-checkbox-outer">
                            <input
                              id="is_multiple_select_text_y"
                              className="basic-form-checkbox"
                              name="multiple_selection"
                              onChange={this.handleInputChange}
                              type="radio"
                              value={1}
                              checked={
                                this.state.multiple_selection == "1"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="is_multiple_select_text_y"
                            >
                              {this.state.yes_option}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input
                              id="is_multiple_select_text_n"
                              className="basic-form-checkbox"
                              name="multiple_selection"
                              type="radio"
                              onChange={this.handleInputChange}
                              value={0}
                              checked={
                                this.state.multiple_selection == "0"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-formultitextm-text"
                              htmlFor="is_multiple_select_text_n"
                            >
                              {this.state.no_option}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={this.state.div_display_image}>
                    <div className="col-lg-6 col-xs-12">
                      <div className="settings-subtitle">
                        {this.state.question_choices}
                        <a
                          onClick={this.addMultiImageOption}
                          className="add-btn pull-right"
                        >
                        </a>
                      </div>
                      <div
                        className="choice-question-outer ui-sortable"
                        id="add_sortable_multi-images"
                      >
                        <div className="choice-question-row no-border no-margin">
                          <div className="qus-chice-label col-xs-6 no-padding quest-img-title">
                            {this.state.question_choices_image}
                          </div>
                          <div className="qus-chice-label col-xs-6 no-padding quest-label-title">
                            {this.state.question_choice_lable}
                          </div>
                        </div>

                        <DragDropContext onDragEnd={onAddTextDragEnd}>
                          <Droppable droppableId="droppableCreateImage" type="droppableCreateImage">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                style={this.getListStyleChild(
                                  snapshot.isDraggingOver
                                )}
                              >
                                {cList.map((item, index) => (
                                  <Draggable
                                    key={`dragglebleCreateImage-0-${index}`}
                                    draggableId={`dragglebleCreateImage-0-${index}`}
                                    index={index}
                                    isDragDisabled={(cList.length > 1) ? false : true}
                                  >
                                     {/* isDragDisabled={(this.state.qId) ? false : true} */}
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={this.getItemStyleChild(
                                          snapshot.isDragging,
                                          provided.draggableProps.style
                                        )}
                                      >
                                        {item.content}
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>

                      </div>
                      <div className="settings-subtitle">
                        <a
                          onClick={this.addMultiImageOption}
                          className="add-btn pull-right"
                        >
                          <span>+</span>{" "}
                          {this.state.questionnaire_Add}
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-6 col-xs-12">
                      <div className="row ques-radio-right">
                        <div className="col-lg-7 col-md-12">
                          {this.state.select_yes}
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <div className="basic-checkbox-outer">
                            <input
                              //disabled={this.state.isDisabled}
                              id="is_multiple_select_image_y"
                              className="basic-form-checkbox"
                              name="multiple_selection_image"
                              onChange={this.handleInputChange}
                              value={"1"}
                              type="radio"
                              checked={
                                this.state.multiple_selection_image == "1"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="is_multiple_select_image_y"
                            >
                              {this.state.yes_option}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input
                              //disabled={this.state.isDisabled}
                              id="is_multiple_select_image_n"
                              className="basic-form-checkbox"
                              name="multiple_selection_image"
                              onChange={this.handleInputChange}
                              value={"0"}
                              type="radio"
                              checked={
                                this.state.multiple_selection_image == "0"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="is_multiple_select_image_n"
                            >
                              {this.state.no_option}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="row ques-radio-right">
                        <div className="col-lg-7 col-md-12">
                          {this.state.select_yes_images}
                        </div>
                        <div className="col-lg-5 col-md-12">
                          <div className="basic-checkbox-outer">
                            <input
                              id="is_multiple_select_labels_y"
                              className="basic-form-checkbox"
                              name="display_labels"
                              onChange={this.handleInputChange}
                              value={1}
                              type="radio"
                              checked={
                                this.state.display_labels == "1"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="is_multiple_select_labels_y"
                            >
                              {this.state.yes_option}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input
                              id="is_multiple_select_labels_n"
                              className="basic-form-checkbox"
                              name="display_labels"
                              onChange={this.handleInputChange}
                              value={0}
                              type="radio"
                              checked={
                                this.state.display_labels == "0"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="is_multiple_select_labels_n"
                            >
                              {this.state.no_option}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xs-12">
                    <a
                      className="new-white-btn cancelAddAction"
                      onClick={this.cancelQuestion}
                    >
                      {this.state.editUsers_CancelBtn}
                    </a>
                    <button
                      id="save_newQuestion"
                      onClick={this.createProject}
                      className="new-blue-btn"
                    >
                      {this.state.questionnaire_Add}
                    </button>
                  </div>
                </div>
              </div>
              {/* Questionnaire Listing - START */}
              {list.length > 0 && (
                <div className="table-responsive">
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppableSaved" type="droppableSaved">
                      {(provided, snapshot) => (
                        <table
                          className="table-updated no-hover setting-table table-min-width"
                          ref={provided.innerRef}
                        >
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-8 table-updated-th sorting">
                                {this.state.questionnaire_Questions}
                              </th>
                              <th className="col-xs-2 table-updated-th sorting text-center">
                                Type
                              </th>
                              <th className="col-xs-2 table-updated-th sorting text-center">
                                &nbsp;
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            className="ui-sortable"
                          >
                          {list.map((item, index) => (
                            <Draggable
                              key={`draggbleSaved-${this.state.edit_question_id}-${index}`}
                              draggableId={`draggbleSaved-${this.state.edit_question_id}-${index}`}
                              index={index}
                              //isDragDisabled={(this.state.qId) ? false : true}
                            >
                              {(provided, snapshot) => (
                                <React.Fragment key={'droppable2Fragment'+item.id}>
                                  <tr
                                  id={"questionList-"+item.id}

                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    'parentItem'
                                  )}

                                  className="table-updated-tr" key={'droppable2'+item.id}
                                  >
                                    <td  className="col-xs-8 table-updated-td Questionnaire-name">
                                      <a {...provided.dragHandleProps} className="drag-dots"  />
                                      {item.question}
                                    </td>
                                    {item.content}
                                  </tr>
                                  {item.id == this.state.edit_question_id && (
                                    <tr className="table-updated-tr editQuestion_tr">
                                      <td colSpan="3">{editChoiceHtml}</td>
                                    </tr>
                                  )}
                                  </React.Fragment >
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          </tbody>
                        </table>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              )}
              {/* Questionnaire Listing - END */}
              <div className="footer-static no-border">
                <a
                  className={
                    this.state.qId != undefined
                      ? "new-red-btn pull-left confirm-model"
                      : "no-display"
                  }
                  onClick={this.showQuestionnaireDeleteModal}
                >
                  Delete
                </a>
                <button
                  id="saveConsultation"
                  onClick={this.handleSubmit}
                  className="new-blue-btn pull-right"
                >
                  Save
                </button>
                <Link
                  to="/settings/questionnaires"
                  className="new-white-btn pull-right cancelAction"
                >
                  Cancel
                </Link>
              </div>

              <div className={this.state.showModal ? "overlay" : ""} />
              <div
                id="filterModal"
                role="dialog"
                className={
                  this.state.showModal
                    ? "modal fade in displayBlock"
                    : "modal fade"
                }
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        ×
                      </button>
                      <h4 className="modal-title" id="model_title">
                        {this.state.delete_confirmation}
                        {this.state.showModal}
                      </h4>
                    </div>
                    <div
                      id="errorwindow"
                      className="modal-body add-patient-form filter-patient"
                    >
                      {this.state.delete_warning}
                    </div>
                    <div className="modal-footer">
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button
                          type="button"
                          className="btn  logout pull-right"
                          data-dismiss="modal"
                          onClick={this.dismissModal}
                        >
                          {this.state.no_option}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success pull-right m-r-10"
                          data-dismiss="modal"
                          onClick={this.deleteQuestion}
                        >
                          {this.state.yes_option}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
        {this.state.isAssignToServicesModalOpen && (
          <AssignServicesModal
            id={this.props.match.params.id}
            modelType={ASSIGN_TO_SERVICES_MODEL_TYPE.cosmeticQuestionnaire}
            servicesType={SERVICES_BY_CATEGORIES_MODEL_TYPE.cosmeticQuestionnaire}
            pickedIds={this.state.assignedServiceIds}
            isOpen={this.state.isAssignToServicesModalOpen}
            onClose={() => this.setState({ isAssignToServicesModalOpen: false })}
            onSuccess={() => this.props.getQuestionnaireById(this.props.match.params.id)}
            title="Assign Services to Questionary"
          />
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.SettingReducer.action === "CREATE_QUESTIONNAIRES") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true;
      returnState.showLoaderTimestamp = new Date();
    }
    else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.redirect = true;
      returnState.redirectTimestamp = new Date();
    }
  } else if (state.SettingReducer.action === "UPDATE_QUESTIONNAIRES") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true
      returnState.showLoaderTimestamp = new Date();
    }
    else {
      returnState.message =
      languageData.global[state.SettingReducer.data.message];
      returnState.redirect = true;
      returnState.redirectTimestamp = new Date();
    }
  } else if (state.SettingReducer.action === "CREATE_QUESTION") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 201) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true
      returnState.showLoaderTimestamp = new Date();
    }
    else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.questionnaireData = state.SettingReducer.data;
      returnState.questionnaireDataTimeStamp = new Date();
    }
  } else if (state.SettingReducer.action === "UPDATE_QUESTION") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200)
    {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true
      returnState.showLoaderTimestamp = new Date();
    }
    else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
     returnState.questionnaireData = state.SettingReducer.data;
     returnState.questionnaireDataTimeStamp = new Date();
   }
  }
  else if (state.SettingReducer.action === "GET_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status != 200) {
      returnState.redirect = true;
      returnState.redirectTimestamp = new Date();
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
        returnState.showLoader = true
        returnState.showLoaderTimestamp = new Date();
    }
    else
    {
      returnState.questionnaireData = state.SettingReducer.data;
      returnState.questionnaireDataTimeStamp = new Date();
    }
  } else if (state.SettingReducer.action === "GET_QUESTION") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true
      returnState.showLoaderTimestamp = new Date();
    }
    else {
     returnState.questionData = state.SettingReducer.data;
     returnState.questionDataTimeStamp = new Date();
   }
  } else if (state.SettingReducer.action === "QUESTION_DELETED") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true
      returnState.showLoaderTimestamp = new Date();
    }
    else {
          toast.success(languageData.global[state.SettingReducer.data.message]);
          returnState.questionnaireData = state.SettingReducer.data;
          returnState.questionnaireDataTimeStamp = new Date();
        }
  } else if (state.SettingReducer.action === "QUESTIONNAIRE_DELETED") {
    if (state.SettingReducer.data.status != 200)
    {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = true
      returnState.showLoaderTimestamp = new Date();
    }
    else
    {
      returnState.redirect = true;
      returnState.redirectTimestamp = new Date();
      returnState.message =
        languageData.global[state.SettingReducer.data.message];
    }
  }
  if (state.SettingReducer.action === "SORT_ORDER_UPDATE") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200){

      toast.error(languageData.global[state.SettingReducer.data.message]);
      //returnState.showLoader = true
    }
    else  {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      if(state.SettingReducer.sortType && state.SettingReducer.sortType === "Question" && state.SettingReducer.sortIds){
        //returnState.sortedListIds = state.SettingReducer.sortIds
      }
      //return { showLoader: false };
     }
    }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createQuestionnaire: createQuestionnaire,
      createQuestion: createQuestion,
      uploadImage: uploadImage,
      getQuestionnaireById: getQuestionnaireById,
      getQuestionById: getQuestionById,
      updateQuestion: updateQuestion,
      deleteQuestion: deleteQuestion,
      deleteQuestionnaire: deleteQuestionnaire,
      updateQuestionnaire: updateQuestionnaire,
      updateSortOrder: updateSortOrder,
      exportEmptyData: exportEmptyData,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateQuestionnaries);
