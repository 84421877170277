import React, { useState, useEffect } from "react";
import _ from "lodash/fp";
import { useForm } from "react-hook-form";
import { Scrollbars } from "react-custom-scrollbars";
import { Input, Select, CheckBox } from '../../../Common/ReactHookForm'
import { ToastContainer, toast } from "react-toastify";


const FormStep6 = (props) => {
  const { handleFormStepChange, formValue, resources, content, updateSignature, merchantNumber, downloadAgreement, isDocumentAdded } = props;
  const { register, handleSubmit, watch, errors, control } = useForm({ formValue });
  const [collapseActive, setCollapseActive] = useState(-1);
  const [terms, setTerms] = useState([]);
  const [term, setTerm] = useState({});
  const [termId, setTermId] = useState("");
  const [popupClose, setPopupClose] = useState(true);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const onSubmit = data => {
    handleFormStepChange(6, data)
    //alert(JSON.stringify(data));
  }; // your form submit function which will invoke after successful validation

  useEffect(() => {
    if(content && terms && terms.length == 0) {
      let tmpTerms = content;
      if(tmpTerms) {
        tmpTerms = tmpTerms.map((obj, idx) => {
          obj.is_signed = false;
          return obj;
        })
        setTerms(tmpTerms);
      }
    }
  }, [content])

  const openPopup = (data) => {
    return (
        <div
            className={
              "blackOverlay"
            }
          >
            <div className="vert-middle ">
              <div className="loyaltyMembership start-program-main small-popup-outer extra-large-popup">
                <div className="newPopupTitle titleWithDownload">
                  <div className="popup-name">
                    TERMS - {(data.terms.description) ? data.terms.description : ""}
                  </div>
                  <a className="new-line-btn popupDownloadBtn" onClick={() => downloadAgreement({terms:{content : [data]}})}>Download</a>
                  <a onClick={() => {setPopupClose(true)}} className="popupClose"><img src="/images/close.png" /></a>
                </div>
                <div className="newPopupContent small-popup-content">
                  <p className="p-text" id={data.terms.description.replace(' ', '-')} dangerouslySetInnerHTML={{__html:(data && data.terms.detail) ? data.terms.detail.text : ""}} />
                                      
                </div>
                <div className="footer-static no-border no-padding text-center">
                <button className="new-blue-btn  m-l-0 m-t-20 acceptTermBtn" type="button" onClick={() => signTerms()}>Agree & Sign</button>
                </div>
              </div>
            </div>
          </div>
      )
  }

  const openConfirmPopup = () => {
    return (
        <div
            className={
              "blackOverlay"
            }
          >
            <div className="vert-middle ">
              <div className="loyaltyMembership start-program-main small-popup-outer extra-large-popup">
                <div className="newPopupTitle">
                  <div className="popup-name">
                    At the completion of your onboarding, you will be sent one Bluetooth card reader to begin taking mobile payments. This cost of this card reader is $99 and will be deducted from your first month’s processing volume.
                  </div>
                  <a onClick={() => {setOpenConfirmation(false)}} className="popupClose"><img src="/images/close.png" /></a>
                </div>
                {!isDocumentAdded && 
                  <div className="text-center m-b-20">
                    <p className="check-text">Everything looks good! Our team may reach back out to you to make sure we confirm your bank account by collecting a bank letter or voided check. We do this to ensure we are sending your funds to the right place.</p>
                  </div>
                }
                <div className="text-center m-b-20">
                  <a className="new-line-btn" onClick={() => updateLocalContent()}>Agree &amp; Continue</a>
                  <a className="new-line-btn" onClick={() => setOpenConfirmation(false)}>Exit</a>
                </div>
                <div className="footer-static no-border no-padding text-center">
                  <p className="m-t-15">
                    Please  contact  Aesthetic Record at <a href={"mailto:info@aestheticrecord.com"} className="easy-link no-padding">info@aestheticrecord.com</a> if  you wish to order additional card readers.
                  </p>
                </div>
              </div>
            </div>
          </div>
      )
  }
  const signTerms = () => {
    let tmpTerms = terms;
    tmpTerms[termId].is_signed = true;
    setTerms(tmpTerms);
    setPopupClose(true)
  }

  const updateLocalContent = () => {
    if(terms && terms.length > 0) {
      let error = true;
      let submitContent = []
      terms.map((obj, idx) => {
        if(!obj.is_signed && obj.show) {
          error = false;
        }
        submitContent.push({"businessContactId": obj.businessContactId,
            "signatureSourceTypeId": obj.signatureSourceTypeId,
            "signatureSectionTypeId": obj.signatureSectionTypeId,
            "ipAddress": "49.36.231.150",
            "timeStamp": new Date(),
            "signerViewedLegalText": true
        })
      })
      if(!error) {
        toast.error('You have to sign each term.')
      } else {
        updateSignature(submitContent);
      }
    }
  }
  return (
    <React.Fragment>
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="setting-setion">
      <Scrollbars
        autoHeight
        autoHeightMin={0}
        autoHeightMax={window.innerHeight-240}
        className="full-width"
        renderTrackHorizontal={props => (
          <div
            {...props}
            style={{ display: "none" }}
            className="track-horizontal"
          />
        )}
      >
        <div className="setting-container p20">
		 
		   <a className="easy-link p-l-0" onClick={() => {
            handleFormStepChange(6)
          }}><i className="fa fa-angle-left m-r-5"></i> Go Back</a>
          {merchantNumber != '' && <a className="easy-link p-l-0 pull-right"> Merchant Id - {merchantNumber}</a>}
		 
          <div className="merchant-title">Merchant Application</div>
          <p className="p-text m-b-20">Welcome to the merchant facing application! Thank you for taking the time to help speed up your company's boarding process. Please fill out the application below to the best of your ability and click send when you're finished. This will alert your local rep.</p>

          <div className="settings-subtitle m-b-20 merchantSteps">4 out of 4: Signatures</div>
          <p className="p-text">Please agree and sign to the  terms below and press submit.</p>
          <div class="accordion" id="accordionClearent">

          {terms && terms.length > 0 && terms.map((obj, idx) => {
            if(!obj.show) return ;
            return (
                <div className="signatureAccordion termSigned">
                  <h3 className="signatureHeading">{obj.terms.description}
                    <div className="termSignedRight">
                      <a
                        className="new-blue-btn no-width sm-btn"
                        data-toggle="collapse"
                        role="button"
                        aria-expanded="false"
                        aria-controls={"collapse_"+idx}
                        onClick={() => {
                          setTerm(obj);
                          setTermId(idx);
                          setPopupClose(false)
                        }}>{obj.is_signed ? "View" : "Agree & Sign"}</a>
                        {(obj.is_signed === true) &&
                          <span>Signed <i className="fa fa-check-circle"></i></span>
                        }
                    </div>
                  </h3>
                </div> 
              )
          })}

        </div>
        </div>
    </Scrollbars>

		<div class="footer-static merchantFooter">
      <div class="footerProgressOuter"> 
       <p className="agree-check"><CheckBox 
             name="agreeToSubmit" 
             register={register} 
             isRequired={false}
             value={'1'}
             errors={errors}
             control={control} /> By submitting these signatures, you agree to comply with the terms listed in each agreement</p>
      <div class="footerProgressBg"><span class="footerProgressBar" style={{width: "100%"}}></span></div>
      </div>
      {watch('agreeToSubmit') == '1' && <button class="new-blue-btn m-t-5" type="button" onClick={() => setOpenConfirmation(true)}>Submit Application</button> }
    </div>

        {/*<div className="footer-static">
                  <button className="new-blue-btn pull-right" type="button">Submit</button>
                  <button className="new-line-btn pull-left" type="button" onClick={() => {
                    handleFormStepChange(5)
                  }}>Back</button>
                </div>*/}
        
      
        
        
        
        
        
      </div>
    </form>
    {!popupClose && openPopup(term)}
    {openConfirmation && openConfirmPopup()}
    </React.Fragment>
  );
}
export default FormStep6;
