import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const clearanceInstance = axios.create();

clearanceInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

clearanceInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

clearanceInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getAllResources(formData) {
  return (dispatch) => {
    clearanceInstance
      .get(process.env.REACT_APP_API_URL + "get-all-resources", formData)
      .then((response) => {
        dispatch({ type: "CLEARANCE_ALL_RESOURCES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARANCE_ALL_RESOURCES",
          payload: error.response.data,
        });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function createApplication(formData) {
  return (dispatch) => {
    clearanceInstance
      .post(process.env.REACT_APP_API_URL + "create-application", formData)
      .then((response) => {
        dispatch({
          type: "CLEARANCE_CREATE_APPLICATION",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARANCE_CREATE_APPLICATION",
          payload: error.response.data,
        });
      });
  };
}

export function saveClearentData(formData) {
  return (dispatch) => {
    clearanceInstance
      .post(process.env.REACT_APP_API_URL + "save-clearent-data", formData)
      .then((response) => {
        dispatch({ type: "CLEARANCE_SAVE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLEARANCE_SAVE_DATA", payload: error.response.data });
      });
  };
}

export function getSavedClearentData(id) {
  return (dispatch) => {
    clearanceInstance
      .get(process.env.REACT_APP_API_URL + "get-clearent-detail?id=" + id)
      .then((response) => {
        dispatch({ type: "CLEARENT_SAVED_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLEARENT_SAVED_DATA", payload: error.response.data });
      });
  };
}

export function updateSignature(formData) {
  return (dispatch) => {
    clearanceInstance
      .post(process.env.REACT_APP_API_URL + "update-signatures", formData)
      .then((response) => {
        dispatch({ type: "CLEARENT_UPDATE_SIGNATURE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLEARENT_UPDATE_SIGNATURE",
          payload: error.response.data,
        });
      });
  };
}

export function submitApp(formData) {
  return (dispatch) => {
    clearanceInstance
      .post(
        process.env.REACT_APP_API_URL + "submit-application-and-signature",
        formData,
      )
      .then((response) => {
        dispatch({ type: "SAVE_APP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_APP_DATA", payload: error.response.data });
      });
  };
}

export function downloadAgreement(formData) {
  return (dispatch) => {
    clearanceInstance
      .post(process.env.REACT_APP_API_URL + "download-terms", formData)
      .then((response) => {
        dispatch({ type: "DOWNLOAD_AGREEMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DOWNLOAD_AGREEMENT", payload: error.response.data });
      });
  };
}

export function deleteContact(formData) {
  return (dispatch) => {
    clearanceInstance
      .post(process.env.REACT_APP_API_URL + "delete-clearent-contact", formData)
      .then((response) => {
        dispatch({ type: "DELETE_CLEARENT_CONTACT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_CLEARENT_CONTACT",
          payload: error.response.data,
        });
      });
  };
}
