import React from "react";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { svg } from "../../../../../assets/svg";
import classes from "./Success.module.scss";
import { useWindowSize } from "../../../../../utilities/hooks/useWindowSize";
import { MOBILE_BREAKPOINT } from "../../SalesCheckoutInvoice.consts";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { Payments } from "./shared/Payments/Payments";
import { PaymentsMobile } from "./shared/PaymentsMobile/PaymentsMobile";

export function Success() {
  const { tSales } = useAppTranslation.Sales();
  const { width } = useWindowSize();
  const { invoice } = useInvoice();

  return (
    <LabeledBox
      label={
        <div className={classes.title}>
          {tSales("checkoutInvoice.stageSuccess.title")}
          <img src={svg.checkCircleGreen2} alt="check" />
        </div>
      }
    >
      {invoice?.paymentTransaction?.payments?.length > 0 && (
        <>
          {width > MOBILE_BREAKPOINT ? (
            <Payments
              list={invoice?.paymentTransaction?.payments || []}
              currency={invoice?.currency}
            />
          ) : (
            <PaymentsMobile
              list={invoice?.paymentTransaction?.payments || []}
              currency={invoice?.currency}
            />
          )}
        </>
      )}
    </LabeledBox>
  );
}
