import {
  APPOINTMENT_ROUTES,
  BI_ROUTES,
  INVENTORY_ROUTES,
  SETTINGS_ROUTES,
} from "../../consts/routes";
import { tCommon } from "../../i18n/useAppTranslation";
import { getIsEvolusEnabled } from "../../Utils/services.js";

const p = (pattern) => tCommon(`globalSearch.patterns.${pattern}`);

export const TOKENS_RESTRICTIONS = {
  [p("evolus")]: !getIsEvolusEnabled(),
};

export const TOKENS = {
  [p("manageClinics")]: SETTINGS_ROUTES.clinics(),

  [p("businessInfo")]: SETTINGS_ROUTES.arAccount(),

  [p("profile")]: SETTINGS_ROUTES.profile(),

  [p("promotions")]: SETTINGS_ROUTES.discountPackages(),

  [p("disputes")]: SETTINGS_ROUTES.posDisputes(),

  [p("posSettings")]: SETTINGS_ROUTES.pos(),

  [p("giftCards")]: SETTINGS_ROUTES.giftCards(),

  [p("posQuickButtons")]: SETTINGS_ROUTES.posQuickButtons(),

  [p("invoiceDisclaimer")]: SETTINGS_ROUTES.invoiceDisclaimer(),

  [p("users")]: SETTINGS_ROUTES.users(),

  [p("userControls")]: SETTINGS_ROUTES.userControls(),

  [p("subscriptions")]: SETTINGS_ROUTES.subscriptions(),

  [p("arInvoices")]: SETTINGS_ROUTES.arInvoices(),

  [p("resourceUsage")]: SETTINGS_ROUTES.resourceUsage(),

  [p("healthTimelineQuestionnaires")]:
    SETTINGS_ROUTES.healthTimelineQuestionnaires(),

  [p("consents")]: SETTINGS_ROUTES.consents(),

  [p("membershipAgreements")]: SETTINGS_ROUTES.membershipAgreements(),

  [p("preTreatment")]: SETTINGS_ROUTES.preTreatment(),

  [p("postTreatment")]: SETTINGS_ROUTES.postTreatment(),

  [p("dotPhrases")]: SETTINGS_ROUTES.dotPhrases(),

  [p("treatmentPlanTemplates")]: SETTINGS_ROUTES.treatmentPlanTemplates(),

  [p("surveys")]: SETTINGS_ROUTES.surveys(),

  [p("payrollCommissionReport")]: SETTINGS_ROUTES.payrollCommissionReport(),

  [p("salesGoals")]: SETTINGS_ROUTES.salesGoals(),

  [p("dashboardSettings")]: SETTINGS_ROUTES.dashboardSettings(),

  [p("referralSources")]: SETTINGS_ROUTES.referralSources(),

  [p("deletedProcedures")]: SETTINGS_ROUTES.deletedProcedures(),

  [p("deletedPaperwork")]: SETTINGS_ROUTES.deletedPaperwork(),

  [p("deletedSurveys")]: SETTINGS_ROUTES.deletedSurveys(),

  [p("touchMd")]: SETTINGS_ROUTES.integrations.touchMd(),

  [p("zoho")]: SETTINGS_ROUTES.integrations.zoho(),

  [p("hubSpot")]: SETTINGS_ROUTES.integrations.hubSpot(),

  [p("mailChimp")]: SETTINGS_ROUTES.integrations.mailChimp(),

  [p("activeCampaign")]: SETTINGS_ROUTES.integrations.activeCampaign(),

  [p("constantContact")]: SETTINGS_ROUTES.integrations.constantContact(),

  [p("zapier")]: SETTINGS_ROUTES.integrations.zapier(),

  [p("cherry")]: SETTINGS_ROUTES.integrations.cherry(),

  [p("leadsApi")]: SETTINGS_ROUTES.integrations.leadsApi(),

  [p("aspire")]: SETTINGS_ROUTES.integrations.aspire(),

  [p("evolus")]: SETTINGS_ROUTES.integrations.evolus(),

  [p("timeClock")]: SETTINGS_ROUTES.timeClock.list(),

  [p("timeClockSettings")]: SETTINGS_ROUTES.timeClock.settings(),

  [p("setupEPrescription")]: SETTINGS_ROUTES.setupEPrescription(),

  [p("inboxSettings")]: SETTINGS_ROUTES.inbox(),

  [p("services")]: APPOINTMENT_ROUTES.servicesMain(),

  [p("verticals")]: APPOINTMENT_ROUTES.verticals(),

  [p("equipment")]: APPOINTMENT_ROUTES.equipmentScheduleEquipment(),

  [p("resources")]: APPOINTMENT_ROUTES.equipmentScheduleEquipment(),

  [p("specialInstructions")]:
    APPOINTMENT_ROUTES.communicationSpecialInstructions(),

  [p("inPersonBooking")]: APPOINTMENT_ROUTES.communicationInPersonBooking(),

  [p("virtualBooking")]: APPOINTMENT_ROUTES.communicationVirtualBooking(),

  [p("cancellation")]: APPOINTMENT_ROUTES.communicationCancellation(),

  [p("inPersonReminder")]: APPOINTMENT_ROUTES.communicationInPersonReminder(),

  [p("virtualReminder")]: APPOINTMENT_ROUTES.communicationVirtualReminder(),

  [p("covidCommunication")]: APPOINTMENT_ROUTES.communicationCovid(),

  [p("reschedule")]: APPOINTMENT_ROUTES.communicationReschedule(),

  [p("surveyEmail")]: APPOINTMENT_ROUTES.communicationSurvey(),

  [p("domainConfig")]: APPOINTMENT_ROUTES.bookingPortalDomainConfig(),

  [p("bookingPortalSettings")]: APPOINTMENT_ROUTES.bookingPortalSettings(),

  [p("webConfig")]: APPOINTMENT_ROUTES.bookingPortalWebConfig(),

  [p("theme")]: APPOINTMENT_ROUTES.bookingPortalTheme(),

  [p("cancellationPolicy")]: APPOINTMENT_ROUTES.cancellationPolicy(),

  [p("apptReminder")]: APPOINTMENT_ROUTES.apptReminder(),

  [p("shopifyProductsReport")]: BI_ROUTES.sales.shopifyProducts(),

  [p("shopifySalesReport")]: BI_ROUTES.sales.shopifySales(),

  [p("salesSummaryReport")]: BI_ROUTES.sales.summaryReport(),

  [p("totalSalesReport")]: BI_ROUTES.sales.totalReport(),

  [p("netSalesReport")]: BI_ROUTES.sales.netSalesReport(),

  [p("productSalesReport")]: BI_ROUTES.sales.productSalesReport(),

  [p("categorySalesReport")]: BI_ROUTES.sales.categorySalesReport(),

  [p("packageSalesReport")]: BI_ROUTES.sales.packageSalesReport(),

  [p("pricingVariationsReport")]: BI_ROUTES.sales.pricingVariationsReport(),

  [p("discountsReport")]: BI_ROUTES.sales.discountsReport(),

  [p("commissionReport")]: BI_ROUTES.sales.commissionReport(),

  [p("tipReport")]: BI_ROUTES.sales.tipReport(),

  [p("cancellationFeesReport")]: BI_ROUTES.sales.cancellationFeesReport(),

  [p("retailReport")]: BI_ROUTES.sales.retailReport(),

  [p("bdAspireReport")]: BI_ROUTES.sales.bdAspireReport(),

  [p("refundsReport")]: BI_ROUTES.sales.refundsReport(),

  [p("costToCompanyReport")]: BI_ROUTES.sales.costToCompanyReport(),

  [p("giftCardsReport")]: BI_ROUTES.sales.giftCardsReport(),

  [p("giftCardsRevenueReport")]: BI_ROUTES.sales.giftCardsRevenueReport(),

  [p("paymentMethodsReport")]: BI_ROUTES.sales.paymentMethodsReport(),

  [p("taxesReport")]: BI_ROUTES.sales.taxesReport(),

  [p("treatmentPlansReport")]: BI_ROUTES.sales.treatmentPlansReport(),

  [p("shortTermLiabilityReport")]: BI_ROUTES.sales.shortTermLiabilityReport(),

  [p("staffTreatmentReport")]: BI_ROUTES.sales.staffTreatmentReport(),

  [p("aspireRedemptionReport")]: BI_ROUTES.sales.aspireRedemptionReport(),

  [p("membershipOverviewReport")]: BI_ROUTES.sales.membershipOverviewReport(),

  [p("membershipRevenueReport")]: BI_ROUTES.sales.membershipRevenueReport(),

  [p("membershipChurnReport")]: BI_ROUTES.sales.membershipChurnReport(),

  [p("membershipDeclineReport")]: BI_ROUTES.sales.membershipDeclineReport(),

  [p("membershipMrrReport")]: BI_ROUTES.sales.membershipMrrReport(),

  [p("inventoryReports")]: BI_ROUTES.inventoryReports(),

  [p("appointmentsReports")]: BI_ROUTES.appointmentsReports(),

  [p("npsReports")]: BI_ROUTES.npsReports(),

  [p("staffReports")]: BI_ROUTES.staffReports(),

  [p("timeCardReports")]: BI_ROUTES.timeCardReports(),

  [p("activeProducts")]: INVENTORY_ROUTES.activeProducts(),

  [p("priceList")]: INVENTORY_ROUTES.priceList(),

  [p("pricingVariation")]: INVENTORY_ROUTES.pricingVariation(),

  [p("inactiveProducts")]: INVENTORY_ROUTES.inactiveProducts(),

  [p("reconciliation")]: INVENTORY_ROUTES.reconciliation(),

  [p("stockTransfers")]: INVENTORY_ROUTES.stockTransfers(),

  [p("stockAlertEmails")]: INVENTORY_ROUTES.stockAlertEmails(),

  [p("suppliers")]: INVENTORY_ROUTES.suppliers(),

  [p("purchaseOrder")]: INVENTORY_ROUTES.purchaseOrder(),

  [p("smartConfig")]: APPOINTMENT_ROUTES.config(),

  [p("communications")]: APPOINTMENT_ROUTES.communicationSpecialInstructions(),

  [p("otherResources")]: APPOINTMENT_ROUTES.equipmentScheduleEquipment(),

  [p("bookingPortal")]: APPOINTMENT_ROUTES.bookingPortalDomainConfig(),

  [p("providerSchedule")]: APPOINTMENT_ROUTES.config(),

  [p("teammates")]: SETTINGS_ROUTES.users(),

  [p("treatmentInstructions")]: SETTINGS_ROUTES.preTreatmentInstructions(),

  [p("cosmeticQuestionnaires")]: SETTINGS_ROUTES.cosmeticQuestionnaires(),
};

export const CLIENT_TOKENS = {
  [p("clientLoyalty")]: SETTINGS_ROUTES.membershipWallet(),
  [p("clientPortalAccess")]: APPOINTMENT_ROUTES.clientPortalAccess(),
  [p("clientsReports")]: BI_ROUTES.clientsReports(),
  [p("deletedClients")]: SETTINGS_ROUTES.deletedPatients(),
};

export const PATIENT_TOKENS = {
  [p("patientLoyalty")]: SETTINGS_ROUTES.membershipWallet(),
  [p("patientPortalAccess")]: APPOINTMENT_ROUTES.clientPortalAccess(),
  [p("patientsReports")]: BI_ROUTES.clientsReports(),
  [p("deletedPatients")]: SETTINGS_ROUTES.deletedPatients(),
};
