import React from "react";
import { Labeled } from "../../shared/Labeled/Labeled";
import classes from "./MultiRowSelect.module.scss";
import { PatientBlock } from "./shared/PatientBlock/PatientBlock";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Option } from "./shared/Option/Option";

export function MultiRowSelect({
  name,
  value,
  patientOptions,
  onChange,
  renderOption,
}) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Labeled divider={Boolean(name)} label={name || ""}>
      <div className={classes.root}>
        {patientOptions.map((o, i) => (
          <PatientBlock
            key={i}
            label={`${tCommon("label.patient")} ${i + 1}`}
            value={value || []}
            options={o}
            onChange={onChange}
            renderOption={renderOption}
          />
        ))}
      </div>
    </Labeled>
  );
}

MultiRowSelect.Option = Option;
