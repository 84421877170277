import React, { Component } from "react";
import { toast } from "react-toastify";
import drilldown from "highcharts-drilldown";
import Highcharts from "highcharts";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { fetchProviderSalesGoalsData } from "../../../Actions/Dashboard/dashboardActions.js";
import { numberFormat, getCurrencySymbol } from "../../../Utils/services.js";
import { WidgetLoader } from "../components/WidgetLoader.js";

drilldown(Highcharts);

class SalesGoals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      monthData: [],
      monthValueData: [],
    };
  }

  componentDidMount() {
    if (this.props.data.sales_goals !== undefined) {
      for (let key in this.props.data.sales_goals.monthly_sales_array) {
        this.state.monthData.push(key);
        this.state.monthValueData.push(
          this.props.data.sales_goals.monthly_sales_array[key],
        );
      }
    }

    Highcharts.chart("salesGoals", {
      chart: {
        type: "areaspline",
        height: 250,
        width: 550,
        margin: [50, 50, 50, 50],
      },
      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      xAxis: {
        categories: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
        ],
        tickLength: 0,
      },
      yAxis: {
        title: {
          text: "",
        },
        plotLines: [
          {
            value: this.props.Monthgoal,
            color: "green",
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: "",
            },
          },
        ],
      },
      legend: {
        enabled: false,
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },
      tooltip: {
        shared: true,
        valuePrefix: getCurrencySymbol(),
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        areaspline: {
          fillOpacity: 0.5,
        },
      },
      series: [
        {
          name: "",
          data: this.state.monthValueData,
          marker: {
            width: 16,
            height: 16,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  }

  render() {
    return (
      <div>
        <div id="salesGoals"></div>
      </div>
    );
  }
}

class SalesGoalsWeek extends Component {
  constructor(props) {
    super(props);
    this.state = {
      weekData: [],
      weekValueData: [],
    };
  }

  componentDidMount() {
    if (this.props.data.sales_goals !== undefined) {
      for (let key in this.props.data.sales_goals.weekly_sales_array) {
        this.state.weekData.push(key);
        this.state.weekValueData.push(
          this.props.data.sales_goals.weekly_sales_array[key],
        );
      }
    }

    Highcharts.chart("salesGoalsWeek", {
      chart: {
        type: "areaspline",
        height: 250,
        width: 550,
        margin: [50, 50, 50, 50],
      },
      title: {
        text: "",
      },

      subtitle: {
        text: "",
      },

      xAxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        tickLength: 0,
      },

      yAxis: {
        title: {
          text: "",
        },
        plotLines: [
          {
            value: this.props.Weekgoal,
            color: "green",
            dashStyle: "shortdash",
            width: 2,
            label: {
              text: " ",
            },
          },
        ],
      },
      legend: {
        enabled: false,
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
      },

      plotOptions: {
        areaspline: {
          fillOpacity: 0.5,
        },
      },
      tooltip: {
        shared: true,
        //valueSuffix: 'USD',
        valuePrefix: getCurrencySymbol(),
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "",
          data: this.state.weekValueData,
          marker: {
            width: 16,
            height: 16,
          },
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: "horizontal",
                align: "center",
                verticalAlign: "bottom",
              },
            },
          },
        ],
      },
    });
  }

  render() {
    return (
      <div>
        <div id="salesGoalsWeek"></div>
      </div>
    );
  }
}

class WidgetProviderSalesGoals extends React.Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      monthClass: "Month",
      weekClass: "Week no-display",
      buttonClassMonth: "btn btn-default graph-btn select",
      buttonClassWeek: "btn btn-default graph-btn",
      totalGoals: 0,
      monthGoal: 0,
      weekGoal: 0,
      PSalesGoalData: [],
      showLoader7: false,
    };
  }

  handleMonth = () => {
    let dataArr = this.state.PSalesGoalData.sales_goals;
    if (this.state.PSalesGoalData.sales_goals) {
      this.setState({
        totalGoals: dataArr.monthly_sales_goal,
        monthGoal: dataArr.monthly_sales_goal,
      });
    }
    this.setState({
      weekClass: "Week no-display",
      monthClass: "Month display",
      buttonClassMonth: "btn btn-default graph-btn select",
      buttonClassWeek: "btn btn-default graph-btn",
    });
  };

  handleWeek = () => {
    let dataArr = this.state.PSalesGoalData.sales_goals;
    if (this.state.PSalesGoalData.sales_goals) {
      this.setState({
        totalGoals: dataArr.weekly_sales_goal,
        weekGoal: dataArr.weekly_sales_goal,
      });
    }
    this.setState({
      monthClass: "Month no-display",
      weekClass: "Week display",
      buttonClassWeek: "btn btn-default graph-btn select",
      buttonClassMonth: "btn btn-default graph-btn ",
    });
  };

  componentDidMount() {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      dash_your_sales_goal_text:
        languageData.dashboard["dash_your_sales_goal_text"],
      dash_total_text: languageData.dashboard["dash_total_text"],
      dash_lbl_month_text: languageData.dashboard["dash_lbl_month_text"],
      dash_lbl_week_text: languageData.dashboard["dash_lbl_week_text"],
    });
    let formData = {
      params: {
        from_date: "2019-11-01",
        to_date: "2019-11-10",
      },
    };
    this.setState({
      showLoader7: true,
    });

    this.props.fetchProviderSalesGoalsData(formData);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.PSalesGoalData != undefined &&
      nextProps.PSalesGoalData.data != prevState.PSalesGoalData.data &&
      nextProps.PSalesGoalData.status == 200
    ) {
      returnState.PSalesGoalData = nextProps.PSalesGoalData.data;
      returnState.showLoader7 = false;
      returnState.totalGoals =
        nextProps.PSalesGoalData.data.sales_goals.monthly_sales_goal;
      returnState.monthGoal =
        nextProps.PSalesGoalData.data.sales_goals.monthly_sales_goal;
      returnState.weekGoal =
        nextProps.PSalesGoalData.data.sales_goals.weekly_sales_goal;
    }
    return returnState;
  }

  render() {
    return (
      <div className="setting-setion m-b-15">
        <div className="dashboardSectionTitle m-t-5">
          <b>{"Provider Sales Goals"}</b>
          <div
            className="btn-group graph-toggle"
            id="sale-group-toggle"
            role="group"
            aria-label="..."
          >
            <button
              type="button"
              className={this.state.buttonClassMonth}
              id="sale-goal-monthly"
              onClick={this.handleMonth}
              data-identifier="monthy"
            >
              {"Month"}
            </button>
            <button
              type="button"
              className={this.state.buttonClassWeek}
              id="sale-goal-weekly"
              onClick={this.handleWeek}
              data-identifier="weekly"
            >
              {"Week"}
            </button>
          </div>
          <span className="pull-right">
            {this.state.dash_total_text} <b id="total-sale"></b>
            {getCurrencySymbol() + "" + numberFormat(this.state.totalGoals)}
          </span>
        </div>
        <div className={this.state.monthClass}>
          {this.state.PSalesGoalData.sales_goals && (
            <SalesGoals
              data={this.state.PSalesGoalData}
              Monthgoal={this.state.monthGoal}
            />
          )}
        </div>
        <div className={this.state.weekClass}>
          {this.state.PSalesGoalData.sales_goals && (
            <SalesGoalsWeek
              data={this.state.PSalesGoalData}
              Weekgoal={this.state.weekGoal}
            />
          )}
        </div>
        {this.state.showLoader7 && <WidgetLoader />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.DashboardReducer.action === "DASHBOARD_PROVIDER_GOALS_DATA") {
    if (state.DashboardReducer.data.status !== 200) {
      toast.error(languageData.global[state.DashboardReducer.data.message]);
    } else {
      returnState.PSalesGoalData = state.DashboardReducer.data;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchProviderSalesGoalsData: fetchProviderSalesGoalsData,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WidgetProviderSalesGoals);
