import { useFormik } from "formik";

/**
 * @template {FormikValues} Values
 * @param {FormikConfig<Values>} args
 */
export function useExtendedFormik(args) {
  const form = useFormik(args);

  const getError = (field) => {
    if (form.touched[field] && form.errors[field]) {
      return form.errors[field];
    }
  };

  const getFirstError = () => {
    const firstErrorEntry = Object.entries(form.errors)?.[0];

    if (form.touched?.[firstErrorEntry?.[0]]) {
      return firstErrorEntry[1];
    }
  };

  const changeField = (field, value) => {
    form.setFieldTouched(field);
    form.setFieldValue(field, value);
  };

  return {
    ...form,
    getError,
    getFirstError,
    changeField,
  };
}
