import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  API_DATE_FORMAT,
  HTTP_ENDPOINTS,
  ORDER_TYPES,
  QUERY_KEYS,
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_TYPES,
} from "../../consts/api";
import { http } from "../../services/HttpService";
import {
  numberOr,
  packedListOr,
  removeNullishFromShape,
  unwrapOr,
} from "../../utilities/general";
import { createQueryKeyFromShape } from "../../utilities/api";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";
import { CURRENCY } from "../../consts/general";

export const SALES_MEMBERSHIP_REPORTS_ORDER_BY = {
  clientName: "patient",
  clinicName: "clinic",
  tierName: "tier",
  membershipFeeAmount: "membership_fee_amount",
  subscriptionType: "payment_frequency",
  status: "status",
  loyalty: "loyalty",
  startDate: "start_date",
  drawDay: "draw_day",
  nextAppointmentDate: "next_appointment_date",
};

/*
  SCHEMAS
*/

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  period: yup
    .object({
      from: yup.date().nullable(),
      to: yup.date().nullable(),
    })
    .nullable(),

  filter: yup.object({
    clinicIds: yup.array().of(yup.number()).nullable(),

    tierIds: yup.array().of(yup.number()).nullable(),

    subscriptionType: yup
      .string()
      .test({
        test: (v) => Object.values(SUBSCRIPTION_TYPES).includes(v) || !v,
      })
      .nullable(),

    status: yup
      .string()
      .test({
        test: (v) => Object.values(SUBSCRIPTION_STATUS).includes(v) || !v,
      })
      .nullable(),
  }),

  order: yup
    .object({
      by: yup
        .string()
        .test({
          test: (v) =>
            Object.values(SALES_MEMBERSHIP_REPORTS_ORDER_BY).includes(v),
        })
        .required(),
      direction: yup
        .string()
        .test({
          name: "validOrder",
          test: (value) => Object.values(ORDER_TYPES).includes(value),
        })
        .required(),
    })
    .nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    newMembers: yup.number().required(),
    canceledMemberships: yup.number().required(),
    activeMemberships: yup.number().required(),
    totalSetupFeeCollected: yup.number().required(),
    totalFeeCollected: yup.number().required(),

    reports: yup
      .array()
      .of(
        yup.object({
          id: yup.number().required(),
          clientName: yup.string().nullable(),
          clinicName: yup.string().nullable(),
          tierName: yup.string().nullable(),
          membershipFeeAmount: yup.number().nullable(),
          drawDay: yup.number().nullable(),
          startDate: yup.string().nullable(),
          subscriptionType: yup.string().nullable(),
          status: yup.string().nullable(),
          loyalty: yup.number().required(),
          nextAppointmentDate: yup.string().nullable(),
          patientId: yup.number().nullable(),
          currency: yup.string().required(),
        }),
      )
      .required(),
  }),
);

/*
  COMPOSERS
*/

const composeResponseSchema = (res, req) => {
  const data = unwrapOr(() => res.data.data, {});
  const reports = unwrapOr(() => res.data.data.report.data, []);
  const posData = unwrapOr(() => res.data.global_settings.pos_data, {});

  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data.report, {}),
      pageSize: req.pageSize,
    }),
    newMembers: data.new_members || 0,
    canceledMemberships: data.cancelled_memberships || 0,
    activeMemberships: data.active_memberships || 0,
    totalSetupFeeCollected: data.total_setup_fee_collected || 0,
    totalFeeCollected: data.total_fee_collected || 0,

    reports: reports.map((r) => ({
      id: r.id,
      clientName: r.patient || null,
      clinicName: r.clinic || null,
      tierName: r.tier || null,
      membershipFeeAmount: numberOr(r.membership_fee_amount, null),
      drawDay: r.draw_day || null,
      startDate: r.start_date || null,
      subscriptionType: r.payment_frequency || null,
      status: r.status || null,
      loyalty: r.loyalty,
      nextAppointmentDate: r.next_appointment_date || null,
      patientId: r.patient_id || null,
      currency: posData.currency_code || CURRENCY.usd,
    })),
  };
};

// ---------

function createSalesMembershipReportsQueryKey({
  order,
  filter,
  fromDate,
  toDate,
}) {
  return [QUERY_KEYS.salesMembershipReports, order, filter, fromDate, toDate];
}

const createKeyFromShape = (shape) =>
  unwrapOr(() => createQueryKeyFromShape(removeNullishFromShape(shape)), null);

export function useSalesMembershipReportsQuery({
  payload = {},
  options = {},
} = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });

  const fromDate = unwrapOr(
    () => moment(req.period.from).format(API_DATE_FORMAT),
    null,
  );
  const toDate = unwrapOr(
    () => moment(req.period.to).format(API_DATE_FORMAT),
    null,
  );

  return useInfiniteQuery({
    queryKey: createSalesMembershipReportsQueryKey({
      order: createKeyFromShape(req.order),
      filter: createKeyFromShape(req.filter),
      fromDate,
      toDate,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.post(
        HTTP_ENDPOINTS.getSalesMembershipReports(),
        removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          start_date: moment(req.period.from).format(API_DATE_FORMAT),
          end_date: moment(req.period.to).format(API_DATE_FORMAT),
          clinic_ids: packedListOr(
            unwrapOr(() => req.filter.clinicIds, null),
            null,
          ),
          tier_ids: packedListOr(
            unwrapOr(() => req.filter.tierIds, null),
            null,
          ),
          payment_frequency: unwrapOr(() => req.filter.subscriptionType, null),
          status: unwrapOr(() => req.filter.status, null),
          sortBy: unwrapOr(() => req.order.by, null),
          order: unwrapOr(() => req.order.direction, null),
        }),
      );
      return responseSchema.validateSync(composeResponseSchema(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
