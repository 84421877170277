import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useFormik } from "formik";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Select } from "../../../../../../shared/Select/Select";
import {
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_STATUS,
} from "../../../../../../consts/api";
import { ReportModalFilter } from "../../../../components/ReportModalFilter/ReportModalFilter";
import { selectOptionSchema } from "../../../../../../shared/Select/Select.schemas";
import { hasLength } from "../../../../../../utilities/general";
import { useClinicsQuery } from "../../../../../../api/queries/useClinicsQuery";
import { useMembershipTiersQuery } from "../../../../../../api/queries/useMembershipTiersQuery";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

const schema = yup.object({
  clinics: yup.array().of(selectOptionSchema),
  tiers: yup.array().of(selectOptionSchema),
  subscriptionType: yup.string(),
  status: yup.string(),
});

const initialValues = {
  clinics: [],
  tiers: [],
  subscriptionType: "",
  status: "",
};

export function ModalFilter({ isOpen, onClose, filter }) {
  const { tCommon } = useAppTranslation.Common();
  const { tBi } = useAppTranslation.BusinessInsights();
  const { data: clinics, isLoading: isClinicsLoading } = useClinicsQuery();
  const { data: tiers, isLoading: isTiersLoading } = useMembershipTiersQuery();

  const handleApply = (values) => {
    filter.update(values);
    onClose();
  };

  const { values, setFieldValue, dirty, handleSubmit, setValues } = useFormik({
    onSubmit: handleApply,
    validationSchema: schema,
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      ...filter.value,
    },
  });

  const subscriptionTypeOptions = [
    {
      label: tCommon("label.any"),
      value: initialValues.subscriptionType,
    },
    {
      label: tCommon("subscriptionType.monthly"),
      value: SUBSCRIPTION_TYPES.monthly,
    },
    {
      label: tCommon("subscriptionType.yearly"),
      value: SUBSCRIPTION_TYPES.yearly,
    },
  ];

  const membershipStatusOptions = [
    {
      label: tBi("membershipOverviewReport.filter.status.activeHold"),
      value: initialValues.status,
    },
    {
      label: tCommon("subscriptionStatus.active"),
      value: SUBSCRIPTION_STATUS.active,
    },
    {
      label: tCommon("subscriptionStatus.inactive"),
      value: SUBSCRIPTION_STATUS.inactive,
    },
    {
      label: tCommon("subscriptionStatus.onHold"),
      value: SUBSCRIPTION_STATUS.hold,
    },
  ];

  const clinicOptions = (clinics || []).map((clinic) => ({
    label: clinic.name,
    value: clinic.id,
  }));

  const tiersOptions = (tiers || []).map((tier) => ({
    label: tier.name,
    value: tier.id,
  }));

  return (
    <ReportModalFilter
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitDisabled={!dirty}
      isFilterTouched={Object.values(values).some(hasLength)}
      onReset={() => setValues(initialValues)}
    >
      <div>
        <InputLabel>{tCommon("label.clinics")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.clinics}
          onChange={(options) => setFieldValue("clinics", options)}
          options={clinicOptions}
          isLoading={isClinicsLoading}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.membershipName")}</InputLabel>
        <Select
          isMulti
          isSearchable
          closeMenuOnSelect={false}
          value={values.tiers}
          onChange={(options) => setFieldValue("tiers", options)}
          options={tiersOptions}
          isLoading={isTiersLoading}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.membershipType")}</InputLabel>
        <Select
          value={subscriptionTypeOptions.find(
            (o) => o.value === values.subscriptionType,
          )}
          onChange={(option) => setFieldValue("subscriptionType", option.value)}
          options={subscriptionTypeOptions}
        />
      </div>
      <div>
        <InputLabel>{tCommon("label.status")}</InputLabel>
        <Select
          value={membershipStatusOptions.find((o) => o.value === values.status)}
          onChange={(option) => setFieldValue("status", option.value)}
          options={membershipStatusOptions}
        />
      </div>
    </ReportModalFilter>
  );
}

ModalFilter.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filter: PropTypes.shape({
    value: PropTypes.object,
    update: PropTypes.func,
  }).isRequired,
};
