import { useMemo, useState } from "react";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { hasLength } from "../../../../utilities/general";
import { uiNotification } from "../../../../services/UINotificationService";
import { useSalesGoalsQuery } from "../../../../api/queries/useSalesGoalsQuery";
import { ORDER_TYPES } from "../../../../consts/api";

const prepareReqFilter = (filterState) => ({
  clinicId: filterState.clinic?.value || undefined,
});

const prepareReqOrder = (orderState) => {
  if (Object.values(orderState).every(Boolean)) {
    return {
      by: orderState.by,
      direction: orderState.direction,
    };
  }
  return null;
};

export function useGoals() {
  const { tCommon } = useAppTranslation.Common();
  const { tSettings } = useAppTranslation.Settings();

  /* Modifiers */

  const [order, setOrder] = useState({
    by: "",
    direction: "",
  });

  const updateOrder = (orderFieldKey) => {
    setOrder({
      by: orderFieldKey,
      direction:
        order.direction === ORDER_TYPES.desc
          ? ORDER_TYPES.asc
          : ORDER_TYPES.desc,
    });
  };

  const [filter, setFilter] = useState({
    clinic: null,
  });

  /* Data */

  const {
    data,
    isLoading,
    isFetching,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useSalesGoalsQuery({
    payload: {
      filter: prepareReqFilter(filter),
      order: prepareReqOrder(order),
      pageSize: 25,
    },
    options: {
      staleTime: 0,
      cacheTime: 0,
      keepPreviousData: true,
      onError: () => {
        uiNotification.error(tSettings("salesGoals.error.fetch"));
      },
    },
  });

  const preparedData = useMemo(() => {
    if (data?.pages) {
      return data.pages.map((p) => p.data).reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const clinicOptions = useMemo(() => {
    return [
      {
        label: tCommon("label.all"),
        value: 0,
      },
      ...(data?.pages?.[0]?.clinics?.map((c) => ({
        label: c.clinic_name,
        value: c.id,
      })) || []),
    ];
  }, [data?.pages]);

  /* Statuses */

  const isSoftLoading = Boolean(
    (Object.values({
      ...order,
    }).some(hasLength) ||
      filter.clinic) &&
      isFetching &&
      !isLoading &&
      !isFetchingNextPage,
  );

  // ---------

  return {
    data: {
      value: preparedData,
      hasMore: Boolean(hasNextPage),
      isNextFetching: isFetchingNextPage,
      isLoading: isLoading,
      fetchMore: fetchNextPage,
      isSoftLoading,
    },
    filter: {
      value: filter,
      update: setFilter,
    },
    order: {
      value: order,
      update: updateOrder,
    },
    clinicOptions,
  };
}
