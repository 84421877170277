import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

const requestSchema = yup.object({
  pageSize: yup.number().required(),
  searchTerm: yup.string().nullable(),
});

const responseSchema = paginationSchema.concat(
  yup.object({
    templates: yup.array().of(
      yup.object({
        id: yup.number().required(),
        title: yup.string().required(),
        firstName: yup.string().nullable(),
        lastName: yup.string().nullable(),
        questions: yup
          .array()
          .of(
            yup.object({
              id: yup.number().required(),
              procedureTemplateId: yup.number().required(),
            }),
          )
          .required(),
      }),
    ),
  }),
);

const composeResponse = (res, req) => {
  const data = unwrapOr(() => res.data.data.data, []);
  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data, {}),
      pageSize: req.pageSize,
    }),
    templates: data.map((p) => ({
      id: p.id,
      title: p.title,
      firstName: p.firstname || null,
      lastName: p.lastname || null,
      questions: p?.procedure_template_question?.map((q) => ({
        id: q.id,
        procedureTemplateId: q.procedure_template_id,
      })),
    })),
  };
};

function createProcedureTemplatesQueryKey({ searchTerm, pageSize }) {
  return [QUERY_KEYS.procedureTemplates, searchTerm, pageSize].filter(Boolean);
}

export function useProcedureTemplatesQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createProcedureTemplatesQueryKey({
      searchTerm: req.searchTerm,
      pageSize: req.pageSize,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getProcedureTemplates(), {
        params: removeNullishFromShape({
          page: pageParam,
          pagesize: req.pageSize,
          term: req.searchTerm || null,
        }),
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
