import axios from "axios";
import { REQ_HEADERS, SERVER_MESSAGE_KEYS } from "../consts/api";
import { unwrapOr } from "../utilities/general";
import { handleInvalidToken, setConfigData } from "../Utils/services";
import { LOCAL_STORAGE_KEYS } from "../consts/localStorage";

export class HttpService {
  constructor(axiosInstance) {
    this.repository = axiosInstance;
  }

  async call({ url, method, data, params }) {
    return this.repository({
      url,
      method,
      data,
      params,
    });
  }

  async get(url, config) {
    return this.repository.get(url, config);
  }

  async post(url, data, config) {
    return this.repository.post(url, data, config);
  }

  async put(url, data, config) {
    return this.repository.put(url, data, config);
  }

  async patch(url, data, config) {
    return this.repository.patch(url, data, config);
  }

  async delete(url, config) {
    return this.repository.delete(url, config);
  }
}

/**
 * ======================================
 */

export const requestInterceptors = {
  success: function (config) {
    return {
      ...config,
      headers: {
        [REQ_HEADERS.accessToken]: localStorage.getItem(
          LOCAL_STORAGE_KEYS.accessToken,
        ),
      },
    };
  },
  error: function (error) {
    return Promise.reject(error);
  },
};

export const responseInterceptors = {
  success: function (response) {
    const globalSettings = unwrapOr(() => response.data.global_settings, null);
    if (globalSettings) {
      setConfigData(globalSettings);
    }
    return response;
  },
  error: function (error) {
    if (error.response) {
      const msg = unwrapOr(() => error.response.data.message, "");
      if (
        msg === SERVER_MESSAGE_KEYS.invalidToken ||
        msg === SERVER_MESSAGE_KEYS.sessionTimeout ||
        msg === SERVER_MESSAGE_KEYS.tokenNotFound
      ) {
        handleInvalidToken();
      }
    }
    return Promise.reject(error);
  },
};

/**
 * ======================================
 */

export const http = (() => {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  axiosInstance.interceptors.request.use(
    requestInterceptors.success,
    requestInterceptors.error,
  );

  axiosInstance.interceptors.response.use(
    responseInterceptors.success,
    responseInterceptors.error,
  );

  return new HttpService(axiosInstance);
})();
