/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "../../../../shared/Button/Button";
import useAdvancedState from "../../../../utilities/hooks/useAdvancedState";
import classes from "../sass/schedule.module.scss";
import { truncateString } from "../../../../utilities/general";

export const ProviderClinicsDropdown = ({
  clinicsAssociated,
  updateSelectedClinics,
  selectedClinics: selectedClinicIds,
}) => {
  const {
    state: { isMenuOpen, selectedClinics },
    updateState,
    toggleField,
  } = useAdvancedState({
    isMenuOpen: false,
    selectedClinics: [],
  });

  const clinicsAssociatedIds = useMemo(
    () => clinicsAssociated.map((clinicDetails) => clinicDetails.id),
    [clinicsAssociated],
  );

  useEffect(() => {
    if (clinicsAssociated.length) {
      updateState({
        selectedClinics: clinicsAssociatedIds,
      });
    }
  }, [clinicsAssociated?.length]);

  const selectUnselectClinic = (clinicId) => {
    updateState({
      selectedClinics: selectedClinics.includes(clinicId)
        ? selectedClinics.filter(
            (selectedClinicId) => selectedClinicId !== clinicId,
          )
        : [...selectedClinics, clinicId],
    });
  };

  const toggleAll = () => {
    updateState({
      selectedClinics:
        selectedClinics.length === clinicsAssociated?.length
          ? []
          : clinicsAssociated.map((x) => x.id),
    });
  };

  const handleCloseClinicsMenu = () => {
    if (isMenuOpen) {
      toggleField("isMenuOpen");

      if (selectedClinicIds.length) {
        const selectedClinicIdsSet = new Set(selectedClinicIds);
        updateState({
          selectedClinics: clinicsAssociatedIds.filter((clinicId) =>
            selectedClinicIdsSet.has(clinicId),
          ),
        });
      } else {
        updateState({ selectedClinics: clinicsAssociatedIds });
      }
    }
  };

  return (
    <div className={classes.selectedClinicsDropdown}>
      <button
        onClick={() => toggleField("isMenuOpen")}
        className="calendar-dropdown"
      >
        {clinicsAssociated.length === selectedClinics.length
          ? "All Clinics"
          : `(${selectedClinics.length}) Clinic${
              selectedClinics.length > 1 ? `s` : ``
            }`}
        <a className="cal-arrow pull-right">
          <img
            alt=""
            src={isMenuOpen ? "/images/cal-up.png" : "/images/cal-down.png"}
          />
        </a>
      </button>
      <Dropdown
        id="provider-clinics"
        open={isMenuOpen}
        onToggle={handleCloseClinicsMenu}
      >
        <Dropdown.Toggle className="no-display" />
        <Dropdown.Menu className={classes.dropdownMenu}>
          <div className={classes.dropdownContent}>
            <div className={classes.clinicOption} onClick={toggleAll}>
              <span>
                <input
                  type="checkbox"
                  name={"all"}
                  checked={selectedClinics.length === clinicsAssociated?.length}
                  className="filled-checkbox"
                  onChange={() => {}}
                />
              </span>
              <span>All</span>
            </div>
            {clinicsAssociated?.map((clinicDetails) => {
              const clinic_name =
                clinicDetails.clinic_name || clinicDetails.clinic.clinic_name;

              const id = clinicDetails.clinic_id || clinicDetails.id;

              const isClinicSelected = selectedClinics.includes(id);

              return (
                <div
                  className={classes.clinicOption}
                  onClick={() => selectUnselectClinic(id)}
                  key={`clinic-${id}`}
                >
                  <span>
                    <input
                      type="checkbox"
                      name={clinic_name}
                      checked={isClinicSelected}
                      className="filled-checkbox"
                      onChange={() => {}}
                    />
                  </span>
                  <span>{truncateString(clinic_name)}</span>
                </div>
              );
            })}
          </div>
          <div className={classes.dropdownContent}>
            <Button
              fullWidth
              size="small"
              onClick={() => {
                updateSelectedClinics(selectedClinics);
                toggleField("isMenuOpen");
              }}
              className={classes.copyDayScheduleButton}
              isDisabled={!selectedClinics.length}
            >
              Apply
            </Button>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
