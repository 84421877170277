/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import cx from "clsx";
import { Scrollbars } from "react-custom-scrollbars";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import Sidebar from "../InventorySidebar";
import Loader from "../../Common/Loader";
import picClose from "../../../_legacy/images/popupClose.png";
import {
  positionFooterCorrectly,
  isPositiveNumber,
  getCurrencySymbol,
} from "../../../Utils/services";
import {
  searchProduct,
  findClinics,
  addNewPackage,
  getPackageData,
  updatePackage,
  deletePackage,
  deleteProductFromChartingPackage,
  exportEmptyData,
  searchProductForRecipe,
  searchCategoryForRecipe,
  searchProductCategory,
  submitCollectiveTotal,
  deleteProductForCollective,
  deleteBucket,
  getProductsForMedicalAndRetail,
  searchProductCategoryForSingleCategory,
} from "../../../Actions/Inventory/inventoryActions";
import { getPrecision, max } from "../../../utilities/general";
import { uiNotification } from "../../../services/UINotificationService";

const MIN_UNITS_ERROR =
  "Units value should be greater or equal than min value (start at unit)";

let selectedProducts = [];

const checkPermission = (globalPermission, specificPermission) => {
  return globalPermission.find((str) => str === specificPermission);
};

class CreateEditChartingPackages extends Component {
  constructor(props) {
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    super(props);
    let permissionData = JSON.parse(
      localStorage.getItem("userData"),
    ).permissions;
    this.state = {
      chartingPackageName: "",
      status: "0",
      search_product_keyword: "",
      is_product_search: false,
      searched_product_id: "",
      searched_product_name: "",
      is_search_first_time: true,
      productList: [],
      globalLang: languageData.global,
      membership_type_product: [],
      selected_products: [],
      showSearch: true,
      packageNameError: false,
      edit: false,
      id: this.props.match.params.id ? this.props.match.params.id : "",
      showModal: false,
      settingsLang: languageData.settings,
      showProductDeleteModal: false,
      saveButton: checkPermission(
        permissionData,
        "create-edit-charting-package",
      )
        ? true
        : false,
      inventoryLang: languageData.inventory,
      appointmentLang: languageData.appointments,
      managePrice: "collective_level_recipe",
      products_color: [],
      totalPriceError: false,
      typeOfBucket: "",
      bucketArray: [],
      productListForRecipe: [],
      categoryListForRecipe: [],
      selectedBucketType: "service",
      service_products: [],
      medical_products: [],
      retail_products: [],
      searchDataInProducts: false,
    };
  }

  componentDidMount() {
    selectedProducts = [];
    if (this.props.match.params.id) {
      this.setState({ edit: true, showLoader: true, showSearch: false });
      this.props.getPackageData(this.props.match.params.id);
    } else {
      this.setState({ edit: false, showSearch: true });
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const name = event.target.name;
    let value = target.value;
    switch (target.type) {
      case "checkbox": {
        value = target.checked;
        break;
      }
    }
    this.setState({ [event.target.name]: value });
    if (name == "managePrice") {
      if (this.state.edit == true) {
        if (this.state.oldManagePrice == value) {
          this.setState({ showLoader: true });
          this.props.getPackageData(this.props.match.params.id);
        } else {
          selectedProducts = [];

          this.setState({
            showSearch: true,
            selected_products: selectedProducts,
            is_product_search: false,
            searched_product_id: 0,
            searched_product_name: "",
            search_product_keyword: "",
            bucketArray: [],
          });
        }
      } else {
        selectedProducts = [];
        this.setState({
          showSearch: true,
          selected_products: selectedProducts,
          is_product_search: false,
          searched_product_id: 0,
          searched_product_name: "",
          search_product_keyword: "",
        });
      }
    }

    let checkValue;
    checkValue = event.target.name.split("-");
    if (checkValue[0] == "new") {
      let returnState = {};
      this.state.selected_products &&
        this.state.selected_products.map((data) => {
          if (Number(checkValue[5]) == Number(data.product_id)) {
            if (checkValue[3] == data.clinicsList[0].clinic_id) {
              returnState["priceRepeat-" + data.product_id] = false;
            }
          }
        });
      this.setState(returnState);
    }
  };

  handleProductChange = (event) => {
    const target = event.target;
    let value = target.value;
    let returnState = {};
    returnState[event.target.name] = value;
    returnState.is_product_search = true;
    returnState.searched_product_id = 0;
    returnState.searched_product_name = "";
    this.setState(returnState);
    this.searchProductByName(value);
  };

  searchProductByName = (value) => {
    let formData = { params: { limit: 20, product_type: "injectable" } };
    let selected_product_ids = [];
    this.state.selected_products.map((obj) => {
      if (obj.product_id) selected_product_ids.push(obj.product_id);
    });
    if (selected_product_ids.length) {
      let formIds = selected_product_ids.join(",");
      formData.params.selected_product_ids = formIds;
    }
    if (typeof value === "string") {
      value = value.trim();
      if (value.length >= 3) {
        formData.params.term = value.trim();
        value = value.trim();
        this.props.searchProduct(formData);
      } else {
        this.setState({ is_search_first_time: true, productList: [] });
      }
    }
  };

  handleOnFocus = () => {
    this.setState({ is_search_first_time: true, productList: [] });
  };

  selectProduct = (event) => {
    this.setState({ showLoader: true });
    let name = event.currentTarget.dataset.name;
    let id = event.currentTarget.dataset.id;
    let returnState = {};
    returnState.searched_product_id = id;
    returnState.searched_product_name = name;
    returnState.search_product_keyword = name;
    returnState.is_product_search = false;
    returnState.showSearch = false;
    this.setState(returnState);
    if (this.state.managePrice == "product_level") {
      this.props.findClinics(id);
      positionFooterCorrectly();
    } else {
      selectedProducts.push({
        product_name: name,
        product_id: id,
        unit: 0,
        color: "",
        clinics: [],
      });
      let returnState = {
        selected_products: selectedProducts,
        showLoader: false,
      };
      returnState["product-unit-" + id] = "";
      returnState["product-color" + id] = "";
      returnState.selected_products = selectedProducts;
      returnState.showLoader = false;
      this.setState(returnState);
    }
  };

  openProductsCategoryOption = () => {
    this.setState({
      showLoader: true,
      openProductAndCategoriesPopup: true,
      popupProducts: [],
      selected_type: "service",
      selectionType: "products",
      searchDataInProducts: false,
      categoryData: [],
      searchForRetailOrMedical: "",
      makeBundle: false,
      bundleName: "",
      bundle_error: false,
    });
    let idsToSend = [];
    if (this.state.service_products && this.state.service_products.length > 0) {
      this.state.service_products.map((data) => {
        if (data.id) {
          idsToSend.push(data.id);
        }
      });
    }
    let formData = {
      bucket_type: "service",
      selected_ids: idsToSend,
    };
    this.props.getProductsForMedicalAndRetail(formData);
  };
  dismissMedicalPopup = () => {
    this.setState({ openMedicalPopup: false, searchDataInProducts: false });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.productsData != undefined &&
      nextProps.productsData != prevState.productList &&
      nextProps.productsDataTime != prevState.productsDataTime
    ) {
      returnState.productList = nextProps.productsData.data.products;
      returnState.showProductDeleteModal = false;
      returnState.showLoader = false;
      returnState.productsDataTime = nextProps.productsDataTime;
      returnState.is_search_first_time = prevState.is_search_first_time
        ? false
        : prevState.is_search_first_time;
    }

    if (
      nextProps.clinicsList &&
      nextProps.clinicListDataTime != prevState.clinicListDataTime
    ) {
      if (
        nextProps.clinicsList.product_per_clinic &&
        nextProps.clinicsList.product_per_clinic.length > 0
      ) {
        nextProps.clinicsList.product_per_clinic.map((data) => {
          data.show_price = true;
          returnState[
            "new-price-clinic-" +
              data.clinic_id +
              "-product-" +
              prevState.searched_product_id
          ] = "";
          returnState[
            "new-price-clinic-" +
              data.clinic_id +
              "-product-" +
              prevState.searched_product_id +
              "error"
          ] = false;
        });
      }
      selectedProducts.push({
        product_name: prevState.searched_product_name,
        product_type:
          nextProps.clinicsList.product &&
          nextProps.clinicsList.product.product_type_label
            ? nextProps.clinicsList.product.product_type_label === "retail"
              ? "retail"
              : nextProps.clinicsList.product.product_type_label ===
                "medical_supplies"
              ? "medical_supplies"
              : "services"
            : "services",
        product_id: prevState.searched_product_id,
        clinicsList: nextProps.clinicsList.product_per_clinic
          ? nextProps.clinicsList.product_per_clinic
          : [],
      });
      returnState.selected_products = selectedProducts;
      returnState.showLoader = false;
      returnState.clinicListDataTime = nextProps.clinicListDataTime;
      nextProps.exportEmptyData();
    }

    if (
      nextProps.medicalAndRetailProducts != undefined &&
      nextProps.medicalAndRetailProductsTime !=
        prevState.medicalAndRetailProductsTime
    ) {
      returnState.medicalAndRetailProductsTime =
        nextProps.medicalAndRetailProductsTime;
      returnState.popupProducts =
        nextProps.medicalAndRetailProducts.product_data;
      returnState.popupProducts.map((data) => {
        returnState["popup-product-" + data.id] = false;
      });
      returnState.categoryData = nextProps.medicalAndRetailProducts
        .category_data
        ? nextProps.medicalAndRetailProducts.category_data
        : [];
      returnState.categoryData.map((data) => {
        returnState["popup-category-" + data.id] = false;
      });
      returnState.showLoader = false;
    }

    if (
      nextProps.singleCategoryProducts != undefined &&
      nextProps.singleCategoryProductsDataTime !=
        prevState.singleCategoryProductsDataTime
    ) {
      returnState.singleCategoryProductsDataTime =
        nextProps.singleCategoryProductsDataTime;
      if (nextProps.singleCategoryProducts.length == 0) {
        returnState.showLoader = false;
        toast.error(
          `Selected Category ${prevState.selected_category} does not have any service type products`,
        );
      } else {
        returnState.showLoader = false;
      }
    }

    if (
      nextProps.recipeProductOfCategory != undefined &&
      nextProps.recipeProductCategoryDataTime !=
        prevState.recipeProductCategoryDataTime
    ) {
      returnState.recipeProductCategoryDataTime =
        nextProps.recipeProductCategoryDataTime;
      returnState.showLoader = false;
      let categories = [];
      let products = prevState.service_products;
      nextProps.recipeProductOfCategory.map((data) => {
        categories.push({
          category_name: data.category.category_name,
          category_id: data.category.id,
        });
      });

      let uniqueIds =
        categories.length > 0
          ? [
              ...new Map(
                categories.map((item) => [item["category_id"], item]),
              ).values(),
            ]
          : [];
      uniqueIds.length > 0 &&
        uniqueIds.map((data2) => {
          products.push({
            category_name: data2.category_name,
            category_id: data2.category_id,
            type: "category",
            price_per_unit: "",
            units: "",
          });

          nextProps.recipeProductOfCategory.map((data) => {
            if (data2.category_id == data.category.id) {
              products.push({
                id: data.id,
                product_name: data.product_name,
                category_id: data.category.id,
                color: "",
                price_per_unit: "",
                units: "",
                min: data.product?.start_at_unit,
              });
            }
          });
        });

      returnState.service_products = products;
      returnState.openProductAndCategoriesPopup = false;
    }

    if (nextProps.redirect != undefined && nextProps.redirect == true) {
      returnState.showLoader = false;
      nextProps.history.push("/inventory/standard-packages");
    }

    if (
      nextProps.packageDetails != undefined &&
      nextProps.packageDetailsDataTime != prevState.packageDetailsDataTime
    ) {
      returnState.packageDetailsDataTime = nextProps.packageDetailsDataTime;
      returnState.chartingPackageName = nextProps.packageDetails.name;
      returnState.managePrice = nextProps.packageDetails.package_type;
      returnState.deleteProductBucketModal = false;
      returnState.showProductDeleteModal = false;
      returnState.deleteBucketPopup = false;
      returnState.showLoader = false;
      returnState.oldManagePrice = nextProps.packageDetails.package_type;
      if (nextProps.packageDetails.package_type == "product_level") {
        nextProps.packageDetails.products.map((data) => {
          let clinics = [];

          const productClinicsMap = data.price_per_clinic.reduce(
            (acc, obj) => ({ ...acc, [obj.clinic_id]: obj }),
            {},
          );

          nextProps.packageDetails.clinics.map((c) => {
            clinics.push({
              clinic_name: c.clinic_name,
              clinic_id: c.id,
              product_id: data.product_id,
              price_per_unit: "",
              show_price: false,
            });

            returnState[
              "show-price-" + c.id + "-product-" + data.product_id
            ] = true;

            returnState[
              "new-price-clinic-" + c.id + "-product-" + data.product_id
            ] = productClinicsMap?.[c.id]?.new_price_per_unit;

            returnState[
              "new-price-clinic-" +
                c.id +
                "-product-" +
                data.product_id +
                "error"
            ] = false;
          });
          selectedProducts.push({
            product_id: data.product.id,
            product_name: data.product.product_name,
            product_type: data.product.product_type_label
              ? data.product.product_type_label === "retail"
                ? "retail"
                : data.product.product_type_label === "medical_supplies"
                ? "medical_supplies"
                : "services"
              : "services",
            clinicsList: clinics,
            id: data.id,
          });
        });
        returnState.selected_products = selectedProducts;
        returnState.showSearch = selectedProducts.length > 0 ? false : true;
        nextProps.exportEmptyData();
        positionFooterCorrectly();
      }
      if (nextProps.packageDetails.package_type == "collective_level_recipe") {
        returnState.totalPrice = nextProps.packageDetails.price;
        nextProps.packageDetails.buckets.map((data) => {
          if (data.bucket_type == "service") {
            returnState.service_bucket_id = data.id;
            let products = [];
            let categories = [];
            let bundles = [];
            data.products.map((obj) => {
              if (obj.type == "product" && obj.is_bundled == 0) {
                products.push({
                  id: obj.product.id,
                  second_id: obj.id,
                  product_name: obj.product.product_name,
                  added_by: 1,
                  color: obj.color,
                  units: obj.units,
                  price_per_unit: obj.price_per_unit,
                  row_id: obj.row_id,
                  min: obj.product.start_at_unit,
                });
                returnState["service_product_units_" + obj.product.id] =
                  obj.units.toString();
                returnState["service_price_per_unit_" + obj.product.id] =
                  obj.price_per_unit.toString();
                returnState["service_product_color_" + obj.product.id] =
                  obj.color;
              } else if (obj.type == "product" && obj.is_bundled == 1) {
                bundles.push({
                  bundle_id: obj.category_id,
                  bundle_name: obj.category.category_name,
                  type: "Bundle",
                  price_per_unit: obj.price_per_unit,
                  units: obj.units,
                  db_bundle_id: obj.category_id,
                });
              } else {
                categories.push({
                  category_id: obj.category_id,
                  category_name: obj.category.category_name,
                  type: "category",
                  price_per_unit: obj.price_per_unit,
                  units: obj.units,
                });
              }
            });
            let uniqueIds =
              categories.length > 0
                ? [
                    ...new Map(
                      categories.map((item) => [item["category_id"], item]),
                    ).values(),
                  ]
                : [];
            let uniqueBundleIds =
              bundles.length > 0
                ? [
                    ...new Map(
                      bundles.map((item) => [item["db_bundle_id"], item]),
                    ).values(),
                  ]
                : [];
            uniqueIds.length > 0 &&
              uniqueIds.map((data2) => {
                products.push({
                  category_id: data2.category_id,
                  category_name: data2.category_name,
                  type: "category",
                  price_per_unit: data2.price_per_unit,
                  units: data2.units,
                });
                returnState["service_category_units_" + data2.category_id] =
                  data2.units.toString();
                returnState[
                  "service_category_price_per_unit_" + data2.category_id
                ] = data2.price_per_unit.toString();
                data.products.map((data) => {
                  if (data.category && data2.category_id == data.category.id) {
                    products.push({
                      id: data.product_id,
                      second_id: data.id,
                      product_name: data.product.product_name,
                      category_id: data.category.id,
                      color: data.color,
                      added_by: 1,
                      row_id: data.row_id,
                      min: data.product.start_at_unit,
                    });
                    returnState["service_product_color_" + data.product_id] =
                      data.color;
                  }
                });
              });

            uniqueBundleIds.length > 0 &&
              uniqueBundleIds.map((data2) => {
                products.push({
                  bundle_id: data2.bundle_id,
                  bundle_name: data2.bundle_name,
                  type: "Bundle",
                  price_per_unit: data2.price_per_unit,
                  units: data2.units,
                  db_bundle_id: data2.db_bundle_id,
                  min: max(
                    data.products
                      .filter((p) => p.category?.id === data2.bundle_id)
                      .map((p) => p.product.start_at_unit),
                  ),
                });

                returnState["service_bundle_units_" + data2.bundle_id] =
                  data2.units.toString();

                returnState[
                  "service_bundle_price_per_unit_" + data2.bundle_id
                ] = data2.price_per_unit.toString();

                data.products.map((data) => {
                  if (data.category && data2.bundle_id == data.category.id) {
                    products.push({
                      id: data.product_id,
                      second_id: data.id,
                      product_name: data.product.product_name,
                      bundle_id: data.category.id,
                      color: data.color,
                      added_by: 1,
                      is_bundle: 1,
                      row_id: data.row_id,
                      db_bundle_id: data.category.id,
                      bundleName: data.category.category_name,
                      min: data.product.start_at_unit,
                    });
                    returnState["service_product_color_" + data.product_id] =
                      data.color;
                  }
                });
              });
            returnState.service_products = products;
          }

          if (data.bucket_type == "retail") {
            returnState.retail_bucket_id = data.id;
            let products = [];
            let bundles = [];
            data.products.map((obj) => {
              if (obj.type == "product" && obj.is_bundled == 0) {
                products.push({
                  id: obj.product.id,
                  second_id: obj.id,
                  product_name: obj.product.product_name,
                  added_by: 1,
                  units: obj.units,
                  price_per_unit: obj.price_per_unit,
                  row_id: obj.row_id,
                  min: obj.product.start_at_unit,
                });
                returnState["retail_product_units_" + obj.product.id] =
                  obj.units.toString();
                returnState["retail_price_per_unit_" + obj.product.id] =
                  obj.price_per_unit.toString();
              } else {
                bundles.push({
                  bundle_id: obj.category_id,
                  bundle_name: obj.category.category_name,
                  type: "Bundle",
                  price_per_unit: obj.price_per_unit,
                  units: obj.units,
                  db_bundle_id: obj.category_id,
                });
              }
            });

            let uniqueBundleIds =
              bundles.length > 0
                ? [
                    ...new Map(
                      bundles.map((item) => [item["db_bundle_id"], item]),
                    ).values(),
                  ]
                : [];
            uniqueBundleIds.length > 0 &&
              uniqueBundleIds.map((data2) => {
                products.push({
                  bundle_id: data2.bundle_id,
                  bundle_name: data2.bundle_name,
                  type: "Bundle",
                  price_per_unit: data2.price_per_unit,
                  units: data2.units,
                  db_bundle_id: data2.db_bundle_id,
                  min: max(
                    data.products
                      .filter((p) => p.category?.id === data2.bundle_id)
                      .map((p) => p.product.start_at_unit),
                  ),
                });
                returnState["retail_bundle_units_" + data2.bundle_id] =
                  data2.units.toString();
                returnState["retail_bundle_price_per_unit_" + data2.bundle_id] =
                  data2.price_per_unit.toString();
                data.products.map((data) => {
                  if (data.category && data2.bundle_id == data.category.id) {
                    products.push({
                      id: data.product_id,
                      second_id: data.id,
                      product_name: data.product.product_name,
                      bundle_id: data.category.id,
                      added_by: 1,
                      is_bundle: 1,
                      row_id: data.row_id,
                      db_bundle_id: data.category.id,
                      bundleName: data.category.category_name,
                      min: data.product.start_at_unit,
                    });
                  }
                });
              });
            returnState.retail_products = products;
          }

          if (data.bucket_type == "medical_supplies") {
            returnState.medical_bucket_id = data.id;
            let products = [];
            let bundles = [];
            data.products.map((obj) => {
              if (obj.type == "product" && obj.is_bundled == 0) {
                products.push({
                  id: obj.product.id,
                  second_id: obj.id,
                  product_name: obj.product.product_name,
                  added_by: 1,
                  units: obj.units,
                  price_per_unit: obj.price_per_unit,
                  row_id: obj.row_id,
                  min: obj.product.start_at_unit,
                });

                returnState["medical_product_units_" + obj.product.id] =
                  obj.units.toString();
                returnState["medical_price_per_unit_" + obj.product.id] =
                  obj.price_per_unit.toString();
              } else {
                bundles.push({
                  bundle_id: obj.category_id,
                  bundle_name: obj.category.category_name,
                  type: "Bundle",
                  price_per_unit: obj.price_per_unit,
                  units: obj.units,
                  db_bundle_id: obj.category_id,
                });
              }
            });
            let uniqueBundleIds =
              bundles.length > 0
                ? [
                    ...new Map(
                      bundles.map((item) => [item["db_bundle_id"], item]),
                    ).values(),
                  ]
                : [];
            uniqueBundleIds.length > 0 &&
              uniqueBundleIds.map((data2) => {
                products.push({
                  bundle_id: data2.bundle_id,
                  bundle_name: data2.bundle_name,
                  type: "Bundle",
                  price_per_unit: data2.price_per_unit,
                  units: data2.units,
                  db_bundle_id: data2.db_bundle_id,
                  min: max(
                    data.products
                      .filter((p) => p.category?.id === data2.bundle_id)
                      .map((p) => p.product.start_at_unit),
                  ),
                });
                returnState["medical_bundle_units_" + data2.bundle_id] =
                  data2.units.toString();
                returnState[
                  "medical_bundle_price_per_unit_" + data2.bundle_id
                ] = data2.price_per_unit.toString();
                data.products.map((data) => {
                  if (data.category && data2.bundle_id == data.category.id) {
                    products.push({
                      id: data.product_id,
                      second_id: data.id,
                      product_name: data.product.product_name,
                      bundle_id: data.category.id,
                      added_by: 1,
                      is_bundle: 1,
                      row_id: data.row_id,
                      db_bundle_id: data.category.id,
                      bundleName: data.category.category_name,
                      min: data.product.start_at_unit,
                    });
                  }
                });
              });
            returnState.medical_products = products;
          }
        });
      }
    }
    return returnState;
  }

  addPopupProductsToBucket = (type) => {
    if (type == "service" || type == "retail") {
      if (
        this.state.makeBundle == true &&
        (this.state.bundleName == "" || this.state.bundleName == null)
      ) {
        this.setState({ bundle_error: true });
        return;
      }
    }
    let products =
      type == "service"
        ? this.state.service_products
        : type == "retail"
        ? this.state.retail_products
        : this.state.medical_products;
    let categories = [];
    let checkProducts = [];
    let returnState = {};
    if (this.state.popupProducts && this.state.popupProducts.length > 0) {
      if (this.state.makeBundle == true) {
        products.push({
          bundle_name: this.state.bundleName,
          type: "Bundle",
          bundle_id: Math.random(),
          min: max(
            this.state.popupProducts
              .filter((data) => data.checked == true)
              .map((p) => p.start_at_unit),
          ),
        });
      }
      this.state.popupProducts.map((data, index) => {
        if (data.checked == true && this.state.makeBundle == true) {
          checkProducts.push(index);
          products.push({
            id: data.id,
            min: data.start_at_unit,
            product_name: data.product_name,
            price_per_unit: "",
            units: "",
            color: "",
            is_bundle: 1,
            bundleName: this.state.bundleName,
            bundle_id: products.find(
              (y) => y.bundle_name == this.state.bundleName,
            ).bundle_id,
          });
          returnState["service_product_units_" + data.id] = "";
          returnState["service_price_per_unit_" + data.id] = "";
          returnState["service_product_color_" + data.id] = "";
          returnState["retail_product_units_" + data.id] = "";
          returnState["medical_product_units_" + data.id] = "";
          returnState["retail_price_per_unit_" + data.id] = "";
          returnState["medical_price_per_unit_" + data.id] = "";
        } else if (data.checked == true && !this.state.makeBundle) {
          checkProducts.push(index);
          products.unshift({
            id: data.id,
            min: data.start_at_unit,
            product_name: data.product_name,
            price_per_unit: "",
            units: "",
            color: "",
          });
          returnState["service_product_units_" + data.id] = "";
          returnState["service_price_per_unit_" + data.id] = "";
          returnState["service_product_color_" + data.id] = "";
          returnState["retail_product_units_" + data.id] = "";
          returnState["medical_product_units_" + data.id] = "";
          returnState["retail_price_per_unit_" + data.id] = "";
          returnState["medical_price_per_unit_" + data.id] = "";
        }
      });
    }
    if (
      checkProducts.length == 0 &&
      (type == "service" || type == "retail") &&
      this.state.makeBundle == true
    ) {
      toast.error("Please select a product to add in a bundle.");
      return;
    }

    if (type == "service") {
      returnState.service_products = products;

      this.state.categoryData &&
        this.state.categoryData.length > 0 &&
        this.state.categoryData.map((data) => {
          if (data.checked == true) {
            categories.push(data.id);
          }
        });

      if (categories.length > 0) {
        this.setState({ showLoader: true });
        let formData = {
          type: "product",
          category_id: categories,
          q: "",
          bucket_type: "service",
        };
        this.props.searchProductCategory(formData);
      }

      returnState.openProductAndCategoriesPopup =
        categories.length > 0 ? true : false;
    } else if (type == "retail") {
      returnState.retail_products = products;
      returnState.openMedicalPopup = false;
    } else {
      returnState.medical_products = products;
      returnState.openMedicalPopup = false;
    }
    this.setState(returnState);
  };

  handleCheckBoxChangeForCollective = (e, type, product_id) => {
    let returnState = {};
    if (type == "products") {
      let popupProducts = this.state.popupProducts;
      popupProducts &&
        popupProducts.length > 0 &&
        popupProducts.map((data) => {
          if (data.id == product_id) {
            data.checked = e.target.checked;
          }
        });
      returnState.popupProducts = popupProducts;
      const target = e.target;
      let value = target.value;
      switch (target.type) {
        case "checkbox": {
          value = target.checked;
          break;
        }
      }
      this.setState({ [e.target.name]: value });
      this.setState(returnState);
    } else {
      let categoryData = this.state.categoryData;
      categoryData &&
        categoryData.length > 0 &&
        categoryData.map((data) => {
          if (data.id == product_id) {
            data.checked = e.target.checked;
            returnState.selected_category = data.category_name;
          }
        });
      returnState.categoryData = categoryData;

      const target = e.target;
      let value = target.value;
      switch (target.type) {
        case "checkbox": {
          value = target.checked;
          break;
        }
      }
      this.setState({ [e.target.name]: value });
      this.setState(returnState);
      if (value == true) {
        this.setState({ showLoader: true });
        let categories = [];
        categories.push(product_id);
        let formData = {
          type: "product",
          category_id: categories,
          q: "",
          bucket_type: "service",
        };
        this.props.searchProductCategoryForSingleCategory(formData);
      }
    }
  };

  dismissCategoriesPopup = () => {
    this.setState({ openProductAndCategoriesPopup: false });
  };

  setSelection = (type) => {
    this.setState({ selectionType: type });
  };

  selectBucket = (bucketType) => {
    this.setState({ selectedBucketType: bucketType });
  };

  openPopupForMedicalOrRetail = (type) => {
    this.setState({
      showLoader: true,
      openMedicalPopup: true,
      selected_type: type,
      popupProducts: [],
      categoryData: [],
      searchForRetailOrMedical: "",
      bundleName: "",
      makeBundle: false,
      searchDataInProducts: false,
      bundle_error: false,
    });
    let idsToSend = [];
    if (type == "retail") {
      if (this.state.retail_products && this.state.retail_products.length > 0) {
        this.state.retail_products.map((data) => {
          if (data.id) {
            idsToSend.push(data.id);
          }
        });
      }
    }
    if (type == "medical_supplies") {
      if (
        this.state.medical_products &&
        this.state.medical_products.length > 0
      ) {
        this.state.medical_products.map((data) => {
          if (data.id) {
            idsToSend.push(data.id);
          }
        });
      }
    }
    let formData = {
      bucket_type: type,
      selected_ids: idsToSend,
    };
    this.props.getProductsForMedicalAndRetail(formData);
  };

  handleCheckBoxChange = (data) => {
    let returnState = {};
    returnState["priceRepeat-" + data.clinicsList[0].product_id] =
      this.state["priceRepeat-" + data.clinicsList[0].product_id] == true
        ? false
        : true;
    let value =
      returnState["priceRepeat-" + data.clinicsList[0].product_id] == true
        ? this.state[
            "new-price-clinic-" +
              data.clinicsList[0].clinic_id +
              "-product-" +
              data.clinicsList[0].product_id
          ]
        : "";
    if (
      this.state[
        "new-price-clinic-" +
          data.clinicsList[0].clinic_id +
          "-product-" +
          data.clinicsList[0].product_id
      ] == null ||
      this.state[
        "new-price-clinic-" +
          data.clinicsList[0].clinic_id +
          "-product-" +
          data.clinicsList[0].product_id
      ] == "" ||
      !isPositiveNumber(
        this.state[
          "new-price-clinic-" +
            data.clinicsList[0].clinic_id +
            "-product-" +
            data.clinicsList[0].product_id
        ],
      )
    ) {
      toast.error("Please enter price for first clinic");
      return;
    }
    data.clinicsList.map((data) => {
      returnState[
        "new-price-clinic-" + data.clinic_id + "-product-" + data.product_id
      ] = value;
    });
    this.setState(returnState);
  };

  handleUnitsChange = (event) => {
    let returnState = {};
    returnState[event.target.name] = event.target.value;

    if (getPrecision(Number(event.target.value)) > 3) {
      return;
    }

    if (event.target.name.startsWith("service_product_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.service_products;
      let check = products.find((y) => y.id == id);
      check.units = event.target.value;
      returnState.service_products = products;
    }
    if (event.target.name.startsWith("service_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.service_products;
      let check = products.find((y) => y.id == id);
      check.price_per_unit = event.target.value;
      returnState.service_products = products;
    }

    if (event.target.name.startsWith("service_category_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.service_products;
      let check = products.find((y) => y.category_id == id);
      check.units = event.target.value;
      returnState.service_products = products;
    }

    if (event.target.name.startsWith("service_category_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.service_products;
      let check = products.find((y) => y.category_id == id);
      check.price_per_unit = event.target.value;
      returnState.service_products = products;
    }

    if (event.target.name.startsWith("retail_product_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.retail_products;
      let check = products.find((y) => y.id == id);
      check.units = event.target.value;
      returnState.retail_products = products;
    }

    if (event.target.name.startsWith("retail_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.retail_products;
      let check = products.find((y) => y.id == id);
      check.price_per_unit = event.target.value;
      returnState.retail_products = products;
    }

    if (event.target.name.startsWith("medical_product_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.medical_products;
      let check = products.find((y) => y.id == id);
      check.units = event.target.value;
      returnState.medical_products = products;
    }

    if (event.target.name.startsWith("medical_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.medical_products;
      let check = products.find((y) => y.id == id);
      check.price_per_unit = event.target.value;
      returnState.medical_products = products;
    }

    if (event.target.name.startsWith("retail_bundle_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.retail_products;
      let check = products.find((y) => y.bundle_id == id);
      check.price_per_unit = event.target.value;
      returnState.retail_products = products;
    }

    if (event.target.name.startsWith("retail_bundle_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.retail_products;
      let check = products.find((y) => y.bundle_id == id);
      check.units = event.target.value;
      returnState.retail_products = products;
    }

    if (event.target.name.startsWith("service_bundle_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.service_products;
      let check = products.find((y) => y.bundle_id == id);
      check.units = event.target.value;
      returnState.service_products = products;
    }

    if (event.target.name.startsWith("service_bundle_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.service_products;
      let check = products.find((y) => y.bundle_id == id);
      check.price_per_unit = event.target.value;
      returnState.service_products = products;
    }

    if (event.target.name.startsWith("medical_bundle_price_per_unit_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.medical_products;
      let check = products.find((y) => y.bundle_id == id);
      check.price_per_unit = event.target.value;
      returnState.medical_products = products;
    }

    if (event.target.name.startsWith("medical_bundle_units_")) {
      let nameArray = event.target.name.split("_");
      let id = nameArray[nameArray.length - 1];
      let products = this.state.medical_products;
      let check = products.find((y) => y.bundle_id == id);
      check.units = event.target.value;
      returnState.medical_products = products;
    }

    this.setState(returnState, () => {
      this.calculatePrice();
    });
  };

  setColor = (obj, colorSelected, type) => {
    let returnState = {};
    if (type == "service") {
      returnState["service_product_color_" + obj.id] = colorSelected;
      let products = this.state.service_products;
      let check = products.find((y) => y.id == obj.id);
      check.color = colorSelected;
      returnState.service_products = products;
    }
    this.setState(returnState);
  };

  calculatePrice = () => {
    let price = 0;
    if (this.state.service_products && this.state.service_products.length > 0) {
      this.state.service_products.map((data) => {
        let price_per_unit = 0;
        let units = 0;
        let amount = 0;
        if (data.type && data.type == "category") {
          price_per_unit = this.state[
            "service_category_price_per_unit_" + data.category_id
          ]
            ? this.state["service_category_price_per_unit_" + data.category_id]
            : 0;
          units = this.state["service_category_units_" + data.category_id]
            ? this.state["service_category_units_" + data.category_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else if (data.type && data.type == "Bundle") {
          price_per_unit = this.state[
            "service_bundle_price_per_unit_" + data.bundle_id
          ]
            ? this.state["service_bundle_price_per_unit_" + data.bundle_id]
            : 0;
          units = this.state["service_bundle_units_" + data.bundle_id]
            ? this.state["service_bundle_units_" + data.bundle_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else {
          price_per_unit = this.state["service_price_per_unit_" + data.id]
            ? this.state["service_price_per_unit_" + data.id]
            : 0;
          units = this.state["service_product_units_" + data.id]
            ? this.state["service_product_units_" + data.id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        }
      });
    }

    if (this.state.retail_products && this.state.retail_products.length > 0) {
      this.state.retail_products.map((data) => {
        let price_per_unit = 0;
        let units = 0;
        let amount = 0;
        if (data.type && data.type == "Bundle") {
          price_per_unit = this.state[
            "retail_bundle_price_per_unit_" + data.bundle_id
          ]
            ? this.state["retail_bundle_price_per_unit_" + data.bundle_id]
            : 0;
          units = this.state["retail_bundle_units_" + data.bundle_id]
            ? this.state["retail_bundle_units_" + data.bundle_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else {
          price_per_unit = this.state["retail_price_per_unit_" + data.id]
            ? this.state["retail_price_per_unit_" + data.id]
            : 0;
          units = this.state["retail_product_units_" + data.id]
            ? this.state["retail_product_units_" + data.id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        }
      });
    }

    if (this.state.medical_products && this.state.medical_products.length > 0) {
      this.state.medical_products.map((data) => {
        let price_per_unit = 0;
        let units = 0;
        let amount = 0;
        if (data.type && data.type == "Bundle") {
          price_per_unit = this.state[
            "medical_bundle_price_per_unit_" + data.bundle_id
          ]
            ? this.state["medical_bundle_price_per_unit_" + data.bundle_id]
            : 0;
          units = this.state["medical_bundle_units_" + data.bundle_id]
            ? this.state["medical_bundle_units_" + data.bundle_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else {
          price_per_unit = this.state["medical_price_per_unit_" + data.id]
            ? this.state["medical_price_per_unit_" + data.id]
            : 0;
          units = this.state["medical_product_units_" + data.id]
            ? this.state["medical_product_units_" + data.id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        }
      });
    }

    this.setState({ totalPrice: price });
  };

  submitCollectiveChartingPackage = () => {
    this.setState({ showLoader: true });
    let error = false;
    let unitsWholeNumberError = false;
    let unitsMinError = false;

    if (
      this.state.chartingPackageName == "" ||
      this.state.chartingPackageName == undefined ||
      this.state.chartingPackageName.trim() == "" ||
      this.state.chartingPackageName == null
    ) {
      this.setState({ packageNameError: true, showLoader: false });
      error = true;
    } else {
      this.setState({ packageNameError: false });
    }

    let returnState = {};
    if (
      this.state.service_products.length == 0 &&
      this.state.retail_products.length == 0 &&
      this.state.medical_products.length == 0
    ) {
      toast.error("Please fill atleast one bucket in the variation.");
      error = true;
      return;
    }
    if (this.state.service_products && this.state.service_products.length > 0) {
      this.state.service_products.map((data) => {
        if (data.id && !data.category_id && !data.is_bundle) {
          if (
            this.state["service_product_units_" + data.id] == null ||
            this.state["service_product_units_" + data.id] == "" ||
            this.state["service_product_units_" + data.id] == undefined
          ) {
            returnState["service_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service_product_units_error_" + data.id] = false;
          }

          if (
            this.state["service_price_per_unit_" + data.id] == null ||
            this.state["service_price_per_unit_" + data.id] == "" ||
            this.state["service_price_per_unit_" + data.id] == undefined
          ) {
            returnState["service_price_per_unit_error_" + data.id] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service_price_per_unit_error_" + data.id] = false;
          }

          if (
            this.state["service_product_color_" + data.id] == null ||
            this.state["service_product_color_" + data.id] == "" ||
            this.state["service_product_color_" + data.id] == "no-color" ||
            this.state["service_product_color_" + data.id].trim() == "" ||
            this.state["service_product_color_" + data.id] == undefined
          ) {
            returnState["service-product-color_error_" + data.id] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service-product-color_error_" + data.id] = false;
          }

          const serviceUnits = this.state["service_product_units_" + data.id];

          if (serviceUnits && Number(serviceUnits) < data.min) {
            returnState["service_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "service";
            error = true;
            unitsMinError = true;
          }
        }

        if (data.id && data.category_id && !data.is_bundle) {
          if (
            this.state["service_product_color_" + data.id] == null ||
            this.state["service_product_color_" + data.id] == "" ||
            this.state["service_product_color_" + data.id].trim() == "" ||
            this.state["service_product_color_" + data.id] == undefined
          ) {
            returnState["service-product-color_error_" + data.id] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service-product-color_error_" + data.id] = false;
          }
        }

        if (!data.id && data.category_id && !data.is_bundle) {
          if (
            this.state["service_category_units_" + data.category_id] == null ||
            this.state["service_category_units_" + data.category_id] == "" ||
            this.state["service_category_units_" + data.category_id] ==
              undefined
          ) {
            returnState[
              "service_category_units_error_" + data.category_id
            ] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service_category_units_error_" + data.id] = false;
          }

          if (
            this.state["service_category_price_per_unit_" + data.category_id] ==
              null ||
            this.state["service_category_price_per_unit_" + data.category_id] ==
              "" ||
            this.state["service_category_price_per_unit_" + data.category_id] ==
              undefined
          ) {
            returnState[
              "service_category_price_per_unit_error_" + data.category_id
            ] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState[
              "service_category_price_per_unit_error_" + data.id
            ] = false;
          }
        }

        if (
          !data.id &&
          data.type == "Bundle" &&
          !data.category_id &&
          data.bundle_id
        ) {
          if (
            this.state["service_bundle_units_" + data.bundle_id] == null ||
            this.state["service_bundle_units_" + data.bundle_id] == "" ||
            this.state["service_bundle_units_" + data.bundle_id] == undefined
          ) {
            returnState["service_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service_bundle_units_error_" + data.bundle_id] = false;
          }
          if (
            this.state["service_bundle_price_per_unit_" + data.bundle_id] ==
              null ||
            this.state["service_bundle_price_per_unit_" + data.bundle_id] ==
              "" ||
            this.state["service_bundle_price_per_unit_" + data.bundle_id] ==
              undefined
          ) {
            returnState[
              "service_bundle_price_per_unit_error_" + data.bundle_id
            ] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState[
              "service_bundle_price_per_unit_error_" + data.bundle_id
            ] = false;
          }

          const serviceBundleUnits =
            this.state["service_bundle_units_" + data.bundle_id];

          if (serviceBundleUnits && Number(serviceBundleUnits) < data.min) {
            returnState["service_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "service";
            error = true;
            unitsMinError = true;
          }
        }

        if (data.id && !data.category_id && data.is_bundle) {
          if (
            this.state["service_product_color_" + data.id] == null ||
            this.state["service_product_color_" + data.id] == "" ||
            this.state["service_product_color_" + data.id] == "no-color" ||
            this.state["service_product_color_" + data.id].trim() == "" ||
            this.state["service_product_color_" + data.id] == undefined
          ) {
            returnState["service-product-color_error_" + data.id] = true;
            returnState.selectedBucketType = "service";
            error = true;
          } else {
            returnState["service-product-color_error_" + data.id] = false;
          }
        }
      });
    }

    if (this.state.retail_products && this.state.retail_products.length > 0) {
      this.state.retail_products.map((data) => {
        if (data.id && !data.is_bundle) {
          if (
            this.state["retail_product_units_" + data.id] == null ||
            this.state["retail_product_units_" + data.id] == "" ||
            this.state["retail_product_units_" + data.id] == undefined
          ) {
            returnState["retail_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
          } else {
            returnState["retail_product_units_error_" + data.id] = false;
          }

          if (
            this.state["retail_price_per_unit_" + data.id] == null ||
            this.state["retail_price_per_unit_" + data.id] == "" ||
            this.state["retail_price_per_unit_" + data.id] == undefined
          ) {
            returnState["retail_price_per_unit_error_" + data.id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
          } else {
            returnState["retail_price_per_unit_error_" + data.id] = false;
          }

          const retailUnits = this.state["retail_product_units_" + data.id];

          if (retailUnits && Number(retailUnits) < data.min) {
            returnState["retail_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
            unitsMinError = true;
          }

          if (retailUnits && Number(retailUnits) % 1 !== 0) {
            returnState["retail_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
            unitsWholeNumberError = true;
          }
        }

        if (!data.id && data.bundle_id && data.type == "Bundle") {
          if (
            this.state["retail_bundle_units_" + data.bundle_id] == null ||
            this.state["retail_bundle_units_" + data.bundle_id] == "" ||
            this.state["retail_bundle_units_" + data.bundle_id] == undefined
          ) {
            returnState["retail_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
          } else {
            returnState["retail_bundle_units_error_" + data.bundle_id] = false;
          }

          if (
            this.state["retail_bundle_price_per_unit_" + data.bundle_id] ==
              null ||
            this.state["retail_bundle_price_per_unit_" + data.bundle_id] ==
              "" ||
            this.state["retail_bundle_price_per_unit_" + data.bundle_id] ==
              undefined
          ) {
            returnState[
              "retail_bundle_price_per_unit_error_" + data.bundle_id
            ] = true;
            returnState.selectedBucketType = "retail";
            error = true;
          } else {
            returnState[
              "retail_bundle_price_per_unit_error_" + data.bundle_id
            ] = false;
          }

          const retailBundleUnits =
            this.state["retail_bundle_units_" + data.bundle_id];

          if (retailBundleUnits && Number(retailBundleUnits) < data.min) {
            returnState["retail_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
            unitsMinError = true;
          }

          if (retailBundleUnits && Number(retailBundleUnits) % 1 !== 0) {
            returnState["retail_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "retail";
            error = true;
            unitsWholeNumberError = true;
          }
        }
      });
    }

    if (this.state.medical_products && this.state.medical_products.length > 0) {
      this.state.medical_products.map((data) => {
        if (data.id && !data.is_bundle) {
          if (
            this.state["medical_product_units_" + data.id] == null ||
            this.state["medical_product_units_" + data.id] == "" ||
            this.state["medical_product_units_" + data.id] == undefined
          ) {
            returnState["medical_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
          } else {
            returnState["medical_product_units_error_" + data.id] = false;
          }

          if (
            this.state["medical_price_per_unit_" + data.id] == null ||
            this.state["medical_price_per_unit_" + data.id] == "" ||
            this.state["medical_price_per_unit_" + data.id] == undefined
          ) {
            returnState["medical_price_per_unit_error_" + data.id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
          } else {
            returnState["medical_price_per_unit_error_" + data.id] = false;
          }

          const medicalUnits = this.state["medical_product_units_" + data.id];

          if (medicalUnits && Number(medicalUnits) < data.min) {
            returnState["medical_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
            unitsMinError = true;
          }

          if (medicalUnits && Number(medicalUnits) % 1 !== 0) {
            returnState["medical_product_units_error_" + data.id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
            unitsWholeNumberError = true;
          }
        }

        if (!data.id && data.bundle_id && data.type == "Bundle") {
          if (
            this.state["medical_bundle_units_" + data.bundle_id] == null ||
            this.state["medical_bundle_units_" + data.bundle_id] == "" ||
            this.state["medical_bundle_units_" + data.bundle_id] == undefined
          ) {
            returnState["medical_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
          } else {
            returnState["medical_bundle_units_error_" + data.bundle_id] = false;
          }

          if (
            this.state["medical_bundle_price_per_unit_" + data.bundle_id] ==
              null ||
            this.state["medical_bundle_price_per_unit_" + data.bundle_id] ==
              "" ||
            this.state["medical_bundle_price_per_unit_" + data.bundle_id] ==
              undefined
          ) {
            returnState[
              "medical_bundle_price_per_unit_error_" + data.bundle_id
            ] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
          } else {
            returnState[
              "medical_bundle_price_per_unit_error_" + data.bundle_id
            ] = false;
          }

          const medicalBundleUnits =
            this.state["medical_bundle_units_" + data.bundle_id];

          if (medicalBundleUnits && Number(medicalBundleUnits) < data.min) {
            returnState["medical_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
            unitsMinError = true;
          }

          if (medicalBundleUnits && Number(medicalBundleUnits) % 1 !== 0) {
            returnState["medical_bundle_units_error_" + data.bundle_id] = true;
            returnState.selectedBucketType = "medical_supplies";
            error = true;
            unitsWholeNumberError = true;
          }
        }
      });
    }
    this.setState(returnState);

    if (unitsWholeNumberError) {
      uiNotification.error("Units should be a whole number");
    }

    if (unitsMinError) {
      uiNotification.error(MIN_UNITS_ERROR);
    }

    if (error) {
      this.setState({ showLoader: false });
      return;
    } else {
      this.submitFinalData();
    }
  };

  submitFinalData = () => {
    let bucket = [];
    if (this.state.service_products && this.state.service_products.length > 0) {
      let products = [];
      this.state.service_products.map((data) => {
        if (data.id) {
          let obj = {
            id: data.id,
            type: data.category_id ? "category" : "product",
            units: data.category_id
              ? this.state["service_category_units_" + data.category_id]
              : data.is_bundle
              ? this.state["service_bundle_units_" + data.bundle_id]
              : this.state["service_product_units_" + data.id],
            price_per_unit: data.category_id
              ? this.state[
                  "service_category_price_per_unit_" + data.category_id
                ]
              : data.is_bundle
              ? this.state["service_bundle_price_per_unit_" + data.bundle_id]
              : this.state["service_price_per_unit_" + data.id],
            color: this.state["service_product_color_" + data.id],
            category_id:
              data.is_bundle && data.db_bundle_id
                ? data.db_bundle_id
                : data.category_id
                ? data.category_id
                : "",
            row_id: data.row_id ? data.row_id : "",
          };
          if (data.is_bundle) {
            obj.is_bundled = 1;
            obj.category = {
              id: data.db_bundle_id ? data.db_bundle_id : "",
              category_name: data.bundleName,
            };
          }
          products.push(obj);
        }
      });
      let data = {
        bucket_name: "Service Bucket",
        bucket_type: "service",
        products: products,
      };
      if (this.state.service_bucket_id) {
        data.id = this.state.service_bucket_id;
      }
      bucket.push(data);
    }

    if (this.state.retail_products && this.state.retail_products.length > 0) {
      let products = [];
      this.state.retail_products.map((data) => {
        if (data.id) {
          let obj = {
            id: data.id,
            type: "product",
            units: data.is_bundle
              ? this.state["retail_bundle_units_" + data.bundle_id]
              : this.state["retail_product_units_" + data.id],
            price_per_unit: data.is_bundle
              ? this.state["retail_bundle_price_per_unit_" + data.bundle_id]
              : this.state["retail_price_per_unit_" + data.id],
            row_id: data.row_id ? data.row_id : "",
          };

          if (data.is_bundle) {
            obj.is_bundled = 1;
            obj.category_id = data.db_bundle_id ? data.db_bundle_id : "";
            obj.category = {
              id: data.db_bundle_id ? data.db_bundle_id : "",
              category_name: data.bundleName,
            };
          }
          products.push(obj);
        }
      });
      let data = {
        bucket_name: "Retail Bucket",
        bucket_type: "retail",
        products: products,
      };
      if (this.state.retail_bucket_id) {
        data.id = this.state.retail_bucket_id;
      }
      bucket.push(data);
    }

    if (this.state.medical_products && this.state.medical_products.length > 0) {
      let products = [];
      this.state.medical_products.map((data) => {
        if (data.id) {
          let obj = {
            id: data.id,
            type: "product",
            units: data.is_bundle
              ? this.state["medical_bundle_units_" + data.bundle_id]
              : this.state["medical_product_units_" + data.id],
            price_per_unit: data.is_bundle
              ? this.state["medical_bundle_price_per_unit_" + data.bundle_id]
              : this.state["medical_price_per_unit_" + data.id],
            row_id: data.row_id ? data.row_id : "",
          };
          if (data.is_bundle) {
            obj.is_bundled = 1;
            obj.category_id = data.db_bundle_id ? data.db_bundle_id : "";
            obj.category = {
              id: data.db_bundle_id ? data.db_bundle_id : "",
              category_name: data.bundleName,
            };
          }
          products.push(obj);
        }
      });
      let data = {
        bucket_name: "Medical Supplies Bucket",
        bucket_type: "medical_supplies",
        products: products,
      };
      if (this.state.medical_bucket_id) {
        data.id = this.state.medical_bucket_id;
      }
      bucket.push(data);
    }

    let formData = {
      name: this.state.chartingPackageName,
      status: 0,
      price: this.state.totalPrice ? this.state.totalPrice : 0,
      package_type: "collective_level_recipe",
      buckets: bucket,
    };
    if (this.state.edit == true) {
      formData.id = this.state.id;
    }
    this.props.submitCollectiveTotal(formData);
  };

  deleteRecipeProductModal = (type, data, index) => {
    this.setState({
      deleteProductBucketModal: true,
      deleteType: type,
      delete_obj: data,
      delete_index: index,
    });
  };
  deleteProductForCollective = () => {
    this.setState({ showLoader: true });
    let added_by = this.state.delete_obj.added_by;
    let index = this.state.delete_index;
    let obj = this.state.delete_obj;
    let productsData =
      this.state.deleteType == "service"
        ? this.state.service_products
        : this.state.deleteType == "retail"
        ? this.state.retail_products
        : this.state.medical_products;

    let returnState = {};
    productsData.splice(index, 1);
    if (this.state.deleteType == "service") {
      if (obj.category_id) {
        let check = productsData.find(
          (y) => y.category_id == obj.category_id && y.type != "category",
        );
        if (!check) {
          let findCategory = productsData.findIndex(
            (y) => y.category_id == obj.category_id,
          );
          if (findCategory > -1) {
            productsData.splice(findCategory, 1);
          }
        }
      }
      if (obj.bundle_id) {
        let check = productsData.find(
          (y) => y.bundle_id == obj.bundle_id && y.type != "Bundle",
        );
        if (!check) {
          let findBundle = productsData.findIndex(
            (y) => y.bundle_id == obj.bundle_id,
          );
          if (findBundle > -1) {
            productsData.splice(findBundle, 1);
          }
        }
      }
      returnState.service_products = productsData;
    } else if (this.state.deleteType == "retail") {
      if (obj.bundle_id) {
        let check = productsData.find(
          (y) => y.bundle_id == obj.bundle_id && y.type != "Bundle",
        );
        if (!check) {
          let findBundle = productsData.findIndex(
            (y) => y.bundle_id == obj.bundle_id,
          );
          if (findBundle > -1) {
            productsData.splice(findBundle, 1);
          }
        }
      }
      returnState.retail_products = productsData;
    } else {
      if (obj.bundle_id) {
        let check = productsData.find(
          (y) => y.bundle_id == obj.bundle_id && y.type != "Bundle",
        );
        if (!check) {
          let findBundle = productsData.findIndex(
            (y) => y.bundle_id == obj.bundle_id,
          );
          if (findBundle > -1) {
            productsData.splice(findBundle, 1);
          }
        }
      }
      returnState.medical_products = productsData;
    }

    returnState.deleteProductBucketModal = false;
    returnState.showLoader = false;
    this.setState(returnState, () => {
      this.calculatePriceForDeleteProduct(added_by, obj.second_id);
    });
  };

  calculatePriceForDeleteProduct = (added_by, second_id) => {
    let price = 0;
    if (this.state.service_products && this.state.service_products.length > 0) {
      this.state.service_products.map((data) => {
        let price_per_unit = 0;
        let units = 0;
        let amount = 0;
        if (data.type && data.type == "category") {
          price_per_unit = this.state[
            "service_category_price_per_unit_" + data.category_id
          ]
            ? this.state["service_category_price_per_unit_" + data.category_id]
            : 0;
          units = this.state["service_category_units_" + data.category_id]
            ? this.state["service_category_units_" + data.category_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else if (data.type && data.type == "Bundle") {
          price_per_unit = this.state[
            "service_bundle_price_per_unit_" + data.bundle_id
          ]
            ? this.state["service_bundle_price_per_unit_" + data.bundle_id]
            : 0;
          units = this.state["service_bundle_units_" + data.bundle_id]
            ? this.state["service_bundle_units_" + data.bundle_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else {
          price_per_unit = this.state["service_price_per_unit_" + data.id]
            ? this.state["service_price_per_unit_" + data.id]
            : 0;
          units = this.state["service_product_units_" + data.id]
            ? this.state["service_product_units_" + data.id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        }
      });
    }

    if (this.state.retail_products && this.state.retail_products.length > 0) {
      this.state.retail_products.map((data) => {
        let price_per_unit = 0;
        let units = 0;
        let amount = 0;
        if (data.type && data.type == "Bundle") {
          price_per_unit = this.state[
            "retail_bundle_price_per_unit_" + data.bundle_id
          ]
            ? this.state["retail_bundle_price_per_unit_" + data.bundle_id]
            : 0;
          units = this.state["retail_bundle_units_" + data.bundle_id]
            ? this.state["retail_bundle_units_" + data.bundle_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else {
          price_per_unit = this.state["retail_price_per_unit_" + data.id]
            ? this.state["retail_price_per_unit_" + data.id]
            : 0;
          units = this.state["retail_product_units_" + data.id]
            ? this.state["retail_product_units_" + data.id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        }
      });
    }

    if (this.state.medical_products && this.state.medical_products.length > 0) {
      this.state.medical_products.map((data) => {
        let price_per_unit = 0;
        let units = 0;
        let amount = 0;
        if (data.type && data.type == "Bundle") {
          price_per_unit = this.state[
            "medical_bundle_price_per_unit_" + data.bundle_id
          ]
            ? this.state["medical_bundle_price_per_unit_" + data.bundle_id]
            : 0;
          units = this.state["medical_bundle_units_" + data.bundle_id]
            ? this.state["medical_bundle_units_" + data.bundle_id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        } else {
          price_per_unit = this.state["medical_price_per_unit_" + data.id]
            ? this.state["medical_price_per_unit_" + data.id]
            : 0;
          units = this.state["medical_product_units_" + data.id]
            ? this.state["medical_product_units_" + data.id]
            : 0;
          amount = price_per_unit * units;
          price = price + amount;
        }
      });
    }

    this.setState({ totalPrice: price });
    if (added_by) {
      this.setState({ showLoader: true });
      let formData = {
        id: this.state.id,
        charting_package_bucket_product_id: second_id,
        price: price,
      };
      this.props.deleteProductForCollective(formData);
    }
  };

  dismissCollectiveModal = () => {
    this.setState({ deleteProductBucketModal: false });
  };

  addMore = () => {
    this.setState({
      showSearch: true,
      searched_product_id: 0,
      searched_product_name: "",
      search_product_keyword: "",
      is_product_search: false,
    });
  };

  openDeleteModal = () => {
    this.setState({ showModal: true });
  };

  deletePackage = () => {
    this.setState({ showLoader: true });
    this.props.deletePackage(this.state.id);
  };
  deleteProductModal = (productID, editProductId) => {
    this.setState({
      showProductDeleteModal: true,
      deleteID: productID,
      editProductId: editProductId,
    });
  };
  dismissProductModal = () => {
    this.setState({ showProductDeleteModal: false });
  };

  deleteProduct = () => {
    if (this.state.edit == true) {
      this.setState({ showLoader: true });
      if (this.state.editProductId) {
        let formData = {
          charting_package_product_id: this.state.editProductId,
          id: this.props.match.params.id,
        };
        selectedProducts = [];
        this.setState({
          searched_product_name: "",
          search_product_keyword: "",
          is_product_search: false,
        });
        this.props.deleteProductFromChartingPackage(formData);
      } else {
        let index;
        index = this.state.selected_products.findIndex(
          (y) => y.product_id == this.state.deleteID,
        );
        let products = this.state.selected_products;
        if (index > -1) {
          products.splice(index, 1);
        }
        this.setState({
          selected_products: products,
          showProductDeleteModal: false,
          showLoader: false,
        });
        if (products.length == 0) {
          this.setState({
            showSearch: true,
            searched_product_id: 0,
            searched_product_name: "",
            search_product_keyword: "",
            is_product_search: false,
          });
        }
      }
    } else {
      let index;
      index = this.state.selected_products.findIndex(
        (y) => y.product_id == this.state.deleteID,
      );
      let products = this.state.selected_products;
      if (index > -1) {
        products.splice(index, 1);
      }
      this.setState({
        selected_products: products,
        showProductDeleteModal: false,
      });
      if (products.length == 0) {
        this.setState({
          showSearch: true,
          searched_product_id: 0,
          searched_product_name: "",
          search_product_keyword: "",
          is_product_search: false,
        });
      }
    }
  };

  submitNewChartingPackage = () => {
    this.setState({ showLoader: true });
    let error = false;
    let returnState = {};
    let formData = {};
    if (
      this.state.chartingPackageName === "" ||
      this.state.chartingPackageName === null
    ) {
      this.setState({ packageNameError: true });
      error = true;
    } else {
      this.setState({ packageNameError: false });
    }
    if (this.state.managePrice == "product_level") {
      this.state.selected_products.map((data) => {
        if (data.product_type !== "medical_supplies") {
          data.clinicsList.map((obj) => {
            if (
              this.state[
                "new-price-clinic-" +
                  obj.clinic_id +
                  "-product-" +
                  obj.product_id
              ] === null ||
              this.state[
                "new-price-clinic-" +
                  obj.clinic_id +
                  "-product-" +
                  obj.product_id
              ] === "" ||
              !isPositiveNumber(
                this.state[
                  "new-price-clinic-" +
                    obj.clinic_id +
                    "-product-" +
                    obj.product_id
                ],
                0,
              )
            ) {
              returnState[
                "new-price-clinic-" +
                  obj.clinic_id +
                  "-product-" +
                  obj.product_id +
                  "error"
              ] = true;
              error = true;
            } else {
              returnState[
                "new-price-clinic-" +
                  obj.clinic_id +
                  "-product-" +
                  obj.product_id +
                  "error"
              ] = false;
            }
          });
        }
      });

      this.setState(returnState);

      if (this.state.selected_products.length <= 0) {
        this.setState({ showLoader: false });
        toast.dismiss();
        toast.error(
          "Please add at least one product in the inventory variation",
        );
        return;
      }

      if (error) {
        this.setState({ showLoader: false });
        return;
      }
      let products = [];
      this.state.selected_products.map((data) => {
        let clinics = [];
        if (data.product_type !== "medical_supplies") {
          data.clinicsList.map((obj) => {
            clinics.push({
              clinic_id: obj.clinic_id,
              new_member_price_per_unit: 0,
              new_price_per_unit:
                this.state[
                  "new-price-clinic-" +
                    obj.clinic_id +
                    "-product-" +
                    obj.product_id
                ],
            });
          });
        }

        products.push({
          product_id: data.product_id,
          product_type: data.product_type,
          clinics: clinics,
        });
      });
      if (products.length == 0) {
        toast.dismiss();
        toast.error(
          "Please add at least one product in the inventory variation",
        );
        this.setState({ showLoader: false });
        return;
      }
      formData = {
        name: this.state.chartingPackageName,
        status: 0,
        package_type: "product_level",
        products: products,
      };
    }
    if (this.state.managePrice == "collective_level_recipe") {
      if (
        this.state.totalPrice == 0 ||
        this.state.totalPrice == null ||
        this.state.totalPrice == "" ||
        !isPositiveNumber(this.state.totalPrice)
      ) {
        this.setState({ totalPriceError: true });
        error = true;
      } else {
        this.setState({ totalPriceError: false });
      }

      let checkArray = [];
      this.state.selected_products &&
        this.state.selected_products.map((data) => {
          if (
            !this.state["product-unit-" + data.product_id] ||
            this.state["product-unit-" + data.product_id] == 0 ||
            this.state["product-unit-" + data.product_id] == "" ||
            this.state["product-unit-" + data.product_id] == null ||
            !isPositiveNumber(this.state["product-unit-" + data.product_id])
          ) {
            returnState["product-unit-error" + data.product_id] = true;
            checkArray.push(data.product_id);
          } else {
            returnState["product-unit-error" + data.product_id] = false;
          }
          if (
            this.state["product-color" + data.product_id] == "" ||
            this.state["product-color" + data.product_id] == null
          ) {
            returnState["product-color-error" + data.product_id] = true;
            checkArray.push(data.product_id);
          } else {
            returnState["product-color-error" + data.product_id] = false;
          }
        });
      this.setState(returnState);
      if (checkArray.length > 0) {
        this.setState({ showLoader: false });
        return;
      }
      if (error) {
        this.setState({ showLoader: false });
        return;
      }
      let products = [];
      this.state.selected_products &&
        this.state.selected_products.map((data) => {
          products.push({
            clinics: [],
            product_id: data.product_id,
            color: this.state["product-color" + data.product_id],
            units: this.state["product-unit-" + data.product_id],
          });
        });

      if (products.length == 0) {
        toast.dismiss();
        toast.error(
          "Please add atleast one product in the inventory variation",
        );
        this.setState({ showLoader: false });
        return;
      }
      formData = {
        name: this.state.chartingPackageName,
        status: 0,
        price: this.state.totalPrice,
        package_type: "collective_level_recipe",
        products: products,
      };
    }

    if (this.state.edit == false) {
      this.props.addNewPackage(formData);
    } else {
      formData.id = this.state.id;
      this.props.updatePackage(formData);
    }
  };

  handleSearchMedical = (event, type) => {
    if (type == "products") {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.value == "") {
        this.setState({ searchDataInProducts: false });
      }
      let value = event.target.value;
      let products = this.state.popupProducts;
      if (typeof value === "string") {
        value = value.trim();
        if (value.length >= 3) {
          this.setState({ searchDataInProducts: true });
          let m = this.search(value, products);
          let mySet =
            m.length > 0
              ? [...new Map(m.map((item) => [item["id"], item])).values()]
              : [];
          this.setState({ searchArray: mySet });
        }
      }
    } else {
      this.setState({ [event.target.name]: event.target.value });
      if (event.target.value == "") {
        this.setState({ searchDataInProducts: false });
      }
      let value = event.target.value;
      let products = this.state.categoryData;
      if (typeof value === "string") {
        value = value.trim();
        if (value.length >= 3) {
          this.setState({ searchDataInProducts: true });
          let m = this.searchCategory(value, products);
          let mySet =
            m.length > 0
              ? [...new Map(m.map((item) => [item["id"], item])).values()]
              : [];
          this.setState({ searchCategoryArray: mySet });
        }
      }
    }
  };

  search = (subject, objects) => {
    var matches = [];
    subject = subject.toLowerCase();
    var regexp = new RegExp(subject, "g");

    for (var i = 0; i < objects.length; i++) {
      for (let _ in objects[i]) {
        if (objects[i]["product_name"].toLowerCase().match(regexp))
          matches.push(objects[i]);
      }
    }
    return matches;
  };

  searchCategory = (subject, objects) => {
    var matches = [];
    subject = subject.toLowerCase();
    var regexp = new RegExp(subject, "g");

    for (var i = 0; i < objects.length; i++) {
      for (let _ in objects[i]) {
        if (objects[i]["category_name"].toLowerCase().match(regexp))
          matches.push(objects[i]);
      }
    }
    return matches;
  };

  removeCheckFromProducts = (data) => {
    let id = data.id;
    let returnState = {};
    let products = this.state.popupProducts;
    let check = products.find((y) => y.id == id);
    check.checked = false;
    returnState["popup-product-" + id] = false;
    returnState.popupProducts = products;
    this.setState(returnState);
  };

  removeCheckFromCategories = (data) => {
    let id = data.id;
    let returnState = {};
    let products = this.state.categoryData;
    let check = products.find((y) => y.id == id);
    check.checked = false;
    returnState["popup-category-" + id] = false;
    returnState.categoryData = products;
    this.setState(returnState);
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  render() {
    let provider_colors = [];
    provider_colors = this.state.provider_colors;
    provider_colors = [
      "#00008B",
      "#0000CD",
      "#8B0000",
      "#FF8C00",
      "#32CD32",
      "#808000",
      "#FF6F61",
      "#008080",
      "#8A2BE2",
      "#00BFFF",
      "#37C5AB",
      "#FF0000",
      "#1E90FF",
      "#FF00FF",
      "#E15D44",
      "#FC91AD",
      "#92A8D1",
      "#BDB76B",
      "#D2B48C",
      "#696969",
    ];
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="">
            <div className="setting-setion m-b-10 auto-height">
              <div className="membership-title">
                {this.state.edit == false && (
                  <span className="cursor-pointer">
                    Create Pricing Variation
                  </span>
                )}
                {this.state.edit == true && (
                  <span className="cursor-pointer">Edit Pricing Variation</span>
                )}
                <div className="memberRightActions">
                  {this.state.saveButton && (
                    <a
                      className="new-blue-btn"
                      onClick={
                        this.state.managePrice == "collective_level_recipe"
                          ? this.submitCollectiveChartingPackage
                          : this.submitNewChartingPackage
                      }
                    >
                      {this.state.globalLang.label_save}
                    </a>
                  )}
                  {this.state.edit == true && (
                    <button
                      onClick={this.openDeleteModal}
                      className="new-red-btn m-l-5"
                    >
                      {this.state.globalLang.label_delete}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="setting-setion m-b-10">
              <div className="setting-container p2030">
                <div className="row  p-b-0">
                  <div className="col-sm-6 col-md-4 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        Name of Pricing Variation
                        <span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer">
                        <input
                          name="chartingPackageName"
                          className={
                            this.state.packageNameError
                              ? "newInputField field-error"
                              : "newInputField"
                          }
                          type="text"
                          value={this.state.chartingPackageName}
                          onChange={this.handleInputChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        Manage Price<span className="setting-require">*</span>
                      </div>
                      <div className="setting-input-outer">
                        <select
                          name="managePrice"
                          className="newSelectField"
                          onChange={this.handleInputChange}
                          value={this.state.managePrice}
                        >
                          <option value="collective_level_recipe">
                            Recipe
                          </option>
                          <option value="product_level">
                            At Product Level
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {this.state.managePrice == "collective_level_recipe" && (
                    <div className="col-sm-6 col-md-4 col-xs-12">
                      <div className="newInputFileldOuter readOnlyField">
                        <div className="newInputLabel">
                          Total Price ({getCurrencySymbol()})
                          <span className="setting-require">*</span>
                        </div>
                        <div className="setting-input-outer">
                          <input
                            name="totalPrice"
                            className={
                              this.state.totalPriceError
                                ? "newInputField field-error"
                                : "newInputField"
                            }
                            type="text"
                            value={this.state.totalPrice}
                            readOnly={true}
                            autoComplete="off"
                          />
                          <div className="field-note">
                            {`Note: Build the recipe below and we'll calculate the
                            total for you.`}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* *****NEW WORK******* */}
              {this.state.managePrice == "collective_level_recipe" && (
                <div className="setting-container p2030 border-top">
                  <div className="membership-title m-b-25 no-padding">
                    Build Your Recipe
                  </div>

                  <ul className="bucket-tabs m-b-25">
                    <li>
                      <a
                        className={
                          this.state.selectedBucketType == "service"
                            ? "active"
                            : ""
                        }
                        onClick={() => this.selectBucket("service")}
                      >
                        {this.state.service_products &&
                          this.state.service_products.length > 0 && (
                            <span>
                              <i className="fa fa-check m-r-5"></i>
                            </span>
                          )}{" "}
                        <span>
                          Service<p>Bucket</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          this.state.selectedBucketType == "retail"
                            ? "active"
                            : ""
                        }
                        onClick={() => this.selectBucket("retail")}
                      >
                        {this.state.retail_products &&
                          this.state.retail_products.length > 0 && (
                            <span>
                              <i className="fa fa-check m-r-5"></i>
                            </span>
                          )}{" "}
                        <span>
                          Retail <p>Bucket</p>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          this.state.selectedBucketType == "medical_supplies"
                            ? "active"
                            : ""
                        }
                        onClick={() => this.selectBucket("medical_supplies")}
                      >
                        {this.state.medical_products &&
                          this.state.medical_products.length > 0 && (
                            <span>
                              <i className="fa fa-check m-r-5"></i>
                            </span>
                          )}{" "}
                        <span>
                          Medical Supplies<p>Bucket</p>
                        </span>
                      </a>
                    </li>
                  </ul>

                  {this.state.selectedBucketType == "service" && (
                    <div>
                      <div className="juvly-subtitle m-b-20">
                        Service Bucket
                        <a
                          className="new-blue-btn pull-right -m-t-5"
                          onClick={() => this.openProductsCategoryOption()}
                        >
                          Add
                        </a>
                      </div>

                      <div className="table-responsive seviceBucketTable m-t-0">
                        <table className="table-updated setting-table min-w-850 no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-4 table-updated-th">
                                Name
                              </th>
                              <th className="col-xs-2 table-updated-th">
                                Units
                              </th>
                              <th className="col-xs-2 table-updated-th">
                                Color
                              </th>
                              <th className="col-xs-2 table-updated-th">
                                Price per unit ({getCurrencySymbol()})
                              </th>
                              <th className="col-xs-2 table-updated-th">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {this.state.service_products &&
                              this.state.service_products.length > 0 &&
                              this.state.service_products.map((data, index) => {
                                if (data.id) {
                                  return (
                                    <tr
                                      className={
                                        data.category_id || data.is_bundle == 1
                                          ? "table-updated-tr backgroundGray"
                                          : "table-updated-tr"
                                      }
                                    >
                                      <td className="table-updated-td">
                                        <div
                                          className={cx({
                                            "m-b-20": !!data.min,
                                          })}
                                        >
                                          {data.product_name}
                                          <div className="font-12 text-italic">
                                            (start at: {data.min})
                                          </div>
                                        </div>
                                      </td>
                                      {data.category_id ||
                                      data.is_bundle == 1 ? (
                                        <td className="table-updated-td"></td>
                                      ) : (
                                        <td className="table-updated-td">
                                          <input
                                            type="number"
                                            className={
                                              this.state[
                                                "service_product_units_error_" +
                                                  data.id
                                              ]
                                                ? "tableInput field-error"
                                                : "tableInput"
                                            }
                                            name={
                                              "service_product_units_" + data.id
                                            }
                                            value={
                                              this.state[
                                                "service_product_units_" +
                                                  data.id
                                              ]
                                            }
                                            onChange={this.handleUnitsChange}
                                          />
                                          {data.min && (
                                            <span className="font-12">
                                              Min: {data.min}
                                            </span>
                                          )}
                                        </td>
                                      )}
                                      <td className="table-updated-td no-padding">
                                        <div
                                          className={cx(
                                            "preferred-color tableColorPrefer dropdown",
                                            { "m-b-23": !!data.min },
                                          )}
                                        >
                                          <a
                                            className="new-white-btn p-l-5 p-r-5 changeColor"
                                            id={
                                              "dropdown-menu-service-" + data.id
                                            }
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="true"
                                          >
                                            Change
                                          </a>
                                          <span
                                            className="current-color"
                                            style={{
                                              backgroundColor: this.state[
                                                "service_product_color_" +
                                                  data.id
                                              ]
                                                ? this.state[
                                                    "service_product_color_" +
                                                      data.id
                                                  ] == "no-color"
                                                  ? "#fff"
                                                  : this.state[
                                                      "service_product_color_" +
                                                        data.id
                                                    ]
                                                : "#fff",
                                              borderColor:
                                                this.state[
                                                  "service_product_color_" +
                                                    data.id
                                                ] &&
                                                !this.state[
                                                  "service-product-color_error_" +
                                                    data.id
                                                ]
                                                  ? this.state[
                                                      "service_product_color_" +
                                                        data.id
                                                    ]
                                                  : this.state[
                                                      "service-product-color_error_" +
                                                        data.id
                                                    ]
                                                  ? "#e74c3c"
                                                  : "#667680",
                                            }}
                                          ></span>
                                          <ul
                                            className="dropdown-menu"
                                            aria-labelledby={
                                              "dropdown-menu-service-" + data.id
                                            }
                                          >
                                            {provider_colors.map((m, i) => {
                                              return (
                                                <li
                                                  key={"provider_colors-" + i}
                                                  data-color={m}
                                                >
                                                  <span
                                                    className="color-palette"
                                                    onClick={() =>
                                                      this.setColor(
                                                        data,
                                                        m,
                                                        "service",
                                                      )
                                                    }
                                                    style={{
                                                      backgroundColor: m,
                                                      borderColor: m,
                                                    }}
                                                  >
                                                    {m ==
                                                      this.state[
                                                        "service_product_color_" +
                                                          data.id
                                                      ] && (
                                                      <span className="color-palette-checked">
                                                        <i
                                                          className="fa fa-check"
                                                          style={{
                                                            color: "#fff",
                                                          }}
                                                        ></i>
                                                      </span>
                                                    )}
                                                  </span>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      </td>
                                      {data.category_id ||
                                      data.is_bundle == 1 ? (
                                        <td className="table-updated-td"></td>
                                      ) : (
                                        <td className="table-updated-td">
                                          <input
                                            type="text"
                                            name={
                                              "service_price_per_unit_" +
                                              data.id
                                            }
                                            onChange={this.handleUnitsChange}
                                            value={
                                              this.state[
                                                "service_price_per_unit_" +
                                                  data.id
                                              ]
                                            }
                                            className={cx("tableInput", {
                                              "field-error":
                                                this.state[
                                                  "service_price_per_unit_error_" +
                                                    data.id
                                                ],
                                              "m-b-20": !!data.min,
                                            })}
                                          />
                                        </td>
                                      )}
                                      <td className="table-updated-td">
                                        <a
                                          className={cx("easy-link p-l-0", {
                                            "m-b-20": !!data.min,
                                          })}
                                          onClick={() =>
                                            this.deleteRecipeProductModal(
                                              "service",
                                              data,
                                              index,
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                } else if (data.type == "Bundle") {
                                  return (
                                    <tr className="table-updated-tr">
                                      <td className="table-updated-td colorAR">
                                        <b>Bundle : {data.bundle_name}</b>
                                      </td>
                                      <td className="table-updated-td">
                                        <input
                                          type="number"
                                          className={
                                            this.state[
                                              "service_bundle_units_error_" +
                                                data.bundle_id
                                            ]
                                              ? "tableInput field-error"
                                              : "tableInput"
                                          }
                                          name={
                                            "service_bundle_units_" +
                                            data.bundle_id
                                          }
                                          value={
                                            this.state[
                                              "service_bundle_units_" +
                                                data.bundle_id
                                            ]
                                          }
                                          onChange={this.handleUnitsChange}
                                        />
                                        {data.min && (
                                          <span className="font-12">
                                            Min: {data.min}
                                          </span>
                                        )}
                                      </td>
                                      <td className="table-updated-td"></td>
                                      <td className="table-updated-td">
                                        <input
                                          type="text"
                                          name={
                                            "service_bundle_price_per_unit_" +
                                            data.bundle_id
                                          }
                                          onChange={this.handleUnitsChange}
                                          value={
                                            this.state[
                                              "service_bundle_price_per_unit_" +
                                                data.bundle_id
                                            ]
                                          }
                                          className={cx("tableInput", {
                                            "field-error":
                                              this.state[
                                                "service_bundle_price_per_unit_error_" +
                                                  data.bundle_id
                                              ],
                                            "m-b-20": !!data.min,
                                          })}
                                        />
                                      </td>
                                      <td className="table-updated-td"></td>
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <tr className="table-updated-tr">
                                      <td className="table-updated-td colorAR">
                                        <b>Category : {data.category_name}</b>
                                      </td>
                                      <td className="table-updated-td">
                                        <input
                                          type="text"
                                          className={
                                            this.state[
                                              "service_category_units_error_" +
                                                data.category_id
                                            ]
                                              ? "tableInput field-error"
                                              : "tableInput"
                                          }
                                          name={
                                            "service_category_units_" +
                                            data.category_id
                                          }
                                          value={
                                            this.state[
                                              "service_category_units_" +
                                                data.category_id
                                            ]
                                          }
                                          onChange={this.handleUnitsChange}
                                        />
                                      </td>
                                      <td className="table-updated-td"></td>
                                      <td className="table-updated-td">
                                        <input
                                          type="text"
                                          name={
                                            "service_category_price_per_unit_" +
                                            data.category_id
                                          }
                                          onChange={this.handleUnitsChange}
                                          value={
                                            this.state[
                                              "service_category_price_per_unit_" +
                                                data.category_id
                                            ]
                                          }
                                          className={
                                            this.state[
                                              "service_category_price_per_unit_error_" +
                                                data.category_id
                                            ]
                                              ? "tableInput field-error"
                                              : "tableInput"
                                          }
                                        />
                                      </td>
                                      <td className="table-updated-td"></td>
                                    </tr>
                                  );
                                }
                              })}

                            {this.state.service_products &&
                              this.state.service_products.length == 0 && (
                                <tr className="table-updated-tr">
                                  <td
                                    colSpan="5"
                                    className="table-updated-td text-center"
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {this.state.selectedBucketType == "retail" && (
                    <div>
                      <div className="juvly-subtitle m-b-20">
                        Retail Bucket
                        <a
                          className="new-blue-btn pull-right -m-t-5"
                          onClick={() =>
                            this.openPopupForMedicalOrRetail("retail")
                          }
                        >
                          Add
                        </a>
                      </div>

                      <div className="table-responsive">
                        <table className="table-updated setting-table min-w-850 no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-4 table-updated-th">
                                Name
                              </th>
                              <th className="col-xs-3 table-updated-th">
                                Units
                              </th>
                              <th className="col-xs-3 table-updated-th">
                                Price per unit ({getCurrencySymbol()})
                              </th>
                              <th className="col-xs-2 table-updated-th">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {this.state.retail_products &&
                              this.state.retail_products.length > 0 &&
                              this.state.retail_products.map((data, index) => {
                                if (data.id) {
                                  return (
                                    <tr
                                      className={
                                        data.is_bundle && data.is_bundle == 1
                                          ? "table-updated-tr backgroundGray"
                                          : "table-updated-tr"
                                      }
                                    >
                                      <td className="table-updated-td">
                                        <div
                                          className={cx({
                                            "m-b-20": !!data.min,
                                          })}
                                        >
                                          {data.product_name}
                                          <div className="font-12 text-italic">
                                            (start at: {data.min})
                                          </div>
                                        </div>
                                      </td>
                                      {data.is_bundle && data.is_bundle == 1 ? (
                                        <td className="table-updated-td"></td>
                                      ) : (
                                        <td className="table-updated-td">
                                          <input
                                            type="number"
                                            name={
                                              "retail_product_units_" + data.id
                                            }
                                            value={
                                              this.state[
                                                "retail_product_units_" +
                                                  data.id
                                              ]
                                            }
                                            onChange={this.handleUnitsChange}
                                            className={
                                              this.state[
                                                "retail_product_units_error_" +
                                                  data.id
                                              ]
                                                ? "tableInput field-error"
                                                : "tableInput"
                                            }
                                          />
                                          {data.min && (
                                            <span className="font-12">
                                              Min: {data.min}
                                            </span>
                                          )}
                                        </td>
                                      )}
                                      {data.is_bundle && data.is_bundle == 1 ? (
                                        <td className="table-updated-td"></td>
                                      ) : (
                                        <td className="table-updated-td">
                                          <input
                                            type="text"
                                            name={
                                              "retail_price_per_unit_" + data.id
                                            }
                                            onChange={this.handleUnitsChange}
                                            value={
                                              this.state[
                                                "retail_price_per_unit_" +
                                                  data.id
                                              ]
                                            }
                                            className={cx("tableInput", {
                                              "field-error":
                                                this.state[
                                                  "retail_price_per_unit_error_" +
                                                    data.id
                                                ],
                                              "m-b-20": !!data.min,
                                            })}
                                          />
                                        </td>
                                      )}
                                      <td className="table-updated-td">
                                        <a
                                          className={cx("easy-link p-l-0", {
                                            "m-b-20": !!data.min,
                                          })}
                                          onClick={() =>
                                            this.deleteRecipeProductModal(
                                              "retail",
                                              data,
                                              index,
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <tr className="table-updated-tr">
                                      <td className="table-updated-td colorAR">
                                        <b>Bundle : {data.bundle_name}</b>
                                      </td>
                                      <td className="table-updated-td">
                                        <input
                                          type="number"
                                          name={
                                            "retail_bundle_units_" +
                                            data.bundle_id
                                          }
                                          value={
                                            this.state[
                                              "retail_bundle_units_" +
                                                data.bundle_id
                                            ]
                                          }
                                          onChange={this.handleUnitsChange}
                                          className={
                                            this.state[
                                              "retail_bundle_units_error_" +
                                                data.bundle_id
                                            ]
                                              ? "tableInput field-error"
                                              : "tableInput"
                                          }
                                        />
                                        {data.min && (
                                          <span className="font-12">
                                            Min: {data.min}
                                          </span>
                                        )}
                                      </td>
                                      <td className="table-updated-td">
                                        <input
                                          type="text"
                                          name={
                                            "retail_bundle_price_per_unit_" +
                                            data.bundle_id
                                          }
                                          onChange={this.handleUnitsChange}
                                          value={
                                            this.state[
                                              "retail_bundle_price_per_unit_" +
                                                data.bundle_id
                                            ]
                                          }
                                          className={cx("tableInput", {
                                            "field-error":
                                              this.state[
                                                "retail_bundle_price_per_unit_error_" +
                                                  data.bundle_id
                                              ],
                                            "m-b-20": !!data.min,
                                          })}
                                        />
                                      </td>
                                      <td className="table-updated-td"></td>
                                    </tr>
                                  );
                                }
                              })}
                            {this.state.retail_products &&
                              this.state.retail_products.length == 0 && (
                                <tr className="table-updated-tr">
                                  <td
                                    colSpan="5"
                                    className="table-updated-td text-center"
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}

                  {this.state.selectedBucketType == "medical_supplies" && (
                    <div>
                      <div className="juvly-subtitle m-b-20">
                        Medical Supplies Bucket
                        <a
                          className="new-blue-btn pull-right -m-t-5"
                          onClick={() =>
                            this.openPopupForMedicalOrRetail("medical_supplies")
                          }
                        >
                          Add
                        </a>
                      </div>

                      <div className="table-responsive">
                        <table className="table-updated setting-table min-w-850 no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-4 table-updated-th">
                                Name
                              </th>
                              <th className="col-xs-3 table-updated-th">
                                Units
                              </th>
                              <th className="col-xs-3 table-updated-th">
                                Price per unit ({getCurrencySymbol()})
                              </th>

                              <th className="col-xs-2 table-updated-th">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {this.state.medical_products &&
                              this.state.medical_products.length > 0 &&
                              this.state.medical_products.map((data, index) => {
                                if (data.id) {
                                  return (
                                    <tr
                                      className={
                                        data.is_bundle && data.is_bundle == 1
                                          ? "table-updated-tr backgroundGray"
                                          : "table-updated-tr"
                                      }
                                    >
                                      <td className="table-updated-td">
                                        <div
                                          className={cx({
                                            "m-b-20": !!data.min,
                                          })}
                                        >
                                          {data.product_name}
                                          <div className="font-12 text-italic">
                                            (start at: {data.min})
                                          </div>
                                        </div>
                                      </td>
                                      {data.is_bundle && data.is_bundle == 1 ? (
                                        <td className="table-updated-td"></td>
                                      ) : (
                                        <td className="table-updated-td">
                                          <input
                                            type="number"
                                            name={
                                              "medical_product_units_" + data.id
                                            }
                                            value={
                                              this.state[
                                                "medical_product_units_" +
                                                  data.id
                                              ]
                                            }
                                            onChange={this.handleUnitsChange}
                                            className={
                                              this.state[
                                                "medical_product_units_error_" +
                                                  data.id
                                              ]
                                                ? "tableInput field-error"
                                                : "tableInput"
                                            }
                                          />
                                          {data.min && (
                                            <span className="font-12">
                                              Min: {data.min}
                                            </span>
                                          )}
                                        </td>
                                      )}
                                      {data.is_bundle && data.is_bundle == 1 ? (
                                        <td className="table-updated-td"></td>
                                      ) : (
                                        <td className="table-updated-td">
                                          <input
                                            type="text"
                                            name={
                                              "medical_price_per_unit_" +
                                              data.id
                                            }
                                            onChange={this.handleUnitsChange}
                                            value={
                                              this.state[
                                                "medical_price_per_unit_" +
                                                  data.id
                                              ]
                                            }
                                            className={cx("tableInput", {
                                              "field-error":
                                                this.state[
                                                  "medical_price_per_unit_error_" +
                                                    data.id
                                                ],
                                              "m-b-20": !!data.min,
                                            })}
                                          />
                                        </td>
                                      )}
                                      <td className="table-updated-td">
                                        <a
                                          className={cx("easy-link p-l-0", {
                                            "m-b-20": !!data.min,
                                          })}
                                          onClick={() =>
                                            this.deleteRecipeProductModal(
                                              "medical_supplies",
                                              data,
                                              index,
                                            )
                                          }
                                        >
                                          <i className="fa fa-trash-alt m-r-5"></i>{" "}
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                } else {
                                  return (
                                    <tr className="table-updated-tr">
                                      <td className="table-updated-td colorAR">
                                        <b>Bundle : {data.bundle_name}</b>
                                      </td>
                                      <td className="table-updated-td">
                                        <input
                                          type="number"
                                          name={
                                            "medical_bundle_units_" +
                                            data.bundle_id
                                          }
                                          value={
                                            this.state[
                                              "medical_bundle_units_" +
                                                data.bundle_id
                                            ]
                                          }
                                          onChange={this.handleUnitsChange}
                                          className={
                                            this.state[
                                              "medical_bundle_units_error_" +
                                                data.bundle_id
                                            ]
                                              ? "tableInput field-error"
                                              : "tableInput"
                                          }
                                        />
                                        {data.min && (
                                          <span className="font-12">
                                            Min: {data.min}
                                          </span>
                                        )}
                                      </td>
                                      <td className="table-updated-td">
                                        <input
                                          type="text"
                                          name={
                                            "medical_bundle_price_per_unit_" +
                                            data.bundle_id
                                          }
                                          onChange={this.handleUnitsChange}
                                          value={
                                            this.state[
                                              "medical_bundle_price_per_unit_" +
                                                data.bundle_id
                                            ]
                                          }
                                          className={cx("tableInput", {
                                            "field-error":
                                              this.state[
                                                "medical_bundle_price_per_unit_error_" +
                                                  data.bundle_id
                                              ],
                                            "m-b-20": !!data.min,
                                          })}
                                        />
                                      </td>
                                      <td className="table-updated-td"></td>
                                    </tr>
                                  );
                                }
                              })}
                            {this.state.medical_products &&
                              this.state.medical_products.length == 0 && (
                                <tr className="table-updated-tr">
                                  <td
                                    colSpan="4"
                                    className="table-updated-td text-center"
                                  >
                                    No data found
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {this.state.managePrice == "product_level" && (
                <div className="row">
                  <div className="col-lg-12 col-xs-12">
                    {this.state.selected_products &&
                      this.state.selected_products.map((data, index) => {
                        return (
                          <div key={"clinic-" + index}>
                            <div className="col-xs-12 col-lg-12">
                              <h5 className="m-b-10  m-t-25 proNameTitle">
                                Product : {data.product_name}{" "}
                                <span
                                  className="pull-right handIcon"
                                  onClick={() =>
                                    this.deleteProductModal(
                                      data.product_id,
                                      data.id,
                                    )
                                  }
                                >
                                  <i className="fa fa-trash"></i>
                                </span>
                              </h5>
                            </div>

                            <div className="col-lg-12 col-xs-12">
                              <div className="right-sign-btn repeat-price new-repeat-price">
                                <input
                                  className="pull-left sel-all-visible"
                                  type="checkbox"
                                  id="save-sign"
                                  name={"priceRepeat-" + data.product_id}
                                  autoComplete="off"
                                  onChange={() =>
                                    this.handleCheckBoxChange(data)
                                  }
                                  checked={
                                    this.state["priceRepeat-" + data.product_id]
                                      ? "checked"
                                      : false
                                  }
                                />
                                <label
                                  className="search-text"
                                  htmlFor="save-sign"
                                >
                                  {this.state.inventoryLang.label_same_clinics}
                                </label>
                              </div>
                            </div>

                            {data.clinicsList &&
                              data.clinicsList.map((obj, i) => {
                                return (
                                  <div className="row" key={"clinicPrice-" + i}>
                                    <div className="col-lg-12 col-xs-12">
                                      <div
                                        className={
                                          this.state.edit == true
                                            ? "col-md-4 col-xs-12"
                                            : "col-md-4 col-xs-12"
                                        }
                                      >
                                        <div className="prod-price-clinic-name m-t-25">
                                          {obj.clinic_name}
                                        </div>
                                      </div>
                                      {obj.show_price == true && (
                                        <div className="col-md-4 col-sm-6 col-xs-12">
                                          <div className="newInputFileldOuter">
                                            <div className="newInputLabel">
                                              {
                                                this.state.inventoryLang
                                                  .label_original_price
                                              }
                                            </div>
                                            <input
                                              className="newInputField"
                                              disabled={true}
                                              type="text"
                                              value={obj.price_per_unit}
                                              autoComplete="off"
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div
                                        className={
                                          this.state.edit == true
                                            ? "col-md-4 col-xs-12"
                                            : "col-md-4 col-xs-12"
                                        }
                                      >
                                        <div className="newInputFileldOuter">
                                          <div className="newInputLabel">
                                            {
                                              this.state.inventoryLang
                                                .label_new_price
                                            }
                                          </div>
                                          <input
                                            className={
                                              this.state[
                                                "new-price-clinic-" +
                                                  obj.clinic_id +
                                                  "-product-" +
                                                  obj.product_id +
                                                  "error"
                                              ] == false
                                                ? "newInputField"
                                                : "newInputField field-error"
                                            }
                                            type="text"
                                            name={
                                              "new-price-clinic-" +
                                              obj.clinic_id +
                                              "-product-" +
                                              obj.product_id
                                            }
                                            value={
                                              this.state[
                                                "new-price-clinic-" +
                                                  obj.clinic_id +
                                                  "-product-" +
                                                  data.product_id
                                              ]
                                            }
                                            autoComplete="off"
                                            onChange={this.handleInputChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}

              {this.state.managePrice == "product_level" && (
                <div
                  className={
                    this.state.selected_products &&
                    this.state.selected_products.length == 0
                      ? "row m-t-15 p15 border-top"
                      : "row"
                  }
                >
                  <div className="col-xs-12 col-lg-12">
                    <div className="col-xs-12 m-b-10">
                      {this.state.selected_products &&
                        this.state.selected_products.length == 0 && (
                          <h5>{this.state.inventoryLang.label_add_products}</h5>
                        )}
                    </div>
                  </div>
                  {this.state.showSearch && this.state.showSearch == true && (
                    <div className="col-sm-12 col-lg-12">
                      <div className="col-md-4 col-sm-6 col-xs-12">
                        <div
                          className="search_product_container"
                          ref={(ref_search_product) =>
                            (this.ref_search_product = ref_search_product)
                          }
                        >
                          <div className="simpleField m-b-10">
                            <div className="simpleLabel">
                              {
                                this.state.inventoryLang
                                  .inventory_product_name_label
                              }
                            </div>
                            <input
                              type="text"
                              className="simpleInput"
                              autoComplete="off"
                              name="search_product_keyword"
                              value={this.state.search_product_keyword}
                              onChange={this.handleProductChange.bind(this)}
                              onFocus={this.handleOnFocus.bind(this)}
                            />
                          </div>
                          <ul
                            className={
                              typeof this.state.search_product_keyword ===
                                "string" &&
                              this.state.search_product_keyword.trim() !== "" &&
                              this.state.is_product_search !== undefined &&
                              this.state.is_product_search === true &&
                              this.state.search_product_keyword.length > 2
                                ? " search-dropdown"
                                : "cal-dropdown clinicname-dropdown no-display"
                            }
                          >
                            {this.state.productList.length > 0 ? (
                              this.state.productList.map((obj, idx) => {
                                return (
                                  <li
                                    key={"search_product-" + idx}
                                    data-id={obj.id}
                                    data-name={obj.product_name}
                                    onClick={(e) => this.selectProduct(e, obj)}
                                  >
                                    <a>{obj && obj.product_name}</a>
                                  </li>
                                );
                              })
                            ) : (
                              <li
                                key={"search_product-norecord"}
                                data-id={0}
                                data-name={"product_match_not_found"}
                                data-index={-1}
                              >
                                <a>
                                  {this.state.is_search_first_time
                                    ? this.state.globalLang
                                        .label_searching_with_dot
                                    : this.state.globalLang
                                        .product_match_not_found}
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {this.state.managePrice == "product_level" && (
                <div className="row">
                  <div className="col-xs-12 p2030">
                    {this.state.selected_products &&
                      this.state.selected_products.length > 0 && (
                        <a
                          onClick={this.addMore}
                          className="new-blue-btn pull-right no-margin m-r-10"
                        >
                          {this.state.globalLang.label_add_more}
                        </a>
                      )}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className={this.state.showModal ? "overlay" : ""}></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showModal ? "modal fade in displayBlock" : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.settingsLang.settings_Confirmation_required}
                    {this.state.showModal}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {this.state.inventoryLang.inventory_variation_delete_msg}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      {this.state.settingsLang.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deletePackage}
                    >
                      {this.state.settingsLang.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delete product starts */}
          <div
            className={this.state.showProductDeleteModal ? "overlay" : ""}
          ></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.showProductDeleteModal
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissProductModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.settingsLang.settings_Confirmation_required}
                    {this.state.showModal}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {this.state.inventoryLang.message_delete_product}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissProductModal}
                    >
                      {this.state.settingsLang.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deleteProduct}
                    >
                      {this.state.settingsLang.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delete product ends */}

          {/* ******DELETE BUCKET PRODUCT********* */}
          <div
            className={this.state.deleteProductBucketModal ? "overlay" : ""}
          ></div>
          <div
            id="filterModal"
            role="dialog"
            className={
              this.state.deleteProductBucketModal
                ? "modal fade in displayBlock"
                : "modal fade"
            }
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    onClick={this.dismissCollectiveModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">
                    {this.state.settingsLang.settings_Confirmation_required}
                    {this.state.showModal}
                  </h4>
                </div>
                <div
                  id="errorwindow"
                  className="modal-body add-patient-form filter-patient"
                >
                  {this.state.inventoryLang.message_delete_product}
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button
                      type="button"
                      className="btn  logout pull-right"
                      data-dismiss="modal"
                      onClick={this.dismissCollectiveModal}
                    >
                      {this.state.settingsLang.no_option}
                    </button>
                    <button
                      type="button"
                      className="btn btn-success pull-right m-r-10"
                      data-dismiss="modal"
                      onClick={this.deleteProductForCollective}
                    >
                      {this.state.settingsLang.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Delete Bucket Moda Starts */}
        <div className={this.state.deleteBucketPopup ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.deleteBucketPopup
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissBucketPopup}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.settingsLang.settings_Confirmation_required}
                  {this.state.showModal}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                Are you sure you want to delete this bucket?
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={this.dismissBucketPopup}
                  >
                    {this.state.settingsLang.no_option}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteBucket}
                  >
                    {this.state.settingsLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Products popup */}
        {
          <div
            className={
              this.state.openMedicalPopup === true
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="loyaltyMembership small-popup">
                <div className="newPopupTitle no-padding m-b-10">
                  <a
                    onClick={() => this.dismissMedicalPopup()}
                    className="popupClose2"
                  >
                    <img alt="" src={picClose} />
                  </a>
                </div>
                <div className="newPopupContent m-t-10">
                  <div className="simpleField">
                    <div className="simpleLabel">
                      Search<span className="required m-l-5">*</span>
                    </div>
                    <input
                      type="text"
                      name="searchForRetailOrMedical"
                      placeholder={
                        this.state.selected_type == "retail"
                          ? "Search retail products"
                          : "Search medical supplies"
                      }
                      value={this.state.searchForRetailOrMedical}
                      onChange={(e) => this.handleSearchMedical(e, "products")}
                      className="simpleInput"
                    />
                  </div>

                  <div className="simpleField">
                    <div className="simpleLabel">
                      Selected Products<span className="required m-l-5">*</span>
                    </div>
                    <div className="simpleSelectProduct">
                      <div className="customTagOuter">
                        {this.state.popupProducts &&
                          this.state.popupProducts.length > 0 &&
                          this.state.popupProducts.map((data) => {
                            if (data.checked == true) {
                              return (
                                <div
                                  className="customTag"
                                  onClick={() =>
                                    this.removeCheckFromProducts(data)
                                  }
                                >
                                  {data.product_name}
                                  <i className="fa fa-times"></i>
                                </div>
                              );
                            }
                          })}
                      </div>

                      <div className="row privilegeOuter p15">
                        <Scrollbars
                          autoHeight
                          autoHeightMin={0}
                          autoHeightMax={150}
                          className="full-width"
                          renderTrackHorizontal={(props) => (
                            <div
                              {...props}
                              style={{ display: "none" }}
                              className="track-horizontal"
                            />
                          )}
                        >
                          {this.state.searchDataInProducts == false &&
                            this.state.popupProducts &&
                            this.state.popupProducts.length > 0 &&
                            this.state.popupProducts.map((data) => {
                              return (
                                <div className="privileges-row" key={data.id}>
                                  <input
                                    type="checkbox"
                                    name={"popup-product-" + data.id}
                                    checked={
                                      this.state["popup-product-" + data.id]
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBoxChangeForCollective(
                                        e,
                                        "products",
                                        data.id,
                                      )
                                    }
                                    className="new-check child_view-patients"
                                  />
                                  <label
                                    className="setting-text"
                                    htmlFor="view-patients-2"
                                  >
                                    {data.product_name}
                                  </label>
                                </div>
                              );
                            })}

                          {this.state.searchDataInProducts == true &&
                            this.state.searchArray &&
                            this.state.searchArray.length > 0 &&
                            this.state.searchArray.map((data) => {
                              return (
                                <div className="privileges-row" key={data.id}>
                                  <input
                                    type="checkbox"
                                    name={"popup-product-" + data.id}
                                    checked={
                                      this.state["popup-product-" + data.id]
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBoxChangeForCollective(
                                        e,
                                        "products",
                                        data.id,
                                      )
                                    }
                                    className="new-check child_view-patients"
                                  />
                                  <label
                                    className="setting-text"
                                    htmlFor="view-patients-2"
                                  >
                                    {data.product_name}
                                  </label>
                                </div>
                              );
                            })}

                          {this.state.searchDataInProducts == true &&
                            this.state.searchArray &&
                            this.state.searchArray.length == 0 && (
                              <div className="privileges-row text-center">
                                <p>No data found</p>
                              </div>
                            )}
                        </Scrollbars>
                      </div>
                    </div>

                    {this.state.selected_type == "retail" && (
                      <div className="col-xs-12 col-lg-12 m-t-10">
                        <div className="privileges-row">
                          <input
                            type="checkbox"
                            name="makeBundle"
                            checked={this.state.makeBundle ? "checked" : false}
                            onChange={this.handleInputChange}
                            className="new-check child_view-patients"
                          />
                          <label
                            className="setting-text"
                            htmlFor="view-patients-2"
                          >
                            Allow provider to choose any product from selected
                            list to create the collective total.{" "}
                            <span
                              data-tip
                              data-for="simpleText"
                              data-place="right"
                            >
                              <i className="fa fa-info-circle"></i>
                            </span>
                            <ReactTooltip
                              id={"simpleText"}
                              data-class={"width300px"}
                              data-multiline="true"
                              effect="solid"
                            >
                              <span>
                                Within the recipes, you set the quantity and the
                                price. By selecting multiple product options,
                                you can create a bundle, and providers can
                                choose from the list, but only at the
                                pre-defined quantities and prices.
                              </span>
                            </ReactTooltip>
                          </label>
                        </div>
                      </div>
                    )}

                    {this.state.selected_type == "retail" &&
                      this.state.makeBundle == true && (
                        <div className="col-xs-12 col-lg-12">
                          <div className="simpleField">
                            <div className="simpleLabel">
                              Bundle Name
                              <span className="required m-l-5">*</span>
                            </div>
                            <input
                              type="text"
                              name="bundleName"
                              value={this.state.bundleName}
                              onChange={this.handleInputChange}
                              className={
                                this.state.bundle_error
                                  ? "simpleInput field-error"
                                  : "simpleInput"
                              }
                            />
                          </div>
                        </div>
                      )}
                    <button
                      className="new-blue-btn m-l-0 m-t-10"
                      onClick={() =>
                        this.addPopupProductsToBucket(this.state.selected_type)
                      }
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }

        {/* ***SEARCH PRODUCTS/CATEGORIES*** */}
        {
          <div
            className={
              this.state.openProductAndCategoriesPopup === true
                ? "blackOverlay"
                : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="loyaltyMembership small-popup">
                <div className="newPopupTitle no-padding m-b-10">
                  <ul className="section-tab-outer bucketProductOuter">
                    <li>
                      <a
                        className={
                          this.state.selectionType == "products"
                            ? "section-title-name section-tab sel-merge-tab"
                            : "section-title-name section-tab"
                        }
                        onClick={() => this.setSelection("products")}
                      >
                        Products
                      </a>
                    </li>
                    <li>
                      <a
                        className={
                          this.state.selectionType == "category"
                            ? "section-title-name section-tab sel-merge-tab"
                            : "section-title-name section-tab"
                        }
                        onClick={() => this.setSelection("category")}
                      >
                        Categories
                      </a>
                    </li>
                  </ul>
                  <a
                    onClick={() => this.dismissCategoriesPopup()}
                    className="popupClose"
                  >
                    <img src={picClose} alt="" />
                  </a>
                </div>
                <div className="newPopupContent">
                  <div className="simpleField">
                    <div className="simpleLabel">
                      Search<span className="required m-l-5">*</span>
                    </div>
                    <input
                      type="text"
                      name="searchForRetailOrMedical"
                      value={this.state.searchForRetailOrMedical}
                      onChange={(e) =>
                        this.handleSearchMedical(e, this.state.selectionType)
                      }
                      className="simpleInput"
                    />
                  </div>

                  <div className="simpleField">
                    <div className="simpleLabel">
                      Selected Products/Categories
                      <span className="required m-l-5">*</span>
                    </div>
                    <div className="simpleSelectProduct">
                      <div className="customTagOuter">
                        {this.state.popupProducts &&
                          this.state.popupProducts.length > 0 &&
                          this.state.popupProducts.map((data) => {
                            if (data.checked == true) {
                              return (
                                <div
                                  key={data.id}
                                  className="customTag"
                                  onClick={() =>
                                    this.removeCheckFromProducts(data)
                                  }
                                >
                                  {data.product_name}
                                  <i className="fa fa-times"></i>
                                </div>
                              );
                            }
                          })}

                        {this.state.categoryData &&
                          this.state.categoryData.length > 0 &&
                          this.state.categoryData.map((data) => {
                            if (data.checked == true) {
                              return (
                                <div
                                  key={data.id}
                                  className="customTag"
                                  onClick={() =>
                                    this.removeCheckFromCategories(data)
                                  }
                                >
                                  Category : {data.category_name}
                                  <i className="fa fa-times"></i>
                                </div>
                              );
                            }
                          })}
                      </div>
                      <div className="row privilegeOuter p15">
                        <Scrollbars
                          autoHeight
                          autoHeightMin={0}
                          autoHeightMax={150}
                          className="full-width"
                          renderTrackHorizontal={(props) => (
                            <div
                              {...props}
                              style={{ display: "none" }}
                              className="track-horizontal"
                            />
                          )}
                        >
                          {this.state.searchDataInProducts == false &&
                            this.state.selectionType == "products" &&
                            this.state.popupProducts &&
                            this.state.popupProducts.length > 0 &&
                            this.state.popupProducts.map((data) => {
                              return (
                                <div className="privileges-row" key={data.id}>
                                  <input
                                    type="checkbox"
                                    name={"popup-product-" + data.id}
                                    checked={
                                      this.state["popup-product-" + data.id]
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBoxChangeForCollective(
                                        e,
                                        "products",
                                        data.id,
                                      )
                                    }
                                    className="new-check child_view-patients"
                                  />
                                  <label
                                    className="setting-text"
                                    htmlFor="view-patients-2"
                                  >
                                    {data.product_name}
                                  </label>
                                </div>
                              );
                            })}

                          {this.state.selectionType == "products" &&
                            this.state.searchDataInProducts == true &&
                            this.state.searchArray &&
                            this.state.searchArray.length > 0 &&
                            this.state.searchArray.map((data) => {
                              return (
                                <div className="privileges-row" key={data.id}>
                                  <input
                                    type="checkbox"
                                    name={"popup-product-" + data.id}
                                    checked={
                                      this.state["popup-product-" + data.id]
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBoxChangeForCollective(
                                        e,
                                        "products",
                                        data.id,
                                      )
                                    }
                                    className="new-check child_view-patients"
                                  />
                                  <label
                                    className="setting-text"
                                    htmlFor="view-patients-2"
                                  >
                                    {data.product_name}
                                  </label>
                                </div>
                              );
                            })}

                          {this.state.selectionType == "products" &&
                            this.state.searchDataInProducts == true &&
                            this.state.searchArray &&
                            this.state.searchArray.length == 0 && (
                              <div className="privileges-row text-center">
                                <p>No data found</p>
                              </div>
                            )}

                          {this.state.selectionType == "category" &&
                            this.state.searchDataInProducts == false &&
                            this.state.categoryData &&
                            this.state.categoryData.length > 0 &&
                            this.state.categoryData.map((data) => {
                              return (
                                <div className="privileges-row" key={data.id}>
                                  <input
                                    type="checkbox"
                                    name={"popup-category-" + data.id}
                                    checked={
                                      this.state["popup-category-" + data.id]
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBoxChangeForCollective(
                                        e,
                                        "category",
                                        data.id,
                                      )
                                    }
                                    className="new-check child_view-patients"
                                  />
                                  <label
                                    className="setting-text"
                                    htmlFor="view-patients-2"
                                  >
                                    {data.category_name}
                                  </label>
                                </div>
                              );
                            })}

                          {this.state.selectionType == "category" &&
                            this.state.searchDataInProducts == true &&
                            this.state.searchCategoryArray &&
                            this.state.searchCategoryArray.length > 0 &&
                            this.state.searchCategoryArray.map((data) => {
                              return (
                                <div className="privileges-row" key={data.id}>
                                  <input
                                    type="checkbox"
                                    name={"popup-category-" + data.id}
                                    checked={
                                      this.state["popup-category-" + data.id]
                                        ? "checked"
                                        : false
                                    }
                                    onChange={(e) =>
                                      this.handleCheckBoxChangeForCollective(
                                        e,
                                        "category",
                                        data.id,
                                      )
                                    }
                                    className="new-check child_view-patients"
                                  />
                                  <label
                                    className="setting-text"
                                    htmlFor="view-patients-2"
                                  >
                                    {data.category_name}
                                  </label>
                                </div>
                              );
                            })}

                          {this.state.selectionType == "category" &&
                            this.state.searchDataInProducts == true &&
                            this.state.searchCategoryArray &&
                            this.state.searchCategoryArray.length == 0 && (
                              <div className="privileges-row text-center">
                                <p>No data found</p>
                              </div>
                            )}
                        </Scrollbars>
                      </div>
                    </div>
                    {this.state.selectionType == "products" && (
                      <div className="col-xs-12 col-lg-12 m-t-10">
                        <div className="privileges-row">
                          <input
                            type="checkbox"
                            name="makeBundle"
                            checked={this.state.makeBundle ? "checked" : false}
                            onChange={this.handleInputChange}
                            className="new-check child_view-patients"
                          />
                          <label
                            className="setting-text"
                            htmlFor="view-patients-2"
                          >
                            Allow provider to choose any product from selected
                            list to create the collective total.{" "}
                            <span
                              data-tip
                              data-for="simpleText"
                              data-place="right"
                            >
                              <i className="fa fa-info-circle"></i>
                            </span>
                            <ReactTooltip
                              id={"simpleText"}
                              data-class={"width300px"}
                              data-multiline="true"
                              effect="solid"
                            >
                              <span>
                                Within the recipes, you set the quantity and the
                                price. By selecting multiple product options,
                                you can create a bundle, and providers can
                                choose from the list, but only at the
                                pre-defined quantities and prices.
                              </span>
                            </ReactTooltip>
                          </label>
                        </div>
                      </div>
                    )}

                    {this.state.selectionType == "products" &&
                      this.state.makeBundle == true && (
                        <div className="col-xs-12 col-lg-12">
                          <div className="simpleField">
                            <div className="simpleLabel">
                              Bundle Name
                              <span className="required m-l-5">*</span>
                            </div>
                            <input
                              type="text"
                              name="bundleName"
                              value={this.state.bundleName}
                              onChange={this.handleInputChange}
                              className={
                                this.state.bundle_error
                                  ? "simpleInput field-error"
                                  : "simpleInput"
                              }
                            />
                          </div>
                        </div>
                      )}
                    <button
                      className="new-blue-btn m-l-0 m-t-10"
                      onClick={() => this.addPopupProductsToBucket("service")}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  if (state.InventoryReducer.action === "SEARCH_CHARTING_PRODUCT") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.productsData = state.InventoryReducer.data;
      returnState.productsDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "GET_CHARTING_CLINICS") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.clinicsList = state.InventoryReducer.data.data;
      returnState.clinicListDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "CREATE_CHARTING_PACKAGES") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.redirect = true;
    }
  }

  if (state.InventoryReducer.action === "GET_PACKAGE_DETAILS") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      returnState.packageDetails = state.InventoryReducer.data.data;
      returnState.packageDetailsDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "UPDATE_CHARTING_PACKAGES") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.redirect = true;
    }
  }

  if (state.InventoryReducer.action === "PACKAGE_ACTIVE_INACTIVE") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.redirect = true;
    }
  }

  if (state.InventoryReducer.action === "DELETE_PACKAGE_PRODUCT") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.packageDetails = state.InventoryReducer.data.data;
      returnState.packageDetailsDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "CHARTING_PRODUCT_SEARCH") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      returnState.recipeProductList = state.InventoryReducer.data.data;
      returnState.recipeProductDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "CHARTING_CATEGORY_SEARCH") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      returnState.recipeCategoryList = state.InventoryReducer.data.data;
      returnState.recipeCategoryDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "CHARTING_CATEGORY_PRODUCT_LIST") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      returnState.recipeProductOfCategory = state.InventoryReducer.data.data;
      returnState.recipeProductCategoryDataTime = new Date();
    }
  }

  if (state.InventoryReducer.action === "CREATE_COLLECTIVE_PACKAGE") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.redirect = true;
    }
  }

  if (state.InventoryReducer.action === "DELETE_BUCKET_PRODUCT") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.packageDetails = state.InventoryReducer.data.data;
      returnState.packageDetailsDataTime = new Date();
      // returnState.deleteData = ;
    }
  }

  if (state.InventoryReducer.action === "DELETE_BUCKET") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.packageDetails = state.InventoryReducer.data.data;
      returnState.packageDetailsDataTime = new Date();
      // returnState.deleteData = ;
    }
  }

  if (state.InventoryReducer.action === "GET_MEDICAL_AND_RETAIL_PRODUCTS") {
    if (state.InventoryReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.medicalAndRetailProducts = state.InventoryReducer.data.data;
      returnState.medicalAndRetailProductsTime = new Date();
    }
  }

  if (
    state.InventoryReducer.action === "CHARTING_SINGLE_CATEGORY_PRODUCT_LIST"
  ) {
    if (state.InventoryReducer.data.status !== 200) {
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.singleCategoryProducts = state.InventoryReducer.data.data;
      returnState.singleCategoryProductsDataTime = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchProduct: searchProduct,
      findClinics: findClinics,
      addNewPackage: addNewPackage,
      getPackageData: getPackageData,
      updatePackage: updatePackage,
      deletePackage: deletePackage,
      deleteProductFromChartingPackage: deleteProductFromChartingPackage,
      exportEmptyData: exportEmptyData,
      searchProductForRecipe: searchProductForRecipe,
      searchCategoryForRecipe: searchCategoryForRecipe,
      searchProductCategory: searchProductCategory,
      submitCollectiveTotal: submitCollectiveTotal,
      deleteProductForCollective: deleteProductForCollective,
      deleteBucket: deleteBucket,
      getProductsForMedicalAndRetail: getProductsForMedicalAndRetail,
      searchProductCategoryForSingleCategory:
        searchProductCategoryForSingleCategory,
    },
    dispatch,
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(CreateEditChartingPackages));
