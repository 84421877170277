import * as Sentry from "@sentry/react";

const isDomNesting = (event) => {
  return Boolean(event?.message?.includes?.("Warning: validateDOMNesting"));
};

const isUncontrolled = (event) => {
  return Boolean(
    event?.message?.includes?.(
      "Decide between using a controlled or uncontrolled",
    ),
  );
};

const isTinyMce = (event) => {
  const exception = event?.exception?.values?.[0] || {};
  const stackFrames = exception.stacktrace?.frames || [];
  return stackFrames.some((frame) => {
    return !!frame.filename?.includes("tinymce/tinymce.min.js");
  });
};

const isNetworkError = (event) => {
  const message = event?.exception?.values?.[0]?.value;
  return message === "Network Error";
};

const isPromiseRejectionWithKeys = (event) => {
  const message = event?.exception?.values?.[0]?.value || "";
  return message.includes("Object captured as promise rejection with keys");
};

const is0MsTimeout = (event) => {
  const message = event?.exception?.values?.[0]?.value || "";
  return message.includes("timeout of 0ms exceeded");
};

const isStripe = (event) => {
  const message = event?.exception?.values?.[0]?.value || "";
  return message.includes("Failed to load Stripe.js");
};

const is400 = (event) => {
  const message = event?.exception?.values?.[0]?.value || "";
  return message.includes("Request failed with status code 400");
};

export function initSentry() {
  if (
    process.env.REACT_APP_SENTRY_DSN &&
    process.env.REACT_APP_SENTRY_RELEASE_VERSION
  ) {
    Sentry.init({
      release: process.env.REACT_APP_SENTRY_RELEASE_VERSION,
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
        Sentry.captureConsoleIntegration({
          levels: ["error"],
        }),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      beforeSend: (event) => {
        if (
          isDomNesting(event) ||
          isUncontrolled(event) ||
          isTinyMce(event) ||
          isNetworkError(event) ||
          isPromiseRejectionWithKeys(event) ||
          is0MsTimeout(event) ||
          isStripe(event) ||
          is400(event)
        ) {
          return null;
        }

        return event;
      },
    });
  }
}
