import React, {useEffect, useState} from "react";
import { FormProvider, useForm } from "react-hook-form";
import classes from "../sass/membership.module.scss";
import cx from "clsx";
import { toast } from "react-toastify";
import { getClearentToken } from "../../../Utils";
import MembershipPayment from "../MembershipPayment";
import { uiNotification } from "../../../services/UINotificationService";
import {connect} from "react-redux";
import {getClientCardDataStripe} from "../../../Actions/Stripe/StripeActions";
import {bindActionCreators} from "redux";

const AddMembershipCard = (props) => {
  const {
    openLoader,
    closeLoader,
    clientData,
    hideShowCardOption,
    userData,
    selectedMembershipID,
    updateMembershipCC,
    clearentClinic,
    stripeIntent,
    getClientCardDataStripe,
    selectedClinicId,
    cardList
  } = props;
  const formMethods = useForm({
    defaultValues: { clearentEmail: clientData.email },
  });

  const { handleSubmit } = formMethods;
  const [displayProceedButton, setDisplayProceedButton] = useState('');
  const [cardSelected, setCardSelected] = useState('');

  const updateProceedState = (value) => {
    setDisplayProceedButton(value);
  };
  const updateCardSelected = (value) => {
    setCardSelected(value);
  };

  const handleSubmitPayment = async (data) => {
    const isStripeGateway = userData?.account?.pos_gateway === "stripe";
    let formData = {};

    const handleStripePayment = async () => {
      if(displayProceedButton) {
        if(!cardSelected) {
          uiNotification.error("Select payment method");
          return false;
        }
        formData = {payment_method_id: cardSelected, membership_id: selectedMembershipID};
      } else {
        const token = stripeIntent?.setupIntentId || "";
        formData = {setup_intent_id: token, membership_id: selectedMembershipID};
      }
      return true;
    };

    const handleClearentPayment = async () => {
      const tokenResponse = { token: { id: await getClearentToken() } };
      if (tokenResponse.error) {
        uiNotification.error(tokenResponse.error.message);
        return false;
      }
      formData = {
        mstripeToken: tokenResponse.token.id,
        membership_id: selectedMembershipID,
        clearent_zip: data.clearentZip,
        clearent_email_id: data.clearentEmail,
      };
      return true;
    };

    try {
      openLoader();

      let isPaymentHandled = false;
      if (isStripeGateway) {
        isPaymentHandled = await handleStripePayment();
      } else {
        isPaymentHandled = await handleClearentPayment();
      }

      if (!isPaymentHandled) return;

      await updateMembershipCC(clientData.id, formData);
      hideShowCardOption();
      getClientCardDataStripe({patient_id: clientData.id});

    } catch (error) {
      uiNotification.error("There was an error processing your payment.");
    } finally {
      closeLoader();
    }
  };

  const onError = (e) => {
    console.log("errors", e);
  };

  useEffect(() => {
    if (cardList?.length === 1) {
      setCardSelected(cardList[0].id)
    }
  }, [cardList])

  return (
    <FormProvider {...formMethods}>
      <form>
        <div className="cc-details">
          <MembershipPayment
            notDisplayTitle
            clearentClinic={clearentClinic}
            updateProceedState={updateProceedState}
            updateCardSelected={updateCardSelected}
            clientId={clientData.id}
            isAddMembership={false}
            onSubmit={handleSubmit(handleSubmitPayment, onError)}
            onCancel={hideShowCardOption}
            selectedClinicId={selectedClinicId}
          />
        {userData?.account?.pos_gateway !== "stripe" &&
          <div className={cx(classes.flexBox, "m-t-20")}>
            <div
              className={classes.saveButton}
              onClick={handleSubmit(handleSubmitPayment, onError)}
            >
              Save
            </div>
            <div onClick={hideShowCardOption} className={classes.cancelButton}>
              Cancel
            </div>
          </div>
        }
        {displayProceedButton && userData?.account?.pos_gateway === "stripe" &&
            <div className={classes.footer}>
              <button onClick={hideShowCardOption} className="new-blue-btn">Cancel</button>
              <button onClick={handleSubmit(handleSubmitPayment, onError)} className="new-blue-btn">Save</button>
            </div>
        }
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => ({
  stripeIntent: state.StripeReducer.stripeIntent,
  cardList: state.StripeReducer.cardList?.filter((c) => c.card.last4),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
          getClientCardDataStripe
        },
        dispatch,
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddMembershipCard);