import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Input } from "../../../shared/Input/Input.js";
import { Select } from "../../../shared/Select/Select.js";
import { Button } from "../../../shared/Button/Button";
import classes from "./discountcoupons.module.scss";
import cx from "clsx";
import {
  fetchDiscountCoupons,
  emptyInventoryReducer,
  updateDiscountCouponsId,
  createDiscountCouponsId,
  deleteDiscountCouponsId,
  fetchDiscountCouponsId,
  exportEmptyData,
} from "../../../Actions/Inventory/inventoryActions.js";
import { withRouter } from "react-router";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  showFormattedDate,
  numberFormat,
  autoScrolling,
  dateFormatPicker,
} from "../../../Utils/services.js";
import { Scrollbars } from "react-custom-scrollbars";
import "react-datepicker/dist/react-datepicker.css";
import { uiNotification } from "../../../services/UINotificationService.js";

const inventorySelectOptions = Array.from(Array(12 + 1).keys())
  .slice(1)
  .map((optionDetails, i) => (
    <option key={i} value={optionDetails}>{optionDetails}</option>
  ));

const discountTypes = [
  {
    label: "Dollar",
    value: "dollar",
    name: "discount_type",
  },
  {
    label: "Percentage",
    value: "percentage",
    name: "discount_type",
  },
];

class DiscountCoupons extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      page: 1,
      pagesize: 15,
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      searchFunction: "",
      user_changed: false,
      tabClicked: false,
      DiscountCoupons: [],
      data: [],
      childCheck: false,
      sortorder: "asc",
      sortby: "coupon_code",
      scopes: "category",
      selected: [],
      selectAll: 0,
      categoryName: "",
      show_below_stock: 0,
      globalLang: languageData.global,
      dashboardLang: languageData.dashboard,
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      showLoadingText: false,
      discountCouponsTimeStamp: new Date(),
      showModal: false,
      id: 0,
      showCalendar: false,
      couponCode: "",
      discount_type: "",
      discount_value: "",
      showVal: true,
      expiry_date: new Date(moment().add(1, "days")),
      active_till: null, //new Date(moment().add(1, 'days')),
      active_till_min_date: new Date(moment().add(1, "days")),
      fetchDiscountCouponsIdVAl: [],
      createUpdateDelete: [],
      showDateVal: new Date(),
      showDateValFetch: new Date(),
      discount_duration: "forever",
      limited_duration: "",
      onSubmitTime: null,
      account_logo: "",
      dashboardLang: languageData.dashboard,
      showDeleteModal: false,
    };

    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);

    window.onscroll = () => {
      return false;
    };
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  handleSelectChange = (selectedValue) => {
    const { name, value } = selectedValue;
    this.setState({
      [name]: value,
      userChanged: true,
    });
  };

  componentDidMount() {
    const discountCouponsId = this.state.id;
    document.addEventListener("click", this.handleClick, false);
    window.onscroll = () => {
      return false;
    };

    if (discountCouponsId < 1) {
      this.props.exportEmptyData();
    }
    let formData = {
      params: {
        sortorder: "asc",
        sortby: "coupon_code",
      },
    };

    // this.setState({ showLoader : true });
    autoScrolling(true);
    this.props.fetchDiscountCoupons(formData);
  }

  onSort = (sortby) => {
    let sortorder = this.state.sortorder === "asc" ? "desc" : "asc";
    let formData = {
      params: {
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
      },
    };
    this.setState({
      sortby: sortby,
      sortorder: sortorder,
      showLoader: true,

      //DiscountCoupons : []
    });
    localStorage.setItem("sortOnly", false);
    autoScrolling(true);
    this.props.fetchDiscountCoupons(formData);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
      return returnState;
    }
    if (
      nextProps.DiscountCoupons != undefined &&
      prevState.DiscountCoupons != nextProps.DiscountCoupons &&
      nextProps.discountCouponsTimeStamp != prevState.discountCouponsTimeStamp
    ) {
      nextProps.exportEmptyData();
      returnState.DiscountCoupons = nextProps.DiscountCoupons
        ? nextProps.DiscountCoupons
        : [];
      returnState.showLoader = false;
      returnState.showLoadingText = false;
      returnState.showDeleteModal = false;
      returnState.showModal = false;
      returnState.discountCouponsTimeStamp = nextProps.discountCouponsTimeStamp;
      nextProps.exportEmptyData();
      if (nextProps.redirect != undefined && nextProps.redirect == true)
        toast.success(nextProps.message);
      returnState.showDeleteModal = false;
      returnState.showModal = false;
      returnState.redirect = false;
      return returnState;
    } else if (
      nextProps.fetchDiscountCouponsIdVAl !== undefined &&
      nextProps.fetchDiscountCouponsIdVAl !=
        prevState.fetchDiscountCouponsIdVAl &&
      nextProps.showDateValFetch != prevState.showDateValFetch
    ) {
      nextProps.exportEmptyData();
      returnState.couponCode = nextProps.fetchDiscountCouponsIdVAl.data
        .coupon_code
        ? nextProps.fetchDiscountCouponsIdVAl.data.coupon_code
        : "";
      returnState.discount_type = nextProps.fetchDiscountCouponsIdVAl.data
        .discount_type
        ? nextProps.fetchDiscountCouponsIdVAl.data.discount_type
        : "";
      returnState.discount_value = nextProps.fetchDiscountCouponsIdVAl.data
        .discount_value
        ? nextProps.fetchDiscountCouponsIdVAl.data.discount_value
        : "";
      returnState.expiry_date = nextProps.fetchDiscountCouponsIdVAl.data
        .expiry_date
        ? moment(nextProps.fetchDiscountCouponsIdVAl.data.expiry_date).toDate()
        : new Date();
      returnState.limited_duration = nextProps.fetchDiscountCouponsIdVAl.data
        .limited_duration
        ? nextProps.fetchDiscountCouponsIdVAl.data.limited_duration
        : "";
      returnState.discount_duration = nextProps.fetchDiscountCouponsIdVAl.data
        .discount_duration
        ? nextProps.fetchDiscountCouponsIdVAl.data.discount_duration
        : "";
      returnState.showDateValFetch = nextProps.showDateValFetch;
      returnState.showLoader = false;
      returnState.showDeleteModal = false;

      return returnState;
    } else return null;
  }
  componentWillUnmount() {
    this.props.exportEmptyData({});
    document.removeEventListener("click", this.handleClick, false);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  inventoryEdit = (id) => {
    this.setState({
      showModal: true,
      id: id,
      couponCode_Error: false,
      discount_type_Error: false,
      discount_value_Error: false,
      expiry_date_Error: false,
      discount_duration_Error: false,
      limited_duration_Error: false,
    });
    window.onscroll = () => {
      return false;
    };
    const discountCouponsId = id;

    let formData = {
      params: {
        id: discountCouponsId,
      },
    };
    if (discountCouponsId) {
      this.setState({ showLoader: true });
      this.props.fetchDiscountCouponsId(formData);
    }
    if (!discountCouponsId) {
      this.props.exportEmptyData();
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        sortorder: "asc",
        sortby: "coupon_code",
        term: this.state.term,
      },
    };
    this.setState({
      sortorder: "asc",
      sortby: "coupon_code",
      // DiscountCoupons: []
    });
    this.setState({ showLoader: true, filterValue: "false" });
    autoScrolling(true);
    this.props.fetchDiscountCoupons(formData);
  };

  handleSubmitData = (event, value) => {
    event.preventDefault();
    const {
      id,
      couponCode,
      discount_type,
      discount_value,
      expiry_date,
      discount_duration,
      limited_duration,
    } = this.state;

    const isInvalidDiscountValue =
      !discount_value ||
      discount_value <= 0 ||
      (discount_type === "percentage" && discount_value > 100);
    const isInvalidCouponCode = !couponCode;
    const isInvalidDiscountType = !discount_type;
    const isInvalidExpiryDate = !expiry_date;
    const isInvalidDiscountDuration = !discount_duration;
    const isInvalidLimitedDuration =
      discount_duration === "limited" && !limited_duration;

    if (
      isInvalidDiscountValue ||
      isInvalidCouponCode ||
      isInvalidDiscountType ||
      isInvalidExpiryDate ||
      isInvalidDiscountDuration ||
      isInvalidLimitedDuration
    ) {
      this.setState({
        discount_value_Error: isInvalidDiscountValue,
        couponCode_Error: isInvalidCouponCode,
        discount_type_Error: isInvalidDiscountType,
        expiry_date_Error: isInvalidExpiryDate,
        discount_duration_Error: isInvalidDiscountDuration,
        limited_duration_Error: isInvalidLimitedDuration,
      });
      return;
    }

    const formData = {
      coupon_code: couponCode,
      discount_type: discount_type,
      discount_value,
      expiry_date: moment(expiry_date).format("YYYY-MM-DD"),
      id: id > 0 ? id : "",
      discount_duration,
      sortby: this.state.sortby,
      sortorder: this.state.sortorder,
    };

    if (discount_duration === "limited") {
      formData.limited_duration = limited_duration;
    }

    this.setState({ showLoader: true });

    if (id > 0) {
      this.props.updateDiscountCouponsId(formData);
    } else {
      this.props.createDiscountCouponsId(formData);
    }
  };

  handleChange = (date) => {
    this.setState({
      expiry_date: date,
    });
  };

  toggleDatePicker = () => {
    this.refDatePicker.setFocus(true);
    this.refDatePicker.setOpen(true);
  };

  blurDatePicker = (date) => {
    this.refDatePicker.setOpen(true);
    this.setState({ showDatePicker: true });
  };

  focusDatePicker = (date) => {
    this.setState({ showDatePicker: true });
  };

  blurRepeatDatePicker = (date) => {
    this.refRepeatDatePicker.setOpen(true);
  };

  focusRepeatDatePicker = (date) => {
    this.setState({ showDatePicker: true });
  };

  toggleRepeatDatePicker = () => {
    this.refRepeatDatePicker.setFocus(true);
    this.refRepeatDatePicker.setOpen(true);
  };

  handleClick = (e) => {
    if (
      this.refDatePickerContainer == null ||
      this.refDatePickerContainer == undefined
    ) {
      return;
    }
    if (!this.refDatePickerContainer.contains(e.target)) {
      this.refDatePicker.setOpen(false);
    }
  };

  showDeleteModal = () => {
    this.setState({ showDeleteModal: true });
  };

  dismissDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  dismissModal = () => {
    this.setState({ showModal: false });
  };

  deleteDiscountCouponsIdFunct = () => {
    this.dismissModal();
    let formData = {
      params: {
        id: this.state.id,
      },
    };
    this.setState({ showLoader: true, showDeleteModal: false });
    this.props.deleteDiscountCouponsId(formData);
  };

  handleAddDiscount = () => {
    this.props.exportEmptyData({});
    this.setState({
      showModal: true,
      id: 0,
      couponCode: "",
      discount_type: "",
      discount_value: "",
      expiry_date: new Date(moment().add(1, "days")),
      discount_duration: "forever",
      limited_duration: {},
      couponCode_Error: false,
      discount_type_Error: false,
      discount_value_Error: false,
      expiry_date_Error: false,
      discount_duration_Error: false,
      limited_duration_Error: false,
    });
  };

  getPlaceholder = () => {
    const lang = this.state.inventoryLang;
    switch (this.state.discount_type) {
      case "percentage":
        return lang.inventory_discount_value_in_percentage_symbol;
      case "dollar":
        return lang.invenoty_discount_value_lbl;
      default:
        return lang.invenoty_discount_value_lbl;
    }
  };

  handleCopyCouponCode(e, couponCode) {
    e.stopPropagation();
    navigator.clipboard
      .writeText(couponCode)
      .then(() => {
        uiNotification.success(
          `Successfully copied ${couponCode} to clipboard!`,
        );
      })
      .catch((error) => {
        uiNotification.error(
          `Error copying ${couponCode} to clipboard: ${error}`,
        );
      });
  }

  handleOpenDeleteModal = (e, id) => {
    e.stopPropagation();

    this.setState({ showDeleteModal: true, id: id });
  };

  render() {
    const datePicker1 = document.getElementsByClassName(
      "react-datepicker__input-container",
    )[0];
    if (datePicker1 !== undefined && datePicker1 !== null) {
      datePicker1.childNodes[0].setAttribute("readOnly", true);
    }

    return (
      <div className="setting-setion m-b-15">
        <div className="membershipSectionTitle p-t-10 p-b-10  flex item-center justify-between m-b-15">
          <div>{this.state.settingsLang.membership_discount_coupons}</div>
          <div>
            <Button
              size="small"
              onClick={() => this.handleAddDiscount()}
              className="easy-link pull-right coupon-button"
            >
              <i className="fa fa-plus  m-r-5" />
              {this.state.globalLang.label_add + " Coupon"}
            </Button>
          </div>
        </div>
        <div className="membershipSectionTitle p-t-0 p-b-0 no-border">
          <form onSubmit={this.handleSubmit}>
            <div className="search-bg new-search">
              <i className="fas fa-search" />
              <input
                className="setting-search-input search-key"
                placeholder="Search"
                name="term"
                autoComplete="off"
                value={this.state.term}
                onChange={this.handleInputChange}
              />
            </div>
          </form>
        </div>
        <div className={cx("wallet-view-block")}>
          <div className="membershipSectionContent">
            <div className="table-responsive fixed-header-table">
              <table className="table-updated juvly-table table-fixed">
                <thead
                  className={cx(
                    "table-updated-thead",
                    classes.discountTableUpdatedThead,
                  )}
                >
                  <tr>
                    <th
                      className={cx(
                        "col-xs-2 p-r-0 table-updated-th sorting",
                        classes.discountTableUpdatedTh,
                      )}
                      onClick={() => this.onSort("coupon_code")}
                      data-sort="name"
                      data-order="DESC"
                    >
                      {this.state.inventoryLang.Inventory_coupon_code}
                      <i
                        className={
                          this.state.sortby === "coupon_code"
                            ? this.state.sortorder === "asc"
                              ? "blue-gray"
                              : "gray-blue"
                            : "gray-gray"
                        }
                      />
                    </th>
                    <th
                      className={cx(
                        "col-xs-3 table-updated-th p-r-0",
                        classes.discountTableUpdatedTh,
                      )}
                    >
                      {this.state.inventoryLang.inventory_discount_type_lbl}
                    </th>
                    <th
                      className={cx(
                        "col-xs-2 table-updated-th p-r-0",
                        classes.discountTableUpdatedTh,
                      )}
                    >
                      {this.state.inventoryLang.invenoty_discount_value_lbl}
                    </th>
                    <th
                      className={cx(
                        "col-xs-3 p-r-0 table-updated-th",
                        classes.discountTableUpdatedTh,
                      )}
                    >
                      {this.state.inventoryLang.inventory_expiry_date}
                    </th>
                    <th
                      className={cx(
                        "col-xs-2 table-updated-th",
                        classes.discountTableUpdatedTh,
                      )}
                    >
                      {"Actions"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="4" className="no-record no-padding">
                      {" "}
                      {this.state.showLoader === false && (
                        <div
                          className={
                            this.state.DiscountCoupons !== undefined &&
                            this.state.DiscountCoupons.length === 0
                              ? "no-record"
                              : "no-record no-display"
                          }
                        >
                          <div
                            className=""
                            style={{
                              float: "left",
                              width: "100%",
                              fontSize: "13px",
                              textAlign: "center",
                              marginTop: "0px",
                              padding: "0px",
                            }}
                          >
                            {this.state.inventoryLang.dashboard_No_record_found}
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
                <Scrollbars style={{ height: 217 }} className="custome-scroll">
                  <tbody>
                    {this.state.DiscountCoupons !== undefined &&
                      this.state.DiscountCoupons.length > 0 &&
                      this.state.DiscountCoupons.map((obj, idx) => {
                        return (
                          <tr
                            className="table-updated-tr"
                            onClick={this.inventoryEdit.bind(this, obj.id)}
                            key={obj.id}
                          >
                            <td
                              className={cx(
                                classes.classNotWorked,
                                "table-updated-td col-xs-2 class-not-worked",
                              )}
                            >
                              {obj.coupon_code ? obj.coupon_code : ""}
                            </td>
                            <td
                              className={cx(
                                "table-updated-td col-xs-3",
                                classes.discountTableUpdatedTd,
                              )}
                            >
                              {obj.discount_type ? obj.discount_type : ""}
                            </td>
                            <td
                              className={cx(
                                "table-updated-td col-xs-2",
                                classes.discountTableUpdatedTd,
                              )}
                            >
                              {obj.discount_type == "dollar"
                                ? numberFormat(
                                    obj.discount_value,
                                    "currency",
                                    2,
                                  )
                                : Number(obj.discount_value).toFixed(2) +
                                  "%"}{" "}
                            </td>
                            <td
                              className={cx(
                                "table-updated-td col-xs-3",
                                classes.discountTableUpdatedTd,
                              )}
                            >
                              {showFormattedDate(obj.expiry_date, false)}
                            </td>
                            <td
                              className={cx(
                                "table-updated-td col-xs-2",
                                classes.discountTableUpdatedTd,
                              )}
                            >
                              <div className="flex justify-between">
                                <a
                                  title="Copy"
                                  className="p-l-0 table-action-button"
                                  onClick={(e) =>
                                    this.handleCopyCouponCode(
                                      e,
                                      obj.coupon_code,
                                    )
                                  }
                                >
                                  <i
                                    aria-hidden="true"
                                    className="fa fa-clone m-r-5"
                                  ></i>
                                </a>
                                {obj.id && (
                                  <a
                                    className="p-l-0 table-action-button"
                                    title="Delete"
                                    onClick={(e) =>
                                      this.handleOpenDeleteModal(e, obj.id)
                                    }
                                    data-message={
                                      "Are you sure you want to delete this Discount Coupon?"
                                    }
                                  >
                                    <i className="fa fa-trash-alt m-r-5"></i>
                                  </a>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Scrollbars>
              </table>
            </div>
          </div>
        </div>
        {/*Create Edit Modal*/}
        {this.state.showModal === true && (
          <div
            className={
              this.state.showModal ? "blackOverlay" : "blackOverlay no-display"
            }
          >
            <div className="vert-middle">
              <div className="white-popup large-popup">
                <div className="white-popup-wrapper p-b-0">
                  <div className="membershipTypeTitle">
                    {this.state.id
                      ? "Edit Discount Coupons"
                      : "Create Discount Coupons"}
                    <a onClick={this.dismissModal} className="popupClose">
                      <img src="/images/popupClose.png" />
                    </a>
                  </div>
                  <div className="row">
                    <div>
                      <div className="col-xs-12">
                        <div className="juvly-subtitle m-b-20">
                          {
                            this.state.inventoryLang
                              .inventory_discount_coupons_information
                          }
                        </div>
                      </div>

                      <div className="col-sm-6 col-xs-12">
                        <div className="simpleField m-b-15">
                          <div className="simpleLabel">
                            {this.state.inventoryLang.Inventory_coupon_code}{" "}
                            <span className="setting-require">*</span>
                          </div>
                          <Input
                            className={
                              this.state.couponCode_Error === true
                                ? "group-error"
                                : ""
                            }
                            type="text"
                            name="couponCode"
                            value={this.state.couponCode}
                            placeholder="Coupon Code"
                            autoComplete="off"
                            onChange={this.handleInputChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          {this.state.inventoryLang.inventory_discount_type_lbl}
                          <span className="setting-require">*</span>
                        </div>
                        <Select
                          options={discountTypes}
                          value={discountTypes.find(
                            (i) => i.value === this.state.discount_type,
                          )}
                          isError={this.state.discount_type_Error}
                          onChange={this.handleSelectChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField m-b-15">
                        <div className="simpleLabel">
                          {this.state.inventoryLang.invenoty_discount_value_lbl}
                          <span className="setting-require">*</span>
                        </div>
                        <Input
                          className={
                            this.state.discount_value_Error === true
                              ? "group-error"
                              : ""
                          }
                          type="text"
                          name="discount_value"
                          value={this.state.discount_value}
                          placeholder={this.getPlaceholder()}
                          autoComplete="off"
                          onChange={this.handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6 col-xs-12">
                      <div className="simpleField field-icon  m-b-15">
                        <div className="simpleLabel">
                          {this.state.inventoryLang.inventory_expiry_date}{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <div
                          ref={(refDatePickerContainer) =>
                            (this.refDatePickerContainer =
                              refDatePickerContainer)
                          }
                        >
                          <button
                            className="client-treat-cal simple-cal-icon calender-icon-discount easy-link m-r-5"
                            onClick={this.toggleDatePicker}
                          ></button>

                          <DatePicker
                            className={
                              this.state.expiry_date_Error
                                ? "simpleInput date-input field_error"
                                : "simpleInput date-input"
                            }
                            selected={
                              this.state.expiry_date
                                ? this.state.expiry_date
                                : null
                            }
                            dateFormat={dateFormatPicker()}
                            placeholderText={dateFormatPicker().toLowerCase()}
                            onChange={this.handleChange}
                            onBlur={this.blurDatePicker}
                            onFocus={this.focusDatePicker}
                            minDate={new Date(moment().add(1, "days"))}
                            maxDate={new Date(moment().add(10, "years"))}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            ref={(refDatePicker) =>
                              (this.refDatePicker = refDatePicker)
                            }
                            autoComplete="off"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12">
                      <div className="juvly-subtitle m-b-10">
                        {this.state.inventoryLang.inventory_duration_msg}
                      </div>

                      <div className="setting-field-outer m-b-10">
                        <div className="setting-input-outer">
                          <div className="basic-checkbox-outer">
                            <input
                              id="radiobutton3"
                              className="basic-form-checkbox"
                              name="discount_duration"
                              type="radio"
                              value="forever"
                              onChange={this.handleInputChange}
                              checked={
                                this.state.discount_duration === "forever"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="radiobutton3"
                            >
                              {this.state.inventoryLang.inventory_forever}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input
                              id="radiobutton4"
                              className="basic-form-checkbox"
                              name="discount_duration"
                              type="radio"
                              value="single"
                              onChange={this.handleInputChange}
                              checked={
                                this.state.discount_duration === "single"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="radiobutton4"
                            >
                              {this.state.inventoryLang.inventory_single_use}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input
                              id="radiobutton5"
                              className="basic-form-checkbox"
                              name="discount_duration"
                              type="radio"
                              value="limited"
                              onChange={this.handleInputChange}
                              checked={
                                this.state.discount_duration === "limited"
                                  ? "checked"
                                  : false
                              }
                            />
                            <label
                              className="basic-form-text"
                              htmlFor="radiobutton5"
                            >
                              {this.state.inventoryLang.inventory_limited_time}
                            </label>
                          </div>
                          <div
                            className={
                              this.state.discount_duration === "limited"
                                ? "limited-time-in-month discountMonthSelect"
                                : "no-display"
                            }
                          >
                            <select
                              className={
                                this.state.limited_duration_Error
                                  ? "simpleSelect field_error p-r-5"
                                  : "simpleSelect p-r-5"
                              }
                              name="limited_duration"
                              value={this.state.limited_duration}
                              onChange={this.handleInputChange}
                            >
                              <option value="">
                                {this.state.inventoryLang.inventory_select}
                              </option>
                              {inventorySelectOptions}
                            </select>
                            <span>
                              {this.state.inventoryLang.inventory_months}
                            </span>
                          </div>
                        </div>
                      </div>

                      <p className="p-text">
                        <i>{this.state.inventoryLang.inventory_coupon_msg}</i>
                      </p>
                      <p className="p-text m-b-25">
                        <i>
                          The coupon codes will be applied to the invoices on
                          the first full month draft. In the case of a pro-rated
                          payment, the coupon code will not be applied.
                        </i>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  <a
                    className="new-blue-btn pull-right"
                    onClick={this.handleSubmitData}
                  >
                    Save
                  </a>
                  <a
                    className="new-white-btn pull-right"
                    onClick={this.dismissModal}
                  >
                    Cancel
                  </a>
                  {/*Delete Modal*/}
                  {this.state.id ? (
                    <button
                      className={
                        this.state.id
                          ? "new-red-btn pull-left"
                          : "new-red-btn pull-left no-display"
                      }
                      onClick={this.showDeleteModal}
                      data-message={
                        this.state.inventoryLang.inventory_are_you_sure_del_PTI
                      }
                    >
                      {this.state.inventoryLang.inventory_delete}
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className={this.state.showDeleteModal ? "overlay" : ""}></div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.showDeleteModal
              ? "modal fade in displayBlock"
              : "modal fade"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissDeleteModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.inventoryLang.inventory_Confirmation_required}
                  {this.state.showModal}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {this.state.inventoryLang.inventory_discount_coupons_lbl}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={this.dismissDeleteModal}
                  >
                    {this.state.inventoryLang.inventory_No}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteDiscountCouponsIdFunct}
                  >
                    {this.state.inventoryLang.inventory_Yes}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {
          <div
            className={
              this.state.showLoader
                ? "new-loader text-left displayBlock popup-loader"
                : "new-loader text-left popup-loader"
            }
          >
            <div className="loader-outer popup-loader">
              <img
                id="loader-outer"
                src="/images/Eclipse.gif"
                className="loader-img"
              />
              <div id="modal-confirm-text" className="popup-subtitle">
                {this.state.globalLang.Please_Wait}
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}

function handleAction(action, data) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  if (data && data.status === 200) {
    const discountCouponsTimeStamp = new Date();
    switch (action) {
      case "DISCOUNT_COUPONS_LIST":
        return {
          DiscountCoupons: data.data,
          discountCouponsTimeStamp,
        };
      case "CREATE_DISCOUNT_COUPONS_ID":
      case "UPDATE_DISCOUNT_COUPONS_ID":
      case "DELETE_DISCOUNT_COUPONS_ID":
        return {
          message: languageData.global[data.message],
          DiscountCoupons: data.data,
          discountCouponsTimeStamp,
          redirect: true,
        };
      case "FETCH_DISCOUNT_COUPONS_ID":
        return {
          fetchDiscountCouponsIdVAl: data,
          showDateValFetch: new Date(),
        };
      default:
        break;
    }
  } else {
    toast.dismiss();
    toast.error(languageData.global[data.message]);
    return { showLoader: false };
  }
}

function mapStateToProps(state) {
  let returnState = {};

  localStorage.setItem("sortOnly", false);

  if (state.InventoryReducer.action === "EMPTY_DATA") {
    if (state.InventoryReducer.data.status === 200) {
      return { DiscountCoupons: undefined };
    }
  } else {
    returnState =
      state?.InventoryReducer?.data &&
      handleAction(
        state.InventoryReducer.action,
        state?.InventoryReducer?.data,
      );
  }

  return returnState || {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchDiscountCoupons: fetchDiscountCoupons,
      exportEmptyData: exportEmptyData,
      emptyInventoryReducer: emptyInventoryReducer,
      updateDiscountCouponsId: updateDiscountCouponsId,
      createDiscountCouponsId: createDiscountCouponsId,
      deleteDiscountCouponsId: deleteDiscountCouponsId,
      fetchDiscountCouponsId: fetchDiscountCouponsId,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(DiscountCoupons));
