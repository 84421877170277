/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getClientDetail,
  refundFees,
  payRefund,
  getClientShopifyDetail,
} from "../../Actions/Clients/clientsAction.js";
import {
  capitalizeFirstLetter,
  numberFormat,
  displayName,
  showFormattedDate,
} from "../../Utils";

class PaymentHistory extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      backURLType: this.props.match.params.actionType
        ? this.props.match.params.actionType
        : "clients",
      action: this.props.match.params.type
        ? this.props.match.params.type
        : "profile",
      showLoader: false,
      globalLang: languageData.global,
      clientID: this.props.match.params.clientID,
      clientData: [],
      dataChanged: false,
      tabType: this.props.match.params.tabType
        ? this.props.match.params.tabType
        : "invoices",
      apptTransactionID: 0,
      showModal: false,
      languageData: languageData.clients,
    };

    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });

    switch (this.props.match.params.tabType) {
      case "invoices":
        this.state.clientScopes = "posInvoice";
        break;
      case "cancellation_charges":
        this.state.clientScopes = "cancelationChargeHistory";
        break;
      case "shopify":
        this.state.clientScopes = "shopify";
        break;
      default:
        this.state.clientScopes = "posInvoice";
    }

    if (this.state.clientScopes !== "shopify") {
      this.props.getClientDetail(this.state.clientID, this.state.clientScopes);
    }
    this.props.getClientShopifyDetail(this.state.clientID);
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.clientData !== undefined &&
      props.clientData.status === 200 &&
      props.clientData.data !== state.clientData
    ) {
      return {
        clientData: props.clientData.data,
        showLoader: false,
        showModal: false,
      };
    }

    if (
      props.shopifyData !== undefined &&
      props.shopifyData.status === 200 &&
      props.shopifyData.data !== state.shopifyData
    ) {
      return {
        shopifyData: props.shopifyData.data,
        showLoader: false,
        showModal: false,
      };
    } else if (
      props.shopifyData !== undefined &&
      props.shopifyData.status !== 200 &&
      props.shopifyData.data !== state.shopifyData
    ) {
      return {
        shopifyData: props.shopifyData.data,
        showLoader: false,
        showModal: false,
      };
    }

    if (
      props.refundFeeData !== undefined &&
      props.refundFeeData.status === 200 &&
      props.refundFeeData.data !== state.refundFeeData
    ) {
      return {
        refundFeeData: props.refundFeeData.data,
        showLoader: false,
        refundFeeStatus: props.refundFeeData.data,
        apptTransactionID: 0,
      };
    } else if (
      props.refundFeeData !== undefined &&
      props.refundFeeData.status !== 200 &&
      props.refundFeeData.data !== state.refundFeeData
    ) {
      return {
        refundFeeData: props.refundFeeData.data,
        showLoader: false,
        refundFeeStatus: "",
        apptTransactionID: 0,
      };
    }

    return null;
  }

  changeTab = (tabType) => {
    let scope = "";

    if (tabType && tabType === "invoices") {
      scope = "posInvoice";
    }

    if (tabType === "cancellation_charges") {
      scope = "cancelationChargeHistory";
    }

    if (tabType === "shopify") {
      scope = "shopify";
    }

    this.props.history.push(
      `/${this.state.backURLType}/payment-history/${this.props.match.params.clientID}/${this.props.match.params.type}/${tabType}`,
    );

    this.setState({
      showLoader: true,
      tabType: tabType,
      clientScopes: scope,
    });

    if (scope === "shopify") {
      this.props.getClientShopifyDetail(this.state.clientID);
    } else {
      this.props.getClientDetail(this.state.clientID, scope);
    }
  };

  getInvoiceDetails = (invoiceID) => {
    return (
      <div>
        {this.props.history.push(
          `/clients/invoice/${invoiceID}/${this.state.clientID}/payment-history`,
        )}
      </div>
    );
  };

  issueRefund = (passedObj) => {
    if (passedObj && passedObj.appointment_transaction_id) {
      this.setState({
        apptTransactionID: passedObj.appointment_transaction_id,
        showModal: true,
        passedObj: passedObj,
      });
    }
  };

  dismissModal = () => {
    this.setState({ apptTransactionID: 0, showModal: false, passedObj: {} });
  };

  handleModalAction = () => {
    if (
      this.state.passedObj &&
      this.state.passedObj.cancellation_type === "new"
    ) {
      let formData = {
        invoice_id: this.state.passedObj.pos_invoice_id,
        transaction_id: this.state.passedObj.transaction_id,
        refund_type: "full",
        refund_reason: "custom",
        text_reason_for_refund: "Refunding Cancellation Fee",
        payment_refund_type: "default",
      };

      this.setState({ showLoader: true, showModal: false });
      this.props.payRefund(formData);
    } else {
      if (this.state.apptTransactionID && this.state.clientID) {
        let formData = {
          trans_id: this.state.apptTransactionID,
          patient_id: this.state.clientID,
        };

        this.setState({ showLoader: true, showModal: false });
        this.props.refundFees(formData);
      }
    }
  };

  componentDidUpdate = (_, prevState) => {
    if (
      this.state.refundFeeData !== null &&
      this.state.refundFeeData !== "" &&
      this.state.refundFeeData !== prevState.refundFeeData &&
      this.state.refundFeeStatus !== null &&
      this.state.refundFeeStatus !== ""
    ) {
      this.setState({
        showLoader: true,
      });

      this.state.clientScopes =
        this.props.match.params.tabType === "invoices"
          ? "posInvoice"
          : "cancelationChargeHistory";
      this.props.getClientDetail(this.state.clientID, this.state.clientScopes);
    }
  };

  render() {
    let returnTo = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      returnTo = this.props.match.params.type
        ? "/" +
          this.state.backURLType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.clientID
        : "/" + this.state.backURLType;
    }

    let patientName = this.state.clientData
      ? displayName(this.state.clientData)
      : "";

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="juvly-section full-width m-t-15">
            <div className="juvly-container">
              {this.state.showLoader === false && (
                <div className="juvly-title no-margin">
                  {patientName} -{" "}
                  {this.state.languageData.clientprofile_payment_history}
                  <Link to={returnTo} className="pull-right">
                    <img alt="" src="../../../../images/close.png" />
                  </Link>
                </div>
              )}
            </div>
            <ul className="sub-menus activity-menu">
              {this.state.clientData !== undefined &&
                this.state.tabType !== "shopify" && (
                  <div className="sales-relation">
                    <label>
                      {this.state.languageData.client_total_sales_relation}{" "}
                    </label>{" "}
                    {numberFormat(
                      this.state.clientData.total_sale_relationship,
                      "currency",
                    )}
                  </div>
                )}
              <li className={this.state.tabType === "invoices" ? "active" : ""}>
                <a onClick={() => this.changeTab("invoices")}>
                  {this.state.languageData.client_invoices}
                </a>
              </li>
              <li
                className={
                  this.state.tabType === "cancellation_charges" ? "active" : ""
                }
              >
                <a onClick={() => this.changeTab("cancellation_charges")}>
                  {this.state.languageData.client_cancelation_charges}
                </a>
              </li>
              {this.state.shopifyData ? (
                <li
                  className={this.state.tabType === "shopify" ? "active" : ""}
                >
                  <a onClick={() => this.changeTab("shopify")}>Shopify</a>
                </li>
              ) : null}
            </ul>

            <div
              className={this.state.showModal === true ? "overlay" : ""}
            ></div>
            <div
              id="filterModal"
              role="dialog"
              className={
                this.state.showModal === true
                  ? "modal fade in displayBlock"
                  : "modal fade no-display"
              }
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      onClick={this.dismissModal}
                    >
                      ×
                    </button>
                    <h4 className="modal-title">
                      {this.state.languageData.client_conf_requierd}
                    </h4>
                  </div>
                  <div
                    id="errorwindow"
                    className="modal-body add-patient-form filter-patient"
                  >
                    {this.state.languageData.client_refund_fee_message}
                  </div>
                  <div className="modal-footer">
                    <div className="col-md-12 text-left">
                      <button
                        type="button"
                        className="btn  logout pull-right"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        {this.state.languageData.client_no}
                      </button>
                      <button
                        type="button"
                        className="btn btn-success pull-right m-r-10"
                        data-dismiss="modal"
                        onClick={this.handleModalAction}
                      >
                        {this.state.languageData.client_yes}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <table
                className={
                  this.state.tabType && this.state.tabType === "invoices"
                    ? "table-updated juvly-table table-min-width"
                    : "table-updated juvly-table table-min-width no-display"
                }
              >
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.languageData.client_invoice_number}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.pro_pro_name}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.pro_pro_date}
                    </th>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.languageData.client_total_amount || "$0.00"}
                    </th>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.languageData.client_payment_mode}
                    </th>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.languageData.client_payment_status}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.client_charged_on}
                    </th>
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.state.clientData !== undefined &&
                    this.state.clientData.pos_invoice !== undefined &&
                    this.state.clientData.pos_invoice !== null &&
                    this.state.clientData.pos_invoice.length > 0 &&
                    this.state.clientData.pos_invoice.map((obj, idx) => {
                      let paymentMode = "";
                      let invoiceStatus = "";

                      if (
                        obj.pos_transaction &&
                        obj.pos_transaction !== null &&
                        obj.pos_transaction !== undefined
                      ) {
                        paymentMode = obj.tender_type;
                      }

                      if (
                        obj.invoice_status &&
                        obj.invoice_status !== undefined
                      ) {
                        if (obj.invoice_status === "refunded") {
                          invoiceStatus = "Refunded";
                        } else if (obj.invoice_status === "canceled") {
                          invoiceStatus = "Void";
                        } else {
                          invoiceStatus = capitalizeFirstLetter(
                            obj.invoice_status,
                          );
                        }
                      }
                      return (
                        <tr
                          key={idx}
                          className="table-updated-tr"
                          onClick={this.getInvoiceDetails.bind(this, obj.id)}
                        >
                          <td className="table-updated-td">
                            {obj.invoice_number}
                          </td>
                          <td className="table-updated-td">
                            {obj.merged_invoices.length > 0 &&
                              obj.merged_invoices.map((mergedInvoice) => {
                                return (
                                  mergedInvoice.procedure && (
                                    <p>
                                      {mergedInvoice.procedure.procedure_name}
                                    </p>
                                  )
                                );
                              })}
                            {obj.procedure
                              ? obj.procedure.procedure_name
                              : obj.appointment
                              ? "Deposit"
                              : ""}
                          </td>
                          <td className="table-updated-td">
                            {obj.merged_invoices.length > 0 &&
                              obj.merged_invoices.map((mergedInvoice) => {
                                return (
                                  mergedInvoice.procedure && (
                                    <p>
                                      {showFormattedDate(
                                        mergedInvoice.procedure.procedure_date,
                                        true,
                                      )}
                                    </p>
                                  )
                                );
                              })}
                            {obj.procedure
                              ? showFormattedDate(
                                  obj.procedure.procedure_date,
                                  true,
                                )
                              : obj.appointment
                              ? showFormattedDate(
                                  obj.appointment.appointment_datetime,
                                  true,
                                )
                              : ""}
                          </td>
                          <td className="table-updated-td">
                            {obj.total_amount
                              ? numberFormat(obj.total_amount, "currency")
                              : numberFormat(0.0, "currency")}
                          </td>
                          <td className="table-updated-td">{paymentMode}</td>
                          <td className="table-updated-td">{invoiceStatus}</td>
                          <td className="table-updated-td">
                            {obj.payment_datetime ? obj.payment_datetime : ""}
                          </td>
                        </tr>
                      );
                    })}
                  {this.state.showLoader === false && (
                    <tr
                      className={
                        this.state.clientData.pos_invoice === undefined ||
                        (this.state.clientData.pos_invoice.length === 0 &&
                          this.state.tabType)
                          ? "table-updated-tr"
                          : "table-updated-tr no-display"
                      }
                    >
                      <td colSpan="7" className="text-center">
                        {this.state.languageData.client_no_record_found}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <table
                className={
                  this.state.tabType &&
                  this.state.tabType === "cancellation_charges"
                    ? "table-updated juvly-table table-min-width"
                    : "table-updated juvly-table table-min-width no-display"
                }
              >
                <thead className="table-updated-thead">
                  <tr>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.client_appointment_date}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.pro_clinic}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.clientprofile_provider}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.client_services}
                    </th>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.languageData.client_status}
                    </th>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.languageData.client_charges}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.languageData.client_charged_on}
                    </th>
                    {<th className="col-xs-2 table-updated-th"></th>}
                  </tr>
                </thead>
                <tbody className="ajax_body">
                  {this.state.clientData !== undefined &&
                    this.state.clientData.cancelation_charge_history !==
                      undefined &&
                    this.state.clientData.cancelation_charge_history !== null &&
                    this.state.clientData.cancelation_charge_history.length >
                      0 &&
                    this.state.clientData.cancelation_charge_history.map(
                      (cobj, cidx) => {
                        let serviceName = [];

                        cobj.appointment_services &&
                          cobj.appointment_services.length > 0 &&
                          cobj.appointment_services.map((sobj) => {
                            sobj.service &&
                              serviceName.push(
                                capitalizeFirstLetter(sobj.service.name),
                              );
                          });

                        return (
                          <tr key={cidx} className="table-updated-tr">
                            <td className="table-updated-td">
                              {cobj.appointment_datetime &&
                              cobj.appointment_datetime !== null
                                ? showFormattedDate(cobj.appointment_datetime)
                                : ""}
                            </td>
                            <td className="table-updated-td">
                              {cobj.clinic_name && cobj.clinic_name !== null
                                ? capitalizeFirstLetter(cobj.clinic_name)
                                : ""}
                            </td>
                            <td className="table-updated-td">
                              {cobj.provider_name && cobj.provider_name !== null
                                ? capitalizeFirstLetter(cobj.provider_name)
                                : ""}
                            </td>
                            <td className="table-updated-td">
                              {serviceName.join(", ")}
                            </td>
                            <td className="table-updated-td">
                              {cobj.appointment_status &&
                              cobj.appointment_status !== null
                                ? capitalizeFirstLetter(
                                    cobj.appointment_status === "canceled"
                                      ? "Voided"
                                      : cobj.appointment_status,
                                  )
                                : ""}
                            </td>
                            <td className="table-updated-td">
                              {cobj.cancellation_fee &&
                              cobj.cancellation_fee !== null
                                ? numberFormat(
                                    cobj.cancellation_fee,
                                    "currency",
                                  )
                                : ""}
                            </td>
                            <td className="table-updated-td">
                              {cobj.charged_on && cobj.charged_on !== null
                                ? cobj.charged_on
                                : ""}
                            </td>
                            {
                              <td className="table-updated-td">
                                {cobj.appointment_status &&
                                  cobj.appointment_status === "charged" && (
                                    <a
                                      className={
                                        this.state.apptTransactionID !==
                                        cobj.appointment_transaction_id
                                          ? "line-btn"
                                          : "line-btn no-display"
                                      }
                                      onClick={() => this.issueRefund(cobj)}
                                    >
                                      {this.state.languageData.client_refund}
                                    </a>
                                  )}
                              </td>
                            }
                          </tr>
                        );
                      },
                    )}
                  {this.state.showLoader === false && (
                    <tr
                      className={
                        this.state.clientData.cancelation_charge_history ===
                          undefined ||
                        this.state.clientData.cancelation_charge_history
                          .length === 0
                          ? "table-updated-tr"
                          : "table-updated-tr no-display"
                      }
                    >
                      <td colSpan="8" className="text-center">
                        {this.state.languageData.client_no_record_found}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              {this.state.shopifyData && (
                <table
                  className={
                    this.state.tabType && this.state.tabType === "shopify"
                      ? "table-updated juvly-table table-min-width"
                      : "table-updated juvly-table table-min-width no-display"
                  }
                >
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-2 table-updated-th">Date</th>
                      <th className="col-xs-2 table-updated-th">
                        # Order/Invoice
                      </th>
                      <th className="col-xs-2 table-updated-th">Amount</th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.shopifyData.length > 0 &&
                      this.state.shopifyData.map((cobj, cidx) => {
                        return (
                          <tr key={cidx} className="table-updated-tr">
                            <td className="table-updated-td">
                              {showFormattedDate(cobj.createdAt)}
                            </td>
                            <td className="table-updated-td">{cobj.name}</td>
                            <td className="table-updated-td">
                              {cobj.totalPrice}
                            </td>
                          </tr>
                        );
                      })}
                    {this.state.showLoader === false && (
                      <tr
                        className={
                          this.state.shopifyData &&
                          this.state.shopifyData.length === 0
                            ? "table-updated-tr"
                            : "table-updated-tr no-display"
                        }
                      >
                        <td colSpan="8" className="text-center">
                          {this.state.languageData.client_no_record_found}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.globalLang.loading_please_wait_text}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.ClientsReducer.action === "GET_CLIENT_DETAIL") {
    if (state.ClientsReducer.data.status !== 200) {
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    } else {
      returnState.clientData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "GET_CLIENT_SHOPIFY_DETAIL") {
    if (state.ClientsReducer.data.status !== 200) {
      toast.dismiss();
      returnState.shopifyData = state.ClientsReducer.data;
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    } else {
      returnState.shopifyData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "REFUND_FEES") {
    if (state.ClientsReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.refundFeeData = state.ClientsReducer.data;
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.refundFeeData = state.ClientsReducer.data;
    }
  }

  if (state.ClientsReducer.action === "ISSUE_REFUND_FROM_HISTORY") {
    if (state.ClientsReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.refundFeeData = state.ClientsReducer.data;
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.ClientsReducer.data.message]);
      returnState.refundFeeData = state.ClientsReducer.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientDetail,
      getClientShopifyDetail,
      refundFees,
      payRefund,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentHistory);
