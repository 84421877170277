import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
} from "../Utils/services.js";
import { accountStatusResponseInterceptor } from "../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const salesInstance = axios.create();

salesInstance.defaults.headers.common["access-token"] = getToken();

salesInstance.interceptors.response.use(
  function (response) {
    if (
      response.data !== undefined &&
      response.data.global_settings !== undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status === 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg === "invalid_token" ||
        msg === "session_timeout" ||
        msg === "server_error" ||
        msg === "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

salesInstance.interceptors.response.use(accountStatusResponseInterceptor);

export const fetchStripeUpdateCheck = () => {
  return (dispatch) => {
    dispatch({ type: "FETCH_STRIPE_UPDATE_CHECK" });
    salesInstance
      .get(process.env.REACT_APP_API_URL + "account/stripe-update-check")
      .then((response) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_CHECK_SUCCESS",
          payload: {
            message: response.data && response.data.message,
            accountId:
              response.data.data && response.data.data.account_update.acct,
            accountNeedsUpdate:
              response.data.data && response.data.data.account_needs_update,
            tosNeedsUpdate:
              response.data.data && response.data.data.tos_needs_update,
            accounts:
              response.data.data &&
              response.data.data.tos_update &&
              response.data.data.tos_update.accounts,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_CHECK_ERROR",
          payload: error.response.data.message,
        });
      });
  };
};

export const fetchStripeUpdateUrl = (accountId) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_STRIPE_UPDATE_URL" });
    salesInstance
      .get(process.env.REACT_APP_API_URL + `account/stripe-update/${accountId}`)
      .then((response) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_URL_SUCCESS",
          payload: response.data.data.url,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_STRIPE_UPDATE_URL_ERROR",
          payload: error.response.data.message,
        });
      });
  };
};

export const fetchStripeTosUpdate = (accounts) => {
  const accountsQueryString = accounts
    .map((item) => {
      return `accounts[]=${item}`;
    })
    .join("&");

  return (dispatch) => {
    salesInstance
      .get(
        process.env.REACT_APP_API_URL +
          "account/stripe-update-tos?" +
          accountsQueryString,
      )
      .then((response) => {
        dispatch({
          type: "FETCH_STRIPE_TOS_UPDATE",
          payload: response.data.data.accounts,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_STRIPE_TOS_UPDATE",
          payload: error.response.data.message,
        });
      });
  };
};
