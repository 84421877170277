import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import validator from 'validator';
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom'
import axios from 'axios';
import Sidebar from '../../../../_legacy/Containers/Settings/sidebar.js';
import { fetchPosttreatmentInstructions, getPostTreatmentEmail,postEmailUpdate } from '../../../../Actions/Settings/settingsActions.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragAndDrop from '../Consents/DragAndDrop.js';
import { autoScrolling } from "../../../../Utils/services.js";
import { Scrollbars } from 'react-custom-scrollbars';
import Loader from '../../../Common/Loader.js'
const checkPermission = (globalPermission, specificPermission) => {
    return globalPermission.find((str) => str === specificPermission);
}

class PostTreatmentInstructions extends Component{
  constructor(nextProps){
    super(nextProps);
    window.scrollTo(0, 0)
    this.state={
      post_subject:'',
      post_body:'',
      userChanged:false,
      userData:'',
      page:1,
      pagesize:15,
      sortorder:'asc',
      term:'',
      hasMoreItems: true,
      next_page_url: '',
      instructionsList: [],
      loadMore: true,
      startFresh: true,
      showLoader: false,
      showLoadingText: false,
      post_treatment_subject:'',
      post_treatment_body:'',
      userChanged:false,
      emailList: {},
      isDisabled: true,
      buttonChange:'Edit',
      save:'new-blue-btn pull-right save',
      edit:'new-blue-btn pull-right edit',
      showLoader: false,
      subjectError: false,
      bodyError: false,

    }
    localStorage.setItem('loadFresh', false);
    localStorage.setItem('sortOnly', false);
    /*window.onscroll = () => {
      const scrollTop = parseInt(Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop))
      if (
        window.innerHeight + scrollTop
        === document.documentElement.offsetHeight
       && this.state.next_page_url != null) {
        this.loadMore();
      }
    };*/
  }

  componentDidMount(){
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.setState({
      post_treatment_instructions_button:languageData.settings['post_treatment_instructions_button'],
      titleName: languageData.settings['title_name'],
      post_treatment_instructions_title:languageData.settings['post_treatment_instructions_title'],
      post_treatment_instructions_added:languageData.settings['post_treatment_instructions_added'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],
      loading_please_wait_text: languageData.global['loading_please_wait_text'],
      clinic_No_Record_Found: languageData.settings['clinic_No_Record_Found'],
      post_treatment_email:languageData.settings['post_treatment_email'],
      post_treatment_email_Subject:languageData.settings['post_treatment_email_Subject'],
      post_treatment_email_body:languageData.settings['post_treatment_email_body'],
      post_treatment_header:languageData.settings['post_treatment_header'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],

    })
    let formData = {'params':{
       page:this.state.page,
       pagesize:this.state.pagesize,
       sortorder: 'asc',
       term:this.state.term
      }
    }
    this.setState({'showLoader': true})
    autoScrolling(true)
    this.props.fetchPosttreatmentInstructions(formData);
  }

  componentDidUpdate(){
    if(this.state.showLoader) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }

  handleInputChange = (event) => {
      const target = event.target;
      const value = target.type === 'checkbox' ? target.checked : target.value;
      this.setState({
          [event.target.name]: value
      });
  }

  handleScrollFrame = (values) => {
    if((values.scrollHeight - (values.scrollTop + values.clientHeight) <= 5) && this.state.next_page_url != null) {
      this.loadMore();
    }
  }

  handleSubmit1 = (event) => {
    event.preventDefault();
    localStorage.setItem('sortOnly', true);
    let formData = {'params':{
       page:1,
       pagesize:this.state.pagesize,
       sortorder: this.state.sortorder,
       term:this.state.term
      }
    }
    this.setState({
      page:1,
      pagesize:this.state.pagesize,
      sortorder: (this.state.sortorder == 'asc') ? 'asc': 'desc',
      startFresh: true,
      loadMore: true,
      showLoader: true,
      next_page_url:'',
      instructionsList : []
    });
    autoScrolling(true)
    this.props.fetchPosttreatmentInstructions(formData);
  }

  loadMore = () => {
    if(!autoScrolling()){
      localStorage.setItem('sortOnly', false);
      this.setState({'loadMore': true, startFresh: true, showLoadingText: true})
      let formData = {'params':{
          page:this.state.page,
          pagesize:this.state.pagesize,
          sortorder: this.state.sortorder && this.state.sortorder === 'asc' ? 'asc' : this.state.sortorder == 'desc' ? 'desc' : '',
          term:this.state.term
        }
      }
      autoScrolling(true)
      this.props.fetchPosttreatmentInstructions(formData);
    }
  }

  postEdit = id => {

    return <div>{this.props.history.push(`/settings/post-treatment-instructions/${id}/edit`)}</div>;
  };

    static getDerivedStateFromProps(nextProps, prevState) {
      if(nextProps.showLoader != undefined && nextProps.showLoader == false) {
          return {showLoader : false};
       }
        if (nextProps.instructionsList != undefined && nextProps.instructionsList.post_instructions != undefined && nextProps.instructionsList.post_instructions.next_page_url !== prevState.next_page_url && nextProps.instructionsListTime != prevState.instructionsListTime) {
            let returnState = {};
            returnState.instructionsListTime = nextProps.instructionsListTime;
            returnState.showLoader = false;
            returnState.showLoadingText = false;
            if(nextProps.instructionsList.account_prefrence) {
              returnState.emailList = nextProps.instructionsList.account_prefrence;
              returnState.post_treatment_subject = (prevState.userChanged) ? prevState.post_treatment_subject : nextProps.instructionsList.account_prefrence.post_treatment_subject;
              returnState.post_treatment_body = (prevState.userChanged) ? prevState.post_treatment_body : nextProps.instructionsList.account_prefrence.post_treatment_body;
            }
            /*if(prevState.next_page_url == null) {
              localStorage.setItem('sortOnly', false);
              autoScrolling(false)
              return returnState.next_page_url = null;
            }*/

            if(prevState.instructionsList.length == 0) {
              if(localStorage.getItem('sortOnly') == 'false') {
                returnState.instructionsList = nextProps.instructionsList.post_instructions.data;
                if(nextProps.instructionsList.post_instructions.next_page_url != null) {
                  returnState.page = prevState.page + 1;
                  returnState.next_page_url = nextProps.instructionsList.post_instructions.next_page_url;
                } else {
                  returnState.next_page_url = null;
                }
                returnState.startFresh = false;


              } else {
                localStorage.setItem('sortOnly', false);
              }

            } else if(prevState.instructionsList != nextProps.instructionsList.post_instructions.data && prevState.instructionsList.length != 0) {
              returnState.instructionsList = [...prevState.instructionsList,...nextProps.instructionsList.post_instructions.data];
              returnState.page = prevState.page + 1;
              returnState.next_page_url = nextProps.instructionsList.post_instructions.next_page_url;
            }
            autoScrolling(false)
            return returnState;
        }

        if (nextProps.emailList !== undefined && nextProps.emailList != prevState.emailList && nextProps.emailListTime != prevState.emailListTime) {
          return {
              emailList:nextProps.emailList,
              emailListTime:nextProps.emailListTime,
              post_treatment_subject: (prevState.userChanged) ? prevState.post_treatment_subject : nextProps.emailList.data.post_treatment_subject,
              post_treatment_body: (prevState.userChanged) ? prevState.post_treatment_body : nextProps.emailList.data.post_treatment_body,
              showLoader: false
          };
      }
        return null;
    }



/*  componentWillUnmount = () => {
    window.onscroll = () => {
      return false;
    }
  }*/

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
      userSelect: 'none',

      // change background colour if dragging
      background: isDragging ? '#f7fbfd' : 'ffffff',

      // styles we need to apply on draggables
      ...draggableStyle,
  });
   handleEdit =()=>{
    this.setState({isDisabled:false,
      buttonChange:'Save'
    })
  }
  handleSubmit =(event) => {
    this.setState({
      save:'new-blue-btn pull-right save display',
      edit:'new-blue-btn pull-right edit display',
      subjectError: false,
      bodyError: false,
      isDisabled: true,})

    event.preventDefault();
    //====Frontend validation=================
    let error = false;

    if (this.state.post_treatment_subject === '' || this.state.post_treatment_subject.trim() == "") {
      this.setState({
        isDisabled: false,
        subjectError: true
      })
      error = true;
    }else if (this.state.pre_treatment_subject) {
      this.setState({
        isDisabled: true,
        subjectError: false
      })
    }
    if (this.state.post_treatment_body === '' || this.state.post_treatment_body.trim() == "") {
      this.setState({
        isDisabled: false,
        bodyError: true
      })
      error = true;
    } else if (this.state.pre_treatment_body) {
      this.setState({
        isDisabled: true,
        bodyError: false
      })
    }
    if (error === true) {
        return;
    }
    let formData = {
        post_treatment_subject: this.state.post_treatment_subject,
        post_treatment_body: this.state.post_treatment_body,
    }
    this.setState({showLoader: true})
    this.props.postEmailUpdate(formData);
  }
  handleCancel = () => {
    this.setState({
      pre_treatment_subject: this.state.emailList.pre_treatment_subject,
      pre_treatment_body: this.state.emailList.pre_treatment_body,
      isDisabled:true
    });
  }
  render(){
    const userPermissions = JSON.parse(localStorage.getItem('userData')).permissions;
    let sideBarPermissions = [...userPermissions];

    return(
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />
          <div className="business-setion memberWalletOuter">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  Treatment Instructions
                </div>
              </div>
              <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    {checkPermission(sideBarPermissions, 'manage-pre-treatment-instructions') &&
                      <Link to="/settings/pre-treatment-instructions" className="newTabs disabled-tab">Pre Treatment</Link>
                    }
                    <Link to="/settings/post-treatment-instructions" className="newTabs ">Post Treatment</Link>
                      <form  onSubmit={this.handleSubmit1} className="searchForm searchFormIpad">
                        <span className="searchOuter searchFormIpad"><i className="fa fa-search search-icon" />
                          <input className="setting-search-input search-key" data-url="/settings/pre_treatment_instructions" name="term" placeholder="Search" value={this.state.term} autoComplete="off" onChange={this.handleInputChange} />
                        </span>
                      </form>
                    <div className="memberRightActions">
                      <Link to="/settings/post-treatment-instructions/create" className="new-blue-btn pull-right edit_setting">{this.state.post_treatment_instructions_button}</Link>
                    </div>
                  </div>
                </div>
            <div className="row business-row">
              <div className="col-lg-6 col-xs-12 new-stats-outer fixed-header-table">
              <div className="setting-setion m-b-15">
                <table className="table-updated setting-table no-td-border no-hover table-fixed">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="col-xs-8 table-updated-th sorting">{this.state.post_treatment_instructions_title}</th>
                      <th className="col-xs-4 table-updated-th sorting text-center">Actions</th>
                    </tr>
                  </thead>
                  <Scrollbars style={{ minHeight:"632px"}}  className="custome-scroll" renderTrackHorizontal={nextProps => <div {...nextProps} style={{display: 'none'}} />} onScrollFrame={this.handleScrollFrame}>
                    <DragAndDrop sortUpdate = {this.updateSortOrder} list = {this.state.instructionsList} module = {'PostTreatmentInstruction'}  editUrl = {this.postEdit}   />

                  {
                    this.state.instructionsList.length <= 0 && (this.state.showLoader === false) &&
                    <tbody>
                      <tr>
                        <td className="col-xs-12 table-updated-th text-center" colSpan={2}>
                          <div className={"no-record"}>
                            {this.state.clinic_No_Record_Found}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  } </Scrollbars>
              </table>

              </div>

              <div className={(this.state.showLoadingText) ? "loading-please-wait no-margin-top" : "loading-please-wait no-margin-top no-display "}>{this.state.loading_please_wait_text}</div>
            </div>
              <div className="col-lg-6 col-xs-12 new-stats-outer">
              <div className="setting-setion m-b-15">

                <div className="mainSectionTitle m-b-20">
                  Post Instructions Email
                  {
                    this.state.isDisabled == true ?
                    <a className="easy-link pull-right no-padding" onClick={this.handleEdit}><i className="fa fa-pencil-alt"></i> Edit</a>
                  :
                  <>
                    <a id="edit_post" className="easy-link pull-right no-padding p-l-20" onClick={this.handleSubmit}><i className="fa fa-save"></i> Save</a>
                    <a id="post_cancel" data-url="" className="easy-link pull-right no-padding" onClick={this.handleCancel}><i className="fa fa-times"></i> Cancel</a>
                  </>
                  }
                </div>

                <div className="col-md-12 clear-both">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.post_treatment_email_Subject}<span className="setting-require">*</span></div>
                          <input name="post_treatment_subject" id="post_treatment_subject" className={this.state.subjectError === true ? "newInputField newInputField-invalid" :"newInputField"} autoComplete="off" placeholder="Subject" value={this.state.post_treatment_subject} disabled={this.state.isDisabled} onChange={this.handleInputChange} type="text" />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.post_treatment_email_body}<span className="setting-require">*</span></div>
                          <textarea name="post_treatment_body" id="post_treatment_body" className={this.state.bodyError === true ? "newtextareaField m-h-300 newInputField-invalid" :"newtextareaField m-h-300"} placeholder="Email body" disabled={this.state.isDisabled} onChange={this.handleInputChange} value={this.state.post_treatment_body} />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="newInstruction">
                   <div className="instruction-subtitle no-margin bold">Variables</div>
                  <div className="setting-variable">
                    <div className="row">
                      <div className="col-xs-12"> {this.state.titleName} Name: {'{'}{'{'}PATIENTNAME{'}'}{'}'}</div>
                      <div className="col-xs-12">Provider Name: {'{'}{'{'}PROVIDERNAME{'}'}{'}'}</div>
                      <div className="col-xs-12">Appointment Date Time: {'{'}{'{'}APPOINTMENTDATETIME{'}'}{'}'}</div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">Clinic Name:{'{'}{'{'}CLINICNAME{'}'}{'}'}</div>
                      <div className="col-xs-12">Clinic Location:{'{'}{'{'}CLINICLOCATION{'}'}{'}'}</div>
                      <div className="col-xs-12">Instructions Service:{'{'}{'{'}INSTRUCTIONSSERVICE{'}'}{'}'}</div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12">Instructions Title:{'{'}{'{'}INSTRUCTIONSTITLE{'}'}{'}'}</div>
                      <div className="col-xs-12">Instructions Description:{'{'}{'{'}INSTRUCTIONSDESCRIPTION{'}'}{'}'}</div>
                    </div>
                  </div>
                  </div>


              </div>

              </div>
            </div>

            </div>
            <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const returnState = {};
    localStorage.setItem('sortOnly', false);
    if (state.SettingReducer.action === "POST_TREATMENT_INSTRUCTIONS_LIST") {
      if(state.SettingReducer.data.status != 200) {
        toast.error(languageData.global[state.SettingReducer.data.message]);
        returnState.showLoader = false
      } else {
        returnState.instructionsList = state.SettingReducer.data.data;
        returnState.instructionsListTime = new Date();
      }
    }
    if (state.SettingReducer.action === "POST_TREATMENT_EMAIL_UPDATE") {
      if(state.SettingReducer.data.status != 200){
        toast.error(languageData.global[state.SettingReducer.data.message]);
        returnState.showLoader = false
      }
      else {
        toast.success(languageData.global[state.SettingReducer.data.message]);
        returnState.emailList = state.SettingReducer.data;
        returnState.emailListTime = new Date();
      }
    }
    return returnState;
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
      fetchPosttreatmentInstructions:fetchPosttreatmentInstructions,
      postEmailUpdate:postEmailUpdate
    }, dispatch)

}

export default connect(mapStateToProps, mapDispatchToProps)(PostTreatmentInstructions);
