export const getSelectedWidgetNames = (allWidgets = [], userWidgetIds = []) => {
  const allWidgetsNames = allWidgets.map((w) => w.name);
  return allWidgets
    .filter((w) => userWidgetIds.includes(w.id))
    .filter((w) => allWidgetsNames.includes(w.name))
    .map((w) => w.name);
};

export const getWidgetCalendarTodayDate = () => {
  let date = new Date();
  let month = date.getMonth() + 1;

  if (month < 10) {
    month = "0" + month;
  }

  return (
    date.getFullYear() +
    "-" +
    month +
    "-" +
    (date.getDate() < 10 ? "0" + date.getDate() : date.getDate())
  );
};

/**
 * @template {unknown} T
 * @param {{
 *  activePage: number;
 *  itemsPerPage: number;
 *  data: T[];
 * }} param0
 * @returns {T[]}
 */
export const slicePaginationData = ({
  activePage,
  itemsPerPage,
  data = [],
}) => {
  const sliceStart = (activePage - 1) * itemsPerPage;
  return data.slice(sliceStart, sliceStart + itemsPerPage) || [];
};
