import React from "react";
import * as yup from "yup";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { ModalCharge } from "../../shared/ModalCharge/ModalCharge";
import { InputLabel } from "../../../../../shared/InputLabel/InputLabel";
import { InputCurrency } from "../../../../../shared/InputCurrency/InputCurrency";
import { useChargeForm } from "../../hooks/useChargeForm";
import { InputError } from "../../../../../shared/InputError/InputError";
import { dispatch } from "../../../../../store/store";
import { checkoutInvoice } from "../../../../../store/checkoutInvoice";
import { img } from "../../../../../assets/img";
import { Textarea } from "../../../../../shared/Textarea/Textarea";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { composeAmountSchema } from "../../SalesCheckoutInvoice.schemas";

export function ViewChargeCareCredit() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, amountToPay } = useInvoice();
  const { chargeCareCredit } = useInvoiceActions();

  const onClose = () => {
    dispatch(checkoutInvoice.actions.paymentViewClose());
  };

  const schema = yup.object({
    amount: composeAmountSchema({ amountToPay, currency: invoice?.currency }),
    note: yup.string(),
  });

  const { form, changeFormValue, hasError, errors, isValid, submit } =
    useChargeForm({
      schema,
      onSubmit: ({ amount, note }) => {
        chargeCareCredit
          .initiate({
            amount,
            note,
          })
          .then(onClose);
      },
      initialValues: {
        amount: amountToPay || "",
        note: "",
      },
    });

  return (
    <ModalCharge
      isOpen
      onClose={onClose}
      title={tSales("checkoutInvoice.paymentOptions.careCredit")}
      imageSrc={img.careCredit}
      onCharge={submit}
      isCharging={chargeCareCredit.isLoading}
      chargeLabel={tCommon("label.apply")}
      isChargeDisabled={!isValid}
    >
      <ModalCharge.InputWrap>
        <InputLabel>
          {tSales("checkoutInvoice.formInputLabel.amount")}
        </InputLabel>
        <InputCurrency
          size="medium"
          prefix={invoice?.currencySymbol}
          value={form.amount}
          isError={hasError("amount")}
          onChange={(value) => changeFormValue("amount", value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.amount")}
        />
        {hasError("amount") && <InputError>{errors.amount}</InputError>}
      </ModalCharge.InputWrap>
      <div>
        <InputLabel>{tSales("checkoutInvoice.formInputLabel.note")}</InputLabel>
        <Textarea
          value={form.note}
          onChange={(e) => changeFormValue("note", e.target.value)}
          placeholder={tSales("checkoutInvoice.formInputPlaceholder.note")}
        />
      </div>
    </ModalCharge>
  );
}
