import React from "react";
import PropTypes from "prop-types";
import "./CalendarEvent.styles.scss";

function CalendarEvent({ event: { confirmed_status } }) {
  return (
    <div className="appointmentConfirmIconWrapper">
      {confirmed_status === "Confirmed" && (
        <span className="confirmedIcon">
          <i className="fa fa-check-circle" />
        </span>
      )}
    </div>
  );
}

CalendarEvent.propTypes = {
  confirmed_status: PropTypes.string,
};

CalendarEvent.defaultProps = {
  confirmed_status: "Not Confirmed",
};

export default CalendarEvent;
