import { useClinicsQuery } from "../../../../../api/queries/useClinicsQuery";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";

export function useClinics({ clinicId }) {
  const { tCommon } = useAppTranslation.Common();

  const { data, isLoading } = useClinicsQuery({
    onError: () => {
      uiNotification.error(tCommon("error.fetchClinics"));
    },
  });

  const list =
    data?.map((c) => ({
      label: c.name,
      value: c.id,
    })) || [];

  const currentClinic = list.find((c) => c.value === Number(clinicId));

  return {
    data: list,
    isLoading,
    currentClinic,
  };
}
