/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { updateReportOrder } from "../../Actions/BusinessInsights/businessInsightActions.js";

class Dnd extends React.Component {
  constructor(props) {
    super(props);
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    background: isDragging ? "#f7fbfd" : "ffffff",
    ...draggableStyle,
  });

  reOrderList = (list) => {
    let formData = {
      reports_order: list,
    };

    this.props.updateReportOrder(this.props.module, formData);
  };

  getReportDetails = (obj) => {
    return (
      <div>
        {this.props.history.push(
          `/business-insight/${this.props.module}/view/${obj.id}/${obj.business_insight_id}`,
        )}
      </div>
    );
  };

  render() {
    var list = [];
    if (this.props.savedReports && this.props.savedReports.length > 0) {
      list = this.props.savedReports.map((obj, idx) => {
        return {
          content: (
            <React.Fragment key={"fragment_" + idx}>
              <td
                onClick={() => this.getReportDetails(obj)}
                className="col-xs-3 table-updated-td Questionnaire-name"
              >
                <a className="drag-dots" />
                {obj.name}
              </td>
              <td
                onClick={() => this.getReportDetails(obj)}
                className="col-xs-6 table-updated-td"
              >
                {obj.grammar}
              </td>
              <td className="col-xs-3 table-updated-td text-right no-padding">
                <a
                  onClick={() => this.props.editThisReport(obj)}
                  className="easy-link no-padding m-r-20"
                >
                  <i className="fa fa-pencil-alt m-r-5" />
                  {this.props.globalLang.label_edit}
                </a>
                <a
                  onClick={() => this.props.showDeleteModal(obj)}
                  className="easy-link no-padding m-r-15"
                >
                  <i className="fa fa-trash-alt m-r-5" />
                  {this.props.globalLang.label_delete}
                </a>
              </td>
            </React.Fragment>
          ),
          id: obj.id,
        };
      });
    }

    var onDragEnd = (result) => {
      let finalArr = [];
      if (!result.destination) {
        return;
      }

      const items = this.reorder(
        list,
        result.source.index,
        result.destination.index,
      );

      list = items;
      finalArr = items.map((obj) => {
        return obj.id;
      });

      this.reOrderList(finalArr);
    };

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <tbody className="ajax_body" ref={provided.innerRef}>
              {list.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => (
                    <tr
                      key={index}
                      className="table-updated-tr"
                      data-order_by={item.display_order}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={this.getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      {item.content}
                    </tr>
                  )}
                </Draggable>
              ))}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};

  if (state.BusinessInsightReducer.action === "UPDATE_REPORT_ORDER") {
    if (state.BusinessInsightReducer.data.status !== 200) {
      toast.dismiss();
      toast.error(
        languageData.global[state.BusinessInsightReducer.data.message],
      );
    } else {
      toast.dismiss();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ updateReportOrder: updateReportOrder }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dnd));
