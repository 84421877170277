import axios from "axios";
import {
  getToken,
  setConfigData,
  handleInvalidToken,
  positionFooterCorrectly,
  setToken,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const membershipWalletInstance = axios.create();

membershipWalletInstance.defaults.headers.common["access-token"] = getToken();

let source = axios.CancelToken.source();

positionFooterCorrectly();

membershipWalletInstance.interceptors.response.use(
  function (response) {
    if (response.headers.access_token) {
      if (getToken() !== response.headers.access_token) {
        setToken(response.headers.access_token);
      }
    }

    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

membershipWalletInstance.interceptors.response.use(
  accountStatusResponseInterceptor,
);

export function emptyMembershipWallet() {
  return (dispatch) => {
    dispatch({ type: "MEMBRTSHIP_WALLET_EMPTY_DATA" });
  };
}

export function getMembershipWallet() {
  return (dispatch) => {
    membershipWalletInstance
      .get(process.env.REACT_APP_API_URL + "account/wallet-membership-setting")
      .then((response) => {
        dispatch({
          type: "GET_MEMBERSHIP_WALLET_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_WALLET_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function updateWallet(formData) {
  return (dispatch) => {
    membershipWalletInstance
      .put(process.env.REACT_APP_API_URL + "settings-wallet", formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_SETTING_WALLET_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SETTING_WALLET_DATA",
          payload: error.response.data,
        });
      });
  };
}
export function updateMembership(formData) {
  return async (dispatch) => {
    return membershipWalletInstance
      .put(process.env.REACT_APP_API_URL + "settings-membership", formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_SETTING_MEMBERSHIP_DATA",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SETTING_MEMBERSHIP_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data || {});
      });
  };
}

const serialize = function (obj) {
  var str = [];

  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

export const searchProductByName = (formData) => {
  return (dispatch) => {
    if (typeof source != typeof undefined) {
      source.cancel("Operation canceled due to new request.");
    }
    source = axios.CancelToken.source();
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "discount-packages/search-product?" +
          serialize(formData.params),
        { cancelToken: source.token },
      )
      .then((response) => {
        dispatch({
          type: "MEMBERSHIP_PRODUCT_SEARCH_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch({
            type: "MEMBERSHIP_PRODUCT_SEARCH_LIST",
            payload: error.response.data,
          });
        }
      });
  };
};

export function getMembershipMultiTier(membershipTierId) {
  return (dispatch) => {
    membershipWalletInstance
      .get(
        process.env.REACT_APP_API_URL +
          "save-multitier-program/" +
          membershipTierId,
      )
      .then((response) => {
        dispatch({ type: "GET_MEMBERSHIP_MULTI_TIER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_MULTI_TIER",
          payload: error.response.data,
        });
      });
  };
}

export function saveMembershipMultiTier(membershipTierId, formData) {
  return async (dispatch) => {
    await membershipWalletInstance
      .post(
        process.env.REACT_APP_API_URL +
          "save-multitier-program/" +
          membershipTierId,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "SAVE_MEMBERSHIP_MULTI_TIER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_MEMBERSHIP_MULTI_TIER",
          payload: error.response.data,
        });
      });
  };
}

export function changeMembershipMultiTierStatus(membershipTierId, formData) {
  return (dispatch) => {
    membershipWalletInstance
      .put(
        process.env.REACT_APP_API_URL +
          "save-multitier-program/" +
          membershipTierId,
        formData,
      )
      .then((response) => {
        dispatch({
          type: "CHANGE_MEMBERSHIP_MULTI_TIER_STATUS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_MEMBERSHIP_MULTI_TIER_STATUS",
          payload: error.response.data,
        });
      });
  };
}

export function deleteMembershipMultiTier(membershipTierId) {
  return (dispatch) => {
    membershipWalletInstance
      .delete(
        process.env.REACT_APP_API_URL +
          "save-multitier-program/" +
          membershipTierId,
      )
      .then((response) => {
        dispatch({
          type: "DELETE_MEMBERSHIP_MULTI_TIER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_MEMBERSHIP_MULTI_TIER",
          payload: error.response.data,
        });
      });
  };
}

export function getMembershipAgreement() {
  return (dispatch) => {
    membershipWalletInstance
      .get(process.env.REACT_APP_API_URL + "membership-get-all")
      .then((response) => {
        dispatch({
          type: "MEMEBERSHIP_AGREEMENT_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "MEMEBERSHIP_AGREEMENT_DATA",
          payload: error.response.data,
        });
      });
  };
}
export function getMembershipLogs(page = 1) {
  return (dispatch) => {
    membershipWalletInstance
      .get(process.env.REACT_APP_API_URL + "membership-logs", {
        params: { page },
      })
      .then((response) => {
        dispatch({ type: "MEMEBERSHIP_LOGS_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MEMEBERSHIP_LOGS_DATA",
          payload: error.response.data,
        });
      });
  };
}
