import { useState } from "react";
import { switchWorkspaces } from "../Actions/Settings/settingsActions";
import { useAppTranslation } from "../i18n/useAppTranslation";
import { uiNotification } from "../services/UINotificationService";
import { dispatch } from "../store/store";
import { unwrapOr } from "../utilities/general";
import { clinicTimezone, getLanguageData } from "../utilities/localStorage";
import { getRedirectTo, getToken, setRedirectTo } from "../Utils/services";
import { ROUTES } from "../consts/routes";

const fn = () => {};

const STATUS = {
  ok: 200,
  upgradeToStripe: 604,
  upgradeSubscription: 603,
  acceptAgreement: 602,
};

const REDIRECT_URL = {
  upgradeToStripe: "upgrade-account-to-stripe",
  upgradeSubscription: "upgrade-subscription-plan",
};

export function useSwitchWorkspace({
  onStart = fn,
  onSuccess = fn,
  onError = fn,
  onSettled = fn,
} = {}) {
  const [isWorkspaceSwitching, setIsWorkspaceSwitching] = useState(false);
  const { tCommon } = useAppTranslation.Common();

  const finishSwitch = (switchData) => {
    const userName = unwrapOr(
      () => switchData.userData.current_wc_full_name,
      "",
    );

    if (switchData.status === STATUS.upgradeToStripe) {
      window.location.href = ROUTES.upgradeAccountToStripe();
    } else if (switchData.status === STATUS.upgradeSubscription) {
      window.location.href = ROUTES.upgradeSubscriptionPlan();
    } else if (switchData.status === STATUS.acceptAgreement) {
      window.location.href = ROUTES.acceptAgreement();
    } else if (switchData.status === STATUS.ok) {
      localStorage.setItem(
        "languageData",
        JSON.stringify(switchData.userData.languageData),
      );
      localStorage.setItem(
        "globalPrivileges",
        JSON.stringify(switchData.userData.globalPrivileges),
      );
      localStorage.setItem(
        "need_clinic_city_state",
        switchData.userData.need_clinic_city_state,
      );
      if (switchData.userData.need_clinic_city_state === true) {
        localStorage.setItem(
          "clinic_list",
          JSON.stringify(switchData.userData.clinic_list),
        );
      }

      clinicTimezone.set(
        switchData?.userData?.user?.user_default_clinic_timezone,
      );
      localStorage.setItem("cCode", switchData.userData.country_code);
      localStorage.setItem("current_wc_full_name", userName);
      localStorage.setItem("isClockPromptLoginDisplay", 1);
      localStorage.removeItem("isLoggedClockCancelled");
      localStorage.removeItem("clockPromptCancelTime");

      window.location.href = switchData.redirect_url;
    }
  };

  const switchWorkspace = async ({ workspaceId }) => {
    try {
      onStart();
      setIsWorkspaceSwitching(true);
      const res = await dispatch(
        switchWorkspaces({ workspace_id: workspaceId }),
      );
      let switchData = {};
      const userData = res.data;

      localStorage.removeItem("recentlyViewedData");
      localStorage.removeItem("calendarFilter");
      localStorage.removeItem("providerWeekFilter");
      localStorage.removeItem("focusedRange");
      localStorage.removeItem("dateFormat");
      localStorage.removeItem("twilioSetup");
      localStorage.removeItem("currencySymbol");
      localStorage.removeItem("accountType");
      localStorage.removeItem("twilioFromNumber");
      localStorage.removeItem("accountStatus");
      localStorage.removeItem("subscriptionType");
      localStorage.removeItem("cCode");
      localStorage.removeItem("currencyLabel");
      localStorage.removeItem("stripeConnection");
      localStorage.removeItem("timeFormat");
      localStorage.removeItem("time_clock_setting");
      localStorage.removeItem("isMembershipEnabled");
      localStorage.removeItem("cTerm");
      localStorage.removeItem("current_wc_full_name");

      switchData.status = STATUS.ok;
      switchData.login_attempts = 1;

      localStorage.removeItem("showRecaptcha");
      localStorage.removeItem("login_attempts");

      switchData.userData = userData;

      if (userData.redirect_to) {
        setRedirectTo(userData.redirect_to);
        switchData.redirect_to = userData.redirect_to;
        switchData.redirect_url = getRedirectTo();
      }
      if (
        userData.redirect_to &&
        userData.redirect_to !== REDIRECT_URL.upgradeToStripe &&
        userData.redirect_to !== REDIRECT_URL.upgradeSubscription &&
        userData.is_bba_signed === 1
      ) {
        switchData.status = STATUS.ok;
        localStorage.setItem("currentUserRole", userData.user.user_role_id);
        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("isLoggedIn", 1);
        localStorage.setItem(
          "user_listing_settings",
          JSON.stringify(userData.user_listing_settings),
        );
      } else {
        if (userData.redirect_to === REDIRECT_URL.upgradeToStripe) {
          switchData.status = STATUS.upgradeToStripe;
        } else if (userData.redirect_to === REDIRECT_URL.upgradeSubscription) {
          switchData.status = STATUS.upgradeSubscription;
        } else {
          setRedirectTo("accept-agreement");
          switchData.status = STATUS.acceptAgreement;
        }
        const tempLoggedUserData = {
          userData: userData,
          currentUserRole: userData.user.user_role_id,
          isLoggedIn: 1,
          user_listing_settings: userData.user_listing_settings,
          access_token: getToken(),
        };
        localStorage.setItem(
          "tempLoggedUserData",
          JSON.stringify(tempLoggedUserData),
        );
      }
      finishSwitch(switchData);
      onSuccess();
    } catch (res) {
      const languageData = getLanguageData();
      uiNotification.error(
        unwrapOr(
          () => languageData.global[res.message],
          tCommon([`apiError.${res.message}`, "error.workspaceSwitch"]),
        ),
      );
      onError();
    } finally {
      setIsWorkspaceSwitching(false);
      onSettled();
    }
  };

  return {
    switchWorkspace,
    isWorkspaceSwitching,
  };
}
