import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {toast} from "react-toastify";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {exportEmptyData, getClientProfiles, markUsersAsNotDuplicate} from '../../../Actions/Clients/clientsAction';
import Loader from '../../Common/Loader.js';

class ScanComponent extends Component {
  constructor(props) {
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    super(props);
    this.state = {
      totalProfiles: 0,
      scan: 0,
      page: 1,
      next_page_url: '',
      pagesize: 15,
      showLoader: false,
      showDuplicatePopup: false,
      globalLang: languageData.global,
      settingsLang: languageData.settings,
      clientLang: languageData.clients,
      userList: []
    }
  }
  
  componentDidMount() {
    localStorage.removeItem('default_profile_id');
    localStorage.removeItem('profiles_id');
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.clientData !== undefined && nextProps.clientTimeStamp !== prevState.clientTimeStamp) {
      returnState.showLoader = false;
      returnState.showDuplicatePopup = false;
      returnState.clientTimeStamp = nextProps.clientTimeStamp;
      returnState.userList = nextProps.clientData;
      returnState.totalProfiles = nextProps.clientData.length;
    }
    return returnState;
  }
  
  fetchList = () => {
    this.setState({ showLoader: true, scan: 1 });
    this.props.getClientProfiles();
  }
  
  markAsNotDuplicatePopup(id) {
    this.setState({showDuplicatePopup: true, sendIds: id.ids})
  }
  
  markAsNotDuplicate = () => {
    this.setState({showLoader: true});
    let formdata = {
      patients_ids: this.state.sendIds
    }
    this.props.markUsersAsNotDuplicate(formdata);
  }
  dismissDuplicateModal = () => {
    this.setState({showDuplicatePopup: false})
  }
  getMergeProfileData = (data) => {
    this.setState({showLoader: true});
    localStorage.setItem('default_profile_id', data.id);
    localStorage.setItem('profiles_id', JSON.stringify(data.ids));
    this.props.history.push('/clients/merge-profiles');
  }
  
  render() {
    return (
      <div className="main protected users-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <div className="memberWalletOuter client-section m-b-10">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  {this.state.scan === 0 && (
                    <span className="cursor-pointer">{this.state.settingsLang.sidebar_patients_menu}
                      &nbsp;<span><FontAwesomeIcon className='fontSize18' icon={faAngleRight} /></span>&nbsp;
                      <span>Merge {this.state.settingsLang.sidebar_patients_menu}</span>
                    </span>
                  )}
                  {this.state.scan === 1 && (
                    <span className="cursor-pointer">{this.state.settingsLang.sidebar_patients_menu}
                      &nbsp;<span><FontAwesomeIcon className='fontSize18' icon={faAngleRight} /></span>&nbsp;
                      <span>{this.state.totalProfiles} {this.state.clientLang.label_duplicate_profiles}</span>
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* *******Please scan now text******* */}
            {this.state.scan === 0 && (
              <div className='row text-center'>
                <div className='col-lg-12 m-t-30'>
                  <h3 className='font20px'>{this.state.clientLang.label_scan_now}</h3>
                </div>
                <div className='col-lg-12 m-t-30'>
                  <button className='new-blue-btn' onClick={this.fetchList}>Scan Now</button>
                </div>
              </div>
            )}
            {this.state.scan === 1 && (
              <div className='row text-center'>
                <div className='col-lg-12'>
                  <div className="table-responsive">
                    <table className="table-updated setting-table table-min-width no-td-border">
                      
                      <thead className="table-updated-thead">
                        <tr>
                          <th className='table-updated-th'>{this.state.globalLang.header_profile}</th>
                          <th className='table-updated-th'>{this.state.clientLang.client_first_name}</th>
                          <th className='table-updated-th'>{this.state.clientLang.client_last_name}</th>
                          <th className='table-updated-th'>{this.state.clientLang.client_email}</th>
                          <th className='table-updated-th'>{this.state.clientLang.wallet_action}</th>
                        </tr>
                      </thead>
                      
                      <tbody>
                        {
                          this.state.userList &&
                          this.state.userList.length > 0 &&
                          this.state.userList.map((obj, idx) => {
                            return (
                              <tr className='table-updated-tr' key={'key' + idx}>
                                <td className='table-updated-td text-left'>{obj.ctn}</td>
                                <td className='table-updated-td text-left'>{obj.firstname}</td>
                                <td className='table-updated-td text-left'>{obj.lastname}</td>
                                <td className='table-updated-td text-left'>{obj.email}</td>
                                <td className='table-updated-td text-left'>
                                  <a className="new-line-btn text-center m-r-5" onClick={() => this.getMergeProfileData(obj)}>
                                    {this.state.clientLang.label_merge_profiles}
                                  </a>
                                  <a className="easy-link" onClick={this.markAsNotDuplicatePopup.bind(this, obj)}>
                                    {this.state.clientLang.label_mark_not_duplicate}
                                  </a>
                                </td>
                              </tr>
                            )
                          })
                        }
                        {
                          this.state.showLoader === false &&
                          (!this.state.userList || this.state.userList.length <= 0) && (
                            <tr>
                              <td colSpan={6} className="col-xs-12 table-updated-td text-center">
                                <div className={(this.state.userList.length) ? "no-record no-display" : "no-record"}>
                                  {this.state.settingsLang.clinic_No_Record_Found}
                                </div>
                              </td>
                            </tr>
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* *******MODAL TO MARK AS NOT DUPLICATE******** */}
          <div className={(this.state.showDuplicatePopup ? 'blackOverlay' : 'blackOverlay no-display')}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="close"
                    onClick={this.dismissDuplicateModal}
                  >
                    ×
                  </button>
                  <h4 className="modal-title" id="model_title">Confirmation Required!!!</h4>
                </div>
                <div className="modal-body add-patient-form filter-patient">
                  Are you sure this user is not duplicate ?
                </div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button type="button" className="btn logout pull-right" onClick={this.dismissDuplicateModal}>
                      {this.state.settingsLang.no_option}
                    </button>
                    <button type="button" className="btn btn-success pull-right m-r-10" onClick={this.markAsNotDuplicate}>
                      {this.state.settingsLang.yes_option}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  let returnState = {};
  if (state.ClientsReducer.action == 'GET_DUPLICATE_PROFILES') {
    if (state.ClientsReducer.data.status == '200') {
      returnState.clientData = state.ClientsReducer.data.data;
      returnState.clientTimeStamp = new Date();
    } else {
      toast.dismiss();
      toast.error([languageData.global[state.ClientsReducer.data.message]]);
    }
  }
  if (state.ClientsReducer.action == 'MARK_NOT_DUPLICATE') {
    if (state.ClientsReducer.data.status == '200') {
      returnState.clientData = state.ClientsReducer.data.data;
      returnState.clientTimeStamp = new Date();
      toast.success(languageData.global["mark_as_no_duplicate_success"]);
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getClientProfiles: getClientProfiles,
    exportEmptyData: exportEmptyData,
    markUsersAsNotDuplicate: markUsersAsNotDuplicate
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ScanComponent);
