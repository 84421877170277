/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Creatable } from "react-select";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import validator from "validator";
import { toast } from "react-toastify";
import {
  exportEmptyData,
  fetchOrderDetails,
  receiveProduct,
  deletePurchaseOrder,
  downloadOrder,
  editOrderItem,
  emailOrder,
} from "../../../Actions/Inventory/inventoryActions.js";
import {
  autoScrolling,
  showFormattedDate,
  dateFormatPicker,
  isPositiveNumber,
  displayName,
  getCurrencySymbol,
  roundOffNum,
  positionFooterInstantly,
  showWithoutRounding,
  decimalPlaces,
} from "../../../Utils/services.js";
import Loader from "../../Common/Loader.js";
import { getPrecision, toFixed } from "../../../utilities/general.js";
import InventorySidebar from "../InventorySidebar.js";

const formatType = "YYYY-MM-DD";
const dateFormat = (date) => {
  return moment(date).format(formatType);
};

const createOption = (label) => ({
  label,
  value: label.toLowerCase(),
});

class PurchaseOrderDetails extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      globalLang: languageData.global,
      status: "",
      showLoadingText: false,
      userData: "",
      page: 1,
      pagesize: 15,
      sortby: "",
      sortorder: "asc",
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      receivingProduct: {},
      receivingProObj: {},
      supplierDetails: null,
      loadMore: true,
      startFresh: true,
      showLoader: false,
      email: "",
      settingsLang: languageData.settings,
      inventoryLang: languageData.inventory,
      batch_id: "",
      units: "",
      userChanged: false,
      isRender: false,
      showModal: false,
      showDeleteModal: false,
      purchase_order_items: [],
      expiry_date: "", //new Date(),
      expiry_dateClass: "simpleInput",
      clinic: {},
      supplier: {},
      totalQty: 0,
      po_number: 0,
      order_status: "",
      downloadData: {},
      editProduct: {},

      order_placed_by: {},
      order_placed_by_name: "",
      placed_on: null,
      clinicDetails: {},
      clinic_address: "",
      supplier_address: "",
      ship_to_account_number: "",
      actual_units: "",
      showMultiPopUp: false,
      isLoading: false,
      options: [],
      value: [],
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    let isRender = false;
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.setState({
      user_Create_UserBtn: languageData.settings["user_Create_UserBtn"],
      user_First_Name: languageData.settings["user_First_Name"],
      user_Role: languageData.settings["user_Role"],
      user_Last_Name: languageData.settings["user_Last_Name"],
      clinics_Search: languageData.settings["clinics_Search"],
      loading_please_wait_text: languageData.global["loading_please_wait_text"],
      user_Status: languageData.settings["user_Status"],
      clinic_Please_Wait: languageData.settings["clinic_Please_Wait"],
      clinic_No_Record_Found: languageData.settings["clinic_No_Record_Found"],
      setting_create_user_button_error__trail_account_type:
        languageData.settings[
          "setting_create_user_button_error__trail_account_type"
        ],
      isRender: isRender,
      orderId: this.props.match.params.id,
    });
    this.fetchDetails();
  }

  fetchDetails = () => {
    this.setState({
      showLoader: true,
    });
    autoScrolling(true);
    this.props.fetchOrderDetails({
      id: this.props.match.params.id,
    });
  };

  handleTaxOnBlur = (event) => {
    this.setState({
      editTax: Number(event.target.value).toFixed(4),
    });
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (
      event.target.name === "units" ||
      event.target.name === "actual_units" ||
      event.target.name === "damage_units" ||
      event.target.name === "editUnit"
    ) {
      if (decimalPlaces(event.target.value) > 3) {
        return;
      }
    }

    this.setState({
      [event.target.name]: value,
    });
    if (event.target.name === "role_id") {
      this.setState({
        showLoader: true,
      });

      localStorage.setItem("roleId", JSON.stringify(value));
      this.handleSubmit("role_id", value);
    }
    if (event.target.name === "role_id_inactive") {
      this.setState({
        showLoader: true,
      });
      localStorage.setItem("roleIdinactive", JSON.stringify(value));
      this.handleSubmit("role_id_inactive", value);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      nextProps.exportEmptyData();
      return { showLoader: false };
    }

    if (nextProps.orderDeleted != undefined && nextProps.orderDeleted == true) {
      nextProps.exportEmptyData();
      nextProps.history.push("/inventory/purchase-order");
    }

    if (
      nextProps.orderDetails != undefined &&
      nextProps.orderDetails !== prevState.orderDetails
    ) {
      let purchaseOrderDetails = nextProps.orderDetails.PurchaseOrders;
      returnState.showEditModal = false;
      returnState.showLoader = false;
      returnState.showReceiveModal = false;
      returnState.orderDetails = nextProps.orderDetails;
      returnState.PurchaseOrders = purchaseOrderDetails;
      returnState.order_status = purchaseOrderDetails.order_status;
      returnState.subtotal = purchaseOrderDetails.subtotal;
      returnState.total = purchaseOrderDetails.total;
      returnState.tax = purchaseOrderDetails.tax;
      returnState.ReceivedPurchaseOrders =
        nextProps.orderDetails.ReceivedPurchaseOrders;
      returnState.purchase_order_items =
        purchaseOrderDetails.purchase_order_items;
      returnState.order_placed_by = purchaseOrderDetails.order_placed_by
        ? purchaseOrderDetails.order_placed_by
        : "";
      returnState.order_placed_by_name = purchaseOrderDetails.order_placed_by
        ? displayName(purchaseOrderDetails.order_placed_by)
        : "";
      returnState.placed_on = purchaseOrderDetails.placed_on
        ? purchaseOrderDetails.placed_on
        : null;
      returnState.clinicList = nextProps.orderDetails.clinic_list;
      returnState.clinic = purchaseOrderDetails.clinic;
      returnState.supplier = purchaseOrderDetails.supplier;
      returnState.po_number = purchaseOrderDetails.po_number;
      returnState.isRender = true;
      returnState.receivingProObj = {};
      returnState.receivingProduct = {};
      returnState.batch_id = "";
      returnState.units = "";
      returnState.expiry_date = ""; //new Date()
      returnState.invoice_number =
        nextProps.orderDetails.ReceivedPurchaseOrders &&
        nextProps.orderDetails.ReceivedPurchaseOrders.length
          ? nextProps.orderDetails.ReceivedPurchaseOrders[
              nextProps.orderDetails.ReceivedPurchaseOrders.length - 1
            ].invoice_number
          : "";
      returnState.supplierDetails = purchaseOrderDetails.supplier
        ? purchaseOrderDetails.supplier
        : null;
      returnState.mdList = nextProps.orderDetails.md_list;
      if (returnState.supplierDetails) {
        returnState.ship_to_account_number = returnState.supplierDetails
          .ship_to_account_number
          ? returnState.supplierDetails.ship_to_account_number
          : "";
        let supplier_address = returnState.supplierDetails.address_1;
        supplier_address += returnState.supplierDetails.city
          ? ", " + returnState.supplierDetails.city
          : "";
        supplier_address += returnState.supplierDetails.state
          ? ", " + returnState.supplierDetails.state
          : "";
        supplier_address += returnState.supplierDetails.country_name
          ? ", " + returnState.supplierDetails.country_name
          : returnState.supplierDetails.country
          ? ", " + returnState.supplierDetails.country
          : "";
        supplier_address += returnState.supplierDetails.zipcode
          ? " -  " + returnState.supplierDetails.zipcode
          : "";
        returnState.supplier_address = supplier_address;
      }
      returnState.clinicDetails = purchaseOrderDetails.clinic
        ? purchaseOrderDetails.clinic
        : null;
      if (returnState.clinicDetails) {
        let clinic_address = returnState.clinicDetails.address;
        clinic_address += returnState.clinicDetails.city
          ? ", " + returnState.clinicDetails.city
          : "";
        clinic_address += returnState.clinicDetails.country_name
          ? ", " + returnState.clinicDetails.country_name
          : returnState.clinicDetails.country
          ? ", " + returnState.clinicDetails.country
          : "";
        returnState.clinic_address = clinic_address;
      }

      let totalQty = 0;
      if (
        purchaseOrderDetails.purchase_order_items &&
        purchaseOrderDetails.purchase_order_items.length > 0
      ) {
        purchaseOrderDetails.purchase_order_items.map((obj) => {
          totalQty += obj.units;
        });
      }

      const biggestPrecision = purchaseOrderDetails.purchase_order_items.reduce(
        (c, i) => {
          const precision = getPrecision(i.products.count_units_by);
          if (precision > c) {
            return precision;
          }
          return c;
        },
        1,
      );

      returnState.totalQty = toFixed(totalQty, biggestPrecision);

      positionFooterInstantly();

      return returnState;
    }
    if (
      nextProps.downloadData !== undefined &&
      nextProps.downloadData !== prevState.downloadData
    ) {
      returnState.showLoader = false;
      returnState.downloadData = nextProps.downloadData;
      nextProps.exportEmptyData();
      window.open(nextProps.downloadData.file);
    }
    if (nextProps.redirect !== undefined && nextProps.redirect == true) {
      returnState.showModal = false;
      returnState.showEditModal = false;

      nextProps.history.push(`/inventory/suppliers`);
    }
    if (nextProps.userChanged !== undefined && nextProps.userChanged == true) {
      returnState.showLoader = false;
      returnState.userChanged = false;
    }

    if (
      nextProps.emailData !== undefined &&
      nextProps.emailData !== prevState.emailData
    ) {
      returnState.showLoader = false;

      returnState.showMultiPopUp = false;
      returnState.sendType = "";
      returnState.isLoading = false;
      returnState.options = [];
      returnState.value = [];

      returnState.emailData = nextProps.emailData;
    }

    autoScrolling(false);
    nextProps.exportEmptyData();

    return returnState;
  }

  supplierDetails = () => {
    return <div>{this.props.history.push(`/inventory/suppliers`)}</div>;
  };

  redirectToSuppliers = () => {
    return <div>{this.props.history.push(`/inventory/suppliers`)}</div>;
  };
  redirectToPurchaseOrder = () => {
    return <div>{this.props.history.push(`/inventory/purchase-order`)}</div>;
  };

  recevingInfo = (id) => {
    return (
      <div>{this.props.history.push(`/inventory/purchase-order/${id}`)}</div>
    );
  };

  orderInfo = (product) => {
    this.setState({
      showReceiveModal: true,
      receivingProduct: product.products,
      receivingProObj: product,
      batch_id: "",
      batch_idClass: "simpleInput",
      units: "",
      unitsClass: "simpleInput",
      UnitError: false,
      receivingBatchError: false,
      expiry_date: "",
      expiry_dateClass: "simpleInput",
      received_status: "received",
      damage_units: "",
      damage_unitsClass: "simpleInput",
      mark_as_reason: "damage",
      mark_as_reasonClass: "simpleSelect",
      mark_as_reason_other: "",
      mark_as_reason_otherClass: "simpleInput",
      invoice_number:
        this.state.ReceivedPurchaseOrders &&
        this.state.ReceivedPurchaseOrders.length
          ? this.state.ReceivedPurchaseOrders[
              this.state.ReceivedPurchaseOrders.length - 1
            ].invoice_number
          : "",
      invoice_numberClass: "simpleInput",
      pending_units: toFixed(
        product.units - (product.damage_units + product.units_receivied),
        getPrecision(product.units),
      ),
      ordered_units: showWithoutRounding(product.units),

      actualUnitsClass: "simpleInput",
      actualUnitError: false,
      actual_units: "",
    });
  };

  editOrderItem = (product) => {
    this.setState({
      showEditModal: true,
      editUnit: product.units,
      editPrice: product.price_per_unit,
      editTax: Number(product.tax_percentage).toFixed(4),
      editProduct: product,
    });
  };

  dismissReceiveModal = () => {
    this.setState({ showReceiveModal: false });
  };

  dismissEditModal = () => {
    this.setState({ showEditModal: false });
  };

  handleSubmitRecieveModal = () => {
    let inputFieldClass = {
      batch_idClass: "simpleInput",
      unitsClass: "simpleInput",
      expiry_dateClass: "simpleInput",
      damage_unitsClass: "simpleInput",
      mark_as_reasonClass: "simpleSelect",
      mark_as_reason_otherClass: "simpleInput",
      invoice_numberClass: "simpleInput",
    };
    let error = false;
    let formData = {
      received_status: this.state.received_status,
    };
    if (this.state.received_status === "received") {
      formData.invoice_number = this.state.invoice_number;
      if (this.state.receivingProduct.product_type != "others") {
        if (
          this.state.batch_id === undefined ||
          this.state.batch_id === "" ||
          this.state.batch_id === null ||
          validator.trim(this.state.batch_id) === ""
        ) {
          inputFieldClass.batch_idClass = "simpleInput field_error";
          error = true;
        }
        if (
          this.state.expiry_date === undefined ||
          this.state.expiry_date === "" ||
          this.state.expiry_date === null
        ) {
          inputFieldClass.expiry_dateClass = "simpleInput field_error";
          error = true;
        } else if (
          this.state.productId < 0 &&
          this.state.expiry_date <= new Date()
        ) {
          inputFieldClass.expiry_dateClass = "simpleInput field_error";
          error = true;
        }
      }
      if (
        this.state.units === undefined ||
        this.state.units === "" ||
        this.state.units === null ||
        !isPositiveNumber(this.state.units, 0.1, this.state.pending_units, 2)
      ) {
        inputFieldClass.unitsClass = "simpleInput field_error";
        error = true;
      }

      if (
        this.state.actual_units === undefined ||
        this.state.actual_units === "" ||
        this.state.actual_units === null ||
        !isPositiveNumber(this.state.actual_units, 0.1, 9999999999, 2)
      ) {
        inputFieldClass.actualUnitsClass = "simpleInput field_error";
        error = true;
      }

      if (
        new Number(showWithoutRounding(this.state.pending_units)) <
        new Number(showWithoutRounding(this.state.units, 1))
      ) {
        inputFieldClass.unitsClass = "simpleInput field_error";
        toast.dismiss();
        toast.error("Can not receive more than ordered quantity.");
        error = true;
      }

      if (
        this.state.invoice_number === undefined ||
        this.state.invoice_number === "" ||
        this.state.invoice_number === null ||
        validator.trim(this.state.invoice_number) === ""
      ) {
        inputFieldClass.invoice_numberClass = "simpleInput field_error";
        error = true;
      }
    } else if (this.state.received_status === "markas") {
      formData.reason = this.state.mark_as_reason;
      formData.damage_units = this.state.damage_units;
      if (
        this.state.mark_as_reason === undefined ||
        this.state.mark_as_reason === "" ||
        this.state.mark_as_reason === null
      ) {
        inputFieldClass.mark_as_reasonClass = "simpleSelect field_error";
        error = true;
      }
      if (this.state.mark_as_reason === "others") {
        formData.reason = this.state.mark_as_reason_other;
        if (
          this.state.mark_as_reason_other === undefined ||
          this.state.mark_as_reason_other === "" ||
          this.state.mark_as_reason_other === null
        ) {
          inputFieldClass.mark_as_reason_otherClass = "simpleInput field_error";
          error = true;
        }
      }
      if (
        this.state.damage_units === undefined ||
        this.state.damage_units === "" ||
        !isPositiveNumber(
          Number(this.state.damage_units),
          0,
          Number(this.state.pending_units),
          getPrecision(Number(this.state.pending_units)) + 1,
        )
      ) {
        inputFieldClass.damage_unitsClass = "simpleInput field_error";
        error = true;
      }

      if (
        new Number(showWithoutRounding(this.state.pending_units)) <
        new Number(showWithoutRounding(this.state.damage_units))
      ) {
        inputFieldClass.unitsClass = "simpleInput field_error";
        toast.dismiss();
        toast.error("Damaged quantity can not exceed remaining quantity.");
        error = true;
      }
    }
    this.setState(inputFieldClass);
    if (error) {
      return false;
    }

    this.setState({ showLoader: true });

    formData.purchase_order_id = this.state.PurchaseOrders.id;
    formData.purchase_order_item_id = this.state.receivingProObj.id;
    formData.product_id = this.state.receivingProduct.id;
    formData.clinic_id = this.state.clinic.id;
    formData.units = showWithoutRounding(this.state.units);
    formData.actual_unit_received = showWithoutRounding(
      this.state.actual_units,
    );
    if (this.state.receivingProduct.product_type != "others") {
      formData.batch_id = this.state.batch_id;
      formData.expiry_date = dateFormat(this.state.expiry_date);
    }

    this.props.receiveProduct(formData);
  };

  submitEditOrder = () => {
    this.setState({
      editQtyError: false,
      editPriceError: false,
      editTaxError: false,
    });
    if (
      this.state.editUnit === undefined ||
      this.state.editUnit === "" ||
      !isPositiveNumber(this.state.editUnit, 0, 1000000, 4)
    ) {
      this.setState({ editQtyError: true });
      return false;
    }
    if (
      this.state.editPrice === undefined ||
      this.state.editPrice === "" ||
      !isPositiveNumber(this.state.editPrice, 0, 1000000, 3)
    ) {
      this.setState({ editPriceError: true });
      return false;
    }
    if (
      this.state.editTax === undefined ||
      this.state.editTax === "" ||
      !isPositiveNumber(this.state.editTax, 0, 1000000, 5)
    ) {
      this.setState({ editTaxError: true });
      return false;
    }

    this.setState({ showLoader: true });

    let formData = {};
    formData.purchase_order_id = this.state.editProduct.purchase_order_id;
    formData.id = this.state.editProduct.id;
    formData.price_per_unit = this.state.editPrice;
    formData.units = this.state.editUnit;
    formData.tax = this.state.editTax;

    this.props.editOrderItem(formData).then(() => {
      this.fetchDetails();
    });
  };

  handleDatePicker = (date) => {
    this.setState({ expiry_date: date });
  };

  downloadOrder = () => {
    this.setState({ showLoader: true });
    this.props.downloadOrder(this.state.orderId);
  };

  confirmDelete = () => {
    this.setState({ showDeleteModal: true });
  };

  dismissModal = () => {
    this.setState({ showDeleteModal: false });
  };

  deleteOrder = () => {
    this.setState({ showLoader: true, showDeleteModal: false });
    this.props.deletePurchaseOrder(this.state.orderId);
  };

  printOrder = () => {
    let htmlData = document.getElementById("print_area").innerHTML;
    let winPrint = window.open(
      "",
      "",
      "left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0",
    );
    let inoiceTitle = "";

    if (this.state.orderId) {
      inoiceTitle = " - " + this.state.orderId;
    }

    winPrint.document.write(
      `<html><head><title>Purchase Order ${inoiceTitle}</title>`,
    );
    winPrint.document.write(htmlData);

    winPrint.document.close();

    winPrint.focus();
    winPrint.print();
    setTimeout(function () {
      winPrint.close();
    }, 1000);
  };

  emailSuppliers = () => {
    this.setState({ showLoader: true });
    this.props.emailOrder(this.state.orderId);
  };

  showMultiSelectPopup = (type) => {
    let selectOptions = [];

    if (this.state.supplier) {
      if (type && type === "Email") {
        let emailArray = [];

        if (this.state.supplier.supplier_email) {
          emailArray = this.state.supplier.supplier_email.split(",");

          if (emailArray) {
            emailArray.map((obj) => {
              selectOptions.push(createOption(obj));
            });
          }
        }
      } else {
        selectOptions.push(createOption(this.state.supplier.supplier_phone));
      }
    }

    this.setState({
      options: selectOptions,
      value: selectOptions,
      showMultiPopUp: true,
      sendType: type,
    });
  };

  dismissMultiSelectPopup = () => {
    this.setState({
      showMultiPopUp: false,
      sendType: "",
      isLoading: false,
      options: [],
      value: [],
    });
  };

  handleChange = (newValue) => {
    this.setState({ value: newValue });
  };

  handleCreate = (inputValue) => {
    this.setState({ isLoading: true });

    setTimeout(() => {
      const { options } = this.state;
      const newOption = createOption(inputValue);
      const addType = this.state.sendType;
      let error = false;

      newOption.value = newOption.value.trim();

      if (addType === "Email") {
        if (!validator.isEmail(newOption.value)) {
          toast.dismiss();
          toast.error("You have entered an invalid email address.");
          error = true;
        }
      }

      if (addType === "SMS") {
        newOption.value = newOption.value.replace(
          /[ `~!@#$%^&*()_|\-=?;:'",.<>{}[\]\\/]/gi,
          "",
        );
        newOption.label = newOption.value.replace(
          /[ `~!@#$%^&*()_|\-=?;:'",.<>{}[\]\\/]/gi,
          "",
        );

        if (!validator.isMobilePhone(newOption.value)) {
          toast.dismiss();
          toast.error("You have entered an invalid mobile number.");
          error = true;
        }
      }

      if (!error) {
        this.setState({
          isLoading: false,
          options: [...options, newOption],
          value: [...this.state.value, newOption],
        });
      } else {
        this.setState({ isLoading: false });
      }
    }, 1000);
  };

  sendNotifications = () => {
    let error = false;
    const addType = this.state.sendType;
    const dataArray = this.state.value;
    let type = "";
    let formData = {};
    let dataToPost = [];

    if (dataArray && dataArray.length === 0) {
      toast.dismiss();
      toast.error("Please select/add at least one recipient.");
      error = true;
    }

    if (dataArray && dataArray.length > 0) {
      dataArray.map((obj) => {
        dataToPost.push(obj.value);
      });
    }

    if (addType === "Email") {
      type = "send_email";
      formData.email_to = dataToPost;
    } else {
      type = "send_sms";
      formData.sms_to = dataToPost;
    }

    formData.action = type;

    if (!error) {
      this.setState({ showLoader: true });
      this.props.emailOrder(this.state.orderId, formData);
    }
  };

  render() {
    const { isLoading, options, value } = this.state;

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />
            <div className="business-setion memberWalletOuter">
              <div className="title-sub-navigation">
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                    <span
                      className="cursor-pointer"
                      onClick={this.redirectToPurchaseOrder}
                    >
                      {"Purchase Order"}
                    </span>{" "}
                    <span className="breadCrumb">
                      {" "}
                      <i className="fa fa-chevron-right" />{" "}
                      <span className="breadCrumb-text">
                        {this.state.po_number
                          ? "PO#" + this.state.po_number
                          : ""}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="juvly-section full-width m-b-15 auto-height">
                <div className="setting-search-outer">
                  <span className="newTableTitle">
                    {"Order Info " +
                      (this.state.clinic
                        ? "for " + this.state.clinic.clinic_name
                        : "") +
                      (this.state.order_placed_by_name
                        ? " Placed by " + this.state.order_placed_by_name
                        : "") +
                      " on " +
                      this.state.placed_on}
                  </span>
                  <div className="mainRightActions" id="mainRightActions">
                    <div
                      className="option-dropDown pull-right m-l-5 churnFilterExport"
                      data-radium="true"
                    >
                      <div className="dropdown pull-right">
                        <button
                          className="btn btn-default dropdown-toggle"
                          type="button"
                          id="dropdownMenu1"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {`Options`}
                          <i className="fas fa-angle-down" />
                        </button>
                        <ul className="dropdown-menu">
                          <li onClick={() => this.downloadOrder()}>
                            <a>{`Download PDF`}</a>
                          </li>
                          <li onClick={() => this.printOrder()}>
                            <a>{`Print`}</a>
                          </li>
                          <li
                            onClick={() => this.showMultiSelectPopup("Email")}
                          >
                            <a>{`Send via Email`}</a>
                          </li>
                          <li onClick={() => this.showMultiSelectPopup("SMS")}>
                            <a>{`Send via SMS`}</a>
                          </li>

                          {this.state.order_status == "not_received" && (
                            <li onClick={() => this.confirmDelete()}>
                              <a>{`Delete`}</a>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive fixed-header-table">
                  <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-2 table-updated-th">Product</th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-10">
                          Quantity
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-10">
                          Received
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-10">
                          Damaged
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-5 p-l-10">
                          Pending
                        </th>
                        <th className="col-xs-2 table-updated-th ">
                          Price per Quantity
                        </th>
                        <th className="col-xs-1 table-updated-th no-padding-right p-r-0 p-l-10">
                          Tax (in %)
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-10">
                          Total
                        </th>
                        <th className="col-xs-2 table-updated-th p-r-0 p-l-10">
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <Scrollbars
                      className="custome-scroll"
                      autoHeight
                      autoHeightMax={210}
                    >
                      <tbody
                        className={
                          this.state.inActive ? "no-display" : "ajax_body"
                        }
                      >
                        {this.state.purchase_order_items !== undefined &&
                          this.state.purchase_order_items.length > 0 &&
                          this.state.purchase_order_items.map((obj, idx) => {
                            return (
                              <tr
                                className="table-updated-tr"
                                key={"purchase_order_items-" + idx}
                              >
                                <td
                                  className="col-xs-2 table-updated-td word-break"
                                  data-id={obj.id}
                                >
                                  {obj.products.product_name}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-10"
                                  data-id={obj.id}
                                >
                                  {obj.units}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-10"
                                  data-id={obj.id}
                                >
                                  {showWithoutRounding(obj.units_receivied)}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-10"
                                  data-id={obj.id}
                                >
                                  {showWithoutRounding(obj.damage_units)}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-5 p-l-10"
                                  data-id={obj.id}
                                >
                                  {toFixed(
                                    Number(
                                      obj.units -
                                        (obj.units_receivied +
                                          obj.damage_units),
                                    ),
                                    getPrecision(obj.products.count_units_by),
                                  )}
                                </td>
                                <td
                                  className="col-xs-2 table-updated-td text-left word-break "
                                  data-id={obj.id}
                                >
                                  {getCurrencySymbol() +
                                    "" +
                                    roundOffNum(obj.price_per_unit)}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-10"
                                  data-id={obj.id}
                                >
                                  {parseFloat(
                                    Number(obj.tax_percentage).toFixed(4),
                                  )}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td p-r-0 word-break p-l-10"
                                  data-id={obj.id}
                                >
                                  {getCurrencySymbol() +
                                    "" +
                                    roundOffNum(obj.total)}
                                </td>
                                <td
                                  className="col-xs-2 table-updated-td word-break p-r-0 p-l-10"
                                  data-id={obj.id}
                                >
                                  {obj.units ==
                                    obj.units_receivied + obj.damage_units &&
                                    obj.receiving_status.toLowerCase() ==
                                      "full" &&
                                    "Received"}
                                  <a
                                    className={
                                      obj.units -
                                        (obj.units_receivied +
                                          obj.damage_units) >
                                      0
                                        ? "easy-link p-l-0 p-r-0"
                                        : "no-display"
                                    }
                                    key={idx}
                                    onClick={this.orderInfo.bind(this, obj)}
                                  >
                                    <i className="fa fa-file-download m-r-5 m-t-2" />
                                    Receive
                                  </a>
                                  <a
                                    className={
                                      obj.receiving_status.toLowerCase() !=
                                      "full"
                                        ? "easy-link p-r-0"
                                        : "no-display"
                                    }
                                    key={idx + "-edit"}
                                    onClick={this.editOrderItem.bind(this, obj)}
                                  >
                                    <i className="fa fa-pencil-alt m-r-5 m-t-2" />
                                    Edit
                                  </a>
                                </td>
                              </tr>
                            );
                          })}
                        {this.state.showLoader === false &&
                          (!this.state.purchase_order_items ||
                            this.state.purchase_order_items.length <= 0) && (
                            <tr>
                              <td
                                colSpan={6}
                                className="col-xs-12 table-updated-td text-center "
                              >
                                <div
                                  className={
                                    this.state.purchase_order_items &&
                                    this.state.purchase_order_items.length
                                      ? "no-record no-display"
                                      : "no-record"
                                  }
                                >
                                  {this.state.clinic_No_Record_Found}
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Scrollbars>
                  </table>
                </div>
                <div
                  className={
                    this.state.totalQty
                      ? "col-sm-6 col-xs-12  purchaseOrderSuntotal pull-left purchaseOrderInfo"
                      : "no-display"
                  }
                >
                  <div className="detail-row">
                    <label>{"Supplier Name"}:</label>
                    <span className="word-break">
                      {this.state.supplierDetails &&
                        this.state.supplierDetails.supplier_name &&
                        this.state.supplierDetails.supplier_name}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>{"Sold-To Account Number"}:</label>
                    <span className="word-break">
                      {this.state.supplierDetails &&
                      this.state.supplierDetails.account_number
                        ? this.state.supplierDetails.account_number
                        : "NA"}
                    </span>
                  </div>
                  {this.state.ship_to_account_number && (
                    <div className="detail-row">
                      <label>{"Ship-To Account Number"}:</label>
                      <span className="word-break">
                        {this.state.ship_to_account_number}
                      </span>
                    </div>
                  )}
                  <div className="detail-row">
                    <label>{"Supplier Address"}:</label>
                    <span className="word-break">
                      {this.state.supplier_address}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>{"Clinic Address"}:</label>
                    <span className="word-break">
                      {this.state.clinic_address}
                    </span>
                  </div>

                  <div className="detail-row">
                    <label>{"Medical Director"}:</label>
                    <span className="word-break">
                      {this.state.PurchaseOrders &&
                      this.state.PurchaseOrders.md_id === "custom"
                        ? this.state.PurchaseOrders.custom_md
                        : this.state.PurchaseOrders &&
                          this.state.PurchaseOrders.md_id
                        ? this.state.PurchaseOrders.md_name
                        : ""}
                    </span>
                  </div>

                  {this.state.PurchaseOrders &&
                    (this.state.PurchaseOrders.payment_term_type ===
                      "last_4_digits_of_cc" ||
                      this.state.PurchaseOrders.payment_term_type ===
                        "both") && (
                      <div className="detail-row">
                        <label>{"Last 4 Digits Of CC"}:</label>
                        <span className="word-break">
                          {this.state.PurchaseOrders
                            ? this.state.PurchaseOrders.last_4_digits_of_cc
                            : ""}
                        </span>
                      </div>
                    )}

                  {this.state.PurchaseOrders &&
                    (this.state.PurchaseOrders.payment_term_type ===
                      "payment_term_text" ||
                      this.state.PurchaseOrders.payment_term_type ===
                        "both") && (
                      <div className="detail-row">
                        <label>{"Payment Terms"}:</label>
                        <span
                          className="word-break"
                          style={{ whiteSpace: "pre-wrap" }}
                        >
                          {this.state.PurchaseOrders
                            ? this.state.PurchaseOrders.payment_terms
                            : ""}
                        </span>
                      </div>
                    )}
                </div>

                <div
                  className={
                    this.state.totalQty
                      ? "col-lg-3 col-sm-4 col-xs-12  pull-right purchaseOrderInfo"
                      : "no-display"
                  }
                >
                  <div className="detail-row">
                    <label>{"Total Qty"}:</label>
                    <span>
                      {!isNaN(this.state.totalQty) ? this.state.totalQty : 0}
                    </span>
                  </div>
                  <div className="detail-row">
                    <label>{"Sub Total (" + getCurrencySymbol() + ")"}:</label>
                    <span>{roundOffNum(this.state.subtotal)}</span>
                  </div>
                  <div className="detail-row">
                    <label>{"Total Tax (" + getCurrencySymbol() + ")"}:</label>
                    <span>{roundOffNum(this.state.tax)}</span>
                  </div>
                  <div className="detail-row">
                    <label>
                      {"Grand Total (" + getCurrencySymbol() + ")"}:
                    </label>
                    <span>{roundOffNum(this.state.total)}</span>
                  </div>
                </div>
              </div>
              <div className="juvly-section full-width auto-height">
                <div className="setting-search-outer">
                  <span className="newTableTitle">
                    {"Received in " +
                      (this.state.clinic ? this.state.clinic.clinic_name : "")}
                  </span>
                </div>
                <div className="table-responsive fixed-header-table">
                  <table className="table-updated juvly-table min-w-850 no-td-border table-fixed no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="col-xs-2 table-updated-th p-r-0">
                          Product
                        </th>
                        <th className="col-xs-2 table-updated-th p-r-0 p-l-5 purchaseOrderInvoice">
                          Invoice No#
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-5">
                          Batch Id
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-5">
                          Quantity
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-5">
                          Units
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-5">
                          Damaged
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-5 purchaseOrderExpiry">
                          Expiry Date
                        </th>
                        <th className="col-xs-2 table-updated-th p-r-0 p-l-5 purchaseOrderRecieveDate">
                          Date & Time of receiving
                        </th>
                        <th className="col-xs-1 table-updated-th p-r-0 p-l-5">
                          Received By
                        </th>
                      </tr>
                    </thead>
                    <Scrollbars
                      className="custome-scroll"
                      autoHeight
                      autoHeightMax={210}
                    >
                      <tbody
                        className={
                          this.state.inActive ? "no-display" : "ajax_body"
                        }
                      >
                        {this.state.ReceivedPurchaseOrders !== undefined &&
                          this.state.ReceivedPurchaseOrders.length > 0 &&
                          this.state.ReceivedPurchaseOrders.map((obj, idx) => {
                            return (
                              <tr
                                className="table-updated-tr"
                                key={"ReceivedPurchaseOrders-" + idx}
                              >
                                <td
                                  className="col-xs-2 table-updated-td word-break p-r-0"
                                  data-id={obj.id}
                                >
                                  {obj.products.product_name}
                                </td>
                                <td
                                  className="col-xs-2 table-updated-td word-break p-r-0 p-l-5 purchaseOrderInvoice"
                                  data-id={obj.id}
                                >
                                  {obj.invoice_number
                                    ? obj.invoice_number
                                    : "NA"}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-5"
                                  data-id={obj.id}
                                >
                                  {obj.products.product_type == "others" ||
                                  obj.received_status == "markas"
                                    ? "NA"
                                    : obj.batch_id}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-5"
                                  data-id={obj.id}
                                >
                                  {showWithoutRounding(obj.units)}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-5"
                                  data-id={obj.id}
                                >
                                  {showWithoutRounding(
                                    obj.actual_unit_received,
                                  )}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-5"
                                  data-id={obj.id}
                                >
                                  {showWithoutRounding(obj.damage_units)}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-r-0 p-l-5 purchaseOrderExpiry"
                                  data-id={obj.id}
                                >
                                  {obj.products.product_type == "others" ||
                                  obj.received_status == "markas"
                                    ? "NA"
                                    : showFormattedDate(obj.expiry_date)}
                                </td>
                                <td
                                  className="col-xs-2 table-updated-td word-break text-left p-r-0 p-l-5 purchaseOrderRecieveDate"
                                  data-id={obj.id}
                                >
                                  {obj.receivied_on}
                                </td>
                                <td
                                  className="col-xs-1 table-updated-td word-break p-l-5 p-r-0"
                                  data-id={obj.id}
                                >
                                  {displayName(obj.users)}
                                </td>
                              </tr>
                            );
                          })}
                        {this.state.showLoader === false &&
                          (!this.state.ReceivedPurchaseOrders ||
                            this.state.ReceivedPurchaseOrders.length <= 0) && (
                            <tr>
                              <td
                                colSpan={6}
                                className="col-xs-12 table-updated-td text-center "
                              >
                                <div
                                  className={
                                    this.state.ReceivedPurchaseOrders &&
                                    this.state.ReceivedPurchaseOrders.length
                                      ? "no-record no-display"
                                      : "no-record"
                                  }
                                >
                                  {this.state.clinic_No_Record_Found}
                                </div>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </Scrollbars>
                  </table>
                </div>
              </div>
              {/* Modal - Add Lot number and Expiration - START */}
              {this.state.showReceiveModal === true && (
                <div className={"modalOverlay modalOverlay-showReceiveModal"}>
                  <div
                    className={
                      "small-popup-outer large-popup recieveProductPopup"
                    }
                  >
                    <div className="small-popup-header p-r-40">
                      <div className="popup-name">
                        {Object.entries(this.state.receivingProduct).length
                          ? "Receive Product " +
                            this.state.receivingProduct.product_name
                          : "Receive Product "}
                      </div>
                      <div className="popup-name pull-right remainUnits m-r-0">
                        {this.state.receivingProObj
                          ? "Remaining Quantity - " + this.state.pending_units
                          : ""}
                      </div>
                      <a
                        onClick={this.dismissReceiveModal}
                        className="small-cross"
                      >
                        ×
                      </a>
                    </div>
                    <div className="small-popup-content">
                      <div className="juvly-container">
                        <div className="row">
                          <div className="col-xs-12 no-padding">
                            <div className="basic-checkbox-outer">
                              <input
                                id="radiobutton3"
                                className="basic-form-checkbox"
                                name="received_status"
                                type="radio"
                                value="received"
                                onChange={this.handleInputChange}
                                checked={
                                  this.state.received_status == "received"
                                    ? "checked"
                                    : false
                                }
                              />
                              <label
                                className="basic-form-text"
                                htmlFor="radiobutton3"
                              >
                                Receive
                              </label>
                            </div>
                            <div className="basic-checkbox-outer">
                              <input
                                id="radiobutton4"
                                className="basic-form-checkbox"
                                name="received_status"
                                type="radio"
                                value="markas"
                                checked={
                                  this.state.received_status == "markas"
                                    ? "checked"
                                    : false
                                }
                                onChange={this.handleInputChange}
                              />
                              <label
                                className="basic-form-text"
                                htmlFor="radiobutton4"
                              >
                                Mark as damage
                              </label>
                            </div>
                          </div>
                          {this.state.received_status == "received" && (
                            <div
                              className={
                                "row received_status-row received_status-row-received"
                              }
                            >
                              <div
                                className={
                                  Object.entries(this.state.receivingProduct)
                                    .length &&
                                  this.state.receivingProduct.product_type ==
                                    "others"
                                    ? "col-sm-4 col-xs-12"
                                    : "col-md-4 col-sm-6 col-xs-12"
                                }
                              >
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    {"Clinic"}
                                    <span className="required">*</span>
                                  </div>
                                  <div className="newInputField text-ellipsis">
                                    {this.state.clinic &&
                                      this.state.clinic.clinic_name}
                                  </div>
                                </div>
                              </div>
                              <div
                                className={
                                  Object.entries(this.state.receivingProduct)
                                    .length &&
                                  this.state.receivingProduct.product_type ==
                                    "others"
                                    ? "col-sm-4 col-xs-12"
                                    : "col-md-4 col-sm-6 col-xs-12"
                                }
                              >
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    Invoice No#
                                    <span className="required">*</span>
                                  </div>
                                  <div className="">
                                    <input
                                      className={this.state.invoice_numberClass}
                                      type="text"
                                      name="invoice_number"
                                      value={this.state.invoice_number}
                                      autoComplete="off"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              {Object.entries(this.state.receivingProduct)
                                .length &&
                                this.state.receivingProduct.product_type !=
                                  "others" && (
                                  <div
                                    className={"col-md-4 col-sm-6 col-xs-12"}
                                  >
                                    <div className="newInputFileldOuter">
                                      <div className="newInputLabel">
                                        {Object.entries(
                                          this.state.receivingProduct,
                                        ).length &&
                                        this.state.receivingProduct
                                          .product_type &&
                                        this.state.receivingProduct
                                          .product_type === "coolsculpting"
                                          ? "Serial No"
                                          : this.state.inventoryLang
                                              .inventory_batch_id}
                                        <span className="required">*</span>
                                      </div>
                                      <div className="">
                                        <input
                                          className={this.state.batch_idClass}
                                          type="text"
                                          name="batch_id"
                                          value={this.state.batch_id}
                                          autoComplete="off"
                                          onChange={this.handleInputChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              <div
                                className={
                                  Object.entries(this.state.receivingProduct)
                                    .length &&
                                  this.state.receivingProduct.product_type ==
                                    "others"
                                    ? "col-sm-4 col-xs-12"
                                    : "col-md-4 col-sm-6 col-xs-12"
                                }
                              >
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    Quantity Received
                                    <span className="required">*</span>
                                  </div>
                                  <div className="">
                                    <input
                                      className={this.state.unitsClass}
                                      type="text"
                                      name="units"
                                      value={this.state.units}
                                      autoComplete="off"
                                      onChange={this.handleInputChange}
                                    />
                                    <span className="maxCharacterInstruction">
                                      Max: {this.state.pending_units}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  Object.entries(this.state.receivingProduct)
                                    .length &&
                                  this.state.receivingProduct.product_type ==
                                    "others"
                                    ? "col-sm-4 col-xs-12"
                                    : "col-md-4 col-sm-6 col-xs-12"
                                }
                              >
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    Units Received
                                    <span className="required">*</span>
                                  </div>
                                  <div className="">
                                    <input
                                      className={this.state.actualUnitsClass}
                                      type="text"
                                      name="actual_units"
                                      value={this.state.actual_units}
                                      autoComplete="off"
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                              </div>

                              {Object.entries(this.state.receivingProduct)
                                .length &&
                                this.state.receivingProduct.product_type !=
                                  "others" && (
                                  <div
                                    className={"col-md-4 col-sm-6 col-xs-12"}
                                  >
                                    <div className="newInputFileldOuter">
                                      <div className="newInputLabel">
                                        {"Expiration Date"}
                                        <span className="required">*</span>
                                      </div>
                                      <div className="simpleDatePicker">
                                        <DatePicker
                                          value={
                                            this.state.expiry_date
                                              ? new Date(
                                                  moment(
                                                    this.state.expiry_date,
                                                  ),
                                                )
                                              : null
                                          }
                                          onChange={this.handleDatePicker}
                                          className={
                                            this.state.expiry_dateClass
                                          }
                                          dateFormat={dateFormatPicker()}
                                          placeholderText={dateFormatPicker().toLowerCase()}
                                          name={"expiry_date"}
                                          selected={
                                            this.state.expiry_date
                                              ? this.state.expiry_date
                                              : null
                                          }
                                          autoComplete="off"
                                          minDate={moment().toDate()}
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          ref={(refDatePickerStockModal) =>
                                            (this.refDatePickerStockModal =
                                              refDatePickerStockModal)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}
                          {this.state.received_status == "markas" && (
                            <div
                              className={
                                "row received_status-row received_status-row-maskas"
                              }
                            >
                              <div className="col-sm-4 col-xs-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">
                                    Damaged Quantity
                                    <span className="required">*</span>
                                  </div>
                                  <div className="">
                                    <input
                                      className={this.state.damage_unitsClass}
                                      type="text"
                                      name="damage_units"
                                      value={this.state.damage_units}
                                      autoComplete="off"
                                      onChange={this.handleInputChange}
                                    />
                                    <span className="maxCharacterInstruction">
                                      Max: {this.state.pending_units}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4 col-xs-12">
                                <div className="simpleField">
                                  <div className="simpleLabel">
                                    {"Reason"}
                                    <span className="required">*</span>
                                  </div>
                                  <div className="setting-input-outer">
                                    <select
                                      className={this.state.mark_as_reasonClass}
                                      onChange={this.handleInputChange}
                                      name={"mark_as_reason"}
                                      value={this.state.mark_as_reason}
                                    >
                                      <option value={0}>
                                        {
                                          this.state.inventoryLang
                                            .inventory_select
                                        }
                                      </option>
                                      <option value="damage">
                                        {
                                          this.state.globalLang
                                            .inventory_reconciliation_damage
                                        }
                                      </option>
                                      <option value="sample">
                                        {
                                          this.state.globalLang
                                            .inventory_reconciliation_sample
                                        }
                                      </option>
                                      <option value="others">
                                        {
                                          this.state.inventoryLang
                                            .inventory_others
                                        }
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              {this.state.mark_as_reason === "others" && (
                                <div className="col-sm-4 col-xs-12">
                                  <div className="simpleField">
                                    <div className="simpleLabel">
                                      {"Other Reason"}
                                      <span className="required">*</span>
                                    </div>
                                    <div className="setting-input-outer">
                                      <input
                                        className={
                                          this.state.mark_as_reason_otherClass
                                        }
                                        type="text"
                                        name="mark_as_reason_other"
                                        value={this.state.mark_as_reason_other}
                                        autoComplete="off"
                                        onChange={this.handleInputChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="footer-static">
                      <a
                        className={"new-blue-btn pull-right"}
                        onClick={this.handleSubmitRecieveModal}
                      >
                        {this.state.globalLang.label_save}
                      </a>
                      <a
                        className="new-white-btn pull-right"
                        onClick={this.dismissReceiveModal}
                      >
                        {this.state.globalLang.label_cancel}
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {/* Modal - Add Lot number and Expiration - END */}

              {/* Modal - Edit Order Item - START */}
              <div
                className={
                  this.state.showEditModal
                    ? "modalOverlay"
                    : "modalOverlay no-display"
                }
              >
                <div className="small-popup-outer createStockInventory large-popup">
                  <div className="small-popup-header">
                    <div className="popup-name">
                      {Object.entries(this.state.receivingProduct).length
                        ? "Edit Order Item " +
                          this.state.receivingProduct.product_name
                        : "Edit Product Item "}
                    </div>
                    <a onClick={this.dismissEditModal} className="small-cross">
                      ×
                    </a>
                  </div>
                  <div className="small-popup-content no-scroll">
                    <div className="juvly-container">
                      <div className="row">
                        <div className={"col-sm-4 col-xs-12"}>
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Quantity<span className="required">*</span>
                            </div>
                            <div className="">
                              <input
                                className={
                                  this.state.editQtyError
                                    ? "newInputField field-error"
                                    : "newInputField"
                                }
                                type="text"
                                name="editUnit"
                                value={this.state.editUnit}
                                autoComplete="off"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={"col-sm-4 col-xs-12"}>
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Price per Quantity
                              <span className="required">*</span>
                            </div>
                            <div className="">
                              <input
                                className={
                                  this.state.editPriceError
                                    ? "newInputField field-error"
                                    : "newInputField"
                                }
                                type="text"
                                name="editPrice"
                                value={this.state.editPrice}
                                disabled={
                                  this.state.editProduct.receiving_status ==
                                  "PARTIAL"
                                    ? "disabled"
                                    : ""
                                }
                                autoComplete="off"
                                onChange={this.handleInputChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={"col-sm-4 col-xs-12"}>
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Tax(in %)<span className="required">*</span>
                            </div>
                            <div className="">
                              <input
                                className={
                                  this.state.editTaxError
                                    ? "newInputField field-error"
                                    : "newInputField"
                                }
                                type="number"
                                min={0}
                                name="editTax"
                                value={this.state.editTax}
                                autoComplete="off"
                                disabled={
                                  this.state.editProduct.receiving_status ==
                                  "PARTIAL"
                                    ? "disabled"
                                    : ""
                                }
                                placeholder="0.0000"
                                onChange={this.handleInputChange}
                                onBlur={this.handleTaxOnBlur}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer-static">
                    <a
                      className={"new-blue-btn pull-right"}
                      onClick={this.submitEditOrder}
                    >
                      {this.state.globalLang.label_save}
                    </a>
                    <a
                      className="new-white-btn pull-right"
                      onClick={this.dismissEditModal}
                    >
                      {this.state.globalLang.label_cancel}
                    </a>
                  </div>
                </div>
              </div>
              {/* Modal - Edit Order Item - END */}

              {/* Modal - Delete Purchase Order - END */}
              <div
                className={this.state.showDeleteModal ? "overlay" : ""}
              ></div>
              <div
                id="filterModal"
                role="dialog"
                className={
                  this.state.showDeleteModal
                    ? "modal fade in displayBlock"
                    : "modal fade no-display"
                }
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={this.dismissModal}
                      >
                        ×
                      </button>
                      <h4 className="modal-title" id="model_title">
                        {this.state.globalLang.delete_confirmation}
                      </h4>
                    </div>
                    <div
                      id="errorwindow"
                      className="modal-body add-patient-form filter-patient"
                    >
                      {"Are you sure you want to delete this purchase order?"}
                    </div>
                    <div className="modal-footer">
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button
                          type="button"
                          className="btn logout pull-right"
                          data-dismiss="modal"
                          onClick={this.dismissModal}
                        >
                          {this.state.globalLang.label_no}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success pull-right m-r-10"
                          data-dismiss="modal"
                          onClick={this.deleteOrder}
                        >
                          {this.state.globalLang.label_yes}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal - Delete Purchase Order - END */}

              {/*Modal - Send multiple notifications*/}
              {this.state.showMultiPopUp === true && (
                <div className={"modalOverlay modalOverlay-showReceiveModal"}>
                  <div
                    className={
                      "small-popup-outer large-popup recieveProductPopup"
                    }
                  >
                    <div className="small-popup-header p-r-40">
                      <div className="popup-name">
                        Send Via {this.state.sendType}
                      </div>

                      <a
                        onClick={this.dismissMultiSelectPopup}
                        className="small-cross"
                      >
                        ×
                      </a>
                    </div>
                    <div className="small-popup-content">
                      <div className="juvly-container">
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="setting-field-outer">
                              <div className="new-field-label">
                                Select/Add Recipients
                              </div>
                              <div className="setting-input-outer">
                                <div className="tag-auto-select">
                                  {
                                    <Creatable
                                      isClearable
                                      isSearchable
                                      isDisabled={isLoading}
                                      isLoading={isLoading}
                                      onChange={this.handleChange}
                                      onCreateOption={this.handleCreate}
                                      options={options}
                                      value={value}
                                      isMulti={true}
                                    />
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer-static">
                      <a
                        className={"new-blue-btn pull-right"}
                        onClick={this.sendNotifications}
                      >
                        {this.state.globalLang.global_label_send}
                      </a>
                      <a
                        className="new-white-btn pull-right"
                        onClick={this.dismissMultiSelectPopup}
                      >
                        {this.state.globalLang.label_cancel}
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {/*Modal - Send multiple notifications*/}

              <div
                className="invoice-preview-outer"
                id="print_area"
                style={{ display: "none" }}
              >
                <center>
                  <table
                    border={0}
                    cellPadding={10}
                    cellSpacing={0}
                    width={700}
                    style={{
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    <thead
                      style={{
                        color: "#667680",
                        fontWeight: "normal",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            fontSize: "13px",
                            display: "inline-block",
                            padding: "0px 0px",
                            color: "#000",
                            fontWeight: "normal",
                            textAlign: "left",
                          }}
                        >
                          {"Order Info " +
                            (this.state.clinic
                              ? "for " + this.state.clinic.clinic_name
                              : "") +
                            (this.state.order_placed_by_name
                              ? " Placed by " + this.state.order_placed_by_name
                              : "") +
                            " on " +
                            this.state.placed_on}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    border={0}
                    cellPadding={10}
                    cellSpacing={0}
                    width={700}
                    style={{
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    <thead
                      style={{
                        background: "#daedf7",
                        color: "#667680",
                        fontWeight: "normal",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            backgroundColor: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "30%",
                          }}
                        >
                          Product
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Received
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Damaged
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Pending
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Price per Quantity
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Tax (in %)
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    {this.state.purchase_order_items !== undefined &&
                      this.state.purchase_order_items.length > 0 && (
                        <tbody>
                          {this.state.purchase_order_items.map((obj, idx) => {
                            return (
                              <tr key={"purchase_order_items-" + idx}>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {obj.products.product_name}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {obj.units}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {showWithoutRounding(obj.units_receivied)}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {showWithoutRounding(obj.damage_units)}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {toFixed(
                                    Number(
                                      obj.units -
                                        (obj.units_receivied +
                                          obj.damage_units),
                                    ),
                                    getPrecision(obj.products.count_units_by),
                                  )}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {getCurrencySymbol() +
                                    "" +
                                    obj.price_per_unit}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {parseFloat(
                                    Number(obj.tax_percentage).toFixed(4),
                                  )}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {getCurrencySymbol() + "" + obj.total}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                    <tfoot>
                      <tr>
                        <td
                          colSpan={5}
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            borderBottom: "1px solid #dddddd",
                          }}
                        >
                          <table
                            border={0}
                            cellPadding={5}
                            cellSpacing={0}
                            style={{
                              fontWeight: 500,
                              fontSize: "13px",
                              float: "left",
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "40%", fontSize: "12px" }}>
                                  Supplier Name:
                                </td>
                                <td
                                  style={{ "text-align": "left", width: "60%" }}
                                >
                                  {this.state.supplierDetails &&
                                    this.state.supplierDetails.supplier_name &&
                                    this.state.supplierDetails
                                      .supplier_name}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "40%", fontSize: "12px" }}>
                                  Ship-To Account Number:
                                </td>
                                <td
                                  style={{ "text-align": "left", width: "60%" }}
                                >
                                  {this.state.supplierDetails &&
                                  this.state.supplierDetails.account_number
                                    ? this.state.supplierDetails.account_number
                                    : "NA"}
                                </td>
                              </tr>
                              {this.state.ship_to_account_number && (
                                <tr>
                                  <td
                                    style={{ width: "40%", fontSize: "12px" }}
                                  >
                                    Supplier Address:
                                  </td>
                                  <td
                                    style={{
                                      "text-align": "left",
                                      width: "60%",
                                    }}
                                  >
                                    {this.state.ship_to_account_number}
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td style={{ width: "40%", fontSize: "12px" }}>
                                  Supplier Address:
                                </td>
                                <td
                                  style={{ "text-align": "left", width: "60%" }}
                                >
                                  {this.state.supplier_address}
                                </td>
                              </tr>

                              <tr>
                                <td style={{ width: "40%", fontSize: "12px" }}>
                                  Clinic Address:
                                </td>
                                <td
                                  style={{ "text-align": "left", width: "60%" }}
                                >
                                  {this.state.clinic_address}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "40%", fontSize: "12px" }}>
                                  Medical Director:
                                </td>
                                <td
                                  style={{ "text-align": "left", width: "60%" }}
                                >
                                  {this.state.PurchaseOrders &&
                                  this.state.PurchaseOrders.md_id === "custom"
                                    ? this.state.PurchaseOrders.custom_md
                                    : this.state.PurchaseOrders &&
                                      this.state.PurchaseOrders.md_id
                                    ? this.state.PurchaseOrders.md_name
                                    : ""}
                                </td>
                              </tr>

                              {this.state.PurchaseOrders &&
                                (this.state.PurchaseOrders.payment_term_type ===
                                  "last_4_digits_of_cc" ||
                                  this.state.PurchaseOrders
                                    .payment_term_type === "both") && (
                                  <tr>
                                    <td
                                      style={{ width: "40%", fontSize: "12px" }}
                                    >
                                      Last 4 Digits Of CC:
                                    </td>
                                    <td
                                      style={{
                                        "text-align": "left",
                                        width: "60%",
                                      }}
                                    >
                                      {this.state.PurchaseOrders
                                        ? this.state.PurchaseOrders
                                            .last_4_digits_of_cc
                                        : ""}
                                    </td>
                                  </tr>
                                )}

                              {this.state.PurchaseOrders &&
                                (this.state.PurchaseOrders.payment_term_type ===
                                  "payment_term_text" ||
                                  this.state.PurchaseOrders
                                    .payment_term_type === "both") && (
                                  <tr>
                                    <td
                                      style={{ width: "40%", fontSize: "12px" }}
                                    >
                                      Payment Terms:
                                    </td>
                                    <td
                                      style={{
                                        "text-align": "left",
                                        width: "60%",
                                        whiteSpace: "pre-wrap",
                                      }}
                                    >
                                      {this.state.PurchaseOrders
                                        ? this.state.PurchaseOrders
                                            .payment_terms
                                        : ""}
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </td>
                        <td
                          colSpan={4}
                          style={{
                            fontWeight: "normal",
                            fontSize: "12px",
                            borderBottom: "1px solid #dddddd",
                          }}
                        >
                          <table
                            border={0}
                            cellPadding={5}
                            cellSpacing={0}
                            style={{
                              fontWeight: 500,
                              fontSize: "13px",
                              float: "right",
                              width: "100%",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td style={{ width: "50%", fontSize: "12px" }}>
                                  Total Qty:
                                </td>
                                <td
                                  style={{
                                    width: "50%",
                                    "text-align": "right",
                                  }}
                                >
                                  {!isNaN(this.state.totalQty)
                                    ? this.state.totalQty
                                    : 0}{" "}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "50%", fontSize: "12px" }}>
                                  Sub Total ({getCurrencySymbol()}):
                                </td>
                                <td
                                  style={{
                                    width: "50%",
                                    "text-align": "right",
                                  }}
                                >
                                  {roundOffNum(this.state.subtotal)}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "50%", fontSize: "12px" }}>
                                  Total Tax ({getCurrencySymbol()}):
                                </td>
                                <td
                                  style={{
                                    width: "50%",
                                    "text-align": "right",
                                  }}
                                >
                                  {roundOffNum(this.state.tax)}
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "50%", fontSize: "12px" }}>
                                  Grand Total ({getCurrencySymbol()}):
                                </td>
                                <td
                                  style={{
                                    width: "50%",
                                    "text-align": "right",
                                  }}
                                >
                                  {roundOffNum(this.state.total)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tfoot>
                  </table>

                  <table
                    border={0}
                    cellPadding={10}
                    cellSpacing={0}
                    width={700}
                    style={{
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontSize: "13px",
                            display: "inline-block",
                            padding: "0px 0px",
                            color: "#000",
                            fontWeight: "normal",
                            textAlign: "left",
                          }}
                        >
                          Received in{" "}
                          {this.state.clinic
                            ? this.state.clinic.clinic_name
                            : ""}
                        </th>
                      </tr>
                    </thead>
                  </table>
                  <table
                    border={0}
                    cellPadding={10}
                    cellSpacing={0}
                    width={700}
                    style={{
                      fontFamily: "Lato,sans-serif",
                      textAlign: "left",
                      marginBottom: "20px",
                    }}
                  >
                    <thead
                      style={{
                        background: "#daedf7",
                        color: "#667680",
                        fontWeight: "normal",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <tr>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "15%",
                          }}
                        >
                          Product
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "15%",
                          }}
                        >
                          Invoice No#
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Batch Id
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Quantity
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Units
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Damaged
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "10%",
                          }}
                        >
                          Expiry Date
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "15%",
                          }}
                        >
                          Date &amp; Time of receiving
                        </th>
                        <th
                          style={{
                            background: "#daedf7",
                            fontWeight: "normal",
                            fontSize: "12px",
                            textAlign: "left",
                            width: "15%",
                          }}
                        >
                          Received By
                        </th>
                      </tr>
                    </thead>
                    {this.state.ReceivedPurchaseOrders !== undefined &&
                      this.state.ReceivedPurchaseOrders.length > 0 && (
                        <tbody>
                          {this.state.ReceivedPurchaseOrders.map((obj, idx) => {
                            return (
                              <tr key={"ReceivedPurchaseOrders-" + idx}>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {obj.products.product_name}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                    "word-break": "break-word",
                                  }}
                                >
                                  {obj.invoice_number
                                    ? obj.invoice_number
                                    : "NA"}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                    "word-break": "break-word",
                                  }}
                                >
                                  {obj.products.product_type == "others" ||
                                  obj.received_status == "markas"
                                    ? "NA"
                                    : obj.batch_id}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {showWithoutRounding(obj.units)}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {showWithoutRounding(
                                    obj.actual_unit_received,
                                  )}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {showWithoutRounding(obj.damage_units)}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {obj.products.product_type == "others" ||
                                  obj.received_status == "markas"
                                    ? "NA"
                                    : showFormattedDate(obj.expiry_date)}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {obj.receivied_on}
                                </td>
                                <td
                                  style={{
                                    fontWeight: "normal",
                                    fontSize: "12px",
                                    borderBottom: "1px solid #dddddd",
                                  }}
                                >
                                  {displayName(obj.users)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      )}
                  </table>
                </center>
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "ORDER_RECEVING_LIST") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.supplierDetails = state.InventoryReducer.data.data;
      returnState.supplierDetailsTime = new Date();
      returnState.supplierUpdate = true;
      toast.success("Supplier updated successfully");
    }
  }

  if (state.SettingReducer.action === "USERS_SEARCH") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false;
    } else {
      returnState.userSearch = state.SettingReducer.data.data;
    }
  }

  if (state.InventoryReducer.action === "GET_ORDER_DETAILS") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.orderDetails = state.InventoryReducer.data.data;
      returnState.orderDetailsTime = new Date();
    }
  }
  if (state.InventoryReducer.action === "RECEIVE_PRODUCT") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.orderDetails = state.InventoryReducer.data.data;
      returnState.orderDetailsTime = new Date();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
    }
  }
  if (state.InventoryReducer.action === "DELETE_PURCHASE_ORDER") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.orderDeleted = true;
      toast.success(languageData.global[state.InventoryReducer.data.message]);
    }
  }
  if (state.InventoryReducer.action === "DOWNLOAD_PURCHASE_ORDER") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.downloadData = state.InventoryReducer.data.data;
    }
  }
  if (state.InventoryReducer.action === "EDIT_ORDER_ITEM") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.orderDetails = state.InventoryReducer.data.data;
      returnState.orderDetailsTime = new Date();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
    }
  }

  if (state.InventoryReducer.action === "EMAIL_PURCHASE_ORDER") {
    localStorage.setItem("loadFresh", false);
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      returnState.emailData = state.InventoryReducer.data.data;
      toast.success(languageData.global[state.InventoryReducer.data.message]);
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchOrderDetails: fetchOrderDetails,
      exportEmptyData: exportEmptyData,
      receiveProduct: receiveProduct,
      deletePurchaseOrder: deletePurchaseOrder,
      downloadOrder,
      editOrderItem,
      emailOrder,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseOrderDetails);
