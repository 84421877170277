import React from "react";
import "../../i18n/setup";
import "bootstrap";
// eslint-disable-next-line import/no-restricted-paths
import "../../_legacy/styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import "../../../node_modules/react-intl-tel-input/dist/libphonenumber.js";
import "../../../node_modules/react-intl-tel-input/dist/main.css";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import { Provider as StoreProvider } from "react-redux";
import { QueryClientProvider } from "@tanstack/react-query";
import { dispatch } from "../../store/store";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";
import { RouterProvider } from "../../router/shared/RouterProvider.js";
import { useMaintenanceModeRedirect } from "../../hooks/useMaintenanceModeRedirect.js";
import { history as globalHistory } from "../../history.js";

// TODO: Should be removed if possible and replaced with a proper solution
axios.interceptors.response.use(accountStatusResponseInterceptor);

// TODO: Should be removed if possible
globalHistory.listen(() => {
  dispatch({ type: "RESET_ALL" });
});

/**
 * @param {Object} props
 * @param {React.ReactNode[]} props.children
 * @param {Object} props.store
 * @param {Object} props.queryClient
 * @param {Object} props.history
 */
export const AppProvider = ({ children, store, queryClient, history }) => {
  useMaintenanceModeRedirect();

  return (
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <RouterProvider history={history}>
          <React.Fragment>{children}</React.Fragment>
        </RouterProvider>
      </QueryClientProvider>
    </StoreProvider>
  );
};
