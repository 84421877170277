import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import DragAndDrop from "../../Consents/DragAndDrop";
import { useQuestionnairesQuery } from "../../../../../api/queries/useQuestionnairesQuery";
import { Empty } from "../../../../../shared/Empty/Empty";
import { LoadMoreObserver } from "../../../../../shared/LoadMoreObserver/LoadMoreObserver";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { history } from "../../../../../history";
import classes from "./List.module.scss";
import { useDebouncedState } from "../../../../../utilities/hooks/useDebouncedState";
import { Skeleton } from "../../../../../shared/Skeleton/Skeleton";

export function List({ searchTerm }) {
  const { tCommon } = useAppTranslation.Common();
  const debouncedSearch = useDebouncedState(searchTerm);
  const search = debouncedSearch[1];
  const setSearch = debouncedSearch[2];

  const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useQuestionnairesQuery({
      payload: {
        pageSize: 15,
        searchTerm: search,
      },
      options: {
        staleTime: 0,
        cacheTime: 0,
        keepPreviousData: true,
        onError: () => {
          uiNotification.error(tCommon("error.fetchQuestionnaires"));
        },
      },
    });

  const questionnaires = useMemo(() => {
    if (data?.pages) {
      return data.pages
        .map((p) => p.questionnaires)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data?.pages]);

  const isEmpty = questionnaires.length === 0 && !isLoading;
  const isVisible = !isLoading && !isEmpty;

  const handleEdit = (id) => {
    history.push(`/settings/questionnaires/${id}/edit`);
  };

  useEffect(() => {
    setSearch((prev) => {
      if (prev !== searchTerm) {
        return searchTerm;
      }
      return prev;
    });
  }, [searchTerm]);

  return (
    <>
      {isLoading && (
        <tr className={classes.skeletonWrap}>
          <div className={classes.skeleton}>
            <Skeleton count={15} height={51} borderRadius="0px" />
          </div>
        </tr>
      )}
      {isEmpty && (
        <tr className={classes.emptyWrap}>
          <Empty position="center" className={classes.empty} />
        </tr>
      )}
      {isVisible && (
        <DragAndDrop
          list={questionnaires}
          module="ConsultationList"
          editUrl={handleEdit}
        />
      )}
      {hasNextPage && (
        <tr className={classes.loadMoreWrap}>
          <LoadMoreObserver
            className={classes.loadMoreObs}
            noPaddingBottom
            loadMore={fetchNextPage}
            isLoading={isFetchingNextPage}
          />
        </tr>
      )}
    </>
  );
}

List.propTypes = {
  searchTerm: PropTypes.string,
};

List.defaultProps = {
  searchTerm: null,
};
