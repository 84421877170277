/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router";
import InboxSidebar from "../InboxSidebar.js";
import Loader from "../../Common/Loader.js";
import { autoScrolling } from "../../../Utils/services.js";
import picClose from "../../../_legacy/images/popupClose.png";
import {
  getSMSTemplateData,
  exportEmptyData,
  addNewSMSTemplate,
  getDataViaId,
  deleteSMSID,
} from "../../../Actions/Inbox/inboxAction";
import ComingSoon from "../../Common/ComingSoon.js";

class SmsTemplate extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      showCreateOptions: false,
      showTableSection: true,
      smsTemplateList: [],
      page: 1,
      pagesize: 20,
      next_page_url: "",
      loadMore: true,
      startFresh: true,
      showLoader: false,
      datetime: "",
      title_name: "",
      text_message: "",
      titleError: false,
      smsId: "",
      edit: false,
      smsData: {},
      deletePopup: false,
    };
    window.onscroll = () => {
      return false;
    };
  }

  render() {
    return <ComingSoon sidebar={"inbox"} />;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.smsTemplateList != undefined &&
      nextProps.datetime != prevState.datetime
    ) {
      returnState.showLoader = false;
      returnState.showCreateOptions = false;
      returnState.deletePopup = false;
      if (nextProps.smsTemplateList.next_page_url != null) {
        returnState.page = prevState.page + 1;
      } else {
        returnState.next_page_url = nextProps.smsTemplateList.next_page_url;
      }
      if (prevState.smsTemplateList.length == 0) {
        returnState.smsTemplateList = nextProps.smsTemplateList.data;
      } else {
        returnState.smsTemplateList = [
          ...prevState.smsTemplateList,
          ...nextProps.smsTemplateList.data,
        ];
      }
      returnState.datetime = nextProps.datetime;
      autoScrolling(false);
      nextProps.exportEmptyData();
    }

    if (
      nextProps.smsData != undefined &&
      nextProps.datetime != prevState.datetime
    ) {
      returnState.smsId = nextProps.smsData.id;
      returnState.title_name = nextProps.smsData.title;
      returnState.text_message = nextProps.smsData.message;
      returnState.showCreateOptions = true;
      returnState.titleError = false;
      returnState.edit = true;
      returnState.showLoader = false;
      returnState.datetime = nextProps.datetime;
    }
    return returnState;
  }

  redirectToCampaign = () => {
    return <div>{this.props.history.push(`/inbox/smscampaign`)}</div>;
  };

  loadMore = () => {
    if (!autoScrolling()) {
      this.setState({ showLoader: true, showLoadingText: true });
      let formData = {
        page: this.state.page,
        pagesize: this.state.pagesize,
      };
      autoScrolling(true);
      this.props.getSMSTemplateData(formData);
    }
  };

  addTemplate = () => {
    if (
      this.state.title_name == "" ||
      this.state.title_name == null ||
      this.state.title_name == undefined
    ) {
      this.setState({ titleError: true });
      return;
    }

    let formData = {
      message: this.state.text_message,
      title: this.state.title_name,
    };
    if (this.state.edit == true && this.state.smsId) {
      formData.sms_template_id = this.state.smsId;
    }
    let empty_array = [];
    this.setState(
      {
        smsTemplateList: empty_array,
        page: 1,
        pagesize: 20,
        next_page_url: "",
        showLoader: true,
      },
      () => {
        this.props.addNewSMSTemplate(formData);
      },
    );
  };

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  openModal = () => {
    this.setState({
      edit: false,
      title_name: "",
      text_message: "",
      titleError: false,
      showCreateOptions: true,
    });
  };

  getDataViaId = (id) => {
    this.setState({ showLoader: true });
    this.props.getDataViaId(id);
  };

  dismissModal = () => {
    this.setState({ showCreateOptions: false });
  };

  deleteSMSTemplatePopup = (id) => {
    this.setState({ deleteId: id, deletePopup: true });
  };

  dismissDeleteModal = () => {
    this.setState({ deletePopup: false });
  };

  deleteSMSID = () => {
    let empty_array = [];
    this.setState(
      {
        showLoader: true,
        smsTemplateList: empty_array,
        page: 1,
        pagesize: 20,
        next_page_url: "",
      },
      () => {
        this.props.deleteSMSID(this.state.deleteId);
      },
    );
  };

  render1() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <InboxSidebar />

          <div className="memberWalletOuter business-section">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                {this.state.globalLang.label_sms_template}
                <div className="memberRightActions">
                  <a className="new-blue-btn" onClick={this.openModal}>
                    {this.state.globalLang.create_template}
                  </a>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.smsTemplateList.length > 0
                  ? "setting-setion m-b-15"
                  : "setting-setion m-b-15 no-display"
              }
            >
              <div className="table-responsive clients-table no-border">
                <table className="table-updated  table-min-width no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      <th className="table-updated-th">
                        {this.state.globalLang.global_title}
                      </th>
                      <th className="table-updated-th">
                        {this.state.globalLang.label_text_message}
                      </th>
                      <th className="table-updated-th"></th>
                    </tr>
                  </thead>
                  <tbody className="ajax_body">
                    {this.state.smsTemplateList.length > 0
                      ? this.state.smsTemplateList.map((data, index) => {
                          return (
                            <tr
                              key={"template -" + index}
                              className="table-updated-tr"
                            >
                              <td className={"table-updated-td break-all"}>
                                {data.title}
                              </td>
                              <td className={"table-updated-td break-all"}>
                                {data.message}
                              </td>
                              <td className="col-xs-3 table-updated-td text-right no-padding">
                                <a
                                  className="easy-link no-padding m-r-20"
                                  onClick={this.getDataViaId.bind(
                                    this,
                                    data.id,
                                  )}
                                >
                                  <i className="fa fa-pencil-alt m-r-5" />
                                  {this.state.globalLang.label_edit}
                                </a>
                                <a
                                  className="easy-link no-padding m-r-15"
                                  onClick={this.deleteSMSTemplatePopup.bind(
                                    this,
                                    data.id,
                                  )}
                                >
                                  <i className="fa fa-trash-alt m-r-5" />
                                  {this.state.globalLang.label_delete}
                                </a>
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className={
                this.state.smsTemplateList.length == 0
                  ? "not-create-report"
                  : "not-create-report no-display"
              }
            >
              <h3>{this.state.globalLang.no_template_created}</h3>
              <a className="new-blue-btn m-t-25" onClick={this.openModal}>
                {this.state.globalLang.create_template}
              </a>
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showCreateOptions
              ? "blackOverlay"
              : "blackOverlay no-display"
          }
        >
          <div className="vert-middle vert-middle-report-option">
            <div className="white-popup small-popup">
              <div className="white-popup-wrapper">
                <div className="membershipTypeTitle">
                  {this.state.edit == false
                    ? this.state.globalLang.create_template
                    : this.state.globalLang.edit_template}
                  <a onClick={this.dismissModal} className="popupClose">
                    <img alt="" src={picClose} />
                  </a>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.global_title}
                        <span className="setting-require">*</span>
                      </div>
                      <input
                        className={
                          this.state.titleError
                            ? "newInputField field_error"
                            : "newInputField"
                        }
                        name="title_name"
                        value={
                          this.state.title_name ? this.state.title_name : ""
                        }
                        onChange={this.handleInputChange}
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="col-xs-12">
                    <div className="newInputFileldOuter">
                      <div className="newInputLabel">
                        {this.state.globalLang.label_text_message}
                      </div>
                      <textarea
                        className="newtextareaField"
                        name="text_message"
                        value={
                          this.state.text_message ? this.state.text_message : ""
                        }
                        onChange={this.handleInputChange}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={this.addTemplate}
                >
                  {this.state.globalLang.label_save}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={this.dismissModal}
                >
                  {this.state.globalLang.label_cancel}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          id="filterModal"
          role="dialog"
          className={
            this.state.deletePopup ? "blackOverlay" : "blackOverlay no-display"
          }
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  onClick={this.dismissDeleteModal}
                >
                  ×
                </button>
                <h4 className="modal-title" id="model_title">
                  {this.state.globalLang.delete_template}
                </h4>
              </div>
              <div
                id="errorwindow"
                className="modal-body add-patient-form filter-patient"
              >
                {this.state.globalLang.confirm_delete_sms_template}
              </div>
              <div className="modal-footer">
                <div className="col-md-12 text-left" id="footer-btn">
                  <button
                    type="button"
                    className="btn  logout pull-right"
                    data-dismiss="modal"
                    onClick={this.dismissDeleteModal}
                  >
                    {this.state.settingsLang.no_option}
                  </button>
                  <button
                    type="button"
                    className="btn btn-success pull-right m-r-10"
                    data-dismiss="modal"
                    onClick={this.deleteSMSID}
                  >
                    {this.state.settingsLang.yes_option}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  let returnState = {};
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  if (state.InboxReducer.action == "GET_SMS_TEMPLATE_LIST") {
    if (
      state.InboxReducer.data.status == 200 ||
      state.InboxReducer.data.status == 201
    ) {
      returnState.smsTemplateList = state.InboxReducer.data.data;
      returnState.datetime = new Date();
    } else {
      returnState.showLoader = false;
    }
  }

  if (state.InboxReducer.action == "ADD_NEW_SMS_TEMPLATE") {
    if (
      state.InboxReducer.data.status == 200 ||
      state.InboxReducer.data.status == 201
    ) {
      toast.success(languageData.global[state.InboxReducer.data.message]);
      returnState.showLoader = false;
      returnState.smsTemplateList = state.InboxReducer.data.data;
      returnState.closePopup = true;
      returnState.datetime = new Date();
    } else {
      returnState.showLoader = false;
      returnState.closePopup = false;
      toast.error(languageData.global[state.InboxReducer.data.message]);
      returnState.datetime = new Date();
    }
  }

  if (state.InboxReducer.action == "GET_SMS_TEMPLATE_BY_ID") {
    if (
      state.InboxReducer.data.status == 200 ||
      state.InboxReducer.data.status == 201
    ) {
      returnState.smsData = state.InboxReducer.data.data;
      returnState.datetime = new Date();
    } else {
      returnState.showLoader = false;
      toast.error(languageData.global[state.InboxReducer.data.message]);
      returnState.datetime = new Date();
    }
  }

  if (state.InboxReducer.action == "DELETE_SMS") {
    if (
      state.InboxReducer.data.status == 200 ||
      state.InboxReducer.data.status == 201
    ) {
      toast.success(languageData.global[state.InboxReducer.data.message]);
      returnState.showLoader = false;
      returnState.smsTemplateList = state.InboxReducer.data.data;
      returnState.closePopup = true;
      returnState.datetime = new Date();
    } else {
      returnState.showLoader = false;
      returnState.closePopup = false;
      toast.error(languageData.global[state.InboxReducer.data.message]);
      returnState.datetime = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSMSTemplateData: getSMSTemplateData,
      exportEmptyData: exportEmptyData,
      addNewSMSTemplate: addNewSMSTemplate,
      getDataViaId: getDataViaId,
      deleteSMSID: deleteSMSID,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SmsTemplate));
