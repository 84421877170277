import axios from "axios";

export function UserResetPasswordForm(userData) {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "password/email", userData)
      .then((response) => {
        dispatch({
          type: "FORGOT_PASSWORD_SUCCESSFULL",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FORGOT_PASSWORD_ERROR",
          payload: error.response.data,
        });
      });
  };
}
