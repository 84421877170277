import Axios from "axios";
import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import Loader from "../../Common/Loader";

const StripeCompletedPayment = (props) => {
  const invoiceId = props.match.params.invoiceId;
  const type = props.match.params.type;

  const redirectTo = {
    checkout: `/sales/checkout-invoice/${invoiceId}/stripe-payment-success/`,
  };

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_URL}stripe/update-invoice-to-pending`,
      { invoice_id: invoiceId },
    )
      .then(() => (window.location.href = redirectTo[type]))
      .catch((error) => {
        toast.error(error.response.data.message);
        setTimeout(() => (window.location.href = "/"), 2000);
      });
  }, []);

  return (
    <>
      <Loader showLoader={true} isFullWidth={false} />
      <ToastContainer
        position="bottom-left"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </>
  );
};

export default StripeCompletedPayment;
