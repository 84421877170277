import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader';
import {
  getActiveCampaignData,
  shareIntegrationActiveCampaignData,
  exportEmptyData
} from '../../../Actions/Integrations/integrationActions.js';
import { getIsAcceleratorPlan, positionFooterCorrectly } from '../../../Utils/services';
import validator from 'validator';
import UpgradeAccountModal from '../../Common/UpgradeAccountModal';

class ActiveCampaign extends Component {
  constructor (props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      sync: false,
      isEditSyncSettings: false,
      syncData: null,
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      api_key: '',
      api_url: '',
      all_patient: false,
      newly_created: false,
      showLoader: false,
      editClicked: false,
      touch_md_sync_type: 'new',
      account_id: '',
      id: '',
      touch_md_sync_all_status: '',
      api_keyClass: 'newInputField',
      api_urlClass: 'newInputField',

      isShowUpgradeAlertModal: !this.isIntegrationToggleEnabled(),
    };
  }

  componentDidMount () {
    if (this.isIntegrationToggleEnabled()) {
      this.setState({ showLoader: true });
      this.props.getActiveCampaignData();
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let returnState = {};

    if (nextProps.invokeRedirect) {
      nextProps.history.push('/settings/profile');
    }

    if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      nextProps.exportEmptyData();
      positionFooterCorrectly();
    }
    if (nextProps.syncData != undefined && nextProps.syncData != prevState.syncData) {
      returnState.showLoader = false;
      returnState.isEditSyncSettings = true;
      returnState.syncData = nextProps.syncData;
      if (nextProps.syncData.auth_data) {
        returnState.all_patient = nextProps.syncData.auth_data.sync_all == 1 ? true : false;
        returnState.newly_created = nextProps.syncData.auth_data.sync_new == 1 ? true : false;
        returnState.sync = nextProps.syncData.auth_data.is_enabled == 1 ? true : false;
        returnState.account_id = nextProps.syncData.auth_data.account_id;
        returnState.api_key = nextProps.syncData.auth_data.access_token;
        returnState.api_url = nextProps.syncData.auth_data.url;
        returnState.id = nextProps.syncData.auth_data.id;
        returnState.auth = nextProps.syncData.auth_data.auth == 0 ? false : true;
      }
    }

    if (nextProps.updatedSyncData != undefined && nextProps.updatedSyncData != prevState.updatedSyncData) {
      returnState.showLoader = false;
      returnState.isEditSyncSettings = true;
      returnState.updatedSyncData = nextProps.updatedSyncData;
      returnState.all_patient = nextProps.updatedSyncData.sync_all == 1 ? true : false;
      returnState.newly_created = nextProps.updatedSyncData.sync_new == 1 ? true : false;
      returnState.sync = nextProps.updatedSyncData.is_enabled == 1 ? true : false;
      returnState.account_id = nextProps.updatedSyncData.account_id;
      returnState.api_key = nextProps.updatedSyncData.access_token;
      returnState.api_url = nextProps.updatedSyncData.url;
      returnState.id = nextProps.updatedSyncData.id;
      returnState.auth = nextProps.updatedSyncData.auth == 0 ? false : true;
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [event.target.name]: value
    });
    if (target.type === 'checkbox' && value == true && event.target.name == 'sync') {
      this.setState({
        isEditSyncSettings: true,
        all_patient: false,
        newly_created: false,
        api_key: '',
        api_keyClass: 'newInputField',
        api_url: '',
        api_urlClass: 'newInputField'
      });
    }
    if (target.type === 'checkbox' && value == false && event.target.name == 'sync') {
      if (this.state.id && (this.state.api_key || this.state.api_url)) {
        this.setState({ showLoader: true });
        let formData = {
          is_enabled: 0,
          code: '',
          url: '',
          sync_type: 'all',
          sync_all: 0,
          sync_new: 0
        };
        this.props.shareIntegrationActiveCampaignData(formData);
      }
    }
  };

  cancelTask = () => {
    this.setState({ showLoader: true });
    this.props.getActiveCampaignData();

  };
  selectedAllPatients = () => {
    this.setState({
      all_patient: true,
      newly_created: false
    });
  };
  selectedNewlyClicked = () => {
    this.setState({
      all_patient: false,
      newly_created: true
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let error = false;
    if (this.state.api_key == '' || this.state.api_key == null || this.state.api_key == undefined) {
      this.setState({ api_keyClass: 'newInputField field_error' });
      error = true;
    } else if (this.state.api_key) {
      this.setState({ api_keyClass: 'newInputField' });
    }

    if (this.state.api_url == '' || this.state.api_url == null || this.state.api_url == undefined || !validator.isURL(this.state.api_url, { protocols: ['https'] })) {
      this.setState({ api_urlClass: 'newInputField field_error' });
      error = true;
    } else if (this.state.api_url) {
      this.setState({ api_urlClass: 'newInputField' });
    }
    if (error) {
      return false;
    }
    let formData = {
      is_enabled: this.state.sync == true ? 1 : 0,
      code: this.state.api_key,
      url: this.state.api_url,
      sync_all: this.state.all_patient == true ? 1 : 0,
      sync_new: this.state.newly_created == true ? 1 : 0
    };
    this.setState({ showLoader: true });
    this.props.shareIntegrationActiveCampaignData(formData);
  };

  handleEditSync = () => {
    this.setState({ showLoader: true, api_keyClass: 'newInputField', api_urlClass: 'newInputField' });
    this.props.shareIntegrationActiveCampaignData();
  };

  handleShowUpgradeAlertModal = (state) => {
    this.setState({ isShowUpgradeAlertModal: state.isShowUpgradeAlertModal });
  };

  isIntegrationToggleEnabled = () => getIsAcceleratorPlan();

  render () {
    return (
      <div id="content">
        <UpgradeAccountModal notificaionType={'integration'}
                             isShowUpgradeAlertModal={this.state.isShowUpgradeAlertModal}
                             handleShowUpgradeAlertModal={this.handleShowUpgradeAlertModal}/>
        <div className="container-fluid content setting-wrapper">
          <Sidebar/>
          {/* ****SYNC HEADING****** */}
          <div className="memberWalletOuter relative">
            <div className="setting-setion m-b-10">
              <div className="membership-title">{this.state.settingsLang.label_sync_active_campaign}
                <div className="memberRightActions">
                  <span className="switch-text"></span>
                  <label className="setting-switch pull-right">
                    <input type="checkbox" name="sync" id="sync"
                           disabled={!this.isIntegrationToggleEnabled()}
                           checked={this.state.sync && this.isIntegrationToggleEnabled()}
                           onChange={this.handleInputChange}/>
                    <span className="setting-slider"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              {(this.state.sync === true && this.isIntegrationToggleEnabled()) &&
                <div className="col-sm-12">
                  <div className="setting-setion">
                    <div className="membershipSectionTitle">{this.state.settingsLang.label_sync_settings}
                      {(this.state.isEditSyncSettings === false) &&
                        <a onClick={this.handleEditSync} className="easy-link pull-right no-padding"><i
                          className="fa fa-pencil-alt m-r-5"/>{this.state.globalLang.label_edit}</a>}
                    </div>
                    {(this.state.isEditSyncSettings === false) &&
                      <div className="wallet-view-block">
                        <div className="membershipSectionContent">
                          <div><b>{this.state.settingsLang.label_API_Key} : </b> {this.state.api_key}</div>
                          <div className="m-t-20"><b><u>{this.state.settingsLang.label_type_of_sync}</u></b></div>
                          <div className="m-t-10"><b>All Patients
                            : </b>{this.state.all_patient == true ? 'Yes' : 'No'} {this.state.all_patient == true &&
                            <span> (Sync Status : {this.state.touch_md_sync_all_status == 'pending' ? <span
                              className="colorRed">In Progress</span> : <span
                              className="colorGreen">Completed</span>} )</span>}</div>
                          <div className="m-t-10"><b>Newly Created
                            : </b>{this.state.newly_created == true ? 'Yes' : 'No'}</div>
                        </div>
                      </div>
                    }
                    {(this.state.isEditSyncSettings === true) &&
                      <div className="wallet-edit-block">
                        <div className="membershipSectionContent p5">
                          <div className="row">
                            <div className="col-lg-6 col-xs-12 m-b-15">
                              <div className="col-xs-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">{'URL'} <span className="setting-require">*</span>
                                  </div>
                                  <div className="setting-input-outer">
                                    <input name="api_url" className={this.state.api_urlClass} type="text"
                                           value={this.state.api_url} onChange={this.handleInputChange}
                                           autoComplete="off"/>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">{this.state.settingsLang.label_API_Key} <span
                                    className="setting-require">*</span></div>
                                  <div className="setting-input-outer">
                                    <input name="api_key" className={this.state.api_keyClass} type="text"
                                           value={this.state.api_key} onChange={this.handleInputChange}
                                           autoComplete="off"/>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 m-t-15">
                                <p className="p-text no-margin">{this.state.settingsLang.label_whom_to_sync} <span
                                  className="setting-require">*</span></p>
                              </div>
                              <div className="col-xs-12">
                                <div className="setting-custom-switch product-active pull-left m-t-15">

                                  <label className="setting-switch pull-left no-margin"><input type="checkbox"
                                                                                               className="setting-custom-switch-input"
                                                                                               name="all_patient"
                                                                                               autoComplete="off"
                                                                                               checked={this.state.all_patient ? 'checked' : false}
                                                                                               onChange={this.handleInputChange}
                                                                                               value={this.state.all_patient}/>
                                    <span className="setting-slider"></span>
                                  </label>
                                  <span id="membership_lable"
                                        className="m-l-15">{this.state.settingsLang.label_all_patients}</span>
                                </div>
                              </div>
                              <div className="col-xs-12">

                                <div className="setting-custom-switch product-active pull-left m-t-15">

                                  <label className="setting-switch pull-left no-margin"><input type="checkbox"
                                                                                               className="setting-custom-switch-input"
                                                                                               name="newly_created"
                                                                                               autoComplete="off"
                                                                                               checked={this.state.newly_created ? 'checked' : false}
                                                                                               onChange={this.handleInputChange}
                                                                                               value={this.state.newly_created}/>
                                    <span className="setting-slider"></span>
                                  </label>
                                  <span id="membership_lable"
                                        className="m-l-15">{this.state.settingsLang.label_newly_created}</span>
                                </div>
                              </div>

                            </div>
                            <div className="col-lg-6 col-xs-12 m-t-15">
                              <div className="col-xs-12">
                                <div className="guidanceDetail">
                                  <div className="juvly-subtitle m-b-10">How to get URL and API Key?</div>
                                  <ul className="guidanceDetailSteps">
                                    <li>Login into <a className="easy-link no-padding"
                                                      href="https://www.activecampaign.com/login/" target="_blank">Active
                                      Campaign</a></li>
                                    <li>Go to Settings -> Developer</li>
                                    <li>From API Access section find URL and API Key</li>
                                  </ul>

                                </div>
                              </div>
                            </div>
                          </div>


                        </div>
                        <div className="footer-static">
                          <button className="new-blue-btn pull-right"
                                  onClick={this.handleSubmit}>{this.state.globalLang.label_save}</button>
                          {/*<button className="new-white-btn pull-right" onClick={this.cancelTask}>{this.state.globalLang.label_cancel}</button>*/}
                        </div>
                      </div>
                    }

                  </div>

                </div>}
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true}/>
        </div>
      </div>
    );
  }
}

function mapStateToProps (state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));

  const returnState = {};
  if (state.IntegrationReducer.data.status === 402) {
    returnState.invokeRedirect = true;
  }

  if (state.IntegrationReducer.action === 'GET_ACTIVE_COMPAIGN_DATA') {
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.syncData = state.IntegrationReducer.data.data;
    } else {
      toast.dismiss();
      returnState.showLoader = false;
      toast.error(languageData.global[state.IntegrationReducer.data.message]);
    }
  }

  if (state.IntegrationReducer.action === 'SHARE_ACTIVE_COMPAIGN_DATA') {
    toast.dismiss();
    if (state.IntegrationReducer.data.status == 200 || state.IntegrationReducer.data.status == 201) {
      returnState.updatedSyncData = state.IntegrationReducer.data.data;
      toast.success(languageData.global[state.IntegrationReducer.data.message]);
    } else {
      returnState.showLoader = false;
      if (state.IntegrationReducer.data.message === 'third_party_error') {
        toast.error(state.IntegrationReducer.data.data);
      } else {
        toast.error(languageData.global[state.IntegrationReducer.data.message]);
      }

    }
  }
  return returnState;
}

function mapDispatchToProps (dispatch) {
  return bindActionCreators({
    getActiveCampaignData: getActiveCampaignData,
    shareIntegrationActiveCampaignData: shareIntegrationActiveCampaignData,
    exportEmptyData: exportEmptyData
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActiveCampaign);
