import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from 'react-router';
import { ToastContainer, toast } from "react-toastify";
import { displayName, capitalizeFirstLetter } from '../../../Utils/services';
import { fetchDocumentHealthQuestionnaire, getDocumentProcedureHealthQuestionnaire } from '../../../Actions/Clients/clientsAction';
import defLogo from '../../../_legacy/images/upload.png';
class DocumentHealthQuestionnaires extends Component{
constructor(props){
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.state = {
        clientID      : this.props.match.params.clientID ? this.props.match.params.clientID : '',
        procedureID   : this.props.match.params.procedureID ? this.props.match.params.procedureID : '',
        appointmentID : this.props.match.params.appointmentID ? this.props.match.params.appointmentID : '',
        globalLang            : languageData.global,
        languageData          : languageData.procedure,
        type          : this.props.match.params.type ? this.props.match.params.type : ''
    };
    window.onscroll = () => {   
      return false
    }
}
componentDidMount(){
    this.setState({ showLoader : true });
    if(this.state.type == 'appointment'){
      let formData = {
        procedure_template_id : this.state.procedureID,
        appointment_id        : this.state.appointmentID,
        patient_id            : this.state.clientID,
    }
    this.props.fetchDocumentHealthQuestionnaire(formData);
    } else {
      let formData = {
        questionnaire_id: this.state.appointmentID,
        procedure_template_id: this.state.procedureID, 
        patient_id: this.state.clientID
      }
      this.props.getDocumentProcedureHealthQuestionnaire(formData)
    }
   
}

static getDerivedStateFromProps(nextProps,prevState){
    let returnState = {};
    if(nextProps.appointmentQuestionnaire != undefined && nextProps.appointmentQuestionnaireDataTime != prevState.appointmentQuestionnaireDataTime){
        returnState.questions = nextProps.appointmentQuestionnaire.procedure_template_question;
        returnState.appointmentQuestionnaireDataTime = nextProps.appointmentQuestionnaireDataTime;
        returnState.questionnaire_name = nextProps.appointmentQuestionnaire.title
        returnState.showLoader = false;
    }

    if(nextProps.appointmentProcedureQuestionnaire != undefined && nextProps.appointmentProcedureQuestionnaireDataTime != prevState.appointmentProcedureQuestionnaireDataTime){
      let questions = nextProps.appointmentProcedureQuestionnaire.data[0].procedure_template_question;
      let answers = nextProps.appointmentProcedureQuestionnaire.data[0].answers;
      let finalArray = [];
      questions && questions.length > 0 && questions.map((data,index) =>{
        let temp = {};
        let check = answers.find(y => y.question_id == data.id);
      
          temp = {
            answer : check ? check.answer : null,
            created: data.created,
            description: data.description,
            description_text: data.description_text,
            id: data.id, 
            modified: data.modified,
            order_by: data.order_by,
            procedure_template_id: data.procedure_template_id,
            procedure_template_question_option: data.procedure_template_question_option,
            procedure_templates_logic: data.procedure_templates_logic,
            question: data.question,
            question_type: data.question_type,
            required: data.required,
            status: data.status,
            why_choose: data.why_choose 
          }
          if(temp.question_type == 'File Upload' && temp.answer != null){
            let key = temp.answer.split('.');
            key = key[key.length - 1];
            temp.file_type = key;
          }
          if(temp.question_type == 'File Upload'){
            temp.answer = nextProps.appointmentProcedureQuestionnaire.image_base_url + '/' + temp.answer;          }
          finalArray.push(temp);
          

      })
          returnState.questions = finalArray;
          returnState.appointmentProcedureQuestionnaireDataTime = nextProps.appointmentProcedureQuestionnaireDataTime;
          returnState.showLoader = false;
          returnState.questionnaire_name = nextProps.appointmentProcedureQuestionnaire.all_questionnaires[0].title;

    }
    return returnState;
}

viewUploadedQuestionnaireFile = (downloadPath, event) => {
    if(downloadPath){
      window.open(downloadPath,'_blank');
    }
  }
render(){
    return(
        <div id="content">
         <div className="container-fluid content setting-wrapper">
            <div className="juvly-section full-width m-t-15">
               <div className="juvly-container">
               <div className="juvly-title m-b-30">{"HEALTH QUESTIONNAIRE"}
                     <a onClick={() => this.props.history.goBack()} className="pull-right"><img className="pull-right m-t-5" src="/images/close.png"/></a>
                     </div>
                  
                     <div className="accordion questionnaireAccordion" id="accordionExample">
                      <div key={"questionnaireList"} className='membershipAccordion'>
                        <div className="membershipAccordionHead" id="headingOne">
                          <h2 className="mb-0">
                            <button className="btn btn-link cursor-default" type="button" data-toggle={'colla[se'}  data-target={'questionnaire'} aria-expanded='false' aria-controls="collapseOne">
                                <span>{this.state.questionnaire_name ? this.state.questionnaire_name : ''} </span>
                               
                            </button>
                          </h2>
                        </div>
                        <div id={'questionnaire'} className={'collapse show'} aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div className={"proQuesAccordion"}>
                            { /* HEALTH TIMELINE QUESTIONNAIRE - APPOINTMENT START */}
                            
                              <div>

                                {this.state.questions && this.state.questions.length > 0 && this.state.questions.map((data,index)=> {
                                    return(<div key={'templateQuestionList-' + data.id} className="survey-qus-ans">
                                    <div className="survey-ques"><span className="que-label">{this.state.languageData.pro_que}</span> {data.question}</div>
                                    {data.question_type!='File Upload' && <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>{(data.answer) ? data.answer : ' '}&nbsp;</div>}
                                    {data.question_type=='File Upload' && 
                                              <div className="survey-ans"><span className="ans-label">{this.state.languageData.pro_ans}</span>

            <div className="row add-doc-section">
              <div className="col-xs-6 m-b-20">
                <div className={"file-container file-upload-img"} title={(data.answer) ? data.answer : ''}>
                  {(data.answer) ? 
                    <img
                      className={"full-custom-img cursor-pointer"}
                      src={(data.file_type && (data.file_type == 'jpg' || data.file_type == 'png' || data.file_type == 'jpeg')) ? data.answer : defLogo}
                      onClick={this.viewUploadedQuestionnaireFile.bind(this,data.answer)}
                    />
                    :
                    <img
                      className={""}
                      src={defLogo}
                    />
                  }
                  <span onClick={this.viewUploadedQuestionnaireFile.bind(this,data.answer)} className={(data.answer) ? "file-name file-info cursor-pointer" : "file-name-hide no-display"}> {data.answer}</span>
                </div>
              </div>
            </div>
          </div>}

            
                                  </div>
                                )})}

                              </div>
                            
                            { /* HEALTH TIMELINE QUESTIONNAIRE APPOINTMENT - END */}

                          </div>
                        </div>
                      </div>
                    
                  
               </div>
            </div>
         </div>
         <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
           <div className="loader-outer">
             <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
             <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
           </div>
         </div>
      </div>
      </div>
    )
}
}
function mapStateToProps(state) {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    const returnState = {};
    if(state.ClientsReducer.action === 'GET_APPOINTMENT_HEALTH_QUESTIONNAIRE'){
        if(state.ClientsReducer.data.status === 200){
            returnState.appointmentQuestionnaire = state.ClientsReducer.data.data;
            returnState.appointmentQuestionnaireDataTime = new Date();
        } else {
            toast.dismiss();
            toast.error(languageData.global[state.ClientsReducer.data.message]);
        }
    }

    if(state.ClientsReducer.action === 'GET_PROCEDURE_HEALTH_QUESTIONNAIRE'){
      if(state.ClientsReducer.data.status === 200){
          returnState.appointmentProcedureQuestionnaire = state.ClientsReducer.data.data;
          returnState.appointmentProcedureQuestionnaireDataTime = new Date();
      } else {
          toast.dismiss();
          toast.error(languageData.global[state.ClientsReducer.data.message]);
      }
  }
    return returnState;
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchDocumentHealthQuestionnaire : fetchDocumentHealthQuestionnaire,
        getDocumentProcedureHealthQuestionnaire : getDocumentProcedureHealthQuestionnaire
     }, dispatch)
  }
  export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DocumentHealthQuestionnaires));
