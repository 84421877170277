/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import Header from "../../_legacy/Containers/Guest/Header.js";
import Footer from "../../_legacy/Containers/Guest/Footer.js";
import {
  accountReset,
  upgradeRecurlyToStripe,
} from "../../Actions/Accounts/accountAction.js";
import HippaPolicyRecurly from "../../Components/Policies/HippaPolicyRecurly.js";
import {
  setToken,
  setRedirectTo,
  getRedirectTo,
  getToken,
} from "../../Utils/services.js";
import { switchWorkspaces } from "../../Actions/Settings/settingsActions.js";
import { getSetupIntentForAccount } from "../../Actions/Stripe/StripeActions";
import Loader from "../Common/Loader.js";
import StripePaymentForm from "../Sales/FrontdeskCheckout/StripePaymentForm";

class RecurlyToStripe extends React.Component {
  constructor(props) {
    super(props);

    let tempLoggedUserData = localStorage.getItem("tempLoggedUserData")
      ? JSON.parse(localStorage.getItem("tempLoggedUserData"))
      : {};

    this.state = {
      globalLang: {},
      isShowBba: false,
      agree_checkbox: false,
      isHppaPolicyAccepted: false,
      isBbaPolicyAccepted: false,
      tempLoggedUserData: {},

      stripeToken: "",
      setup_intent_id: "",
      processingLoder: false,
      skip_upgrade: 0,
      stripe_inactive: 0,
      isShowCardInfo: true,
      isShowHippa: false,
      loginData: {},
      privilegeData: {},
      workspaces:
        tempLoggedUserData.userData &&
        tempLoggedUserData.userData.workspaces &&
        tempLoggedUserData.userData.workspaces.length > 0
          ? tempLoggedUserData.userData.workspaces
          : [],
      selected_workspace: "",
      no_reminder: false,
      defaultWorkspace:
        tempLoggedUserData.userData &&
        tempLoggedUserData.userData.workspaces &&
        tempLoggedUserData.userData.workspaces.length > 0
          ? tempLoggedUserData.userData.workspaces.find(
              (y) => y.is_default_web == 1,
            )
          : "",
    };
  }

  componentDidMount() {
    let tempLoggedUserData = {};

    if (localStorage.getItem("tempLoggedUserData")) {
      tempLoggedUserData = JSON.parse(
        localStorage.getItem("tempLoggedUserData"),
      );
      if (tempLoggedUserData.userData != undefined) {
        if (tempLoggedUserData.userData.skip_upgrade != undefined) {
          this.setState({
            skip_upgrade: tempLoggedUserData.userData.skip_upgrade,
          });
        }
        if (tempLoggedUserData.userData.stripe_inactive != undefined) {
          this.setState({
            stripe_inactive: tempLoggedUserData.userData.stripe_inactive,
          });
        }
        if (
          tempLoggedUserData.userData.redirect_to != undefined &&
          tempLoggedUserData.userData.redirect_to !==
            "upgrade-account-to-stripe"
        ) {
          this.redirectToLogin();
        }
      }
    } else {
      this.redirectToLogin();
    }

    this.setState({ tempLoggedUserData: tempLoggedUserData });

    let langData = {};

    if (localStorage.getItem("languageData")) {
      langData = JSON.parse(localStorage.getItem("languageData"));
    }

    if (!langData || langData.global === undefined || !langData.global) {
      axios
        .get(process.env.REACT_APP_API_URL + `getLanguageText/1/global`)
        .then((res) => {
          const languageData = res.data.data;
          localStorage.setItem("languageData", JSON.stringify(languageData));
          this.setState({ globalLang: languageData.global });
        })
        .catch(function () {});
    } else {
      const languageData = JSON.parse(localStorage.getItem("languageData"));
      this.setState({ globalLang: languageData.global });
    }

    this.props.getSetupIntentForAccount();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.processingLoder != undefined &&
      nextProps.processingLoder == false
    ) {
      returnState.processingLoder = false;
    } else if (
      nextProps.loginData != undefined &&
      Object.keys(nextProps.loginData).length > 0 &&
      nextProps.loginData !== prevState.loginData
    ) {
      toast.dismiss();
      toast.success(prevState.globalLang[nextProps.message]);
      returnState.loginData = nextProps.loginData;
      setRedirectTo(nextProps.loginData.redirect_to);

      if (
        nextProps.loginData.is_bba_signed != undefined &&
        nextProps.loginData.is_bba_signed == 1
      ) {
        localStorage.setItem(
          "currentUserRole",
          nextProps.loginData.user.user_role_id,
        );
        localStorage.setItem("userData", JSON.stringify(nextProps.loginData));
        localStorage.setItem("isLoggedIn", 1);
        localStorage.setItem(
          "user_listing_settings",
          JSON.stringify(nextProps.loginData.user_listing_settings),
        );
        localStorage.setItem(
          "languageData",
          JSON.stringify(nextProps.loginData.languageData),
        );
        localStorage.setItem(
          "globalPrivileges",
          JSON.stringify(nextProps.loginData.globalPrivileges),
        );
        localStorage.removeItem("tempLoggedUserData");
        nextProps.history.push(getRedirectTo());
      } else {
        setRedirectTo("accept-agreement");
        let tempLoggedUserData = prevState.tempLoggedUserData;
        tempLoggedUserData.userData = nextProps.loginData;
        localStorage.setItem(
          "tempLoggedUserData",
          JSON.stringify(tempLoggedUserData),
        );
        nextProps.accountReset();
        nextProps.history.push(getRedirectTo());
      }
    }

    if (
      nextProps.userDataTime != prevState.userDataTime &&
      nextProps.userData != undefined
    ) {
      returnState.userDataTime = nextProps.userDataTime;
      returnState.workspaces = nextProps.userData.workspaces;
      returnState.user_name =
        nextProps.userData && nextProps.userData.current_wc_full_name
          ? nextProps.userData.current_wc_full_name
          : "";
      returnState.showLoader = false;
      returnState.defaultWorkspace = nextProps.userData.workspaces.find(
        (y) => y.is_default_web == 1,
      );

      if (nextProps.status == 604) {
        nextProps.history.push("/upgrade-account-to-stripe");
      } else if (nextProps.status == 603) {
        nextProps.history.push("/upgrade-subscription-plan");
      } else if (nextProps.status == 602) {
        nextProps.history.push("/accept-agreement");
      } else if (nextProps.status == 605) {
        nextProps.history.push("/two-factor-login");
      } else if (nextProps.status == 200) {
        localStorage.setItem(
          "languageData",
          JSON.stringify(nextProps.userData.languageData),
        );
        localStorage.setItem(
          "globalPrivileges",
          JSON.stringify(nextProps.userData.globalPrivileges),
        );
        localStorage.setItem(
          "need_clinic_city_state",
          nextProps.userData.need_clinic_city_state,
        );
        returnState.need_clinic_city_state =
          nextProps.userData.need_clinic_city_state == true ? "true" : "false";

        if (nextProps.userData.need_clinic_city_state == true) {
          localStorage.setItem(
            "clinic_list",
            JSON.stringify(nextProps.userData.clinic_list),
          );
        }

        localStorage.setItem("cCode", nextProps.userData.country_code);
        localStorage.setItem("current_wc_full_name", returnState.user_name);
        localStorage.setItem("isClockPromptLoginDisplay", 1);
        localStorage.removeItem("isLoggedClockCancelled");
        localStorage.removeItem("clockPromptCancelTime");
        nextProps.history.push(nextProps.redirect_url);
      }
    }

    return returnState;
  }

  getStripeIntent = () => {
    this.setState({ setup_intent_id: "", processingLoder: true });

    toast.dismiss();

    if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
      this.setState({
        setup_intent_id: this.props.stripeIntent.setupIntentId,
        processingLoder: false,
        isShowCardInfo: false,
        isShowHippa: true,
      });
    }
  };

  handleSubmit = () => {
    if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
      this.setState({ processingLoder: true });

      let formData = {
        setup_intent_id: this.props.stripeIntent.setupIntentId,
        return_type: "login",
        stripe_inactive: this.state.stripe_inactive,
      };

      this.props.upgradeRecurlyToStripe(formData);
    }
  };

  redirectToDashboard = () => {
    const tempLoggedUserData = this.state.tempLoggedUserData;

    localStorage.setItem("currentUserRole", tempLoggedUserData.currentUserRole);
    localStorage.setItem(
      "userData",
      JSON.stringify(tempLoggedUserData.userData),
    );
    localStorage.setItem("isLoggedIn", tempLoggedUserData.isLoggedIn);
    localStorage.setItem(
      "user_listing_settings",
      JSON.stringify(tempLoggedUserData.user_listing_settings),
    );

    setToken(tempLoggedUserData.access_token);

    axios
      .get(process.env.REACT_APP_API_URL + `privileges`)
      .then((privilegeResponse) => {
        const priviligeData = privilegeResponse.data.data;
        localStorage.setItem("globalPrivileges", JSON.stringify(priviligeData));
        this.props.history.push("/");
      })
      .catch(function () {});
  };

  redirectToLogin = () => {
    this.props.history.push("/logout");
  };

  handleInputChange = (event) => {
    const target = event.target;
    const name = event.target.name;

    let value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [name]: value,
    });

    if (event.target.name == "selected_workspace") {
      this.switchWorkspace(value);
    }
  };

  switchWorkspace = (value) => {
    this.setState({ showLoader: true });

    let check = this.state.workspaces.find((y) => y.is_default_web == 1);
    let formData = {
      workspace_id: value,
      old_workspace_id: check.id,
      remove_old_workspace: this.state.no_reminder ? 1 : 0,
    };

    this.props.switchWorkspaces(formData);
  };

  render() {
    return (
      <div className="guest">
        <Header />
        <div className="wrapper">
          {/*  Card Details START  */}
          {this.state.workspaces &&
            this.state.workspaces.length == 0 &&
            this.state.isShowHippa == false && (
              <div
                className={
                  this.state.isShowCardInfo ? "card-block" : "card-block hide"
                }
              >
                <div className="login-main  recurly-to-stripe-main sign-up-main">
                  <form>
                    <h1 className="login-title">
                      {this.state.globalLang.update_credit_card_details}
                    </h1>
                    <div className="login-title-description">
                      {this.state.globalLang.update_credit_card_details_notes}
                    </div>
                    {this.props.stripeIntent &&
                      this.props.stripeIntent.setupIntentId && (
                        <StripePaymentForm
                          type="setupIntent"
                          onSubmit={this.getStripeIntent}
                        />
                      )}
                  </form>
                </div>
              </div>
            )}

          {/* ******NEW WORK STARTS********* */}

          {this.state.workspaces &&
            this.state.workspaces.length > 0 &&
            this.state.isShowHippa == false && (
              <div className="login-main  recurly-to-stripe-main sign-up-main creditCardMain">
                <p className="p-text text16px m-b-30 m-t-20">
                  <b>
                    Hello. we noticed you are part of / own more than one
                    Aesthetic Record workspace and one of them is expired.
                  </b>
                </p>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="creditCardSections">
                      <p className="p-text">
                        To reactivate your account{" "}
                        {this.state.defaultWorkspace
                          ? this.state.defaultWorkspace.account.name
                          : ""}
                        , please update your payment information.
                      </p>

                      <div className="p-b-50">
                        {this.props.stripeIntent &&
                          this.props.stripeIntent.setupIntentId && (
                            <>
                              <StripePaymentForm
                                type="setupIntent"
                                onSubmit={this.getStripeIntent}
                              />
                              {this.state.skip_upgrade ? (
                                <div className="col-sm-6 text-left p-t-20">
                                  <button
                                    disabled={this.state.processingLoder}
                                    className="btn btn-danger skip-upgrade p-t-5 p-b-5"
                                    onClick={this.redirectToDashboard}
                                  >
                                    {this.state.globalLang.upgrade_button_skip}
                                  </button>
                                </div>
                              ) : null}
                            </>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="creditCardSections switchTo">
                      <div className="form-group row">
                        <div className="col-sm-12 first-name">
                          <label>Join another workspace</label>
                          <select
                            className="form-control"
                            name="selected_workspace"
                            value={this.state.selected_workspace}
                            onChange={this.handleInputChange}
                          >
                            <option value="">----Select----</option>
                            {this.state.workspaces &&
                              this.state.workspaces.length > 0 &&
                              this.state.workspaces.map((data) => {
                                if (data.is_default_web == 0) {
                                  return (
                                    <option key={data.id} value={data.id}>
                                      {data.account.name}
                                    </option>
                                  );
                                }
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row m-t-10">
                        <div className="col-sm-12 terms text-left">
                          <input
                            type="checkbox"
                            id="UserTos"
                            onChange={this.handleInputChange}
                            className="validate[custom[tosRequired]]"
                            name="no_reminder"
                            value={this.state.no_reminder ? "checked" : false}
                          />{" "}
                          Do not remind me again.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {/*  Card Details END  */}
          {/*  Hippa Policy Block START  */}
          <div
            className={
              this.state.isShowHippa ? "hippa-block" : "hippa-block hide"
            }
          >
            <div className="recurly-to-stripe-main sign-up-main">
              <HippaPolicyRecurly
                globalLang={this.state.globalLang}
                processingLoder={this.state.processingLoder}
                redirectToLogin={this.redirectToLogin}
                handleSubmit={this.handleSubmit}
              />
            </div>
          </div>
          {/*  Hippa Policy Block START  */}
        </div>
        <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        <Footer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  let returnState = {};

  if (state.AccountReducer.action === "UPGRADE_RECURLY_TO_STRIPE") {
    if (state.AccountReducer.data.status != 200) {
      if (state.AccountReducer.data.message != "third_party_error") {
        toast.dismiss();
        const languageData = JSON.parse(localStorage.getItem("languageData"));
        toast.error(languageData.global[state.AccountReducer.data.message]);
      } else {
        toast.dismiss();
        toast.error(state.AccountReducer.data.data);
      }

      returnState.processingLoder = false;
    } else {
      returnState.loginData = state.AccountReducer.data.data;
      returnState.message = state.AccountReducer.data.message;
    }
  }

  if (state.SettingReducer.action == "SWITCH_WORKSPACE") {
    if (state.SettingReducer.data.status == 200) {
      toast.dismiss();
      toast.success(languageData.global[state.SettingReducer.data.message]);

      const userData = state.SettingReducer.data.data;

      localStorage.removeItem("recentlyViewedData");
      localStorage.removeItem("focusedRange");
      localStorage.removeItem("dateFormat");
      localStorage.removeItem("twilioSetup");
      localStorage.removeItem("currencySymbol");
      localStorage.removeItem("accountType");
      localStorage.removeItem("twilioFromNumber");
      localStorage.removeItem("accountStatus");
      localStorage.removeItem("subscriptionType");
      localStorage.removeItem("cCode");
      localStorage.removeItem("currencyLabel");
      localStorage.removeItem("stripeConnection");
      localStorage.removeItem("timeFormat");
      localStorage.removeItem("time_clock_setting");
      localStorage.removeItem("isMembershipEnabled");
      localStorage.removeItem("cTerm");
      localStorage.removeItem("current_wc_full_name");
      returnState.userDataTime = new Date();

      if (userData.render_view) {
        returnState.status = 605;
        returnState.twoFactorData = userData;
        localStorage.setItem("twoFactorData", JSON.stringify(userData));
      } else {
        returnState.status = 200;
        returnState.login_attempts = 1;
        returnState.loggingIn = true;
        localStorage.removeItem("showRecaptcha");
        localStorage.removeItem("login_attempts");
        returnState.userData = state.SettingReducer.data.data;

        if (
          userData.redirect_to != undefined &&
          userData.redirect_to != null &&
          userData.redirect_to != ""
        ) {
          setRedirectTo(userData.redirect_to);
          returnState.redirect_to = userData.redirect_to;
          returnState.redirect_url = getRedirectTo();
        }
        if (
          userData.redirect_to != undefined &&
          userData.redirect_to != "upgrade-account-to-stripe" &&
          userData.redirect_to != "upgrade-subscription-plan" &&
          userData.is_bba_signed != undefined &&
          userData.is_bba_signed == 1
        ) {
          returnState.status = 200;
          localStorage.setItem("currentUserRole", userData.user.user_role_id);
          localStorage.setItem("userData", JSON.stringify(userData));
          localStorage.setItem("isLoggedIn", 1);
          localStorage.setItem(
            "user_listing_settings",
            JSON.stringify(userData.user_listing_settings),
          );
        } else {
          if (
            userData.redirect_to != undefined &&
            userData.redirect_to == "upgrade-account-to-stripe"
          ) {
            returnState.status = 604;
          } else if (
            userData.redirect_to != undefined &&
            userData.redirect_to == "upgrade-subscription-plan"
          ) {
            returnState.status = 603;
          } else {
            setRedirectTo("accept-agreement");
            returnState.status = 602;
          }
          const tempLoggedUserData = {
            userData: userData,
            currentUserRole: userData.user.user_role_id,
            isLoggedIn: 1,
            user_listing_settings: userData.user_listing_settings,

            access_token: getToken(),
          };
          localStorage.setItem(
            "tempLoggedUserData",
            JSON.stringify(tempLoggedUserData),
          );
        }
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.accountInactiveDataTime = new Date();
    }
  }

  returnState.stripeIntent = state.StripeReducer.stripeIntent;

  return returnState;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      accountReset,
      upgradeRecurlyToStripe,
      switchWorkspaces,
      getSetupIntentForAccount,
    },
    dispatch,
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(RecurlyToStripe));
