import { useMemo } from "react";
import * as yup from "yup";
import { useEditEventByIdQuery } from "../../../api/queries/useEditEventByIdQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { EVENT_TYPES } from "../../../store/event/consts";

/* Schemas */

const infoSchema = yup.object({
  id: yup.number(),
  type: yup.string(),
});

const appointmentSchema = yup.object({
  type: yup.string(),
  providerId: yup.number(),
  clinicId: yup.number(),
  services: yup.array().of(
    yup.object({
      id: yup.number(),
      name: yup.string(),
      durationInMin: yup.number(),
      isDepositConfirmed: yup.bool(),
      isFree: yup.bool(),
      isSkipPaymentCheck: yup.bool(),
    }),
  ),
  isDoubleBooking: yup.bool(),
  isOutsideScheduledHours: yup.bool(),
  date: yup.string(),
  timeStart: yup.string(),
  clientId: yup.string(),
  clientName: yup.string(),
  clientEmail: yup.string(),
  clientPhone: yup.string(),
  clientPinCode: yup.string(),
  notes: yup.string(),
});

const outOfOfficeSchema = yup.object({
  id: yup.number(),
  clinicId: yup.number(),
  providerId: yup.number(),
  date: yup.string(),
  timeStart: yup.string(),
  timeEnd: yup.string(),
  eventName: yup.string(),
  frequency: yup.string(),
  frequencyRepeatCount: yup.number(),
  frequencyRepeatOnWeekDays: yup.array().of(yup.string()),
  frequencyRepeatMonthlyValue: yup.string(),
  frequencyEndType: yup.string(),
  frequencyEndDate: yup.string(),
  frequencyEndOccurrences: yup.number(),
});

/* Composers */

const composeInfo = (response) => {
  let result = {};
  if (response && response.data.data.appointment_details) {
    const details = response.data.data.appointment_details;
    result = {
      id: details.id,
      type: details.type,
    };
  }
  return result;
};

const composeAppointment = (response) => {
  const details = response?.data?.data?.appointment_details;
  const isAppointment = details?.type === EVENT_TYPES.appointment;
  return details
    ? {
        type: details.appointment_type,
        providerId: details.provider.id,
        clinicId: details.clinic.id,
        services: details.appointment_services.map((s) => ({
          id: s.service.id,
          name: s.service.name,
          durationInMin: Number(s.duration),
          isDepositConfirmed: false,
          isFree: String(s.service.is_service_free) === "1",
          isSkipPaymentCheck: true,
        })),
        isOutsideScheduledHours: String(details.is_booked_outside) === "1",
        isDoubleBooking:
          String(details.appointment_booking.is_override_allowed) === "1",
        date: details.date,
        timeStart: details.start_time,
        clientId: isAppointment ? String(details.patient.id) : "",
        clientName: isAppointment ? details.patient.full_name : "",
        clientEmail: isAppointment ? details.appointment_booking.email : "",
        clientPhone: isAppointment ? details.appointment_booking.phone : "",
        clientPinCode: isAppointment ? details.patient.pincode || "" : "",
        notes: details.appointment_booking.appointment_notes || "",
      }
    : {};
};

const composeOutOfOffice = (response) => {
  const details = response?.data?.data?.appointment_details;
  const outOfOffice = details?.out_of_office;
  return details && outOfOffice
    ? {
        id: outOfOffice.id,
        clinicId: Number(outOfOffice.clinic_id),
        providerId: Number(outOfOffice.provider_id),
        date: details.date,
        timeStart: outOfOffice.from_time,
        timeEnd: outOfOffice.to_time,
        eventName: outOfOffice.name,
        frequency: outOfOffice.frequency || "",
        frequencyRepeatCount: Number(outOfOffice.interval || ""),
        frequencyRepeatOnWeekDays: outOfOffice.week_days_arr || [],
        frequencyRepeatMonthlyValue: outOfOffice.repeat_type || "",
        frequencyEndType: outOfOffice.frequency_type || "",
        frequencyEndDate: outOfOffice.ends_on || "",
        frequencyEndOccurrences: Number(outOfOffice.occurrence || ""),
      }
    : {};
};

/* --------- */

export function useApiEditEvent({ eventId }) {
  const { data, isFetching } = useEditEventByIdQuery(
    { eventId },
    {
      staleTime: 0,
      enabled: Boolean(eventId),
      onError: () => {
        uiNotification.error("Unable to retrieve event data. Try again later");
      },
    },
  );

  const info = useMemo(
    () =>
      infoSchema.validateSync(composeInfo(data), {
        strict: true,
      }),
    [data],
  );

  const appointment = useMemo(
    () =>
      appointmentSchema.validateSync(composeAppointment(data), {
        strict: true,
      }),
    [data],
  );

  const outOfOffice = useMemo(
    () =>
      outOfOfficeSchema.validateSync(composeOutOfOffice(data), {
        strict: true,
      }),
    [data],
  );

  return {
    editEvent: {
      ...info,
      appointment,
      outOfOffice,
    },
    isEditEventFetching: isFetching,
  };
}
