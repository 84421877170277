import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

positionFooterCorrectly();

const http = axios.create();

http.defaults.headers.common["access-token"] = getToken();

http.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

http.interceptors.response.use(accountStatusResponseInterceptor);

export function getEvolusConfig() {
  return (dispatch) => {
    dispatch({ type: "FETCH_EVOLUS_CONFIG_LOADING", payload: {} });
    http
      .get(process.env.REACT_APP_API_URL + "evolus/setup", {})
      .then((response) => {
        dispatch({
          type: "FETCH_EVOLUS_CONFIG_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_EVOLUS_CONFIG_ERROR",
          payload: error.response.message,
        });
      });
  };
}

export function getEvolusFacilities(data) {
  return (dispatch) => {
    dispatch({ type: "FETCH_EVOLUS_FACILITIES_LOADING", payload: {} });
    http
      .post(process.env.REACT_APP_API_URL + "evolus/setup/facilities", data)
      .then((response) => {
        if (response.data.evolus.errors) {
          dispatch({
            type: "FETCH_EVOLUS_FACILITIES_ERROR",
            payload: response.data.evolus.errors,
          });
        } else {
          dispatch({
            type: "FETCH_EVOLUS_FACILITIES_SUCCESS",
            payload: { evolusFacilities: response.data },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_EVOLUS_FACILITIES_ERROR",
          payload: error.response.data.message,
        });
      });
  };
}

export function saveEvolusConfig(data) {
  return (dispatch) => {
    dispatch({ type: "SAVE_EVOLUS_CONFIG_LOADING", payload: {} });
    http
      .post(process.env.REACT_APP_API_URL + "evolus/setup", data)
      .then((response) => {
        dispatch({ type: "SAVE_EVOLUS_CONFIG_SUCCESS" });
        dispatch({
          type: "FETCH_EVOLUS_CONFIG_SUCCESS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_EVOLUS_CONFIG_ERROR",
          payload: error.response.data.message,
        });
      });
  };
}
