import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

positionFooterCorrectly();

const http = axios.create();

http.defaults.headers.common["access-token"] = getToken();

http.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

http.interceptors.response.use(accountStatusResponseInterceptor);

export function getAspireConfig() {
  return (dispatch) => {
    dispatch({ type: "ASPIRE_CONFIG_LOADING", payload: {} });
    http
      .get(process.env.REACT_APP_API_URL + "aspire-config", {})
      .then((response) => {
        dispatch({
          type: "GET_ASPIRE_CONFIG_SUCCESS",
          payload: { aspireConfig: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "ASPIRE_CONFIG_ERROR",
          payload: {
            error: error.response.data.message,
            invokeRedirect: error.response.data.status === 402,
          },
        });
      });
  };
}

export function patchAspireConfig(params) {
  return (dispatch) => {
    dispatch({ type: "ASPIRE_CONFIG_LOADING", payload: {} });
    http
      .patch(process.env.REACT_APP_API_URL + "aspire-config", {
        aspireConfig: params,
      })
      .then((response) => {
        dispatch({
          type: "SAVE_ASPIRE_CONFIG_SUCCESS",
          payload: { aspireConfig: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "ASPIRE_CONFIG_ERROR",
          payload: {
            error: error.response.data.message,
            invokeRedirect: error.response.data.status === 402,
          },
        });
      });
  };
}

export function saveAspireConfig(params) {
  return (dispatch) => {
    dispatch({ type: "ASPIRE_CONFIG_LOADING", payload: {} });
    http
      .put(process.env.REACT_APP_API_URL + "aspire-config", {
        aspireConfig: params,
      })
      .then((response) => {
        dispatch({
          type: "SAVE_ASPIRE_CONFIG_SUCCESS",
          payload: { aspireConfig: response.data.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "ASPIRE_CONFIG_ERROR",
          payload: {
            error: error.response.data.message,
            invokeRedirect: error.response.data.status === 402,
          },
        });
      });
  };
}
