import React from "react";
import PropTypes from "prop-types";
import classes from "./MergedInvoice.module.scss";
import { useInvoiceActions } from "../../../hooks/useInvoiceActions";
import {
  prepareAmount,
  tipOptionToAmount,
} from "../../../SalesCheckoutInvoice.utils";
import { formatCurrency } from "../../../../../../utilities/general";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { TipsApplier } from "../../../boxes/TipsApplier/TipsApplier";

export function MergedInvoice({ mergedInvoice }) {
  const { invoice } = useInvoice();
  const { applyMergedTip } = useInvoiceActions();

  const beforeTipAmount = prepareAmount(
    mergedInvoice.totalAmount - mergedInvoice.tipAmount,
  );

  const onApplyCustomTip = ({ amount }) => {
    applyMergedTip.initiate({
      tipAmount: amount,
      mergedInvoiceId: mergedInvoice.id,
    });
  };

  const onApplyTip = (tipOptionValue) => {
    applyMergedTip.initiate({
      tipAmount: tipOptionToAmount({
        tipOptionValue,
        beforeTipAmount,
      }),
      mergedInvoiceId: mergedInvoice.id,
    });
  };

  const getMergedInvoiceTitle = () => {
    const beforeTip = formatCurrency(beforeTipAmount, invoice?.currency);
    const tip = formatCurrency(mergedInvoice.tipAmount, invoice?.currency);
    const provider =
      mergedInvoice.procedure?.doctorName || mergedInvoice.user?.fullName;

    return `(${beforeTip} + ${tip}) ${provider}`;
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {getMergedInvoiceTitle()}
        {/*{getMergedInvoiceTitle(mergedInvoice)}*/}
      </div>
      <TipsApplier
        onApply={onApplyTip}
        onApplyCustom={onApplyCustomTip}
        tipAmount={mergedInvoice.tipAmount}
        beforeTipAmount={beforeTipAmount}
        isApplying={applyMergedTip.isLoading}
        currencySymbol={invoice?.currencySymbol}
      />
    </div>
  );
}

MergedInvoice.propTypes = {
  mergedInvoice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    tipAmount: PropTypes.number.isRequired,
    totalAmount: PropTypes.number.isRequired,
    procedure: PropTypes.shape({
      doctorName: PropTypes.string,
    }),
    user: PropTypes.shape({
      fullName: PropTypes.string,
    }),
  }),
};
