import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { toast } from "react-toastify";
import IntlTelInput from 'react-intl-tel-input';
import validator from 'validator';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import Loader from '../../Common/Loader.js'
import {
  getPosData, changePosStatus, changePosConnectionStatus, exportEmptyData, checkPosStripeBalance, disconnectPosStripeAcount, getPosStripeUpdateFields,
  updatePosStripeAccountFields, getPosStripeDashboardUrl, editStripeOwnerInfoAction, updateStripeOwnerInfoAction
} from '../../../Actions/Settings/settingsActions.js';
import { capitalizeFirstLetter, numberFormat, toggleBodyScroll, getIsPosEnabled, showFormattedDate } from '../../../Utils/services.js';
import PosTitle from './PosTitle.js';
import EditStripeOwnerModal from './EditStripeOwnerModal/EditStripeOwnerModal.js';
import {fetchStripeUpdateUrl} from "../../../Actions/stripeUpdateInfoActions";
import CardReaders from './ClearentCardreaders/CardReaders.js';
import { withRouter } from 'react-router';
import { unwrapOr } from '../../../utilities/general.js';
import { mainAdminGuard } from '../../../Utils/authorization.js';
import { StatementDescriptor } from './components/StatementDescriptor/StatementDescriptor.js';
import { MutateMid } from './components/MutateMid/MutateMid.js';
import { DeleteConnection } from './components/DeleteConnection/DeleteConnection.js';
import RestrictionModal from '../ManageClinics/Clinics/RestrictionModal.js';
import { uiNotification } from '../../../services/UINotificationService.js';
import { PosSwitchClinicPicker } from './components/PosSwitchClinicPicker/PosSwitchClinicPicker.js';
import { CLINIC_NO_ASSOC_ID } from './components/PosSwitchClinicPicker/PosSwitchClinicPicker.consts.js';
import { tCommon } from '../../../i18n/useAppTranslation.js';

const CLEARENT_CONTINUE_SETUP_TEXT = "Contact processing@aestheticrecord.com to continue setup";

class Pos extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      connectionFee: localStorage.getItem('connectionFee'),
      userChanged: false,
      showLoader: false,
      posData: {},
      stripeConfigClinic: [],
      stripeConfigGlobal: [],
      isStripeConfigGlobal: false,
      connectionMode: 'global',
      stripeCountry: "US",
      stripeCurrency: "usd",
      stripeMode: "",
      pos_enabled: getIsPosEnabled(),
      isShowAlertModal: false,
      alertModalContent: '',
      alertModalType: 'pos_enabled',
      disconnectStripeId: '',
      disconnectClinicId: 0,
      updateStripeId: '',
      updateClinicId: 0,
      isShowUpdateModal: false,

      first_name: '',
      last_name: '',
      email: '',
      phoneNumber: '',
      firstNameClass: 'newInputField',
      lastNameClass: 'newInputField',
      emailClass: 'newInputField',
      phoneNumberClass: 'newInputField',
      phoneNumberError: false,
      isRender: false,
      defaultCountry: localStorage.getItem('cCode'),
      isPhoneNumberChanged: false,
      cardInputChnaged: false,
      showLoaderStripeUpdate: false,

      stripeSetupClinicId: 0,
      posDashboardData: {},
      clearentConfigGlobal: [],
      clearentConfigClinic: [],

      stripeOwnerEditModal: {
        open: false,
        stripe_user_id: '',
      },
      stripeOwnerInfo: {},
      showErrorDisableModal: false,

      posSwitchClinicId: CLINIC_NO_ASSOC_ID,
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true })
    this.props.getPosData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.stripeUpdateUrlData.status === "loading" && this.props.stripeUpdateUrlData.status === "success") {
      this.setState({ showLoader: false })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.posData !== undefined && nextProps.posData !== prevState.posData) {
      returnState.pos_gateway = nextProps.posData.pos_gateway;
      returnState.posData = nextProps.posData;
      returnState.pos_enabled = returnState.posData.pos_enabled;
      if(nextProps.posData.pos_gateway == 'stripe') {
        returnState.stripeMode = returnState.posData.stripe_mode;
        returnState.stripeConfigClinic = returnState.posData.clinic_stripe_config;
        returnState.stripeConfigGlobal = returnState.posData.global_stripe_config;
        let isStripeConfigGlobal = Object.keys(returnState.stripeConfigGlobal).length;
        if (returnState.stripeMode === 'express' && isStripeConfigGlobal <= 1) {
          isStripeConfigGlobal = 0
        }
        returnState.isStripeConfigGlobal = (isStripeConfigGlobal) ? true : false;
      } else {
        returnState.clearentConfigGlobal = returnState.posData.global_clearent_config;
        let isClearentConfigGlobal = Object.keys(returnState.clearentConfigGlobal).length;
        if ( isClearentConfigGlobal <= 1) {
          isClearentConfigGlobal = 0
        }
        returnState.isClearentConfigGlobal = (isClearentConfigGlobal) ? true : false;
        let ifAnyClinicSelected = false;

        returnState.clearentConfigGlobal = nextProps.posData.global_clearent_config;
        returnState.clearentConfigClinic = nextProps.posData.clinic_clearent_config;

        if(returnState.clearentConfigClinic && returnState.clearentConfigClinic.length > 0) {
          returnState.clearentConfigClinic.map((obj, idx) => {
            if(obj.account_clearent_config != null) {
              ifAnyClinicSelected = true
            }
          })
        }

        returnState.connectionMode = returnState.posData.connection_method;
        // SUPPORT-1811 Disable POS - Issue
        // returnState.pos_enabled = (returnState.posData.pos_enabled) ? returnState.posData.pos_enabled : (returnState.connectionMode == 'global' && isClearentConfigGlobal) ? true : (returnState.connectionMode == 'clinic' && ifAnyClinicSelected) ? true : false;
        returnState.pos_enabled = returnState.posData.pos_enabled;
      }

      if (!prevState.userChanged) {
        if(nextProps.posData.pos_gateway == 'stripe') {
          returnState.connectionMode = returnState.posData.connection_method;
          returnState.stripeCountry = returnState.posData.stripe_country;
          returnState.stripeCurrency = returnState.posData.stripe_currency;
        }
      }
      if (nextProps.isDisconnect !== undefined && nextProps.isDisconnect === true) {
        returnState.pos_enabled = getIsPosEnabled() //(nextProps.pos_enabled) ? true : false;
      }

      returnState.showLoader = false;
      returnState.showLoaderStripeUpdate = false;
      returnState.isShowUpdateModal = false;
      returnState.disconnectStripeId = '';
      returnState.disconnectClinicId = 0;
      returnState.updateStripeId = '';
      returnState.updateClinicId = 0;
      returnState.first_name = '';
      returnState.last_name = '';
      returnState.email = '';
      returnState.phoneNumber = '';
      returnState.firstNameClass = 'newInputField';
      returnState.lastNameClass = 'newInputField';
      returnState.emailClass = 'newInputField';
      returnState.phoneNumberClass = 'newInputField';
      returnState.phoneNumberError = false;
      returnState.defaultCountry = 'us';
      returnState.isPhoneNumberChanged = false;
      returnState.cardInputChnaged = false;
      returnState.isRender = false;
      toggleBodyScroll(false);
    } else if (nextProps.resetConnectionAccount != undefined && nextProps.resetConnectionAccount == true) {
      returnState.showLoader = false;
      returnState.isShowAlertModal = true;
      returnState.alertModalType = 'warning';
      returnState.alertModalContent = nextProps.message;
      nextProps.exportEmptyData();
    } else if (nextProps.changePosMode != undefined && nextProps.changePosMode == true) {
      returnState.showLoader = false;
      returnState.pos_enabled = !prevState.pos_enabled;
      nextProps.exportEmptyData();
    } else if (nextProps.changeconnectionMode != undefined && nextProps.changeconnectionMode == true) {
      returnState.showLoader = false;
      returnState.connectionMode = (prevState.connectionMode == 'global') ? 'clinic' : 'global';
      nextProps.exportEmptyData();
    } else if (nextProps.posStripeBalanceData != undefined) {
      nextProps.exportEmptyData();
      if (nextProps.posStripeBalanceData == 0) {
        returnState.alertModalType = 'pos_disconnect';
        returnState.isShowAlertModal = true;
        returnState.alertModalContent = prevState.settingsLang.pos_delete_account_for_zero_balance_alert_msg;
        toggleBodyScroll(true)
      } else {
        returnState.alertModalType = 'warning';
        returnState.isShowAlertModal = true;
        returnState.alertModalContent = prevState.settingsLang.pos_delete_account_for_balance_part_1_alert_msg + ' ' + numberFormat(nextProps.posStripeBalanceData, 'currency', 2) + '. ' + prevState.settingsLang.pos_delete_account_for_balance_part_2_alert_msg+'!';
        returnState.disconnectStripeId = '';
        returnState.disconnectClinicId = 0;
      }
      returnState.showLoader = false;
      toggleBodyScroll(true)
    } else if (nextProps.posStripeUpdateData != undefined) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      returnState.showLoaderStripeUpdate = false;
      returnState.isShowUpdateModal = true;
      returnState.first_name = nextProps.posStripeUpdateData.stripe_first_name;
      returnState.last_name = nextProps.posStripeUpdateData.stripe_last_name;
      returnState.email = nextProps.posStripeUpdateData.stripe_email;
      returnState.phoneNumber = nextProps.posStripeUpdateData.stripe_phone_number;
      returnState.firstNameClass = 'newInputField';
      returnState.lastNameClass = 'newInputField';
      returnState.emailClass = 'newInputField';
      returnState.phoneNumberClass = 'newInputField';
      returnState.phoneNumberError = false;
      returnState.defaultCountry = 'us';
      returnState.isPhoneNumberChanged = false;
      returnState.cardInputChnaged = false;
      returnState.isRender = true;
      toggleBodyScroll(true)
    } else if (nextProps.posDashboardData != undefined) {
      nextProps.exportEmptyData();
      returnState.showLoader = false;
      window.open(nextProps.posDashboardData, '_blank')
    } else if (nextProps.showLoader != undefined && nextProps.showLoader == false) {
      returnState.showLoader = false;
      returnState.showLoaderStripeUpdate = false;
      nextProps.exportEmptyData();
    }
    if (nextProps.stripeOwnerInfoTimestamp && nextProps.stripeOwnerInfoTimestamp !== prevState.stripeOwnerInfoTimestamp) {
      returnState.showLoader = false;
      returnState.stripeOwnerInfo = {};
      returnState.stripeOwnerInfoTimestamp = nextProps.stripeOwnerInfoTimestamp;
      if (nextProps.stripeOwnerInfo) {
        returnState.stripeOwnerInfo = nextProps.stripeOwnerInfo;
        returnState.stripeOwnerEditModal = {
          ...prevState.stripeOwnerEditModal,
          open: true,
        };
      }
    }
    return returnState
  }

  handleInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let returnState = {}
    returnState.userChanged = true;
    switch (target.type) {
      case 'checkbox':
        value = target.checked;
        break;
      case 'radio':
        //value = target.checked;
        break;
    }
    if (!value && name == 'pos_enabled') {
      returnState.alertModalType = 'pos_enabled';
      returnState.isShowAlertModal = true;
      returnState.alertModalContent = this.state.settingsLang.pos_enable_alert_msg;
      toggleBodyScroll(false)
    } else if (name == 'connectionMode' && value != this.state.connectionMode) {
      returnState.alertModalType = 'connection';
      returnState.isShowAlertModal = true;
      returnState.alertModalContent = this.state.settingsLang.pos_stripe_alert_msg;
      toggleBodyScroll(false)
    } else {
      if (name == 'pos_enabled') {
        returnState.showLoader = true;
        this.props.changePosStatus({ status: 1 })
      }
    }
    this.setState(returnState);
  }

  handleSubmit = (event) => {
    return
  }

  handlePosDisable = () => {
    toggleBodyScroll(false)
    this.setState({ showLoader: true, isShowAlertModal: false })
    this.props.changePosStatus({ status: 0 })
  }

  handleStripeType = () => {
    toggleBodyScroll(false)
    this.props.changePosConnectionStatus({ id: this.state.posSwitchClinicId }).then(() => {
      this.setState({ posSwitchClinicId: CLINIC_NO_ASSOC_ID });
      window.location.reload();
    });
    this.setState({ showLoader: true, isShowAlertModal: false })
  }


  handleAlertModal = () => {
    toggleBodyScroll(!this.state.isShowAlertModal)
    this.setState({ isShowAlertModal: !this.state.isShowAlertModal })
  }

  PosSetup = (event) => {
    const accountType = event.target.dataset.accountType;
    const clinicId = event.target.dataset.clinicId;
    if (accountType == 'global') {
      return (
        <div>
          {this.props.history.push(`/settings/pos/setup/global`)}
        </div>
      );
    } else if (accountType == 'clinic' && clinicId > 0) {
      return (
        <div>
          {this.props.history.push(`/settings/pos/setup/clinic/${clinicId}`)}
        </div>
      );
    }
  }
  PosCardReader = (event) => {
    const accountType = event.target.dataset.accountType;
    const clinicId = event.target.dataset.clinicId;
    if (accountType == 'global') {
      return (
        <div>
          {this.props.history.push(`/settings/pos/card-reader/global`)}
        </div>
      );
    } else if (accountType == 'clinic' && clinicId > 0) {
      return (
        <div>
          {this.props.history.push(`/settings/pos/card-reader/clinic/${clinicId}`)}
        </div>
      );
    }
  }

  VisitStipeDashboard = (clinicId) => {
    this.setState({ showLoader: true })
    this.props.getPosStripeDashboardUrl({ clinic_id: clinicId })
  }

  checkPosStripeBalance = (event) => {
    const stripeId = event.target.dataset.stripeId;
    const clinicId = event.target.dataset.clinicId;
    if (stripeId != undefined && stripeId != null && stripeId != '') {
      this.setState({ showLoader: true, disconnectStripeId: stripeId, disconnectClinicId: clinicId })
      this.props.checkPosStripeBalance({ stripe_user_id: stripeId });
    }
  }

  disconnectPosStripeAcount = () => {
    if (this.state.disconnectStripeId !== '') {
      this.setState({ showLoader: true })
      this.props.disconnectPosStripeAcount({ stripe_user_id: this.state.disconnectStripeId, clinic_id: this.state.disconnectClinicId }).catch(error => {
        if(error.message === "disconnect_pos_active_membership_error") {
          this.setState({ showErrorDisableModal: true })
        } else {
          uiNotification.error(error.message)
        }
      })
    }
    toggleBodyScroll(false)
    this.setState({ isShowAlertModal: false })
  }

  fetchStripeUpdateUrl = (event) => {
    const stripeId = event.target.dataset.stripeId;
    if (!stripeId) {
      return false;
    }
    const clinicId = event.target.dataset.clinicId;
    this.setState({ showLoader: true, updateStripeId: stripeId, updateClinicId: clinicId, isRender: false })
    this.props.fetchStripeUpdateUrl(stripeId);
  }

  handleCardInputChange = (event) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let returnState = {}
    returnState.cardInputChnaged = true;
    returnState[name] = value;
    this.setState(returnState);
  }

  updatePosStripeAccountFields = (event) => {
    let error = false;

    if (this.state.first_name === undefined || this.state.first_name === null || this.state.first_name.trim() === '' || !validator.isAlpha(this.state.first_name)) {
      this.setState({ firstNameClass: 'newInputField field_error' })
      error = true;
    } else if (this.state.first_name) {
      this.setState({ firstNameClass: 'newInputField' })
    }

    if (this.state.last_name === undefined || this.state.last_name === null || this.state.last_name.trim() === '' || !validator.isAlpha(this.state.last_name)) {
      this.setState({ lastNameClass: 'newInputField field_error' })
      error = true;
    } else if (this.state.last_name) {
      this.setState({ lastNameClass: 'newInputField' })
    }

    if (typeof this.state.email === undefined || this.state.email === null || this.state.email.trim() === '' || !validator.isEmail(this.state.email)) {
      this.setState({ emailClass: 'newInputField field_error' })
      error = true;
    } else if (this.state.email) {
      this.setState({ emailClass: 'newInputField' })
    }

    if ((typeof this.state.phoneNumber === undefined || this.state.phoneNumber === null || this.state.phoneNumber.trim() === '')) {
      this.setState({ phoneNumberClass: 'newInputField' })
    } else if (this.state.phoneNumber !== '' && this.state.phoneNumberError) {
      this.setState({ phoneNumberClass: 'newInputField  field_error' })
      error = true;
    } else if (this.state.phoneNumber !== '' && !this.state.phoneNumberError) {
      this.setState({ phoneNumberClass: 'newInputField' })
    }
    if (error) {
      return
    }

    let formData = {
      stripe_user_id: this.state.updateStripeId,
      clinic_id: this.state.updateClinicId,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      phone_number: this.state.phoneNumber
    }
    if (this.state.updateStripeId !== '') {
      this.setState({ showLoaderStripeUpdate: true })
      this.props.updatePosStripeAccountFields(formData);
    }
  }

  handleUpdatModal = (event) => {
    toggleBodyScroll(false);
    this.setState({ isShowUpdateModal: false })
  }

  phoneNumberChanged = (inputFiled, t, x, y, number) => {
    if (!number) {
      return
    }
    this.setState({ cardInputChnaged: true, isPhoneNumberChanged: true });
    let fullNumber = ''
    //const phoneNumber = number.replace(/\s/g,'')
    const phoneNumber = number.replace(/[ `~!@#$%^&*()_|\-=?;:'",.<>\{\}\[\]\\\/]/gi, '')
    let phoneNumberError = true;
    let phoneNumberClass = 'newInputField field_error';
    if (t) {
      phoneNumberError = false;
      phoneNumberClass = 'newInputField';
      fullNumber = number
    }
    this.setState({ [inputFiled]: phoneNumber, [inputFiled + 'Error']: phoneNumberError, [inputFiled + 'Class']: phoneNumberClass })
  }

  fetchStripeOwnerInfo = (id) => {
    this.setState({ 
      showLoader: true,
      stripeOwnerEditModal: {
        open: false,
        stripe_user_id: id,
      }
    });
    this.props.editStripeOwnerInfoAction(id);
  }

  onStripeOwnerModalClose = () => {
    this.setState({ 
      stripeOwnerEditModal: {
        open: false,
        stripe_user_id: '',
      }
    });
  }
  onStripeOwnerModalSave = (formData) => {
    this.props.updateStripeOwnerInfoAction(formData);
    this.setState({
      showLoader: true,
      stripeOwnerEditModal: {
        open: false,
        stripe_user_id: '',
      }
    });
  };

  get isCardReadersTab () {
    return this.props.match.params.activeTab === "card-readers"
  }

  get isGeneralSettingsTab () {
    return this.props.location.pathname === "/settings/pos"
  }

  get allowedCardreadersCurrencies () {
    return ["usd", "cad"]
  }

  render() {
    return (
      <div className="main protected pos-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter">
              <PosTitle
                history={this.props.history}
                settingsLang={this.state.settingsLang}
                globalLang={this.state.globalLang}
                pos_enabled={this.state.pos_enabled}
                key={this.props.location.pathname}
              />
              {Boolean(this.state.pos_enabled) &&
                <div className="setting-setion m-b-10">
                  <div className="membership-title">
                  {(this.state.connectionMode == 'global') ?
                    `Please Note: POS Connect Fee is ${numberFormat(this.state.connectionFee, 'currency', 2)}/month`
                    :
                    `Please Note: POS Connect Fee is ${numberFormat(this.state.connectionFee, 'currency', 2)}/month/clinic`
                  }
                  </div>
                </div>
              }
              {(Boolean(this.state.pos_enabled) && this.state.pos_gateway == 'stripe') &&
                <div className="row business-row">
                  <div className="col-sm-6 new-stats-outer">
                    <div className="setting-setion m-b-10 newPOStripe">
                      <div className="pos-stripe-outer">
                        <input type="radio" onChange={this.handleInputChange} className="pos-stripe-input pos-stripe-option stripe-option" id="single-stripe" name="connectionMode" value="global" checked={this.state.connectionMode == 'global' ? 'checked' : false} />
                        <label className="pos-stripe-discrip" htmlFor="single-stripe">
                          <span className="pos-stripe-title">{(this.state.stripeMode === 'custom') ? this.state.settingsLang.pos_single_pos_account : this.state.settingsLang.pos_single_stripe_account}</span>
                          {this.state.settingsLang.pos_single_stripe_account_label}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 new-stats-outer">
                    <div className="setting-setion m-b-10 newPOStripe">
                      <div className="pos-stripe-outer">
                        <input type="radio" onChange={this.handleInputChange} className="pos-stripe-input pos-stripe-option stripe-option" id="stripe-account" name="connectionMode" value="clinic" checked={this.state.connectionMode == 'clinic' ? 'checked' : false} />
                        <label className="pos-stripe-discrip" htmlFor="stripe-account">
                          <span className="pos-stripe-title">{(this.state.stripeMode === 'custom') ? this.state.settingsLang.pos_pos_account_per_clinic : this.state.settingsLang.pos_stripe_account_per_clinic}</span>
                          {this.state.settingsLang.pos_stripe_account_per_clinic_label}</label>
                      </div>
                    </div>
                  </div>

                </div>
              }
               {(this.state.pos_gateway == 'clearent' && this.isGeneralSettingsTab) &&
                <div className="row business-row">
                  <div className="col-sm-6 new-stats-outer">
                    <div className="setting-setion m-b-10 newPOStripe">
                      <div className="pos-stripe-outer">
                        <input type="radio" onChange={this.handleInputChange} className="pos-stripe-input pos-stripe-option stripe-option" id="single-stripe" name="connectionMode" value="global" checked={this.state.connectionMode == 'global' ? 'checked' : false} />
                        <label className="pos-stripe-discrip" htmlFor="single-stripe">
                          <span className="pos-stripe-title">Single Account </span>
                          {this.state.settingsLang.pos_single_stripe_account_label}</label>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 new-stats-outer">
                    <div className="setting-setion m-b-10 newPOStripe">
                      <div className="pos-stripe-outer">
                        <input type="radio" onChange={this.handleInputChange} className="pos-stripe-input pos-stripe-option stripe-option" id="stripe-account" name="connectionMode" value="clinic" checked={this.state.connectionMode == 'clinic' ? 'checked' : false} />
                        <label className="pos-stripe-discrip" htmlFor="stripe-account">
                          <span className="pos-stripe-title">Account per Clinic/Office</span>
                          {this.state.settingsLang.pos_stripe_account_per_clinic_label}</label>
                      </div>
                    </div>
                  </div>

                </div>
              }
              {(Boolean(this.state.pos_enabled) && this.state.pos_gateway == 'clearent' && this.isCardReadersTab) &&
                <CardReaders />
              }
              {Boolean(this.state.pos_enabled) && this.state.pos_gateway == 'stripe' &&

                <div className="setting-setion">
                  {(this.state.connectionMode == 'global') &&
                  <div className="p2030">

                    <div className={this.state.pos_enabled ? 'is-pos_enabled-form-title' : 'is-pos_enabled-form-title no-display'}>
                      <div className={this.state.connectionMode == 'global' ? "row single" : "row single no-display"} id="global-settings">
                        {(!this.state.isStripeConfigGlobal)
                          ?
                          <div className="col-lg-12 col-xs-12">
                            <div className="row global-connect m-t-0">
                              <div className="col-sm-6 col-md-8 col-lg-9">
                                {this.state.settingsLang.pos_you_have_not_connected_your_pos_account_yet}
                                {this.state.settingsLang.pos_please_click_connect_to_link_your_pos_account}
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-3 text-right">
                                <a onClick={this.PosSetup} data-account-type="global" data-clinic-id="0"
                                   className="new-white-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_setup_your_account}</a>
                              </div>
                            </div>
                          </div>
                          :
                          (this.state.stripeMode === 'express') ?
                            <div className="col-lg-12 col-xs-12 m-t-0">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.pos_your_account_is_connected_on}
                                  </div>
                                  <div className="newInputField" type="text" autoComplete="off">{showFormattedDate(this.state.stripeConfigGlobal.date_created, false, 'MM/DD/YYYY')} </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.pos_your_stripe_id}
                                  </div>
                                  <div className="newInputField" type="text" autoComplete="off">{this.state.stripeConfigGlobal.stripe_user_id} </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Card Not Present Fee
                                  </div>
                                  <div className="newInputField" type="text" autoComplete="off">{(this.state.stripeConfigGlobal.platform_fee) ? numberFormat(this.state.stripeConfigGlobal.platform_fee, 'decimal', 2) + '%' : ''} </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Card Present Fee
                                  </div>
                                  <div className="newInputField" type="text" autoComplete="off">{(this.state.stripeConfigGlobal.platform_fee_swipe) ? numberFormat(this.state.stripeConfigGlobal.platform_fee_swipe, 'decimal', 2) + '%' : ''} </div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="new-field-label">{/*this.state.settingsLang.pos_stripe_dashboard*/}&nbsp;</div>
                                    <a onClick={this.VisitStipeDashboard.bind(this, 0)} data-account-type="global" data-clinic-id="0" data-stripe-id={this.state.stripeConfigGlobal.stripe_user_id} className="new-white-btn small-stripe-btn dashboard-link">{this.state.settingsLang.pos_visit_stripe_dashboard}</a>
                                  </div>
                                </div>
                                <div className="col-sm-12 m-t-10 m-b-10">
                                  {(this.state.stripeCurrency === 'usd') &&
                                    <a onClick={this.checkPosStripeBalance} data-account-type="global" data-clinic-id="0" data-stripe-id={this.state.stripeConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn stripe-disconnect">{this.state.settingsLang.pos_disconnect_with_stripe}</a>
                                  }
                                  <a onClick={this.PosCardReader} data-account-type="global" data-clinic-id="0" data-stripe-id={this.state.stripeConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_card_readers}</a>
                                </div>
                              </div>
                            </div>
                            :
                            <div className="col-lg-12 col-xs-12 m-t-0">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.pos_legal_name}</div>
                                    <div className="newInputField">{this.state.stripeConfigGlobal.stripe_first_name + " " + this.state.stripeConfigGlobal.stripe_last_name}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.post_treatment_email}</div>
                                    <div className="newInputField">{this.state.stripeConfigGlobal.stripe_email}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Card Not Present Fee</div>
                                    <div className="newInputField">{(this.state.stripeConfigGlobal.platform_fee) ? numberFormat(this.state.stripeConfigGlobal.platform_fee, 'decimal', 2) + '%' : ''}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Card Present Fee</div>
                                    <div className="newInputField">{(this.state.stripeConfigGlobal.platform_fee_swipe) ? numberFormat(this.state.stripeConfigGlobal.platform_fee_swipe, 'decimal', 2) + '%' : ''}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.setting_currency}</div>
                                    <div className="newInputField">{(this.state.stripeConfigGlobal.platform_fee_swipe) ? this.state.stripeCurrency.toUpperCase() : ''}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.pos_account_status}</div>
                                    <div className={(this.state.stripeConfigGlobal.stripe_account_status == 'verified') ? "newInputField stripe-verified" : "newInputField stripe-unverified"}>{(this.state.stripeConfigGlobal.stripe_account_status != null && this.state.stripeConfigGlobal.stripe_account_status != '') ? capitalizeFirstLetter(this.state.stripeConfigGlobal.stripe_account_status) : null}</div>
                                  </div>
                                </div>
                                <div className="col-sm-12 m-t-10 m-b-10">
                                  <a onClick={this.checkPosStripeBalance} data-account-type="global" data-clinic-id='0' data-stripe-id={this.state.stripeConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn stripe-disconnect">{this.state.settingsLang.pos_disconnect_pos}</a>
                                  {this.allowedCardreadersCurrencies.includes(this.state.stripeCurrency.toLowerCase()) &&
                                    <a onClick={this.PosCardReader} data-account-type="global" data-clinic-id='0' data-stripe-id={this.state.stripeConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_card_readers}</a>
                                  }
                                  <a onClick={this.fetchStripeUpdateUrl} data-account-type="global" data-clinic-id='0' data-stripe-id={this.state.stripeConfigGlobal.stripe_user_id} data-title className="new-blue-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_update_info}</a>
                                  {mainAdminGuard(() => (
                                    <StatementDescriptor
                                      inlineBlock 
                                      stripeUserId={this.state.stripeConfigGlobal.stripe_user_id}
                                      onSuccess={this.props.getPosData}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                {(this.state.connectionMode != 'global') &&
                  <div id="per-clinic-settings" className={this.state.pos_enabled ? '' : 'no-display'}>
                    <div className={this.state.connectionMode == 'clinic' ? "table-responsive" : "table-responsive no-display"}>
                      {(this.state.stripeMode === 'custom') &&
                        <table className="table-updated no-hover setting-table table-min-width no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-2 table-updated-th sorting">{this.state.globalLang.label_clinic}</th>
                              <th className="col-xs-2 table-updated-th sorting">{this.state.settingsLang.pos_legal_name}</th>
                              <th className="col-xs-1 table-updated-th sorting">{this.state.settingsLang.post_treatment_email}</th>
                              <th className="col-xs-1 table-updated-th sorting">Card Not Present Fee</th>
                              <th className="col-xs-1 table-updated-th sorting">Card Present Fee</th>
                              <th className="col-xs-1 table-updated-th sorting">{this.state.settingsLang.pos_account_status}</th>
                              <th className="col-xs-3 table-updated-th sorting">{this.state.globalLang.label_action} </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {(this.state.stripeConfigClinic.length > 0) &&
                              this.state.stripeConfigClinic.map((obj, idx) => {
                                return (
                                  <tr className="table-updated-tr" key={'stripeConfigClinic-' + idx}>
                                    <td className="table-updated-td  break-all">{obj.clinic_name}</td>
                                    <td className="table-updated-td">{obj.stripe_first_name + " " + obj.stripe_last_name}</td>
                                    <td className="table-updated-td">{obj.stripe_email}</td>
                                    <td className="table-updated-td">{(obj.platform_fee) ? numberFormat(obj.platform_fee, 'decimal', 2) + '%' : ''}</td>
                                    <td className="table-updated-td">{(obj.platform_fee_swipe) ? numberFormat(obj.platform_fee_swipe, 'decimal', 2) + '%' : ''}</td>
                                    <td className={(obj.stripe_account_status == 'verified') ? "table-updated-td stripe-verified" : "table-updated-td stripe-unverified"}>{(obj.stripe_account_status != null && obj.stripe_account_status != '') ? capitalizeFirstLetter(obj.stripe_account_status) : null}</td>
                                    {(obj.stripe_user_id == undefined || obj.stripe_user_id == null || obj.stripe_user_id == '') ? (
                                      <td className="table-updated-td">
                                        <a onClick={this.PosSetup} data-account-type="clinic" data-clinic-id={obj.id} className="stripe-link">{this.state.settingsLang.pos_setup_your_account}</a>
                                      </td>
                                    ) : (
                                      <td className="table-updated-td">
                                        {mainAdminGuard(() => (
                                          <StatementDescriptor
                                            buttonVariant="text"
                                            stripeUserId={obj.stripe_user_id}
                                            onSuccess={this.props.getPosData}
                                          />
                                        ))}
                                        {(this.state.stripeCurrency === 'usd') && (
                                          <a
                                            onClick={this.PosCardReader}
                                            data-account-type="clinic"
                                            data-clinic-id={obj.id}
                                            data-stripe-id={obj.stripe_user_id}
                                            className="stripe-link modal-link"
                                          >
                                            {this.state.settingsLang.pos_card_readers}
                                          </a>
                                        )}
                                        <br />
                                        <a
                                          onClick={this.fetchStripeUpdateUrl}
                                          data-account-type="clinic"
                                          data-clinic-id={obj.id}
                                          data-stripe-id={obj.stripe_user_id}
                                          className="stripe-link get-stripe-acct-details"
                                        >
                                          {this.state.settingsLang.pos_update_info}
                                        </a>
                                        <DeleteConnection
                                          clinicId={obj.id}
                                          onSuccess={this.props.getPosData}
                                        />
                                      </td>
                                    )}
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      }
                      {(this.state.stripeMode === 'express') &&
                        <table className="table-updated no-hover setting-table table-min-width no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-3 table-updated-th sorting">{this.state.globalLang.label_clinic}</th>
                              <th className="col-xs-2 table-updated-th sorting">{this.state.globalLang.label_status} </th>
                              <th className="col-xs-2 table-updated-th sorting">Card Not Present Fee</th>
                              <th className="col-xs-2 table-updated-th sorting">Card Present Fee</th>
                              <th className="col-xs-3 table-updated-th sorting">{(this.state.stripeConfigClinic.length > 0) ? this.state.globalLang.label_action : ''} </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {(this.state.stripeConfigClinic.length > 0) &&
                              this.state.stripeConfigClinic.map((obj, idx) => {
                                return (
                                  <tr className="table-updated-tr" key={'stripeConfigClinic-' + idx}>
                                    <td className="table-updated-td break-all">{obj.clinic_name}</td>
                                    <td className="table-updated-td">
                                      {(obj.stripe_user_id == undefined || obj.stripe_user_id == null || obj.stripe_user_id == '') ?
                                        'Not connected' : 'Connected ' + showFormattedDate(obj.stripe_date_created)}
                                    </td>
                                    <td className="table-updated-td">{(obj.platform_fee) ? numberFormat(obj.platform_fee, 'decimal', 2) + '%' : ''}</td>
                                    <td className="table-updated-td">{(obj.platform_fee_swipe) ? numberFormat(obj.platform_fee_swipe, 'decimal', 2) + '%' : ''}</td>
                                    {(obj.stripe_user_id == undefined || obj.stripe_user_id == null || obj.stripe_user_id == '') ?
                                      <td className="table-updated-td">
                                        <a onClick={this.PosSetup} data-account-type="clinic" data-clinic-id={obj.id}
                                           className="new-white-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_setup_your_account}</a>
                                        {/*<Link to="/pos/setup-clearent" className="stripe-link">Connect POS</Link>*/}
                                      </td>
                                      :
                                      <td className="table-updated-td">
                                        <a onClick={this.VisitStipeDashboard.bind(this, obj.id)} data-account-type="clinic" data-clinic-id={obj.id} data-stripe-id={obj.stripe_user_id} className="stripe-link get-stripe-acct-details ">{this.state.settingsLang.pos_visit_stripe_dashboard} </a>
                                        <br />
                                        <a onClick={this.checkPosStripeBalance} data-account-type="clinic" data-clinic-id={obj.id} data-stripe-id={obj.stripe_user_id} className="stripe-link">{this.state.settingsLang.pos_disconnect_with_stripe}</a>
                                        <br />
                                        {(this.state.stripeCurrency === 'usd') &&
                                          <a onClick={this.PosCardReader} data-account-type="clinic" data-clinic-id={obj.id} data-stripe-id={obj.stripe_user_id} className="stripe-link modal-link">{this.state.settingsLang.pos_card_readers}</a>
                                        }
                                      </td>
                                    }
                                  </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                      }
                    </div>
                  </div>
                }
                </div>
              }
              {Boolean(this.state.pos_enabled) && this.state.pos_gateway == 'clearent' &&

                <div className="setting-setion">
                  {(this.state.connectionMode == 'global') &&
                  <div className="p2030">
                  {((this.state.clearentConfigGlobal != undefined && this.state.clearentConfigGlobal.length == 0) || (this.state.clearentConfigGlobal != undefined && this.state.clearentConfigGlobal.clearent_setup != undefined  && this.state.clearentConfigGlobal.clearent_setup.step_completed < 7)) &&

                    <div className={this.state.pos_enabled ? 'is-pos_enabled-form-title' : 'is-pos_enabled-form-title no-display'}>
                      <div className={this.state.connectionMode == 'global' ? "row single" : "row single no-display"} id="global-settings">
                          <div className="col-lg-12 col-xs-12">
                            <div className="row global-connect m-t-0">
                              <div className="text-center">
                                {this.state.settingsLang.pos_you_have_not_connected_your_pos_account_yet}
                              </div>
                              <div className="text-center align-center flex-col gap-8">
                                {CLEARENT_CONTINUE_SETUP_TEXT}
                                <MutateMid
                                  className="w-fit"
                                  clinicId={0}
                                  merchantId={this.state.clearentConfigGlobal?.merchant_id}
                                  onSuccess={this.props.getPosData}
                                />
                              </div>
                            </div>
                          </div>
                      </div>
                    </div>
                  }
                  {this.state.clearentConfigGlobal && this.state.clearentConfigGlobal.clearent_setup  && this.state.clearentConfigGlobal.clearent_setup.step_completed == 7 &&
                    <div className="col-lg-12 col-xs-12 m-t-0 p-b-15">
                              <div className="row">
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.pos_legal_name}</div>
                                    <div className="newInputField">{unwrapOr(() => this.state.clearentConfigGlobal.clearent_setup.business_information.firstName + " " + this.state.clearentConfigGlobal.clearent_setup.business_information.lastName, "")}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.post_treatment_email}</div>
                                    <div className="newInputField">{unwrapOr(() => this.state.clearentConfigGlobal.clearent_setup.business_information.emailAddress, "")}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Card Not Present Fee</div>
                                    <div className="newInputField">{(this.state.clearentConfigGlobal.platform_fee) ? numberFormat(this.state.clearentConfigGlobal.platform_fee, 'decimal', 2) + '%' : ''}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">Card Present Fee</div>
                                    <div className="newInputField">{(this.state.clearentConfigGlobal.platform_fee_swipe) ? numberFormat(this.state.clearentConfigGlobal.platform_fee_swipe, 'decimal', 2) + '%' : ''}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.setting_currency}</div>
                                    <div className="newInputField">{(this.state.clearentConfigGlobal.platform_fee_swipe) ? this.state.stripeCurrency.toUpperCase() : ''}</div>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="newInputFileldOuter">
                                    <div className="newInputLabel">{this.state.settingsLang.pos_account_status}</div>
                                    <div className={(this.state.clearentConfigGlobal.clearent_setup.status == 'completed') ? "newInputField stripe-verified" : "newInputField stripe-unverified"}>{(this.state.clearentConfigGlobal.clearent_setup.status != null && this.state.clearentConfigGlobal.clearent_setup.status != '') ? capitalizeFirstLetter(this.state.clearentConfigGlobal.clearent_setup.status != 'completed' ? 'Onboarding Completed, Verification Pending' : "Completed") : null}</div>
                                  </div>
                                </div>
                                {this.state.clearentConfigGlobal.tracking_number && this.state.clearentConfigGlobal.tracking_number != null &&
                                  <div className="col-sm-6">
                                    <div className="newInputFileldOuter">
                                      <div className="newInputLabel">{"Card Reader Tracking ID"}</div>
                                      <div className={"newInputField"}>{this.state.clearentConfigGlobal.tracking_number}</div>
                                    </div>
                                  </div>
                                }
                                {this.state.clearentConfigGlobal && this.state.clearentConfigGlobal.merchant_id && this.state.clearentConfigGlobal.merchant_id != null &&
                                  <div className="col-sm-6">
                                    <div className="newInputFileldOuter">
                                      <div className="newInputLabel">{"MID"}</div>
                                      <div className={"newInputField"}>{this.state.clearentConfigGlobal.merchant_id}</div>
                                    </div>
                                  </div>
                                }
                                {/*<div className="col-sm-12 m-t-10 m-b-10">
                                                                  <a onClick={this.checkPosStripeBalance} data-account-type="global" data-clinic-id='0' data-stripe-id={this.state.clearentConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn stripe-disconnect">{this.state.settingsLang.pos_disconnect_pos}</a>
                                                                  {(this.state.stripeCurrency === 'usd') &&
                                                                    <a onClick={this.PosCardReader} data-account-type="global" data-clinic-id='0' data-stripe-id={this.state.clearentConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_card_readers}</a>
                                                                  }
                                                                  {(this.state.clearentConfigGlobal.clearent_setup.status != 'completed') &&
                                                                    <a onClick={this.getPosStripeUpdateFields} data-account-type="global" data-clinic-id='0' data-stripe-id={this.state.clearentConfigGlobal.stripe_user_id} data-title className="new-blue-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_update_info}</a>
                                                                  }
                                                                </div>*/}
                            </div>
                            <MutateMid
                              clinicId={this.state.clearentConfigGlobal?.clinic_id}
                              merchantId={this.state.clearentConfigGlobal?.merchant_id}
                              onSuccess={this.props.getPosData}
                            />
                          </div>
                          }
                  </div>

                  }
                {(this.state.connectionMode != 'global' && this.state.clearentConfigClinic != undefined && this.state.clearentConfigClinic.length > 0) &&
                  <div id="per-clinic-settings" className={this.state.pos_enabled ? '' : 'no-display'}>
                    <div className={this.state.connectionMode == 'clinic' ? "table-responsive" : "table-responsive no-display"}>

                        <table className="table-updated no-hover setting-table table-min-width no-td-border">
                          <thead className="table-updated-thead">
                            <tr>
                              <th className="col-xs-2 table-updated-th sorting">{this.state.globalLang.label_clinic}</th>
                              <th className="col-xs-2 table-updated-th sorting">{this.state.globalLang.label_status}  & MID </th>
                              <th className="col-xs-1 table-updated-th sorting">Card Not Present Fee</th>
                              <th className="col-xs-2 table-updated-th sorting">Card Present Fee</th>
                              <th className="col-xs-3 table-updated-th sorting">{this.state.settingsLang.pos_account_status}</th>
                              <th className="col-xs-2 table-updated-th sorting">{this.state.globalLang.label_action} </th>
                            </tr>
                          </thead>
                          <tbody className="ajax_body">
                            {(this.state.clearentConfigClinic.length > 0) &&
                              this.state.clearentConfigClinic.map((obj, idx) => {
                                return (
                                  <tr className="table-updated-tr" key={'clearentConfigClinic-' + idx}>
                                    <td className="table-updated-td break-all">{obj.clinic_name}</td>
                                    {(obj.account_clearent_config != null ) ?
                                      <React.Fragment>
                                        <td className="table-updated-td">
                                        {obj.account_clearent_config.clearent_setup.status == 'completed' && 'Connected ' + showFormattedDate(obj.account_clearent_config.created)} <br /> <span className="stripe-link">{obj.account_clearent_config && obj.account_clearent_config. merchant_id}</span>
                                        </td>
                                        <td className="table-updated-td">{(obj.account_clearent_config.platform_fee) ? numberFormat(obj.account_clearent_config.platform_fee, 'decimal', 2) + '%' : ''}</td>
                                        <td className="table-updated-td">{(obj.account_clearent_config.platform_fee_swipe) ? numberFormat(obj.account_clearent_config.platform_fee_swipe, 'decimal', 2) + '%' : ''}</td>
                                        <td className="table-updated-td">
                                        {obj.account_clearent_config.clearent_setup.status == 'inprogress' && obj.account_clearent_config.clearent_setup.step_completed < 7 && 'Onboarding in progress'}
                                        {obj.account_clearent_config.clearent_setup.status == 'inprogress' && obj.account_clearent_config.clearent_setup.step_completed == 7 && 'Onboarding Completed, Verification Pending'}
                                        {obj.account_clearent_config.clearent_setup.status == 'completed' && 'Verified'}
                                        {obj.account_clearent_config.clearent_setup.status == 'declined' && 'Declined'}
                                        {obj.account_clearent_config.clearent_setup.status == 'onboarding_error' && 'Error in onboarding'}
                                        {/*<a onClick={this.PosCardReader} data-account-type="clinic" data-clinic-id={obj.clinic_id} data-stripe-id={this.state.clearentConfigGlobal.stripe_user_id} className="new-blue-btn small-stripe-btn modal-link">{this.state.settingsLang.pos_card_readers}</a>*/}
                                        </td>
                                        <td className="table-updated-td">
                                          {obj.account_clearent_config.tracking_number != null &&
                                            <React.Fragment>
                                              <span className="tracking-number"><label>Card Reader Tracking ID</label></span>
                                              <span className="tracking-number">{obj.account_clearent_config.tracking_number}</span>
                                            </React.Fragment>
                                          }
                                          {obj.account_clearent_config.clearent_setup.status == 'inprogress' && obj.account_clearent_config.clearent_setup.step_completed < 7 && CLEARENT_CONTINUE_SETUP_TEXT }
                                          <MutateMid
                                            legacyBtn
                                            clinicId={obj.clinic_id}
                                            merchantId={obj.account_clearent_config?.merchant_id}
                                            onSuccess={this.props.getPosData}
                                          />
                                        </td>
                                      </React.Fragment>
                                        :
                                      <React.Fragment>
                                        <td className="table-updated-td">
                                              Not Connected
                                          </td>
                                          <td className="table-updated-td"></td>
                                          <td className="table-updated-td"></td>
                                          <td className="table-updated-td"></td>
                                          <td className="table-updated-td">
                                            {CLEARENT_CONTINUE_SETUP_TEXT}
                                            <MutateMid
                                              legacyBtn
                                              clinicId={obj.clinic_id}
                                              merchantId={obj.account_clearent_config?.merchant_id}
                                              onSuccess={this.props.getPosData}
                                            />
                                          </td>
                                      </React.Fragment>
                                      }
                                 </tr>
                                )
                              })
                            }
                          </tbody>
                        </table>
                    </div>
                  </div>
                }
                {this.state.connectionMode != 'global' && (this.state.clearentConfigClinic != undefined && this.state.clearentConfigClinic.length == 0) && <div className="row p2030"><div className="col-md-12">Please add clinics in your account.</div></div>
                }
                </div>
              }
              <Loader showLoader={this.state.showLoader} isFullWidth={true} />
            </div>
            {/* Update Stipe Info Modal - START */}
            <div className={this.state.isShowUpdateModal ? "modalOverlay" : 'no-display'}>
              <div className="small-popup-outer no-popup-scroll">
                <div className="small-popup-header">
                  <div className="popup-name">{this.state.settingsLang.pos_update_account_details}</div>
                  <a className="small-cross" onClick={this.handleUpdatModal} >×</a>
                </div>
                <div className="small-popup-content">
                  <div className="juvly-container">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.user_First_Name}<span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input name="first_name" value={this.state.first_name} onChange={this.handleCardInputChange} className={this.state.firstNameClass} type="text" autoComplete="off" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.user_Last_Name}<span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input name="last_name" value={this.state.last_name} onChange={this.handleCardInputChange} className={this.state.lastNameClass} type="text" autoComplete="off" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.post_treatment_email}<span className="setting-require">*</span></div>
                          <div className="setting-input-outer">
                            <input name="email" value={this.state.email} onChange={this.handleCardInputChange} className={this.state.emailClass} type="text" autoComplete="off" />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.pos_phone_number}</div>
                          <div className="setting-input-outer">
                            {(this.state.isRender) && <IntlTelInput
                              preferredCountries={['us']}
                              css={['intl-tel-input', this.state.phoneNumberClass]}
                              utilsScript={'libphonenumber.js'}
                              defaultValue={(this.state.phoneNumber) ? this.state.phoneNumber : ''}
                              defaultCountry={this.state.defaultCountry}
                              fieldName='phoneNumber'
                              onPhoneNumberChange={this.phoneNumberChanged.bind(this, 'phoneNumber')}
                              onPhoneNumberBlur={this.phoneNumberChanged.bind(this, 'phoneNumber')}
                              //placeholder="Phone Number"
                              separateDialCode={`true`}
                              onSelectFlag={this.phoneNumberChanged.bind(this, 'phoneNumber')}
                              autoPlaceholder={true}
                            />}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="footer-static">
                  <a className="new-blue-btn pull-right" onClick={this.updatePosStripeAccountFields}>{this.state.globalLang.label_save}</a>
                  <a className="new-white-btn pull-right" onClick={this.handleUpdatModal}>{this.state.globalLang.label_cancel}</a>
                </div>
                <Loader showLoader={this.state.showLoaderStripeUpdate} />
              </div>
            </div>
            {/* Update Stipe Info Modal - END */}
            {/* Alert Modal - START */}
            <div className={this.state.isShowAlertModal ? "modalOverlay" : 'no-display'}>
              <div className="small-popup-outer">
                <div className="small-popup-header">
                  <div className="popup-name">{this.state.globalLang.label_alert}</div>
                  <a className="small-cross" onClick={this.handleAlertModal} >×</a>
                </div>
                <div className="small-popup-content">
                  <div className="juvly-container">
                    <div className="row">
                      <div className="col-sm-12 col-xs-12">
                        <div className="newInputFileldOuter">
                          {(this.state.alertModalType != 'warning') &&
                            <div className="new-field-label font-size-12 alert-modal-title">{this.state.globalLang.are_you_sure}</div>
                          }
                          <div className="new-field-label font-size-12 alert-modal-content">{this.state.alertModalContent}</div>
                        </div>
                      </div>
                    </div>
                    <PosSwitchClinicPicker
                      isModalOpen={this.state.isShowAlertModal}
                      posType={this.state.connectionMode} 
                      value={this.state.posSwitchClinicId} 
                      onChange={(nextClinicId) => this.setState({
                        posSwitchClinicId: nextClinicId,
                      })}
                    />
                  </div>
                </div>
                <div className="footer-static">
                  <a className="new-white-btn pull-right" onClick={this.handleAlertModal}>{this.state.globalLang.label_cancel}</a>
                  {(this.state.alertModalType == 'pos_enabled') &&
                    <a className="new-red-btn pull-left" onClick={this.handlePosDisable} >{this.state.settingsLang.yes_disable_it}</a>
                  }
                  {(this.state.alertModalType == 'connection') &&
                    <a className="new-red-btn pull-left" onClick={this.handleStripeType}>{this.state.settingsLang.yes_change_it}</a>
                  }
                  {(this.state.alertModalType == 'pos_disconnect') &&
                    <a className="new-red-btn pull-left" onClick={this.disconnectPosStripeAcount}>{this.state.settingsLang.pos_yes_disconnect_it}</a>
                  }
                </div>
              </div>
            </div>
            {this.state.stripeOwnerEditModal.open && (
              <EditStripeOwnerModal
                stripeOwnerInfo={this.state.stripeOwnerInfo}
                onModalClose={this.onStripeOwnerModalClose}
                onModalSave={this.onStripeOwnerModalSave}
                stripe_user_id={this.state.stripeOwnerEditModal.stripe_user_id}
              />
            )}
            {/* Alert Modal - END */}
          </div>
          {this.state.showErrorDisableModal && 
            <RestrictionModal  closeModal={() => this.setState({ showErrorDisableModal: false })}>
              <p className='text-center'> 
                You cannot disconnect a POS that has active memberships. Please cancel the patient memberships that are associated with this account and then you can disconnect it.
              </p>
            </RestrictionModal>}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  returnState.stripeUpdateUrlData = state.StripeInfoUpdateReducer.stripeUpdateUrlData;

  if (state.SettingReducer.action === "GET_POS_DATA") {
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.posData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "CHANGE_POS_STATUS") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.changePosMode = true;
    }
  } else if (state.SettingReducer.action === "CHANGE_POS_CONNECTION_STATUS") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200 && state.SettingReducer.data.status != 201) {
      const message = state.SettingReducer?.data?.message;
      uiNotification.error(tCommon(`apiError.${message}`, languageData.global?.[message] || "Unable to change POS connection. Try again later."));
      returnState.showLoader = false
    } else {
      if (state.SettingReducer.data.status == 201) {
        returnState.resetConnectionAccount = true;
        returnState.message = languageData.global[state.SettingReducer.data.message]
      } else {
        toast.success(languageData.global[state.SettingReducer.data.message]);
        returnState.changeconnectionMode = true;
      }
    }
  } else if (state.SettingReducer.action === "CHECK_POS_STRIPE_BALANCE") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false
    } else {
      returnState.posStripeBalanceData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "DISCONNENCT_POS_STRIPE_ACCOUNT") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.posData = state.SettingReducer.data.data
      returnState.isDisconnect = true;
    }
  } else if (state.SettingReducer.action === "GET_POS_STRIPE_ACCOUNT_FIELDS") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false
    } else {
      returnState.posStripeUpdateData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "UPDATE_POS_STRIPE_ACCOUNT") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false
    } else {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.posData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "GET_POS_STRIPE_DASHBOARD_URL") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      if (state.SettingReducer.data.message == 'third_party_error') {
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false
    } else {
      //toast.success(languageData.global[state.SettingReducer.data.message]);
      returnState.posDashboardData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "EMPTY_DATA") {
  }
  else if (state.SettingReducer.action === "STRIPE_OWNER_INFO") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      toast.error(state.SettingReducer.data.message);
      returnState.showLoader = false;
    } else {
      returnState.stripeOwnerInfo = state.SettingReducer.data.data.data;
      returnState.stripeOwnerInfoTimestamp = new Date();
    }
  } else if (state.SettingReducer.action === "UPDATED_OWNER_INFO") {
    toast.dismiss();
    if (state.SettingReducer.data.status != 200) {
      toast.error(state.SettingReducer.data.message);
      returnState.showLoader = false;
    } else {
      toast.success(state.SettingReducer.data.message);
      returnState.showLoader = false;
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getPosData: getPosData,
    changePosStatus: changePosStatus,
    changePosConnectionStatus: changePosConnectionStatus,
    checkPosStripeBalance: checkPosStripeBalance,
    disconnectPosStripeAcount: disconnectPosStripeAcount,
    getPosStripeUpdateFields: getPosStripeUpdateFields,
    getPosStripeDashboardUrl: getPosStripeDashboardUrl,
    updatePosStripeAccountFields: updatePosStripeAccountFields,
    exportEmptyData: exportEmptyData,
    editStripeOwnerInfoAction,
    updateStripeOwnerInfoAction,
    fetchStripeUpdateUrl,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Pos));
