/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { Scrollbars } from "react-custom-scrollbars";
import { format } from "date-fns";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  autoScrolling,
  showFormattedDate,
  displayName,
  showWithoutRounding,
  decimalPlaces,
} from "../../Utils/services.js";
import Loader from "../Common/Loader.js";
import {
  getReconciliationData,
  saveReconciledData,
  fetchClinics,
  getProductDetails,
  getReconciledReports,
  downloadReconciledReport,
  checkIfReconciledForDay,
} from "../../Actions/Inventory/inventoryActions.js";
import InventorySidebar from "./InventorySidebar.js";

const apiDateFormat = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

const viewDateFormat = (date) => {
  return moment(date).format("MMMM DD, YYYY");
};

const initReconciliation = () => {
  return {
    product_inventory_id: "",
    in_stock: "",
    batch_id: "",
    expected_stock: "",
    deviation: "",
    deviation_type: "",
  };
};

class InventoryReconciliation extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const startDate = moment().startOf("week");
    const endDate = moment().endOf("week");
    this.state = {
      term: "",
      langData: languageData,
      global: languageData.global,
      inventory: languageData.inventory,
      showCalendar: false,
      showSolvePop: false,
      showTakeOverPop: false,
      dateRangePicker: {
        selection: {
          startDate: startDate,
          endDate: endDate,
          key: "selection",
        },
      },
      endDate: endDate,
      toDate: format(endDate, "YYYY-MM-DD"),
      startDate: startDate,
      fromDate: format(startDate, "YYYY-MM-DD"),
      reconciliationData: {},
      reconciliationList: [],
      finalReconciledData: [],
      deviatedProducts: [],
      reconciliation_array: [],
      viewReconciliationData: [],
      includeDates: [],
      page: 1,
      pagesize: 20,
      next_page_url: null,
      searchPickerDate: "",
      clinicId: 0,
      showClinics: false,
      showReconciledReport: false,
      productData: {},
      clinicName: "",
      dateError: false,
      showReconciliationClinics: false,
      reconcileType: "all",
      showInitialForReconciliation: true,
      showInitialForReconciledReport: false,
      showReconciliationData: false,
      showSelectDate: false,
      reason: "damage",
      reconciledBy: {},
    };

    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  componentDidMount() {
    document.addEventListener("click", this.handleClick, false);
    this.setState({ showLoader: true });
    this.props.fetchClinics();
  }

  getReconciliationData = (mode) => {
    let formData = {};
    this.setState({
      clinicReconciliationError: false,
      clinicReconciledError: false,
    });
    formData.params = {
      clinic_id:
        mode == "list" ? this.state.clinic_list_id : this.state.clinic_view_id,
      mode: mode,
    };

    if (mode == "list") {
      formData.params.is_need = 0;
      formData.params.page = this.state.page;
      formData.params.pagesize = this.state.pagesize;
    }
    if (!formData.params.clinic_id) {
      if (mode == "list") {
        this.setState({ clinicReconciliationError: true });
      } else {
        this.setState({ clinicReconciledError: true });
      }
      return false;
    }
    this.setState({ showLoader: true, mode: mode }, () => {
      autoScrolling(true);
      if (this.state.reconcileType != "few") {
        this.props.getReconciliationData(formData);
      } else {
        this.props.checkIfReconciledForDay({
          clinic_id: this.state.clinic_list_id,
        });
      }
    });
  };

  checkDeviatedProducts = (pId) => {
    if (this.state.deviatedProducts) {
      let x = this.state.deviatedProducts.findIndex((e) => e.product_id == pId);
      if (x < 0) {
        return false;
      } else {
        return x;
      }
    } else {
      return false;
    }
  };

  handleInputChange = (event) => {
    const deviationPrecision = 3;
    const target = event.target;
    let name = event.target.name;
    const value = target.type === "checkbox" ? target.checked : target.value;

    if (name.startsWith("expected_in_stock_") || name == "expected_stock") {
      if (decimalPlaces(event.target.value) > deviationPrecision) {
        return;
      }
    }

    let returnState = {};
    if (name.startsWith("expected_in_stock_")) {
      var nameArr = name.split("_");
      var id = nameArr[nameArr.length - 1];
      let deviatedProducts = JSON.parse(
        JSON.stringify(this.state.deviatedProducts),
      );
      let deviation = (
        Number(value) - Number(this.state["product_in_stock_" + id])
      ).toFixed(deviationPrecision);
      let index = this.checkDeviatedProducts(id);

      if (!index) {
        deviatedProducts.push({
          product_id: id,
          in_stock: value,
          deviation: deviation,
        });
      } else {
        deviatedProducts[index].in_stock = value;
        deviatedProducts[index].deviation = deviation;
      }
      let finalReconciledData = JSON.parse(
        JSON.stringify(this.state.finalReconciledData),
      );
      let index1 = finalReconciledData.findIndex((e) => e.product_id == id);
      if (index1 > -1) {
        finalReconciledData[index1].is_solved = false;
        returnState.finalReconciledData = finalReconciledData;
      }
      returnState["deviation_in_stock_" + id] = deviation;
      returnState.deviatedProducts = deviatedProducts;
    }

    if (name == "expected_stock") {
      if (decimalPlaces(event.target.value) > deviationPrecision) {
        return;
      }
      let id = event.currentTarget.dataset.index;
      let reconciliation_array = JSON.parse(
        JSON.stringify(this.state.reconciliation_array),
      );
      reconciliation_array[id].expected_stock = value;
      let dev = (
        Number(reconciliation_array[id].expected_stock) -
        Number(reconciliation_array[id].in_stock)
      ).toFixed(deviationPrecision);
      reconciliation_array[id].deviation = dev;
      returnState.reconciliation_array = reconciliation_array;
    }

    if (name == "clinic_list_id" || name == "clinic_view_id") {
      let clinic = this.state.clinicData.find((e) => e.id == value);
      returnState["clinicName"] = clinic.clinic_name;
    }

    returnState[event.target.name] = value;
    this.setState(returnState);
  };

  changeClinic = (clinicId) => {
    let formData = { params: {} };
    let ids = [];

    if (clinicId == 0) {
      this.state.clinicData &&
        this.props.clinicData.map((obj) => {
          ids.push(obj.id);
        });

      formData.params.clinic_id = ids;
      this.setState({ clinicName: "All Clinics", clinicId: 0 });
    } else {
      formData.params.clinic_id = clinicId;
      let clinic = this.state.clinicData.find((e) => e.id == clinicId);
      this.setState({
        clinicName: clinic.clinic_name,
        clinicId: clinicId,
        clinic_list_id: clinicId,
        showLoader: true,
        finalReconciledData: [],
        reconciliationList: [],
      });
    }

    formData.params.mode = "list";
    formData.params.page = 1;
    formData.params.pagesize = this.state.pagesize;
    autoScrolling(true);
    this.props.getReconciliationData(formData);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        term: this.state.term,
        clinic_id: this.state.clinic_list_id,
        mode: "list",
        is_need: 1,
      },
    };

    this.setState({
      page: 1,
      sortby: this.state.sortby,
      pagesize: this.state.pagesize,
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      reconciliationList: [],
      showLoader: true,
    });
    autoScrolling(true);
    this.props.getReconciliationData(formData);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: true,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortby: this.state.sortby,
          term: this.state.term,
          clinic_id: this.state.clinic_list_id,
          mode: "list",
          is_need: 1,
        },
      };
      autoScrolling(true);
      this.props.getReconciliationData(formData);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};

    if (
      nextProps.timeStamp != undefined &&
      nextProps.timeStamp != prevState.timeStamp
    ) {
      returnState.showLoader = false;
      returnState.includeDates = [];
      returnState.showInitialForReconciliation = true;
      returnState.showFinalPop = false;
      returnState.showReconciliationData = false;
    }

    if (
      nextProps.reloadStamp != undefined &&
      nextProps.reloadStamp != prevState.reloadStamp
    ) {
      returnState.showLoader = false;
      returnState.includeDates = [];
      returnState.showInitialForReconciliation = true;
      returnState.showFinalPop = false;
      returnState.showReconciliationData = false;
      returnState.reloadStamp = nextProps.reloadStamp;
    }

    if (
      nextProps.isReconciledForDay != undefined &&
      nextProps.isReconciledTime != prevState.isReconciledTime
    ) {
      returnState.showLoader = false;
      returnState.isReconciledTime = nextProps.isReconciledTime;

      if (nextProps.isReconciledForDay.data) {
        returnState.reconcileType = "all";
        let lang = JSON.parse(localStorage.getItem("languageData"));
        toast.error(lang.global["canot_reconcil"]);
      } else {
        returnState.showReconciliationData = true;
        returnState.showInitialForReconciliation = false;
      }
    }

    if (
      nextProps.reconciliationAlreadyInProgress != undefined &&
      nextProps.progressTimeStamp != prevState.progressTimeStamp
    ) {
      returnState.showLoader = false;
      returnState.showTakeOverPop = true;
      returnState.progressTimeStamp = nextProps.progressTimeStamp;
    }

    if (
      nextProps.clinicData != undefined &&
      nextProps.clinicData !== prevState.clinicData
    ) {
      returnState.clinicData = nextProps.clinicData;
      returnState.showLoader = false;
      returnState.includeDates = [];
    }
    if (
      nextProps.productData != undefined &&
      nextProps.productData !== prevState.productData
    ) {
      if (nextProps.productData.product_type != "others") {
        let reconciliation_array = [];
        let finalReconciledData = JSON.parse(
          JSON.stringify(prevState.finalReconciledData),
        );
        let index = finalReconciledData.findIndex(
          (e) => e.product_id == prevState.product_to_solve,
        );
        if (index > -1) {
          returnState.note = finalReconciledData[index].note;
          reconciliation_array =
            finalReconciledData[index].reconciliation_array;
        } else {
          nextProps.productData.product_inventory.map((obj) => {
            if (obj.id) {
              let x = JSON.parse(JSON.stringify(initReconciliation()));
              x.product_inventory_id = obj.id;
              x.batch_id = obj.batch_id;
              x.in_stock = obj.units;
              x.expected_stock = obj.units;
              x.deviation = 0;
              reconciliation_array.push(x);
            }
          });
        }
        returnState.reconciliation_array = reconciliation_array;
      }
      returnState.productData = nextProps.productData;
      returnState.showLoader = false;
      returnState.showSolvePop = true;
      returnState.includeDates = [];
    }

    if (
      nextProps.viewReconciliationDataMain != undefined &&
      nextProps.viewReconciliationDataMain !==
        prevState.viewReconciliationDataMain
    ) {
      let reconciledData = [];
      returnState.viewReconciliationDataMain =
        nextProps.viewReconciliationDataMain;
      returnState.reconciledBy = nextProps.viewReconciliationDataMain.user;

      if (
        nextProps.viewReconciliationDataMain &&
        nextProps.viewReconciliationDataMain.length > 0
      ) {
        nextProps.viewReconciliationDataMain.map((pobj) => {
          if (pobj && pobj.inventory_reconcilation) {
            pobj.inventory_reconcilation.map((obj) => {
              let reconciliation_array = [];
              let finalArr = {
                clinic_id: pobj.clinic_id,
                note: obj.note,
                reason: obj.reason,
                product_id: obj.product_id,
                product_name: obj.product.product_name,
                product_type: obj.product_type,
                in_stock: obj.in_stock,
                expected_stock: obj.expected_stock,
                deviation: obj.deviation,
                deviation_type: "",
                reconciliation_array: [],
              };

              if (
                obj.product.product_type != "others" &&
                obj.inventory_detail
              ) {
                obj.inventory_detail.map((objInner) => {
                  let x = JSON.parse(JSON.stringify(initReconciliation()));
                  let batch = objInner.batches;
                  x.batch_id = batch.batch_id;
                  x.expected_stock = objInner.expected_stock;
                  x.in_stock = objInner.in_stock;
                  x.deviation = objInner.deviation;
                  reconciliation_array.push(x);
                });
              }
              finalArr.reconciliation_array = reconciliation_array;
              reconciledData.push(finalArr);
            });
          }
        });
      }

      if (prevState.includeDates.length == 0) {
        returnState.includeDates = [
          new Date(nextProps.viewReconciliationDataMain.from_date),
        ];
        returnState.searchPickerDate = new Date(
          nextProps.viewReconciliationDataMain.from_date,
        );
        returnState.apiDateFormat =
          nextProps.viewReconciliationDataMain.from_date;
      }
      returnState.viewReconciliationData = reconciledData;

      returnState.showLoader = false;
      returnState.showFinalPop = false;
      returnState.showSelectDate = true;
      returnState.showReconciliationData = false;
    }

    if (
      nextProps.reconciliationData != undefined &&
      nextProps.reconciliationData !== prevState.reconciliationData &&
      nextProps.reconcileTime != prevState.reconcileTime
    ) {
      returnState.reconcileTime = nextProps.reconcileTime;
      if (nextProps.cannotReconcile) {
        returnState.showInitialForReconciliation = true;
        returnState.includeDates = [];
        returnState.viewReconciliationData = [];
        returnState.clinic_list_id = 0;
        returnState.clinic_view_id = 0;
        returnState.page = 1;
        returnState.term = "";
        returnState.clinicName = "";
        returnState.reconcileType = "all";
        returnState.showLoader = false;
        returnState.showReconciliationData = false;

        return returnState;
      }
      if (prevState.mode == "list") {
        returnState.reconciliationData = nextProps.reconciliationData;
        if (nextProps.reconciliationData.length == 0) {
          returnState.showInitialForReconciliation = true;
          returnState.reconciliationList = [];
          returnState.showLoader = false;
          return returnState;
        }
        let finalReconciledData = [];
        returnState.showInitialForReconciledReport = false;
        returnState.showInitialForReconciliation = false;
        returnState.showReconciliationData = true;
        returnState.showTakeOverPop = false;
        returnState.showFinalPop = false;
        returnState.includeDates = false;
        let clinic = prevState.clinicData.find(
          (e) => e.id == prevState.clinic_list_id,
        );
        returnState.clinicId = nextProps.reconciliationData.clinic_list_id;
        returnState.clinicName = clinic.clinic_name;
        returnState.showLoader = false;
        finalReconciledData = prevState.finalReconciledData;
        if (
          prevState.reconciliationList &&
          prevState.reconciliationList.length === 0
        ) {
          if (nextProps.reconciliationData.next_page_url !== null) {
            returnState.page = prevState.page + 1;
          }
          returnState.next_page_url =
            nextProps.reconciliationData.next_page_url;
          returnState.reconciliationList = nextProps.reconciliationData.data;
        } else {
          returnState.reconciliationList = [
            ...prevState.reconciliationList,
            ...nextProps.reconciliationData.data,
          ];
          returnState.page = prevState.page + 1;
          returnState.next_page_url =
            nextProps.reconciliationData.next_page_url;
        }

        if (returnState.reconciliationList) {
          returnState.reconciliationList.map((obj) => {
            let toGoInLoop = finalReconciledData.find(
              (e) => e.product_id == obj.product_id,
            );

            if (obj.deviation && !toGoInLoop) {
              let reconciliation_array = [];
              let reconcileArr = {
                clinic_id: prevState.clinic_list_id,
                note: obj.note,
                reason: obj.reason,
                product_id: obj.product_id,
                product_name: obj.product_name,
                product_type: obj.product_type,
                in_stock: obj.in_stock,
                expected_stock: obj.expected_stock,
                deviation: showWithoutRounding(obj.deviation),
                deviation_type: "",
                reconciliation_array: [],
                is_edit: false,
                is_solved: obj.is_solved,
                inventory_reconciliation_product_id:
                  obj.inventory_reconciliation_product_id,
              };

              if (obj.product_type != "others" && obj.product_inventory) {
                obj.product_inventory.map((objInner) => {
                  if (objInner && objInner.batches && objInner.batches.id) {
                    let x = JSON.parse(JSON.stringify(initReconciliation()));
                    let batch = objInner.batches;
                    x.batch_id = objInner.batch_id;
                    x.product_inventory_id = batch && batch.id;
                    x.expected_stock = batch?.expected_stock || 0;
                    x.in_stock = batch?.in_stock || 0;
                    x.deviation = showWithoutRounding(batch && batch.deviation);
                    x.product_inventory_id =
                      batch && batch.product_inventory_id;
                    x.inventory_reconciliation_product_id =
                      batch && batch.inventory_reconciliation_product_id;
                    reconciliation_array.push(x);
                  }
                });
              }
              reconcileArr.reconciliation_array = reconciliation_array;
              finalReconciledData.push(reconcileArr);
            }
            if (!toGoInLoop) {
              returnState.finalReconciledData = finalReconciledData;
              returnState["show-product-" + obj.product_id] = false;
              returnState["product_in_stock_" + obj.product_id] = obj.in_stock;
              returnState["expected_in_stock_" + obj.product_id] =
                obj.expected_stock;
              returnState["deviation_in_stock_" + obj.product_id] =
                showWithoutRounding(obj.deviation);
            }
          });
        }
        autoScrolling(false);
        returnState.includeDates = [];
      } else {
        autoScrolling(false);
        returnState.reconciliationData = nextProps.reconciliationData;
        let dates = [];
        if (
          nextProps.reconciliationData.reconciliation_dates &&
          nextProps.reconciliationData.reconciliation_dates.length > 0
        ) {
          nextProps.reconciliationData.reconciliation_dates.map((obj) => {
            dates.push(moment(obj).toDate());
          });
        } else {
          toast.dismiss();
          toast.error("There is no reconciled report for this clinic.");
          returnState.includeDates = dates;
          returnState.showLoader = false;
          returnState.includeDates = dates;
          return returnState;
        }
        returnState.showSelectDate = true;
        returnState.showInitialForReconciledReport = false;
        returnState.includeDates = dates;
        returnState.showLoader = false;
      }
    }

    if (
      nextProps.exportDetails !== undefined &&
      nextProps.exportDetails.status === 200 &&
      nextProps.exportDetails.data !== prevState.exportDetails
    ) {
      returnState.exportDetails = nextProps.exportDetails.data;
      returnState.showLoader = false;

      if (nextProps.exportDetails.data && nextProps.exportDetails.data.file) {
        window.open(
          process.env.REACT_APP_API_URL +
            "download-data/" +
            nextProps.exportDetails.data.file,
          "_blank",
        );
      }
    }

    return returnState;
  }

  dateRangeOnChange = (payload) => {
    let payloadValue = {};
    let startDate = payload.selection.startDate;
    let endDate = payload.selection.endDate;
    let clicked = this.state.clicked + 1;
    let localPref = localStorage.getItem("focusedRange");
    let canBypass = localPref && localPref === "oneClick" ? true : false;

    if (canBypass) {
      clicked = 2;
    }

    let showCalendar = true;

    if (clicked % 2 === 0) {
      showCalendar = false;
    }

    this.setState({
      showCalendar: showCalendar,
      fromDate: format(startDate, "YYYY-MM-DD"),
      toDate: format(endDate, "YYYY-MM-DD"),
      startDate: startDate,
      endDate: endDate,
      clicked: clicked,
      dateData: JSON.stringify(payloadValue),
    });

    if (clicked && clicked % 2 === 0) {
      if (payload) {
        payloadValue = {
          startDate: apiDateFormat(payload.selection.startDate),
          endDate: apiDateFormat(payload.selection.endDate),
          key: "selection",
        };
        localStorage.setItem(
          "insight-sales-date",
          JSON.stringify(payloadValue),
        );
      }

      let formData = {};

      formData.fromDate = apiDateFormat(startDate);
      formData.toDate = apiDateFormat(endDate);
      formData.canSubmit = true;
      formData.showLoader = true;

      if (
        this.props.parentState &&
        this.props.parentState.clinic_id &&
        this.props.parentState.clinic_id.length > 0
      ) {
        formData.clinic_id = this.props.parentState.clinic_id;
      }

      if (this.state.actionDetails.canShowChurnFilter) {
        formData.churn_filter = this.state.churn_filter;
      }

      if (
        this.state.actionDetails &&
        this.state.actionDetails.showComissionFilter
      ) {
        formData.report_type = this.props.parentState.report_type;
      }

      this.props.handleGrandChildFilter(formData);
    }
  };

  toggleCalendar = (elem) => {
    if (elem.name !== "calendar-input" && this.state.showCalendar === false) {
      return;
    }

    let showCalendar = false;

    if (
      this.state.showCalendar === false &&
      elem.name !== undefined &&
      elem.name === "calendar-input"
    ) {
      showCalendar = true;
    } else {
      showCalendar = false;
    }

    this.setState({ showCalendar: showCalendar, clicked: 0 });
  };

  handleClick = (event) => {
    if (
      this.state.showReconciliationClinics &&
      !this.refReconciliationClinics.contains(event.target)
    ) {
      this.setState({ showReconciliationClinics: false });
    }
    if (
      this.state.showReconciledReportsDrop &&
      !this.refReconciledClinics.contains(event.target)
    ) {
      this.setState({ showReconciledReportsDrop: false });
    }

    if (
      this.refDatePickerContainer &&
      this.refDatePicker &&
      !this.refDatePickerContainer.contains(event.target)
    ) {
      if (this.state.showDatePicker) {
        this.setState({ showDatePicker: false });
      }
    }

    if (
      this.node &&
      this.node.contains(event.target) &&
      this.state.showCalendar === true
    ) {
      return;
    }

    if (
      event.target.className !== "easy-link no-padding" &&
      this.state.showClinics === true
    ) {
      this.setState({ showClinics: false });
    }

    this.toggleCalendar(event.target);
  };

  handleDatePicker = (date) => {
    this.setState({ selectedPickerDate: date, showDatePicker: false });
    this.refDatePicker.setOpen(false);
  };

  resetDatePicker = () => {
    this.setState({ selectedPickerDate: null, showDatePicker: true });
    this.toggleDatePicker();
  };

  blurDatePicker = () => {
    this.refDatePicker.setOpen(true);
    this.setState({ showDatePicker: true });
  };

  focusDatePicker = () => {
    this.setState({ showDatePicker: true });
  };

  toggleDatePicker = () => {
    this.setState({ showDatePicker: true });
    this.refDatePicker.setFocus(true);
    this.refDatePicker.setOpen(true);
  };

  handleSearchDatePicker = (date) => {
    let formData = {};
    formData.params = {};
    formData.params.clinic_id = this.state.clinic_view_id;
    formData.params.from_date = apiDateFormat(date);
    this.setState({
      showLoader: true,
      showDatePicker: false,
      searchPickerDate: date,
      apiDateFormat: apiDateFormat(date),
    });
    this.props.getReconciledReports(formData);
  };

  hidePop = () => {
    this.setState({ showSolvePop: false });
  };

  toggleClinic = () => {
    this.setState({ showClinics: !this.state.showClinics });
  };

  toggleOkBtn = (id) => {
    let returnState = {};
    returnState["show-product-" + id] = true;
    this.setState(returnState);
  };

  saveDeviation = (id) => {
    let formData = {};
    formData.fromDate = apiDateFormat();
    formData.toDate = apiDateFormat();
    formData.note = this.state.note;
    formData.reason = this.state.reason;
    formData.mode = "single";
    formData.clinic_id = this.state.clinicId;
    formData.data = [
      { product_id: id, in_stock: this.state["expected_in_stock_" + id] },
    ];
    this.props.saveReconciledData(formData);
  };

  getProductDetails = (id) => {
    let formData = {};
    formData.params = {};
    formData.params.clinic_id = this.state.clinic_list_id;
    formData.params.product_id = id;
    this.setState(
      {
        product_to_solve: id,
        deviation_to_solve: this.state["deviation_in_stock_" + id],
        showLoader: true,
      },
      () => {
        this.props.getProductDetails(formData);
      },
    );
  };

  saveSolvedProduct = () => {
    this.setState({ noteError: false });

    if (this.state.note == "" || this.state.note == undefined) {
      this.setState({ noteError: true });
      return false;
    }

    let reconciliation_array = JSON.parse(
      JSON.stringify(this.state.reconciliation_array),
    );
    let qtyTobeSolved =
      this.state["expected_in_stock_" + this.state.product_to_solve];
    let totalSolvedQty = 0;
    if (reconciliation_array) {
      reconciliation_array.map((obj) => {
        totalSolvedQty += parseFloat(obj.expected_stock);
      });
    }

    if (
      this.state.productData.product_type != "others" &&
      reconciliation_array.length == 0
    ) {
      this.setState({ note: "", reason: "damage" });
      toast.error(
        "There are no batches for this product. Please update product inventory.",
      );
      return false;
    }

    if (
      parseInt(qtyTobeSolved) != parseInt(totalSolvedQty) &&
      this.state.productData.product_type != "others"
    ) {
      toast.error(
        "Sum of In Stock of all batches should be equal to In Stock of product.",
      );
      return false;
    }
    let finalReconciledData = JSON.parse(
      JSON.stringify(this.state.finalReconciledData),
    );
    let index = finalReconciledData.findIndex(
      (e) => e.product_id == this.state.product_to_solve,
    );

    if (index < 0) {
      finalReconciledData.push({
        clinic_id: this.state.clinic_list_id,
        note: this.state.note,
        reason: this.state.reason,
        product_id: this.state.product_to_solve,
        is_solved: 1,
        product_type: this.state.productData.product_type,
        product_name: this.state.productData.product_name,
        in_stock: this.state["product_in_stock_" + this.state.product_to_solve],
        expected_stock:
          this.state["expected_in_stock_" + this.state.product_to_solve],
        deviation:
          this.state["deviation_in_stock_" + this.state.product_to_solve],
        deviation_type: "",
        reconciliation_array: this.state.reconciliation_array,
        is_edit: false,
      });
    } else {
      finalReconciledData[index].is_edit = true;
      finalReconciledData[index].is_solved = 1;
      finalReconciledData[index].note = this.state.note;
      finalReconciledData[index].reason = this.state.reason;
      finalReconciledData[index].reconciliation_array =
        this.state.reconciliation_array;
      finalReconciledData[index].in_stock =
        this.state["product_in_stock_" + this.state.product_to_solve];
      finalReconciledData[index].expected_stock =
        this.state["expected_in_stock_" + this.state.product_to_solve];
      finalReconciledData[index].deviation =
        this.state["deviation_in_stock_" + this.state.product_to_solve];
      finalReconciledData[index].reconciliation_array =
        this.state.reconciliation_array;
    }

    this.setState({
      finalReconciledData: finalReconciledData,
      reconciliation_array: [],
      showSolvePop: false,
      note: "",
      reason: "damage",
    });
  };

  saveReconciledData = (mode) => {
    let formData = {};
    if (this.state.finalReconciledData.length) {
      formData.data = this.state.finalReconciledData;
      formData.report_status = mode;
      this.setState({
        finalFormData: formData,
        showFinalPop: true,
        report_status: mode,
      });
    } else {
      toast.dismiss();
      toast.error(
        "There is no deviation in any product or deviation hasn't been resolved.",
      );
    }
  };

  finalSaveReconcilliation = () => {
    this.setState({
      showLoader: true,
      reconciliationList: [],
      finalReconciledData: [],
    });
    this.props.saveReconciledData(this.state.finalFormData);
  };
  takeItOver = () => {
    let formData = {
      params: {
        mode: "list",
        clinic_id: this.state.clinic_list_id,
        is_need: 1,
        page: 1,
        pagesize: 20,
      },
    };
    this.setState({ showLoader: true });
    this.props.getReconciliationData(formData);
  };

  componentDidUpdate = (_, state) => {
    if (this.state.showSolvePop || this.state.showFinalPop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    if (
      this.state.reloadStamp !== null &&
      this.state.reloadStamp !== state.reloadStamp
    ) {
      document.addEventListener("click", this.handleClick, false);
      this.setState({
        showLoader: true,
        clinic_list_id: "0",
        clinicId: "0",
        clinicName: "",
      });
      this.props.fetchClinics();
    }
  };

  selectClinicForReconciliation = (cId) => {
    let returnState = {};
    let clinic = this.state.clinicData.find((e) => e.id == cId);
    returnState.clinic_list_id = cId;
    returnState.clinicId = cId;
    returnState.clinicName = clinic.clinic_name;
    returnState.showReconciliationClinics = false;
    this.setState(returnState);
  };

  selectClinicForReports = (cId) => {
    let returnState = {};
    let clinic = this.state.clinicData.find((e) => e.id == cId);
    returnState.clinic_view_id = cId;
    returnState.clinicId = cId;
    returnState.clinicName = clinic.clinic_name;
    returnState.showReconciledReportsDrop = false;
    this.setState(returnState);
  };

  exportData = (mode) => {
    let formData = {
      params: {
        clinic_id: this.state.clinic_view_id
          ? this.state.clinic_view_id
          : this.state.clinic_list_id,
        from_date: this.state.apiDateFormat,
        is_export: 1,
        file_type: mode ? mode : "xls",
      },
    };

    this.setState({ showLoader: true });
    this.props.downloadReconciledReport(formData);
  };

  backToMainScreen = (mode) => {
    let returnState = {};
    returnState.showInitialForReconciliation = true;
    returnState.includeDates = [];
    returnState.viewReconciliationData = [];
    returnState.showReconciliationData = false;
    returnState.clinic_list_id = 0;
    returnState.clinic_view_id = 0;
    returnState.page = 1;
    returnState.term = "";
    returnState.clinicName = "";
    returnState.reconcileType = "all";

    if (mode == "reconciled-report") {
      returnState.showInitialForReconciledReport = false;
      returnState.showSelectDate = false;
      returnState.searchPickerDate = "";
    } else {
      returnState.showReconciliationData = false;
      returnState.reconciliationList = [];
      returnState.finalReconciledData = [];
      returnState.clinic_list_id = 0;
      returnState.viewReconciliationData = [];
    }
    this.setState(returnState);
  };

  render() {
    return (
      <div className="main protected users-settings">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <InventorySidebar />
            <div className="memberWalletOuter business-section">
              <div className="setting-setion m-b-10">
                <div className="membership-title">
                  <span className="cursor-pointer">
                    {this.state.clinicName
                      ? this.state.clinicName
                      : this.state.global.inventory_reconciliation}
                  </span>
                  <div className="memberRightActions">
                    <a
                      className={
                        !this.state.showInitialForReconciliation ||
                        this.state.showInitialForReconciledReport
                          ? "no-display"
                          : "easy-link pull-right m-r-0"
                      }
                      onClick={() =>
                        this.setState({
                          showInitialForReconciledReport: true,
                          showInitialForReconciliation: false,
                          clinicName: "",
                          clinic_list_id: "",
                          clinic_view_id: "",
                        })
                      }
                    >
                      View Past Reconciled Reports
                    </a>

                    <button
                      onClick={this.backToMainScreen.bind(
                        this,
                        "reconciled-report",
                      )}
                      className={
                        this.state.showInitialForReconciledReport ||
                        this.state.showSelectDate
                          ? "new-blue-btn pull-right"
                          : "no-display"
                      }
                    >
                      Back
                    </button>

                    <button
                      onClick={this.backToMainScreen.bind(
                        this,
                        "reconciliation",
                      )}
                      className={
                        !this.state.showReconciledReport &&
                        this.state.showReconciliationData
                          ? "new-blue-btn pull-right"
                          : "no-display"
                      }
                    >
                      Back
                    </button>

                    <a
                      className={
                        this.state.viewReconciliationData &&
                        this.state.viewReconciliationData.length > 0
                          ? "easy-link no-padding"
                          : "no-display"
                      }
                      onClick={this.exportData.bind(this, "xls")}
                    >
                      <i className="fa fa-download m-r-5" /> Download Excel
                    </a>
                    <a
                      className={
                        this.state.viewReconciliationData &&
                        this.state.viewReconciliationData.length > 0
                          ? "easy-link no-padding"
                          : "no-display"
                      }
                      onClick={this.exportData.bind(this, "csv")}
                    >
                      <i className="fa fa-download m-r-5" /> Download CSV
                    </a>
                    {this.state.showReconciliationData &&
                      this.state.finalReconciledData &&
                      this.state.finalReconciledData.length > 0 && (
                        <span className="reconciledProducts">
                          {" "}
                          {this.state.finalReconciledData.length +
                            (this.state.finalReconciledData.length == 1
                              ? " product reconciled"
                              : " products reconciled")}
                        </span>
                      )}
                  </div>
                </div>
              </div>
              <div className={"row"}>
                <div
                  className={
                    this.state.showInitialForReconciliation ? "" : " no-display"
                  }
                >
                  <div className="col-xs-12">
                    <div className="setting-setion m-b-10">
                      <div className="setting-container p20 p-t-10">
                        <div className="row">
                          <div className="settings-subtitle m-b-0 m-t-10 col-xs-12 bold">
                            {this.state.langData.settings.twoFA_step1} -{" "}
                            {this.state.global.pick_clinic}
                          </div>
                          <div className="col-sm-6 col-xs-12">
                            <div className="newInputFileldOuter">
                              <div className="newInputLabel">
                                {this.state.langData.sales.sales_select_clinic}{" "}
                                <span className="setting-require">*</span>
                              </div>

                              <div
                                className={
                                  this.state.clinicReconciliationError
                                    ? "newSelectField customSelectbox field-error"
                                    : "newSelectField customSelectbox"
                                }
                                onClick={() =>
                                  this.setState({
                                    showReconciliationClinics: true,
                                  })
                                }
                                ref={(refReconciliationClinics) =>
                                  (this.refReconciliationClinics =
                                    refReconciliationClinics)
                                }
                              >
                                {this.state.clinicName
                                  ? this.state.clinicName
                                  : "Select Clinic"}{" "}
                              </div>
                              <div
                                className={
                                  this.state.showReconciliationClinics
                                    ? "customSelectOption"
                                    : "no-display"
                                }
                              >
                                <ul>
                                  <Scrollbars
                                    style={{ minHeight: "200px" }}
                                    className="full-width"
                                    renderTrackHorizontal={(props) => (
                                      <div
                                        {...props}
                                        style={{ display: "none" }}
                                        className="track-horizontal"
                                      />
                                    )}
                                  >
                                    {this.state.clinicData &&
                                      this.state.clinicData.map((obj) => {
                                        return (
                                          <li
                                            key={"clinic_list_id_" + obj.id}
                                            onClick={this.selectClinicForReconciliation.bind(
                                              this,
                                              obj.id,
                                            )}
                                          >
                                            {obj.clinic_name}
                                          </li>
                                        );
                                      })}
                                  </Scrollbars>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="ReconciliationOuter">
                          <div className="settings-subtitle m-b-5  m-t-20 bold">
                            {this.state.langData.settings.twoFA_step2} -{" "}
                            {this.state.global.what_to_reconcile}
                          </div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input
                              type="radio"
                              name="reconcileType"
                              className="basic-form-checkbox"
                              value="all"
                              id="cosmetic"
                              checked={this.state.reconcileType === "all"}
                              onChange={this.handleInputChange}
                            />
                            <label
                              htmlFor="cosmetic"
                              className="basic-form-text"
                            >
                              {this.state.global.all_inventory}
                            </label>
                          </div>
                          <div className="basic-checkbox-outer m-b-0">
                            <input
                              type="radio"
                              name="reconcileType"
                              className="basic-form-checkbox"
                              value="few"
                              id="health"
                              checked={this.state.reconcileType === "few"}
                              onChange={this.handleInputChange}
                            />
                            <label htmlFor="health" className="basic-form-text">
                              {this.state.global.only_few}
                            </label>
                          </div>
                        </div>
                        <a
                          onClick={this.getReconciliationData.bind(
                            this,
                            "list",
                          )}
                          className="new-blue-btn no-margin no-width m-t-5"
                        >
                          Go
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.state.showInitialForReconciledReport
                      ? "col-sm-12"
                      : "col-sm-12  no-display"
                  }
                >
                  <div className="setting-setion m-b-10">
                    <div className="mainSectionTitle">
                      <b>{this.state.global.view_reconciled_reports}</b>
                    </div>
                    <div className="setting-container p20 p-t-10">
                      <div className="newInputFileldOuter">
                        <div className="newInputLabel">
                          {this.state.global.required_select_clinic}{" "}
                          <span className="setting-require">*</span>
                        </div>
                        <div
                          className={
                            this.state.clinicReconciledError
                              ? "newSelectField customSelectbox field-error"
                              : "newSelectField customSelectbox"
                          }
                          onClick={() =>
                            this.setState({ showReconciledReportsDrop: true })
                          }
                          ref={(refReconciledClinics) =>
                            (this.refReconciledClinics = refReconciledClinics)
                          }
                        >
                          {this.state.clinicName
                            ? this.state.clinicName
                            : this.state.global.required_select_clinic}
                        </div>
                        <div
                          className={
                            this.state.showReconciledReportsDrop
                              ? "customSelectOption"
                              : "no-display"
                          }
                        >
                          <ul>
                            <Scrollbars
                              style={{ minHeight: "200px" }}
                              className="full-width"
                              renderTrackHorizontal={(props) => (
                                <div
                                  {...props}
                                  style={{ display: "none" }}
                                  className="track-horizontal"
                                />
                              )}
                            >
                              {this.state.clinicData &&
                                this.state.clinicData.map((obj) => {
                                  return (
                                    <li
                                      key={"clinic_list_id_" + obj.id}
                                      onClick={this.selectClinicForReports.bind(
                                        this,
                                        obj.id,
                                      )}
                                    >
                                      {obj.clinic_name}
                                    </li>
                                  );
                                })}
                            </Scrollbars>
                          </ul>
                        </div>
                      </div>
                      <a
                        onClick={this.getReconciliationData.bind(this, "view")}
                        className="new-blue-btn no-margin no-width m-t-5"
                      >
                        {this.state.global.inventory_reconciliation_go}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.showSelectDate && (
                <div className="row">
                  <div className="col-xs-12">
                    <div className="setting-setion m-b-10">
                      <div className="membership-title">
                        <div className="row">
                          <div
                            className={
                              this.state.viewReconciliationData &&
                              this.state.viewReconciliationData.length > 0
                                ? "col-sm-8  col-xs-12 p-t-0"
                                : "no-display"
                            }
                            style={{ "padding-top": "12px" }}
                          >
                            <span className="">
                              {`Reconciliation Report for `}{" "}
                              {showFormattedDate(this.state.apiDateFormat)}
                            </span>
                            {this.state.reconciledBy && (
                              <div>
                                {` Reconciled By`} -{" "}
                                {this.state.reconciledBy &&
                                  displayName(this.state.reconciledBy)}
                              </div>
                            )}
                          </div>
                          <div className="col-sm-4  col-xs-12">
                            <div className="simpleField field-icon no-margin">
                              <div className="simpleLabel">{"Select Date"}</div>
                              <div
                                className=" appointmant-date-picker"
                                ref={(refDatePickerContainer) =>
                                  (this.refDatePickerContainer =
                                    refDatePickerContainer)
                                }
                              >
                                <a
                                  className="simple-cal-icon"
                                  onClick={this.toggleDatePicker}
                                ></a>

                                <DatePicker
                                  value={
                                    this.state.searchPickerDate
                                      ? viewDateFormat(
                                          this.state.searchPickerDate,
                                        )
                                      : null
                                  }
                                  onChange={this.handleSearchDatePicker}
                                  className={
                                    (
                                      this.state.dateError
                                        ? "input-cal simpleInput field-error"
                                        : "input-cal simpleInput"
                                    )
                                      ? "input-cal simpleInput"
                                      : "setting-field-outer no-display"
                                  }
                                  dateFormat="YYYY-MM-dd"
                                  includeDates={this.state.includeDates}
                                  name="searchPickerDate"
                                  selected={
                                    this.state.searchPickerDate != ""
                                      ? this.state.searchPickerDate
                                      : null
                                  }
                                  autoComplete="off"
                                  showDisabledMonthNavigation
                                  ref={(refDatePicker) =>
                                    (this.refDatePicker = refDatePicker)
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {this.state.viewReconciliationData &&
                        this.state.viewReconciliationData.length > 0 && (
                          <div className="table-responsive">
                            <table className="table-updated setting-table no-td-border weeklyInventoryTable table-min-width">
                              <thead className="table-updated-thead">
                                <tr>
                                  <th className="table-updated-th col-xs-3 p-l-25">
                                    {
                                      this.state.inventory
                                        .inventory_product_name_label
                                    }
                                  </th>
                                  <th className="table-updated-th col-xs-2 p-l-10 p-r-0">
                                    {this.state.global.expected_stock}
                                  </th>
                                  <th className="table-updated-th col-xs-2 p-l-10 p-r-0">
                                    {this.state.global.in_stock}
                                  </th>
                                  <th className=" table-updated-th text-left col-xs-2 p-l-10">
                                    {this.state.global.deviation}
                                  </th>
                                  <th className=" table-updated-th text-left col-xs-3 p-l-10">
                                    {this.state.global.global_reason}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ajax_body">
                                {this.state.viewReconciliationData &&
                                  this.state.viewReconciliationData.map(
                                    (objOuter, i) => {
                                      return (
                                        <React.Fragment key={i}>
                                          <tr>
                                            <td colSpan="5">
                                              <table className="full-width weeklyInventory">
                                                <tbody>
                                                  <tr>
                                                    <td className=" table-updated-td word-break col-xs-3 p-l-25">
                                                      {" "}
                                                      {objOuter.product_name}
                                                    </td>
                                                    <td className=" table-updated-td word-break col-xs-2">
                                                      {showWithoutRounding(
                                                        objOuter.in_stock,
                                                      )}{" "}
                                                    </td>
                                                    <td className=" table-updated-td word-break col-xs-2">
                                                      {showWithoutRounding(
                                                        objOuter.expected_stock,
                                                      )}{" "}
                                                    </td>
                                                    <td className=" table-updated-td word-break text-left col-xs-2">
                                                      {" "}
                                                      {showWithoutRounding(
                                                        objOuter.deviation,
                                                      )}
                                                    </td>
                                                    <td className=" table-updated-td word-break text-left col-xs-3">
                                                      {" "}
                                                      ({objOuter.reason}){" "}
                                                      {objOuter.note}
                                                    </td>
                                                  </tr>
                                                  {objOuter.reconciliation_array &&
                                                    objOuter
                                                      .reconciliation_array
                                                      .length > 0 && (
                                                      <tr>
                                                        <td
                                                          className=" table-updated-td word-break no-border p-t-0 tdtextarea childTable bg-light-blue"
                                                          colSpan="5"
                                                        >
                                                          <table className="table-updated setting-table no-td-border weeklyInventoryTable">
                                                            <React.Fragment>
                                                              <thead className="table-updated-thead">
                                                                <tr>
                                                                  <td
                                                                    colSpan="5"
                                                                    className="table-updated-th col-xs-6 p-l-25"
                                                                  >
                                                                    Batches
                                                                    resolved for
                                                                    product{" "}
                                                                    {
                                                                      objOuter.product_name
                                                                    }
                                                                  </td>
                                                                </tr>
                                                              </thead>
                                                              <tr>
                                                                <td colSpan="5">
                                                                  <table className="full-width weeklyInventory">
                                                                    <tbody>
                                                                      <tr>
                                                                        <td className="table-updated-th col-xs-5 p-l-25 bold">
                                                                          {
                                                                            this
                                                                              .state
                                                                              .global
                                                                              .inventory_reconciliation_batch_name
                                                                          }
                                                                        </td>
                                                                        <td className="table-updated-th col-xs-4 bold">
                                                                          {
                                                                            this
                                                                              .state
                                                                              .global
                                                                              .expected_stock
                                                                          }
                                                                        </td>
                                                                        <td className="table-updated-th col-xs-3 text-left bold">
                                                                          {
                                                                            this
                                                                              .state
                                                                              .global
                                                                              .in_stock
                                                                          }
                                                                        </td>
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </td>
                                                              </tr>
                                                            </React.Fragment>

                                                            {objOuter.reconciliation_array.map(
                                                              (obj) => {
                                                                return (
                                                                  <tr
                                                                    key={
                                                                      "solvable12-" +
                                                                      obj.batch_id
                                                                    }
                                                                  >
                                                                    <td colSpan="5">
                                                                      <table className="full-width weeklyInventory">
                                                                        <tbody>
                                                                          <tr>
                                                                            <td className=" table-updated-td word-break col-xs-5 p-l-25">
                                                                              {
                                                                                obj.batch_id
                                                                              }
                                                                            </td>
                                                                            <td className=" table-updated-td word-break col-xs-4">
                                                                              {showWithoutRounding(
                                                                                obj.in_stock,
                                                                              )}
                                                                            </td>
                                                                            <td className=" table-updated-td word-break text-left col-xs-3">
                                                                              {" "}
                                                                              {showWithoutRounding(
                                                                                obj.expected_stock,
                                                                              )}{" "}
                                                                            </td>
                                                                          </tr>
                                                                        </tbody>
                                                                      </table>
                                                                    </td>
                                                                  </tr>
                                                                );
                                                              },
                                                            )}
                                                          </table>
                                                        </td>
                                                      </tr>
                                                    )}
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      );
                                    },
                                  )}
                              </tbody>
                            </table>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
              <div
                className={
                  this.state.showReconciliationData
                    ? "setting-setion m-b-10"
                    : "no-display"
                }
              >
                <div className="membership-title">
                  <form
                    onSubmit={this.handleSubmit}
                    className="searchForm m-l-0"
                  >
                    <span className="searchOuter">
                      <i className="fa fa-search search-icon" />
                      <input
                        className="setting-search-input search-key"
                        name="term"
                        placeholder={
                          this.state.global
                            .inventory_reconciliation_search_product
                        }
                        autoComplete="off"
                        value={this.state.term}
                        onChange={this.handleInputChange}
                      />
                    </span>
                  </form>
                  <div className="memberRightActions">
                    <div
                      className={
                        this.state.reconciliationList &&
                        this.state.reconciliationList.length
                          ? "saveDraft"
                          : "no-display"
                      }
                    >
                      <button
                        onClick={this.saveReconciledData.bind(this, "saved")}
                        className="new-blue-btn pull-right"
                      >
                        {this.state.global.inventory_reconciliation_save_update}
                      </button>
                      <button
                        onClick={this.saveReconciledData.bind(this, "draft")}
                        className="new-blue-btn pull-right"
                      >
                        {this.state.global.inventory_reconciliation_save_draft}
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.showReconciliationData
                    ? "juvly-section full-width"
                    : "no-display"
                }
              >
                <div className="table-responsive">
                  <table className="table-updated setting-table no-td-border min-w-600 no-hover">
                    <thead className="table-updated-thead">
                      <tr>
                        <th className="table-updated-th col-xs-3 sorting">
                          {"Product"}
                        </th>
                        <th className=" table-updated-th col-xs-2 sorting text-left">
                          {this.state.langData.sales.sales_category}
                        </th>
                        <th className=" table-updated-th col-xs-2 sorting text-left">
                          {this.state.global.expected_stock}
                        </th>
                        <th className=" table-updated-th col-xs-2 sorting text-left">
                          {this.state.global.in_stock}
                        </th>
                        <th className=" table-updated-th col-xs-1 sorting text-left">
                          {this.state.global.deviation}
                        </th>
                        <th className=" table-updated-th col-xs-2 sorting text-left">
                          {this.state.global.label_action}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="ajax_body">
                      {this.state.reconciliationList &&
                        this.state.reconciliationList.length > 0 &&
                        this.state.reconciliationList.map((obj) => {
                          let showSolveBtn = false;
                          let showSolvedBtn = false;
                          let finalReconciledData = JSON.parse(
                            JSON.stringify(this.state.finalReconciledData),
                          );
                          let index1 = finalReconciledData.findIndex(
                            (e) => e.product_id == obj.product_id,
                          );
                          if (
                            obj.deviation &&
                            obj.deviation !=
                              this.state["deviation_in_stock_" + obj.product_id]
                          ) {
                            showSolveBtn = true;
                          } else if (
                            !obj.deviation &&
                            this.state[
                              "deviation_in_stock_" + obj.product_id
                            ] != 0
                          ) {
                            showSolveBtn = true;
                          }

                          if (obj.is_solved) {
                            showSolvedBtn = true;
                          }

                          if (
                            index1 > -1 &&
                            finalReconciledData[index1].is_solved
                          ) {
                            showSolvedBtn = true;
                          } else if (
                            index1 > -1 &&
                            !finalReconciledData[index1].is_solved
                          ) {
                            showSolvedBtn = false;
                          }

                          if (showSolvedBtn) {
                            showSolveBtn = false;
                          }
                          return (
                            <tr
                              className="table-updated-tr"
                              key={"products-" + obj.product_id}
                            >
                              <td className="table-updated-td word-break">
                                {obj.product_name}
                              </td>
                              <td className="table-updated-td word-break text-left">
                                {obj.category_name}
                              </td>
                              <td className="table-updated-td word-break text-left">
                                {" "}
                                {obj.in_stock}
                              </td>
                              <td className="table-updated-td word-break text-left">
                                <input
                                  name={"expected_in_stock_" + obj.product_id}
                                  id="name"
                                  className={"tableInputTax"}
                                  value={
                                    this.state[
                                      "expected_in_stock_" + obj.product_id
                                    ]
                                  }
                                  autoComplete="off"
                                  type="text"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </td>
                              <td className="table-updated-td word-break text-left">
                                {
                                  this.state[
                                    "deviation_in_stock_" + obj.product_id
                                  ]
                                }
                              </td>
                              <td className="table-updated-td word-break ">
                                <button
                                  className={
                                    showSolveBtn
                                      ? "new-blue-btn no-width m-l-5 p-r-10 p-l-10"
                                      : "no-display"
                                  }
                                  onClick={this.getProductDetails.bind(
                                    this,
                                    obj.product_id,
                                  )}
                                >
                                  {
                                    this.state.global
                                      .inventory_reconciliation_solve
                                  }
                                </button>
                                <button
                                  className={
                                    showSolvedBtn
                                      ? "new-green-btn no-width m-l-5 p-r-10 p-l-10"
                                      : "no-display"
                                  }
                                >
                                  {
                                    this.state.global
                                      .inventory_reconciliation_solved
                                  }
                                </button>
                              </td>
                            </tr>
                          );
                        })}

                      {this.state.showLoader === false &&
                        (!this.state.reconciliationList ||
                          this.state.reconciliationList.length <= 0) && (
                          <tr>
                            <td
                              colSpan={6}
                              className="col-xs-12 table-updated-td word-break text-center "
                            >
                              <div
                                className={
                                  this.state.reconciliationList.length
                                    ? "no-record no-display"
                                    : "no-record"
                                }
                              >
                                {this.state.global.sorry_no_record_found}
                              </div>
                            </td>
                          </tr>
                        )}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className={
                  this.state.showSolvePop
                    ? "blackOverlay"
                    : "blackOverlay no-display"
                }
              >
                <div className="vert-middle vert-middle-report-option">
                  <div className="white-popup large-popup">
                    <div className="white-popup-wrapper p-b-0">
                      <div className="membershipTypeTitle">
                        {
                          this.state.global
                            .inventory_reconciliation_daily_message
                        }
                        <a onClick={this.hidePop} className="popupClose">
                          <img alt="" src="/images/popupClose.png" />
                        </a>
                      </div>
                      <p className="m-b-15">
                        {this.state.global.inventory_reconciliation_mismatch}
                      </p>
                    </div>
                    <table className="table-updated setting-table no-td-border weeklyInventoryTable">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="table-updated-th col-xs-5 p-l-25">
                            {this.state.inventory.inventory_product_name_label}
                          </th>
                          <th className="table-updated-th col-xs-2 p-l-10 p-r-0">
                            {this.state.global.expected_stock}
                          </th>
                          <th className="table-updated-th col-xs-3 p-l-10 p-r-0">
                            {this.state.global.in_stock}
                          </th>
                          <th className=" table-updated-th text-left col-xs-2 p-l-10">
                            {this.state.global.deviation}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="ajax_body">
                        <tr>
                          <td colSpan="4">
                            <table className="full-width weeklyInventory">
                              <tbody>
                                <tr>
                                  <td className=" table-updated-td word-break col-xs-5 p-l-25">
                                    {" "}
                                    {this.state.productData &&
                                      this.state.productData.product_name}
                                  </td>
                                  <td className=" table-updated-td word-break col-xs-2">
                                    {this.state.productData &&
                                      this.state[
                                        "product_in_stock_" +
                                          this.state.productData.id
                                      ]}{" "}
                                  </td>
                                  <td className=" table-updated-td word-break col-xs-3">
                                    {this.state.productData &&
                                      this.state[
                                        "expected_in_stock_" +
                                          this.state.productData.id
                                      ]}{" "}
                                  </td>
                                  <td className=" table-updated-td word-break text-left col-xs-2">
                                    {" "}
                                    {this.state.deviation_to_solve}
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className=" table-updated-td word-break no-border p-t-0 tdtextarea"
                                    colSpan="4"
                                  >
                                    <div className="newInputFileldOuter">
                                      <div className="newInputLabel">
                                        {this.state.global.global_reason}
                                        <span className="setting-require">
                                          *
                                        </span>
                                      </div>
                                      <select
                                        className="newSelectField"
                                        name="reason"
                                        onChange={this.handleInputChange}
                                        value={this.state.reason}
                                      >
                                        <option value="damage">
                                          {
                                            this.state.global
                                              .inventory_reconciliation_damage
                                          }
                                        </option>
                                        <option value="shrinkage">
                                          {
                                            this.state.global
                                              .inventory_reconciliation_shrinkage
                                          }
                                        </option>
                                        <option value="sample">
                                          {
                                            this.state.global
                                              .inventory_reconciliation_sample
                                          }
                                        </option>
                                        <option value="expired">
                                          {
                                            this.state.langData.inventory
                                              .inventory_expired
                                          }
                                        </option>
                                        <option value="clinical_use">{`Clinical Use`}</option>
                                        <option value="others">
                                          {
                                            this.state.langData.inventory
                                              .inventory_others
                                          }
                                        </option>
                                      </select>
                                    </div>
                                    <div className="newInputFileldOuter">
                                      <div className="newInputLabel">
                                        {
                                          this.state.langData.clients
                                            .clientprofile_notes
                                        }
                                        <span className="setting-require">
                                          *
                                        </span>{" "}
                                      </div>
                                      <div className="setting-input-outer">
                                        <textarea
                                          className={
                                            this.state.noteError
                                              ? "newtextareaField m-h-100 field-error"
                                              : "newtextareaField m-h-100"
                                          }
                                          name="note"
                                          value={this.state.note}
                                          onChange={this.handleInputChange}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>

                        {this.state.reconciliation_array &&
                          this.state.reconciliation_array.length > 0 && (
                            <React.Fragment>
                              <tr>
                                <td colSpan="4">
                                  <table className="table-updated setting-table no-td-border weeklyInventoryTable">
                                    <thead className="table-updated-thead">
                                      <tr>
                                        <td
                                          colSpan="4"
                                          className="table-updated-th col-xs-6 p-l-25"
                                        >
                                          Resolve {this.state.global.deviation}{" "}
                                          in Batches
                                        </td>
                                      </tr>
                                    </thead>
                                  </table>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="4">
                                  <table className="full-width weeklyInventory">
                                    <tr>
                                      <th className="table-updated-th col-xs-5 p-l-25 bold">
                                        {
                                          this.state.global
                                            .inventory_reconciliation_batch_name
                                        }
                                      </th>
                                      <th className="table-updated-th col-xs-4 bold">
                                        {this.state.global.expected_stock}
                                      </th>
                                      <th className="table-updated-th col-xs-3 text-left bold">
                                        {this.state.global.in_stock}
                                      </th>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </React.Fragment>
                          )}

                        {this.state.reconciliation_array &&
                          this.state.reconciliation_array.map((obj, idx) => {
                            return (
                              <tr key={"solvableaa-" + idx}>
                                <td colSpan="4">
                                  <table className="full-width weeklyInventory">
                                    <tr>
                                      <td className=" table-updated-td word-break col-xs-5 p-l-25">
                                        {obj.batch_id}
                                      </td>
                                      <td className=" table-updated-td word-break text-left col-xs-4">
                                        {obj.in_stock}
                                      </td>
                                      <td className=" table-updated-td word-break col-xs-4">
                                        <input
                                          className="tableInputTax"
                                          autoComplete="off"
                                          type="text"
                                          name="expected_stock"
                                          data-index={idx}
                                          value={obj.expected_stock}
                                          onChange={(e) =>
                                            this.handleInputChange(e)
                                          }
                                        />
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    <div className="footer-static">
                      <a
                        className="new-blue-btn pull-right"
                        onClick={this.saveSolvedProduct}
                      >
                        {this.state.langData.business_insights.bi_save}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.showFinalPop
                    ? "blackOverlay"
                    : "blackOverlay no-display"
                }
              >
                <div className="vert-middle vert-middle-report-option">
                  <div className="white-popup large-popup">
                    <div className="white-popup-wrapper p-b-0">
                      <div className="membershipTypeTitle">
                        {
                          this.state.global
                            .inventory_reconciliation_daily_message
                        }
                        <a
                          onClick={() => this.setState({ showFinalPop: false })}
                          className="popupClose"
                        >
                          <img alt="" src="/images/popupClose.png" />
                        </a>
                      </div>
                      <p className="m-b-15">
                        {this.state.global.inventory_reconciliation_review_msg}
                      </p>
                    </div>
                    <table className="table-updated setting-table no-td-border weeklyInventoryTable">
                      <thead className="table-updated-thead">
                        <tr>
                          <th className="table-updated-th col-xs-3 p-l-25">
                            {this.state.inventory.inventory_product_name_label}
                          </th>
                          <th className="table-updated-th col-xs-2 p-l-10 p-r-0">
                            {this.state.global.expected_stock}
                          </th>
                          <th className="table-updated-th col-xs-2 p-l-10 p-r-0">
                            {this.state.global.in_stock}
                          </th>
                          <th className=" table-updated-th text-left col-xs-2 p-l-10">
                            {this.state.global.deviation}
                          </th>
                          <th className=" table-updated-th text-left col-xs-3 p-l-10">
                            {this.state.global.global_reason}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="ajax_body">
                        {this.state.finalReconciledData &&
                          this.state.finalReconciledData.map(
                            (objOuter, idxOuter) => {
                              return (
                                <React.Fragment key={idxOuter}>
                                  <tr>
                                    <td colSpan="5">
                                      <table className="full-width weeklyInventory">
                                        <tbody>
                                          <tr>
                                            <td className=" table-updated-td word-break col-xs-3 p-l-25">
                                              {" "}
                                              {objOuter.product_name}
                                            </td>
                                            <td className=" table-updated-td word-break col-xs-2">
                                              {objOuter.in_stock}{" "}
                                            </td>
                                            <td className=" table-updated-td word-break col-xs-2">
                                              {objOuter.expected_stock}{" "}
                                            </td>
                                            <td className=" table-updated-td word-break text-left col-xs-2">
                                              {" "}
                                              {objOuter.deviation}
                                            </td>
                                            <td className=" table-updated-td word-break text-left col-xs-3">
                                              {" "}
                                              ({objOuter.reason}){" "}
                                              {objOuter.note}
                                            </td>
                                          </tr>
                                          {objOuter.reconciliation_array &&
                                            objOuter.reconciliation_array
                                              .length > 0 && (
                                              <tr>
                                                <td
                                                  className=" table-updated-td word-break no-border p-t-0 tdtextarea childTable bg-light-blue"
                                                  colSpan="5"
                                                >
                                                  <table className="table-updated setting-table no-td-border weeklyInventoryTable">
                                                    <React.Fragment>
                                                      <thead className="table-updated-thead">
                                                        <tr>
                                                          <td
                                                            colSpan="5"
                                                            className="table-updated-th col-xs-6 p-l-25"
                                                          >
                                                            {
                                                              this.state.global
                                                                .ir_batches_resolved
                                                            }
                                                          </td>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        <tr>
                                                          <td colSpan="5">
                                                            <table className="full-width weeklyInventory">
                                                              <tr>
                                                                <td className="table-updated-th col-xs-5 p-l-25 bold">
                                                                  {
                                                                    this.state
                                                                      .global
                                                                      .inventory_reconciliation_batch_name
                                                                  }
                                                                </td>
                                                                <td className="table-updated-th col-xs-3 bold">
                                                                  {
                                                                    this.state
                                                                      .global
                                                                      .expected_stock
                                                                  }
                                                                </td>
                                                                <td className="table-updated-th col-xs-4 text-left bold">
                                                                  {
                                                                    this.state
                                                                      .global
                                                                      .in_stock
                                                                  }
                                                                </td>
                                                              </tr>
                                                            </table>
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </React.Fragment>

                                                    {objOuter.reconciliation_array.map(
                                                      (obj) => {
                                                        return (
                                                          <tr
                                                            key={
                                                              "solvable-" +
                                                              obj.batch_id
                                                            }
                                                          >
                                                            <td colSpan="5">
                                                              <table className="full-width weeklyInventory">
                                                                <tr>
                                                                  <td className=" table-updated-td word-break col-xs-5 p-l-25">
                                                                    {
                                                                      obj.batch_id
                                                                    }
                                                                  </td>
                                                                  <td className=" table-updated-td word-break text-left col-xs-3">
                                                                    {
                                                                      obj.in_stock
                                                                    }
                                                                  </td>
                                                                  <td className=" table-updated-td word-break col-xs-4">
                                                                    {" "}
                                                                    {
                                                                      obj.expected_stock
                                                                    }{" "}
                                                                  </td>
                                                                </tr>
                                                              </table>
                                                            </td>
                                                          </tr>
                                                        );
                                                      },
                                                    )}
                                                  </table>
                                                </td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </React.Fragment>
                              );
                            },
                          )}
                      </tbody>
                    </table>

                    <div className="footer-static">
                      <a
                        className="new-blue-btn pull-right"
                        onClick={this.finalSaveReconcilliation}
                      >
                        {this.state.langData.business_insights.bi_save}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={this.state.showTakeOverPop ? "overlay" : ""}
              ></div>
              <div
                id="filterModal"
                role="dialog"
                className={
                  this.state.showTakeOverPop
                    ? "modal fade in displayBlock"
                    : "modal fade"
                }
              >
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        onClick={() =>
                          this.setState({ showTakeOverPop: false })
                        }
                      >
                        ×
                      </button>
                      <h4 className="modal-title" id="model_title">
                        {this.state.global.are_you_sure}
                      </h4>
                    </div>
                    <div
                      id="errorwindow"
                      className="modal-body add-patient-form filter-patient"
                    >
                      {this.state.global.ir_take_over}
                    </div>
                    <div className="modal-footer">
                      <div className="col-md-12 text-left" id="footer-btn">
                        <button
                          type="button"
                          className="btn  logout pull-right"
                          data-dismiss="modal"
                          onClick={() =>
                            this.setState({ showTakeOverPop: false })
                          }
                        >
                          {"No"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-success pull-right m-r-10"
                          data-dismiss="modal"
                          onClick={this.takeItOver}
                        >
                          {"Yes"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={
                  this.state.showLoadingText
                    ? "loading-please-wait no-margin-top"
                    : "loading-please-wait no-margin-top no-display "
                }
              >
                {this.state.loading_please_wait_text}
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  const returnState = {};
  if (state.InventoryReducer.action === "GET_RECONCILIATION_DATA") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.reconciliationData = state.InventoryReducer.data.data;
      returnState.reconcileTime = new Date();
    } else {
      toast.dismiss();
      if (state.InventoryReducer.data.message === "reconciliation_inprogress") {
        returnState.reconciliationAlreadyInProgress = true;
        returnState.progressTimeStamp = new Date();
      } else if (state.InventoryReducer.data.message === "canot_reconcil") {
        toast.error(languageData.global[state.InventoryReducer.data.message]);
        returnState.reconciliationData = [];
        returnState.reconcileTime = new Date();
        returnState.cannotReconcile = true;
      } else {
        toast.error(languageData.global[state.InventoryReducer.data.message]);
        returnState.showLoader = false;
        returnState.timeStamp = new Date();
      }
    }
  }
  if (state.InventoryReducer.action === "FETCH_CLINICS") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.clinicData = state.InventoryReducer.data.data;
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }

  if (state.InventoryReducer.action === "SAVE_RECONCILED_DATA") {
    if (state.InventoryReducer.data.status === 200) {
      if (
        state.InventoryReducer.data.data &&
        state.InventoryReducer.data.data.total
      ) {
        returnState.reconciliationData = state.InventoryReducer.data.data;
        returnState.reconcileTime = new Date();
      } else {
        returnState.reloadStamp = new Date();
      }
      toast.success(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }
  if (state.InventoryReducer.action === "GET_PRODUCT_DETAILS") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.productData = state.InventoryReducer.data.data;
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }
  if (state.InventoryReducer.action === "GET_RECONCILED_REPORT") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.viewReconciliationDataMain = state.InventoryReducer.data.data;
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }

  if (state.InventoryReducer.action === "DOWNLOAD_RECONCILED_REPORT") {
    if (state.InventoryReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    } else {
      returnState.exportDetails = state.InventoryReducer.data;
      returnState.showLoader = false;
    }
  }

  if (state.InventoryReducer.action === "CHECK_IF_RECONCILED_ALREADY") {
    if (state.InventoryReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    } else {
      returnState.isReconciledForDay = state.InventoryReducer.data;
      returnState.isReconciledTime = new Date();
    }
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getReconciliationData: getReconciliationData,
      saveReconciledData: saveReconciledData,
      fetchClinics: fetchClinics,
      getProductDetails: getProductDetails,
      getReconciledReports: getReconciledReports,
      downloadReconciledReport: downloadReconciledReport,
      checkIfReconciledForDay: checkIfReconciledForDay,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryReconciliation);
