import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../../../../shared/Modal/Modal";
import classes from "./ModalCharge.module.scss";
import { InputWrap } from "./InputWrap";
import { Footer } from "./Footer";
import { Content } from "./Content";
import { InputRow } from "./InputRow";
import { Skeleton } from "./Skeleton";

export function ModalCharge({
  isOpen,
  onClose,
  title,
  imageSrc,
  chargeLabel,
  onCharge,
  isCharging,
  children,
  isChargeDisabled,
  isCancelDisabled,
  noFooter,
  imageClassName,
}) {
  return (
    <Modal
      headerNoBorder
      footerNoBorder
      isOpen={isOpen}
      onClose={onClose}
      className={classes.root}
      contentClassName={classes.content}
      footerClassName={classes.modalNativeFooter}
      header={
        <Modal.Title className={classes.titleWrap}>
          <img src={imageSrc} alt="" className={imageClassName} />
          {title}
        </Modal.Title>
      }
      footer={
        !noFooter && (
          <Footer
            onClose={onClose}
            isCharging={isCharging}
            onCharge={onCharge}
            isChargeDisabled={isChargeDisabled}
            isCancelDisabled={isCancelDisabled}
            chargeLabel={chargeLabel}
            className={classes.footerInModal}
          />
        )
      }
    >
      {children}
    </Modal>
  );
}

ModalCharge.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
  chargeLabel: PropTypes.string,
  onCharge: PropTypes.func,
  isCharging: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  isChargeDisabled: PropTypes.bool,
  isCancelDisabled: PropTypes.bool,
  noFooter: PropTypes.bool,
  imageClassName: PropTypes.string,
};

ModalCharge.defaultProps = {
  chargeLabel: undefined,
  isChargeDisabled: undefined,
  isCancelDisabled: undefined,
  noFooter: false,
  isCharging: false,
  onCharge: () => {},
};

ModalCharge.InputWrap = InputWrap;
ModalCharge.InputRow = InputRow;
ModalCharge.Footer = Footer;
ModalCharge.Content = Content;
ModalCharge.Skeleton = Skeleton;
