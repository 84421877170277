import React from "react";
import PropTypes from "prop-types";
import classes from "./Status.module.scss";
import cx from "clsx";
import { EXAM_STATUSES } from "../../QualiphyExamProcedure.consts";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";

export function Status({ type }) {
  const { tCommon } = useAppTranslation.Common();

  const labelByType = {
    [EXAM_STATUSES.approved]: tCommon(
      "createQualifyProcedure.exams.approvedStatus",
    ),
    [EXAM_STATUSES.rejected]: tCommon(
      "createQualifyProcedure.exams.deniedStatus",
    ),
    [EXAM_STATUSES.notApplicable]: tCommon(
      "createQualifyProcedure.exams.notApplicableStatus",
    ),
    [EXAM_STATUSES.expired]: tCommon(
      "createQualifyProcedure.exams.expiredStatus",
    ),
  };

  const iconByType = {
    [EXAM_STATUSES.approved]: (
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    ),
    [EXAM_STATUSES.rejected]: (
      <i class="fa fa-times-circle" aria-hidden="true"></i>
    ),
    [EXAM_STATUSES.expired]: <i class="fa fa-clock" aria-hidden="true"></i>,
    [EXAM_STATUSES.notApplicable]: null,
  };

  return (
    <div
      className={cx(classes.root, {
        [classes.approved]: type === EXAM_STATUSES.approved,
        [classes.rejected]: type === EXAM_STATUSES.rejected,
        [classes.expired]: type === EXAM_STATUSES.expired,
      })}
    >
      {type ? (
        iconByType[type]
      ) : (
        <i class="fa fa-info-circle" aria-hidden="true"></i>
      )}
      <div className={classes.label}>
        {labelByType[type] ||
          tCommon("createQualifyProcedure.exams.requestedStatus")}
      </div>
    </div>
  );
}

Status.propTypes = {
  type: PropTypes.oneOf(Object.values(EXAM_STATUSES)),
  isExpired: PropTypes.bool.isRequired,
};

Status.defaultProps = {
  type: null,
};
