import { useState } from "react";
import { useLoginMutation } from "../../../api/mutations/useLoginMutation";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { uiNotification } from "../../../services/UINotificationService";
import { extractApiError } from "../../../utilities/api";
import { ROUTES } from "../../../consts/routes";
import { authService } from "../../../services/AuthService";
import { history } from "../../../history";
import { useSwitchWorkspace } from "../../../hooks/useSwitchWorkspace";
import { LOGIN_ERROR_KEYS } from "../Login.consts";

export function useLogin() {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [cachedResponse, setCachedResponse] = useState(null);
  const { tAuth } = useAppTranslation.Auth();
  const { mutateAsync, isLoading } = useLoginMutation();
  const { switchWorkspace, isWorkspaceSwitching } = useSwitchWorkspace();

  const handleError = (e) => {
    const fallbackError = "error.fallbackLogin";
    if (Object.values(LOGIN_ERROR_KEYS).includes(e.message)) {
      uiNotification.error(tAuth([`error.${e.message}`, fallbackError]));
    } else {
      const key = extractApiError(e);
      uiNotification.error(tAuth([`apiError.${key}`, fallbackError]));
    }
  };

  const finishLogin = ({ res, standalone = false }) => {
    try {
      setIsConfirmModalOpen(false);

      if (!res) throw Error();

      if (res.need2Fa) {
        authService.setPreLoginData({
          wpuId: res.wpuId,
          twoFactorType: res.twoFactorType,
          googleAuthCode: res.googleAuthCode,
        });
        history.push(ROUTES.twoFactorLogin());
      } else if (res.accessToken && res.wpuId) {
        if (res.workspaces.length === 0) {
          throw new Error(LOGIN_ERROR_KEYS.emptyWorkspaces);
        }

        authService.setLoginData({
          accessToken: res.accessToken,
          wpuId: res.wpuId,
        });

        if (res.workspaces.length === 1) {
          switchWorkspace({ workspaceId: res.workspaces[0].id }).then(
            authService.afterLogin,
          );
        } else {
          window.location.href = ROUTES.workspaces();
        }
      } else {
        throw Error();
      }
    } catch (e) {
      if (standalone) {
        handleError(e);
      } else {
        throw e;
      }
    }
  };

  const login = async (formValues) => {
    try {
      const res = await mutateAsync({
        email: formValues.email,
        password: formValues.password,
      });

      if (res.alreadyLoggedIn) {
        setCachedResponse(res);
        setIsConfirmModalOpen(true);
      } else {
        finishLogin({ res });
      }
    } catch (e) {
      handleError(e);
    }
  };

  return {
    login,
    finishLogin: () => finishLogin({ res: cachedResponse, standalone: true }),
    isLoggingIn: isLoading || isWorkspaceSwitching,
    confirmLogin: {
      isOpen: isConfirmModalOpen,
      close: () => setIsConfirmModalOpen(false),
    },
  };
}
