import axios from "axios";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

const proNoteInstance = axios.create();

proNoteInstance.defaults.headers.common["access-token"] = getToken();

positionFooterCorrectly();

proNoteInstance.interceptors.response.use(
  function (response) {
    if (
      response.data != undefined &&
      response.data.global_settings != undefined
    ) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

proNoteInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getProcedureNotes(formData) {
  return async (dispatch) => {
    return proNoteInstance
      .get(
        process.env.REACT_APP_API_URL + "procedure-notes",
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_NOTES", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_NOTES", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveProcedureNote(formData, noteID) {
  noteID = noteID || 0;

  if (noteID && noteID > 0) {
    return async (dispatch) => {
      return proNoteInstance
        .patch(
          process.env.REACT_APP_API_URL + "procedure-notes/" + noteID,
          formData,
        )
        .then((response) => {
          dispatch({
            type: "IS_RELOAD_CLIENT_PROCEDURES",
            isReloadClientProcedure: true,
          });
          dispatch({ type: "UPDATE_PROCEDURE_NOTE", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "UPDATE_PROCEDURE_NOTE",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    };
  } else {
    return async (dispatch) => {
      return proNoteInstance
        .post(process.env.REACT_APP_API_URL + "procedure-notes", formData)
        .then((response) => {
          dispatch({
            type: "IS_RELOAD_CLIENT_PROCEDURES",
            isReloadClientProcedure: true,
          });
          dispatch({ type: "SAVE_PROCEDURE_NOTES", payload: response.data });
          return response.data;
        })
        .catch((error) => {
          dispatch({
            type: "SAVE_PROCEDURE_NOTES",
            payload: error.response.data,
          });
          return Promise.reject(error.response.data);
        });
    };
  }
}

export function deleteProcedureNote(noteID) {
  return async (dispatch) => {
    return proNoteInstance
      .delete(process.env.REACT_APP_API_URL + "procedure-notes/" + noteID)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "DELETE_PROCEDURE_NOTE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_PROCEDURE_NOTE",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getAProcedureNote(noteID) {
  return async (dispatch) => {
    return proNoteInstance
      .get(process.env.REACT_APP_API_URL + "procedure-notes/" + noteID)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_NOTE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_NOTE", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}
