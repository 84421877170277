import React from "react";
import PropTypes from "prop-types";
import cx from "clsx";
import classes from "./Toggle.module.scss";

export function Toggle({
  isEnabled,
  onChange,
  className,
  children,
  alignStart,
  alignEnd,
  labelClassName,
  rtl,
  isDisabled,
}) {
  const rootClasses = cx(
    classes.root,
    {
      [classes.rootAlignStart]: alignStart,
      [classes.rootAlignEnd]: alignEnd,
      [classes.rootDisabled]: isDisabled,
    },
    className,
  );

  return (
    <button
      type="button"
      className={rootClasses}
      onClick={onChange}
      disabled={isDisabled}
    >
      {children && !rtl && (
        <div className={cx(classes.label, labelClassName)}>{children}</div>
      )}
      <div
        className={cx(classes.toggle, { [classes.toggleActive]: isEnabled })}
      >
        <span />
      </div>
      {children && rtl && (
        <div className={cx(classes.label, labelClassName)}>{children}</div>
      )}
    </button>
  );
}

Toggle.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
  alignStart: PropTypes.bool,
  alignEnd: PropTypes.bool,
  labelClassName: PropTypes.string,
  rtl: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Toggle.defaultProps = {
  className: undefined,
  children: null,
  alignStart: false,
  alignEnd: false,
  labelClassName: undefined,
  rtl: false,
  isDisabled: false,
};
