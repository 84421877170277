import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import { fetchProcedureQuestionnaire, fetchHealthQuestionnaire } from '../../../Actions/Settings/settingsActions.js';
import { fetchSelectedQuestionnaireData } from '../../../Actions/Clients/clientsAction.js';
import { displayName, capitalizeFirstLetter } from '../../../Utils/services.js';


class ProcedureQuestionnaireDetail extends Component {
  constructor(props) {

    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      id: props.match.params.id,
      questionnaireID: props.match.params.serviceId ? props.match.params.serviceId : '',
      questionsAllData: [],
      questionsMultipleData: [],
      defaultOptions: [],
      selectedOption: null,
      userChanged: false,
      select_Default_Clinic: [],
      showCommentBox: false,
      roomType: this.props.match.url.split('/')[1],
      action: (this.props.match.url) ? this.props.match.url.split('/')[4] : 'pending',
      showLoader: false,
      globalLang: languageData.global,
      questionnaireList : []
    }
    window.onscroll = () => {
      return false
    }
  }

  componentDidMount() {
    this.setState({ showLoader: true })
    if (this.props.match.params.type == 'health') {
      this.props.fetchHealthQuestionnaire(this.props.match.params.serviceId, this.props.match.params.id);
      //this.setCollapse(0);
    } else {
      if (this.state.questionnaireID) {
        this.props.fetchSelectedQuestionnaireData(this.state.id, this.state.questionnaireID)
      } else {
        this.props.fetchProcedureQuestionnaire(this.state.id)
      }
      //this.setCollapse(0);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.questionsData !== undefined && nextProps.questionsData.status === 200 && nextProps.questionsData.data !== prevState.questionsAllData) {
      let questionnaireList = (nextProps.questionsData.data && nextProps.questionsData.data.questionnaires) ? nextProps.questionsData.data.questionnaires : []
      questionnaireList.map((obj,idx) => {
        questionnaireList[idx]['is_filled'] =  false
        let isFilled = obj.questions.find(x => (x.answers && x.answers.length > 0))
        if(isFilled){
          questionnaireList[idx]['is_filled'] = true
        }
      })
      let returnState = {
        questionsAllData: nextProps.questionsData.data,
        showLoader: false,
        questionnaireList: questionnaireList
      }
      return returnState;
    } else if (nextProps.questionsData !== undefined && nextProps.questionsData.status !== 200 && nextProps.questionsData.data !== prevState.questionsAllData) {
      return {
        questionsAllData: nextProps.questionsData.data,
        showLoader: false
      }
    }

    if (nextProps.healthQuestionData != undefined && nextProps.healthQuestionData != prevState.healthQuestionData && nextProps.healthQuestionDataTime != prevState.healthQuestionDataTime) {
      let questionnaireData = [];
      let questionsAllData = {};
      nextProps.healthQuestionData.data.prodecure_templates.map((data, index) => {
        let questions = [];
        data.procedure_template_question.map((obj, idx) => {

          questions.push({
            id: obj.id,
            consultation_id: obj.id,
            question: obj.question,
            status: obj.status,
            order_by: obj.order_by,
            created: obj.created,
            modified: obj.modified,
            question_type: obj.question_type,
            question_choices: obj.answers && obj.answers.length > 0 ? obj.procedure_template_question_option : [],
            answers: []
          })
        })
        questionnaireData.push({
          consultationID: data.id,
          consultation_title: data.title,
          questions: questions
        })
        questionsAllData = {
          questionnaires: questionnaireData
        }


      })
      return {
        questionsAllData: questionsAllData,
        showLoader: false
      }
    }
    return null
  }


  handleChange = (selectedOption) => {
    this.setState({
      select_Default_Clinic: selectedOption,
      selectedOption
    });
  }

  handleDrop = () => {
  }
  addComment = () => {
    this.setState({ showCommentBox: true })
  }
  setCollapse1 = (index) => {
    let returnState = {};
    returnState['showQuestionnaire-' + index] = true;
    this.setState(returnState, () => { })
  }
  openEditPage = (consultationID) => {
    // event.stopPropagation();
    this.props.history.push(`/clients/procedure-health/edit/${this.props.match.params.id}/${this.props.match.params.clientID}/${this.props.match.params.serviceId}/questionnaire/${consultationID}`);
  }

  setCollapse = (questionnaireID, isFilled, event) => {
    event.preventDefault();
    if (isFilled) {
      let returnState = {};
      const state = this.state;
      Object.keys(state).forEach(function (key) {
        if (typeof key === 'string' && key.startsWith('showQuestionnaire-')) {
          returnState[key] = false
        }
      });
      returnState['showQuestionnaire-' + questionnaireID] = true;
      this.setState(returnState)
    }
  }
  render() {
    let returnTo = '';

    if (this.state.roomType && this.state.roomType === 'clients') {
      if (this.props.match.params.type == 'health') {
        returnTo = '/clients/profile/' + this.props.match.params.clientID + "/1"
      } else {
        returnTo = (this.props.match.params.type) ? "/" + this.state.roomType + "/" + this.props.match.params.type + "/" + this.props.match.params.clientID + "/1" : "/" + this.state.roomType
      }
    } else {
      returnTo = (this.state.action) ? "/" + this.state.roomType + "/" + this.state.action : 'pending'
    }
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="wide-popup">
            <div className="modal-blue-header questHealtimeTitle">
              <a onClick={() => this.props.history.goBack()} className="popup-cross">×</a>
              {(this.state.showLoader === false && this.state.questionsAllData && this.state.questionsAllData.patient) && <span className="popup-blue-name">{displayName(this.state.questionsAllData.patient) + ` - `} {(this.state.questionsAllData && this.state.questionsAllData.procedure_name) && capitalizeFirstLetter(this.state.questionsAllData.procedure_name) + ` - `}
                {this.state.questionnaireID ? 'SELECTED QUESTIONNAIRE DETAILS' : 'QUESTIONNAIRES DETAILS'}</span>}
            </div>
            <div className="wide-popup-wrapper time-line questHealthTimeline">
              <div className="accordion questionnaireAccordion" id="accordionExample">
                {this.state.questionnaireList && this.state.questionnaireList.length > 0 && this.state.questionnaireList.map((data, idx) => {
                  return (
                    <div key={"questionnaireList-" + idx} className={`membershipAccordion`} >
                      <div className="membershipAccordionHead" id={"headingOne" + idx}>
                        <h2 className="mb-0">
                          <button className="btn btn-link cursor-default" type="button" data-toggle={(data.is_filled) ? "collapse" : ""} data-target={'#questionnaire-' + data.id} aria-expanded='false' aria-controls="collapseOne">
                            <span>{idx + 1}. {data.consultation_title} {'-'}</span> <span onClick={this.setCollapse.bind(this, data.id, data.is_filled)} className={(data.is_filled) ? "easy-link no-padding" : "easy-link no-padding disable"}>{'Click to View'}</span>
                            {(this.state.roomType != "md-room") && <span style={{ float: 'right' }} className={"easy-link no-padding cursor-pointer"}>{(data.is_filled) ? <i className="fa fa-check m-r-5 text-success"></i> : <i className="fa fa-times m-r-5 text-danger"></i>}</span>}
                          </button>
                        </h2>
                      </div>

                      <div id={'questionnaire-' + data.id} className={this.state['showQuestionnaire-' + data.id] == true ? 'collapse show' : 'collapse'} aria-labelledby="headingOne" data-parent="#accordionExample">
                        <div className="proQuesAccordionOuter">
                          {data.questions && data.questions.map((obj1, idx1) => {
                            return (
                              <div key={'templateQuestionList-' + obj1.id} className="accordion-discription-row">
                                <div className="accordian-section col-xs-6 no-border-left" name="questionId" value={obj1.id}>{obj1.question}</div>
                                {(obj1.question_type == 'yesno') ?
                                  <div className="accordian-section col-xs-6 border-left">
                                    {obj1.answers && obj1.answers != undefined && obj1.answers.map((obj2, idx2) => {
                                      return (
                                        <div key={idx2}>
                                          {(obj2.answer) == 1 ? 'Yes' : (obj2.answer) == 0 ? 'No' : '' }
                                          {((obj2.answer) == 1 && obj2.comment) && <div>{obj2.comment}</div>}
                                        </div>
                                      )
                                    })}
                                  </div>
                                  : (obj1.question_type == 'multitext') ?
                                    <div className="accordian-section col-xs-6 border-left">
                                      {obj1.answers && obj1.question_choices != undefined && obj1.question_choices.map((obj2, idx2) => {
                                        return (
                                          <div key={idx2}>
                                            {(obj2.is_selected) == 1 ? obj2.text : null}
                                            {(this.props.match.params.type == 'health' && obj2.question_option) ? obj2.question_option : null}
                                          </div>
                                        )
                                      })}
                                    </div>
                                    :
                                    <div className="accordian-section col-xs-6 border-left">
                                      {obj1.answers && obj1.question_choices != undefined && obj1.question_choices.map((obj2, idx2) => {
                                        return (
                                          <div className="question-img-outer sel" key={idx2}>
                                            <div className="question-img">
                                              <img src={obj2.image_url} />
                                            </div>
                                            <div className="qusst-name">{obj2.image_label}</div>
                                            {(obj2.is_selected) ? <div className="check-quest"></div> : ''}
                                          </div>
                                        )
                                      })}
                                    </div>
                                }
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
          <div className="loader-outer">
            <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
            <div id="modal-confirm-text" className="popup-subtitle" >{this.state.globalLang.loading_please_wait_text}</div>
          </div>
        </div>
      </div >
    )
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  if (state.SettingReducer.action === "PROCEDURE_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status === 200) {
      return {
        questionsData: state.SettingReducer.data
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      return {
        questionsData: state.SettingReducer.data
      }
    }
    return {};
  } else if (state.SettingReducer.action === "FETCH_HEALTH_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status === 200) {
      return {
        healthQuestionData: state.SettingReducer.data,
        healthQuestionDataTime: new Date()
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message])
    }
  } else if (state.ClientsReducer.action === 'GET_SELECTED_QUESTIONNAIRE_DETAILS') {
    if (state.ClientsReducer.data.status == 200) {
      return {
        questionsData: state.ClientsReducer.data
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      return {
        questionsData: state.ClientsReducer.data
      }
    }
    return {};
  }

  else {
    return {};
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchProcedureQuestionnaire: fetchProcedureQuestionnaire,
    fetchHealthQuestionnaire: fetchHealthQuestionnaire, fetchSelectedQuestionnaireData: fetchSelectedQuestionnaireData
  }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProcedureQuestionnaireDetail));
