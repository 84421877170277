import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "../../../../_legacy/Containers/Settings/sidebar.js";
import { toast } from "react-toastify";
import {
  getSubscriptionDetails,
  subscriptionAddOn,
  subscriptionCancelReactivate,
  subscriptionUpgradeAccount,
  subscriptionUpdateCard,
  subscriptionAutoRefill,
  getSubscriptionMonthlyEstimate,
  subscriptionUpgradeToYearly,
  exportEmptyData
} from "../../../../Actions/Settings/settingsActions.js";
import { getSetupIntentForAccount } from '../../../../Actions/Stripe/StripeActions'
import SubscriptionMonthlyEstimate from './SubscriptionMonthlyEstimate.js';
import { numberFormat, isFormSubmit, showFormattedDate, upgradeAcceleratorUrl, } from "../../../../Utils/services.js";
import Loader from '../../../Common/Loader.js'
import { formatBillingCurrency } from "../YourBilling.utils.js";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal.js";
import { PLAN_CODES } from "../../../../consts/api";
import { withDowngradeToEssentials } from "./withDowngradeToEssentials.js";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress.js";
import { Modal } from "../../../../shared/Modal/Modal.js";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox.js";
import { CancelPlanModal } from "./components/CancelPlanModal/CancelPlanModal.js";
import StripePaymentForm from "../../../Sales/FrontdeskCheckout/StripePaymentForm";

const getPercentage = (used, total) => {
  return numberFormat((used * 100 / total), 'decimal', 2);
}

const SMS_LIMIT = "50(SMS)"
const EMAIL_LIMIT = "50(EMAIL)"
const DATA_LIMIT = "1(GB)"

class Subscriptions extends Component {
  constructor(props) {
    super(props);
    const userData = JSON.parse(localStorage.getItem('userData'));
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      settingsLang: languageData.settings,
      globalLang: languageData.global,
      showLoader: true,
      isShowConfirmationModal: false,
      confirmationModalMsg: '',
      confirmationModalHandler: '',
      refill_sms_status: false,
      refill_sms_limit:'250',
      refill_sms_value:'50',
      refill_email_status: false,
      refill_email_limit:'500',
      refill_email_value:'50',
      refill_data_status: false,
      refill_data_limit:'1',
      refill_data_value:'1',
      is_edit_card: false,
      card_number: '',
      card_cvv: '',
      card_month: '',
      card_year: '',
      add_on_sms: '250',
      add_on_email: '500',
      add_on_data: '1',
      isAddMoreSms: false,
      isAddMoreEmail: false,
      isAddMoreData: false,
      subscriptionData: {},
      storageData: {
        limit: 0,
        used: 0,
        used_percentage: 0,
        free: 0,
        free_percentage: 0
      },
      cardNumberClass: 'newInputField',
      cardCvvClass: 'newInputField',
      cardMonthClass: 'newSelectField',
      cardYearClass: 'newSelectField',
      userChanged:false,
      message:'',
      isShowUpgradeModal: false,
      subscription_type:'monthly',
      accountType:'trial',
      accountStatus:'cancelled',
      isShowMonthlyEstimateModal:false,
      monthlyToYearlyData:{},
      isAccountInStripe:false,
      one_time_setup_fees:0,
      showLoaderMonthlyEstimateModal: false,
      subscription_plan : 'practice_essentials',
      plan_code : "practice_essentials",
      isTouchMD : userData.account.account_preference.is_touch_md,
      plan_selected : userData.account.account_subscription.plan_code,
      isDowngradeToEssentialsModalOpen: false,
      isCancelPlanModalOpen: false,
    };
    window.onscroll = () => {
      return false
    }
  }

  componentDidMount() {
    this.state['showLoader'] = true;
    this.props.exportEmptyData()
    this.props.getSubscriptionDetails();
    if ( window.Stripe ) {
      this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)});
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({stripe: window.Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)});
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {

    let returnState = {};
    if (nextProps.subscriptionData != undefined && nextProps.subscriptionData !== prevState.subscriptionData && nextProps.timestamp !== prevState.timestamp) {
      returnState.subscriptionData = nextProps.subscriptionData;
      returnState.one_time_setup_fees = (nextProps.subscriptionData.one_time_setup_fees) ? nextProps.subscriptionData.one_time_setup_fees : '0.00';
      returnState.subscription_plan  = (nextProps.subscriptionData.plan_code) ? nextProps.subscriptionData.plan_code : 'practice_essentials';

      returnState.isAccountInStripe = (nextProps.subscriptionData.account_code.indexOf('cus_') > -1) ? true : false
      const storageLimit = numberFormat((returnState.subscriptionData.storage_limit / 1000), 'decimal', 2);
      const storageUsed = numberFormat(returnState.subscriptionData.storage_used, 'decimal', 2);
      const storageUsedPercentage = numberFormat(getPercentage(storageUsed, storageLimit), 'decimal', 2);

      returnState.storageData = {
        limit: storageLimit,
        used: storageUsed,
        used_percentage: storageUsedPercentage,
      }

      returnState.isAddMoreSms =  false;
      returnState.isAddMoreEmail =  false;
      returnState.isAddMoreData =  false;
      returnState.is_edit_card = false;

      returnState.accountType = (returnState.subscriptionData.account_type != undefined && returnState.subscriptionData.account_type != '') ?  returnState.subscriptionData.account_type.toLowerCase() : 'trial'
      returnState.accountStatus = (returnState.subscriptionData.account_status != undefined && returnState.subscriptionData.account_status != '') ?  returnState.subscriptionData.account_status.toLowerCase() : 'cancelled'

      returnState.refill_sms_status = (returnState.subscriptionData.refill_sms_status) ? true : false;
      returnState.refill_sms_limit = returnState.subscriptionData.refill_sms_limit;
      returnState.refill_sms_value = nextProps.subscriptionData.refill_sms_value;

      returnState.refill_email_status = (returnState.subscriptionData.refill_email_status) ? true : false;
      returnState.refill_email_limit = returnState.subscriptionData.refill_email_limit;
      returnState.refill_email_value = returnState.subscriptionData.refill_email_value;

      returnState.refill_data_status = (returnState.subscriptionData.refill_data_status) ? true : false;
      returnState.refill_data_limit = returnState.subscriptionData.refill_data_limit;
      returnState.refill_data_value = returnState.subscriptionData.refill_data_value;
      if (nextProps.isEditCard !== undefined && nextProps.isEditCard === false) {
        returnState.is_edit_card = false;
      }

      if (nextProps.message !== undefined && nextProps.message != null && nextProps.message != '') {
        returnState.message = nextProps.message;
        toast.dismiss();
        toast.success(nextProps.message)
      }
      returnState.showLoader = false;
      returnState.showLoaderMonthlyEstimateModal = false;
    } if (nextProps.monthlyToYearlyData != undefined && nextProps.monthlyToYearlyData !== prevState.monthlyToYearlyData &&  nextProps.monthlyTimestamp !== prevState.monthlyTimestamp) {
      returnState.showLoader = false;
      returnState.monthlyToYearlyData = nextProps.monthlyToYearlyData;
      returnState.monthlyTimestamp = nextProps.monthlyTimestamp;
      returnState.isShowMonthlyEstimateModal = true
      returnState.showLoaderMonthlyEstimateModal = false;
    } else if (nextProps.reload !== undefined && nextProps.reload === true) {
      toast.dismiss();
      toast.success(nextProps.message)
      nextProps.getSubscriptionDetails();
    } else if (nextProps.showLoader !== undefined && nextProps.showLoader === false) {
      if (nextProps.message !== undefined && nextProps.message != null && nextProps.message != '' && nextProps.message != prevState.message) {
        returnState.message = nextProps.message;
        toast.dismiss();
        toast.success(nextProps.message, {onClose : () => {return}});
      }
      returnState.showLoader = false;
      if (nextProps.isEditCard !== undefined && nextProps.isEditCard === false) {
        returnState.is_edit_card = false;
      }
      nextProps.exportEmptyData()
    } else if (nextProps.showLoaderMonthlyEstimateModal !== undefined && nextProps.showLoaderMonthlyEstimateModal === false) {
      returnState.showLoaderMonthlyEstimateModal = false;
      nextProps.exportEmptyData()
    }
    return returnState;
  }

handleDropdownChange = event => {
  this.setState({userChanged:true});
  const target = event.target;
  const name = event.target.name;
  let value = target.value;
  if (target.type === "checkbox") {
    value = target.checked;
    this.setState({ [event.target.name]: value }, () => {
    if (!value) {
      if (name == 'refill_sms_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_sms_package_warning,
            confirmationModalHandler: "handleRefillSmsStatus",
            tmpDropDown : "refill_sms_status",
            isShowConfirmationModal: true
          })
          return;
        } else if (name == 'refill_email_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_emails_package_warning,
            confirmationModalHandler: "handleRefillEmailStatus",
            tmpDropDown : "refill_email_status",
            isShowConfirmationModal: true
          })
          return;
        } else if (name == 'refill_data_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_data_package_warning,
            confirmationModalHandler: "handleRefillDataStatus",
            tmpDropDown : "refill_data_status",
            isShowConfirmationModal: true
          })
          return;
        }
    } else {
      this.setState({showLoader:true, message:''});
        let formData = {
          "refill_sms_status":(this.state.refill_sms_status) ? 1 : 0,
          "refill_sms_value": this.state.refill_sms_value,
          "refill_sms_limit": this.state.refill_sms_limit,
          "refill_email_status":(this.state.refill_email_status) ? 1 : 0,
          "refill_email_value": this.state.refill_email_value,
          "refill_email_limit": this.state.refill_email_limit,
          "refill_data_status":(this.state.refill_data_status) ? 1 : 0,
          "refill_data_value": this.state.refill_data_value,
          "refill_data_limit": this.state.refill_data_limit
        }

        this.props.subscriptionAutoRefill(formData)
      }
    });
  } else {
    this.setState({ [event.target.name]: value , showLoader:true, message:''}, () => {
      let formData = {
        "refill_sms_status":(this.state.refill_sms_status) ? 1 : 0,
        "refill_sms_value": this.state.refill_sms_value,
        "refill_sms_limit": this.state.refill_sms_limit,
        "refill_email_status":(this.state.refill_email_status) ? 1 : 0,
        "refill_email_value": this.state.refill_email_value,
        "refill_email_limit": this.state.refill_email_limit,
        "refill_data_status":(this.state.refill_data_status) ? 1 : 0,
        "refill_data_value": this.state.refill_data_value,
        "refill_data_limit": this.state.refill_data_limit
      }

      this.props.subscriptionAutoRefill(formData)
    })
  }
}

  handleInputChange = event => {
    this.setState({userChanged:true});
    const target = event.target;
    const name = event.target.name;
    let value = target.value;
    if (target.type === "checkbox") {
      value = target.checked;
      if (!value) {
        if (name == 'refill_sms_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_sms_package_warning,
            confirmationModalHandler: "handleRefillSmsStatus",
            tmpDropDown : "refill_sms_status"
          })
          this.toggleConfirmationModal('refill_sms_status');
          return;
        } else if (name == 'refill_email_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_emails_package_warning,
            confirmationModalHandler: "handleRefillEmailStatus",
            tmpDropDown : "refill_email_status"
          })
          this.toggleConfirmationModal('refill_email_status');
          return;
        } else if (name == 'refill_data_status') {
          this.setState({
            confirmationModalMsg: this.state.settingsLang.subscription_refill_data_package_warning,
            confirmationModalHandler: "handleRefillDataStatus",
            tmpDropDown : "refill_data_status"
          })
          this.toggleConfirmationModal('refill_data_status');
          return;
        }
      }
    }

    this.setState({
      [event.target.name]: value
    });
  };

  handleCC = () => {
    this.setState({is_edit_card: true, showLoader:true});
    this.props.getSetupIntentForAccount();

      setTimeout(() => {
      this.setState({showLoader:false})
    }, 500)

    return;
  }

  handleSubmitAddOn = (type) => {
    let formData = {
      addon_type: (type == 'add_on_sms') ? 'sms' : (type == 'add_on_email') ? 'email' : 'storage',
      addon_value: this.state[type]
    };
    this.setState({showLoader:true,message:''});
    this.props.subscriptionAddOn(formData)
  };
  handleStripeSubmit = (type) => {

      if (this.props.stripeIntent && this.props.stripeIntent.setupIntentId) {
        let formData = {
          setup_intent_id  : this.props.stripeIntent.setupIntentId
        }

        if(type === 'is_edit_card'){
            this.setState({showLoader: true})
            this.props.subscriptionUpdateCard(formData) //TO DO STRIPE SETUP INTENT
        } else if(type === 'isShowUpgradeModal'){
            this.setState({showLoader: true})
            formData.subscription_type = this.state.subscription_type;
            formData.subscription_plan = this.state.subscription_plan;
            this.setState({isShowUpgradeModal:false});
            this.props.subscriptionUpgradeAccount(formData) //TO DO STRIPE SETUP INTENT
        }
    }
  }

  toggleConfirmationModal = (type) => {
    let returnState = {}
    if (type == 'cancel_plan') {
      this.setState({
        confirmationModalMsg: this.state.settingsLang.subscription_cancel_plan_msg,
        confirmationModalHandler: 'subscriptionCancelReactivate',
      })
    } else if (type == 'reactivate_plan') {
      this.setState({
        confirmationModalMsg: this.state.settingsLang.subscription_resume_plan_msg,
        confirmationModalHandler: 'subscriptionCancelReactivate',
      })
    } else if (type == 'yes') {
      this.setState({showLoader:true});
      let formData = {
        "refill_sms_status":(this.state.refill_sms_status) ? 1 : 0,
        "refill_sms_value": this.state.refill_sms_value,
        "refill_sms_limit": this.state.refill_sms_limit,
        "refill_email_status":(this.state.refill_email_status) ? 1 : 0,
        "refill_email_value": this.state.refill_email_value,
        "refill_email_limit": this.state.refill_email_limit,
        "refill_data_status":(this.state.refill_data_status) ? 1 : 0,
        "refill_data_value": this.state.refill_data_value,
        "refill_data_limit": this.state.refill_data_limit
      }

      this.setState({ message:''});
      this.props.subscriptionAutoRefill(formData)
    } else {
      returnState[this.state.tmpDropDown] = true;
    }
    returnState.isShowConfirmationModal = !this.state.isShowConfirmationModal;
    this.setState(returnState)
  }

  toggleUpgradeModal = () => {
    return <div>{this.props.history.push(upgradeAcceleratorUrl())}</div>
  }

  toggleMonthlyEstimateModal = () => {
    if(!this.state.isShowMonthlyEstimateModal){
      this.setState({showLoader:true})
        this.props.getSubscriptionMonthlyEstimate();
    }
  }

  dismissMonthlyEstimateModal = () => {
    this.setState({isShowMonthlyEstimateModal:false});
  }

  handleRefillSmsStatus = () => {
    this.toggleConfirmationModal('yes');
  }
  handleRefillEmailStatus = () => {
    this.toggleConfirmationModal('yes');
  }
  handleRefillDataStatus = () => {
    this.toggleConfirmationModal('yes');
  }

  subscriptionCancelReactivate = () => {
    if(this.state.subscriptionData.account_status != undefined)
    this.setState({ showLoader: true,message:'' })
    this.toggleConfirmationModal();
    this.props.subscriptionCancelReactivate({
      action_type: this.state.subscriptionData.account_status == 'Cancelled' ? 'reactivate' : 'cancelled',
    });
  }

  subscriptionUpgradeToYearly = () => {
      this.setState({showLoaderMonthlyEstimateModal:true})
      this.props.subscriptionUpgradeToYearly();
      this.dismissMonthlyEstimateModal();
  }

  toggleState = (type) => {
    this.setState({ [type]: !this.state[type],userChanged:true });
  }

  renderObjectOption = (list, key,labelTag,insideBraces) => {
    let htmlList = []
      Object.keys(list).forEach((idx) => {
        htmlList.push(<option key={key+'-'+idx} value={idx} >{idx} {(insideBraces == true) ? labelTag + ' @ $'+ numberFormat(list[idx].price,'decimal',2) : `(${labelTag})`}</option>);
      })
    return htmlList;
  }

  renderArrayOption = (list, key,labelTag,insideBraces) => {
    let htmlList = []
      list.map((obj,idx) => {
        htmlList.push(<option key={key+'-'+idx} value={obj} >{obj} {(insideBraces == true) ? labelTag : `(${labelTag})`}</option>);
      })
    return htmlList;
  }

  renderSubcriptionPlan = () => {
    let htmlList = []
    if(this.state.subscriptionData.subscription_plans){
      Object.keys(this.state.subscriptionData.subscription_plans).forEach((idx) => {
        htmlList.push(<option key={'subscription_plans-'+idx} value={idx} >{this.state.subscriptionData.subscription_plans[idx]}</option>);
      })
    }
    return htmlList;
  }

  handleDowngradeToEssentials = () => {
    const { downgradeToEssentials, getSubscriptionDetails } = this.props;
    this.setState({
      isDowngradeToEssentialsModalOpen: false,
    });
    downgradeToEssentials.downgrade().then(getSubscriptionDetails);
  }

  handleCancelSubscription = (values) => {
    this.setState({
      showLoader: true,
      isCancelPlanModalOpen: false,
    });
    this.props.subscriptionCancelReactivate({
      action_type: "cancelled",
      reason: values.reasonType,
      message: values.message,
    });
  }

  render() {
    const subDate = this.state.subscriptionData.subscription_satartdate;
    let plan_label = '';
    if(this.state.isTouchMD == 1){
      plan_label = this.state.plan_selected == 'practice_booking' ? 'Booking Only - TouchMD' : 'Accelerator - TouchMD';
    } else {
      plan_label = this.state.subscriptionData.subscription_plan_label ? this.state.subscriptionData.subscription_plan_label : ''
    }
    
    const { downgradeToEssentials } = this.props;
    const isYearlySubscription = this.state.subscriptionData.subscription_type === "Yearly";
    const isDowngradeToEssentialsAvailable = this.state.subscriptionData.plan_code === PLAN_CODES.accelerator && downgradeToEssentials.isAvailable;
    const isMainAdmin = downgradeToEssentials.isAvailable

    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="business-setion memberWalletOuter relative">
              {(this.state.subscriptionData.subscription_type == "Monthly" && this.state.accountType == 'paid' && this.state.subscriptionData.plan_estimated_amount != undefined) &&
                <div className="row">
                  <div className="col-lg-12 autoRefill">
                    <div className="setting-setion m-b-15">
                        <div className="graphHeading no-border">{this.state.settingsLang.subscription_your_estimated_bill}
                            <div className="pull-right credit-text">
                              {formatBillingCurrency(this.state.subscriptionData.credits)}
                            </div>
                            <div className="pull-right credit-text">{this.state.settingsLang.subscription_credit_balance}: &nbsp;&nbsp;</div>
                        </div>
                        <div className="accordion-table your-billing">
                          <div className="accordion-row accordion-head table-head-color">
                            <div className="accordian-section col-xs-6 text-left">{this.state.settingsLang.subscription_your_billing_cycle} ({this.state.subscriptionData.plan_estimated_amount.bill_cycle})</div>
                            <div className="accordian-section col-xs-6 text-right">
                              {this.state.settingsLang.subscription_your_expected_bill_for_this_month} {" "}
                              {formatBillingCurrency(this.state.subscriptionData.plan_estimated_amount.total_amount)}
                            </div>
                          </div>
                          {this.state.isTouchMD == 1  ? '':
                          <SubscriptionMonthlyEstimate estimatedData={this.state.subscriptionData.plan_estimated_amount} />}
                        </div>
                    </div>
                </div>
              </div>
              }
              <div className="row">
                <div className="col-lg-12 ">
                  <div className="setting-setion m-b-15">
                      <div className="mainSectionTitle m-b-10"><span className="pull-left m-t-5">{this.state.settingsLang['manage-subscription-details']}</span>
                        {this.state.isTouchMD == 1  ? '' :
                        <div className="memberRightActions">
                          {(this.state.accountType != 'paid')
                        ?
                        <button disabled={downgradeToEssentials.isDowngrading} className="save-profile confirm-model new-blue-btn pull-right" data-message={this.state.settingsLang.subscription_resume_plan_msg} data-confirm-url="" id="upgrade_account" type="button" onClick={this.toggleUpgradeModal}>{this.state.settingsLang.subscription_upgrade_account}</button>
                        :
                          (this.state.accountStatus == 'cancelled')
                        ?
                         isMainAdmin && <button disabled={downgradeToEssentials.isDowngrading} className="save-profile confirm-model new-blue-btn pull-right m-l-10" data-message={this.state.settingsLang.subscription_resume_plan_msg} data-confirm-url="" id="cancel_plan" type="button" onClick={this.toggleConfirmationModal.bind(this, 'reactivate_plan')}>{this.state.settingsLang.subscription_resume_plan}</button>
                        :
                         isMainAdmin && <button
                            disabled={downgradeToEssentials.isDowngrading}
                            className="save-profile confirm-model new-blue-btn pull-right m-l-10"                      
                            type="button"
                            onClick={() => this.setState({ isCancelPlanModalOpen: true })}
                          >
                            {this.state.settingsLang.subscription_cancel_plan}
                          </button>
                        }
                        {(this.state.subscriptionData.subscription_type == "Yearly" && this.state.accountType == 'paid') &&
                          <div className="pull-right credit-text m-t-5">
                            {this.state.settingsLang.subscription_credit_balance}: &nbsp;&nbsp;
                            {formatBillingCurrency(this.state.subscriptionData.credits)}
                          </div>
                        }
                        </div>}
                      </div>
                      <div className="table-responsive">
                        <table className="records-table">
                          <tbody>
                            {(this.state.accountType == 'paid') ?
                              <React.Fragment>
                                <tr>
                                  <td>{(this.state.settingsLang.subscription_start_date)}</td>
                                  <td >{showFormattedDate(subDate)}</td>
                                </tr>
                                <tr>
                                  <td>{this.state.settingsLang.subscription_plan_label}</td>
                                  <td className="items-center">
                                    {plan_label}
                                    {isDowngradeToEssentialsAvailable && (
                                      <button
                                        type="button"
                                        className="m-l-10 link-btn items-center"
                                        onClick={() => this.setState({
                                          isDowngradeToEssentialsModalOpen: true,
                                        })}
                                        disabled={downgradeToEssentials.isDowngrading}
                                      >
                                        {downgradeToEssentials.isDowngrading && (
                                          <CircularProgress className="m-r-5" size="tiny" />
                                        )}
                                        Downgrade to Essentials
                                      </button>
                                    )} 
                                  </td>
                                </tr>
                                <tr>
                                  <td>{this.state.settingsLang.subscription_type}</td>
                                  <td >{this.state.subscriptionData.subscription_type}</td>
                                </tr>
                                <tr>
                                  <td>Subscription {this.state.globalLang.label_status}</td>
                                  <td >{this.state.subscriptionData.account_status}</td>
                                </tr>
                              </React.Fragment>
                          :
                              <React.Fragment>
                                <tr>
                                  <td>{this.state.settingsLang.subscription_plan_label}</td>
                                  <td>{this.state.subscriptionData.subscription_plan_label}</td>
                                </tr>
                                <tr>
                                  <td>{this.state.settingsLang.subscription_type}</td>
                                  <td >{this.state.subscriptionData.subscription_type} ({this.state.subscriptionData.account_type})</td>
                                </tr>
                                <tr>
                                  <td>{this.state.settingsLang.subscription_start_date}</td>
                                  <td >{showFormattedDate(this.state.subscriptionData.subscription_satartdate)}</td>
                                </tr>
                                <tr>
                                  <td>{this.state.settingsLang.subscription_end_date}</td>
                                  <td >{showFormattedDate(this.state.subscriptionData.subscription_valid_upto)}</td>
                                </tr>
                                <tr>
                                  <td>Subscription {this.state.globalLang.label_status}</td>
                                  <td >{this.state.subscriptionData.account_status}</td>
                                </tr>
                              </React.Fragment>
                            }
                            <tr>
                              <td>{this.state.settingsLang.subscription_sms_in_plan}</td>
                              <td>{this.state.subscriptionData.sms_limit} {this.state.settingsLang.subscription_sms_per_month}</td>
                            </tr>
                            <tr>
                              <td>{this.state.settingsLang.subscription_emails_in_plan}</td>
                              <td>{this.state.subscriptionData.email_limit} {this.state.settingsLang.subscription_email_per_month}</td>
                            </tr>
                            <tr>
                              <td>{this.state.settingsLang.subscription_data_in_plan}</td>
                              <td>{this.state.storageData.limit} {this.state.settingsLang.subscription_gb}</td>
                            </tr>
                            <tr className="font-weight-6">
                              <td colSpan="2">Add-On</td>
                            </tr>
                            <tr>
                              <td className="p-l-20">-&nbsp;{this.state.settingsLang.subscription_addon_sms_this_month}</td>
                              <td>
                                {(this.state.subscriptionData.add_on_sms > 0) ? `${this.state.subscriptionData.add_on_sms} @ $${numberFormat((this.state.subscriptionData.add_on_sms_charge) ? this.state.subscriptionData.add_on_sms_charge : 0, 'decimal', 2)}` : 0 }
                              </td>
                            </tr>
                            <tr>
                              <td className="p-l-20">-&nbsp;{this.state.settingsLang.subscription_addon_emails_this_month}</td>
                              <td>
                                {(this.state.subscriptionData.add_on_email > 0) ? `${this.state.subscriptionData.add_on_email} @ $${numberFormat((this.state.subscriptionData.add_on_email_charge) ? this.state.subscriptionData.add_on_email_charge : 0, 'decimal', 2)}` : 0 }
                            </td>
                            </tr>
                            {(this.state.accountType == 'paid' && this.state.subscriptionData.subscription_type == "Yearly" && this.state.isAccountInStripe == true) &&
                            <React.Fragment>
                              <tr>
                                <td>{this.state.settingsLang.subscription_users_limit}</td>
                                <td>{this.state.subscriptionData.users_limit}</td>
                              </tr>
                              <tr>
                                <td>{this.state.settingsLang.subscription_users_used}</td>
                                <td>{this.state.subscriptionData.users_used}</td>
                              </tr>
                              </React.Fragment>
                            }
                          </tbody>
                        </table>
                      </div>
                  </div>
                </div>
              </div>
              <div className={(this.state.accountType == 'paid') ? "row" : "no-display"}>
                <div className="col-lg-12 autoRefill">
                  <div className="setting-setion m-b-15">
                      <div className="mainSectionTitle no-border">Auto Refill Settings</div>
                      <div className="table-responsive ">
                        <table className="table-updated setting-table no-hover no-td-border table-min-width">
                          <tbody className="ajax_body">
                            <tr className="table-updated-tr" >
                              <td className="table-updated-td ">{this.state.settingsLang.subscription_refill_sms_package}</td>
                              <td className="table-updated-td ">
                                <select name="refill_sms_value" value={this.state.refill_sms_value} onChange={this.handleDropdownChange} disabled={!this.state.refill_sms_status} className="newSelectField">
                                  {
                                    this.state.subscriptionData.sms_packages &&
                                    this.renderObjectOption(this.state.subscriptionData.sms_packages,'refill_sms_value',this.state.settingsLang.subscription_sms,true)
                                  }
                                </select>
                              </td>
                              <td className="table-updated-td ">{this.state.settingsLang.subscription_if_sms_balance_goes_below}</td>
                              <td className="table-updated-td ">
                              <span className="limits">{SMS_LIMIT}</span>
                              </td>
                              <td className="table-updated-td ">
                                <label className="setting-switch">
                                  <input type="checkbox" id="refill_sms_status" name="refill_sms_status" value={this.state.refill_sms_status} checked={this.state.refill_sms_status ? 'checked' : false} onChange={this.handleDropdownChange} className="refill-status setting-custom-switch-input" data-message={this.state.settingsLang.subscription_refill_sms_package_warning} />
                                  <span className="setting-slider" />
                                  </label>
                              </td>
                            </tr>
                            <tr className="table-updated-tr" >
                              <td className="table-updated-td ">{this.state.settingsLang.subscription_refill_emails_package}</td>
                              <td className="table-updated-td ">
                                <select name="refill_email_value" value={this.state.refill_email_value} onChange={this.handleDropdownChange} disabled={!this.state.refill_email_status} className="newSelectField">
                                  {this.state.subscriptionData.email_packages &&
                                  this.renderObjectOption(this.state.subscriptionData.email_packages,'refill_email_value',this.state.settingsLang.subscription_email,true)}
                                </select>
                              </td>
                              <td className="table-updated-td ">{this.state.settingsLang.subscription_if_emails_balance_goes_below}</td>
                              <td className="table-updated-td ">
                              <span className="limits">{EMAIL_LIMIT}</span>
                              </td>
                              <td className="table-updated-td ">
                                <label className="setting-switch">
                                  <input type="checkbox" id="refill_email_status" name="refill_email_status" value={this.state.refill_email_status} checked={this.state.refill_email_status ? 'checked' : false} onChange={this.handleDropdownChange} className="refill-status setting-custom-switch-input" data-message={this.state.settingsLang.subscription_refill_emails_package_warning} />                                  <span className="setting-slider" />
                                  </label>
                              </td>
                            </tr>
                            <tr className="table-updated-tr" >
                              <td className="table-updated-td ">{this.state.settingsLang.subscription_refill_data_package}</td>
                              <td className="table-updated-td ">
                                <select name="refill_data_value" value={this.state.refill_data_value} onChange={this.handleDropdownChange} className="newSelectField dataPackageSelect" disabled>
                                  {this.state.subscriptionData.storage_data_packages &&
                                  this.renderObjectOption(this.state.subscriptionData.storage_data_packages,'refill_data_value',this.state.settingsLang.subscription_gb,true)
                                }
                                </select>
                              </td>
                              <td className="table-updated-td ">{this.state.settingsLang.subscription_if_data_goes_below}</td>
                              <td className="table-updated-td ">
                                <span className="limits">{DATA_LIMIT}</span>
                              </td>
                              <td className="table-updated-td ">
                                <label className="setting-switch">
                                  <input type="checkbox" id="refill_data_status" name="refill_data_status" value={this.state.refill_data_status} checked={this.state.refill_data_status ? 'checked' : false} onChange={this.handleDropdownChange} className="refill-status setting-custom-switch-input" data-message={this.state.settingsLang.subscription_refill_data_package_warning} />
                                  <span className="setting-slider" />
                                </label>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                  </div>
                </div>
              </div>
              {(this.state.accountType == 'paid') &&
                <div className="row">
                  <div className="col-lg-12 new-sale-col">
                    <div className="setting-setion m-b-15">
                        <div className="mainSectionTitle m-b-10">Credit Card
                        <a onClick={this.handleCC} className="easy-link pull-right no-padding"><i className="fa fa-pencil-alt"></i> &nbsp;Edit</a>
                        </div>
                        <div className="p2030">
                        <div className={this.state.is_edit_card ?    "card-edit-mode"  : 'no-display'}>
                            <StripePaymentForm type={'setupIntent'} onSubmit={this.handleStripeSubmit.bind(this,'is_edit_card')} onCancel={()=>this.setState({is_edit_card:!this.state.is_edit_card})} onSubmitText="Update" />
                            <div className="footer-static"></div>
                          </div>
                          <div className={!this.state.is_edit_card ? "card-view-mode" : 'no-display'}>
                            <div className="row">
                              <div className="col-lg-5 col-md-4 col-sm-12">
                                <div className="newInputFileldOuter">
                                  <div className="newInputLabel">{this.state.settingsLang.subscription_credit_card_number}</div>
                                  <div className="newInputField" id="Card_Number_label">{this.state.subscriptionData.stripe_card_details}</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
          {/* Confirmation Modal - START */}
          <div className={(this.state.isShowConfirmationModal) ? 'overlay' : ''} ></div>
          <div id="filterModal" role="dialog" className={(this.state.isShowConfirmationModal) ? 'modal fade in displayBlock' : 'modal fade no-display'}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button type="button" className="close" data-dismiss="modal" onClick={this.toggleConfirmationModal}>×</button>
                  <h4 className="modal-title" id="model_title">{this.state.globalLang.delete_confirmation}</h4>
                </div>
                <div id="errorwindow" className="modal-body add-patient-form filter-patient">{this.state.confirmationModalMsg}</div>
                <div className="modal-footer">
                  <div className="col-md-12 text-left" id="footer-btn">
                    <button type="button" className="btn  logout pull-right" data-dismiss="modal" onClick={this.toggleConfirmationModal}>{this.state.globalLang.label_no}</button>
                    <button type="button" className="btn btn-success pull-right m-r-10" data-dismiss="modal" onClick={this[this.state.confirmationModalHandler]}>{this.state.globalLang.label_yes}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  Confirmation Modal - END */}
          {/* Upgrade Account Modal - START */}
          <div className={(this.state.isShowUpgradeModal) ? 'modalOverlay' : 'no-display'}>
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.settingsLang.subscription_upgrade_account}</div>
                <button className="small-cross" onClick={this.toggleUpgradeModal}>×</button>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">
                  <div className="row">
                    {(this.state.subscriptionData.subscription_plans != undefined) &&
                      <>
                      <div className="col-xs-12">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.subscription_plan_label}<span className="setting-require">*</span></div>
                          <select  className="newSelectField" name="subscription_plan" value={this.state.subscription_plan} onChange={this.handleInputChange} >
                            {this.renderSubcriptionPlan()}
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.settingsLang.subscription_type}<span className="setting-require">*</span></div>
                          <select  className="newSelectField" name="subscription_type" value={this.state.subscription_type} onChange={this.handleInputChange} >
                            <option value='monthly'>{this.state.subscriptionData ?  this.state.subscriptionData.subscription_plans.monthly : 'Monthly'}</option>
                          { /* <option value="yearly">{this.state.subscriptionData ? this.state.subscriptionData.subscription_plans.yearly : 'Yearly'}</option> */}
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-6">
                        <div className="newInputFileldOuter">
                          <div className="newInputLabel">{this.state.globalLang.one_time_setup_fees}<span className="setting-require">*</span></div>
                          <input
                            name="one_time_setup_fees"
                            value={formatBillingCurrency(this.state.one_time_setup_fees)}
                            onChange={this.handleInputChange}
                            className='newInputField setting-input-box-disabled'
                            disabled={'dissabled'}
                          />
                        </div>
                      </div>
                    </>
                    }
                    <StripePaymentForm type={'setupIntent'} onSubmit={this.handleStripeSubmit.bind(this,'isShowUpgradeModal')} onCancel={this.toggleUpgradeModal}/>
                  </div>
                </div>
              </div>
              <div className="footer-static"></div>
            </div>
          </div>
          {/* Upgrade Account Modal - END */}
          {/* Monthly Estimate Modal - START */}
          <div className={(this.state.isShowMonthlyEstimateModal) ? 'modalOverlay' : 'no-display'}>
            <div className="small-popup-outer subscription-popup">
              <div className="small-popup-header">
                <div className="popup-name">{this.state.settingsLang.subscription_switch_to_yearly_subscription}</div>
                <button className="small-cross" onClick={this.dismissMonthlyEstimateModal}>×</button>
              </div>
              <div className="small-popup-content">
                <div className="juvly-container no-padding-bottom">
                  {(this.state.monthlyToYearlyData.monthly_plan_estimated != undefined && this.state.monthlyToYearlyData.yearly_plan_estimated != undefined) &&
                    <div className="monthly-estimate-block">
                      <div className="popup-accordian-section-head">
                        <div className="accordian-section text-left">
                          {this.state.settingsLang.subscription_we_will_charge_your_card_with} {formatBillingCurrency(this.state.monthlyToYearlyData.monthly_plan_estimated.total_amount)} {this.state.settingsLang.subscription_for_monthly_subscription_until_today}<br /> From {(this.state.monthlyToYearlyData.monthly_plan_estimated.from_date) ? showFormattedDate(this.state.monthlyToYearlyData.monthly_plan_estimated.from_date) : ''} To {(this.state.monthlyToYearlyData.monthly_plan_estimated.to_date) ? showFormattedDate(this.state.monthlyToYearlyData.monthly_plan_estimated.to_date) : ''}</div>
                      </div>
                      <div className="accordion-table your-billing m-b-20">
                        <SubscriptionMonthlyEstimate estimatedData={this.state.monthlyToYearlyData.monthly_plan_estimated} />
                      </div>

                      <div className="popup-accordian-section-head">
                        <div className="accordian-section text-left">{this.state.settingsLang.subscription_we_will_charge_your_card_upfront_with} {formatBillingCurrency(this.state.monthlyToYearlyData.yearly_plan_estimated.total_amount)} {this.state.settingsLang.subscription_for_yearly_subscription}<br /> From {(this.state.monthlyToYearlyData.yearly_plan_estimated.from_date) ? showFormattedDate(this.state.monthlyToYearlyData.yearly_plan_estimated.from_date) : ''} To {(this.state.monthlyToYearlyData.yearly_plan_estimated.to_date) ? showFormattedDate(this.state.monthlyToYearlyData.yearly_plan_estimated.to_date)  : ''}</div>
                      </div>
                      <div className="accordion-table your-billing m-b-20">
                          <div className="profile accordion-row">
                            <div className="accordian-section col-xs-6 text-left inner-box">
                              {this.state.settingsLang.subscription_your_yearly_subscription_for} {this.state.monthlyToYearlyData.yearly_plan_estimated.total_users} {this.state.settingsLang.subscription_users}
                            </div>
                            <div className="accordian-section col-xs-6 text-right inner-box">{formatBillingCurrency(this.state.monthlyToYearlyData.yearly_plan_estimated.total_amount)}</div>
                          </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
              <div className="footer-static">
                <button className="new-blue-btn pull-right" name='btn_create_schedule' onClick={this.subscriptionUpgradeToYearly}>{this.state.globalLang.label_continue}</button>
                <button className="new-white-btn pull-right" onClick={this.dismissMonthlyEstimateModal}>{this.state.globalLang.label_cancel}</button>
              </div>
              <Loader showLoader={this.state.showLoaderMonthlyEstimateModal} />
            </div>
          </div>
          {/* Monthly Estimate Modal - END */}
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
        {this.state.isDowngradeToEssentialsModalOpen && !isYearlySubscription && (
          <ConfirmModal
            isOpen={this.state.isDowngradeToEssentialsModalOpen}
            onConfirm={this.handleDowngradeToEssentials}
            onCancel={() => 
              this.setState({ isDowngradeToEssentialsModalOpen: false })
            }
            onClose={() => 
              this.setState({ isDowngradeToEssentialsModalOpen: false })
            }
            confirmTitle="Confirm and Downgrade"
            cancelTitle="Stay on Accelerator"
          >
            <div className="m-b-10">
              You are about to downgrade your Subscription Plan to Essentials.
              Please note you will lose access to integrations, inbox and additional key features.
            </div>
            <div className="m-b-15">
              <b>Are you sure you want to continue?</b>
            </div>
          </ConfirmModal>
        )}
        {this.state.isDowngradeToEssentialsModalOpen && isYearlySubscription && (
          <Modal
            headerNoBorder
            isOpen={this.state.isDowngradeToEssentialsModalOpen}
            onClose={() => 
              this.setState({ isDowngradeToEssentialsModalOpen: false })
            }
            header={
              <Modal.Title>
                Attention Required
              </Modal.Title>
            }
          >
            <AlertBox>
              You're on a Yearly Subscription Plan. Please contact live chat support for assistance
            </AlertBox>
          </Modal>
        )}
        {this.state.isCancelPlanModalOpen && (
          <CancelPlanModal
            isOpen={this.state.isCancelPlanModalOpen}
            onClose={() => this.setState({ isCancelPlanModalOpen: false })}
            onSubmit={this.handleCancelSubscription}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  const returnState = {};
  if (state.SettingReducer.action === "SUBSCRIPTION_DETAILS") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false
    } else {
      returnState.timestamp = new Date();
      returnState.subscriptionData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_ADD_ON") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.timestamp = new Date();
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.subscriptionData = state.SettingReducer.data.data
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_CANCEL_REACTIVATE") {
    if (state.SettingReducer.data.status != 200 && state.SettingReducer.data.status != 201) {
      toast.dismiss();
      if(state.SettingReducer.data.message == 'third_party_error'){
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      if (state.SettingReducer.data.status == 201) {
        returnState.message = state.SettingReducer.data.message;
      } else {
        returnState.message = languageData.global[state.SettingReducer.data.message];
      }
      returnState.subscriptionData = state.SettingReducer.data.data
      returnState.timestamp = new Date();
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_UPGRADE_ACCOUNT") {
   if (state.SettingReducer.data.status != 200) {
     toast.dismiss();
     if(state.SettingReducer.data.message == 'third_party_error'){
       toast.error(state.SettingReducer.data.data);
     } else {
       toast.error(languageData.global[state.SettingReducer.data.message]);
     }
     returnState.showLoader = false;
   } else {
     returnState.timestamp = new Date();
     returnState.message = languageData.global[state.SettingReducer.data.message];
     returnState.subscriptionData = state.SettingReducer.data.data
   }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_UPDATE_CARD") {
   if (state.SettingReducer.data.status != 200) {
     toast.dismiss();
     if(state.SettingReducer.data.message == 'third_party_error'){
       toast.error(state.SettingReducer.data.data);
     } else {
       toast.error(languageData.global[state.SettingReducer.data.message]);
     }
     returnState.showLoader = false;
   } else {
     returnState.timestamp = new Date();
     returnState.isEditCard = false;
     returnState.subscriptionData = state.SettingReducer.data.data
     returnState.message = languageData.global[state.SettingReducer.data.message];
   }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_AUTO_REFILL") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.timestamp = new Date();
      returnState.showLoader = false;
      returnState.subscriptionData = state.SettingReducer.data.data
      toast.success(languageData.global[state.SettingReducer.data.message]);
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_MONTHLY_TO_YEARLY_ESTIMATE") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      if(state.SettingReducer.data.message == 'third_party_error'){
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoader = false;
    } else {
      returnState.monthlyToYearlyData = state.SettingReducer.data.data;
      returnState.monthlyTimestamp = new Date();
    }
  } else if (state.SettingReducer.action === "SUBSCRIPTION_MONTHLY_TO_YEARLY_UPGRADE") {
    if (state.SettingReducer.data.status != 200) {
      toast.dismiss();
      if(state.SettingReducer.data.message == 'third_party_error'){
        toast.error(state.SettingReducer.data.data);
      } else {
        toast.error(languageData.global[state.SettingReducer.data.message]);
      }
      returnState.showLoaderMonthlyEstimateModal = false;
    } else {
      returnState.message = languageData.global[state.SettingReducer.data.message];
      returnState.subscriptionData = state.SettingReducer.data.data;
      returnState.timestamp = new Date();
    }
  }
  returnState.isAccountOnHold = state.AccountHoldRedirectReducer.isAccountOnHold
  returnState.isAccountCancelled = state.AccountHoldRedirectReducer.isAccountCancelled
  returnState.stripeIntent = state.StripeReducer.stripeIntent;

    return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    getSubscriptionDetails,
    subscriptionAddOn,
    subscriptionCancelReactivate,
    subscriptionUpgradeAccount,
    subscriptionUpdateCard,
    subscriptionAutoRefill,
    getSubscriptionMonthlyEstimate,
    subscriptionUpgradeToYearly,
    getSetupIntentForAccount,
    exportEmptyData:exportEmptyData,
  }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withDowngradeToEssentials(Subscriptions));
