/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import picClose from "../../../_legacy/images/popupClose.png";
import ReportsFilters from "../ReportsFilters.js";
import { fromToDateRange } from "../../../Utils/services.js";
import {
  acceleratorInsightsAppointments,
  isRestrictedReport,
} from "../../../Utils/privilege.js";
import UpgradeAccount from "../../Common/UpgradeAccount.js";

const FromToMonthDate = fromToDateRange("mtd");
const ApiDateFormat = "YYYY-MM-DD";

const initReportFilter = () => {
  return {
    date: "mtd",
    from_date: FromToMonthDate.from_date,
    to_date: FromToMonthDate.from_date,
    service: "",
    month_of_birth: "1",
    spent_money: "",
    discount_type: "0",
    provider: "0",
    cashier: "0",
    product: "",
    cancellation_fee_charge: false,
    show_report_by: "clinic",
    lot_number: "",
    month: "1",
    month_year: "2017-01",
    year: "2017",
    clinic: "0",
  };
};

export default class AppointmentsReportOptions extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
    this.state = {
      showLoader: false,
      globalLang: languageData.global,
      showCreateOptions: props.showCreateOptions
        ? props.showCreateOptions
        : false,
      type: "appointments",
      report_type_sysname: "",
      reportData: {},
      reportOptions: props.reportOptions ? props.reportOptions : [],
      reportVariableData: props.reportVariableData
        ? props.reportVariableData
        : [],
      rangeClass: "col-sm-6 col-xs-12 calender no-display",
      reportFilter: initReportFilter(),
      reportMode: props.reportMode,
      editReportID: props.editReportID,
      propReportType: props.propReportType,
      resetEditMode: false,
      preSelectedData: {},
      acceleratorPlan: acceleratorInsightsAppointments(),
      isRestrictedPrivilege: false,
      isTouchMd:
        userData && userData.account
          ? userData.account.account_preference.is_touch_md
          : 0,
      selected_plan: userData.account.account_subscription.plan_code,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (
      nextProps.reportOptions !== undefined &&
      nextProps.reportOptions !== prevState.reportOptions
    ) {
      returnState.reportOptions = nextProps.reportOptions;
      if (
        prevState.editReportID &&
        prevState.editReportID > 0 &&
        prevState.reportMode === "edit" &&
        nextProps.allData &&
        nextProps.allData.business_insight_id
      ) {
        returnState.report_type_id = nextProps.allData.business_insight_id;
        returnState.report_type_sysname =
          nextProps.allData.business_insight_sysname;
        returnState.reportVariableData =
          nextProps.allData.business_insight_variables;
        returnState.reportName = nextProps.allData.business_insight_report_name;
        returnState.reportFilter = initReportFilter();

        if (
          nextProps.allData.business_insight_variables &&
          nextProps.allData.business_insight_variables.length > 0
        ) {
          nextProps.allData.business_insight_variables.map((obj1, idx1) => {
            if (obj1.variable.startsWith("service_")) {
              let idxx1 = ++idx1;
              returnState.reportFilter[`service_` + idxx1] = obj1.value;
            }

            if (obj1.variable === "service") {
              returnState.reportFilter.service = obj1.value;
            }

            if (obj1.variable === "date") {
              returnState.reportFilter.date = obj1.value;

              if (
                obj1.value !== "qtd" &&
                obj1.value !== "mtd" &&
                obj1.value !== "ytd"
              ) {
                returnState.reportFilter.date = "custom";
                let dateArray = obj1.value.split("|");
                returnState.startDate = moment(dateArray[0]).toDate();
                returnState.endDate = moment(dateArray[1]).toDate();

                returnState.reportFilter.from_date = moment(
                  dateArray[0],
                ).format(ApiDateFormat);
                returnState.reportFilter.to_date = moment(dateArray[1]).format(
                  ApiDateFormat,
                );
              }
            }
            if (obj1.variable === "show_report_by") {
              returnState.reportFilter.show_report_by = obj1.value
                ? obj1.value
                : "clinic";
            }

            if (obj1.variable === "clinic") {
              returnState.reportFilter.clinic = obj1.value ? obj1.value : 0;
            }
            if (obj1.variable === "provider") {
              returnState.reportFilter.provider = obj1.value ? obj1.value : 0;
            }
          });
        }

        returnState.preSelectedData = {
          report_type_sysname: returnState.report_type_sysname,
          report_type_id: returnState.report_type_id,
          reportFilter: returnState.reportFilter,
        };

        let isRestrictedPrivilege = isRestrictedReport(
          prevState.acceleratorPlan,
          returnState.report_type_sysname,
        );
        returnState.isRestrictedPrivilege = isRestrictedPrivilege;
        if (!isRestrictedPrivilege) {
          returnState.resetEditMode = true;
          returnState.showLoader = true;
          nextProps.getReportVariables(returnState, !isRestrictedPrivilege);
        }
      }
    }

    if (
      nextProps.showCreateOptions !== undefined &&
      nextProps.showCreateOptions === true &&
      prevState.showCreateOptions === false
    ) {
      returnState.showCreateOptions = nextProps.showCreateOptions;
      if (
        prevState.showCreateOptions === false &&
        prevState.preSelectedData &&
        prevState.preSelectedData.report_type_sysname
      ) {
        returnState.report_type_sysname =
          prevState.preSelectedData.report_type_sysname;
        returnState.report_type_id = prevState.preSelectedData.report_type_id;
        returnState.reportFilter = prevState.preSelectedData.reportFilter;
        returnState.reportVariableData =
          prevState.preSelectedData.reportVariableData;
      }
    }

    if (
      nextProps.reportVariableData !== undefined &&
      nextProps.reportVariableData !== prevState.reportVariableData
    ) {
      returnState.reportVariableData = nextProps.reportVariableData;

      if (prevState.resetEditMode) {
        returnState.reportFilter = prevState.reportFilter;
        if (
          prevState.preSelectedData.reportVariableData !==
          returnState.reportVariableData
        ) {
          returnState.preSelectedData = prevState.preSelectedData;
          returnState.preSelectedData.reportVariableData =
            returnState.reportVariableData;
        }
      } else {
        returnState.reportFilter = initReportFilter();
      }

      if (
        nextProps.reportVariableData &&
        nextProps.reportVariableData.length > 0 &&
        !prevState.resetEditMode
      ) {
        nextProps.reportVariableData.map((obj, idx) => {
          if (obj.variable === "service") {
            if (obj.variable_data && obj.variable_data.length > 0) {
              returnState.reportFilter.service = obj.variable_data[0].id;
            }
          }
          if (obj.variable.startsWith("service_")) {
            if (obj.variable_data && obj.variable_data.length > 0) {
              let idxx = ++idx;
              returnState.reportFilter[`service_` + idxx] =
                obj.variable_data[0].id;
            }
          }
        });
      }
    }

    return returnState;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({ [event.target.name]: value, dataChanged: true });

    if (event.target.name === "filter_date") {
      if (value === "custom") {
        this.setState({ rangeClass: "col-sm-6 col-xs-12 calender" });
      } else {
        this.setState({ rangeClass: "col-sm-6 col-xs-12 calender no-display" });
      }
    }
  };

  hideCreateOptions = () => {
    this.setState({ showCreateOptions: false });
    this.props.hideCreateOptions({ showCreateOptions: false });
  };

  getData = () => {
    if (!this.state.report_type_sysname) {
      toast.dismiss();
      toast.error(
        this.props.businessInsightLang.bi_please_select_a_report_type,
      );
      return;
    } else {
      const preSelectedData = {
        report_type_sysname: this.state.report_type_sysname,
        report_type_id: this.state.report_type_id,
        reportFilter: this.state.reportFilter,
        reportVariableData: this.state.reportVariableData,
      };

      this.setState({
        showCreateOptions: false,
        preSelectedData: preSelectedData,
      });
      let reportOptionFilter = this.state.reportFilter;

      reportOptionFilter.report_type_sysname = this.state.report_type_sysname;
      reportOptionFilter.report_type_id = this.state.report_type_id;
      delete reportOptionFilter.is_download;
      this.props.getData({
        report_type_sysname: this.state.report_type_sysname,
        report_type_id: this.state.report_type_id,
        reportFilter: this.state.reportFilter,
        showCreateOptions: false,
      });
    }
  };

  getReportVariables = (obj) => {
    let isRestrictedPrivilege = isRestrictedReport(
      this.state.acceleratorPlan,
      obj.sysname,
    );
    this.setState({ isRestrictedPrivilege: isRestrictedPrivilege });
    let returnState = {
      report_type_sysname: obj.sysname,
      report_type_id: obj.id,
      resetEditMode: false,
    };
    this.setState(returnState);
    if (!isRestrictedPrivilege) {
      returnState.showLoader = true;
    }
    this.props.getReportVariables(returnState, !isRestrictedPrivilege);
  };

  handleCalendarChange = (childState) => {
    this.setState(childState);
  };

  handleChildState = (childState) => {
    this.setState(childState);
  };

  render() {
    return (
      <div
        className={
          this.state.showCreateOptions
            ? "blackOverlay"
            : "blackOverlay no-display"
        }
      >
        <div className="vert-middle vert-middle-report-option">
          <div className="white-popup small-popup">
            <div className="white-popup-wrapper">
              <div className="membershipTypeTitle">
                {this.props.businessInsightLang.bi_create_report}
                <a
                  onClick={() => this.hideCreateOptions()}
                  className="popupClose"
                >
                  <img alt="" src={picClose} />
                </a>
              </div>
              <div className="question-name-report">
                {
                  this.props.businessInsightLang
                    .bi_what_type_of_report_do_you_want_to_create
                }
              </div>

              {this.state.reportOptions &&
                this.state.reportOptions.length > 0 &&
                this.state.reportOptions.map((obj, idx) => {
                  let isRestrictedPrivilege = isRestrictedReport(
                    this.state.acceleratorPlan,
                    obj.sysname,
                  );
                  if (
                    this.state.isTouchMd == 1 &&
                    this.state.selected_plan == "practice_booking"
                  ) {
                    if (isRestrictedPrivilege == false) {
                      return (
                        <div key={`div_${idx}`} className="report-ans-outer">
                          <input
                            onChange={() => this.getReportVariables(obj)}
                            id={obj.sysname}
                            checked={this.state.report_type_id == obj.id}
                            className="basic-form-checkbox"
                            name="report_type_sysname"
                            value={obj.id}
                            type="radio"
                          />
                          <label
                            className="basic-form-text"
                            htmlFor={obj.sysname}
                          >
                            {obj.name}{" "}
                            {isRestrictedPrivilege === true && (
                              <span className="fa-lock-upgrade-plan">
                                <i className="fa fa-lock" />
                              </span>
                            )}
                          </label>
                        </div>
                      );
                    }
                  } else {
                    return (
                      <div key={`div_${idx}`} className="report-ans-outer">
                        <input
                          onChange={() => this.getReportVariables(obj)}
                          id={obj.sysname}
                          checked={this.state.report_type_id == obj.id}
                          className="basic-form-checkbox"
                          name="report_type_sysname"
                          value={obj.id}
                          type="radio"
                        />
                        <label
                          className="basic-form-text"
                          htmlFor={obj.sysname}
                        >
                          {obj.name}{" "}
                          {isRestrictedPrivilege === true && (
                            <span className="fa-lock-upgrade-plan">
                              <i className="fa fa-lock" />
                            </span>
                          )}
                        </label>
                      </div>
                    );
                  }
                })}
              {this.state.isRestrictedPrivilege === false &&
                this.state.reportVariableData &&
                this.state.reportVariableData.length > 0 &&
                this.props.showFilters && (
                  <ReportsFilters
                    reportType={this.state.report_type_sysname}
                    reportVariableData={this.state.reportVariableData}
                    reportSection={`appointments`}
                    handleInputChange={this.handleInputChange}
                    parentState={this.state}
                    handleChildState={this.handleChildState}
                    handleCalendarChange={this.handleCalendarChange}
                    type={this.state.type}
                  />
                )}
              {this.state.isRestrictedPrivilege === true && (
                <div className="popp-up-upgrade-block">
                  <UpgradeAccount notificaionType={"report"} />
                </div>
              )}
            </div>
            {this.state.isRestrictedPrivilege === false && (
              <div className="footer-static">
                <a
                  className="new-blue-btn pull-right"
                  onClick={() => this.getData()}
                >
                  {this.props.globalLang.label_continue}
                </a>
                <a
                  className="new-white-btn pull-right"
                  onClick={() => this.hideCreateOptions()}
                >
                  {this.props.globalLang.label_cancel}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
