export const PRICE_TYPES = {
  inventory: "Inventory",
  variation: "Variation",
  promotion: "Promotion",
};

const inventoryColumns = [
  {
    Header: "Product",
    accessor: "product_name",
    id: "product_name",
    width: "200px",
  },
  {
    Header: "Category",
    accessor: "category_name",
    id: "category_name",
    width: "140px",
  },
  {
    Header: "Price",
    accessor: "price_per_unit",
    id: "price_per_unit",
    width: "80px",
    type: "price",
  },
  {
    Header: "Member Price",
    accessor: "price_per_unit_members",
    id: "price_per_unit_members",
    width: "80px",
    type: "price",
  },
];

const variationColumns = [
  {
    Header: "Pricing Variation Name",
    accessor: "name",
    accessor2: "product_name",
    id: "name",
    width: "400px",
    type: "concat",
  },
  {
    Header: "Price",
    accessor: "price",
    accessor2: "product_name",
    id: "price",
    width: "80px",
    type: "pricePerUnit",
  },
];

const promotionColumns = [
  {
    Header: "Promotion Name",
    accessor: "name",
    id: "name",
    width: "120px",
  },
  {
    Header: "Type",
    accessor: "type",
    id: "type",
    width: "120px",
    type: "formatCells",
  },
  {
    Header: "Amount",
    accessor: "discount_percentage",
    id: "discount_percentage",
    width: "80px",
    type: "formatCells",
  },
  {
    Header: "Price",
    accessor: "package_bogo_price",
    id: "package_bogo_price",
    width: "80px",
    type: "formatCells",
  },
  {
    Header: "Member Price",
    accessor: "package_price_for_members",
    id: "package_price_for_members",
    width: "80px",
    type: "price",
  },
];

export const pricelistOptions = [
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Variations",
    value: "variation",
  },
  {
    label: "Promotions",
    value: "promotion",
  },
];

export const PriceListColumns = {
  inventoryColumns,
  variationColumns,
  promotionColumns,
};
