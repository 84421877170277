import React, { useMemo } from "react";
import { Select } from "../../../../../shared/Select/Select";
import { useShopifySearchProducts } from "../../../../../api/queries/useShopifySearchProducts";
import { useDebouncedState } from "../../../../../utilities/hooks/useDebouncedState";

const ProductSelect = ({ value, onSelect }) => {
  const [search, debouncedSearch, setSearch] = useDebouncedState(
    value?.label || "",
  );

  const { data, isLoading, isFetching } = useShopifySearchProducts({
    payload: {
      title: debouncedSearch,
    },
  });

  const options = useMemo(() => {
    return (
      data?.map((product) => ({
        label: product.title,
        value: product.id,
      })) || []
    );
  }, [data]);

  return (
    <Select
      isSearchable
      inputValue={search}
      isLoading={isLoading || isFetching}
      size="small"
      options={options}
      onChange={onSelect}
      onInputChange={setSearch}
    />
  );
};

export default ProductSelect;
