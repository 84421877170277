import React from "react";
import PropTypes from "prop-types";
import { SortableElement } from "react-sortable-hoc";
import cx from "clsx";
import classes from "./SortableItem.module.scss";

export const SortableItem = SortableElement(({ children, isDisabled }) => {
  return (
    <div
      className={cx(classes.root, {
        [classes.disabled]: isDisabled,
      })}
    >
      <div className={classes.dragIcon}>
        <span />
        <span />
      </div>
      <div>{children}</div>
    </div>
  );
});

SortableItem.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool.isRequired,
};
