import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { fetchProcedureQuestionnaire, updateProcedureQuestionnaire, getQuestionnaireById } from '../../Actions/Settings/settingsActions.js';
import crossImg from '../../_legacy/images/close.png';
import { isFormSubmit } from '../../Utils/services.js';

class ProcedureQuestionnaireCosmeticEdit extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    this.state = {
      questionnaireData: (props.questionnaireData) ? (props.questionnaireData) : null,
      questionList: (props.questionnaireData && props.questionnaireData.question_answer) ? (props.questionnaireData.question_answer) : [],
      roomsLang: languageData.rooms,
    }
  }



  handleSubmit = (event) => {
    event.preventDefault();
    if (isFormSubmit()) {
      let questions = [];
      if (this.state.questionList) {
        this.state.questionList.map((obj, idx) => {
          let answers = [];
          if (obj.question_type == 'yesno') {
            if (obj.field_value != undefined || obj.field_value != null || obj.field_value != '') {
              answers.push({ "answer": obj.field_value, "comment": (obj.field_value) ? obj.field_value_comment : '' })
            }
          } else {
            obj.field_value.map((obj, idx2) => {
              answers.push({ "question_choice_id": obj })
            })
          }
          if (answers.length) {
            questions.push({ id: obj.id, question_type: obj.question_type, answers: answers })
          }
        })
      }
      if (questions.length == 0) {
        toast.error(this.state.roomsLang.rooms_please_answer_one_question);
        return false;
      }
      let formData = {}
      formData.user_id = this.props.clientID
      formData.patient_id = this.props.clientID
      formData.procedure_id = this.props.procedureID
      formData.appointment_id = this.props.appointmentID
      formData.questionnaire_id = this.props.questionnaireID
      formData.questionnaires = [{
        id: this.props.questionnaireID,
        questionnaire_id: this.props.questionnaireID,
        procedure_id: this.props.procedureID,
        questions: questions
      }]

    }
  }

  handleDrop = (event) => {
    this.setState({
      active_consultation: event.currentTarget.dataset.id,
      consultation_title: "accordion-discription-row consultTitle display",
    })
  }

  addComment = () => {
    this.setState({ showCommentBox: true })
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    const questionIndex = target.dataset.questionIndex
    const choiceIndex = target.dataset.choiceIndex
    let questionList = this.state.questionList
    let question = questionList[questionIndex];
    if (question) {
      if (name.startsWith('yesno-')) {
        question.field_value = value
      } else if (name.startsWith('yesno_comment-')) {
        question.field_value_comment = value
      } else if (name.startsWith('multi_text-')) {
        const choiceId = parseInt(target.dataset.choiceId)
        const valueIndex = question.field_value.indexOf(choiceId)
        if (valueIndex == -1) {
          question.field_value.push(choiceId)
        } else {
          question.field_value.splice(valueIndex, 1);
        }
      } else if (name.startsWith('multi_text_radio-')) {
        question.field_value = [parseInt(value)]
      }
      questionList[questionIndex] = question
    }

    this.setState({ questionList: questionList });
  }

  handleImageChange = (questionIndex, choiceIndex, choiceId, isMultipleSelection) => {
    let questionList = this.state.questionList
    let question = questionList[questionIndex];
    if (question) {
      if (isMultipleSelection) {
        const valueIndex = question.field_value.indexOf(choiceId)
        if (valueIndex == -1) {
          question.field_value.push(choiceId)
        } else {
          question.field_value.splice(valueIndex, 1);
        }
      } else {
        question.field_value = [parseInt(choiceId)]
      }
      questionList[questionIndex] = question
      this.setState({ questionList: questionList });
    }
  }

  render() {

    return (
      <React.Fragment>
        <div className="container-fluid content setting-wrapper">
          <div className="juvly-section full-width m-t-15 proQuesAccordionOuter">
            <div className="proQuesAccordionTitle">Edit Questionnaire {(this.props.questionnaireData && this.props.questionnaireData.consultation_title) ? ' - ' + this.props.questionnaireData.consultation_title : ''}
              <a className="pull-right" onClick={this.props.handleDismissQuestionnaire}><img src={crossImg} /></a>
            </div>

            <div className="half-preview healthtimeline" id="half-preview -12">
              {this.state.questionList && this.state.questionList.map((obj1, idx1) => {
                return (
                  <div className={'accordion-discription-row'} key={'question-' + idx1}>
                    <div className="accordian-section col-xs-6" name="questionId" value={obj1.id}>{obj1.question}
                    </div>
                    {(obj1.question_type == 'yesno') &&
                      <div className="accordian-section col-xs-6 border-left">
                        <div >
                          <div className="basic-checkbox-outer">
                            <input id={"questionListYes" + obj1.id} type="radio" checked={(obj1.field_value != '' && obj1.field_value == 1) ? 'checked' : false} onChange={this.handleInputChange} className="basic-form-checkbox" name={"yesno-" + obj1.id} value="1" data-question-index={idx1} data-choice-index={0} data-choice-id={0} />
                            <label className="basic-form-text" htmlFor={"questionListYes" + obj1.id}>{this.state.roomsLang.yes_text}</label>
                          </div>
                          <div className="basic-checkbox-outer">
                            <input id={"questionListNo" + obj1.id} type="radio" checked={(obj1.field_value != '' && obj1.field_value == 0) ? 'checked' : false} onChange={this.handleInputChange} className="basic-form-checkbox" name={"yesno-" + obj1.id} value="0" data-question-index={idx1} data-choice-index={0} data-choice-id={0} />
                            <label className="basic-form-text" htmlFor={"questionListNo" + obj1.id}>{this.state.roomsLang.rooms_no_text}</label>
                          </div>
                          {(obj1.field_value == 1) &&
                            <div className="setting-field-outer m-t-20 m-b-0">
                              <div className="new-field-label">{this.state.roomsLang.rooms_type_your_answer}</div>
                              <textarea className="setting-textarea-box" cols="30" rows="2" name={"yesno_comment-" + obj1.id} onChange={this.handleInputChange} value={obj1.field_value_comment} data-question-index={idx1} data-choice-index={0} data-choice-id={0}></textarea>
                            </div>
                          }
                        </div>
                      </div>
                    }
                    {(obj1.question_type == 'multitext') &&
                      <div className="accordian-section col-xs-6 border-left">
                        {obj1.question_choices != undefined && obj1.question_choices.map((obj2, idx2) => {
                          return (
                            <div key={'multi_text-' + obj1.id + "-" + obj2.id}>
                              {(obj2.multiple_selection == 1) &&
                                <div key={'multi_text-' + obj1.id + "-" + obj2.id}>
                                  <div className="basic-checkbox-outer">
                                    <input id={"multi_text-" + obj1.id + "-" + obj2.id} type="checkbox" checked={(obj1.field_value.indexOf(obj2.id) != -1) ? 'checked' : null} name={'multi_text-' + obj1.id + "-" + obj2.id} className="basic-form-checkbox" onChange={this.handleInputChange} data-question-index={idx1} data-choice-index={idx2} data-choice-id={obj2.id} />
                                    <label className="basic-form-text" htmlFor={'multi_text-' + obj1.id + "-" + obj2.id}>{obj2.text}</label>
                                  </div>
                                </div>
                              }
                              {obj2.multiple_selection == 0 &&
                                <div className="basic-checkbox-outer">
                                  <input id={'multi_text_radio-' + obj1.id + "-" + obj2.id} type="radio" checked={(obj1.field_value.indexOf(obj2.id) != -1) ? 'checked' : false} onChange={this.handleInputChange} className="basic-form-checkbox" name={'multi_text_radio-' + obj1.id} value={obj2.id} data-question-index={idx1} data-choice-index={idx2} data-choice-id={obj2.id} />
                                  <label className="basic-form-text" htmlFor={'multi_text_radio-' + obj1.id + "-" + obj2.id}>{obj2.text}</label>
                                </div>
                              }
                            </div>
                          )
                        })}
                      </div>
                    }
                    {(obj1.question_type == 'multiimage') &&
                      <div className="accordian-section col-xs-6 border-left">
                        {obj1.question_choices != undefined && obj1.question_choices.map((obj2, idx2) => {
                          return (
                            <div className="question-img-outer" key={"multi_image-" + obj1.id + "-" + obj2.id}>
                              {(obj2.multiple_selection == 1) &&
                                <div className={(obj1.field_value.indexOf(obj2.id) != -1) ? "question-img-outer sel" : "question-img-outer"} key={'multiquestion-' + idx2}>
                                  <div className="question-img" data-id={"multi_image-" + obj1.id + "-" + obj2.id} onClick={this.handleImageChange.bind(this, idx1, idx2, obj2.id, 1)} data-question-index={idx1} data-choice-index={idx2} data-choice-id={obj2.id}>
                                    <img src={obj2.image_url} />
                                  </div>
                                  <div className="qusst-name">{obj2.image_label}</div>
                                  <div className="check-quest"></div>
                                </div>
                              }
                              {(obj2.multiple_selection == 0) &&
                                <div className={(obj1.field_value.indexOf(obj2.id) != -1) ? "question-img-outer sel" : "question-img-outer"} key={'multiquestion-' + idx2}>
                                  <div className="question-img" data-id={"multiImage-" + obj1.id + "-" + obj2.id} onClick={this.handleImageChange.bind(this, idx1, idx2, obj2.id, 0)} data-question-index={idx1} data-choice-index={idx2} data-choice-id={obj2.id}>
                                    <img src={obj2.image_url} />
                                  </div>
                                  {(obj2.display_labels == 1) && <div className="qusst-name">{obj2.image_label}</div>}
                                  <div className="check-quest"></div>
                                </div>
                              }
                            </div>
                          )
                        })}
                      </div>
                    }
                  </div>
                )
              })}
            </div>
            <div className="footer-static">
              <button onClick={this.handleSubmit} className="new-blue-btn pull-right" value="Save">{this.state.roomsLang.rooms_save}</button>
              <a onClick={this.props.handleDismissQuestionnaire} className="new-white-btn pull-right">{this.state.roomsLang.rooms_cancel}</a>
            </div>
          </div>

        </div>
        <div className={(this.state.showLoader) ? 'new-loader text-left displayBlock clientLoader clientProfileLoader' : 'new-loader text-left'}>
          <div className="loader-outer">
            <img id="loader-outer" src="/images/Eclipse.gif" className="loader-img" />
            <div id="modal-confirm-text" className="popup-subtitle" >{this.state.roomsLang.loading_please_wait_text}</div>
          </div>
        </div>



      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem('languageData'));
  toast.dismiss();
  if (state.SettingReducer.action === "PROCEDURE_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status === 200) {
      return {
        questionsData: state.SettingReducer.data.data
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
    }
    return {};
  }

  if (state.SettingReducer.action === "UPDATE_PROCEDURE_QUESTIONNAIRE") {
    if (state.SettingReducer.data.status === 200) {
      toast.success(languageData.global[state.SettingReducer.data.message]);
      return {
        questionsData: state.SettingReducer.data.data
      }
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.SettingReducer.data.message]);
      return { showLoader: false }
    }
    return {};
  }

  if (state.SettingReducer.action === "GET_QUESTIONNAIRE") {
    let returnState = {}
    if (state.SettingReducer.data.status == 200) {
      returnState.questionnaireData = state.SettingReducer.data;
    } else {
      returnState.redirect = true;
      returnState.message = languageData.global[state.SettingReducer.data.message];
    }
    return returnState;
  }
  return {}
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    fetchProcedureQuestionnaire: fetchProcedureQuestionnaire,
    updateProcedureQuestionnaire: updateProcedureQuestionnaire,
    getQuestionnaireById: getQuestionnaireById,
  }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProcedureQuestionnaireCosmeticEdit);
