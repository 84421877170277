import React from "react";
import classes from "./Amount.module.scss";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { formatCurrency } from "../../../../../utilities/general";
import { useInvoiceActions } from "../../hooks/useInvoiceActions";
import { TipsApplier } from "../../boxes/TipsApplier/TipsApplier";
import { MergedInvoice } from "./MergedInvoice/MergedInvoice";

export function Amount() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { invoice, beforeTipAmount } = useInvoice();
  const { applyTip } = useInvoiceActions();

  const formattedTipAmount = formatCurrency(
    invoice?.tipAmount,
    invoice?.currency,
  );
  const formattedBeforeTipAmount = formatCurrency(
    beforeTipAmount,
    invoice?.currency,
  );

  const onApplyCustomTip = ({ amount }) => {
    applyTip.initiate({ tipAmount: amount });
  };

  const onApplyTip = (tipOptionValue) => {
    applyTip.initiate({
      tipAmount: applyTip.optionToAmount(tipOptionValue),
    });
  };

  const isTipsEnabled = () => {
    if (invoice?.isMerger) {
      const tipsEnabled = invoice.mergedInvoices.filter((i) => {
        return i.isTipsEnabled;
      });
      return Boolean(tipsEnabled.length);
    }
    return invoice.isTipsEnabled;
  };

  return (
    <LabeledBox label={tCommon("label.totalAmount")}>
      <div className={classes.amountRow}>
        <div className={classes.amount}>
          {formatCurrency(invoice?.totalAmount, invoice?.currency)}
        </div>
        {isTipsEnabled() && (
          <div className={classes.tip}>
            {`(${formattedBeforeTipAmount} + ${tCommon(
              "label.tip",
            )} ${formattedTipAmount})`}
          </div>
        )}
      </div>
      {isTipsEnabled() && (
        <div className={classes.tipsWrap}>
          <div className={classes.tipHeader}>
            {tSales("checkoutInvoice.tips.header")}
          </div>
          {!invoice?.isMerger && (
            <TipsApplier
              onApply={onApplyTip}
              onApplyCustom={onApplyCustomTip}
              tipAmount={invoice?.tipAmount}
              beforeTipAmount={beforeTipAmount}
              isApplying={applyTip.isLoading}
              currencySymbol={invoice?.currencySymbol}
            />
          )}
          {invoice?.isMerger &&
            invoice?.mergedInvoices?.map(
              (mergedInvoice, index) =>
                mergedInvoice.isTipsEnabled && (
                  <MergedInvoice key={index} mergedInvoice={mergedInvoice} />
                ),
            )}
        </div>
      )}
    </LabeledBox>
  );
}
