import axios from "axios";
import { toast } from "react-toastify";
import {
  getToken,
  handleInvalidToken,
  setConfigData,
  positionFooterCorrectly,
  getIsPosEnabled,
} from "../../Utils/services.js";
import { accountStatusResponseInterceptor } from "../../Components/AccountHoldRedirect/AccountHoldRedirectInterceptor";

positionFooterCorrectly();

const stripeInstance = axios.create();

stripeInstance.defaults.headers.common["access-token"] = getToken();

stripeInstance.interceptors.response.use(
  function (response) {
    if (response.data?.global_settings) {
      setConfigData(response.data.global_settings);
    }

    positionFooterCorrectly();

    return response;
  },
  function (error) {
    if (!error.response) {
      return { data: { data: "", message: "server_error", status: 500 } };
    } else {
      if (error.response.status == 500) {
        return { data: { data: "", message: "server_error", status: 500 } };
      }

      let msg = error.response.data.message;

      if (
        msg == "invalid_token" ||
        msg == "session_timeout" ||
        msg == "server_error" ||
        msg == "token_not_found"
      ) {
        handleInvalidToken();
      }

      return Promise.reject(error);
    }
  },
);

stripeInstance.interceptors.response.use(accountStatusResponseInterceptor);

export function getSetupIntent(formData, cancel, showError = true) {
  return async (dispatch) => {
    if (cancel) {
      await dispatch(cancelSetupIntent());
    }

    dispatch({ type: "GET_STRIPE_INTENT_RESET" });
    if (getIsPosEnabled()) {
      await stripeInstance
        .post(process.env.REACT_APP_API_URL + "stripe/setup-intent", formData)
        .then((response) => {
          dispatch({
            type: "GET_STRIPE_INTENT_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          dispatch({
            type: "GET_STRIPE_INTENT_ERROR",
            payload: error.response.data,
          });
          if (showError) {
            toast.error(error.response.data.message);
          }
        });
    }
  };
}

export function clearSetupIntent() {
  return async (dispatch) => {
    await dispatch({ type: "GET_STRIPE_INTENT_RESET" });
  };
}

function cancelSetupIntent() {
  return (dispatch) => dispatch({ type: "CANCEL_STRIPE_SETUP_INTENT" });
}

export const getSetupIntentForSignUp = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_STRIPE_INTENT_RESET", payload: {} });
    stripeInstance
      .post(
        process.env.REACT_APP_API_URL + "register-account-setup-intent",
        formData || "",
      )
      .then((response) => {
        dispatch({ type: "GET_STRIPE_INTENT_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_STRIPE_INTENT_ERROR",
          payload: error.response.data,
        });
      });
  };
};

export const getSetupIntentForAccount = (formData) => {
  return (dispatch) => {
    dispatch({ type: "GET_STRIPE_INTENT_RESET", payload: {} });
    stripeInstance
      .post(
        process.env.REACT_APP_API_URL + "stripe/account-setup-intent",
        formData || "",
      )
      .then((response) => {
        dispatch({ type: "GET_STRIPE_INTENT_SUCCESS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_STRIPE_INTENT_ERROR",
          payload: error.response.data,
        });
      });
  };
};

export const getStripePaymentIntent = (formData) => {
  return async (dispatch) => {
    if (getIsPosEnabled()) {
      dispatch({ type: "GET_STRIPE_INTENT_RESET", payload: {} });
      await stripeInstance
        .post(`${process.env.REACT_APP_API_URL}stripe/payment-intent`, formData)
        .then((response) => {
          dispatch({
            type: "GET_STRIPE_INTENT_SUCCESS",
            payload: response.data,
          });
        })
        .catch((error) => {
          return Promise.reject(error.response.data);
        });
    }
  };
};

export const getClientCardDataStripe = (form) => {
  return async (dispatch) => {
    if (getIsPosEnabled()) {
      await stripeInstance
        .post(
          process.env.REACT_APP_API_URL + "stripe/list-payment-methods",
          form || "",
        )
        .then((response) => {
          dispatch({
            type: "GET_CLIENT_STRIPE_CARDS",
            payload: response.data.data.paymentMethods,
          });
        })
        .catch(() => {
          dispatch({ type: "GET_CLIENT_STRIPE_CARDS", payload: [] });
        });
    }
  };
};
