import React from "react";
import { Labeled } from "../../shared/Labeled/Labeled";
import classes from "./RadioRowSelect.module.scss";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Radio } from "../../../../../../shared/Radio/Radio";

export function RadioRowSelect({ name, value, patientOptions, onChange }) {
  const { tCommon } = useAppTranslation.Common();

  return (
    <Labeled divider label={name}>
      <div className={classes.root}>
        {patientOptions.map((option, index) => (
          <div key={index} className={classes.block}>
            <div className={classes.label}>{`${tCommon("label.patient")} ${
              index + 1
            }`}</div>
            <div className={classes.content}>
              <Radio
                label=""
                name={name}
                isChecked={value === option}
                onChange={() => onChange(option)}
              />
              {option || (
                <span className={classes.empty}>{tCommon("label.none")}</span>
              )}
            </div>
          </div>
        ))}
      </div>
    </Labeled>
  );
}
