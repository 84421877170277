import React from "react";

export default class MembershipBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color ? this.props.color : "#F5CB42",
    };
  }
  render() {
    return (
      <span
        className={
          this.props.displayPage
            ? `${this.props.displayPage} membership-bedge-block`
            : "membership-bedge-block"
        }
        style={{ color: this.props.color }}
      >
        <span
          className="membership-bedge-circle"
          style={{ borderColor: this.props.color, color: this.props.color }}
        >
          <i className="membership-bedge-icon fa fa-star"> </i>
        </span>
      </span>
    );
  }
}
