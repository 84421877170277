/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  capitalizeFirstLetter,
  showFormattedDate,
  displayName,
  numberFormat,
} from "../../Utils/services.js";
import picClose from "../../_legacy/images/close.png";
import defVImage from "../../_legacy/images/no-image-vertical.png";
import {
  getClientDetail,
  exportClientProcedures,
  getAllClientProcedures,
  exportEmptyData,
} from "../../Actions/Clients/clientsAction.js";
import LockProcedure from "../Clients/Timeline/LockProcedure.js";
import ConfirmUnlockModal from "../Clients/Timeline/ConfirmUnlockModal.js";

class ExportProcedures extends Component {
  constructor(props) {
    super(props);

    const languageData = JSON.parse(localStorage.getItem("languageData"));
    let defTimeLine = localStorage.getItem("defTimeLine")
      ? localStorage.getItem("defTimeLine")
      : "cosmetic";

    this.state = {
      backURLType: props.match.params.actionType
        ? props.match.params.actionType
        : "clients",
      action: props.match.params.type ? props.match.params.type : "profile",
      showLoader: false,
      globalLang: languageData.global,
      clientID: this.props.match.params.clientID,
      dataChanged: false,
      def_no_image_vertical: defVImage,
      totalChecked: 0,
      selected: [],
      falseCount: 0,
      languageData: languageData.clients,
      defTimeLine: defTimeLine,
      export_notes: false,
      export_markings: false,
      export_draw_tool_marking: false,
      clientLang: languageData.clients,
      clientProcedureList: [],
      clientProfileData: null,
      lockConfirmationModal: {
        isOpen: false,
        lockedIds: [],
        formData: {},
      },
    };
    window.onscroll = () => {
      return false;
    };
  }

  componentDidMount() {
    this.setState({
      showLoader: true,
    });

    this.state.clientScopes = "procedures";
    this.props.getAllClientProcedures({ patient_id: this.state.clientID });
  }

  static getDerivedStateFromProps(props, state) {
    if (
      props.allProcedureData &&
      props.allProcedureData !== state.allProcedureData
    ) {
      props.exportEmptyData();
      return {
        allProcedureData: props.allProcedureData,
        clientProfileData: props.allProcedureData.patient_data
          ? props.allProcedureData.patient_data
          : null,
        clientProcedureList: props.allProcedureData.patient_data
          ? props.allProcedureData.all_procedures
          : [],
        showLoader: false,
      };
    } else if (props.showLoader === false) {
      props.exportEmptyData();
      return { showLoader: false };
    } else if (
      props.exportProData &&
      props.exportProData !== state.exportProData
    ) {
      let returnState = {};
      returnState.export_notes = false;
      returnState.export_markings = false;
      returnState.export_draw_tool_marking = false;
      returnState.exportProData = props.exportProData;
      returnState.selected = [];

      window.open(props.exportProData, "_blank");
      returnState.showLoader = false;
      return returnState;
    }

    return null;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [event.target.name]: value,
    });

    if (target.type === "checkbox") {
      let val = event.target.value;
      let idx = event.target.name.split("_")[1];

      if (
        this.state.selected.length > 0 &&
        this.state.selected[idx] !== undefined
      ) {
        let newSelected = this.state.selected;
        let falseCount = this.state.falseCount;

        newSelected[[idx]][val] = !this.state.selected[[idx]][val];

        if (!newSelected[[idx]][val]) {
          falseCount = falseCount + 1;
        }

        if (falseCount <= this.state.selected.length) {
          falseCount = 0;
        }

        this.setState({
          selected: newSelected,
          falseCount: falseCount,
        });
      } else {
        let newSelected = this.state.selected;

        let falseCount = this.state.falseCount;

        if (falseCount > 0) {
          falseCount = falseCount - 1;
        }

        let a = {};
        a[val] = true;

        newSelected[idx] = a;
        this.setState({
          selected: newSelected,
          falseCount: falseCount,
        });
      }
    }
  };

  handleConfirmation(isOpen, lockedIds, formData = {}) {
    this.setState({
      lockConfirmationModal: {
        isOpen,
        lockedIds,
        formData,
      },
    });
  }

  hasCommonElement(arr1, arr2) {
    for (const element of arr2) {
      if (arr1.includes(element.toString())) {
        return true;
      }
    }
    return false;
  }

  exportProcedures = () => {
    const {
      clientProcedureList,
      selected,
      clientID,
      defTimeLine,
      export_notes,
      export_markings,
    } = this.state;
    const bluredSelectedIdx = clientProcedureList.flatMap((procedure) =>
      procedure.is_lock ? [procedure.id] : [],
    );

    let exportPro = false;
    const proIDArray = [];

    selected.forEach((ob) => {
      for (let proID in ob) {
        if (ob[proID] === true) {
          exportPro = true;
          proIDArray.push(proID);
        }
      }
    });
    const formData = {
      patient_id: clientID,
      pro_ids: proIDArray,
      default_template: defTimeLine,
      export_procedure_notes: export_notes ? 1 : 0,
      export_injection_marking: +export_markings,
      export_draw_tool_marking: +this.state.export_draw_tool_marking,
    };
    if (exportPro && !this.hasCommonElement(proIDArray, bluredSelectedIdx)) {
      this.setState({ showLoader: true });
      this.props.exportClientProcedures(formData);
    } else {
      this.handleConfirmation(true, bluredSelectedIdx, formData);
    }
  };

  render() {
    let totalChecked = 0;

    if (this.state.selected.length > 0) {
      this.state.selected.map((ob) => {
        for (let proID in ob) {
          if (ob[proID] === true) {
            totalChecked = 1;
          }
        }
      });
    }

    let returnTo = "";

    if (this.state.backURLType && this.state.backURLType === "clients") {
      returnTo = this.props.match.params.type
        ? "/" +
          this.state.backURLType +
          "/" +
          this.props.match.params.type +
          "/" +
          this.props.match.params.clientID +
          "/1"
        : "/" + this.state.backURLType;
    }

    let clientName = "";

    if (this.state.clientProfileData) {
      clientName = displayName(this.state.clientProfileData);
    }

    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <div className="merge-outer">
            <div className="merge-setion">
              <div className="juvly-container">
                {
                  <div className="juvly-title m-b-20">
                    {" "}
                    {this.state.showLoader === false && (
                      <span>
                        {clientName} -{" "}
                        {this.state.languageData.clientprofile_export_procedure}
                      </span>
                    )}
                    <Link to={returnTo} className="pull-right crossIcon">
                      <img alt="" src={picClose} />
                    </Link>
                  </div>
                }
                <div className="table export-procedure">
                  {this.state.clientProfileData &&
                    this.state.clientProcedureList &&
                    this.state.clientProcedureList.length > 0 &&
                    this.state.clientProcedureList.map((obj, idx) => {
                      return (
                        <div className="table-row" key={idx}>
                          <div className="table-cell export-input exportProcedureCheck">
                            <input
                              className={
                                obj.template_used === 0 &&
                                obj.procedure_type === "health"
                                  ? "without-consult-fees"
                                  : ""
                              }
                              type="checkbox"
                              id={"childCheck_" + idx}
                              name={"childCheck_" + idx}
                              checked={
                                this.state.selected.length > 0 &&
                                this.state.selected[idx] !== undefined &&
                                this.state.selected[[idx]][obj.id] === true
                              }
                              onChange={this.handleInputChange}
                              value={obj.id}
                            />
                          </div>
                          {obj.procedure_type === "cosmetic" && (
                            <React.Fragment key={idx}>
                              <div className="table-cell">
                                <label htmlFor={"childCheck_" + idx}>
                                  <div className="export-pro-image">
                                    <img
                                      key={idx}
                                      src={
                                        obj.procedure_image_data &&
                                        obj.procedure_image_data
                                          .patient_image_front_thumb_url
                                          ? obj.procedure_image_data
                                              .patient_image_front_thumb_url
                                          : this.state.def_no_image_vertical
                                      }
                                      alt=""
                                      className={
                                        obj.is_lock
                                          ? "lockSensitiveProcedureImages"
                                          : ""
                                      }
                                    />
                                  </div>
                                </label>
                              </div>
                              <div className="table-cell">
                                <div className="pro-proc-title">
                                  {obj.procedure_name}
                                </div>
                                <div className="export-pro-time">
                                  {showFormattedDate(obj.procedure_date, true)}
                                </div>
                                <span className="juvly-subtitle no-margin">
                                  {this.state.languageData.client_notes}
                                </span>
                                <div className="export-pro-note word-break">
                                  {obj.procedure_notes &&
                                    obj.procedure_notes.length > 0 &&
                                    obj.procedure_notes.map((iobj, iidx) => {
                                      return (
                                        <div
                                          key={iidx}
                                          className="p-text pro-to-export"
                                        >
                                          {iobj.notes}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                              <div className="table-cell">
                                <LockProcedure
                                  refetchProcedures={() =>
                                    this.props.getAllClientProcedures({
                                      patient_id: this.state.clientID,
                                    })
                                  }
                                  lockUnlockby={obj.locked_unlocked_by}
                                  isLocked={obj.is_lock}
                                  procedureId={obj.id}
                                />
                              </div>
                            </React.Fragment>
                          )}
                          {obj.procedure_type === "health" && (
                            <React.Fragment key={idx}>
                              <div className="table-cell">
                                <div className="pro-proc-title">
                                  {obj.procedure_name}
                                </div>
                                <div className="export-pro-time">
                                  {showFormattedDate(obj.procedure_date, true)}
                                </div>
                                <p className="p-text no-margin">
                                  <b>Provider:</b>{" "}
                                  {capitalizeFirstLetter(obj.doctor_name)}
                                </p>
                                {obj.template_used === 1 && (
                                  <p className="p-text no-margin">
                                    <b>Consultation fee:</b>{" "}
                                    {numberFormat(
                                      obj.consultation_fee,
                                      "currency",
                                    )}
                                  </p>
                                )}
                              </div>

                              <div className="table-cell">
                                <span className="juvly-subtitle no-margin">
                                  Notes:
                                </span>
                                <div className="export-pro-note word-break">
                                  {obj.procedure_notes &&
                                    obj.procedure_notes.length > 0 &&
                                    obj.procedure_notes.map((iobj, iidx) => {
                                      return (
                                        <div
                                          key={iidx}
                                          className="p-text pro-to-export"
                                        >
                                          {iobj.notes}
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      );
                    })}
                  {this.state.showLoader === false &&
                    this.state.clientProfileData &&
                    this.state.clientProcedureList &&
                    this.state.clientProcedureList.length === 0 && (
                      <div className="table-row">
                        <div className="no-record ">
                          <div className="text-center">
                            {this.state.languageData.client_no_procedure_found}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {totalChecked > 0 && (
                    <div>
                      <div className="basic-checkbox-outer  col-xs-12 no-margin">
                        <input
                          className="basic-form-checkbox"
                          type="checkbox"
                          name="export_markings"
                          checked={this.state.export_markings}
                          onChange={this.handleInputChange}
                          value={this.state.export_markings}
                        />
                        <label
                          className="basic-form-text"
                          htmlFor="Injection marking"
                        >
                          {
                            this.state.clientLang
                              .label_include_injection_marking
                          }
                        </label>
                      </div>
                      <div className="basic-checkbox-outer col-xs-12 no-margin">
                        <input
                          className="basic-form-checkbox"
                          type="checkbox"
                          name="export_draw_tool_marking"
                          checked={this.state.export_draw_tool_marking}
                          onChange={this.handleInputChange}
                          value={this.state.export_draw_tool_marking}
                        />
                        <label
                          className="basic-form-text"
                          htmlFor="Injection marking"
                        >
                          Include draw tool markings
                        </label>
                      </div>

                      <div className="basic-checkbox-outer  col-xs-12 no-margin">
                        <input
                          className="basic-form-checkbox"
                          type="checkbox"
                          name="export_notes"
                          checked={this.state.export_notes}
                          onChange={this.handleInputChange}
                          value={this.state.export_notes}
                        />
                        <label
                          className="basic-form-text"
                          htmlFor="Injection marking"
                        >
                          {this.state.clientLang.label_include_procedure_notes}
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {this.state.showLoader === false && (
                <div className="footer-static">
                  <a
                    id="saveConsultation"
                    className={
                      totalChecked > 0
                        ? "new-blue-btn pull-right"
                        : "new-blue-btn pull-right disable"
                    }
                    onClick={() => this.exportProcedures()}
                  >
                    {this.state.globalLang.label_export_text}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock clientLoader clientProfileLoader"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.globalLang.loading_please_wait_text}
            </div>
          </div>
        </div>

        {this.state.lockConfirmationModal.isOpen && (
          <ConfirmUnlockModal
            isOpen={this.state.lockConfirmationModal.isOpen}
            handleClose={() =>
              this.handleConfirmation(
                false,
                this.state.lockConfirmationModal.lockedIds,
                this.state.lockConfirmationModal.formData,
              )
            }
            procedureIds={this.state.lockConfirmationModal.lockedIds}
            refetchProcedures={() =>
              this.props.getAllClientProcedures({
                patient_id: this.state.clientID,
              })
            }
            downloadProcedures={() =>
              this.props.exportClientProcedures(
                this.state.lockConfirmationModal.formData,
              )
            }
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));

  let returnState = {};

  if (state.ClientsReducer.action === "EXPORT_CLIENT_PROCEDURES") {
    if (state.ClientsReducer.data.status !== 200) {
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.exportProData = state.ClientsReducer.data.data;
    }
  }

  if (state.ClientsReducer.action === "GET_ALL_CLIENT_PROCEDURES") {
    if (state.ClientsReducer.data.status !== 200) {
      toast.error(languageData.global[state.ClientsReducer.data.message]);
      returnState.showLoader = false;
    } else {
      returnState.allProcedureData = state.ClientsReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getClientDetail,
      exportClientProcedures,
      getAllClientProcedures,
      exportEmptyData,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportProcedures);
