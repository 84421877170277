import { dispatch } from "../../store/store";

export function accountStatusResponseInterceptor(response) {
  if (response?.data?.global_settings?.pos_data) {
    dispatch({
      type: "SET_ACCOUNT_STATUS",
      payload: response.data.global_settings.pos_data.account_status,
    });
  }

  return response;
}
