/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faSortUp } from "@fortawesome/free-solid-svg-icons";
import { Scrollbars } from "react-custom-scrollbars";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  fetchInventoryData,
  exportProducts,
  emptyInventoryReducer,
  activateProduct,
  fetchCategories,
} from "../../../Actions/Inventory/inventoryActions.js";
import { autoScrolling } from "../../../Utils/services.js";
import Sidebar from "../InventorySidebar";
import Loader from "../../Common/Loader";

var timeOut;

localStorage.removeItem("iiapfCategory");
localStorage.removeItem("iiapfStock");
localStorage.removeItem("iiapfSortBy");
localStorage.removeItem("iiapfSortOrder");

class InventoryProductsInactive extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const languageData = JSON.parse(localStorage.getItem("languageData")) || {
      global: {},
      inventory: {},
    };
    const iiapfCategory = localStorage.getItem("iiapfCategory");
    const iiapfStock = localStorage.getItem("iiapfStock");
    const iiapfSortBy = localStorage.getItem("iiapfSortBy");
    const iiapfSortOrder = localStorage.getItem("iiapfSortOrder");
    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      page: 1,
      pagesize: 20,
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      searchFunction: "",
      user_changed: false,
      tabClicked: false,
      InventoryData: [],
      defaultImage: "inventory-product-img",
      data: [],
      childCheck: false,
      action: "inactive",
      sortorder: iiapfSortOrder ? iiapfSortOrder : "asc",
      sortby: iiapfSortBy ? iiapfSortBy : "product_name",
      scopes: "category",
      selected: [],
      selectAll: 0,
      filterValue: false,
      categoryName: "",
      show_below_stock: iiapfStock ? iiapfStock : 0,
      filter_by_category_id: iiapfCategory ? iiapfCategory : "",
      globalLang: languageData.global,
      inventoryLang: languageData.inventory,
      showLoadingText: false,
      timeStamp: new Date(),
      apiInventoryData: {},
      activeProductId: 0,
      showModal: false,
      searchCategoryTerm: "",
      categoryList: [],
      selectedCategoryTerm: "",
      showActivationButton: false,
    };
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
    if (
      document.body.scrollTop != 0 ||
      document.documentElement.scrollTop != 0
    ) {
      window.scrollBy(0, -50);
      timeOut = setTimeout("scrollToTop()", 10);
    } else clearTimeout(timeOut);
  }

  handleInputChange = (event) => {
    let checkList = [];
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    this.setState({ [event.target.name]: value }, () => {
      if (target.type == "checkbox") {
        this.state.InventoryData &&
          this.state.InventoryData.map((data) => {
            if (this.state["product-status-" + data.id] == true) {
              checkList.push(data.id);
            }
          });
        if (checkList.length > 0) {
          this.setState({ showActivationButton: true });
        } else {
          this.setState({ showActivationButton: false });
        }
      }
    });
    if (target.name === "filter_by_category_id") {
      localStorage.setItem("iiapfCategory", value);
    }
    if (target.name === "show_below_stock") {
      localStorage.setItem("iiapfStock", value ? 1 : 0);
    }
  };

  componentDidUpdate = () => {
    if (this.state.action != "inactive") {
      let formData = {
        params: {
          page: 1,
          pagesize: this.state.pagesize,
          sortorder: this.state.sortorder,
          sortby: this.state.sortby,
          term: this.state.term,
        },
      };
      if (
        this.state.filter_by_category_id != null &&
        this.state.filter_by_category_id != "" &&
        this.state.filter_by_category_id != undefined
      ) {
        formData.params.filter_by_category_id =
          this.state.filter_by_category_id;
      }
      this.setState({
        action: this.props.match.params.statusId,
        InventoryData: [],
        showLoader: true,
      });
      autoScrolling(true);
      this.props.fetchInventoryData(this.props.match.params.statusId, formData);
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.emptyInventoryReducer();
    let formData = {
      params: {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder,
        sortby: this.state.sortby,
        term: this.state.term,
      },
    };
    if (this.state.filter_by_category_id) {
      formData.filter_by_category_id = this.state.filter_by_category_id;
    }
    this.setState({
      action: "inactive",
    });
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }
    this.setState({ showLoader: true });
    if (
      document.body.scrollTop != 0 ||
      document.documentElement.scrollTop != 0
    ) {
      window.scrollBy(0, -50);
      timeOut = setTimeout("scrollToTop()", 10);
    } else clearTimeout(timeOut);
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
  }

  handleSubmit = (event) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    localStorage.setItem("showLoader", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        sortby: "product_name",
        term: this.state.term,
      },
    };
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      InventoryData: [],
      showLoader: true,
      filterValue: "false",
      sortorder: "asc",
      sortby: "product_name",
    });
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
    localStorage.setItem("iiapfSortBy", "product_name");
    localStorage.setItem("iiapfSortOrder", "asc");
  };

  onSort = (sortby) => {
    let sortorder =
      sortby == this.state.sortby
        ? this.state.sortorder === "asc"
          ? "desc"
          : "asc"
        : "asc";
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: sortby,
        sortorder: sortorder,
        term: this.state.term,
      },
    };
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortby: sortby,
      sortorder: sortorder,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      InventoryData: [],
    });
    localStorage.setItem("sortOnly", true);
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
    localStorage.setItem("iiapfSortBy", sortby);
    localStorage.setItem("iiapfSortOrder", sortorder);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: false,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder:
            this.state.sortorder && this.state.sortorder === "asc"
              ? "asc"
              : this.state.sortorder == "desc"
              ? "desc"
              : "asc",
          sortby: this.state.sortby,
          term: this.state.term,
        },
      };
      if (
        this.state.filter_by_category_id != null &&
        this.state.filter_by_category_id != "" &&
        this.state.filter_by_category_id != undefined
      ) {
        formData.params.filter_by_category_id =
          this.state.filter_by_category_id;
      }
      this.setState({ showLoader: true });
      autoScrolling(true);
      this.props.fetchInventoryData(this.state.action, formData);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.exportCsvData != undefined &&
      prevState.timeStamp != nextProps.timeStamp
    ) {
      if (localStorage.getItem("showLoader") == "false") {
        localStorage.setItem("showLoader", true);
        window.open(nextProps.exportCsvData.file);
      }
      nextProps.emptyInventoryReducer();
    } else if (
      nextProps.showLoader != undefined &&
      nextProps.showLoader == false
    ) {
      nextProps.emptyInventoryReducer();
      return { showLoader: false };
    } else if (
      nextProps.activeProduct != undefined &&
      nextProps.activeProduct == true
    ) {
      nextProps.emptyInventoryReducer();
      let returnState = {};
      returnState.showLoader = false;
      returnState.activeProductId = 0;
      returnState.showModal = false;
      returnState.InventoryData = prevState.InventoryData;
      let index = -1;

      if (index !== -1) {
        returnState.InventoryData.splice(index, 1);
      }

      return returnState;
    } else if (
      nextProps.InventoryData != undefined &&
      nextProps.InventoryData !== prevState.apiInventoryData
    ) {
      let returnState = {};
      returnState.apiInventoryData = nextProps.InventoryData;
      returnState.showActivationButton = prevState.showActivationButton
        ? true
        : false;
      returnState.categoryList = nextProps.InventoryData.category_list;
      returnState.showLoader = false;
      nextProps.InventoryData.data.map((data) => {
        returnState["product-status-" + data.id] = false;
      });

      if (prevState.InventoryData.length == 0) {
        returnState.InventoryData = nextProps.InventoryData.data;
        returnState.CategoryData = nextProps.InventoryData.category_list;
        returnState.showLoader = false;
        if (nextProps.InventoryData.next_page_url != null) {
          returnState.page = prevState.page + 1;
        } else {
          returnState.next_page_url = nextProps.InventoryData.next_page_url;
          returnState.showLoader = false;
        }
        returnState.startFresh = false;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      } else if (
        prevState.InventoryData != nextProps.InventoryData.data &&
        prevState.InventoryData.length != 0
      ) {
        returnState.InventoryData = [
          ...prevState.InventoryData,
          ...nextProps.InventoryData.data,
        ];
        returnState.selectAll = 0;
        returnState.showLoader = false;
        returnState.total = nextProps.InventoryData.total;
        returnState.page = prevState.page + 1;
        returnState.next_page_url = nextProps.InventoryData.next_page_url;
        returnState.CategoryData = nextProps.InventoryData.category_list;
        returnState.showLoader = false;
        returnState.showLoadingText = false;
      }
      autoScrolling(false);
      return returnState;
    }
    return null;
  }

  shouldComponentUpdate() {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  inventoryEdit = (statusId) => {
    return (
      <div>
        {this.props.history.push(`/inventory/product/edit/${statusId}`)}
      </div>
    );
  };

  onFilter = () => {
    this.setState({ filterValue: true });
  };

  onReset = () => {
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        sortby: "product_name",
        term: "",
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: "asc",
      sortby: "product_name",
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      InventoryData: [],
      showLoader: true,
      filterValue: "false",
      term: "",
      show_below_stock: 0,
      filter_by_category_id: "",
    });
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
    localStorage.removeItem("iiapfCategory");
    localStorage.removeItem("iiapfStock");
    localStorage.removeItem("iiapfSortBy");
    localStorage.removeItem("iiapfSortOrder");
  };

  exportProducts = (mode) => {
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        term: this.state.term,
        show_below_stock: this.state.show_below_stock == false ? 0 : 1,
        action: this.state.action,
      },
    };
    if (
      this.state.filter_by_category_id != null &&
      this.state.filter_by_category_id != "" &&
      this.state.filter_by_category_id != undefined
    ) {
      formData.params.filter_by_category_id = this.state.filter_by_category_id;
    }

    localStorage.setItem("showLoader", true);
    this.setState({ showExportOptions: false, showStatusOptions: false });
    formData.params.product_status = "active";

    this.props.exportProducts(formData, mode);
  };

  showActiveProductodal = (activeProductId) => {
    this.setState({ showModal: true, activeProductId: activeProductId });
  };

  dismissModal = () => {
    this.setState({ showModal: false, activeProductId: 0 });
  };

  activateProduct = () => {
    let formData = {};
    let dataToSend = [];
    this.state.InventoryData.map((data) => {
      if (this.state["product-status-" + data.id] == true) {
        dataToSend.push(data.id);
      }
    });
    formData.product_ids = dataToSend;
    this.setState(
      { showLoader: true, showModal: false, InventoryData: [], page: 1 },
      () => {
        this.props.activateProduct(formData);
      },
    );
  };
  setCategoryShow = () => {
    let value = this.state.showCategoryList;
    this.setState({ showCategoryList: !value });
  };
  selectCategory = (term) => {
    this.setState({
      selectedCategoryTerm: term.category_name,
      selectedCategoryId: term.id,
      showLoader: true,
    });
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortby: this.state.sortby,
        sortorder: this.state.sortorder,
        term: this.state.term,
      },
    };

    if (term && term.id) {
      formData.params.filter_by_category_id = term.id;
    }

    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      loadMore: true,
      startFresh: true,
      showLoader: true,
      next_page_url: "",
      InventoryData: [],
    });
    localStorage.setItem("sortOnly", true);
    autoScrolling(true);
    this.props.fetchInventoryData(this.state.action, formData);
  };

  render() {
    return (
      <div id="content">
        <div className="container-fluid content setting-wrapper">
          <Sidebar />

          <div className="">
            <div className="setting-setion m-b-10">
              <div className="membership-title">
                <span className="cursor-pointer productDirectoryTitle">
                  AR Product Directory
                </span>
                <form
                  onSubmit={this.handleSubmit}
                  className="searchForm productDirectorySearch"
                >
                  <span className="searchOuter">
                    <i className="fa fa-search search-icon" />
                    <input
                      className="setting-search-input search-key"
                      placeholder={
                        this.state.globalLang
                          .inventory_reconciliation_search_product
                      }
                      name="term"
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </span>
                </form>
                <div className="memberRightActions ">
                  {this.state.showActivationButton == true && (
                    <button
                      className="new-blue-btn"
                      onClick={this.activateProduct}
                    >
                      Activate
                    </button>
                  )}
                </div>
              </div>
              <div className="table-responsive m-h-300">
                <table className="table-updated setting-table no-td-border no-hover">
                  <thead className="table-updated-thead">
                    <tr>
                      <th
                        className="col-xs-3 table-updated-th cursor-pointer sorting"
                        data-sort="product_name"
                        data-order="DESC"
                      >
                        Product
                      </th>
                      <th
                        className="col-xs-3 table-updated-th  cursor-pointer "
                        onClick={this.setCategoryShow}
                      >
                        Category{" "}
                        {this.state.selectedCategoryTerm
                          ? "(" + this.state.selectedCategoryTerm + ")"
                          : ""}
                        &nbsp;
                        <FontAwesomeIcon
                          onClick={this.setCategoryShow}
                          className={
                            this.state.showCategoryList == true
                              ? "tableArrowUp"
                              : "tableArrowDown"
                          }
                          icon={
                            this.state.showCategoryList == true
                              ? faSortUp
                              : faSortDown
                          }
                        ></FontAwesomeIcon>
                        <div
                          className={
                            this.state.showCategoryList == true
                              ? "tableCategoryDropDown"
                              : "no-display"
                          }
                        >
                          <Scrollbars
                            style={{ minHeight: "264px" }}
                            className="full-width"
                            renderTrackHorizontal={(props) => (
                              <div
                                {...props}
                                style={{ display: "none" }}
                                className="track-horizontal"
                              />
                            )}
                          >
                            <ul>
                              <li
                                onClick={() =>
                                  this.selectCategory({
                                    id: 0,
                                    category_name: "All",
                                  })
                                }
                              >
                                All
                              </li>
                              {this.state.categoryList.length > 0
                                ? this.state.categoryList.map((category) => {
                                    return (
                                      <li
                                        value={category.id}
                                        onClick={() =>
                                          this.selectCategory(category)
                                        }
                                      >
                                        {category.category_name}
                                      </li>
                                    );
                                  })
                                : ""}
                            </ul>
                          </Scrollbars>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.InventoryData !== undefined &&
                      this.state.InventoryData.map((obj, i) => {
                        return (
                          <tr key={i} className="table-updated-tr">
                            <td className="table-updated-td col-xs-7 text-ellipsis inentory-product-name">
                              <input
                                type="checkbox"
                                name={"product-status-" + obj.id}
                                checked={
                                  this.state["product-status-" + obj.id]
                                    ? "checked"
                                    : false
                                }
                                onChange={this.handleInputChange}
                                className="productDirectoryCheck"
                              />
                              {obj.product_name ? obj.product_name : ""}
                            </td>
                            <td className="table-updated-td col-xs-5 text-ellipsis">
                              {obj.category_name ? obj.category_name : ""}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                {this.state.showLoader === false && (
                  <div
                    className={
                      this.state.InventoryData != undefined &&
                      this.state.InventoryData.length == 0
                        ? "no-record"
                        : "no-record no-display"
                    }
                  >
                    <div
                      className=""
                      style={{
                        float: "left",
                        width: "100%",
                        fontSize: "13px",
                        textAlign: "center",
                        marginTop: "0px",
                        padding: "0px",
                      }}
                    >
                      {this.state.inventoryLang.inventory_No_record_found}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <Loader showLoader={this.state.showLoader} isFullWidth={true} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  let returnState = {};
  localStorage.setItem("showLoader", false);
  localStorage.setItem("sortOnly", false);
  if (state.InventoryReducer.action === "INVENTORY_PRODUCT_LIST_INACTIVE") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.InventoryData = state.InventoryReducer.data.data;
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }
  if (state.InventoryReducer.action === "EXPORT_PRODUCT_DATA") {
    if (state.InventoryReducer.data.status === 200) {
      returnState.exportCsvData = state.InventoryReducer.data.data;
      returnState.timeStamp = new Date();
    } else {
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
      returnState.showLoader = false;
      returnState.timeStamp = new Date();
    }
  }
  if (state.InventoryReducer.action === "ACTIVATE_PRODUCT") {
    if (state.InventoryReducer.data.status != 200) {
      returnState.showLoader = false;
      toast.dismiss();
      toast.error(languageData.global[state.InventoryReducer.data.message]);
    } else {
      toast.dismiss();
      toast.success(languageData.global[state.InventoryReducer.data.message]);
      returnState.InventoryData = state.InventoryReducer.data.data;
    }
  }

  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchInventoryData: fetchInventoryData,
      exportProducts: exportProducts,
      emptyInventoryReducer: emptyInventoryReducer,
      activateProduct: activateProduct,
      fetchCategories: fetchCategories,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(InventoryProductsInactive));
